// eslint-disable-next-line import/prefer-default-export
export const membershipStatuses = {
  active: 'Jäsenyys voimassa',
  ended: 'Jäsenyys päättynyt',
  periodized: 'Jäsenyys jaksotuksessa',
  ending: 'Jäsenyys päättymässä',
  dormant: 'Lepäävä jäsenyys',
  starting: 'Jäsenyys alkamassa',
};

export const LANGUAGE_OPTIONS = [
  { value: 'fi', ariaLabel: 'ARIA.CHANGE_LANGUAGE_FIN', label: 'FINNISH' },
  { value: 'se', ariaLabel: 'ARIA.CHANGE_LANGUAGE_SWE', label: 'SWEDISH' },
  { value: 'en', ariaLabel: 'ARIA.CHANGE_LANGUAGE_ENG', label: 'ENGLISH' },
];

export const localeMap = {
  fi: 'fi-FI',
  se: 'se-SE',
  en: 'en-EN',
};

export const fundingOptions = {
  fund100: {
    value: 100000000,
    label: 'Rahastoin palkkion kokonaan',
    translate: 'PERSONNELFUND.FUND100',
  },
  fund75: {
    value: 100000001,
    label: 'Rahastoin palkkiosta 75%',
    translate: 'PERSONNELFUND.FUND75',
  },
  fund50: {
    value: 100000002,
    label: 'Rahastoin puolet ja nostan puolet käteisenä',
    translate: 'PERSONNELFUND.FUND50',
  },
  fund25: {
    value: 100000003,
    label: 'Rahastoin palkkiosta 25%',
    translate: 'PERSONNELFUND.FUND25',
  },
  fund67: {
    value: 100000004,
    label: 'Rahastoin palkkiosta 2/3',
    translate: 'PERSONNELFUND.FUND67',
  },
  fund33: {
    value: 100000005,
    label: 'Rahastoin palkkiosta 1/3',
    translate: 'PERSONNELFUND.FUND33',
  },
  withdrawCash: {
    value: 100000006,
    label: 'Nostan käteisenä',
    translate: 'PERSONNELFUND.WITHDRAWCASH',
  },
  fund50eur: {
    value: 100000007,
    label: 'Rahastoin palkkiosta 50€',
    translate: 'PERSONNELFUND.FUND50EUR',
  },
  fund100eur: {
    value: 100000010,
    label: 'Rahastoin palkkiosta 100€',
    translate: 'PERSONNELFUND.FUND100EUR',
  },
  fund150eur: {
    value: 100000008,
    label: 'Rahastoin palkkiosta 150€',
    translate: 'PERSONNELFUND.FUND150EUR',
  },
  fund300eur: {
    value: 100000009,
    label: 'Rahastoin palkkiosta 300€',
    translate: 'PERSONNELFUND.FUND300EUR',
  },
};

export const fundingOptionsAsArray = Object.keys(fundingOptions).map(
  (key) => fundingOptions[key],
);

export const riskOptions = {
  riskCareful: {
    value: 'riskStrategyCareful',
    label: 'PERSONNELFUND.CAREFUL',
    infoKey: 'carefulStrategyInfoText',
  },
  riskNeutral: {
    value: 'riskStrategyNeutral',
    label: 'PERSONNELFUND.NEUTRAL',
    infoKey: 'neutralStrategyInfoText',
  },
  riskAggressive: {
    value: 'riskStrategyAggressive',
    label: 'PERSONNELFUND.AGGRESSIVE',
    infoKey: 'aggressiveStrategyInfoText',
  },
  riskOwnShare: {
    value: 'riskStrategyOwnShare',
    label: 'PERSONNELFUND.OWN_SHARE',
    infoKey: '',
  },
};

export const contacts = (t) => ({
  customerService: {
    phone: '0201 558 610',
    email: 'asiakaspalvelu@eabgroup.fi',
    serviceTime: `09:00-17:00 ${t('ON_WEEKDAYS')}`,
  },
  memberService: {
    phone: '0201 558 660',
    email: 'jasenpalvelu@eai.fi',
    serviceTime: `09:00-17:00 ${t('ON_WEEKDAYS')}`,
  },
});

export const EAB_EUROOPPA_FOKUS_FAMILY_GUID = '2e15ffff-a69b-e611-80c2-dab4f8767ec4';
export const EAB_OSAKE_FAMILY_GUID = '8c15ffff-a69b-e611-80c2-dab4f8767ec4';
export const EAB_KORKO_FAMILY_GUID = '8a15ffff-a69b-e611-80c2-dab4f8767ec4';
export const EAB_OPTIMAALIVARAINHOITO_FAMILY_GUID = 'a588af75-21c5-e711-80d7-005056aa5d60';
export const EAB_SUOMI_FOKUS_FAMILY_GUID = '0215ffff-a69b-e611-80c2-dab4f8767ec4';
export const EAB_USA_FOKUS_FAMILY_GUID = '6bfb9dc8-40dd-ea11-8112-005056aa5d60';
export const EAB_MARKKINAT_FOKUS_FAMILY_GUID = '3fc0c00a-700c-eb11-8114-005056aa5d60';
export const EAB_VUOKRATUOTTO_FAMILY_GUID = '30294238-74ce-e611-80c5-dab4f8767ec4';
export const EAB_LOGISTIIKKA_FAMILY_GUID = 'cc720221-db9e-ec11-b69c-005056aac1b6';

export const monthlySavingsContractTermsLink = 'https://assets.ctfassets.net/m7g7zm9i0ru1/4vHV6gFsw9c8HKILJSYMrD/5bfe95804e24f26763e4a13b3a5182e2/kuukausisaaston_sopimusehdot.pdf';

export const summaryReportTimeSpanOptions = (t) => [
  { value: '3_MONTHS', label: t('THREE_MONTHS_SHORT') },
  { value: '6_MONTHS', label: t('SIX_MONTHS_SHORT') },
  { value: '12_MONTHS', label: t('TWELVE_MONTHS_SHORT') },
  { value: 'CURRENT_YEAR', label: t('WEB_REPORTS.CURRENT_YEAR') },
  { value: 'SINCE_BEGINNING', label: t('WEB_REPORTS.SINCE_BEGINNING') },
  { value: 'SELECT_DATES', label: t('WEB_REPORTS.ADJUSTED_DATES') },
];

export const fundInfoTimeSpanOptions = (t) => [
  { value: '3_MONTHS', label: t('FUNDSEARCH.3_MONTHS') },
  { value: '6_MONTHS', label: t('FUNDSEARCH.PROFIT_6_MONTHS') },
  { value: '12_MONTHS', label: t('FUNDSEARCH.12_MONTHS') },
  { value: 'CURRENT_YEAR', label: t('FUNDSEARCH.PROFIT_YTD') },
  { value: 'SINCE_BEGINNING', label: t('WEB_REPORTS.SINCE_BEGINNING') },
  { value: 'SELECT_DATES', label: t('WEB_REPORTS.ADJUSTED_DATES') },
];

export const transactionTypes = [
  'Subscription',
  'Redemption',
  'Transfer',
  'Merge',
  'FXTrade',
  'Dividend',
  'Buy',
  'Sell',
  'Installment',
  'Coupon_Interest',
  'Deposit',
  'Withdraw',
  'PE_Interest',
  'PE_Dividend',
  'PE_Subscription',
  'PE_Redemption',
  'Return_Of_Capital',
  'Cash_Withdraw',
  'Cash_Deposit',
  'CA_Deposit',
  'CA_Withdraw',
  'Kickback_Subscription',
  'Dividend_Subscription',
  'Amortization',
  'Deposit_Swap',
  'Donation_Redemption',
  'Donation_Subscription',
  'Expiration_Sell',
  'Interest',
  'Rights_Issue',
  'Switch_Redemption',
  'Switch_Subscription',
  'Transfer_Deposit',
  'Transfer_Withdraw',
  'Withdraw_Swap',
];

export const dataToDisplayInReturnSummary = (t, thisYear) => [
  {
    key: 'threeMonthReturnPercentage',
    dateRange: t('THREE_MONTHS_SHORT'),
  },
  {
    key: 'sixMonthReturnPercentage',
    dateRange: t('SIX_MONTHS_SHORT'),
  },
  {
    key: 'oneYearReturnPercentage',
    dateRange: t('TWELVE_MONTHS_SHORT'),
  },
  {
    key: 'allYearReturnPercentage',
    dateRange: t('WEB_REPORTS.CURRENT_YEAR'),
  },
  {
    key: 'twoYearReturnPercentage',
    dateRange: t('TWO_YEARS_SHORT'),
  },
  {
    key: 'lastYearReturnPercentage',
    dateRange: (thisYear - 1).toString(),
  },
  {
    key: 'yearBeforeLastYearReturnPercentage',
    dateRange: (thisYear - 2).toString(),
  },
  {
    key: 'allTimeReturnPercentage',
    dateRange: t('FROM_BEGINNING_OF_CONTRACT'),
  },
  {
    key: 'annualReturnPercentage',
    dateRange: t('FROM_BEGINNING_OF_CONTRACT_ANNUAL'),
  },
];

export const depositMethodOptions = (t) => [
  {
    label: t('ORDERS_DEPOSIT.PAYTRAIL_ONLINE_PAYMENT'),
    value: 'paytrail',
  },
  {
    label: t('ACCOUNT_TRANSFER'),
    value: 'transfer',
  },
];

export const depositIncomeOriginOptions = (t) => [
  {
    label: t('WORK_INCOME_MIFID'),
    name: 'sourceWorkIncome',
  },
  {
    label: t('COMPANY_SALE'),
    name: 'sourceCompanySale',
  },
  {
    label: t('INVESTMENT_INCOME_EG_DIVIDEND_INCOME_RENT_INCOME'),
    name: 'sourceInvestmentIncome',
  },
  {
    label: t('REAL_ESTATE_SALE'),
    name: 'sourceRealSale',
  },
  {
    label: t('INVESTMENT_SALES'),
    name: 'sourceInvestmentSales',
  },
  {
    label: t('GIFT'),
    name: 'sourceGift',
  },
  {
    label: t('BUSINESS_INCOME'),
    name: 'sourceBusinessIncome',
  },
  {
    label: t('INHERITANCE'),
    name: 'sourceInheritance',
  },
  {
    label: t('LOAN'),
    name: 'sourceLoan',
  },
  {
    label: t('OTHER'),
    name: 'sourceOther',
  },
];

export const sellFundManagementOptions = (translateFunction, accountNumber) => {
  const t = translateFunction || ((val) => val);
  return [
    {
      value: 'portfolio',
      label: t('ORDERS_SELL.KEEP_FUNDS_IN_PORTFOLIO'),
      managementRedemptionText: t('ORDERS_CONFIRMATION.AND_KEEP_FUNDS_IN_PORTFOLIO'),
    },
    {
      value: 'withdraw',
      label: `${t('ORDERS_SELL.WITHDRAW_FUNDS')} ${accountNumber}`,
      managementRedemptionText: t('ORDERS_CONFIRMATION.AND_WITHDRAW_FUNDS'),
    },
  ];
};

export const buyFundOrderPurposeOptions = (t) => [
  {
    value: 'investment',
    label: t('GENERAL_INVESTMENT_PURSUIT_OF_PROFIT'),
  },
  {
    value: 'other',
    label: t('OTHER'),
  },
];

// Valuuttakaupan hinnasto:
export const currencyFeePrices = [
  { bottomLimit: 10000000, feePercentage: 0.001, feeAtBottomLimit: 20615 },
  { bottomLimit: 1000000, feePercentage: 0.0020, feeAtBottomLimit: 2615 },
  { bottomLimit: 100000, feePercentage: 0.0025, feeAtBottomLimit: 365 },
  { bottomLimit: 10000, feePercentage: 0.0035, feeAtBottomLimit: 50 },
  { bottomLimit: 0, feePercentage: 0.005, feeAtBottomLimit: 0 },
];

export const paymentFrequencyOptions = (t) => [
  { label: t('ORDERS_MONTHLY.MONTHLY'), value: 1 },
  { label: t('ORDERS_MONTHLY.EVERY_3_MONTHS'), value: 3 },
  { label: t('ORDERS_MONTHLY.EVERY_6_MONTHS'), value: 6 },
];

export const paymentDateOptions = (t) => [
  { label: t('ORDERS_MONTHLY.FIRST_DAY'), value: 1 },
  { label: t('ORDERS_MONTHLY.15TH_DAY'), value: 15 },
  { label: t('ORDERS_MONTHLY.LAST_DAY'), value: 100 },
];

export const fundListsEAB = {
  hedge: [
    {
      name: 'AJ EAB Value Hedge A',
      guid: 'cba4589c-eb15-e711-80cb-dab4f8767ec4',
    },
    {
      name: 'AJ EAB Value Hedge C',
      guid: '173b10e9-e415-e711-80cb-dab4f8767ec4',
    },
  ],
  osake: [
    {
      name: 'Evli Osake B',
      guid: '46d6849c-ac46-e711-80cd-dab4f8767ec4',
    },
  ],
  korko: [
    {
      name: 'Evli Globaali Korko A',
      guid: '2a0f388c-e002-e911-80f5-005056aa5d60',
    },
    {
      name: 'Evli Globaali Korko B',
      guid: 'd6cf6a4f-189a-e711-80d5-005056aa5d60',
    },
  ],
  optimaalivarainhoito: [
    {
      name: 'Evli Optimaalivarainhoito A',
      guid: 'cabe8e9f-3bd4-e711-80d9-005056aa5d60',
    },
    {
      name: 'Evli Optimaalivarainhoito B',
      guid: 'c0efc107-e3f3-e811-80f5-005056aa5d60',
    },
  ],
  fokusEurooppa: [
    {
      name: 'Elite Alfred Berg Eurooppa Fokus A Tuotto',
      guid: 'bebe8e9f-3bd4-e711-80d9-005056aa5d60',
    },
    {
      name: 'Elite Alfred Berg Eurooppa Fokus A Kasvu',
      guid: '2baa6d43-a99b-e611-80c2-dab4f8767ec4',
    },
    {
      name: 'Elite Alfred Berg Eurooppa Fokus A',
      guid: 'e385a9bb-ab1d-ea11-8104-005056aa5d60',
    },
    {
      name: 'Elite Alfred Berg Eurooppa Fokus B',
      guid: 'a81f52a2-ad1d-ea11-8104-005056aa5d60',
    },
    {
      name: 'Elite Alfred Berg Eurooppa Fokus C',
      guid: 'edbabc50-ae1d-ea11-8104-005056aa5d60',
    },
    {
      name: 'Elite Alfred Berg Eurooppa Fokus D',
      guid: '3b84a80e-af1d-ea11-8104-005056aa5d60',
    },
    {
      name: 'Elite Alfred Berg Eurooppa Fokus E',
      guid: '43e46ea3-af1d-ea11-8104-005056aa5d60',
    },
  ],
  fokusSuomi: [
    {
      name: 'Elite Alfred Berg Suomi Fokus A Tuotto',
      guid: '5a03733d-a99b-e611-80c2-dab4f8767ec4',
    },
    {
      name: 'Elite Alfred Berg Suomi Fokus A Kasvu',
      guid: '5403733d-a99b-e611-80c2-dab4f8767ec4',
    },
    {
      name: 'Elite Alfred Berg Suomi Fokus A',
      guid: '4f365297-9b12-ea11-8103-005056aa5d60',
    },
    {
      name: 'Elite Alfred Berg Suomi Fokus B',
      guid: 'a3b2e858-9d12-ea11-8103-005056aa5d60',
    },
    {
      name: 'Elite Alfred Berg Suomi Fokus D',
      guid: '3a2bb612-9e12-ea11-8103-005056aa5d60',
    },
    {
      name: 'Elite Alfred Berg Suomi Fokus E',
      guid: 'c89b5de7-9e12-ea11-8103-005056aa5d60',
    },
  ],
  vuokratuotto: [
    {
      name: 'Elite Alfred Berg Vuokratuotto',
      guid: 'e25cfc9b-74ce-e611-80c5-dab4f8767ec4',
    },
  ],
  alyenergia: [
    {
      name: 'Elite Alyenergia',
      guid: 'a7b50706-0fc6-e711-80d7-005056aa5d60',
    },
  ],
};

export const portfolioReportGroupingOptions = (t) => [
  {
    label: t('WEB_REPORTS.ASSET_TYPE'),
    value: 'assetType',
  },
  {
    label: t('WEB_REPORTS.CLASSIFICATION'),
    value: 'classification',
    dependentOf: 'assetType',
  },
  {
    label: t('WEB_REPORTS.INSTRUMENT_TYPE'),
    value: 'instrumentType',
  },
  {
    label: t('WEB_REPORTS.CURRENCY'),
    value: 'currency',
    dependentOf: 'instrumentType',
  },
];

// show these funds documents in evli page https://www.evli.com/tuotteet-ja-palvelut/rahastot/rahastot/eab-dokumentit
export const fundsForCommonDocumentsLink = [
  'd04cb2f8-dc9e-ec11-b69c-005056aac1b6', // Elite Alfred Berg Logistiikkakiinteistöt
  '081f65c7-dd9e-ec11-b69c-005056aac1b6',
  '526dd701-df9e-ec11-b69c-005056aac1b6',
  'bc96fb78-df9e-ec11-b69c-005056aac1b6',
  'e25cfc9b-74ce-e611-80c5-dab4f8767ec4', // Elite Alfred Berg Vuokratuotto
  'f6831157-7af3-e611-80c6-dab4f8767ec4',
  '0cfc3e84-7af3-e611-80c6-dab4f8767ec4',
  '88a868d9-0046-e711-80cd-dab4f8767ec4',
  '9f4f8749-a99b-e611-80c2-dab4f8767ec4', // Elite Alfred Berg Korko
  'ea178293-179a-e711-80d5-005056aa5d60',
  'd6cf6a4f-189a-e711-80d5-005056aa5d60',
  'eab17822-1a9a-e711-80d5-005056aa5d60',
  '2a0f388c-e002-e911-80f5-005056aa5d60',
  '09f81c42-5c17-ea11-8104-005056aa5d60', // Elite Alfred Berg Optimaalivarainhoito
  '7e939870-0ec6-e711-80d7-005056aa5d60',
  'cabe8e9f-3bd4-e711-80d9-005056aa5d60',
  '2d9b05de-5d17-ea11-8104-005056aa5d60',
  '02c6276f-e2f3-e811-80f5-005056aa5d60',
  'c0efc107-e3f3-e811-80f5-005056aa5d60',
  '502d867e-e3f3-e811-80f5-005056aa5d60',
  '0c2cbb4f-a99b-e611-80c2-dab4f8767ec4', // Elite Alfred Berg Osake
  '4b90ff22-0446-e711-80cd-dab4f8767ec4',
  '46d6849c-ac46-e711-80cd-dab4f8767ec4',
  '55cf65aa-ad46-e711-80cd-dab4f8767ec4',
  'cba4589c-eb15-e711-80cb-dab4f8767ec4', // AJ EAB Value Hedge
  '173b10e9-e415-e711-80cb-dab4f8767ec4',
];

// Verkkopalvelu freeze date 30.9.2022. Show data max to this date.
export const VP_FREEZE_DATE_OBJECT = new Date(2022, 8, 30);
