import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import SectionMenu from '../common/SectionMenu';
import { SECTION_MENU_VALUES } from '../../lib/navigationLinks';

function NavigationSectionMenu({ sectionMenuValue }) {
  const { t } = useTranslation();
  const inPersonnelFundView = useSelector((state) => state.UIstate.inPersonnelFundView);

  const currentUser = useSelector((state) => state.auth.currentUser);

  // construct section menu based on user customer status
  const options = [];
  if (currentUser && currentUser.isEliteCustomer && inPersonnelFundView) {
    options.unshift(
      {
        label: 'Evli.com',
        value: ' ',
        href: 'https://login.evli.com/interaction',
      },
    );
  }
  if (currentUser && currentUser.isEliteCustomer && !inPersonnelFundView) {
    options.unshift(
      {
        label: t('ONLINE_SERVICE'),
        value: SECTION_MENU_VALUES(t).WEB_SERVICE,
        href: '/varallisuus',
      },
    );
  }
  if (currentUser && currentUser.isPersonnelFundCustomer) {
    options.unshift(
      {
        label: t('PERSONNEL_FUNDS'),
        value: SECTION_MENU_VALUES(t).PERSONNEL_FUND,
        href: '/henkilostorahasto',
      },
    );
  }

  if (options.length <= 1) {
    return (
      <Typography
        align="center"
        style={
          {
            color: '#4D5758', fontSize: '0.75rem', textTransform: 'uppercase', fontWeight: 600,
          }
        }
      >
        {sectionMenuValue}
      </Typography>
    );
  }

  return (
    <SectionMenu
      ariaLabel={t('ARIA.SWITCH_TO_SERVICE')}
      value={sectionMenuValue}
      options={options}
    />
  );
}

NavigationSectionMenu.propTypes = {
  sectionMenuValue: PropTypes.string,
};

NavigationSectionMenu.defaultProps = {
  sectionMenuValue: '',
};

export default NavigationSectionMenu;
