import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../api/axiosSetup';

export const fetchPersonalBankerInfo = createAsyncThunk('customerService/fetchPersonalBankerInfo', async (_, thunkAPI) => {
  const { customerId } = thunkAPI.getState().auth;
  const response = await axios.get(`api/banker${customerId ? `?customerId=${customerId}` : ''}`);
  return response.data;
});

// announcements
export const fetchValidNewsLetters = createAsyncThunk('customerService/fetchValidNewsLetters', async (getAttachments, thunkAPI) => {
  const { customerId } = thunkAPI.getState().auth;
  const response = await axios.get(`api/newsletters/${getAttachments}${customerId ? `?customerId=${customerId}` : ''}`);
  return response.data;
});

export const fetchForceOpenNewsLetters = createAsyncThunk('customerService/fetchForceOpenNewsLetters', async (getAttachments, thunkAPI) => {
  const { customerId } = thunkAPI.getState().auth;
  const response = await axios.get(`api/newsletters/${getAttachments}${customerId ? `?customerId=${customerId}` : ''}`);
  const today = new Date().getTime();
  const forceOpenNewsLetters = response.data.filter((newsLetter) => {
    const raisedFromDateTime = new Date(newsLetter.raisedFromDateTime).getTime();
    const raisedUntilDateTime = new Date(newsLetter.raisedUntilDateTime).getTime();
    return today >= raisedFromDateTime && today <= raisedUntilDateTime && !newsLetter.isOpened;
  });
  return forceOpenNewsLetters;
});

export const fetchRaiseNewsletters = createAsyncThunk('customerService/fetchRaiseNewsletters', async () => {
  const response = await axios.get('api/newsletters/raised');
  return response.data;
});

export const postMarkNewsletterOpened = createAsyncThunk('customerService/postMarkNewsletterOpened', async ({ model }, thunkAPI) => {
  const { customerId } = thunkAPI.getState().auth;
  if (customerId) {
    return thunkAPI.rejectWithValue('Forbidden in banker view');
  }
  const response = await axios.post('api/newsletters/opened/', model);
  return response.data;
});

// messages
export const fetchCustomerConversations = createAsyncThunk('customerService/fetchConversations', async (_, thunkAPI) => {
  const { customerId } = thunkAPI.getState().auth;
  const response = await axios.get(`api/customerservice/conversations${customerId ? `?customerId=${customerId}` : ''}`);
  return response.data;
});

export const fetchCustomerConversationMessages = createAsyncThunk('customerService/fetchCustomerConversationMessages', async ({ conversationId }, thunkAPI) => {
  const { customerId } = thunkAPI.getState().auth;
  const response = await axios.get(`api/customerservice/conversations/${conversationId}${customerId ? `?customerId=${customerId}` : ''}`);
  return response.data;
});

export const fetchCustomerConversationMessageReceivers = createAsyncThunk('customerService/fetchCustomerConversationMessageReceivers', async (_, thunkAPI) => {
  const { customerId } = thunkAPI.getState().auth;
  const response = await axios.get(`api/customerservice/conversations/receivers${customerId ? `?customerId=${customerId}` : ''}`);
  return response.data;
});

export const postNewConversation = createAsyncThunk('customerService/postNewConversation', async ({ newConversation }, thunkAPI) => {
  const { customerId } = thunkAPI.getState().auth;
  if (customerId) {
    return thunkAPI.rejectWithValue('Forbidden in banker view');
  }
  const response = await axios.post(
    'api/customerservice/conversations',
    newConversation,
  );
  return response.status;
});

export const postNewConversationMessage = createAsyncThunk('customerService/postNewConversationMessage', async ({ newConversationMessage }, thunkAPI) => {
  const { customerId } = thunkAPI.getState().auth;
  if (customerId) {
    return thunkAPI.rejectWithValue('Forbidden in banker view');
  }
  const response = await axios.post(
    `api/customerservice/conversations/${newConversationMessage.ConversationId}`,
    newConversationMessage,
  );
  return response.status;
});

export const postMarkAsReadConversationMessage = createAsyncThunk('customerService/postMarkAsReadConversationMessage', async ({ messageId }, thunkAPI) => {
  const { customerId } = thunkAPI.getState().auth;
  if (customerId) {
    return thunkAPI.rejectWithValue('Forbidden in banker view');
  }
  const response = await axios.post(
    `api/customerservice/conversations/messages/mark/${messageId}`,
  );
  return response.status;
});

// documents

export const fetchUserReports = createAsyncThunk('customerService/fetchUserReports', async (_, thunkAPI) => {
  const { customerId } = thunkAPI.getState().auth;
  const response = await axios.get(`api/report/userReports${customerId ? `?customerId=${customerId}` : ''}`);
  return response.data;
});

const initialState = {
  personalBankerInfo: null,
  fetchingPersonalBankerInfo: false,
  fetchingValidNewsLetters: false,
  validNewsLetters: [],
  fetchingForceOpenNewsLetters: null,
  forceOpenNewsLetters: null,
  fetchingCustomerConversations: false,
  customerConversations: [],
  fetchingCustomerConversationMessages: false,
  customerConversationMessages: null,
  fetchingCustomerMessageReceivers: false,
  customerMessageReceivers: [],
  postingNewConversation: false,
  newConversationPostStatus: null,
  postingNewConversationMessage: false,
  newConversationMessageStatus: null,
  userReports: [],
  fetchingUserReports: false,
};

export const customerServiceSlice = createSlice({
  name: 'customerService',
  initialState,
  reducers: {
    resetCustomerServiceState: () => initialState,
    resetPostStatus: (state) => {
      state.newConversationMessageStatus = null;
      state.newConversationPostStatus = null;
    },
    resetForcedNewsLetters: (state) => {
      state.forceOpenNewsLetters = null;
    },
  },
  extraReducers: {
    [fetchPersonalBankerInfo.pending]: (state) => {
      state.fetchingPersonalBankerInfo = true;
      state.personalBankerInfo = null;
    },
    [fetchPersonalBankerInfo.fulfilled]: (state, { payload }) => {
      state.fetchingPersonalBankerInfo = false;
      state.personalBankerInfo = payload;
    },
    [fetchPersonalBankerInfo.rejected]: (state) => {
      state.fetchingPersonalBankerInfo = false;
    },
    [fetchValidNewsLetters.pending]: (state) => {
      state.fetchingValidNewsLetters = true;
    },
    [fetchValidNewsLetters.fulfilled]: (state, { payload }) => {
      state.fetchingValidNewsLetters = false;
      state.validNewsLetters = payload;
    },
    [fetchValidNewsLetters.rejected]: (state) => {
      state.fetchingValidNewsLetters = false;
    },
    [fetchForceOpenNewsLetters.pending]: (state) => {
      state.fetchingForceOpenNewsLetters = true;
    },
    [fetchForceOpenNewsLetters.fulfilled]: (state, { payload }) => {
      state.forceOpenNewsLetters = payload;
      state.fetchingForceOpenNewsLetters = false;
    },
    [fetchForceOpenNewsLetters.rejected]: (state) => {
      state.fetchingForceOpenNewsLetters = false;
    },
    [fetchCustomerConversations.pending]: (state) => {
      state.fetchingCustomerConversations = true;
    },
    [fetchCustomerConversations.fulfilled]: (state, { payload }) => {
      state.fetchingCustomerConversations = false;
      state.customerConversations = payload;
    },
    [fetchCustomerConversations.rejected]: (state) => {
      state.fetchingCustomerConversations = false;
    },
    [fetchCustomerConversationMessages.pending]: (state) => {
      state.fetchingCustomerConversationMessages = true;
      state.customerConversationMessages = null;
    },
    [fetchCustomerConversationMessages.fulfilled]: (state, { payload }) => {
      state.fetchingCustomerConversationMessages = false;
      state.customerConversationMessages = payload;
    },
    [fetchCustomerConversationMessages.rejected]: (state) => {
      state.fetchingCustomerConversationMessages = false;
    },
    [fetchCustomerConversationMessageReceivers.pending]: (state) => {
      state.fetchingCustomerMessageReceivers = true;
      state.customerMessageReceivers = [];
    },
    [fetchCustomerConversationMessageReceivers.fulfilled]: (state, { payload }) => {
      state.fetchingCustomerMessageReceivers = false;
      state.customerMessageReceivers = payload;
    },
    [fetchCustomerConversationMessageReceivers.rejected]: (state) => {
      state.fetchingCustomerMessageReceivers = false;
    },
    [postNewConversation.pending]: (state) => {
      state.postingNewConversation = true;
      state.newConversationPostStatus = null;
    },
    [postNewConversation.fulfilled]: (state, { payload }) => {
      state.postingNewConversation = false;
      state.newConversationPostStatus = payload;
    },
    [postNewConversation.rejected]: (state) => {
      state.postingNewConversation = false;
    },
    [postNewConversationMessage.pending]: (state) => {
      state.postingNewConversationMessage = true;
      state.newConversationMessageStatus = null;
    },
    [postNewConversationMessage.fulfilled]: (state, { payload }) => {
      state.postingNewConversationMessage = false;
      state.newConversationMessageStatus = payload;
    },
    [postNewConversationMessage.rejected]: (state) => {
      state.postingNewConversationMessage = false;
    },
    [fetchUserReports.pending]: (state) => {
      state.fetchingUserReports = true;
      state.userReports = [];
    },
    [fetchUserReports.fulfilled]: (state, { payload }) => {
      state.fetchingUserReports = false;
      state.userReports = payload;
    },
    [fetchUserReports.rejected]: (state) => {
      state.fetchingUserReports = false;
    },
  },
});

export const {
  resetCustomerServiceState, resetPostStatus, resetForcedNewsLetters,
} = customerServiceSlice.actions;

export default customerServiceSlice.reducer;
