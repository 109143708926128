export const phoneNumberValidation = /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5})|(\(?\d{2,6}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/;

export const phoneNumberValidationFinnish = /^((90[0-9]{3})?0|\+358\s?)(?!(100|20(0|2(0|[2-3])|9[8-9])|300|600|700|708|75(00[0-3]|(1|2)\d{2}|30[0-2]|32[0-2]|75[0-2]|98[0-2])))(4|50|10[1-9]|20(1|2(1|[4-9])|[3-9])|29|30[1-9]|71|73|75(00[3-9]|30[3-9]|32[3-9]|53[3-9]|83[3-9])|2|3|5|6|8|9|1[3-9])\s?(\d\s?){4,10}\d$/;

export const bicValidation = /^([a-zA-Z]{4}[a-zA-Z]{2}[a-zA-Z0-9]{2}([a-zA-Z0-9]{3})?)$/;

export const allowLettersNumbersSpaces = /^([a-zA-ZåäöÅÖÄ0-9 ]*[a-zA-ZåäöÅÖÄ0-9]*[a-zA-ZåäöÅÖÄ0-9 ])$/;

/**
 * Check if Iban has FI-code = if Iban is Finnish
 * @param {string} iban - iban string to be checked
 */
export const isIbanFinnish = (iban) => {
  if (iban !== null && iban !== '' && iban !== undefined) {
    if (iban.slice(0, 2).toUpperCase() !== 'FI') {
      return false;
    }
    return true;
  }
  return true;
};

export const formatter = new Intl.NumberFormat('fi', {
  style: 'currency',
  currency: 'EUR',

  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export const percentFormatter = new Intl.NumberFormat('fi', {
  style: 'percent',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export const formatEurAndPercent = (data) => data.map(
  (row) => ({
    ...row,
    eur: formatter.format(row.eur),
    percent: percentFormatter.format(row.percent),
  }),
);

export const toDecimalWithComma = (value, digits) => {
  const decimalFormatter = new Intl.NumberFormat('fi', {
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  });
  return decimalFormatter.format(value);
};
