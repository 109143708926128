import React from 'react';
import { useTranslation } from 'react-i18next';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';

function TWRInfo() {
  const { t } = useTranslation();
  return (
    <Tooltip
      title={(
        <List sx={{ '&>li': { width: '250px' } }}>
          <ListItem>
            <ListItemText
              primaryTypographyProps={{ variant: 'body1', fontWeight: 600 }}
              primary={t('WEB_REPORTS.TWR_DISCLAIMER1')}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primaryTypographyProps={{ variant: 'body1', fontWeight: 600 }}
              primary={t('WEB_REPORTS.TWR_DISCLAIMER2')}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primaryTypographyProps={{ variant: 'body1', fontWeight: 600 }}
              primary={t('WEB_REPORTS.TWR_DISCLAIMER3')}
            />
          </ListItem>
        </List>
      )}
      color="primary"
      sx={{ marginLeft: 1 }}
    >
      <InfoIcon />
    </Tooltip>
  );
}

export default TWRInfo;
