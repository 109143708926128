import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/system';
import Button from '@mui/material/Button';
import PersonIcon from '@mui/icons-material/Person';

const NameText = styled('span')({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

// Displays user name, acts as a link to user settings.
function UserButton({ className, onClick }) {
  const { t } = useTranslation();
  const token = useSelector((state) => state.auth.token);
  const customerContactinfo = useSelector((state) => state.users.customerContactinfo);
  const inPersonnelFundView = useSelector((state) => state.UIstate.inPersonnelFundView);
  const settingsLink = inPersonnelFundView ? '/omattiedot' : '/yleisetasetukset';
  if (!token || !customerContactinfo) {
    return null;
  }
  return (
    <Button
      startIcon={<PersonIcon />}
      component={RouterLink}
      to={settingsLink}
      className={className}
      size="large"
      aria-label={`${t('GENERAL_SETTINGS')}: ${customerContactinfo.firstName} ${customerContactinfo.lastName}`}
      onClick={onClick}
      sx={{ width: '100%' }}
    >
      <NameText>{`${customerContactinfo.firstName} ${customerContactinfo.lastName}`}</NameText>
    </Button>
  );
}

UserButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};

UserButton.defaultProps = {
  className: null,
  onClick: () => {},
};

export default UserButton;
