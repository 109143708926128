import * as React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Card, CardContent, Typography, Paper,
} from '@mui/material';
import TWRInfo from './TWRInfo';

function ContentCard({
  ariaLabel, title, children, titleType, titleLinkTo, showTWRToolTip,
}) {
  return (
    <Card elevation={0} aria-label={ariaLabel}>
      {title && (
        <CardContent>
          <Typography
            variant={titleType}
            sx={{
              color: 'inherit',
              '& > a': { textDecoration: 'none', color: 'inherit' },
            }}
          >
            {titleLinkTo ? (
              <Link to={titleLinkTo}>{title}</Link>
            ) : title}
            {showTWRToolTip && <TWRInfo />}
          </Typography>
          <Paper className="cardBody" elevation={0}>{children}</Paper>
        </CardContent>
      )}
      {!title && (
        <CardContent sx={{ paddingTop: 0 }}>
          <Paper className="cardBody" elevation={0}>{children}</Paper>
        </CardContent>
      )}
    </Card>
  );
}

ContentCard.propTypes = {
  ariaLabel: PropTypes.string.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  children: PropTypes.node,
  titleType: PropTypes.string,
  titleLinkTo: PropTypes.string,
  showTWRToolTip: PropTypes.bool,
};

ContentCard.defaultProps = {
  children: null,
  titleType: 'h3',
  title: false,
  titleLinkTo: null,
  showTWRToolTip: false,
};

export default ContentCard;
