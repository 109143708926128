import React, { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// MUI
import { styled } from '@mui/system';
import useMediaQuery from '@mui/material/useMediaQuery';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import HomeIcon from '@mui/icons-material/Home';
import LockIcon from '@mui/icons-material/Lock';
import Badge from '@mui/material/Badge';

// Helper functions.
import { logUserOut } from '../../lib/authFunctions';

// Redux.
import { fetchCurrentUser, fetchCustomerUser } from '../../redux/authSlice';
import {
  fetchAccountInformation,
  resetPersonnelFundContactFormData,
  fetchCustomerContactInfo,
  resetMifidData,
  fetchIsMifidValid,
} from '../../redux/usersSlice';
import { resetPostStatus as resetCustomerServicePostStatus, fetchForceOpenNewsLetters, fetchCustomerConversations } from '../../redux/customerServiceSlice';
import { resetPostStatus as resetFundingPostStatus } from '../../redux/fundingAndWithdrawalsSlice';
import { resetWithdrawal } from '../../redux/fundUnitWithdrawalsSlice';
import { resetFundShares } from '../../redux/fundSharesSlice';
import { resetAllButSelectedPortfolios } from '../../redux/portfoliosSlice';
import { resetRequestStatus as resetOrderRequestStatuses } from '../../redux/ordersSlice';
import { setRedirectToAnnouncement, toggleHideSubNav } from '../../redux/UIcontrolSlice';

// Custom components.
import PrimaryButton from '../common/PrimaryButton';
import SubNavigationBar from './SubNavigationBar';
import NavigationSectionMenu from './NavigationSectionMenu';
import EvliLogo from '../../images/evliLogoBlue.png';
import EAILogo from '../../images/eaiLogoBlue.png';
import LanguageSelect from './LanguageSelect';
import MobileDrawer from './MobileDrawer';
import UserButton from './UserButton';
import BankerViewIndicator from './BankerViewIndicator';

// navigation constants
import {
  PERSONNELFUND_LINKS,
  SECTION_MENU_VALUES,
  ONLINE_SERVICE_LINKS,
  PUBLIC_LINKS,
  getSubnavigationLinks,
  isSubNavOf,
} from '../../lib/navigationLinks';

const CustomToolbar = styled(Toolbar)(({ theme }) => ({
  width: '100%',
  maxWidth: 1460,
  marginLeft: 'auto',
  marginRight: 'auto',
  [theme.breakpoints.down('md')]: {
    paddingRight: 5,
  },
}));

const LogoContainer = styled('div')({
  flexShrink: 1,
  minWidth: 135,
  maxWidth: 200,
  flexGrow: 1,
});

const LogoImg = styled('img', {
  shouldForwardProp: (prop) => prop !== 'personnelFund',
})(({ personnelFund, theme }) => ({
  height: personnelFund ? 43 : 45,
  verticalAlign: 'bottom',
  [theme.breakpoints.down('md')]: {
    height: personnelFund ? 38 : 33,
  },
}));

function NavigationBar() {
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const isDesktopScreen = useMediaQuery('(min-width:1060px)');
  const rightContainerBreakpoint = useMediaQuery('(min-width:1360px)');

  const hideSubNav = useSelector((state) => state.UIstate.hideSubNav);
  const token = useSelector((state) => state.auth.token);
  const currentUser = useSelector((state) => state.auth.currentUser);
  const customerId = useSelector((state) => state.auth.customerId);
  const inPersonnelFundView = useSelector((state) => state.UIstate.inPersonnelFundView);
  const inLoginView = useSelector((state) => state.UIstate.inLoginView);
  const hideNavigationBar = location.pathname === '/palkkiolaskuri';

  const userIsLoggedIn = Boolean(token);
  const inBankerView = currentUser && currentUser.isBanker && customerId;

  const forceOpenNewsLetters = useSelector(
    (state) => state.customerService.forceOpenNewsLetters,
  );
  const fetchingForceOpenNewsLetters = useSelector(
    (state) => state.customerService.fetchingForceOpenNewsLetters,
  );
  useEffect(() => {
    // forceOpenNewsLetters is null in initial state, after fetch there will be an array.
    // Checking that shows if the call is done already
    if (userIsLoggedIn && currentUser && currentUser.isEliteCustomer
      && !fetchingForceOpenNewsLetters && !forceOpenNewsLetters) {
      dispatch(fetchForceOpenNewsLetters(true)).unwrap().then((letters) => {
        if (letters.length > 0) dispatch(setRedirectToAnnouncement(true));
      });
    }
  }, [
    dispatch,
    location.pathname,
    forceOpenNewsLetters,
    fetchingForceOpenNewsLetters,
    userIsLoggedIn,
    currentUser,
  ]);

  useEffect(() => {
    if (userIsLoggedIn) {
      dispatch(fetchCurrentUser())
        .unwrap()
        .then((currentUserResult) => {
          // Fetch personnel fund data only if user is a personnel fund customer.
          if (currentUserResult?.isPersonnelFundCustomer) {
            dispatch(fetchAccountInformation());
          }
          if (currentUserResult?.isEliteCustomer) {
            dispatch(fetchCustomerConversations());
            dispatch(fetchCustomerContactInfo());
          }
        })
        .catch((error) => {
          console.error('Fetching current user failed', error);
          // TODO show error message.
        });
      dispatch(fetchIsMifidValid());
    }
  }, [dispatch, userIsLoggedIn]);

  // reset all possibly conflicting states in redux to avoid showing data from
  // wrong funds and/or irrelevant success messages
  useEffect(() => {
    dispatch(toggleHideSubNav(false));
    // don't reset if user is going to rename a portfolio or edit account numbers
    if (location.pathname !== '/yhteystiedot/muokkaus/yhteystiedot') {
      dispatch(resetAllButSelectedPortfolios());
    }
    dispatch(resetCustomerServicePostStatus());
    dispatch(resetFundingPostStatus());
    dispatch(resetFundShares());
    dispatch(resetWithdrawal());
    dispatch(resetMifidData());
    dispatch(resetOrderRequestStatuses());
    dispatch(resetPersonnelFundContactFormData());
  }, [dispatch, location.pathname]);

  // Fetch customer data if in banker view.
  useEffect(() => {
    if (inBankerView) {
      dispatch(fetchCustomerUser());
    }
  }, [dispatch, inBankerView]);

  const handleLogout = () => {
    logUserOut(dispatch);
  };

  // Define which section is active - moved from sectionMenu component
  const sectionMenuValue = SECTION_MENU_VALUES(t).PERSONNEL_FUND;

  // TODO: this is to silence errors from browsing Verkkopalvelu links.
  let currentPage = location.pathname;
  if (!(sectionMenuValue === SECTION_MENU_VALUES(t).PERSONNEL_FUND
    ? PERSONNELFUND_LINKS(t) : ONLINE_SERVICE_LINKS(t)).some(
    (link) => link.href === location.pathname,
  ) && !PUBLIC_LINKS(t).some((link) => link.href === location.pathname)) {
    // TODO: silence errors if current route not selectable from navbar.
    currentPage = false;
  }
  if (isSubNavOf(location.pathname, t)) {
    currentPage = isSubNavOf(isSubNavOf(location.pathname, t), t);
  }
  if (location.pathname.split('/')[1] === 'rahasto') {
    currentPage = '/rahastot';
  }

  const subNavigationLinks = getSubnavigationLinks(currentPage, t);

  const customerConversations = useSelector(
    (state) => state.customerService.customerConversations,
  );
  const unopenedConversations = customerConversations
    ? customerConversations.filter((message) => !message.isOpened)
    : [];

  const mapTabLinks = (links) => (links.map((link) => (links.indexOf(link) === 0 ? (
    <Tab
      icon={<HomeIcon />}
      key={link.label}
      className="navigation"
      value={link.href}
      component={RouterLink}
      to={link.href}
      aria-label={link.label}
    />
  ) : (
    <Tab
      className="navigation"
      key={link.label}
      label={unopenedConversations.length > 0 && link.href === '/asiakaspalvelu/etusivu' ? (
        <Badge
          badgeContent={unopenedConversations.length}
          color="green"
          componentsProps={{
            badge: {
              sx: {
                minWidth: '13px', height: '13px', width: '13px', mr: '-8px',
              },
            },
          }}
        >
          <Typography variant="navi">{link.label}</Typography>
        </Badge>
      ) : (
        <Typography variant="navi">{link.label}</Typography>
      )}
      value={link.href}
      component={RouterLink}
      to={link.href}
      aria-label={unopenedConversations.length > 0 && link.href === '/asiakaspalvelu/etusivu'
        ? `${link.label} ${unopenedConversations.length} ${t('ARIA.UNOPENED_MESSAGES')}` : link.label}
    />
  )))
  );

  // Determine frontpage path.
  let frontPagePath = '/';
  if (!inLoginView) {
    frontPagePath = '/henkilostorahasto';
  }

  // if user needs to update mifid info, hide navbar
  if (location.pathname === '/mifid') {
    return null;
  }

  if (userIsLoggedIn && !hideNavigationBar) {
    return (
      <Box role="navigation">
        <AppBar position="static" elevation={0} className="mainNavigation">
          <CustomToolbar>
            <LogoContainer>
              <RouterLink to={frontPagePath}>
                <LogoImg alt={t('ARIA.LOGO_IMAGE')} src={EAILogo} personnelFund={inPersonnelFundView} />
              </RouterLink>
            </LogoContainer>
            {isDesktopScreen && (
              <Box sx={{ flexShrink: 0 }}>
                <Tabs
                  value={currentPage}
                  indicatorColor="primary"
                  TabIndicatorProps={{ children: <span /> }}
                >
                  {sectionMenuValue === SECTION_MENU_VALUES(t).PERSONNEL_FUND
                    ? mapTabLinks(PERSONNELFUND_LINKS(t)) : mapTabLinks(ONLINE_SERVICE_LINKS(t))}
                </Tabs>
              </Box>
            )}
            <Box sx={{ flexGrow: 1 }} />
            {rightContainerBreakpoint ? (
              <>
                {inBankerView ? <BankerViewIndicator /> : (
                  <>
                    <Box>
                      <NavigationSectionMenu sectionMenuValue={sectionMenuValue} />
                    </Box>
                    <Box sx={{ minWidth: 170 }}>
                      <UserButton className="underline" />
                    </Box>
                  </>
                )}
                <Box>
                  <PrimaryButton
                    id="navbar-logout"
                    startIcon={<LockIcon />}
                    onClick={handleLogout}
                    ariaLabel={t('ARIA.LOGOUT')}
                    size="large"
                  >
                    {t('LOG_OUT')}
                  </PrimaryButton>
                </Box>
              </>
            ) : (
              <MobileDrawer />
            )}
          </CustomToolbar>
        </AppBar>
        {isDesktopScreen && !hideSubNav && (
          <SubNavigationBar links={subNavigationLinks} />
        )}
      </Box>
    );
  }
  return (
    <Box>
      {!hideNavigationBar && (
        <AppBar position="static" elevation={0} className="mainNavigation">
          <CustomToolbar>
            <LogoContainer>
              <RouterLink to={frontPagePath}>
                <LogoImg alt="Evli logo" src={EvliLogo} />
              </RouterLink>
            </LogoContainer>
            <Box sx={{ flexGrow: 1 }} />
            {isDesktopScreen ? (
              <Box sx={{ display: 'flex' }}>
                <LanguageSelect />
              </Box>
            ) : (
              <Box>
                <MobileDrawer />
              </Box>
            )}
          </CustomToolbar>
        </AppBar>
      )}
    </Box>
  );
}

export default NavigationBar;
