import vectors from './subNavigationVectors';

export const SECTION_MENU_VALUES = (t) => ({
  PERSONNEL_FUND: t('PERSONNEL_FUNDS'),
  WEB_SERVICE: t('ONLINE_SERVICE'),
});

export const PERSONNELFUND_MAIN_URLS = ['/henkilostorahasto', '/rahasto-osuus', '/omattiedot', '/asiakaspalvelu'];
export const PERSONNELFUND_SUB_URLS = ['/rahasto-osuus', '/nostoilmoitus', '/rahastointi-ilmoitus', '/maksettuosuus/'];
export const OTHER_PERSONNELFUND_URLS = ['/kirjauduuloshrj', '/maksettuosuus/'];

export const PERSONNELFUND_LINKS = (t) => ([
  {
    label: t('PERSONNELFUND.HOME'),
    href: '/henkilostorahasto',
  }, {
    label: t('PERSONNELFUND.MY_FUND'),
    href: '/rahasto-osuus',
  }, {
    label: t('PERSONNELFUND.PERSONAL_DETAILS'),
    href: '/omattiedot',
  }, {
    label: t('PERSONNELFUND.CONTACT'),
    href: '/asiakaspalvelu',
  },
]);

export const SUBNAV_PERSONNELFUND_MY_FUNDS_LINKS = (t) => ([
  {
    label: t('PERSONNELFUND.MY_FUND_UNIT'),
    href: '/rahasto-osuus',
  }, {
    label: t('PERSONNELFUND.FUND_UNIT_WITHDRAW'),
    href: '/nostoilmoitus',
  }, {
    label: t('PERSONNELFUND.FUNDING_AND_WITHDRAWAL_NOTIFICATION'),
    href: '/rahastointi-ilmoitus',
  },
]);

export const ONLINE_SERVICE_LINKS = (t) => ([
  {
    label: t('HOME'),
    href: '/varallisuus',
  }, {
    label: t('OWN_PORTFOLIOS'),
    href: '/salkkuyhteenveto',
  }, {
    label: t('ORDERS'),
    href: '/toimeksiannot',
  }, {
    label: t('FUND_SEARCH'),
    href: '/rahastot',
  }, {
    label: t('CUSTOMERSERVICE.TITLE'),
    href: '/asiakaspalvelu/etusivu',
  }, {
    label: t('PERSONAL_INFO'),
    href: '/yleisetasetukset',
  },
]);

export const SUBNAV_OWN_PORTFOLIOS_LINKS = (t) => (
  [
    {
      label: t('SUMMARY'),
      href: '/salkkuyhteenveto',
    }, {
      label: t('WEB_REPORTS.PORTFOLIO_REPORT'),
      href: '/salkkuraportti',
    }, {
      label: t('WEB_REPORTS.TRANSACTION_REPORT'),
      href: '/tapahtumaraportti',
    }, {
      label: t('WEB_REPORTS.REALIZED_PROFITS'),
      href: '/tuottoraportti',
    }, {
      label: t('ACCOUNT_STATEMENT'),
      href: '/tilioteraportti',
    },
  ]);

export const SUBNAV_FUND_SEARCH_LINKS = (t) => ([
  {
    label: t('FUND_SEARCH'),
    href: '/rahastot',
  }, {
    label: t('MONTHLYREPORTS.FUNDS_SEARCH'),
    href: '/rahastohaku',
  }, {
    label: t('MONTHLYREPORTS.REPORTS'),
    href: '/kuukausikatsaukset',
  },
]);

export const SUBNAV_CUSTOMER_SERVICE_LINKS = (t) => ([
  {
    label: t('CUSTOMERSERVICE.TITLE'),
    href: '/asiakaspalvelu/etusivu',
  }, {
    label: t('CUSTOMERSERVICE.MESSAGES.TITLE'),
    href: '/asiakaspalvelu/viestit',
  }, {
    label: t('CUSTOMERSERVICE.ANNOUNCEMENTS.TITLE'),
    href: '/asiakaspalvelu/tiedotteet',
  }, {
    label: t('CUSTOMERSERVICE.CONTACTS.TITLE'),
    href: '/asiakaspalvelu/yhteystiedot',
  },
]);

export const SUBNAV_PERSONAL_INFO_LINKS = (t) => ([
  {
    label: t('GENERAL_SETTINGS'),
    href: '/yleisetasetukset',
  }, {
    label: t('CUSTOMER_INFORMATION'),
    href: '/yhteystiedot',
  }, {
    label: t('CUSTOMERSERVICE.DOCUMENTS.TITLE'),
    href: '/asiakaspalvelu/asiakirjat',
  }, {
    label: t('CUSTOMERSERVICE.MONTHLYSAVINGS.TITLE'),
    href: '/asiakaspalvelu/kuukausisaastot',
  }, {
    label: t('GET_TO_KNOW_INFORMATION'),
    href: '/verovelvollisuus',
  }, {
    label: t('NAME_YOUR_OWN_PORTFOLIOS'),
    href: '/nimea-omat-salkut',
  },
]);

export const SUBNAV_ORDERS_LINKS = (t) => ([
  {
    label: t('ORDERS'),
    href: '/toimeksiannot',
    cardLabel: t('BROWSE_ORDERS'),
    svg: vectors.browse(t('ORDERS')),
  }, {
    label: t('ORDERS_NAV.BUY_FUND'),
    href: '/uusiToimeksianto/merkinta',
    svg: vectors.buy(t('ORDERS_NAV.BUY_FUND')),
  }, {
    label: t('ORDERS_NAV.SELL_FUND'),
    href: '/uusiToimeksianto/lunastus',
    svg: vectors.sell(t('ORDERS_NAV.SELL_FUND')),
  }, {
    label: t('ORDERS_NAV.WITHRAW_MONEY'),
    href: '/uusiNosto',
    svg: vectors.withdraw(t('ORDERS_NAV.WITHRAW_MONEY')),
  }, {
    label: t('ORDERS_NAV.DEPOSIT_MONEY'),
    href: '/uusiTalletus',
    svg: vectors.deposit(t('ORDERS_NAV.DEPOSIT_MONEY')),
  }, {
    label: t('NEWPORTFOLIO.NEWPORTFOLIO'),
    href: '/uusiSalkku',
    svg: vectors.new(t('NEWPORTFOLIO.NEWPORTFOLIO')),
  },
]);

export const FUND_INFO_LINKS = (t, instrumentId) => ([
  {
    label: t('ORDERS_NAV.BUY_FUND'),
    href: `/uusiToimeksianto/merkinta/${instrumentId}/rahasto`,
    svg: vectors.buy(t('ORDERS_NAV.BUY_FUND')),
  },
  {
    label: t('ORDERS_MONTHLY.START_MONTHLY_SAVING'),
    href: `/uusiToimeksianto/merkinta/${instrumentId}/rahasto`,
    svg: vectors.monthlySaving(t('ORDERS_MONTHLY.START_MONTHLY_SAVING')),
  },
]);

export const FUND_INFO_PUBLIC_LINKS = (t, instrumentId) => ([
  {
    label: t('ORDERS_NAV.BUY_FUND'),
    href: `/ostaRahastoa/kirjautuminen/${instrumentId}`,
    svg: vectors.buy(t('ORDERS_NAV.BUY_FUND')),
  },
  {
    label: t('ORDERS_MONTHLY.START_MONTHLY_SAVING'),
    href: `/uusiToimeksianto/merkinta/${instrumentId}/rahasto`,
    svg: vectors.monthlySaving(t('ORDERS_MONTHLY.START_MONTHLY_SAVING')),
  },
]);

export const PUBLIC_LINKS = (t) => {
  const translate = t || ((value) => value);
  return ([
    {
      label: translate('MONTHLYREPORTS.FUNDS_SEARCH'),
      href: '/julkinen/rahastohaku',
    },
    /* {
      label: translate('MONTHLYREPORTS.REPORTS'),
      href: '/julkinen/kuukausikatsaukset',
    }, */
  ]);
};

export const SUBNAV_PUBLIC_FUNDS = (t) => {
  const translate = t || ((value) => value);
  return ([
    {
      label: translate('MONTHLYREPORTS.FUNDS_SEARCH'),
      href: '/julkinen/rahastohaku',
    },
    {
      label: 'rahastot',
      href: '/julkinen/rahasto',
    },
  ]);
};

const subnavigationLists = (t) => ([
  SUBNAV_PERSONNELFUND_MY_FUNDS_LINKS(t),
  SUBNAV_OWN_PORTFOLIOS_LINKS(t),
  SUBNAV_FUND_SEARCH_LINKS(t),
  SUBNAV_CUSTOMER_SERVICE_LINKS(t),
  SUBNAV_ORDERS_LINKS(t),
  SUBNAV_PERSONAL_INFO_LINKS(t),
  SUBNAV_PUBLIC_FUNDS(t),
]);

export const getSubnavigationLinks = (location, t) => subnavigationLists(t).find(
  (list) => list.map((link) => link.href).includes(location),
) || [];

export const urlIsChildOf = (currentUrl, compareUrl) => {
  const urlAsArray = currentUrl.split('/');
  const possibleUrls = urlAsArray.map((string) => urlAsArray.slice(0, urlAsArray.indexOf(string) + 1).join('/'));
  // eslint-disable-next-line consistent-return
  urlAsArray.forEach((string) => {
    if (urlAsArray.slice(0, urlAsArray.indexOf(string) + 1).join('/') === compareUrl) {
      return true;
    }
  });
  return possibleUrls.includes(compareUrl) ? possibleUrls.find((url) => url === compareUrl) : null;
};

export const isSubNavOf = (location, t) => {
  const subNavs = subnavigationLists(t).find(
    (list) => list.some((link) => urlIsChildOf(location, link.href)),
  );
  return subNavs !== undefined ? subNavs[0].href : null;
};

export const isPublicAddress = (url) => url.split('/')[1] === 'julkinen';

export const isBuyFundPipeAddress = (url) => url.split('/')[1] === 'ostaRahastoa';
