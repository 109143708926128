import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { format } from 'date-fns';
import axios from '../api/axiosSetup';
import { sortObjectsByValueOf, translateInstrumentName } from '../lib/commonFunctions';

export const fetchPortfolios = createAsyncThunk(
  'portfolios/fetchPortfolios',
  async (viewType, thunkAPI) => {
    const { customerId } = thunkAPI.getState().auth;
    let url = `/api/portfolios${customerId ? `?customerId=${customerId}` : ''}`;
    if (viewType) {
      url = `/api/portfolios?viewType=${viewType}${customerId ? `&customerId=${customerId}` : ''}`;
    }
    const response = await axios.get(url, { options: { cache: true } });
    const handledResponse = response.data.map((customer) => (
      { ...customer, hasOwnerRole: customer.portfolios.some((portfolio) => portfolio.role === 'Owner') }
    ));
    return handledResponse;
  },
);

export const fetchPortfolioById = createAsyncThunk(
  'portfolios/fetchPortfolioById',
  async (portfolioId, thunkAPI) => {
    const { customerId } = thunkAPI.getState().auth;
    const response = await axios.get(`/api/portfolios/${portfolioId}${customerId ? `?customerId=${customerId}` : ''}`);
    return response.data;
  },
);

export const fetchForCurrentUser = createAsyncThunk(
  'portfolios/fetchForCurrentUser',
  async (viewType) => {
    let response = await axios.get('/api/portfolios', { options: { cache: true } });
    if (viewType) {
      response = await axios.get(`/api/portfolios?viewType=${viewType}`);
    }
    const orderedResponse = sortObjectsByValueOf(
      response.data,
      'customerName',
    );
    return orderedResponse;
  },
);

export const fetchDefaultPortfolio = createAsyncThunk(
  'portfolios/fetchDefaultPortfolio',
  async (_, thunkAPI) => {
    const { customerId } = thunkAPI.getState().auth;
    const response = await axios.get(`/api/portfolios/defaultPortfolio${customerId ? `?customerId=${customerId}` : ''}`);
    return response.data;
  },
);

export const fetchHoldingsDistributions = createAsyncThunk(
  'portfolios/fetchHoldingsDistributions',
  async ({ date, externalIds }, thunkAPI) => {
    const { customerId } = thunkAPI.getState().auth;
    const formattedDate = format(new Date(date), 'yyyy-M-d');
    const response = await axios.get(
      `/api/portfolios/holdingsDistributions?date=${formattedDate}${customerId ? `&customerId=${customerId}` : ''}`,
      { params: { portfolioIds: externalIds } },
    );
    const orderedResponse = sortObjectsByValueOf(response.data, 'weight', 'assetType', false);
    return orderedResponse;
  },
);

export const fetchRiskDistributions = createAsyncThunk(
  'portfolios/fetchRiskDistributions',
  async ({ date, externalIds }, thunkAPI) => {
    const { customerId } = thunkAPI.getState().auth;
    const formattedDate = format(new Date(date), 'yyyy-M-d');
    const response = await axios.get(
      `/api/portfolios/riskDistributions?date=${formattedDate}&portfolioIds=${externalIds}${customerId ? `&customerId=${customerId}` : ''}`,
    );
    return response.data;
  },
);

export const fetchOneCashWithoutReserved = createAsyncThunk(
  'portfolios/fetchOneCashWithoutReserved',
  async ({ portfolioId, addDraft }, thunkAPI) => {
    const { customerId } = thunkAPI.getState().auth;
    const addDraftText = addDraft ? '/draft' : '/confirmed';
    const response = await axios.get(
      `/api/portfolios/${portfolioId}/cashWithoutReserved${addDraftText}${customerId ? `?customerId=${customerId}` : ''}`,
    );
    return { value: response.data, id: portfolioId, status: 'fulfilled' };
  },
);

export const fetchDisposableCash = createAsyncThunk(
  'portfolios/fetchDisposableCash',
  async ({ portfolioId, addDraft }, thunkAPI) => {
    const { customerId } = thunkAPI.getState().auth;
    const addDraftText = addDraft ? '/draft' : '/confirmed';
    const response = await axios.get(
      `/api/portfolios/${portfolioId}/cashWithoutReserved${addDraftText}${customerId ? `?customerId=${customerId}` : ''}`,
    );
    return { value: response.data, id: portfolioId, status: 'fulfilled' };
  },
);

export const fetchOneEstimatedCashFromOpenSells = createAsyncThunk(
  'portfolios/fetchOneEstimatedCashFromOpenSells',
  async ({ portfolioId }, thunkAPI) => {
    const { customerId } = thunkAPI.getState().auth;
    const response = await axios.get(
      `/api/portfolios/${portfolioId}/estimatedCashFromSells${customerId ? `?customerId=${customerId}` : ''}`,
    );
    return { value: response.data, id: portfolioId, status: 'fulfilled' };
  },
);

export const fetchCashForOrderTables = createAsyncThunk(
  'portfolios/fetchCashForOrderTables',
  async ({ portfolioList, addDraft }, thunkAPI) => {
    const { customerId } = thunkAPI.getState().auth;
    const addDraftText = addDraft ? '/draft' : '/confirmed';
    const portfolioIdList = portfolioList.map((portfolio) => ({
      portfolioId: portfolio.id,
      portfolioGuid: portfolio.portfolioId,
    }));
    const sellsPromises = portfolioIdList.map((idObject) => axios
      .get(`/api/portfolios/${idObject.portfolioId}/estimatedCashFromSells${customerId ? `?customerId=${customerId}` : ''}`)
      .then((res) => ({ value: res.data, id: idObject.portfolioId })));
    const noReservedPromises = portfolioIdList.map((idObject) => axios
      .get(
        `/api/portfolios/${idObject.portfolioId}/cashWithoutReserved${addDraftText}${customerId ? `?customerId=${customerId}` : ''}`,
        {
          params: {
            portfolioGuid: idObject.portfolioGuid, // TODO: poista?
          },
        },
      )
      .then((res) => ({ value: res.data, id: idObject.portfolioId })));
    const estimatedCashFromOpenSells = await Promise.allSettled(sellsPromises);
    const cashWithoutReserved = await Promise.allSettled(noReservedPromises);
    const processedCashFromSells = estimatedCashFromOpenSells.map(
      (result) => (result.value !== undefined
        ? { ...result.value, status: result.status }
        : { status: result.status }),
    );
    const processedWithoutReserved = cashWithoutReserved.map((result) => (result.value !== undefined
      ? { ...result.value, status: result.status }
      : { status: result.status }));
    return {
      estimatedCashFromOpenSells: processedCashFromSells,
      cashWithoutReserved: processedWithoutReserved,
    };
  },
);

export const removeCustomersWithCustomerManagedPortfolios = createAsyncThunk(
  'portfolios/removeCustomersWithCustomerManagedPortfolios',
  async (customerList) => {
    const response = await axios.post('/api/portfolios/removecustomerswithcustomermanagedportfolios', customerList);
    const orderedResponse = sortObjectsByValueOf(
      response.data,
      'customerName',
    );
    return orderedResponse;
  },
);

export const postNewUserPortfolio = createAsyncThunk(
  'portfolios/postNewUserPortfolio',
  async ({ portfolioName, accountIdGuid }, thunkAPI) => {
    const { customerId } = thunkAPI.getState().auth;
    if (customerId) {
      return thunkAPI.rejectWithValue('Forbidden in banker view');
    }
    const response = await axios.post(
      `/api/portfolios/createnewportfolio/${accountIdGuid}/${portfolioName}`,
    );
    return { status: response.status, customerAccountId: accountIdGuid, ...response.data };
  },
);

export const fetchAssetChanges = createAsyncThunk(
  'portfolios/fetchAssetChanges',
  async ({
    portfolioId, startDate, endDate,
  }, thunkAPI) => {
    const { customerId } = thunkAPI.getState().auth;
    const formattedStartDate = format(new Date(startDate), 'yyyy-M-d');
    const formattedEndDate = format(new Date(endDate), 'yyyy-M-d');
    const firstParams = {
      endDate: formattedEndDate,
      portfolioIds: portfolioId,
      startDate: formattedStartDate,
    };
    const assetChange = await axios.get(
      `/api/portfolios/assetChanges${customerId ? `?customerId=${customerId}` : ''}`,
      { params: firstParams },
    );
    return assetChange.data;
  },
);

export const fetchInitialAssetChanges = createAsyncThunk(
  'portfolios/fetchInitialAssetChanges',
  async ({
    portfolioId, endDate,
  }, thunkAPI) => {
    const { customerId } = thunkAPI.getState().auth;
    const formattedEndDate = format(new Date(endDate), 'yyyy-M-d');
    const initialAssetChange = await axios.get(
      `/api/portfolios/wireInDate/${portfolioId}${customerId ? `?customerId=${customerId}` : ''}`,
    )
      .then((response) => {
        const params = {
          endDate: formattedEndDate,
          portfolioIds: portfolioId,
          startDate: format(new Date(response.data), 'yyyy-M-d'),
        };
        return axios.get(`/api/portfolios/assetChanges${customerId ? `?customerId=${customerId}` : ''}`, { params });
      });
    return initialAssetChange.data;
  },
);

export const fetchReturnSummary = createAsyncThunk(
  'portfolios/fetchReturnSummary',
  async ({ portfolioId, date, portfolioIds }, thunkAPI) => {
    const { customerId } = thunkAPI.getState().auth;
    const formattedDate = format(new Date(date), 'yyyy-M-d');
    if (portfolioIds) {
      const response = await axios.get(
        `api/portfolios/returnSummaries/${formattedDate}${customerId ? `?customerId=${customerId}` : ''}`,
        { params: { portfolioIds } },
      );
      return response.data;
    }
    const response = await axios.get(
      `api/portfolios/${portfolioId}/returnSummaries/${formattedDate}${customerId ? `?customerId=${customerId}` : ''}`,
    );
    return response.data;
  },
);

export const fetchReturnReferenceSummary = createAsyncThunk(
  'portfolios/fetchReturnReferenceSummary',
  async ({ portfolioId, date }, thunkAPI) => {
    const { customerId } = thunkAPI.getState().auth;
    const formattedDate = format(new Date(date), 'yyyy-M-d');
    const response = await axios.get(
      `api/portfolios/${portfolioId}/returnReferenceSummaries/${formattedDate}${customerId ? `?customerId=${customerId}` : ''}`,
    );
    return response.data;
  },
);

export const fetchShowReturnReferenceSummary = createAsyncThunk(
  'portfolios/fetchShowReturnReferenceSummary',
  async (portfolioId) => {
    const response = await axios.get(`api/portfolios/showReturnReferenceSummaries/${portfolioId}`);
    return response.data;
  },
);

export const fetchPortfolioReferenceNumber = createAsyncThunk(
  'portfolios/fetchPortfolioReferenceNumber',
  async (portfolioId, thunkAPI) => {
    const { customerId } = thunkAPI.getState().auth;
    const response = await axios.get(`api/portfolios/referenceNumber/${portfolioId}${customerId ? `?customerId=${customerId}` : ''}`);
    return response.data;
  },
);

export const fetchIndexedReturns = createAsyncThunk(
  'portfolios/fetchIndexedReturns',
  async ({
    portfolioId,
    startDate,
    endDate,
    portfolioIds,
  }, thunkAPI) => {
    const { customerId } = thunkAPI.getState().auth;
    const formattedEndDate = format(new Date(endDate), 'yyyy-M-d');
    const formattedStartDate = format(new Date(startDate), 'yyyy-M-d');
    if (portfolioIds) {
      const response = await axios.get(
        `api/portfolios/indexedReturns/${formattedStartDate}/${formattedEndDate}${customerId ? `?customerId=${customerId}` : ''}`,
        { params: { portfolioIds } },
      );
      return response.data;
    }
    const response = await axios.get(
      `api/portfolios/${portfolioId}/indexedReturns/${formattedStartDate}/${formattedEndDate}${customerId ? `?customerId=${customerId}` : ''}`,
    );
    return response.data;
  },
);

export const fetchPartnerMappingForPortfolio = createAsyncThunk(
  'portfolios/fetchPartnerMappingForPortfolio',
  async (portfolioId, thunkAPI) => {
    const { customerId } = thunkAPI.getState().auth;
    const response = await axios.get(`api/portfolios/${portfolioId}/partnerMapping${customerId ? `?customerId=${customerId}` : ''}`);
    return response.data;
  },
);

export const fetchSellable = createAsyncThunk('portfolios/fetchSellable', async ({ portfolioId, date }, thunkAPI) => {
  const { customerId } = thunkAPI.getState().auth;
  const url = date ? `api/portfolios/${portfolioId}/holdings/sellable/${format(new Date(date), 'yyyy-M-d')}${customerId ? `?customerId=${customerId}` : ''}`
    : `api/portfolios/${portfolioId}/holdings/sellable${customerId ? `?customerId=${customerId}` : ''}`;
  const response = await axios.get(url);
  return response.data;
});

export const fetchMarketStartValues = createAsyncThunk(
  'portfolios/fetchMarketStartValues',
  async ({ portfolioIds, startDate }, thunkAPI) => {
    const { customerId } = thunkAPI.getState().auth;
    const formattedDate = format(new Date(startDate), 'yyyy-M-d');
    const response = await axios.get(`/api/portfolios/marketValues${customerId ? `?customerId=${customerId}` : ''}`,
      { params: { portfolioIds, date: formattedDate } });
    return response.data;
  },
);

export const fetchMarketEndValues = createAsyncThunk(
  'portfolios/fetchMarketEndValues',
  async ({ portfolioIds, endDate }, thunkAPI) => {
    const { customerId } = thunkAPI.getState().auth;
    const formattedDate = format(new Date(endDate), 'yyyy-M-d');
    const response = await axios.get(`/api/portfolios/marketValues${customerId ? `?customerId=${customerId}` : ''}`,
      { params: { portfolioIds, date: formattedDate } });
    return response.data;
  },
);

export const fetchWireInDate = createAsyncThunk('portfolios/fetchWireInDate', async (portfolioId, thunkAPI) => {
  const { customerId } = thunkAPI.getState().auth;
  const response = await axios.get(`api/portfolios/wireInDate/${portfolioId}${customerId ? `?customerId=${customerId}` : ''}`);
  return response.data;
});

export const fetchHoldings = createAsyncThunk('portfolios/fetchHoldings', async ({ portfolioId, date }, thunkAPI) => {
  const { customerId } = thunkAPI.getState().auth;
  const formattedDate = date ? `/${format(new Date(date), 'yyyy-M-d')}` : '';
  const response = await axios.get(`api/portfolios/${portfolioId}/holdings${formattedDate}${customerId ? `?customerId=${customerId}` : ''}`);
  const translatedResponse = response.data.map((element) => {
    const { instrument } = element;
    instrument.name = translateInstrumentName(instrument.name);
    return {
      ...element,
      instrument,
    };
  });
  return translatedResponse;
});

export const fetchPurchases = createAsyncThunk('portfolios/fetchPurchases', async ({ portfolioId, date }, thunkAPI) => {
  const { customerId } = thunkAPI.getState().auth;
  const formattedDate = date ? `/${format(new Date(date), 'yyyy-M-d')}` : '';
  const response = await axios.get(`api/portfolios/${portfolioId}/purchases${formattedDate}${customerId ? `?customerId=${customerId}` : ''}`);
  return response.data;
});

export const fetchLastHistoricalDate = createAsyncThunk('portfolios/fetchLastHistoricalDate', async (portfolioId, thunkAPI) => {
  const { customerId } = thunkAPI.getState().auth;
  const response = await axios.get(`api/portfolios/lastHistoricalDate/${portfolioId}${customerId ? `?customerId=${customerId}` : ''}`);
  return response.data;
});

export const savePortfolioName = createAsyncThunk('portfolios/savePortfolioName', async (portfolioNameSetting) => {
  const response = await axios.put('api/portfolios/setPortfolioName', portfolioNameSetting);
  return response.status;
});

export const removeCustomersWithNonAccessableCustomerManagedPortfolios = createAsyncThunk(
  'portfolios/removeCustomersWithNonAccessableCustomerManagedPortfolios',
  async (customers) => {
    const response = await axios.post('api/portfolios/checkCustomerManagedPortfolios', customers);
    return response.data;
  },
);

export const fetchCustomerAccountPortfolios = createAsyncThunk('portfolios/fetchCustomerAccountPortfolios', async ({ accountId }) => {
  const response = await axios.get(`/api/portfolios/customerPortfolios/${accountId}`);
  return response.data;
});

const initialState = {
  portfolios: [], // Portfolios are listed per customer.
  fetchingPortfolios: false,
  defaultPortfolio: null,
  fetchingDefaultPortfolio: false,
  holdingsDistributions: [],
  fetchingHoldingsDistributions: false,
  riskDistributions: [],
  fetchingRiskDistributions: false,
  selectedPortfolios: [],
  selectedMonthlyPortolios: [],
  fetchingCashForOrderTables: false,
  cashForOrderTables: {
    estimatedCashFromOpenSells: [],
    cashWithoutReserved: [],
  },
  fetchingCashBalance: false,
  cashBalance: null,
  fetchingDisposableCash: false,
  disposableCash: null,
  fetchingEstimatedCashFromOpenSells: false,
  estimatedCashFromOpenSells: null,
  currentUserCustomers: [],
  fetchingCurrentUserCustomers: false,
  selectedCustomers: [],
  postingNewPortfolio: false,
  newPortfolio: { status: null },
  additionalInfoById: null,
  fetchingAdditionalInfo: false,
  assetChanges: null,
  fetchingAssetChanges: false,
  initialAssetChanges: null,
  fetchingInitialAssetChanges: false,
  returnSummary: null,
  fetchingReturnSummary: false,
  returnReferenceSummary: null,
  fetchingReturnReferenceSummary: false,
  showReturnReferenceSummary: null,
  fetchingShowReturnReferenceSummary: false,
  fetchingPortfolioReferenceNumber: false,
  portfolioReferenceNumber: null,
  fetchingIndexedReturns: false,
  indexedReturns: [],
  fetchingPartnerMappingForPortfolio: false,
  partnerMappingForPortfolio: null,
  sellableHoldings: [],
  fetchingSellableHoldings: false,
  marketStartValues: [],
  fetchingMarketStartValues: false,
  marketEndValues: [],
  fetchingMarketEndValues: false,
  fetchingWireInDate: false,
  wireInDate: null,
  showPurchases: false,
  groupingSelections: [],
  fetchingHoldings: false,
  holdings: [],
  fetchingPurchases: false,
  purchases: [],
  portfolioReportViewArray: [],
  savingPortfolioName: false,
  savePortfolioNameStatus: null,
  lastHistoricalDate: null,
  fetchingLastHistoricalDate: false,
  fetchingCustomerAccountPortfolios: false,
  customerAccountPortfolios: [],
};

export const portfoliosSlice = createSlice({
  name: 'portfolios',
  initialState,
  reducers: {
    resetSavePortfolioNameStatus: (state) => {
      state.savePortfolioNameStatus = null;
    },
    setPortfolioReportViewArray: (state, { payload }) => {
      state.portfolioReportViewArray = payload;
    },
    resetPortfolios: () => initialState,
    resetAllButSelectedPortfolios: (state) => {
      Object.keys(initialState).forEach((key) => {
        if (key !== 'selectedPortfolios') {
          state[key] = initialState[key];
        }
      });
    },
    selectPortfolio: (state, { payload }) => {
      if (!state.selectedPortfolios.includes(payload)) {
        state.selectedPortfolios = [...state.selectedPortfolios, payload];
      }
    },
    selectMonthlyPortfolio: (state, { payload }) => {
      if (!state.selectedMonthlyPortolios.includes(payload)) {
        state.selectedMonthlyPortolios = [...state.selectedMonthlyPortolios, payload];
      }
    },
    unSelectPortfolio: (state, { payload }) => {
      if (state.selectedPortfolios.includes(payload)) {
        state.selectedPortfolios = state.selectedPortfolios.filter(
          (portfolio) => portfolio !== payload,
        );
      }
    },
    unSelectMonthlyPortfolio: (state, { payload }) => {
      if (state.selectedMonthlyPortolios.includes(payload)) {
        state.selectedMonthlyPortolios = state.selectedMonthlyPortolios.filter(
          (portfolio) => portfolio !== payload,
        );
      }
    },
    unSelectAllPortfolios: (state) => {
      state.selectedPortfolios = [];
    },
    unSelectAllMonthlyPortfolios: (state) => {
      state.selectedMonthlyPortolios = [];
    },
    sortPortfolios: (state, { payload }) => {
      state.portfolios = sortObjectsByValueOf(state.portfolios, payload);
    },
    selectCustomer: (state, { payload }) => {
      if (
        !state.selectedCustomers
          .map((customer) => customer.customerAccountId)
          .includes(payload.customerAccountId)
      ) {
        state.selectedCustomers = [...state.selectedCustomers, payload];
      }
    },
    unSelectCustomer: (state, { payload }) => {
      if (
        state.selectedCustomers.selectedCustomers
          .map((customer) => customer.customerAccountId)
          .includes(payload.customerAccountId)
      ) {
        state.selectedCustomers = state.selectedCustomers.filter(
          (customer) => customer.customerAccountId !== payload.customerAccountId,
        );
      }
    },
    setReportGrouping: (state, { payload }) => {
      state.groupingSelections = payload;
    },
    toggleShowPurchases: (state, { payload }) => {
      state.showPurchases = payload;
    },
  },
  extraReducers: {
    [fetchPortfolios.pending]: (state) => {
      state.fetchingPortfolios = true;
    },
    [fetchPortfolios.fulfilled]: (state, { payload }) => {
      state.fetchingPortfolios = false;
      state.portfolios = payload;
    },
    [fetchPortfolios.rejected]: (state) => {
      state.fetchingPortfolios = false;
    },
    [fetchPortfolioById.pending]: (state) => {
      state.fetchingAdditionalInfo = true;
    },
    [fetchPortfolioById.fulfilled]: (state, { payload }) => {
      state.fetchingAdditionalInfo = false;
      state.additionalInfoById = payload;
    },
    [fetchPortfolioById.rejected]: (state) => {
      state.fetchingAdditionalInfo = false;
    },
    [fetchDefaultPortfolio.pending]: (state) => {
      state.fetchingDefaultPortfolio = true;
    },
    [fetchDefaultPortfolio.fulfilled]: (state, { payload }) => {
      state.fetchingDefaultPortfolio = false;
      state.defaultPortfolio = payload;
    },
    [fetchDefaultPortfolio.rejected]: (state) => {
      state.fetchingDefaultPortfolio = false;
    },
    [fetchHoldingsDistributions.pending]: (state) => {
      state.holdingsDistributions = [];
      state.fetchingHoldingsDistributions = true;
    },
    [fetchHoldingsDistributions.fulfilled]: (state, { payload }) => {
      state.holdingsDistributions = payload;
      state.fetchingHoldingsDistributions = false;
    },
    [fetchHoldingsDistributions.rejected]: (state) => {
      state.fetchingHoldingsDistributions = false;
    },
    [fetchRiskDistributions.pending]: (state) => {
      state.riskDistributions = [];
      state.fetchingRiskDistributions = true;
    },
    [fetchRiskDistributions.fulfilled]: (state, { payload }) => {
      state.riskDistributions = payload;
      state.fetchingRiskDistributions = false;
    },
    [fetchRiskDistributions.rejected]: (state) => {
      state.fetchingRiskDistributions = false;
    },
    [fetchOneCashWithoutReserved.pending]: (state) => {
      state.fetchingCashBalance = true;
      state.cashBalance = null;
    },
    [fetchOneCashWithoutReserved.fulfilled]: (state, { payload }) => {
      state.fetchingCashBalance = false;
      state.cashBalance = payload;
    },
    [fetchOneCashWithoutReserved.rejected]: (state) => {
      state.fetchingCashBalance = false;
    },
    [fetchDisposableCash.pending]: (state) => {
      state.fetchingDisposableCash = true;
      state.disposableCash = null;
    },
    [fetchDisposableCash.fulfilled]: (state, { payload }) => {
      state.fetchingDisposableCash = false;
      state.disposableCash = payload;
    },
    [fetchDisposableCash.rejected]: (state) => {
      state.fetchingDisposableCash = false;
    },
    [fetchOneEstimatedCashFromOpenSells.pending]: (state) => {
      state.fetchingEstimatedCashFromOpenSells = true;
      state.estimatedCashFromOpenSells = null;
    },
    [fetchOneEstimatedCashFromOpenSells.fulfilled]: (state, { payload }) => {
      state.fetchingEstimatedCashFromOpenSells = false;
      state.estimatedCashFromOpenSells = payload;
    },
    [fetchOneEstimatedCashFromOpenSells.rejected]: (state) => {
      state.fetchingEstimatedCashFromOpenSells = false;
    },
    [fetchCashForOrderTables.pending]: (state) => {
      state.fetchingCashForOrderTables = true;
    },
    [fetchCashForOrderTables.fulfilled]: (state, { payload }) => {
      state.fetchingCashForOrderTables = false;
      state.cashForOrderTables = payload;
    },
    [fetchCashForOrderTables.rejected]: (state) => {
      state.fetchingCashForOrderTables = false;
    },
    [removeCustomersWithCustomerManagedPortfolios.pending]: (state) => {
      state.fetchingPortfolios = true;
    },
    [removeCustomersWithCustomerManagedPortfolios.fulfilled]: (state, { payload }) => {
      state.fetchingPortfolios = false;
      state.portfolios = payload;
    },
    [removeCustomersWithCustomerManagedPortfolios.rejected]: (state) => {
      state.fetchingPortfolios = false;
    },
    [postNewUserPortfolio.pending]: (state) => {
      state.postingNewPortfolio = true;
    },
    [postNewUserPortfolio.fulfilled]: (state, { payload }) => {
      state.postingNewPortfolio = false;
      state.newPortfolio = payload;
    },
    [postNewUserPortfolio.rejected]: (state) => {
      state.postingNewPortfolio = false;
    },
    [fetchAssetChanges.pending]: (state) => {
      state.assetChanges = null;
      state.fetchingAssetChanges = true;
    },
    [fetchAssetChanges.fulfilled]: (state, { payload }) => {
      state.fetchingAssetChanges = false;
      state.assetChanges = payload;
    },
    [fetchAssetChanges.rejected]: (state) => {
      state.fetchingAssetChanges = false;
    },
    [fetchInitialAssetChanges.pending]: (state) => {
      state.initialAssetChange = null;
      state.fetchingInitialAssetChanges = true;
    },
    [fetchInitialAssetChanges.fulfilled]: (state, { payload }) => {
      state.fetchingInitialAssetChanges = false;
      state.initialAssetChanges = payload;
    },
    [fetchInitialAssetChanges.rejected]: (state) => {
      state.fetchingInitialAssetChanges = false;
    },
    [fetchForCurrentUser.pending]: (state) => {
      state.fetchingCurrentUserCustomers = true;
    },
    [fetchForCurrentUser.fulfilled]: (state, { payload }) => {
      state.fetchingCurrentUserCustomers = false;
      state.currentUserCustomers = payload;
    },
    [fetchForCurrentUser.rejected]: (state) => {
      state.fetchingCurrentUserCustomers = false;
    },
    [fetchReturnSummary.pending]: (state) => {
      state.fetchingReturnSummary = true;
    },
    [fetchReturnSummary.fulfilled]: (state, { payload }) => {
      state.fetchingReturnSummary = false;
      state.returnSummary = payload;
    },
    [fetchReturnSummary.rejected]: (state) => {
      state.fetchingReturnSummary = false;
    },
    [fetchReturnReferenceSummary.pending]: (state) => {
      state.fetchingReturnReferenceSummary = true;
    },
    [fetchReturnReferenceSummary.fulfilled]: (state, { payload }) => {
      state.fetchingReturnReferenceSummary = false;
      state.returnReferenceSummary = payload;
    },
    [fetchReturnReferenceSummary.rejected]: (state) => {
      state.fetchingReturnReferenceSummary = false;
    },
    [fetchShowReturnReferenceSummary.pending]: (state) => {
      state.fetchingShowReturnReferenceSummary = true;
    },
    [fetchShowReturnReferenceSummary.fulfilled]: (state, { payload }) => {
      state.fetchingShowReturnReferenceSummary = false;
      state.showReturnReferenceSummary = payload;
    },
    [fetchShowReturnReferenceSummary.rejected]: (state) => {
      state.fetchingShowReturnReferenceSummary = false;
    },
    [fetchPortfolioReferenceNumber.pending]: (state) => {
      state.fetchingPortfolioReferenceNumber = true;
    },
    [fetchPortfolioReferenceNumber.fulfilled]: (state, { payload }) => {
      state.fetchingPortfolioReferenceNumber = false;
      state.portfolioReferenceNumber = payload;
    },
    [fetchPortfolioReferenceNumber.rejected]: (state) => {
      state.fetchingPortfolioReferenceNumber = false;
    },
    [fetchIndexedReturns.pending]: (state) => {
      state.fetchingIndexedReturns = true;
    },
    [fetchIndexedReturns.fulfilled]: (state, { payload }) => {
      state.fetchingIndexedReturns = false;
      state.indexedReturns = payload;
    },
    [fetchIndexedReturns.rejected]: (state) => {
      state.fetchingIndexedReturns = false;
    },
    [fetchPartnerMappingForPortfolio.pending]: (state) => {
      state.fetchingPartnerMappingForPortfolio = true;
      state.partnerMappingForPortfolio = null;
    },
    [fetchPartnerMappingForPortfolio.fulfilled]: (state, { payload }) => {
      state.fetchingPartnerMappingForPortfolio = false;
      state.partnerMappingForPortfolio = payload;
    },
    [fetchPartnerMappingForPortfolio.rejected]: (state) => {
      state.fetchingPartnerMappingForPortfolio = false;
    },
    [fetchSellable.pending]: (state) => {
      state.fetchingSellableHoldings = true;
    },
    [fetchSellable.fulfilled]: (state, { payload }) => {
      state.fetchingSellableHoldings = false;
      state.sellableHoldings = payload;
    },
    [fetchSellable.rejected]: (state) => {
      state.fetchingSellableHoldings = false;
    },
    [fetchMarketStartValues.pending]: (state) => {
      state.fetchingMarketStartValues = true;
      state.marketStartValues = [];
    },
    [fetchMarketStartValues.fulfilled]: (state, { payload }) => {
      state.fetchingMarketStartValues = false;
      state.marketStartValues = payload;
    },
    [fetchMarketStartValues.rejected]: (state) => {
      state.fetchingMarketStartValues = false;
    },
    [fetchMarketEndValues.pending]: (state) => {
      state.fetchingMarketEndValues = true;
      state.marketEndValues = [];
    },
    [fetchMarketEndValues.fulfilled]: (state, { payload }) => {
      state.fetchingMarketEndValues = false;
      state.marketEndValues = payload;
    },
    [fetchMarketEndValues.rejected]: (state) => {
      state.fetchingMarketEndValues = false;
    },
    [fetchWireInDate.pending]: (state) => {
      state.fetchingWireInDate = true;
    },
    [fetchWireInDate.fulfilled]: (state, { payload }) => {
      state.fetchingWireInDate = false;
      state.wireInDate = payload;
    },
    [fetchWireInDate.rejected]: (state) => {
      state.fetchingWireInDate = false;
    },
    [fetchHoldings.pending]: (state) => {
      state.fetchingHoldings = true;
    },
    [fetchHoldings.fulfilled]: (state, { payload }) => {
      state.fetchingHoldings = false;
      state.holdings = payload;
    },
    [fetchHoldings.rejected]: (state) => {
      state.fetchingHoldings = false;
    },
    [fetchPurchases.pending]: (state) => {
      state.fetchingPurchases = true;
    },
    [fetchPurchases.fulfilled]: (state, { payload }) => {
      state.fetchingPurchases = false;
      state.purchases = payload;
    },
    [fetchPurchases.rejected]: (state) => {
      state.fetchingPurchases = false;
    },
    [savePortfolioName.pending]: (state) => {
      state.savingPortfolioName = true;
    },
    [savePortfolioName.fulfilled]: (state, { payload }) => {
      state.savingPortfolioName = false;
      state.savePortfolioNameStatus = payload;
    },
    [savePortfolioName.rejected]: (state) => {
      state.savingPortfolioName = false;
    },
    [fetchLastHistoricalDate.pending]: (state) => {
      state.fetchingLastHistoricalDate = true;
    },
    [fetchLastHistoricalDate.fulfilled]: (state, { payload }) => {
      state.fetchingLastHistoricalDate = false;
      state.lastHistoricalDate = payload;
    },
    [fetchLastHistoricalDate.rejected]: (state) => {
      state.fetchingLastHistoricalDate = false;
    },
    [removeCustomersWithNonAccessableCustomerManagedPortfolios.pending]: (state) => {
      state.fetchingPortfolios = true;
    },
    [removeCustomersWithNonAccessableCustomerManagedPortfolios.fulfilled]: (state, { payload }) => {
      state.fetchingPortfolios = false;
      state.portfolios = payload;
    },
    [removeCustomersWithNonAccessableCustomerManagedPortfolios.rejected]: (state) => {
      state.fetchingPortfolios = false;
    },
    [fetchCustomerAccountPortfolios.pending]: (state) => {
      state.fetchingCustomerAccountPortfolios = true;
      state.customerAccountPortfolios = [];
    },
    [fetchCustomerAccountPortfolios.fulfilled]: (state, { payload }) => {
      state.fetchingCustomerAccountPortfolios = false;
      state.customerAccountPortfolios = payload;
    },
    [fetchCustomerAccountPortfolios.rejected]: (state) => {
      state.fetchingCustomerAccountPortfolios = false;
    },
  },
});

export default portfoliosSlice.reducer;

export const {
  selectPortfolio,
  unSelectPortfolio,
  resetPortfolios,
  sortPortfolios,
  selectCustomer,
  unselectCustomer,
  unSelectAllPortfolios,
  selectMonthlyPortfolio,
  unSelectAllMonthlyPortfolios,
  unSelectMonthlyPortfolio,
  setReportGrouping,
  toggleShowPurchases,
  setPortfolioReportViewArray,
  resetSavePortfolioNameStatus,
  resetAllButSelectedPortfolios,
} = portfoliosSlice.actions;
