/* eslint-disable react/jsx-props-no-spreading */
import React, { Suspense, lazy, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect,
  useLocation,
} from 'react-router-dom';
import { styled } from '@mui/system';

// Redux.
import { updateCustomerId } from '../redux/authSlice';

// Helper functions.
import { getLanguage } from './localStorage';
import { getCustomerId, getAuthData } from './sessionStorage';

// Custom components.
import NavigationBar from '../components/navigation/NavigationBar';
import LoadingContainer from '../components/navigation/LoadingContainer';
import ErrorBoundary from '../components/navigation/ErrorBoundary';
import DialogError from '../components/common/DialogError';
import Footer from '../components/navigation/Footer';
import ScrollToTop from './ScrollToTop';
import AppSectionDefiner from './routes/AppSectionDefiner';
import LoginRoute from './routes/LoginRoute';
import { isPublicAddress, isBuyFundPipeAddress } from '../lib/navigationLinks';
import RedirectToExternalUrl from '../components/common/RedirectToExternalUrl';

/**
 * Renders route which requires authentication.
 * Redirects to login page if user is not authenticated.
 */
function PrivateRoute({ children, location, ...rest }) {
  const token = useSelector((state) => state.auth.token);
  const accountInformation = useSelector(
    (state) => state.users.accountInformation,
  );
  const inBankerView = useSelector(
    (state) => Boolean(state.auth.currentUser?.isBanker && state.auth.customerId),
  );
  const currentUser = useSelector((state) => state.auth.currentUser);
  const forceOpenNewsLetters = useSelector(
    (state) => state.customerService.forceOpenNewsLetters,
  );
  const redirectToAnnouncement = useSelector(
    (state) => state.UIstate.redirectToAnnouncement,
  );
  const inPersonnelFundView = useSelector((state) => state.UIstate.inPersonnelFundView);
  const isMifidValid = useSelector((state) => state.users.isMifidValid);

  const inBankerCustomersView = location.pathname === '/asiakkaat';

  if (isPublicAddress(location.pathname)) {
    // if user is logged in, remove /julkinen from the url and redirect
    if (token) {
      const privateLocation = location.pathname.replace('/julkinen', '');
      return (
        <Route
          {...rest}
          location={location}
          render={() => (
            <Redirect
              to={{ pathname: privateLocation, state: { from: location } }}
            />
          )}
        />
      );
    }
    return (
      <Route
        {...rest}
        location={location}
        render={() => children}
      />
    );
  }

  if (location.pathname === '/palkkiolaskuri') {
    return (
      <Route
        {...rest}
        location={location}
        render={() => children}
      />
    );
  }

  // Redirect user to login if auth token missing.
  if (!token) {
    return (
      <Route
        {...rest}
        location={location}
        render={() => <Redirect to={{ pathname: '/kirjaudu', state: { from: location } }} />}
      />
    );
  }

  // Skip user data checks if in buy fund pipe or in banker customer select.
  if (isBuyFundPipeAddress(location.pathname) || inBankerCustomersView) {
    return (
      <Route
        {...rest}
        location={location}
        render={() => children}
      />
    );
  }

  // Wait for user data so we can determine correct route.
  if (!currentUser
    || (currentUser && currentUser.IsEliteCustomer && !forceOpenNewsLetters)
    || isMifidValid === null) {
    return <LoadingContainer />;
  }

  // Redirect user to web service if no permission to personnel funds.
  if (inPersonnelFundView && !currentUser.isPersonnelFundCustomer) {
    return (
      <Route
        {...rest}
        location={location}
        render={() => (
          <Redirect
            to={{ pathname: '/varallisuus', state: { from: location } }}
          />
        )}
      />
    );
  }

  // Redirect user to personnel funds if no permission to web service.
  if (!inPersonnelFundView && !currentUser.isEliteCustomer) {
    return (
      <Route
        {...rest}
        location={location}
        render={() => (
          <Redirect
            to={{ pathname: '/henkilostorahasto', state: { from: location } }}
          />
        )}
      />
    );
  }

  // In banker view redirect away from personnel fund pages.
  if (inBankerView && inPersonnelFundView) {
    return (
      <Route
        {...rest}
        location={location}
        render={() => (
          <Redirect
            to={{ pathname: '/varallisuus', state: { from: location } }}
          />
        )}
      />
    );
  }

  // only if user is not in banker view
  if (!inBankerView) {
    // do these only if user is in VP side
    if (!inPersonnelFundView) {
      // Redirect to VP mifid update if user is VP customer and mifid needs update.
      if (currentUser.isEliteCustomer && isMifidValid === 'mifid' && location?.pathname !== '/mifid') {
        return (
          <Route
            {...rest}
            location={location}
            render={() => (
              <Redirect
                to={{ pathname: '/mifid', state: { from: location } }}
              />
            )}
          />
        );
      }

      // if there are raised newsletters and no need to update tax countries
      if (redirectToAnnouncement && forceOpenNewsLetters?.length > 0 && isMifidValid !== 'mifid') {
        const openAnnouncementURL = `/asiakaspalvelu/tiedotteet/${forceOpenNewsLetters[0].id}`;
        if (location.pathname !== openAnnouncementURL) {
          return (
            <Route
              {...rest}
              location={location}
              render={() => (
                <Redirect
                  to={{ pathname: `/asiakaspalvelu/tiedotteet/${forceOpenNewsLetters[0].id}`, state: { from: location } }}
                />
              )}
            />
          );
        }
      }
    }

    // only do this if user is in HRJ side
    // Redirect to HRJ omat tiedot if user is HRJ customer and info is missing.
    if (inPersonnelFundView && currentUser.isPersonnelFundCustomer && accountInformation
        && accountInformation.Fatca === null && location?.pathname !== '/omattiedot') {
      return (
        <Route
          {...rest}
          location={location}
          render={() => (
            <Redirect
              to={{ pathname: '/omattiedot', state: { from: location } }}
            />
          )}
        />
      );
    }
  }

  return (
    <Route
      {...rest}
      location={location}
      render={() => children}
    />
  );
}

PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
};

PrivateRoute.defaultProps = {
  location: null,
};

/**
 * Renders root route. Redirects user either to login or front page.
 */
function RootRoute() {
  const token = useSelector((state) => state.auth.token);
  const location = useLocation();

  // Redirect hash URLs.
  if (location.hash) {
    const path = location.hash.substring(1);
    return <Redirect to={{ pathname: path }} />;
  }

  return (
    <Route path="/">
      {token ? (
        <Redirect to={{ pathname: '/varallisuus' }} />
      ) : (
        <Redirect to={{ pathname: '/kirjaudu' }} />
      )}
    </Route>
  );
}

const AsyncPersonnelFundHomeContainer = lazy(() => import('../components/personnelFundHome/PersonnelFundHomeContainer'));
const AsyncLoginContainer = lazy(() => import('../components/login/LoginContainer'));
const AsyncPersonnelFundLogin = lazy(() => import('../components/login/PersonnelFundLogin'));
const AsyncPersonalInfoContainer = lazy(() => import('../components/personalInformation/PersonalInfoContainer'));
const AsyncFundingNotificationContainer = lazy(() => import('../components/fundingNotification/FundingNotificationContainer'));
const AsyncFundUnitWithdrawalContainer = lazy(() => import('../components/fundUnitWithdrawal/FundUnitWithdrawalContainer'));
const AsyncMyFundUnitDetailsContainer = lazy(() => import('../components/myFundUnitDetails/MyFundUnitDetailsContainer'));
const AsyncPersonnelFundContactContainer = lazy(() => import('../components/personnelFundContact/PersonnelFundContactContainer'));
const AsyncTupasLoginReturnContainer = lazy(() => import('../components/login/TupasLoginReturnContainer'));
const AsyncTupasLoginEliteReturnContainer = lazy(() => import('../components/login/TupasLoginEliteReturnContainer'));
const AsyncPersonnelFundExitContainer = lazy(() => import('../components/logout/PersonnelFundExitContainer'));
const AsyncCustomerServiceHomeContainer = lazy(() => import('../components/customerService/CustomerServiceHomeContainer'));
const AsyncCustomerServiceMessagesContainer = lazy(() => import('../components/customerService/messages/CustomerServiceMessagesContainer'));
const AsyncCustomerServiceOneMessageContainer = lazy(() => import('../components/customerService/singleConversation/CustomerServiceOneMessageContainer'));
const AsyncCustomerServiceAnnouncementsContainer = lazy(() => import('../components/customerService/announcements/CustomerServiceAnnouncementsContainer'));
const AsyncCustomerServiceDocumentsContainer = lazy(() => import('../components/customerService/documents/CustomerServiceDocumentsContainer'));
const AsyncCustomerServiceMonthlySavingsContainer = lazy(() => import('../components/customerService/monthlySavings/CustomerServiceMonthlySavingsContainer'));
const AsyncCustomerServiceContactContainer = lazy(() => import('../components/customerService/contact/CustomerServiceContactContainer'));
const AsyncFundOptionsContainer = lazy(() => import('../components/funds/fundOptions/FundOptionsContainer'));
const AsyncFundSearchContainer = lazy(() => import('../components/funds/FundSearchContainer'));
const AsyncNewFundInfoContainer = lazy(() => import('../components/funds/fund/FundInfoContainer'));
const AsyncReportsContainer = lazy(() => import('../components/funds/ReportsContainer'));
const AsyncBrowseOrdersContainer = lazy(() => import('../components/orders/browseOrders/BrowseOrdersContainer'));
const AsyncNewPortfolioContainer = lazy(() => import('../components/orders/newPortfolio/NewPortfolioContainer'));
const AsyncNewWithdrawalContainer = lazy(() => import('../components/orders/withdrawal/NewWithdrawalContainer'));
const AsyncNewDepositContainer = lazy(() => import('../components/orders/deposit/NewDepositContainer'));
const AsyncNewDepositSuccess = lazy(() => import('../components/orders/deposit/NewDepositSuccess'));
const AsyncNewDepositFail = lazy(() => import('../components/orders/deposit/NewDepositFail'));
const AsyncNewDepositPending = lazy(() => import('../components/orders/deposit/NewDepositPending'));
const AsyncBuyFundContainer = lazy(() => import('../components/orders/buyFund/BuyFundContainer'));
const AsyncNewOrderConfirmationContainer = lazy(() => import('../components/orders/NewOrderConfirmationContainer'));
const AsyncSellFundContainer = lazy(() => import('../components/orders/sellFund/SellFundContainer'));
const AsyncMyPortfoliosSummaryContainer = lazy(() => import('../components/myPortfolios/summary/MyPortfoliosSummaryContainer'));
const AsyncPortfolioReportContainer = lazy(() => import('../components/myPortfolios/portfolioReport/PortfolioReportContainer'));
const AsyncStatementReportContainer = lazy(() => import('../components/myPortfolios/statementReport/StatementReportContainer'));
const AsyncCustomerSelectContainer = lazy(() => import('../components/login/customers/CustomerSelectContainer'));
const AsyncTupasSignupReturnContainer = lazy(() => import('../components/signup/TupasSignupReturnContainer'));
const AsyncWealthSummaryContainer = lazy(() => import('../components/wealthSummary/WealthSummaryContainer'));
const AsyncFundUnitInfoContainer = lazy(() => import('../components/myFundUnitDetails/FundUnitInfoContainer'));
const AsyncChangePasswordReturnContainer = lazy(() => import('../components/settings/generalSettings/ChangePasswordReturnContainer'));
const AsyncGeneralSettingsContainer = lazy(() => import('../components/settings/generalSettings/GeneralSettingsContainer'));
const AsyncNameYourOwnPortfoliosContainer = lazy(() => import('../components/settings/renamePortfolios/NameYourOwnPortfoliosContainer'));
const AsyncTransactionsReportContainer = lazy(() => import('../components/transactionsReport/TransactionsReportContainer'));
const AsyncProfitsReportContainer = lazy(() => import('../components/profitsReport/ProfitsReportContainer'));
const AsyncUserContactInfoContainer = lazy(() => import('../components/settings/contactInfo/UserContactInfoContainer'));
const AsyncEditUserContactInfoContainer = lazy(() => import('../components/settings/contactInfo/editContactInfo/EditUserContactInfoContainer'));
const AsyncTaxInformationContainer = lazy(() => import('../components/settings/taxInformation/TaxInformationContainer'));
const AsyncEditTaxInformationContainer = lazy(() => import('../components/settings/taxInformation/editTaxInformation/EditTaxInformationContainer'));
const AsyncBuyFundStartContainer = lazy(() => import('../components/buyFundPipe/BuyFundStartContainer'));
const AsyncBuyFundLoginReturnContainer = lazy(() => import('../components/buyFundPipe/BuyFundLoginReturnContainer'));
const AsyncBuyFundExistingUserContainer = lazy(() => import('../components/buyFundPipe/BuyFundExistingUserContainer'));
const AsyncBuyFundReturnContainer = lazy(() => import('../components/buyFundPipe/BuyFundReturnContainer'));
const AsyncBuyFundOkContainer = lazy(() => import('../components/buyFundPipe/BuyFundOkContainer'));
const AsyncBuyFundDepositContainer = lazy(() => import('../components/buyFundPipe/deposit/BuyFundDepositContainer'));
const AsyncBuyFundDepositPendingContainer = lazy(() => import('../components/buyFundPipe/deposit/BuyFundDepositPendingContainer'));
const AsyncBuyFundDepositNokContainer = lazy(() => import('../components/buyFundPipe/deposit/BuyFundDepositNokContainer'));
const AsyncRewardCalculatorContainer = lazy(() => import('../components/rewardCalculator/RewardCalculatorContainer'));

const FullHeightDiv = styled('div')({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
});

const ScrollDiv = styled('div')({
  overflowY: 'auto',
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  // First div is ScrollToTop, expand the 2nd one.
  '& > div:nth-of-type(2)': {
    flexGrow: 1,
  },
});

function Routes() {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();

  // Update UI language if different language is saved in local storage.
  useEffect(() => {
    const language = getLanguage();
    if (i18n.language !== language) {
      i18n.changeLanguage(language);
    }
  }, [i18n]);

  // Update customerId in Redux if found in session storage along with auth data.
  useEffect(() => {
    const customerId = getCustomerId();
    const authData = getAuthData();
    if (authData?.token && customerId) {
      dispatch(updateCustomerId(customerId));
    } else {
      dispatch(updateCustomerId(null));
    }
  }, [dispatch]);

  return (
    <BrowserRouter>
      <FullHeightDiv>
        <DialogError />
        <NavigationBar />
        <AppSectionDefiner />
        <ErrorBoundary>
          <ScrollDiv role="main">
            <ScrollToTop />
            <Suspense fallback={<LoadingContainer />}>
              <Switch>
                <PrivateRoute path="/varallisuus">
                  <AsyncWealthSummaryContainer />
                </PrivateRoute>
                <PrivateRoute path="/mifid">
                  <AsyncWealthSummaryContainer />
                </PrivateRoute>
                <PrivateRoute path="/omattiedot">
                  <AsyncPersonalInfoContainer />
                </PrivateRoute>
                <PrivateRoute path="/rahastointi-ilmoitus">
                  <AsyncFundingNotificationContainer />
                </PrivateRoute>
                <PrivateRoute path="/nostoilmoitus">
                  <AsyncFundUnitWithdrawalContainer />
                </PrivateRoute>
                <PrivateRoute path="/rahasto-osuus/maksettuosuus/:membershipId?/:fiscalYearId?">
                  <AsyncFundUnitInfoContainer />
                </PrivateRoute>
                <PrivateRoute path="/rahasto-osuus">
                  <AsyncMyFundUnitDetailsContainer />
                </PrivateRoute>
                <LoginRoute path="/kirjaudu">
                  <AsyncLoginContainer />
                </LoginRoute>
                <Route
                  path="/verkkopalvelu"
                  render={() => <RedirectToExternalUrl redirectTo="https://m2.evli.com/m2/wealth?lang=fi" />}
                />
                <LoginRoute path="/jasenpalvelu">
                  <AsyncPersonnelFundLogin />
                </LoginRoute>
                <PrivateRoute path="/henkilostorahasto">
                  <AsyncPersonnelFundHomeContainer />
                </PrivateRoute>
                <PrivateRoute path="/asiakaspalvelu/etusivu">
                  <AsyncCustomerServiceHomeContainer />
                </PrivateRoute>
                <PrivateRoute exact path="/asiakaspalvelu/viestit">
                  <AsyncCustomerServiceMessagesContainer />
                </PrivateRoute>
                <PrivateRoute path="/asiakaspalvelu/viestit/:conversationId?">
                  <AsyncCustomerServiceOneMessageContainer />
                </PrivateRoute>
                <PrivateRoute path="/asiakaspalvelu/tiedotteet/:announcementId?">
                  <AsyncCustomerServiceAnnouncementsContainer />
                </PrivateRoute>
                <PrivateRoute path="/asiakaspalvelu/asiakirjat">
                  <AsyncCustomerServiceDocumentsContainer />
                </PrivateRoute>
                <PrivateRoute path="/asiakaspalvelu/kuukausisaastot">
                  <AsyncCustomerServiceMonthlySavingsContainer />
                </PrivateRoute>
                <PrivateRoute path="/asiakaspalvelu/yhteystiedot">
                  <AsyncCustomerServiceContactContainer />
                </PrivateRoute>
                <PrivateRoute path="/asiakaspalvelu">
                  <AsyncPersonnelFundContactContainer />
                </PrivateRoute>
                <PrivateRoute path="/toimeksiannot">
                  <AsyncBrowseOrdersContainer />
                </PrivateRoute>
                <PrivateRoute path="/uusiToimeksianto/merkinta/:fundId?/:prevLocation?/:portfolioId?">
                  <AsyncBuyFundContainer />
                </PrivateRoute>
                <PrivateRoute path="/uusiToimeksianto/lunastus/:fundId?/:prevLocation?/:portfolioId?">
                  <AsyncSellFundContainer />
                </PrivateRoute>
                <PrivateRoute path="/uusiNosto">
                  <AsyncNewWithdrawalContainer />
                </PrivateRoute>
                <PrivateRoute exact path="/uusiTalletus">
                  <AsyncNewDepositContainer />
                </PrivateRoute>
                <PrivateRoute path="/uusiTalletus/onnistui:params?">
                  <AsyncNewDepositSuccess />
                </PrivateRoute>
                <PrivateRoute path="/uusiTalletus/virhe">
                  <AsyncNewDepositFail />
                </PrivateRoute>
                <PrivateRoute path="/uusiTalletus/odottaa">
                  <AsyncNewDepositPending />
                </PrivateRoute>
                <PrivateRoute path="/uusiSalkku">
                  <AsyncNewPortfolioContainer />
                </PrivateRoute>
                <PrivateRoute path="/salkkuyhteenveto">
                  <AsyncMyPortfoliosSummaryContainer />
                </PrivateRoute>
                <PrivateRoute path="/salkkuraportti/:portfolioId?">
                  <AsyncPortfolioReportContainer />
                </PrivateRoute>
                <PrivateRoute path="/tilioteraportti/:urlParameter?">
                  <AsyncStatementReportContainer />
                </PrivateRoute>
                <PrivateRoute path="/rahastot">
                  <AsyncFundOptionsContainer />
                </PrivateRoute>
                <PrivateRoute path="/julkinen/rahastohaku">
                  <AsyncFundSearchContainer />
                </PrivateRoute>
                <PrivateRoute path="/rahastohaku">
                  <AsyncFundSearchContainer />
                </PrivateRoute>
                <PrivateRoute path="/palkkiolaskuri">
                  <AsyncRewardCalculatorContainer />
                </PrivateRoute>
                <PrivateRoute path="/julkinen/rahasto/:instrumentId?/:prevLocation?">
                  <AsyncNewFundInfoContainer />
                </PrivateRoute>
                <PrivateRoute path="/rahasto/:instrumentId?/:prevLocation?">
                  <AsyncNewFundInfoContainer />
                </PrivateRoute>
                <PrivateRoute path="/kuukausikatsaukset">
                  <AsyncReportsContainer />
                </PrivateRoute>
                <PrivateRoute path="/uusiToimeksiantoVarmistus">
                  <AsyncNewOrderConfirmationContainer />
                </PrivateRoute>
                <PrivateRoute path="/tuottoraportti">
                  <AsyncProfitsReportContainer />
                </PrivateRoute>
                <PrivateRoute exact path="/yhteystiedot">
                  <AsyncUserContactInfoContainer />
                </PrivateRoute>
                <PrivateRoute path="/yhteystiedot/muokkaus/yhteystiedot">
                  <AsyncEditUserContactInfoContainer />
                </PrivateRoute>
                <PrivateRoute path="/yleisetasetukset/salasanavahvistus">
                  <AsyncChangePasswordReturnContainer />
                </PrivateRoute>
                <PrivateRoute path="/yleisetasetukset">
                  <AsyncGeneralSettingsContainer />
                </PrivateRoute>
                <PrivateRoute path="/nimea-omat-salkut">
                  <AsyncNameYourOwnPortfoliosContainer />
                </PrivateRoute>
                <PrivateRoute path="/tapahtumaraportti">
                  <AsyncTransactionsReportContainer />
                </PrivateRoute>
                <PrivateRoute exact path="/verovelvollisuus">
                  <AsyncTaxInformationContainer />
                </PrivateRoute>
                <PrivateRoute path="/verovelvollisuus/muokkaus/verovelvollisuus">
                  <AsyncEditTaxInformationContainer />
                </PrivateRoute>
                <PrivateRoute path="/kirjauduuloshrj/:guid?/:hrjsale?">
                  <AsyncPersonnelFundExitContainer />
                </PrivateRoute>
                <LoginRoute path="/tupasLoginReturn">
                  <AsyncTupasLoginReturnContainer />
                </LoginRoute>
                <LoginRoute path="/tupasLoginEliteReturn">
                  <AsyncTupasLoginEliteReturnContainer />
                </LoginRoute>
                <LoginRoute path="/asiakkaaksi/tupastunnistettu">
                  <AsyncTupasSignupReturnContainer />
                </LoginRoute>
                <PrivateRoute path="/asiakkaat">
                  <AsyncCustomerSelectContainer />
                </PrivateRoute>
                <Route path="/ostaRahastoa/vahvistuksenVarmistus">
                  <AsyncBuyFundReturnContainer />
                </Route>
                <Route path="/ostaRahastoa/kirjautuminen/:fundGuid/:contactCreated?/:hrjSale?">
                  <AsyncBuyFundStartContainer />
                </Route>
                <LoginRoute path="/ostaRahastoa/asiakkaaksi/tupastunnistettu/:fundId/:hrjSale?">
                  <AsyncTupasSignupReturnContainer />
                </LoginRoute>
                <PrivateRoute path="/ostaRahastoa/talleta/:fundId/:portfolioId/:hrjSale?">
                  <AsyncBuyFundDepositContainer />
                </PrivateRoute>
                <PrivateRoute path="/ostaRahastoa/onnistui/:hrjSale?">
                  <AsyncBuyFundDepositPendingContainer />
                </PrivateRoute>
                <PrivateRoute path="/ostaRahastoa/ostoonnistui/:fundId/:monthlyId?">
                  <AsyncBuyFundOkContainer />
                </PrivateRoute>
                <PrivateRoute path="/ostaRahastoa/virhe/:fundId?/:portfolioId?/:hrjSale?">
                  <AsyncBuyFundDepositNokContainer />
                </PrivateRoute>
                <Route path="/ostaRahastoa/:fundGuid/:portfolioId?/:hrjSale?">
                  <AsyncBuyFundExistingUserContainer />
                </Route>
                <Route path="/tupasbuyfundpipeloginreturn">
                  <AsyncBuyFundLoginReturnContainer />
                </Route>
                <RootRoute />
              </Switch>
            </Suspense>
            <Footer />
          </ScrollDiv>
        </ErrorBoundary>
      </FullHeightDiv>
    </BrowserRouter>
  );
}

export default Routes;
