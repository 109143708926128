import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { styled } from '@mui/system';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import PersonIcon from '@mui/icons-material/Person';

const CustomerNameText = styled('span')({
  textDecoration: 'underline',
});

function BankerViewIndicator({ white }) {
  const { t } = useTranslation();
  const customerUser = useSelector((state) => state.auth.customerUser);
  const customerName = customerUser ? `${customerUser.firstName} ${customerUser.lastName}` : '';

  return (
    <Tooltip title={t('BANKER.CHANGE_USER_INFO')}>
      <Box
        sx={{
          marginRight: 2,
          display: 'inline-flex',
          alignItems: 'center',
          height: '40px',
          fontSize: '0.75rem',
          fontWeight: 600,
          textTransform: 'uppercase',
          letterSpacing: 0.5,
          paddingX: 2,
          color: white ? 'white.main' : 'primary.main',
          backgroundColor: white ? 'transparent' : 'rgba(34,95,146,0.15)',
        }}
      >
        {`${t('BANKER.BANKER_VIEW')}:`}
        <PersonIcon sx={{ marginLeft: 1, marginRight: 1 }} />
        <CustomerNameText>{customerName}</CustomerNameText>
      </Box>
    </Tooltip>
  );
}

BankerViewIndicator.propTypes = {
  white: PropTypes.bool,
};

BankerViewIndicator.defaultProps = {
  white: false,
};

export default BankerViewIndicator;
