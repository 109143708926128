import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../api/axiosSetup';

export const fetchTotalByFiscal = createAsyncThunk('fundShares/fetchTotalByFiscal', async ({ memberGuid, fiscalGuid }) => {
  const response = await axios.get(`/api/fundshares/totalbyfiscal/${memberGuid}/${fiscalGuid}`);
  return response.data;
});

export const fetchMemberFundShares = createAsyncThunk('fundShares/fetchMemberFundShares', async ({ membershipId, fiscalYearId }) => {
  const response = await axios.get(`/api/fundshares/${membershipId}/${fiscalYearId}`);
  return response.data;
});

export const fetchPaidShareByFiscal = createAsyncThunk('fundShares/fetchPaidShareByFiscal', async ({ membershipId, fiscalYearId }) => {
  const response = await axios.get(`/api/fundshares/paidshare/${membershipId}/${fiscalYearId}`);
  return response.data;
});

export const fetchMemberEmploymentOccurrenceSummary = createAsyncThunk('fundShares/fetchMemberEmploymentOccurrenceSummary', async ({ membershipId, fiscalYearId }) => {
  const response = await axios.get(`/api/fundshares/summary/${membershipId}/${fiscalYearId}`);
  return response.data;
});

export const fetchMemberMidYearRewards = createAsyncThunk('fundShares/fetchMemberMidYearRewards', async ({ membershipId }) => {
  const response = await axios.get(`/api/fundshares/midyearrewards/${membershipId}`);
  if (!response.data) {
    return [];
  }
  // Sort items from newest to oldest
  return response.data.sort(
    (item1, item2) => new Date(item2.RewardDate) - new Date(item1.RewardDate),
  );
});

export const fetchRewardSystemForMembership = createAsyncThunk('fundShares/fetchRewardSystemForMembership', async ({ membershipId }) => {
  const response = await axios.get(`/api/fundshares/rewardsystem/?membershipId=${membershipId}`);
  return response.data;
});

export const fundSharesSlice = createSlice({
  name: 'fundShares',
  initialState: {
    totalByFiscal: null,
    fetchingTotalByFiscal: false,
    memberFundShares: [],
    fetchingMemberFundShares: false,
    paidShareByFiscal: null,
    fetchingPaidShareByFiscal: false,
    memberEmploymentOccurenceSummary: null,
    fetchingMemberEmploymentOccurrenceSummary: false,
    memberMidYearRewards: [],
    fetchingMemberMidYearRewards: false,
    rewardSystemForMembership: null,
  },
  reducers: {
    resetFundShares: (state) => {
      state.totalByFiscal = null;
      state.fetchingTotalByFiscal = false;
      state.memberFundShares = [];
      state.fetchingMemberFundShares = false;
      state.paidShareByFiscal = null;
      state.fetchingPaidShareByFiscal = false;
    },
  },
  extraReducers: {
    [fetchTotalByFiscal.pending]: (state) => {
      state.fetchingTotalByFiscal = true;
      state.totalByFiscal = null;
    },
    [fetchTotalByFiscal.fulfilled]: (state, { payload }) => {
      state.fetchingTotalByFiscal = false;
      state.totalByFiscal = payload;
    },
    [fetchTotalByFiscal.rejected]: (state) => {
      state.fetchingTotalByFiscal = false;
    },
    [fetchMemberFundShares.pending]: (state) => {
      state.fetchingMemberFundShares = true;
      state.memberFundShares = [];
    },
    [fetchMemberFundShares.fulfilled]: (state, { payload }) => {
      state.fetchingMemberFundShares = false;
      state.memberFundShares = payload;
    },
    [fetchMemberFundShares.rejected]: (state) => {
      state.fetchingMemberFundShares = false;
    },
    [fetchPaidShareByFiscal.pending]: (state) => {
      state.fetchingMemberFundShares = true;
      state.paidShareByFiscal = null;
    },
    [fetchPaidShareByFiscal.fulfilled]: (state, { payload }) => {
      state.fetchingMemberFundShares = false;
      state.paidShareByFiscal = payload;
    },
    [fetchPaidShareByFiscal.rejected]: (state) => {
      state.fetchingMemberFundShares = false;
    },
    [fetchMemberEmploymentOccurrenceSummary.pending]: (state) => {
      state.fetchingMemberEmploymentOccurrenceSummary = true;
      state.memberEmploymentOccurenceSummary = null;
    },
    [fetchMemberEmploymentOccurrenceSummary.fulfilled]: (state, { payload }) => {
      state.fetchingMemberEmploymentOccurrenceSummary = false;
      state.memberEmploymentOccurenceSummary = payload;
    },
    [fetchMemberEmploymentOccurrenceSummary.rejected]: (state) => {
      state.fetchingMemberEmploymentOccurrenceSummary = false;
    },
    [fetchMemberMidYearRewards.pending]: (state) => {
      state.memberMidYearRewards = [];
      state.fetchingMemberMidYearRewards = true;
    },
    [fetchMemberMidYearRewards.fulfilled]: (state, { payload }) => {
      state.memberMidYearRewards = payload;
      state.fetchingMemberMidYearRewards = false;
    },
    [fetchMemberMidYearRewards.rejected]: (state) => {
      state.fetchingMemberMidYearRewards = false;
    },
    [fetchRewardSystemForMembership.pending]: (state) => {
      state.rewardSystemForMembership = null;
    },
    [fetchRewardSystemForMembership.fulfilled]: (state, { payload }) => {
      state.rewardSystemForMembership = payload;
    },
  },
});

export default fundSharesSlice.reducer;

export const { resetFundShares } = fundSharesSlice.actions;
