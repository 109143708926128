import * as React from 'react';

const vectors = {
  browse: (svgTitle) => (
    <svg width="56" height="59" viewBox="0 0 56 59" fill="none" xmlns="http://www.w3.org/2000/svg" aria-labelledby={svgTitle} role="img">
      <title id="monthlySavingTitle">{svgTitle}</title>
      <path d="M14.4 13.0298L25.09 37.7598L25.12 46.2598L18.94 40.4198L8.25 15.6998L14.4 13.0298Z" stroke="#010101" strokeLinejoin="round" />
      <path d="M14.4 12.9999L9.81996 2.44987C9.65239 2.06073 9.40967 1.70849 9.10571 1.41334C8.80176 1.11818 8.44254 0.885913 8.04865 0.729844C7.65476 0.573774 7.23394 0.496973 6.81032 0.503841C6.38669 0.51071 5.96858 0.601113 5.57996 0.769869L5.34996 0.859869C4.96046 1.02846 4.60801 1.27216 4.31275 1.57704C4.01749 1.88192 3.78521 2.242 3.62919 2.6367C3.47316 3.0314 3.39646 3.45298 3.40345 3.87734C3.41045 4.30171 3.50101 4.72053 3.66996 5.10987L8.24996 15.6999" stroke="#010101" strokeLinejoin="round" />
      <path d="M4.19997 6.33984L2.33997 7.13984C1.6016 7.46284 1.02046 8.06409 0.722764 8.81302C0.425065 9.56195 0.434842 10.3981 0.74997 11.1398L5.14997 21.3098" stroke="#010101" strokeLinejoin="round" />
      <path d="M19.53 23.5H55.48V58.5H2.82996" stroke="#010101" strokeLinejoin="round" />
      <path d="M46.51 48.1299H21.39" stroke="#010101" strokeLinejoin="round" />
      <path d="M46.5099 41H31.7599" stroke="#010101" strokeLinejoin="round" />
      <path d="M46.51 31.52H39.77" stroke="#010101" strokeLinejoin="round" />
    </svg>
  ),
  buy: (svgTitle) => (
    <svg width="78" height="42" viewBox="0 0 78 42" fill="none" xmlns="http://www.w3.org/2000/svg" aria-labelledby={svgTitle} role="img">
      <title id="monthlySavingTitle">{svgTitle}</title>
      <path d="M67.3141 29.1483V8.03125" stroke="#010101" strokeLinejoin="round" />
      <path d="M56.5863 18.5967H78" stroke="#010101" strokeLinejoin="round" />
      <path d="M21.9026 28.0875V14.3125H43.0928C43.0928 6.88772 33.6082 0.688965 21.9026 0.688965C10.197 0.688965 0.698456 6.88772 0.698456 14.3813C0.698456 21.8749 10.1831 28.1563 21.9026 28.1563V36.2423C10.1831 36.1734 0.698456 30.0435 0.698456 22.481V14.3813" stroke="#010101" strokeLinejoin="round" />
      <path d="M27.1129 33.2256V19.5332H48.317C48.317 27.0957 38.8185 33.2256 27.1129 33.2256V41.3253C38.8185 41.3253 48.317 35.1954 48.317 27.6329V19.5332" stroke="#010101" strokeLinejoin="round" />
    </svg>
  ),
  sell: (svgTitle) => (
    <svg width="78" height="42" viewBox="0 0 78 42" fill="none" xmlns="http://www.w3.org/2000/svg" aria-labelledby={svgTitle} role="img">
      <title id="monthlySavingTitle">{svgTitle}</title>
      <path d="M56.5863 18.5967H78" stroke="#010101" strokeLinejoin="round" />
      <path d="M21.9026 28.0875V14.3125H43.0928C43.0928 6.88772 33.6082 0.688965 21.9026 0.688965C10.197 0.688965 0.698441 6.88772 0.698441 14.3813C0.698441 21.8749 10.183 28.1563 21.9026 28.1563V36.2423C10.183 36.1734 0.698441 30.0435 0.698441 22.481V14.3813" stroke="#010101" strokeLinejoin="round" />
      <path d="M27.1129 33.2256V19.5332H48.317C48.317 27.0957 38.8184 33.2256 27.1129 33.2256V41.3253C38.8184 41.3253 48.317 35.1954 48.317 27.6329V19.5332" stroke="#010101" strokeLinejoin="round" />
    </svg>
  ),
  withdraw: (svgTitle) => (
    <svg width="59" height="52" viewBox="0 0 59 52" fill="none" xmlns="http://www.w3.org/2000/svg" aria-labelledby={svgTitle} role="img">
      <title id="monthlySavingTitle">{svgTitle}</title>
      <path d="M51.88 34.81H57.96V0.5H0.5V34.81H28.63" stroke="#010101" strokeLinejoin="round" />
      <path d="M34.7305 23.1529C37.7648 20.1185 37.7648 15.1988 34.7305 12.1645C31.6961 9.1301 26.7764 9.1301 23.742 12.1645C20.7076 15.1988 20.7076 20.1185 23.742 23.1529C26.7764 26.1873 31.6961 26.1873 34.7305 23.1529Z" stroke="#010101" strokeLinejoin="round" />
      <path d="M58 9.89C56.7635 9.89527 55.5382 9.65627 54.3943 9.18674C53.2504 8.71721 52.2106 8.02638 51.3344 7.15391C50.4582 6.28145 49.763 5.24452 49.2886 4.10266C48.8142 2.9608 48.57 1.73649 48.57 0.5" stroke="#010101" strokeLinejoin="round" />
      <path d="M9.89 0.5C9.88735 2.98957 8.8972 5.37642 7.13681 7.13681C5.37642 8.8972 2.98957 9.88735 0.5 9.89" stroke="#010101" strokeLinejoin="round" />
      <path d="M0.5 25.4199C2.98957 25.4226 5.37642 26.4127 7.13681 28.1731C8.8972 29.9335 9.88735 32.3204 9.89 34.8099" stroke="#010101" strokeLinejoin="round" />
      <path d="M14.01 17.98H10.05" stroke="#010101" strokeLinejoin="round" />
      <path d="M48.41 17.98H44.44" stroke="#010101" strokeLinejoin="round" />
      <path d="M40.25 33.1298C46.6731 33.1298 51.88 31.5449 51.88 29.5898C51.88 27.6347 46.6731 26.0498 40.25 26.0498C33.8269 26.0498 28.62 27.6347 28.62 29.5898C28.62 31.5449 33.8269 33.1298 40.25 33.1298Z" stroke="#010101" strokeLinejoin="round" />
      <path d="M51.88 29.5898V35.6598C51.88 37.6598 46.68 39.1998 40.25 39.1998C33.82 39.1998 28.63 37.6198 28.63 35.6598V29.5898" stroke="#010101" strokeLinejoin="round" />
      <path d="M51.88 35.4902V41.5602C51.88 43.5602 46.68 45.1102 40.25 45.1102C33.82 45.1102 28.63 43.5202 28.63 41.5602V35.4902" stroke="#010101" strokeLinejoin="round" />
      <path d="M51.88 41.3999V47.4699C51.88 49.4699 46.68 51.0099 40.25 51.0099C33.82 51.0099 28.63 49.4299 28.63 47.4699V41.3999" stroke="#010101" strokeLinejoin="round" />
      <path d="M51.14 28.3501C52.0223 27.4185 53.0863 26.6778 54.2662 26.1738C55.4462 25.6698 56.717 25.4133 58 25.4201" stroke="#010101" strokeLinejoin="round" />
    </svg>

  ),
  deposit: (svgTitle) => (
    <svg width="60" height="53" viewBox="0 0 60 53" fill="none" xmlns="http://www.w3.org/2000/svg" aria-labelledby={svgTitle} role="img">
      <title id="monthlySavingTitle">{svgTitle}</title>
      <path d="M10.79 32.1202C7.88 32.1202 0.5 30.0002 0.5 34.3302C0.5 34.7241 0.577597 35.1142 0.728361 35.4782C0.879125 35.8422 1.1001 36.1729 1.37868 36.4515C1.65726 36.7301 1.98797 36.9511 2.35195 37.1018C2.71593 37.2526 3.10603 37.3302 3.5 37.3302C3.89397 37.3302 4.28407 37.2526 4.64805 37.1018C5.01203 36.9511 5.34274 36.7301 5.62132 36.4515C5.8999 36.1729 6.12087 35.8422 6.27164 35.4782C6.4224 35.1142 6.5 34.7241 6.5 34.3302C6.5 29.9202 2.29 27.4302 0.5 27.4302" stroke="#010101" strokeLinejoin="round" />
      <path d="M46.73 30.29C47.8346 30.29 48.73 29.3946 48.73 28.29C48.73 27.1855 47.8346 26.29 46.73 26.29C45.6254 26.29 44.73 27.1855 44.73 28.29C44.73 29.3946 45.6254 30.29 46.73 30.29Z" stroke="#010101" strokeLinejoin="round" />
      <path d="M32.82 16.56C37.2549 16.56 40.85 12.9648 40.85 8.53C40.85 4.09515 37.2549 0.5 32.82 0.5C28.3852 0.5 24.79 4.09515 24.79 8.53C24.79 12.9648 28.3852 16.56 32.82 16.56Z" stroke="#010101" strokeLinejoin="round" />
      <path d="M39.09 19.1701C34.998 17.957 30.642 17.957 26.55 19.1701" stroke="#010101" strokeLinejoin="round" />
      <path d="M26.84 13.8799C24.1266 14.3041 21.5389 15.3171 19.2586 16.8476C16.9782 18.3782 15.0605 20.3894 13.64 22.7399C12.1808 25.2339 11.252 28.0022 10.9117 30.8717C10.5714 33.7411 10.8269 36.6499 11.6623 39.416C12.4977 42.1822 13.8949 44.7462 15.7666 46.9476C17.6383 49.149 19.9442 50.9405 22.54 52.2099H29.54C29.54 51.9499 29.54 51.6699 29.54 51.4199C29.5444 50.3274 29.6617 49.2382 29.89 48.1699C32.1921 48.6522 34.5603 48.7334 36.89 48.4099C37.616 49.8248 38.5724 51.109 39.72 52.2099H46C45.7374 51.2022 45.5799 50.17 45.53 49.1299C45.53 48.8399 45.53 48.5299 45.53 48.2399C45.5087 47.153 45.6026 46.067 45.81 44.9999C46.8512 44.2824 47.8282 43.4761 48.73 42.5899C49.3683 42.5765 50.0042 42.5063 50.63 42.3799C52.8115 41.9605 54.8375 40.9545 56.49 39.4699C57.4416 38.6241 58.2519 37.6315 58.89 36.5299V31.5299C57.439 31.2646 56.0696 30.6655 54.89 29.7799C54.828 29.7481 54.7707 29.7077 54.72 29.6599C54.5128 29.545 54.3125 29.4181 54.12 29.2799C53.6492 26.4843 52.6296 23.8095 51.12 21.4099C51.3849 20.5033 51.8305 19.6597 52.43 18.9299C52.57 18.7699 52.7 18.6299 52.85 18.4799C53.6941 17.6214 54.7495 17.0005 55.91 16.6799C55.0109 16.1987 54.0746 15.7907 53.11 15.4599C52.2587 15.1567 51.3864 14.9161 50.5 14.7399C49.1014 14.4538 47.6775 14.3097 46.25 14.3099C44.6541 14.3092 43.0634 14.4938 41.51 14.8599C40.6628 14.4061 39.7579 14.0697 38.82 13.8599" stroke="#010101" strokeLinejoin="round" />
    </svg>
  ),
  new: (svgTitle) => (
    <svg width="59" height="50" viewBox="0 0 59 50" fill="none" xmlns="http://www.w3.org/2000/svg" aria-labelledby={svgTitle} role="img">
      <title id="monthlySavingTitle">{svgTitle}</title>
      <path d="M56.73 10.0503H2.27C1.29246 10.0503 0.5 10.8427 0.5 11.8203V47.5503C0.5 48.5278 1.29246 49.3203 2.27 49.3203H56.73C57.7075 49.3203 58.5 48.5278 58.5 47.5503V11.8203C58.5 10.8427 57.7075 10.0503 56.73 10.0503Z" stroke="#010101" strokeLinejoin="round" />
      <path d="M42.47 38.54V20.98" stroke="#010101" strokeLinejoin="round" />
      <path d="M33.67 29.7603H51.24" stroke="#010101" strokeLinejoin="round" />
      <path d="M20 0.500058H39C39.4531 0.500058 39.9017 0.589295 40.3203 0.762674C40.7389 0.936052 41.1192 1.19018 41.4395 1.51054C41.7599 1.8309 42.014 2.21123 42.1874 2.6298C42.3608 3.04837 42.45 3.497 42.45 3.95006V9.38006C42.45 9.55775 42.3794 9.72817 42.2538 9.85382C42.1281 9.97947 41.9577 10.0501 41.78 10.0501H17.2C17.112 10.0501 17.0249 10.0327 16.9436 9.99906C16.8623 9.96539 16.7885 9.91604 16.7263 9.85382C16.6641 9.7916 16.6147 9.71774 16.581 9.63646C16.5474 9.55517 16.53 9.46804 16.53 9.38006V3.95006C16.53 3.49531 16.6199 3.04505 16.7945 2.62516C16.9692 2.20528 17.2251 1.82407 17.5476 1.50344C17.8701 1.18282 18.2527 0.929109 18.6736 0.756911C19.0945 0.584713 19.5453 0.497422 20 0.500058V0.500058Z" stroke="#010101" strokeLinejoin="round" />
    </svg>
  ),
  monthlySaving: (svgTitle) => (
    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg" aria-labelledby={svgTitle} role="img">
      <title id="monthlySavingTitle">{svgTitle}</title>
      <g id="EAB/slider/Time value7">
        <g id="Layer_2_00000151505998292813446720000004654988761342064306_">
          <g id="Layer_1-2">
            <path id="Vector" d="M11.9662 8.2981H37.8563" stroke="#010101" strokeLinejoin="round" />
            <path id="Vector_2" d="M40.5772 8.2981H47.3383C48.1628 8.2981 48.8224 8.95772 48.8224 9.78224V42.5159C48.8224 43.3404 48.1628 44 47.3383 44H2.48414C1.65962 44 1 43.3404 1 42.5159V9.78224C1 8.95772 1.65962 8.2981 2.48414 8.2981H9.08034" stroke="#010101" strokeLinejoin="round" />
          </g>
        </g>
        <g id="Layer_2_00000155144525577064954030000004835938031479692447_">
          <g id="Layer_1-2_00000005235053601089849420000011380909963347573925_">
            <path id="Vector_3" d="M38.7633 33.9408V23.3869" stroke="#010101" strokeLinejoin="round" />
            <path id="Vector_4" d="M33.4038 28.6639H44.0402" stroke="#010101" strokeLinejoin="round" />
            <path id="Vector_5" d="M16.2537 33.4461V26.5201H26.7252C26.7252 22.8097 22.0254 19.6765 16.2537 19.6765C10.482 19.6765 5.78223 22.8097 5.78223 26.5201C5.78223 30.2304 10.482 33.4461 16.2537 33.4461ZM16.2537 33.4461V37.5687C10.482 37.5687 5.78223 34.4355 5.78223 30.6427V26.6025" stroke="#010101" strokeLinejoin="round" />
            <path id="Vector_6" d="M18.8922 36.0021V29.1586H29.3637C29.3637 32.9514 24.6639 36.0021 18.8922 36.0021ZM18.8922 36.0021V40.0423C24.6639 40.0423 29.3637 36.9916 29.3637 33.1987V29.1586" stroke="#010101" strokeLinejoin="round" />
          </g>
        </g>
        <path id="Vector_7" d="M10.5 12C9.70588 12 9 11.4024 9 10.5488V6.45122C9 5.68293 9.61765 5 10.5 5C11.2941 5 12 5.59756 12 6.45122V10.6341C11.9118 11.4024 11.2941 12 10.5 12Z" stroke="#010101" strokeLinejoin="round" />
        <path id="Vector_8" d="M39.5 12C38.7059 12 38 11.4024 38 10.5488V6.45122C38 5.68293 38.6176 5 39.5 5C40.2941 5 41 5.59756 41 6.45122V10.6341C41 11.4024 40.3824 12 39.5 12Z" stroke="#010101" strokeLinejoin="round" />
        <path id="Vector_9" d="M1 16H49" stroke="#010101" strokeLinejoin="round" />
      </g>
    </svg>
  ),
};

export default vectors;
