import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import axios from '../api/axiosSetup';

export const fetchUserMemberships = createAsyncThunk('fundShares/fetchUserMemberships', async () => {
  const response = await axios.get('/api/membershipdetails/getUserMemberShips');
  return response.data;
});

export const fetchMembershipDetails = createAsyncThunk('fundShares/fetchMembershipDetails', async ({ membershipId }) => {
  const response = await axios.get(`/api/membershipdetails/getMembershipDetails/?membershipId=${membershipId}`);
  return response.data;
});

export const fetchFiscalYears = createAsyncThunk('fundShares/fetchFiscalYears', async ({ membershipId }) => {
  const response = await axios.get(`/api/membershipdetails/fiscalyears/?membershipId=${membershipId}`);
  return response.data || [];
});

export const membershipsSlice = createSlice({
  name: 'memberships',
  initialState: {
    userMemberships: [],
    fetchingUserMemberships: false,
    selectedMembershipId: '',
    membershipDetails: null,
    fetchingMembershipDetails: false,
    fiscalYears: [],
  },
  reducers: {
    selectMembership: (state, { payload }) => {
      state.selectedMembershipId = payload;
    },
  },
  extraReducers: {
    [fetchUserMemberships.pending]: (state) => {
      state.fetchingUserMemberships = true;
    },
    [fetchUserMemberships.fulfilled]: (state, { payload }) => {
      state.fetchingUserMemberships = false;
      state.userMemberships = payload;
    },
    [fetchUserMemberships.rejected]: (state) => {
      state.fetchingUserMemberships = false;
    },
    [fetchMembershipDetails.pending]: (state) => {
      state.fetchingMembershipDetails = true;
      state.membershipDetails = null;
    },
    [fetchMembershipDetails.fulfilled]: (state, { payload }) => {
      state.fetchingMembershipDetails = false;
      state.membershipDetails = payload;
    },
    [fetchMembershipDetails.rejected]: (state) => {
      state.fetchingMembershipDetails = false;
    },
    [fetchFiscalYears.pending]: (state) => {
      state.fiscalYears = [];
    },
    [fetchFiscalYears.fulfilled]: (state, { payload }) => {
      state.fiscalYears = payload;
    },
  },
});

export default membershipsSlice.reducer;

export const { selectMembership } = membershipsSlice.actions;
