import { configureStore } from '@reduxjs/toolkit';
import usersReducer from '../redux/usersSlice';
import authReducer from '../redux/authSlice';
import portfoliosReducer from '../redux/portfoliosSlice';
import fundSharesReducer from '../redux/fundSharesSlice';
import membershipsReducer from '../redux/membershipsSlice';
import fundUnitWithdrawalsReducer from '../redux/fundUnitWithdrawalsSlice';
import fundingAndWithdrawalsReducer from '../redux/fundingAndWithdrawalsSlice';
import notesReducer from '../redux/notesSlice';
import personnelFundContactReducer from '../redux/personnelFundContactSlice';
import errorsReducer from '../redux/errorsSlice';
import customerServiceReducer from '../redux/customerServiceSlice';
import fundsReducer from '../redux/fundsSlice';
import datePickerReducer from '../redux/datePickerSlice';
import ordersReducer from '../redux/ordersSlice';
import UIcontrolReducer from '../redux/UIcontrolSlice';
import customerReducer from '../redux/customerSlice';
import transactionReducer from '../redux/transactionsSlice';
import fileDownloadsReducer from '../redux/fileDownloadsSlice';
import contentfulReducer from '../redux/contentfulSlice';

export default configureStore({
  reducer: {
    users: usersReducer,
    auth: authReducer,
    portfolios: portfoliosReducer,
    transactions: transactionReducer,
    fundShares: fundSharesReducer,
    memberships: membershipsReducer,
    fundUnitWithdrawals: fundUnitWithdrawalsReducer,
    fundingAndWithdrawals: fundingAndWithdrawalsReducer,
    notes: notesReducer,
    personnelFundContact: personnelFundContactReducer,
    errors: errorsReducer,
    customerService: customerServiceReducer,
    funds: fundsReducer,
    datePicker: datePickerReducer,
    orders: ordersReducer,
    UIstate: UIcontrolReducer,
    customer: customerReducer,
    fileDownloads: fileDownloadsReducer,
    contentful: contentfulReducer,
  },
});
