import React from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Grid from '@mui/material/Grid';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';

import { urlIsChildOf } from '../../lib/navigationLinks';

const CustomToolbar = styled(Toolbar)({
  height: 55,
  minHeight: 55,
});

function SubNavigationBar({ links }) {
  const { t } = useTranslation();
  const location = useLocation();
  const customerConversations = useSelector(
    (state) => state.customerService.customerConversations,
  );
  const unopenedConversations = customerConversations
    ? customerConversations.filter((message) => !message.isOpened)
    : [];

  if (links.length > 0) {
    // any of the links is included in the current location path
    const showBar = links.some((link) => urlIsChildOf(location.pathname, link.href))
      || location.pathname.split('/')[1] === 'rahasto';

    // Default to first tab.
    let currentPage = links[0].href;
    if (location.pathname.split('/')[1] === 'rahasto') {
      currentPage = '/rahastohaku';
    } else {
      currentPage = links.find(
        (link) => urlIsChildOf(location.pathname, link.href),
      )?.href || links[0].href;
    }

    return (
      <Collapse
        collapsedSize="0px"
        easing="1s"
        in={showBar}
      >
        <AppBar
          position="static"
          elevation={0}
          color="secondary"
          className="subNavigation"
        >
          <CustomToolbar variant="dense">
            <Grid
              maxWidth="xl"
              justifyContent="end"
              columnSpacing={3}
              container
              columns={{ xs: 4, md: 12 }}
              sx={{ margin: 'auto', paddingLeft: '24px', paddingRight: '24px' }}
            >
              <Grid item xs={4} md={12} lg={10}>
                <Tabs
                  value={currentPage}
                  indicatorColor="primary"
                  TabIndicatorProps={{ children: <span /> }}
                >
                  {links.map((link) => (
                    <Tab
                      className="navigation"
                      key={link.label}
                      label={unopenedConversations.length > 0 && link.href === '/asiakaspalvelu/viestit' ? (
                        <Badge
                          badgeContent={unopenedConversations.length}
                          color="green"
                          componentsProps={{
                            badge: {
                              sx: {
                                minWidth: '13px', height: '13px', width: '13px', mr: '-8px',
                              },
                            },
                          }}
                        >
                          <Typography variant="navi">{link.label}</Typography>
                        </Badge>
                      ) : (
                        <Typography variant="navi">{link.label}</Typography>
                      )}
                      value={link.href}
                      component={RouterLink}
                      to={link.href}
                      aria-label={unopenedConversations.length > 0 && link.href === '/asiakaspalvelu/viestit'
                        ? `${link.label} ${unopenedConversations.length} ${t('ARIA.UNOPENED_MESSAGES')}` : link.label}
                    />
                  ))}
                </Tabs>
              </Grid>
            </Grid>
          </CustomToolbar>
        </AppBar>
      </Collapse>
    );
  }
  return '';
}

SubNavigationBar.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  links: PropTypes.arrayOf(PropTypes.object).isRequired,
};

SubNavigationBar.defaultProps = {};

export default SubNavigationBar;
