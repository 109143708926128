import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import i18next from 'i18next';
import axios from '../api/axiosSetup';

export const postTaskRequest = createAsyncThunk('orders/postTaskRequest', async ({ taskActivity, selectedPortfolioId }, thunkAPI) => {
  const { customerId } = thunkAPI.getState().auth;
  if (customerId) {
    return thunkAPI.rejectWithValue('Forbidden in banker view');
  }
  const scheduledEndDate = new Date();
  scheduledEndDate.setDate(scheduledEndDate.getDate() + 1);
  const taskRequest = {
    subject: 'Yhteydenottopyyntö asiakkaalta',
    description: `Asiakas on pyytänyt olemaan yhteydessä koskien ${taskActivity} salkusta ${selectedPortfolioId}`,
    scheduledStart: new Date(),
    scheduledEnd: scheduledEndDate,
  };
  const response = await axios.post('api/orders/createTaskToAllowOrder', taskRequest);
  return response.data;
});

export const postNewOrder = createAsyncThunk('orders/postNewOrder', async ({ order, monthlyOrder }, thunkAPI) => {
  const { customerId } = thunkAPI.getState().auth;
  if (customerId) {
    return thunkAPI.rejectWithValue('Forbidden in banker view');
  }
  if (monthlyOrder === true) {
    const response = await axios.post('/api/orders/monthly', order);
    return response.data;
  }
  const response = await axios.post('/api/orders', order);
  return response.data;
});

export const initDeposit = createAsyncThunk('orders/initDeposit', async ({ order }, thunkAPI) => {
  const { customerId } = thunkAPI.getState().auth;
  if (customerId) {
    return thunkAPI.rejectWithValue('Forbidden in banker view');
  }
  const orderWithLocale = { ...order, locale: i18next.language };
  const response = await axios.post('api/orders/initDeposit', orderWithLocale);
  return response.data;
});

export const postAddDeposit = createAsyncThunk('orders/postAddDeposit', async ({ order }, thunkAPI) => {
  const { customerId } = thunkAPI.getState().auth;
  if (customerId) {
    return thunkAPI.rejectWithValue('Forbidden in banker view');
  }
  const orderWithLocale = { ...order, locale: i18next.language };
  const response = await axios.post('api/orders/deposit/react', orderWithLocale);
  return response.data;
});

export const postMakeDeposit = createAsyncThunk('orders/postMakeDeposit', async ({ order }, thunkAPI) => {
  const { customerId } = thunkAPI.getState().auth;
  if (customerId) {
    return thunkAPI.rejectWithValue('Forbidden in banker view');
  }
  const response = await axios.post('api/orders/makeDeposit', order);
  return { ...response.data, date: new Date().toUTCString(), status: response.status };
});

export const fetchMonthlySavingOrder = createAsyncThunk('orders/fetchMonthlySavingOrder', async ({ orderId }) => {
  const response = await axios.get(`api/orders/getMonthlySavingWithId/${orderId}`);
  return response.data;
});

const initialState = {
  postingTaskRequest: false,
  taskRequestStatus: null,
  postingOrder: false,
  postingOrderStatus: null,
  postingAddDeposit: false,
  makeDepositResponse: null,
  postingMakeDeposit: false,
  fetchingMonthlySavingOrder: false,
  monthlySavingOrder: null,
  initializedDeposit: null,
  initializingDeposit: false,
};

export const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    resetOrders: () => initialState,
    resetRequestStatus: (state) => {
      state.taskRequestStatus = null;
      state.postingOrderStatus = null;
      state.postingMakeDeposit = null;
      state.initializedDeposit = null;
    },
  },
  extraReducers: {
    [postTaskRequest.pending]: (state) => {
      state.postingTaskRequest = true;
      state.taskRequestStatus = null;
    },
    [postTaskRequest.fulfilled]: (state, { payload }) => {
      state.postingTaskRequest = false;
      state.taskRequestStatus = payload;
    },
    [postTaskRequest.rejected]: (state) => {
      state.postingTaskRequest = false;
    },
    [postNewOrder.pending]: (state) => {
      state.postingOrder = true;
      state.postingOrderStatus = null;
    },
    [postNewOrder.fulfilled]: (state, { payload }) => {
      state.postingOrder = false;
      state.postingOrderStatus = payload;
    },
    [postNewOrder.rejected]: (state) => {
      state.postingOrder = false;
    },
    [postAddDeposit.pending]: (state) => {
      state.postingAddDeposit = true;
      state.postingOrderStatus = null;
    },
    // eslint-disable-next-line no-unused-vars
    [postAddDeposit.fulfilled]: (state, { payload }) => {
      state.postingAddDeposit = false;
      // state.postingOrderStatus = payload;
    },
    [postAddDeposit.rejected]: (state) => {
      state.postingAddDeposit = false;
    },
    [initDeposit.pending]: (state) => {
      state.initializingDeposit = true;
      state.postingOrderStatus = null;
    },

    [initDeposit.fulfilled]: (state, { payload }) => {
      state.initializingDeposit = false;
      state.initializedDeposit = payload;
    },
    [initDeposit.rejected]: (state) => {
      state.initializingDeposit = false;
    },
    [postMakeDeposit.pending]: (state) => {
      state.postingMakeDeposit = true;
      state.makeDepositResponse = null;
    },
    [postMakeDeposit.fulfilled]: (state, { payload }) => {
      state.postingMakeDeposit = false;
      state.makeDepositResponse = payload;
    },
    [postMakeDeposit.rejected]: (state) => {
      state.postingMakeDeposit = false;
    },
    [fetchMonthlySavingOrder.pending]: (state) => {
      state.fetchingMonthlySavingOrder = true;
      state.monthlySavingOrder = null;
    },
    [fetchMonthlySavingOrder.fulfilled]: (state, { payload }) => {
      state.fetchingMonthlySavingOrder = false;
      state.monthlySavingOrder = payload;
    },
    [fetchMonthlySavingOrder.rejected]: (state) => {
      state.fetchingMonthlySavingOrder = false;
    },
  },
});

export default ordersSlice.reducer;

export const {
  resetOrders,
  resetRequestStatus,
} = ordersSlice.actions;
