import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../api/axiosSetup';

export const fetchMemberNotes = createAsyncThunk('notes/fetchMemberNotes', async ({ membershipId }) => {
  const response = await axios.get(`/api/notes/getMemberNotes/?membershipId=${membershipId}`);
  return response.data;
});

export const fetchRules = createAsyncThunk('notes/fetchRules', async ({ membershipId }) => {
  const response = await axios.get(`/api/notes/getRulesList/?membershipId=${membershipId}`);
  return response.data;
});

export const notesSlice = createSlice({
  name: 'notes',
  initialState: {
    memberNotes: [],
    fetchingMemberNotes: false,
    rules: [],
    fetchingRules: false,
  },
  extraReducers: {
    [fetchMemberNotes.pending]: (state) => {
      state.memberNotes = [];
      state.fetchingMemberNotes = true;
    },
    [fetchMemberNotes.fulfilled]: (state, { payload }) => {
      state.memberNotes = payload;
      state.fetchingMemberNotes = false;
    },
    [fetchMemberNotes.rejected]: (state) => {
      state.fetchingMemberNotes = false;
    },
    [fetchRules.pending]: (state) => {
      state.rules = [];
      state.fetchingRules = true;
    },
    [fetchRules.fulfilled]: (state, { payload }) => {
      state.rules = payload;
      state.fetchingRules = false;
    },
    [fetchRules.rejected]: (state) => {
      state.fetchingRules = false;
    },
  },
});

export default notesSlice.reducer;
