/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { styled } from '@mui/system';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MuiLink from '@mui/material/Link';

const CustomMenu = styled((props) => (
  <Menu
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(() => ({
  '& .MuiPaper-root': {
    '& .MuiMenu-list': {
      padding: 0,
    },
    '& .MuiMenuItem-root': {
      borderBottom: '1px solid rgb(77, 87, 88, 0.3)',
      margin: 0,
      height: 40,
      '& .MuiSvgIcon-root': {
        marginLeft: 10,
      },
    },
  },
}));

const CustomMenuItem = styled(MenuItem)(() => ({
  fontSize: '0.75rem',
  textTransform: 'uppercase',
  color: 'rgba(77, 87, 88, 1)',
  fontWeight: 600,
  display: 'flex',
  justifyContent: 'space-between',
  paddingLeft: 22,
  paddingRight: 22,
  height: '2.5rem',
}));

function SectionMenu({
  ariaLabel,
  options,
  value,
}) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        onClick={handleOpen}
        aria-label={ariaLabel}
        endIcon={(
          <ExpandMoreIcon
            className="oceanColorIcon"
            sx={
              anchorEl
                ? { transform: 'rotate(180deg)', transition: '0.3s' }
                : { transition: '0.3s' }
            }
          />
        )}
        variant="navi"
        aria-haspopup="true"
        aria-controls="services-menu"
        sx={{
          color: 'rgba(77, 87, 88, 1)',
          fontWeight: 600,
          fontSize: '0.75rem',
          height: '2.5rem',
        }}
      >
        {options.find((option) => option.value === value)?.label || ''}
      </Button>
      <CustomMenu
        id="services-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {options
          .filter((option) => option.value !== value)
          .map((option) => {
            const outsideLink = option.href?.indexOf('http') === 0;
            if (outsideLink) {
              return (
                <CustomMenuItem
                  key={option.value}
                  onClick={handleClose}
                  component={MuiLink}
                  href={option.href}
                  target="_blank"
                  rel="noopener"
                >
                  <>
                    {option.label}
                    <KeyboardArrowRightIcon className="oceanColorIcon" sx={{ fontSize: 20 }} />
                  </>
                </CustomMenuItem>
              );
            }
            return (
              <CustomMenuItem
                key={option.value}
                onClick={handleClose}
                component={Link}
                to={option.href}
              >
                <>
                  {option.label}
                  <KeyboardArrowRightIcon className="oceanColorIcon" sx={{ fontSize: 20 }} />
                </>
              </CustomMenuItem>
            );
          })}
      </CustomMenu>
    </>
  );
}

SectionMenu.propTypes = {
  ariaLabel: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  value: PropTypes.string.isRequired,
};

SectionMenu.defaultProps = {};

export default SectionMenu;
