/* eslint-disable dot-notation */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../api/axiosSetup';

export const fetchNews = createAsyncThunk('contentful/fetchNews', async () => {
  const response = await axios.get('api/contentful/news');
  return response.data;
});

export const fetchPromotedFunds = createAsyncThunk('contentful/fetchPromotedFunds', async () => {
  const response = await axios.get('api/contentful/promotedFunds');
  return response.data.map((fund) => {
    const urlParts = fund?.url?.split('?');
    return ({
      ...fund,
      guid: urlParts.length > 0 ? urlParts[1] : null,
    });
  });
});

export const fetchSustainabilityReportsFi = createAsyncThunk('contentful/fetchSustainabilityReportsFi', async () => {
  // get entryId from contentful (Block - Rahastojen vastuullisuusraportit FI)
  const response = await axios.get('api/contentful/sustainabilityReports?entryId=sQLHryvtUVOIXp3bWxGiu');
  return response.data;
});

export const fetchSustainabilityReportsEn = createAsyncThunk('contentful/fetchSustainabilityReportsEn', async () => {
  // get entryId from contentful (Block - Rahastojen vastuullisuusraportit EN)
  const response = await axios.get('api/contentful/sustainabilityReports?entryId=27p4UpIr2yOmlagD6fmjPa');
  return response.data;
});

export const fetchFunds = createAsyncThunk('contentful/fetchFunds', async () => {
  const response = await axios.get('api/contentful/funds');
  return response.data.map((fund) => {
    const urlParts = fund?.url?.split('?');
    return ({
      ...fund,
      guid: urlParts.length > 0 ? urlParts[1] : null,
    });
  });
});

const initialState = {
  news: [],
  fetchingNews: false,
  promotedFunds: [],
  fetchingPromotedFunds: false,
  sustainabilityReportsFi: [],
  fetchingSustainabilityReportsFi: false,
  sustainabilityReportsEn: [],
  fetchingSustainabilityReportsEn: false,
  funds: [],
  fetchingFunds: false,
};

export const contentfulSlice = createSlice({
  name: 'contentful',
  initialState,
  extraReducers: {
    [fetchNews.pending]: (state) => {
      state.fetchingNews = true;
      state.news = [];
    },
    [fetchNews.fulfilled]: (state, { payload }) => {
      state.fetchingNews = false;
      state.news = payload;
    },
    [fetchNews.rejected]: (state) => {
      state.fetchingNews = false;
    },
    [fetchPromotedFunds.pending]: (state) => {
      state.fetchingPromotedFunds = true;
      state.promotedFunds = [];
    },
    [fetchPromotedFunds.fulfilled]: (state, { payload }) => {
      state.fetchingPromotedFunds = false;
      state.promotedFunds = payload;
    },
    [fetchPromotedFunds.rejected]: (state) => {
      state.fetchingPromotedFunds = false;
    },
    [fetchSustainabilityReportsFi.pending]: (state) => {
      state.fetchingSustainabilityReportsFi = true;
      state.sustainabilityReportsFi = [];
    },
    [fetchSustainabilityReportsFi.fulfilled]: (state, { payload }) => {
      state.fetchingSustainabilityReportsFi = false;
      state.sustainabilityReportsFi = payload;
    },
    [fetchSustainabilityReportsFi.rejected]: (state) => {
      state.fetchingSustainabilityReportsFi = false;
    },
    [fetchSustainabilityReportsEn.pending]: (state) => {
      state.fetchingSustainabilityReportsEn = true;
      state.sustainabilityReportsEn = [];
    },
    [fetchSustainabilityReportsEn.fulfilled]: (state, { payload }) => {
      state.fetchingSustainabilityReportsEn = false;
      state.sustainabilityReportsEn = payload;
    },
    [fetchSustainabilityReportsEn.rejected]: (state) => {
      state.fetchingSustainabilityReportsEn = false;
    },
    [fetchFunds.pending]: (state) => {
      state.fetchingFunds = true;
      state.funds = [];
    },
    [fetchFunds.fulfilled]: (state, { payload }) => {
      state.fetchingFunds = false;
      state.funds = payload;
    },
    [fetchFunds.rejected]: (state) => {
      state.fetchingFunds = false;
    },
  },
});

export default contentfulSlice.reducer;
