import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';

// MUI
import { styled, useTheme } from '@mui/system';
import useMediaQuery from '@mui/material/useMediaQuery';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import List from '@mui/material/List';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import LockIcon from '@mui/icons-material/Lock';

import { checkLogoutPage, logUserOut } from '../../lib/authFunctions';
import {
  getSubnavigationLinks, isPublicAddress, ONLINE_SERVICE_LINKS, PERSONNELFUND_LINKS, PUBLIC_LINKS,
} from '../../lib/navigationLinks';

import UserButton from './UserButton';
import MobileDrawerItem from './MobileDrawerItem';
import LanguageSelect from './LanguageSelect';
import BankerViewIndicator from './BankerViewIndicator';

const CustomList = styled(List)(({ theme }) => ({
  borderTop: '1px solid rgba(77, 87, 88, 0.3)',
  '& > .MuiListItem-root': {
    height: 40,
    color: 'rgba(77, 87, 88, 1)',
    textTransform: 'uppercase',
    borderBottom: '1px solid rgba(77, 87, 88, 0.3)',
    '& .MuiListItemText-primary': {
      color: 'rgba(77, 87, 88, 1)',
      fontWeight: 600,
      fontSize: '0.75rem',
      letterSpacing: 0.5,
    },
  },
  '& > .MuiCollapse-root': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white.main,
    paddingLeft: 35,
    paddingRight: 20,
    '& > .MuiCollapse-wrapper': {
      paddingTop: 7,
      paddingBottom: 20,
    },
    '& .MuiListItem-root': {
      height: 45,
      textTransform: 'uppercase',
      borderBottom: '1px solid rgba(255, 255, 255, 0.5)',
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
      '& .MuiListItemText-primary': {
        color: theme.palette.white.main,
        fontWeight: 600,
        fontSize: '0.75rem',
        letterSpacing: 0.5,
      },
    },
    '& .MuiCollapse-root': {
      paddingLeft: 12,
      '& .MuiListItem-root': {
        height: 45,
        borderBottom: '1px solid rgba(255, 255, 255, 0.2)',
        paddingBottom: 0,
        '& .MuiListItemText-primary': {
          fontWeight: 400,
          fontSize: '0.78rem',
          letterSpacing: 0.2,
          textTransform: 'none',
        },
      },
    },
  },
}));

const BottomDiv = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  height: 120,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingLeft: 20,
  paddingRight: 20,
}));

function MobileDrawer() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isDesktopScreen = useMediaQuery(theme.breakpoints.up('md'));
  const location = useLocation();
  const history = useHistory();

  const token = useSelector((state) => state.auth.token);
  const currentUser = useSelector((state) => state.auth.currentUser);
  const customerUser = useSelector((state) => state.auth.customerUser);
  const inPersonnelFundView = useSelector((state) => state.UIstate.inPersonnelFundView);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [sectionIndexOpen, setSectionIndexOpen] = useState(null);
  const [subItemIndexOpen, setSubItemIndexOpen] = useState(null);

  const inBankerView = currentUser && currentUser.isBanker && customerUser;

  // construct section menu based on user customer status
  const authSections = [];
  if (currentUser && currentUser.isEliteCustomer && !inPersonnelFundView) {
    authSections.unshift(
      {
        label: t('ONLINE_SERVICE'),
        href: '/varallisuus',
        subItems: ONLINE_SERVICE_LINKS(t).map((link) => {
          const subNavigationLinks = getSubnavigationLinks(link.href, t);
          if (subNavigationLinks.length > 0) return { ...link, subItems: subNavigationLinks };
          return link;
        }),
      },
    );
  }
  if (currentUser && currentUser.isPersonnelFundCustomer && !inBankerView) {
    authSections.unshift(
      {
        label: t('PERSONNEL_FUNDS'),
        href: '/henkilostorahasto',
        subItems: PERSONNELFUND_LINKS(t).map((link) => {
          const subNavigationLinks = getSubnavigationLinks(link.href, t);
          if (subNavigationLinks.length > 0) return { ...link, subItems: subNavigationLinks };
          return link;
        }),
      },
    );
  }
  if (isPublicAddress(location.pathname)) {
    authSections.unshift(
      {
        label: t('MONTHLYREPORTS.FUNDS_SEARCH'),
        href: '/julkinen/rahastohaku',
        subItems: PUBLIC_LINKS(t).map((link) => {
          const subNavigationLinks = getSubnavigationLinks(link.href, t);
          if (subNavigationLinks.length > 0) return { ...link, subItems: subNavigationLinks };
          return link;
        }),
      },
    );
  }

  const openDrawer = () => setDrawerOpen(true);

  const closeDrawer = () => {
    setSubItemIndexOpen(null);
    setSectionIndexOpen(null);
    setDrawerOpen(false);
  };

  const handleLogout = () => {
    closeDrawer();
    if (inBankerView || !inPersonnelFundView) {
      logUserOut(dispatch);
    } else {
      checkLogoutPage(dispatch, currentUser, location.pathname, history.push);
    }
  };

  const handleSectionClick = (index) => () => {
    // Either close current section or switch to another.
    setSectionIndexOpen(sectionIndexOpen === index ? null : index);
    // Reset sub item selection.
    setSubItemIndexOpen(null);
  };

  const handleSubItemClick = (index) => () => {
    // Either close current sub item or switch to another.
    setSubItemIndexOpen(subItemIndexOpen === index ? null : index);
  };

  return (
    <>
      <IconButton
        size="large"
        color="primary"
        aria-label="Avaa sivuvalikko"
        onClick={openDrawer}
      >
        <MenuIcon sx={{ fontSize: 40 }} />
      </IconButton>
      <Drawer
        anchor="right"
        component="nav"
        open={drawerOpen}
        onClose={closeDrawer}
        BackdropProps={{ invisible: true }}
        PaperProps={{
          style: {
            marginTop: isDesktopScreen ? 90 : 70,
            width: '100%',
            height: 'inherit',
          },
          elevation: 0,
        }}
      >
        {(token || isPublicAddress(location.pathname)) && (
          <CustomList disablePadding>
            {authSections.map((section, index) => (
              <MobileDrawerItem
                key={section.label}
                label={section.label}
                href={section.href}
                open={index === sectionIndexOpen}
                onClick={section.subItems
                  ? handleSectionClick(index)
                  : closeDrawer}
              >
                {section.subItems?.map((subItem, subItemIndex) => (
                  <MobileDrawerItem
                    key={subItem.label}
                    label={subItem.label}
                    href={subItem.href}
                    open={subItemIndex === subItemIndexOpen}
                    onClick={subItem.subItems
                      ? handleSubItemClick(subItemIndex)
                      : closeDrawer}
                  >
                    {subItem.subItems?.map((link) => (
                      <MobileDrawerItem
                        key={link.label}
                        label={link.label}
                        href={link.href}
                        onClick={closeDrawer}
                      />
                    ))}
                  </MobileDrawerItem>
                ))}
              </MobileDrawerItem>
            ))}
          </CustomList>
        )}
        {token ? (
          <BottomDiv>
            {inBankerView ? <BankerViewIndicator white /> : (
              <Box>
                <UserButton
                  className="white underline"
                  onClick={closeDrawer}
                />
              </Box>
            )}
            <Button
              id="navbar-logout"
              startIcon={<LockIcon />}
              onClick={handleLogout}
              aria-label={t('ARIA.LOGOUT')}
              variant="outlined"
              color="white"
              size="large"
            >
              {t('LOG_OUT')}
            </Button>
          </BottomDiv>
        ) : (
          <BottomDiv>
            <LanguageSelect
              variant="outlined"
              color="white"
            />
            <Button
              id="navbar-webpage"
              component={Link}
              href="https://www.eabgroup.fi"
              aria-label={t('ARIA.GO_TO_WEBPAGE')}
              variant="outlined"
              color="white"
              size="large"
              target="_blank"
              rel="noopener"
            >
              {t('GO_TO_WEBPAGE')}
            </Button>
          </BottomDiv>
        )}
      </Drawer>
    </>
  );
}

export default MobileDrawer;
