import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';

// MUI
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

// Custom components
import PrimaryButton from '../common/PrimaryButton';

// Catches errors and displays error message to prevent crashing UI.
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      errorInfo: null,
    };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error, errorInfo });
  }

  render() {
    const { error, errorInfo } = this.state;
    const { children, t, history } = this.props;

    const isProduction = process.env.REACT_APP_ENVIRONMENT === 'production';

    // Display error.
    if (error) {
      return (
        <Grid container direction="column" rowSpacing={2} padding={3}>
          <Grid item>
            <Typography variant="h4">
              {t('anErrorOccurred')}
            </Typography>
          </Grid>
          <Grid item>
            <PrimaryButton onClick={() => history.go(0)}>
              {t('reloadPage')}
            </PrimaryButton>
          </Grid>
          {!isProduction && (
            <Grid item>
              <details style={{ whiteSpace: 'pre-wrap' }}>
                {error?.toString() || '-'}
                <br />
                {errorInfo?.componentStack || '-'}
              </details>
            </Grid>
          )}
        </Grid>
      );
    }

    // Return normal UI.
    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
  t: PropTypes.func.isRequired,
  history: PropTypes.shape({
    go: PropTypes.func,
  }).isRequired,
};

export default withRouter(withTranslation()(ErrorBoundary));
