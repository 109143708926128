import React from 'react';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';

function LoadingContainer() {
  return (
    <Container
      maxWidth="xl"
      sx={{ display: 'flex', justifyContent: 'center', margin: 4 }}
    >
      <CircularProgress role="progressbar" />
    </Container>
  );
}

export default LoadingContainer;
