import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';

function PrimaryButton({
  startIcon,
  endIcon,
  children,
  disabled,
  size,
  onClick,
  type,
  round,
  component,
  to,
  id,
  ariaLabel,
  href,
}) {
  return (
    <Button
      type={type}
      size={size}
      disabled={disabled}
      variant="contained"
      color="primary"
      disableElevation
      startIcon={startIcon}
      endIcon={endIcon}
      onClick={onClick}
      style={{ borderRadius: round ? '16px' : 0 }}
      component={component}
      to={to}
      id={id}
      aria-label={ariaLabel}
      href={href}
    >
      {children}
    </Button>
  );
}

PrimaryButton.propTypes = {
  startIcon: PropTypes.node,
  endIcon: PropTypes.node,
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  size: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.string,
  round: PropTypes.bool,
  component: PropTypes.elementType,
  to: PropTypes.string,
  id: PropTypes.string,
  ariaLabel: PropTypes.string,
  href: PropTypes.string,
};

PrimaryButton.defaultProps = {
  startIcon: null,
  endIcon: null,
  disabled: false,
  size: 'medium',
  onClick: () => {},
  type: 'button',
  round: false,
  component: null,
  to: null,
  id: null,
  ariaLabel: null,
  href: null,
};

export default PrimaryButton;
