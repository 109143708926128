import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import axios from '../api/axiosSetup';

export const fetchTaxCountries = createAsyncThunk('customer/fetchTaxCountries', async () => {
  const response = await axios.get('api/customer/taxcountries');
  return response.data;
});

export const fetchSectors = createAsyncThunk('customer/fetchSectors', async () => {
  const response = await axios.get('api/customer/sectors');
  return response.data;
});

export const fetchEducations = createAsyncThunk('customer/fetchEducations', async () => {
  const response = await axios.get('api/customer/education');
  return response.data;
});

export const fetchIndustries = createAsyncThunk('customer/fetchIndustries', async () => {
  const response = await axios.get('api/customer/industries');
  return response.data;
});

export const postNewPersonCustomer = createAsyncThunk('customer/postNewPersonCustomer', async ({ customer }) => {
  const response = await axios.post('api/customer/contract/newperson', customer);
  return { ...response.data, status: response.status };
});

export const customerSlice = createSlice({
  name: 'customer',
  initialState: {
    taxCountries: [],
    fetchingTaxCountries: false,
    sectors: [],
    fetchingSectors: false,
    educations: [],
    fetchingEducations: false,
    industries: [],
    fetchingIndustries: false,
    addNewPersonCustomerResponse: null,
    postingNewPersonCustomer: false,
  },
  extraReducers: {
    [fetchTaxCountries.pending]: (state) => {
      state.fetchingTaxCountries = true;
    },
    [fetchTaxCountries.fulfilled]: (state, { payload }) => {
      state.fetchingTaxCountries = false;
      state.taxCountries = payload;
    },
    [fetchTaxCountries.rejected]: (state) => {
      state.fetchingTaxCountries = false;
    },
    [fetchSectors.pending]: (state) => {
      state.fetchingSectors = true;
    },
    [fetchSectors.fulfilled]: (state, { payload }) => {
      state.fetchingSectors = false;
      state.sectors = payload;
    },
    [fetchSectors.rejected]: (state) => {
      state.fetchingSectors = false;
    },
    [fetchEducations.pending]: (state) => {
      state.fetchingEducations = true;
    },
    [fetchEducations.fulfilled]: (state, { payload }) => {
      state.fetchingEducations = false;
      state.educations = payload;
    },
    [fetchEducations.rejected]: (state) => {
      state.fetchingEducations = false;
    },
    [fetchIndustries.pending]: (state) => {
      state.fetchingIndustries = true;
    },
    [fetchIndustries.fulfilled]: (state, { payload }) => {
      state.fetchingIndustries = false;
      state.industries = payload;
    },
    [fetchIndustries.rejected]: (state) => {
      state.fetchingIndustries = false;
    },
    [postNewPersonCustomer.pending]: (state) => {
      state.postingNewPersonCustomer = true;
      state.addNewPersonCustomerResponse = null;
    },
    [postNewPersonCustomer.fulfilled]: (state, { payload }) => {
      state.postingNewPersonCustomer = false;
      state.addNewPersonCustomerResponse = payload;
    },
    [postNewPersonCustomer.rejected]: (state) => {
      state.postingNewPersonCustomer = false;
    },
  },
});

export default customerSlice.reducer;
