import { LANGUAGE_OPTIONS } from '../lib/constants';

const LANGUAGE = 'language';

/**
 * Retrieves language saved in local storage.
 * @returns {string} - Selected language (e.g. 'fi').
 */
export function getLanguage() {
  const language = window.localStorage.getItem(LANGUAGE);
  return LANGUAGE_OPTIONS.some((option) => option.value === language)
    ? language : 'fi';
}

/**
 * Updates language in local storage.
 * @param {string} value - Selected language (e.g. 'fi');
 */
export function setLanguage(value) {
  if (!LANGUAGE_OPTIONS.some((option) => option.value === value)) {
    throw new Error('Invalid language!');
  }
  window.localStorage.setItem(LANGUAGE, value);
}
