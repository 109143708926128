/* eslint-disable */
export default {
  // New translations.
  // TODO: replace these with original translations?
  "home": "Home",
  "users": "Users",
  "notifications": "Notifications in Finnish",
  "contactUs": "Contact us",
  "myFundUnit": "My fund unit",
  "byLaws": "Bylaws in Finnish",
  "withdrawal": "Withdrawal",
  "updateContactInfo": "Update your contact information",
  "customerService": "Customer service",
  "chooseRules": "Choose rule you want to download",
  "personalDetails": "Personal Details",
  "myContactInfo": "My contact information",
  "name": "Name",
  "streetName": "Street name",
  "postalCode": "Postal code",
  "postOffice": "Post office",
  "emailAddress": "E-mail address",
  "mobileNumber": "Mobile number",
  "accountNumber": "Account number",
  "taxforIncome": "Tax-% for income",
  "taxLiability": "Tax liability",
  "usNationality": "US nationality",
  "usaCitizen": {
    "yes": "I confirm that I am a US citizen.",
    "no": "I confirm that I am not a US citizen."
  },
  "countriesOfTaxResidency": "Countries of tax residency",
  "countryOfTax": "Country of taxation",
  "usaTaxId": "The tax identification number of the USA (TIN or equivalent)",
  "addNewTaxCountry": "Add new tax country",
  "taxLiabilityFIN": "Liability to pay taxes in Finland",
  "taxId": "Tax identification number (TIN or equivalent)",
  "taxOnlyInFinland": {
    "yes": "I confirm that I am only a tax resident of Finland.",
    "no": "I confirm that I am (also) a tax resident of countries other than Finland."
  },
  "communicationWithEAB": "Communication with Elite Alfred Berg",
  "formOfDelivery": "Membership details: form of delivery",
  "deliverIn": "I want my membership information to be delivered",
  "confirm": "Confirm",
  "fundingNotification": "Funding notification",
  "selectedFund": "Selected fund",
  "fundingNotificationEnded": "Funding notification time has ended",
  "cancel": "Cancel",
  "ok": "OK",
  "error": "Error",
  "close": "Close",
  "withdrawalOfFundUnit": "Withdrawal of fund unit",
  "giveWithdrawalNotification": "Give withdrawal notification",
  "moreInformation": "More information",
  "readMoreAboutTaxation": "Read more about taxation",
  "detailsOnEmploymentContractExpiration" : "Details on employment contract expiration",
  "withdrawalNotification": "Withdrawal notification",
  "availableForWithdrawal": "Available for withdrawal TODO",
  "willWithdrawSum": "I will withdraw (€) TODO",
  "helperText" : {
    "required": {
      "requiredField": "Required field"
    },
    "typeError": {
      "useOnlyNumbers": "Use only numbers"
    }
  },
  "sumToBeWithdrawn": "Sum to be withdrawn",
  "noVAT": "Veroton TODO",
  "yesVAT": "Veronalainen rahasto-osuus TODO",
  "withholding": "Withholding",
  "toBePaidToAccount": "Maksetaan tilille TODO",
  "taxingInfo": "Taxing information TODO",
  "taxRate": "Tax rate",
  "fund": "Fund",
  "fundEstablished": "The Fund established",
  "fundFinancialPeriod": "The Fund’s financial period",
  "membershipPeriod": "Membership period",
  "fundUnitValue": "Value of fund unit",
  "restrictedAmount": "Restricted amount",
  "unitAvailableForWithdrawal": "Unit available for withdrawal",
  "date": "Date",
  "explanation": "Explanation",
  "withdrawableAmount": "Withdrawable amount",
  "bonusesDuringFinancialPeriod": "Bonuses funded during the company’s financial period",
  "fundingDate": "Funding date",
  "fundValueDate": "Fund's value date",
  "bonus": "Bonus",
  "anErrorOccurred": "An error occurred.",
  "reloadPage": "Reload page",

  // Original translations.
  "1 Security-oriented": "1 Security-oriented",
  "11101, Non-financial corporations, excl. housing corporations, public": "11101, Non-financial corporations, excl. housing corporations ",
  "11102, Non-financial corporations, excl. housing corporations, national private": "11102, Non-financial corporations, excl. housing corporations, national private ",
  "11103, Non-financial corporations, excl. housing corporations, foreign controlled": "11103, Non-financial corporations, excl. housing corporations, foreign controlled ",
  "112, Housing corporations": "112, Housing corporations",
  "11211, Housing companies, public": "11211, Housing companies, public",
  "11212, Housing companies, national private": "11212, Housing companies, national private",
  "11213, Housing companies, foreign controlled": "11213, Housing companies, foreign controlled",
  "11221, Other housing corporations, public": "11221, Other housing corporations, public",
  "11222, Other housing corporations, national private": "11222, Other housing corporations, national private",
  "11223, Other housing corporations, foreign controlled": "11223, Other housing corporations, foreign controlled",
  "121, Central bank": "121, Central bank",
  "1221, Deposit banks": "1221, Deposit banks",
  "12211, Deposit banks, public": "12211, Deposit banks, public",
  "12212, Deposit banks, national private": "12212, Deposit banks, national private",
  "12213, Deposit banks, foreign controlled": "12213, Deposit banks, foreign controlled",
  "1222, Other credit institutions": "1222, Other credit institutions",
  "12221, Other credit institutions, public": "12221, Other credit institutions, public",
  "12222, Other credit institutions, national private": "12222, Other credit institutions, national private",
  "12223, Other credit institutions, foreign controlled": "12223, Other credit institutions, foreign controlled",
  "1223, Other monetary financial institutions, excl. credit institutions": "1223, Other monetary financial institutions, excl. credit institutions",
  "12231, Other monetary financial institutions, excl. credit institutions, public": "12231, Other monetary financial institutions, excl. credit institutions, public",
  "12232, Other monetary financial institutions, excl. credit institutions, national private": "12232, Other monetary financial institutions, excl. credit institutions, national private",
  "12233, Other monetary financial institutions, excl. credit institutions, foreign controlled": "12233, Other monetary financial institutions, excl. credit institutions, foreign controlled",
  "123, Money market funds (MMF)": "123, Money market funds (MMF)",
  "1241, Investment funds (UCITS)": "1241, Investment funds (UCITS)",
  "1242, Other collective investment schemes": "1242, Other collective investment schemes",
  "125, Other financial intermediaries": "125, Other financial intermediaries",
  "12501, Other financial intermediaries, public": "12501, Other financial intermediaries, public",
  "12502, Other financial intermediaries, national private": "12502, Other financial intermediaries, national private",
  "12503, Other financial intermediaries, foreign controlled": "12503, Other financial intermediaries, foreign controlled",
  "126, Financial auxiliaries": "126, Financial auxiliaries",
  "12601, Financial auxiliaries, public": "12601, Financial auxiliaries, public",
  "12602, Financial auxiliaries, national private": "12602, Financial auxiliaries, national private",
  "12603, Financial auxiliaries, foreign controlled": "12603, Financial auxiliaries, foreign controlled",
  "127, Captive financial institutions and money lenders": "127, Captive financial institutions and money lenders",
  "12701, Captive financial institutions and money lenders, public": "12701, Captive financial institutions and money lenders, public",
  "12702, Captive financial institutions and money lenders, national private": "12702, Captive financial institutions and money lenders, national private",
  "12703, Captive financial institutions and money lenders, foreign controlled": "12703, Captive financial institutions and money lenders, foreign controlled",
  "128, Insurance corporations": "128, Insurance corporations",
  "12801, Insurance corporations, public": "12801, Insurance corporations, public",
  "12802, Insurance corporations, national private": "12802, Insurance corporations, national private",
  "12803, Insurance corporations, foreign controlled": "12803, Insurance corporations, foreign controlled",
  "129, Pension funds": "129, Pension funds",
  "1311, Central government": "1311, Central government",
  "13111, Budget economy of central government": "13111, Budget economy of central government",
  "13119, Other units of central government": "13119, Other units of central government",
  "1312, State government (not used in Finland)": "1312, State government (not used in Finland)",
  "1313, Local government": "1313, Local government",
  "13131, Municipalities": "13131, Municipalities",
  "13132, Joint municipal authorities": "13132, Joint municipal authorities",
  "13133, The Ã…land Government": "13133, The Åland Government",
  "13139, Other units of local government": "13139, Other units of local government",
  "1314, Social security funds": "1314, Social security funds",
  "13141, Employment pension schemes": "13141, Employment pension schemes",
  "13149, Other social security funds": "13149, Other social security funds",
  "14, Households": "14, Households",
  "141, Employers and own-account workers": "141, Employers and own-account workers",
  "143, Employees": "143, Employees",
  "1441, Recipients of property income": "1441, Recipients of property income",
  "1442, Recipients of pensions": "1442, Recipients of pensions",
  "1443, Recipients of other transfers": "1443, Recipients of other transfers",
  "15, Non-profit institutions serving households": "15, Non-profit institutions serving households",
  "2 Cautious": "2 Cautious",
  "3 Moderate": "3 Moderate",
  "4 Return-oriented": "4 Return-oriented",
  "5 Aggressive": "5 Aggressive",
  "ACCOUNT_IS_FREE": "Fund custody services are free of charge",
  "ACCOUNT_STATEMENT": "Account statement",
  "ACCOUNT_TRANSFER": "Bank transfer",
  "ACCOUNTANT_REPORT": "Summary report",
  "ACCOUNTANT_REPORT_SUMMARY": "The summary report includes all the reports on your portfolio for the selected period.", 
  "ACTIVE_PORTFOLIOS": "Active portfolios",
  "ADDED_NEW_ORDER": "New order added.",
  "ADDITIONAL_CUSTOMER_INFORMATION": "Additional client information",
  "ADDITIONAL_INFORMATION": "Additional information",
  "ADDITIONAL_INFORMATION_ADDED_SUCCESSFULLY": "Additional information saved successfully",
  "ADDITIONAL_INVESTMENTS": "Depositions",
  "ADDITIONAL_INVESTMENTS_DURING_PERIOD": "Additional investments during the period",
  "ADDITIONAL_TAX_COUNTRY": "Other country of taxation",
  "ADDITIONS": "Additions",
  "ADDRESS": "Address",
  "COUNTRY_OF_RESIDENCE": "Residence Country",
  "AFGHANISTAN": "Afghanistan",
  "AGGRESSIVE": "Aggressive",
  "ALBANIA": "Albania",
  "alempi korkeakouluaste": "Lower-degree level tertiary education",
  "ALGERIA": "Algeria",
  "ALL": "All",
  "ALL_FUND_FAMILIES": "All fund companies",
  "CHOOSE_FUND_FAMILY": "Choose fund company",
  "FUND_FAMILIES": "Fund companies",
  "ALL_MAIN_CLASSES": "All asset classes",
  "ALL_SUB_CLASSES": "All sub classes",
  "ALL_TOTAL": "All total",
  "Alternative": "Alternatives",
  "ALTERNATIVEFUND": "Alternative investment fund",
  "Alternatives": "Alternatives",
  "AMERICAN SAMOA": "American Samoa",
  "AMOUNT": "Amount",
  "AMOUNT_PER_SHARE": "Quantity (€/units)",
  "AND_YOUR_NEW_PASSWORD": "and you have updated your password yourself.",
  "AND_YOUR_PASSWORD": "and you have entered a password yourself.",
  "ANDORRA": "Andorra",
  "ANGOLA": "Angola",
  "ANGUILLA": "Anguilla",
  "ANNUALLY": "Yearly",
  "ANTARCTICA": "Antarctica",
  "ANTIGUA AND BARBUDA": "Antigua and Barbuda",
  "ARE_YOU_SURE_YOU_WANT_TO_EXIT_FROM_TRANSACTION": "Are you sure you want to exit from order entry before the order is completed and confirmed?",
  "ARGENTINA": "Argentina",
  "Arkkitehti- ja insinööripalvelut, tekninen testaus ja analysointi": "Architectural and engineering activities; technical testing and analysis",
  "ARMENIA": "Armenia",
  "ARUBA": "Aruba",
  "Asia": "Asia",
  "Asia-Pacific": "Asia and Pacific",
  "Asset management fee": "Asset management fee",
  "ASSET_CHANGES": "Change in portfolio value",
  "ASSET_MANAGEMENT": "Asset management",
  "ASSET_MANAGEMENT_FEES": "Asset management fees",
  "ASSET_VALUE_IN_BEGINNING": "Initial value",
  "ASSET_VALUE_IN_BEGINNING_OF_PERIOD": "Asset value at the beginning of the period",
  "ASSET_VALUE_IN_END": "Ending value",
  "ASSET_VALUE_IN_END_OF_PERIOD": "Asset value at the end of the period",
  "ASSETMANAGEMENTFEE": "Asset management fee",
  "ASSETS": "Assets",
  "ASSETS_SUMMARY": "Asset summary",
  "Asuin- ja muiden rakennusten rakentaminen": "Construction of residential and non-residential buildings",
  "AUSTRALIA": "Australia",
  "AUSTRIA": "Austria",
  "AZERBAIJAN": "Azerbaijan",
  "BACK_TO_ASSET_REPORT": "Back to asset summary",
  "BACK_TO_HOLDINGS": "Back to the holdings",
  "BACK_TO_SEARCH": "Back to search",
  "BAHAMAS": "Bahamas",
  "BAHRAIN": "Bahrain",
  "Balanced funds": "Balanced funds",
  "Balanced Funds": "Balanced funds",
  "BALANCEDFUND": "Balanced fund",
  "BANGLADESH": "Bangladesh",
  "BANK_ACCOUNT": "Destination account (IBAN)",
  "BANK_ACCOUNT_NUMBER": "Bank account (IBAN)",
  "BANK_ACCOUNTS": "Bank account details",
  "BARBADOS": "Barbados",
  "BECOME_A_CUSTOMER_WITH_BANK_ACCESS_CODES": "Become a customer by identifying yourself online.",
  "BELARUS": "Belarus",
  "BELGIUM": "Belgium",
  "BELIZE": "Belize",
  "BENEFICIARIES": "Beneficiaries",
  "BENEFICIARY": "Payee",
  "BENEFICIARY_ACCOUNT_NUMBER": "Payee’s account number",
  "BENIN": "Benin",
  "BERMUDA": "Bermuda",
  "BHUTAN": "Bhutan",
  "BLOOMBERG": "Bloomberg",
  "BLOOMBERG_CODE": "Bloomberg code",
  "BOLIVIA": "Bolivia",
  "BONDFUND": "Interest fund",
  "BONDS": "Bonds",
  "BOSNIA AND HERTSEGOVINA": "Bosnia and Herzegovina",
  "BOTSWANA": "Botswana",
  "BOUGHT_TO_PORTFOLIO": "purchased for portfolio",
  "BOUVET ISLAND": "Bouvet Island",
  "BRAZIL": "Brazil",
  "BRIEFS": "Bulletins",
  "BRITISH INDIAN OCEAN TERRITORY": "British Indian Ocean Territory",
  "BROWSE_ORDERS": "Browse orders",
  "BRUNEI DARUSSALAM": "Brunei",
  "BULGARIA": "Bulgaria",
  "BURKINA FASO": "Burkina Faso",
  "BURUNDI": "Burundi",
  "BUSINESS_ID": "Business ID",
  "BUSINESS_INCOME": "Business",
  "BUY": "Buy",
  "Buy": "Purchase",
  "BUYRECEIVABLE": "Buys receivable",
  "BuyWithdrawal": "Charge for the purchase",
  "BYPASS_FOR_NOW": "Bypass for now",
  "CALCULATORY_PRICE": "Theoretical price including costs (EUR/unit)",
  "CALCULATION_OF_SECURITY_PROFITS_FOR_TAXREPORT": "CALCULATION ON PROFIT OR LOSS FROM ASSIGMENT OF SECURITIES AND BOOK-ENTRY SECURITIES",
  "CALL_RATES": "Call rates",
  "CAMBODIA": "Cambodia",
  "CAMEROON": "Cameroon",
  "CANADA": "Canada",
  "CANCEL": "Cancel",
  "CANCEL_TRANSACTIONS": "Cancel the transaction",
  "CAPE VERDE": "Cape Verde",
  "CAPITAL_GAIN": "Realized profit",
  "CAPITAL_INCOME": "Capital income",
  "CAPITAL_LOSS": "Realized loss",
  "CASH": "Cash",
  "Cash": "Cash",
  "Cash and others": "Cash and others",
  "Cash and Others": "Cash and equivalents",
  "CASH_RESERVED_FOR_BUYS": "Cash reserved for buys",
  "CAUTIOUS": "Cautious",
  "CAYMAN ISLANDS": "Cayman Islands",
  "CENTRAL AFRICAN REPUBLIC": "Central African Republic",
  "CHAD": "Chad",
  "CHANGE": "Unrealized P/L",
  "CHANGE_%": "Unrealized P/L %",
  "CHANGE_AFTER_COSTS": "Change after costs",
  "CHANGE_AND_OTHER_TRANSACTIONS": "Change in value",
  "CHANGE_ARGUMENTS_AND_SEARCH_AGAIN": "Change criteria and search again.",
  "CHANGE_PASSWORD": "Change the password",
  "CHANGE_YOUR_PASSWORD": "Change your password",
  "CHANGING_PASSWORD": "Password change",
  "CHECK_OUT_MORE_NEWS": "Read more news here",
  "CHILE": "Chile",
  "CHINA": "China",
  "CHOOSE": "Select",
  "CHOOSE_DATE": "Choose date",
  "CHOOSE_AT_LEAST_ONE_PORTFOLIO": "Select at least one portfolio.",
  "CHOOSE_AT_LEAST_ONE_TRANSACTION_TYPE": "Select at least one transaction type.",
  "CHOOSE_BANK_YOU_WANT_USE_FOR_IDENTIFYING": "Select the bank through which you wish to authenticate yourself.",
  "CHOOSE_CUSTOMER_YOU_WANT_TO_CREATE_USERID_FOR": "Select the client you want to create a User ID for.",
  "CHOOSE_FOR_VALUE_COMPARISON": "Select for return comparison",
  "CHOOSE_FUND": "Select a fund.",
  "CHOOSE_PORTFOLIO_FOR_DEPOSIT": "First select the portfolio into which you wish to make a deposit.",
  "CHOOSE_PORTFOLIO_FOR_MONTHLY_DEPOSIT": "First select the portfolio into which you wish to make a monthly deposit.",
  "CHOOSE_RISK_CLASS": "Select a risk class",
  "CHOOSE_TRANSACTION_TYPES": "Select transaction types",
  "CHOOSE_USER_YOU_WANT_TO_UPDATE": "Select the user whose password you want to change",
  "CHOOSE_USER_YOU_WANT_TO_LOGIN": "Select the user you want to login",
  "CHOOSING_PASSWORD": "Choosing a password",
  "CHOSEN_PORTFOLIO_HAS_NOT_HOLDINGS": "There are no holdings in the selected portfolio so a redemption can not be made. Choose another portfolio",
  "CHOSEN_PORTFOLIO_HAS_NO_BALANCE": "There is no balance in the selected portfolio so a withdrawal can not be made. Choose another portfolio",
  "CHOSEN_PORTFOLIO_HAS_NO_BALANCE_FOR_PURCHASES": "There is no balance in the selected portfolio so purchases can not be made. Choose another portfolio",
  "CHRISTMAS ISLAND": "Christmas Island",
  "CITIZENSHIP": "Citizenship",
  "CITIZENSHIP_REQUIRED": "Citizenship is required",
  "CLEAR": "Clear",
  "CLEARSELECTIONS": "Clear selections",
  "CLICK": "Click",
  "CLICK_ON_FLAG_TO_DOWNLOAD_INSTRUMENT_DOCUMENT": "Click on flag icon to download respective instrument document.",
  "CLOSE": "Close",
  "CLOSE_WINDOW": "Close the window",
  "COCOS (KEELING) ISLANDS": "Cocos (Keeling) Islands",
  "COLOMBIA": "Colombia",
  "COME_TO": "will be",
  "COME_TO_CUSTOMER": "Become a client",
  "COME_TO_ELITES_CUSTOMER": "Why does it pay off to be an Elite Alfred Berg customer?",
  "COMMISSION": "Order",
  "COMMISSION_CONFIRMATION": "Order authentication method",
  "COMMISSION_DATE": "Order date",
  "Commodities": "Commodities",
  "COMMODITYFUND": "Commodity fund",
  "COMMON_CONTRACT_TERMS": "General contract terms",
  "COMMON_FUND_DESCRIPTION": "In addition to the fixed management fee, the fund management company may deduct other costs from the assets of the fund, such as a performance-based management fee or custodian and trading fees. Further information on the total costs of a fund can be found in the Key Investor Information Document (KIID), and prospectus published by the fund management company.",
  "COMOROS": "Comoros",
  "COMPANY_SALE": "Corporate acquisition",
  "CONFIRM": "Confirm",
  "CONFIRM_MARK_TRANSACTION": "Confirm the subscription order",
  "CONFIRM_RECLAIM_TRANSACTION": "Confirm the redemption order",
  "CONFIRM_SIGN_IN": "Confirm sign-in",
  "CONFIRM_WITHDRAWAL_TRANSACTION": "Confirm the withdrawal order",
  "CONFIRMATION_CODE": "Confirmation code",
  "CONFIRMATION_CODE_IS_INVALID": "Invalid confirmation code",
  "CONFIRMATION_CODE_IS_MANDATORY": "A confirmation code must be entered.",
  "CONGO": "Congo",
  "CONGO THE DEMOCRATIC REPUBLIC OF THE": " The Democratic Republic of the Congo",
  "CONTACT_AND_BANK_INFORMATION": "Contact and bank information",
  "CONTACT_INFORMATION": "Contact information",
  "CONTACT_INFORMATION_CHANGED": "Contact information saved",
  "CONTACT_PERSON": "Contact person",
  "CONTACT_REQUEST": "Contact request",
  "CONTACT_REQUEST_SUCCESSFUL": "Contact request forwarded successfully!",
  "CONTACT_REQUEST_FAILED": "Failed to forward contact request. Please contact customer service.",
  "CONTINUE": "CONTINUE",
  "CONTRACT_FOR_COMMUNITY": "Institutional client contract",
  "CONTRACT_FOR_ORGANIZATION_CLIENTS": "Corporate client contract",
  "CONTRACT_FOR_PERSON": "Private client contract",
  "CONTRACT_FOR_PRIVATE_CLIENTS": "Private client contract",
  "CONTRACT_MUST_BE_SIGNED_BEFORE_CONTINUING": "You must sign the contract before you can continue.",
  "CONTRACT_TERMS_MUST_BE_ACCEPTED_BEFORE_CONTINUING": "You must accept the contract terms before you can continue.",
  "CONTRARIANFUND": "Contra fund",
  "COOK ISLANDS": "Cook Islands",
  "Corporate bonds (convertible bonds)": "Corporate bonds (convertible bonds)",
  "Corporate bonds (high yield)": "Corporate bonds (high yield)",
  "Corporate bonds (investment grade)": "Corporate bonds (investment grade)",
  "Corporate bonds (variable rate)": "Corporate bonds (variable rate)",
  "Corporate Debt (Convertibles)": "Corporate bonds (convertible bonds)",
  "Corporate Debt (Floating Rate)": "Corporate bonds (variable rate)",
  "Corporate Debt (High Yield)": "Corporate bonds (high yield)",
  "Corporate Debt (Investment Grade)": "Corporate bonds (investment grade)",
  "Corporate Debt (Non-Rated)": "Corporate Debt (Non-Rated)",
  "COST": "Service charge",
  "COSTA RICA": "Costa Rica",
  "COSTS": "Service fees",
  "COUNTER_ACCOUNT": "Destination account",
  "COUNTRY": "Country",
  "COUNTRY_REQUIRED": "Country is required",
  "CREATE_A_NEW_USERID": "Create a new User ID",
  "CREATING_NEW_PASSWORD": "Creating a new password",
  "CREATING_NEW_USERID": "Creating a User ID",
  "CREDIT_INTERESTS": "Loan interest fees",
  "CREDITINTERESTDEPOSITION": "Investment loan interest credit",
  "CREDITINTERESTWITHDRAWAL": "Investment loan interest debit",
  "CreditInterestWithdrawal": "Investment loan interest debit",
  "CROATIA": "Croatia",
  "CUBA": "Cuba",
  "CHOOSE_CURRENCY": "Choose currency",
  "CURRENCY": "Currency",
  "Currency": "Cash",
  "CURRENT_CUSTOMER": "Current client",
  "CURRENT_CUSTOMER_GET_USERID": "Are you already a client and want a User ID and password for the online service?",
  "CURRENT_INVESTMENT_SERVICES": "Current investment services",
  "CURRENT_PASSWORD": "Current password",
  "CURRENT_YEAR": "Year to date",
  "CURRENTLY_USED_SERVICES": "Currently used services",
  "CUSTOMER_ADDITIONAL_INFORMATION": "Additional client information",
  "CUSTOMER_FUNDS": "Client assets",
  "CUSTOMER_INFORMATION": "Customer Information",
  "CUSTOMER_SERVICE": "Customer service",
  "Customer Service": "Customer service",
  "Customer service": "Customer service",
  "CUSTOMERSHIP_CONSISTS": "Fund account agreement",
  "CYPRUS": "Cyprus",
  "CZECH REPUBLIC": "Czech Republic",
  "DAILY": "Daily",
  "DATA": "Data",
  "DATE": "Date",
  "DATE_FILTERS": "Date selection:",
  "DATE_RANGE": "Date range",
  "DAY": "Day",
  "DEBT": "Liabilities",
  "Debt": "Liabilities",
  "DEFAULT_PORTFOLIO": "Default portfolio",
  "DENMARK": "Denmark",
  "DEPOSITINTERESTDEPOSITION": "Deposit interest credit",
  "DEPOSITINTERESTWITHDRAWAL": "Deposit interest debit",
  "DEPOSITION": "Deposit",
  "Deposition": "Deposit",
  "DEPOSITION_INTERESTS": "Deposit rates",
  "DEPOSITIONS": "Deposits",
  "DEPOSITS": "Deposits",
  "DERIVATIVES": "Derivatives",
  "DERIVATIVESFUND": "Leverage fund",
  "DESCRIPTION": "Description",
  "DID_YOU_FORGET_YOUR_ACCOUNT": "Forgot your user ID or password",
  "DIFFERENCE":"Difference",
  "DISTRIBUTION_HISTORY_NOT_FOUND": "No allocation history was found.",
  "DIVIDEND": "Dividend",
  "DIVIDENDS": "Dividends",
  "DJIBOUTI": "Djibouti",
  "DOCUMENTS": "Documents",
  "DOMINICA": "Dominica",
  "DOMINICAN REPUBLIC": "Dominican Republic",
  "DOWNLOAD_SUSTAINABILITY_REPORT_PDF": "Download sustainability report (PDF)",
  "DUE_DATE": "Due date",
  "EAB_ASSET_MANAGEMENT": "EAB Asset Management",
  "ECUADOR": "Ecuador",
  "EDUCATION": "Education",
  "EDUCATION_AND_OCCUPATION": "Education and occupation",
  "EDUCATION_IS_REQUIRED": "Education is required information.",
  "EG": "E.g.",
  "EGYPT": "Egypt",
  "ei koulutusta": "No education",
  "EL SALVADOR": "El Salvador",
  "ELECTRONIC_SIGNATURE": "Electronic signature",
  "ELECTRONIC_TRADING": "Electronic trading",
  "Elintarvikkeiden valmistus": "Manufacture of food products",
  "ELITE_ASSET_MANAGEMENT_LTD": "EAB Group Plc",
  "ELITE_CUSTOMER_SERVICE": "Elite Alfred Berg Customer Service",
  "Elokuva- ja televisio-ohjelmatuotanto, musiikin kustantaminen": "Motion picture, video and television programme production, sound recording and music publishing activities",
  "Eläinlääkintäpalvelut": "Veterinary activities",
  "EMAIL": "Email",
  "Emerging market bonds": "Emerging market bonds",
  "Emerging Market Debt": "Emerging market bonds",
  "Emerging markets": "Emerging markets",
  "Emerging Markets": "Emerging markets",
  "EMPTY": "Empty",
  "ENGLISH": "english",
  "END_BALANCE": "Ending balance",
  "END_DATE": "End date",
  "EQUATORIAL GUINEA": "Equatorial Guinea",
  "Equities": "Stocks",
  "Other Equities": "Other Stocks",
  "EQUITY": "Equity",
  "EQUITYFUND": "Stock fund",
  "Erikoistunut rakennustoiminta": "Specialised construction activities",
  "ERITREA": "Eritrea",
  "ERROR_CANCELLING_TRANSACTION": "Oops! Cancelling the transaction failed or the transaction cannot be cancelled any more.",
  "ERROR_DETAILS": "Error messages",
  "ERROR_IN_CREATING_USERID": "An error occurred while creating a new User ID. You can try again by selecting “Cancel”.",
  "ERROR_SENDING_CONFIRMATION_CODE_BY_SMS": "An error occurred when sending an SMS containing the confirmation code. Please check that your telephone number has been typed correctly in your settings.",
  "ERROR_SENDING_INFORMATION": "An error in sending information",
  "ERROR_UPDATING_INFO": "An error occurred in updating the information.",
  "ERROR_IN_ONLINE_AUTHENTICATION": "Error with online authentication",
  "ERROR_IN_NEW_ORDER": "Error in creating new order.",
  "ESTONIA": "Estonia",
  "ETHIOPIA": "Ethiopia",
  "EUR_MARK": "€",
  "Europe": "Europe",
  "EXCECUTING_ORDER": "The order is being executed",
  "EXPECTED_END_VALUE_OF_100000_€_INVESTMENT": "Expected end value of a 100,000 euro investment",
  "EXPECTED_END_VALUE_OF_THE_WORST_CASE_SCENARIO": "Worst case scenario (5% probability) expected end value",
  "EXPENSE": "Fee",
  "EXTERNAL_ACCOUNT": "External account",
  'Factsheet': "Factsheet",
  "FALKLAND ISLANDS (MALVINAS)": "Falkland Islands (Malvinas)",
  "FAROE ISLANDS": "Faroe Islands",
  "FEE": "Fee",
  "FEE_DEBT": "Fee liabilities",
  "FEE_EUR": "Fee",
  "FEE_REBATES": "Fee rebates",
  "FEE_SUMMARY": "Fee summary",
  "FEEREBATE": "Fee rebate",
  "FIELD_CANT_BE_EMPTY": "The field may not be blank.",
  "FIJI": "Fiji",
  "FIN": "Finland",
  "FINANCIAL_INFORMATION": "Financial information",
  "FINLAND": "Finland",
  "FINNISH": "finnish",
  "FINNISH_DATE": "Ending date",
  "FIRST_NAME": "First name",
  "FIRST_NAME_IS_REQUIRED": "First name is required.",
  "Fixed income": "Interest",
  "Fixed Income": "Interest",
  "Flexible Fixed Income Strategies": "Flexible interest rate strategies",
  "Flexible interest rate strategies": "Flexible interest rate strategies",
  "FOR_SERVICES_OF_ELITE": "for Elite Alfred Berg services",
  "FOR_TRANSACTION": "transaction",
  "FOR_WHOLE_WEALTH": "Total assets",
  "FOREKNOWLEDGE_OF_DISTANCE_SELLING_AND_SERVICES": "Preliminary information on distance sales and services",
  "FORGOT_YOUR_ACCOUNT": "Forgot your User ID or password?",
  "FOUND_CUSTOMERS_WITH_THE_GIVEN_IDENTIFICATION": "Clients found with the given authentication information",
  "FOUND_USERS_WITH_BANK_ACCOUNT": "Users found through TUPAS authentication",
  "FRANCE": "France",
  "FREE_RANGE": "Custom date range",
  "FRENCH GUIANA": "French Guiana",
  "FRENCH POLYNESIA": "French Polynesia",
  "FRENCH SOUTHERN TERRITORIES": "French Southern Territories",
  "FREQUENT_PAYMENT": "Recurrent payment",
  "FROM": "From",
  "FROM_BEGINNING": "Since inception",
  "FROM_BEGINNING_OF_CONTRACT": "Since inception",
  "FROM_BEGINNING_OF_CONTRACT_ANNUAL": "From portfolio start, annual",
  "FROM_BEGINNING_OF_YEAR": "Year to date",
  "FROM_PORTFOLIO": "from portfolio",
  "FUND": "Fund",
  "FUND_BASIC_INFORMATION": "Basic information",
  "FUND_FAMILY": "Fund family",
  "FUND_KIID_MUST_BE_ACCEPTED_BEFORE_CONTINUING": "You must give acceptance before you can continue.",
  "FUND_MUST_BE_SELECTED": "A fund must be selected",
  "FUND_MUST_BE_SELECTED_FROM_LIST": "A fund must be selected from the list",
  "FUND_OF_FUNDS": "Fund of funds",
  "FUND_SEARCH": "Funds",
  "FUND_SEARCH_LINK": "Read about the range of funds",
  "FUND_SEARCH_TOOLTIP_CURRENCY": "Subscription currency of the Fund; Elite Alfred Berg automatically performs the necessary exchange transactions on your behalf.",
  "FUND_SEARCH_TOOLTIP_PROFIT_ONE_MONTH": "Monthly change (%) in the fund unit value in the euro",
  "FUND_SEARCH_TOOLTIP_PROFIT_ONE_YEAR": "Annual change (%) in the fund unit value in the euro.",
  "FUND_SEARCH_TOOLTIP_VALUE": "Price of the fund unit quoted in the subscription currency",
  "FUND_TO_CLAIM": "The fund to be redeemed",
  "FUND_TO_MARK": "The fund to be subscribed to",
  "FUNDS_NON_UCITS": "Funds (Non-UCITS)",
  "FUNDS_TOTAL_FOUND": "funds.\n Total found",
  "FUNDS_UCITS": "Funds (UCITS)",
  "GABON": "Gabon",
  "GAMBIA": "Gambia",
  "GENERAL": "General",
  "GENERAL_CONTRACT_TERMS": "General contract terms",
  "CONTRACT_TERMS_IMPORTANT_INFO": "General terms and conditions",
  "CONTRACT_CUSTOMER_INFO": "Customer information",
  "PERSONAL_DATA_PROCESSING": "Processing of personal data",
  "ACCESSIBILITY_STATEMENT": "Accessibility statement",
  "CUSTOMER_SERVICE_OPENING_HOURS": "9:00 a.m. - 5:00 p.m.",
  "GENERAL_INVESTMENT_PURSUIT_OF_PROFIT": "General investment activities (pursuit of return)",
  "GEORGIA": "Georgia",
  "GERMANY": "Germany",
  "GENERAL_SETTINGS":"General settings",
  "GET_USERID": "Create User ID and password",
  "GHANA": "Ghana",
  "GIBRALTAR": "Gibraltar",
  "GIFT": "Gift",
  "GIVE_YOUR_NEW_PASSWORD": "Enter your new password in the below fields and send the information.",
  "GIVE_YOUR_PASSWORD": "Enter your password in the below fields.",
  "Global": "World",
  "GO_TO_CUSTOMER_SERVICE_PAGE": "Go to the customer service page",
  "YOU_WILL_BE_REDIRECTED": "You will be redirected ",
  "TO_CUSTOMER_SERVICE_PAGE": "to the customer service page.",
  "TO_TAX_LIABILITY_PAGE": "to the Tax liability page.",
  "TO_CUSTOMER_INFO_PAGE": "to the Customer information page.",
  "Government bonds": "Government bonds",
  "Government Bonds": "Government bonds",
  "GREECE": "Greece",
  "GREENLAND": "Greenland",
  "GRENADA": "Grenada",
  "GROSS_SALARIES": "Gross salaries",
  "GROUPING_AND_ADMINISTRATION": "Grouping and administration",
  "GUADELOUPE": "Guadeloupe",
  "GUAM": "Guam",
  "GUATEMALA": "Guatemala",
  "GUERNSEY": "Guernsey",
  "GUINEA": "Guinea",
  "GUYANA": "Guyana",
  "HAITI": "Haiti",
  "Hallinto- ja tukipalvelut liike-elämälle": "Office administrative, office support and other business support activities",
  "HEADER_DANGER": "Error!",
  "HEADER_SAVED": "Saved!",
  "HEARD ISLAND AND MCDONALD ISLANDS": "Heard Island and Mcdonald Islands",
  "Hedge funds": "Hedge funds",
  "Hedge Funds": "Hedge funds",
  "HEDGEFUND": "Hedge fund",
  "HERE": "here",
  "HISTORICAL_PROFIT_IS_NOT_GUARANTEE_FOR_FUTURE_PROFIT": "Past performance does not guarantee future yields.",
  "HOLDINGS": "Holdings",
  "HOLDINGS_DISTRIBUTION": "Asset allocation",
  "HOLDINGS_DISTRIBUTION_HISTORY": "Historical allocation",
  "HOLY SEE (VATICAN CITY STATE)": "Vatican City State",
  "HONDURAS": "Honduras",
  "HONG KONG": "Hong Kong",
  "HUNGARY": "Hungary",
  "Huonekalujen valmistus": "Manufacture of furniture",
  "I_AM_GENERALLY_A_TAXPAYER_IN_ANOTHER_COUNTRY_THAN_FINLAND": "I am generally subject to taxation in a country other than Finland.",
  "I_HAVE_KNOWLEDGE_OF_THE_FOLLOWING_INSTRUMENTS": "I have knowledge of the following financial instruments",
  "I_HAVE_READ_FUND_KIID": "I have read and understood the contents of the fund prospectus, Key Investor Information Document (KIID) and the fund rules.",
  "I_HAVE_READ_GENERAL_INVESTMENT_SERVICE_TERMS": "I have read",
  "I_HAVE_READ_GENERAL_INVESTMENT_SERVICE_TERMS_2": "the General Terms and Conditions",
  "I_HAVE_READ_GENERAL_INVESTMENT_SERVICE_TERMS_3": "of the investment services and accept them as binding.",
  "I_HAVE_READ_SERVICE_RATES": "I have read",
  "I_HAVE_READ_SERVICE_RATES_2": "the service price list",
  "I_HAVE_READ_SERVICE_RATES_3": "and accept it.",
  "I_SIGN_THE_CONTRACT": "I assure that the above information is correct and sign the contract.",
  "ICELAND": "Iceland",
  "ID": "Code",
  "IDENTIFICATION_INFORMATION": "Authentication information",
  "IDENTIFY": "Identify yourself",
  "IDENTIFY_WITH_BANK_ACCOUNTS": "Select the bank",
  "IDENTIFY_TO_MODIFY_INFORMATION": "Identify yourself to modify information",
  "IDENTIFYING_CANCELLED": "Online authentication was interrupted",
  "IDENTIFYING_DIDNT_SUCCESS": "Online authentication either failed or was not executed. Return to the Online authentication page and try again.",
  "IDENTIFYING_FAILED_PLEASE_TRY_AGAIN": "Oops! Online authentication failed. Please try again or return to the main page.",
  "IDENTIFYING_IS_CANCELLED": "Online authentication was interrupted in the middle of the transaction. Please try again",
  "IDENTIFYING_IS_REJECTED": "Online authentication was rejected. Please check the access codes and try again.",
  "IDENTIFYING_REJECTED": "Online authentication was interrupted.",
  "IDENTIFYING_FAILED": "Online authentication failed.",
  "IDENTIFYING_SUCCEEDED_YOUR_ACCOUNT_IS": "Online authentication succeeded. Your User ID is",
  "IDENTIFYING_SUCCESS_YOUR_ACCOUNT_IS": "User to reset the password for: ",
  "IDENTIFYING_SUCCESS_YOUR_CONTACT_IS": "Client to create a User ID for",
  "IF_ANOTHER_WHAT": "If other, what?",
  "IF_ANY_QUESTIONS": "Any questions? Contact your personal banker.",
  "IF_YOU_CANT_FIND_FUND_TRY": "If you cannot find the fund you are looking for, try",
  "IF_YOU_HAVE_FORGOTTEN_ACCOUNTS": "If you have forgotten your User ID or password, you can retrieve your current User ID and/or change your password through TUPAS authentication",
  "Ilmaliikenne": "Air transport",
  "INDEXED_PROFIT_CHART_OF_PORTFOLIO": "Indexed return of the asset",
  "INDEXLINKEDBONDFUND": "Index bond fund",
  "INDIA": "India",
  "INDONESIA": "Indonesia",
  "INDUSTRY": "Industry",
  "INDUSTRY_IS_REQUIRED": "Industry is required information.",
  "Inflation-linked": "Inflation-linked",
  "INFO": "Info",
  "INHERITANCE": "Inheritance",
  "INPUTTING_ADDITIONAL_INFOMATION": "Entry of additional information",
  "INPUTTING_INVESTMENT_EXPERIENCE_INFORMATION": "Investment experience information",
  "INSTRUCTIONS": "Instructions",
  "INSTRUCTIONS_FOR_MONTHLY_DEPOSIT": "Monthly savings do not require a separate agreement with Elite Alfred Berg or your bank. You can simply define the payment as a recurrent payment in the online bank. When making a payment in the online service of your bank, enter the information of the payment form below and the payment schedule, e.g. monthly. When you make a payment, a corresponding amount will be deposited into your fund account, after which the system will automatically enter a subscription for the amount in the fund you have selected.",
  "INSTRUMENT_KNOWLEDGE": "Financial instrument knowledge",
  "INSTRUMENTS_NAME": "Instrument name",
  "INSTRUMENTS_NAME_AND_QUANTITY": "Security/book-entry security assigned, and number of units",
  "Inverse": "Inverted",
  "INVESTMENT_ADVICE": "Investment advice",
  "INVESTMENT_AREA": "Investment area",
  "INVESTMENT_EXPERIENCE": "Investment experience",
  "INVESTMENT_EXPERIENCE_AND_KNOWLEDGE": "Investment experience and knowledge",
  "INVESTMENT_EXPERIENCE_EXPLANATION": "The law also obligates us to ask about investment experience and knowledge.",
  "INVESTMENT_EXPERIENCE_IS_REQUIRED": "Investment experience is required information.",
  "INVESTMENT_EXPERIENCE_MAXIMUM": "Investment experience cannot be more than 100 years.",
  "INVESTMENT_EXPERIENCE_MINIMUM": "Investment experience must be at least 0 years.",
  "INVESTMENT_EXPERIENCE_MUST_BE_A_NUMBER": "Investment experience must be a number.",
  "INVESTMENT_EXPERIENCE_MUST_BE_AN_INTEGER": "Investment experience must be an integer.",
  "INVESTMENT_GOALS": "Investment goals",
  "INVESTMENT_GOALS_EXPLANATION": "Select the risk class that best describes your investment goals.",
  "INVESTMENT_INCOME_EG_DIVIDEND_INCOME_RENT_INCOME": "Investment income (e.g. dividend income, rent income)",
  "INVESTMENT_MONITORING": "Investment monitoring",
  "INVESTMENT_MONITORING_IS_REQUIRED": "Investment monitoring is required information.",
  "INVESTMENT_POLICY": "Investment policy",
  "INVESTMENT_SALES": "Investment sales",
  "INVESTMENT_SECTOR": "Investment sector",
  "INVESTMENT_STYLE": "Investment style",
  "INVESTMENT_WEALTH": "Investment assets",
  "INVESTMENT_WEALTH_AMOUNT": "Amount of investments",
  "INVESTMENT_WEALTH_IS_REQUIRED": "Investment assets are required information.",
  "INVESTMENT_WEALTH_MUST_BE_A_NUMBER": "Investment assets must be a number.",
  "INVESTMENT_WEALTH_MUST_BE_POSITIVE": "Investments must be a positive value.",
  "IRAN, ISLAMIC REPUBLIC OF": " Islamic Republic of Iran",
  "IRAQ": "Iraq",
  "IRELAND": "Ireland",
  "IS": "is",
  "IS_INVALID": "is invalid",
  "FILE": "File",
  "IS_TOO_HIGH": "is too high",
  "IS_TOO_LOW": "is too low",
  "IS_TOO_LARGE": "is too large",
  "TYPE_A_NUMBER": "enter a number",
  "ON_WEEKDAYS": "(Mon - Fri)",
  "MAX_TWO_DECIMALS": "Use maximum of two decimals.",
  "MAX_N_DECIMALS_1": "Use maximum of ",
  "MAX_N_DECIMALS_2": " decimals.",
  "IS_REQUIRED": "is required",
  "ISLE OF MAN": "Isle Of Man",
  "ISRAEL": "Israel",
  "ITALY": "Italy",
  "ITS_EASY_TO_OPEN_ACCOUNT": "Opening an account is easy. You can do it by identifying yourself online or by ordering a contract package by mail.",
  "JAMAICA": "Jamaica",
  "Japan": "Japan",
  "JAPAN": "Japan",
  "JERSEY": "Jersey",
  "JORDAN": "Jordan",
  "Julkinen hallinto ja maanpuolustus, pakollinen sosiaalivakuutus": "Public administration and defence; compulsory social security",
  "Juomien valmistus": "Manufacture of beverages",
  "Järjestöjen toiminta": "Activities of membership organisations",
  "Jätteen keruu, käsittely ja loppusijoitus, materiaalien kierrätys": "Waste collection, treatment and disposal activities; materials recovery",
  "Kaivostoimintaa palveleva toiminta": "Mining support service activities",
  "Kalastus ja vesiviljely": "Fishing and aquaculture",
  "Kansainvälisten organisaatioiden ja toimielinten toiminta": "Activities of extraterritorial organisations and bodies",
  "Kasvinviljely ja kotieläintalous, riistatalous ja niihin liittyvät palvelut": "Crop and animal production, hunting and related service activities",
  "KAZAKHSTAN": "Kazakhstan",
  "Kemikaalien ja kemiallisten tuotteiden valmistus": "Manufacture of chemicals and chemical products",
  "KENYA": "Kenya",
  "keskiaste": "Secondary level education",
  "Key Investor Information Document (KIID)": "KID",
  "Kiinteistöalan toiminta": "Real estate activities",
  "Kiinteistön- ja maisemanhoito": "Services to buildings and landscape activities",
  "KIRIBATI": "Kiribati",
  "Kirjastojen, arkistojen, museoiden ja muiden kulttuurilaitosten toiminta": "Libraries, archives, museums and other cultural activities",
  "Kivihiilen ja ruskohiilen kaivu": "Mining of coal and lignite",
  "Koksin ja jalostettujen öljytuotteiden valmistus": "Manufacture of coke and refined petroleum products",
  "Koneiden ja laitteiden korjaus, huolto ja asennus": "Repair and installation of machinery and equipment",
  "KOREA, REPUBLIC OF": "South Korea",
  "Kotitalouksien eriyttämätön toiminta": "Undifferentiated goods and services produced by private households",
  "Kotitalouksien toiminta kotitaloustyöntekijöiden työnantajina": "Services of households as employers of domestic personnel",
  "Koulutus": "Education",
  "Kulttuuri- ja viihdetoiminta": "Creative, arts and entertainment activities",
  "Kumi- ja muovituotteiden valmistus": "Manufacture of rubber and plastic products",
  "Kustannustoiminta": "Publishing activities",
  "KUWAIT": "Kuwait",
  "KYRGYZSTAN": "Kyrgyzstan",
  "Käsitelty": "Process completed",
  "Käsittelyssä": "Being processed",
  "Käteinen": "Cash",
  "Lakiasiain- ja laskentatoimen palvelut": "Legal and accounting activities",
  "Landline": "From a fixed connection",
  "LANGUAGE_SELECTION": "Language selection",
  "LANGUAGE": "Language",
  "WEB_SERVICE_LANGUAGE": "Web service language",
  "LANGUAGE_POPUP_TEXT": "The user interface of EAB Online Service is also available in English and Swedish. However, the primary service language at Elite Alfred Berg is Finnish and it has therefore no obligation to provide services in other languages. Key Investor Information Documents of EAB Funds are available in Finnish or Swedish. The availability of fund material in other languages cannot be guaranteed.",
  "LAST_NAME": "Last name",
  "LAST_NAME_IS_REQUIRED": "Last name is required.",
  "LATVIA": "Latvia",
  "LAW_FOR_MONEY_LAUNDERING_AND_TERRORISM": "The Act on Detecting and Preventing Money Laundering and Terrorist Financing obligates us to ascertain the origin of the assets that the client intends to invest.",
  "LEBANON": "Lebanon",
  "LESOTHO": "Lesotho",
  "LESS_THAN": "Less than",
  "LESS_THAN_ANNUALLY": "Less than once a year",
  "LIABILITIES": "Liabilities",
  "LIBERIA": "Liberia",
  "LIBYAN ARAB JAMAHIRIYA": "Libya",
  "LIECHTENSTEIN": "Liechtenstein",
  "LITHUANIA": "Lithuania",
  "LOADING_ASSET_CHANGES": "Loading asset changes",
  "LOADING_ASSET_SUMMARY": "Loading asset summary",
  "LOADING_ACCOUNTS": "Loading customerships",
  "LOADING_BALANCE": "Loading balance",
  "LOADING_BANKER_INFO": "Loading personal banker info", 
  "LOADING_FEE_SUMMARY": "Loading fee summary",
  "LOADING_FILE": "Loading file",
  "LOADING_FUNDS": "Loading funds",
  "LOADING_FUND": "Loading fund",
  "LOADING_FUNDS_FAILED": "Loading of funds failed.",
  "LOADING_GAINS_AND_LOSSES": "Loading realised profits/losses",
  "LOADING_HOLDINGS": "Loading holdings",
  "LOADING_INSTRUMENT_DOCUMENTS": "Loading instrument documents",
  "LOADING_INFORMATION": "Loading data",
  "LOADING_TAXREPORT": "Loading the tax report",
  "LOADING_HOLDINGS_DISTRIBUTION": "Loading asset allocation",
  "LOADING_HOLDINGS_DISTRIBUTION_HISTORY_CHART": "Loading allocation history chart of holdings",
  "LOADING_HOLDINGS_FAILED": "Loading of holdings failed",
  "LOADING_TAXREPORT_FAILED": "Loading of the tax report failed.",
  "LOADING_INDEXED_PROFIT_CHART_OF_PORTFOLIO": "Loading the indexed return chart of the portfolio",
  "LOADING_PORTFOLIOS": "Loading portfolios",
  "LOADING_NEWSLETTERS": "Loading newsletters",
  "LOADING_NOTES": "Loading notifications",
  "LOADING_RULES": "Loading bylaws",
  "LOADING_RULES_AND_NOTES": "Loading bylaws and notifications",
  "LOADING_CALCS": "Loading calculations",
  "LOADING_MIDYEAR": "Loading bonuses",
  "LOADING_SHARES": "Loading shares",
  "LOADING_PROFIT_SUMMARY": "Loading reference index values",
  "LOADING_REFERENCE_INDEX_VALUES": "Loading reference index values",
  "LOADING_REFERENCE_INDICES": "Loading reference indices.",
  "LOADING_REFERENCE_INDICES_FAILED": "Loading of reference indices failed.",
  "LOADING_RISK_DISTRIBUTION": "Loading risk allocation",
  "LOADING_SUMMARY": "Loading summary",
  "LOADING_TEN_LARGEST_INSTRUMENTS": "Loading 10 largest holdings",
  "LOADING_TRANSACTIONS": "Loading transactions",
  "UPDATING_TRANSACTIONS": "Updating transactions",
  "LOADING_TRANSACTIONS_FAILED": "Loading of transactions failed.",
  "LOADING_USER_INFO": "Loading user information",
  "LOADING_VALUE_CHART": "Loading values",
  "LOADING_VALUE_CHART_FAILED": "Loading of values failed.",
  "LOAN": "Loan",
  "LOCKED_SHARES": "Locked sections",
  "LOG_OUT": "Log out",
  "LOGIN_EXTRA_CONFIRMATION": "Additional log-in authentication",
  "LOGIN_INFO": "Login information",
  "LOGIN_INFORMATION_CHANGED": "Log-in codes changed.",
  "LOGIN_PERSONNEL_FUND": "Log-in to the Personnelfund-service",
  "LOGIN_PERSONNEL_FUND_INFO": "On the Personnelfund-service you can view details about your funds and ownerships. You can also handle all fund-related annual notices and change your personal info",
  "LOGGING_IN": "Logging you in, please wait ...",
  "LOSSES_TOGETHER": "Total losses",
  "LUXEMBOURG": "Luxembourg",
  "Lääkeaineiden ja lääkkeiden valmistus": "Manufacture of basic pharmaceutical products and pharmaceutical preparations",
  "Maa- ja vesirakentaminen": "Civil engineering",
  "Maaliikenne ja putkijohtokuljetus": "Land transport and transport via pipelines",
  "Maaperän ja vesistöjen kunnostus ja muut ympäristönhuoltopalvelut": "Remediation activities and other waste management services",
  "MACAO": "Macao",
  "MACEDONIA, THE FORMER  YUGOSLAV REPUBLIC OF": "Republic of Macedonia",
  "MADAGASCAR": "Madagascar",
  "MAIN_CLASS": "Asset class",
  "Mainostoiminta ja markkinatutkimus": "Advertising and market research",
  "Majoitus": "Accommodation service activities",
  "MALAWI": "Malawi",
  "MALAYSIA": "Malaysia",
  "MALDIVES": "Maldives",
  "MALI": "Mali",
  "MALTA": "Malta",
  "MANAGEMENT_FEE": "Management fee",
  "MANDATORY_FIELD": "Mandatory field",
  "MARK": "Subscribe",
  "MARK_AGAIN_CLAIMED_FUNDS": "Re-subscribe to the redeemed assets",
  "MARK_TO_PORTFOLIO": "Subscription to portfolio",
  "MARKET_VALUE": "Market value",
  "MARSHALL ISLANDS": "Marshall Islands",
  "MARTINIQUE": "Martinique",
  "Matkatoimistojen ja matkanjärjestäjien toiminta varauspalvelut": "Travel agency, tour operator and other reservation service and related activities",
  "MAURITANIA": "Mauritania",
  "MAURITIUS": "Mauritius",
  "MAXIMUM_100": "Max. 100%",
  "MAYOTTE": "Mayotte",
  "MERGE": "Merger",
  "Metallien jalostus": "Manufacture of basic metals",
  "Metallimalmien louhinta": "Mining of metal ores",
  "Metallituotteiden valmistus (pl. koneet ja laitteet)": "Manufacture of fabricated metal products, except machinery and equipment",
  "Metsätalous ja puunkorjuu": "Forestry and logging",
  "MEXICO": "Mexico",
  "MICRONESIA, FEDERATED STATES OF": " Federated States of Micronesia",
  "MIFID_EXPLANATION": "The Act on Detecting and Preventing Money Laundering and Terrorist Financing obligates us to ask the following information from our clients.",
  "MIN_MARK_FOR_FUND": "The minimum subscription to the fund is",
  "MINIMUM": "(minimum)",
  "MINIMUM_0": "Min. 0",
  "MINIMUM_1": "Min. 1",
  "MINIMUM_25": "Min. 25%",
  "MINIMUM_INVESTMENT": "Minimum subscription",
  "MINIMUM_INVESTMENT_AND_FEES": "Minimum investment and fees",
  "MINIMUM_INVESTMENT_AT_MOST": "Minimum investment at the most",
  "MINIMUM_INVESTMENT_TIME": "Minimum investment",
  "MINIMUM_REDEMPTION_FEE": "Minimum redemption fee",
  "MINIMUM_SUBSCRIPTION_FEE": "Minimum subscription fee",
  "Mobile": "With mobile phone",
  "MODERATE": "Cautious",
  "MODIFY": "Edit",
  "MODIFY_INFORMATION": "Edit Information",
  "MODIFYING_USER_INFO": "Editing user information",
  "MOLDOVA": "Moldova",
  "MONACO": "Monaco",
  "Money Market": "Money markets",
  "MONGOLIA": "Mongolia",
  "MONTENEGRO": "Montenegro",
  "MONTHLY": "Monthly",
  "MONTHLY_EXPEDITURE": "Regular monthly commitments",
  "MONTHLY_REPORT": "Monthly Report",
  "MONTHLY_REPORTS": "Monthly Reports",
  "MONTHLY_REPORTS_OWNED": "Monthly reports of the funds owned",
  "TO_MONTHLY_REPORTS": "See monthly reports for all fund series here",
  "MONTHS": "months",
  "MONTSERRAT": "Montserrat",
  "Moottoriajoneuvojen tukku- ja vähittäiskauppa sekä korjaus": "Wholesale and retail trade and repair services of motor vehicles and motorcycles",
  "Moottoriajoneuvojen, perävaunujen ja puoliperävaunujen valmistus": "Manufacture of motor vehicles, trailers and semi-trailers",
  "MORE": "More",
  "MORE_THAN": "More than",
  "MORE_OPTIONS": "More options",
  "MORNINGSTAR": "Morningstar",
  "MORNINGSTAR_RATING": "Morningstar Rating",
  "MOROCCO": "Morocco",
  "MOZAMBIQUE": "Mozambique",
  "Muiden ei-metallisten mineraalituotteiden valmistus": "Manufacture of other non-metallic mineral products",
  "Muiden koneiden ja laitteiden valmistus": "Manufacture of machinery and equipment n.e.c.",
  "Muiden kulkuneuvojen valmistus": "Manufacture of other transport equipment",
  "MUST_BE_A_NUMBER": "Must be a number",
  "MUST_BE_GIVEN": "must be entered",
  "MUST_BE_NUMERIC": "must be numeric",
  "Muu kaivostoiminta ja louhinta": "Other mining and quarrying",
  "Muu valmistus": "Manufacture of other products",
  "Muut erikoistuneet palvelut liike-elämälle": "Other professional, scientific and technical activities",
  "Muut henkilökohtaiset palvelut": "Other personal services",
  "MYANMAR": "Myanmar",
  "Nahan ja nahkatuotteiden valmistus": "Manufacture of leather and related products",
  "NAME": "Name",
  "NAME_YOUR_OWN_PORTFOLIOS": "Name your own portfolios",
  "NAME_YOUR_PORTFOLIOS_TO_FIND_THEM_EASIER": "You can rename your portfolios to find them easily in the portfolio view.",
  "NAMIBIA": "Namibia",
  "NAURU": "Nauru",
  "NEPAL": "Nepal",
  "NET_PROFIT": "Operating profit",
  "NETHERLANDS": "Netherlands",
  "NETHERLANDS ANTILLES": "Netherlands Antilles",
  "NEWSLETTERS": "Newsletter and releases",
  "NEW CALEDONIA": "New Caledonia",
  "NEW ZEALAND": "New Zealand",
  "NEW_CUSTOMER": "New customer",
  "NEW_DEPOSIT": "Make a new deposit",
  "NEW_MONTHLY_SAVING": "New monthly saving",
  "NEW_PASSWORD": "New password",
  "NEW_PASSWORD_MATCHES_OLD": "The new password is the same as the old password.",
  "NEW_PASSWORD_UPDATED": "Your new password has been updated.",
  "NEW_REDEMPTION": "New redemption",
  "NEW_SUBSCRIPTION": "New subscription",
  "NEW_WITHDRAW": "New withdrawal",
  "NEW_ORDER_PORTFOLIO_CASHBALANCE": "Portfolio balance / Withdrawable",
  "NEW_ORDER_ESTIMATED_CASH_FROM_OPEN_SELLS": "Estimate of the cash received from the redemptions",
  "NEW_ORDER_TOTAL_AVAILABLE_CASH": "Withdrawable cash",
  "NEW_ORDER_ESTIMATE": "estimate",
  "NEW_ORDER_PRECISE_SUM_WHEN_CONFIRMED": "The accurate amount is available as the redemptions are confirmed",
  "NEW": "New",
  "Nosto Elite Varainhoidon verkkopalvelusta": "Withdrawal from the Elite Alfred Berg’s online service",
  "NEXT": "Next",
  "NICARAGUA": "Nicaragua",
  "NIGER": "Niger",
  "NIGERIA": "Nigeria",
  "NIUE": "Niue",
  "NO": "No",
  "NO_CONTACTS_FOUND": "No clients were found based on the identification. You can return to identification.",
  "NO_FEES_FOUND": "No fees were found.",
  "NO_FUNDS_FOUND": "No funds were found with the given search criteria.",
  "NO_HOLDINGS_FOUND": "No holdings were found.",
  "NO_HOLDINGS_FOR_TAXREPORT": "No profit/loss from assignment during this taxation period.",
  "NO_INSTRUMENT_DOCUMENTS_FOUND": "No instrument documents found.",
  "NO_NEWSLETTERS": "No newsletters...",
  "NO_NEWSLETTERS_FOUND": "No notifications were found.",
  "NO_PORTFOLIOS_FOUND_FOR_WHICH_THIS_OPERATION_IS_ALLOWED": "You have no portfolios in which this function is allowed.",
  "NO_PREVIOUS_INVESTMENT_EXPERIENCE": "No previous investment knowledge/ experience",
  "NO_PROFITS_FOUND": "No returns were found.",
  "NO_TRANSACTIONS_FOUND": "No transactions were found with the given search criteria.",
  "NO_VALUES_FOUND_WITH_THE_GIVEN_DATE_RANGE": "No values were found for the given date range. Select a shorter time period, a custom date range, or since inception.",
  "NO_VALUES_FOUND_WITH_THE_DATE_RANGE": "No values were found for the given date range.",
  "Nordic Countries": "Nordic Countries",
  "NORFOLK ISLAND": "Norfolk Island",
  "North America": "North America",
  "NORTHERN MARIANA ISLANDS": "Northern Mariana Islands",
  "NORWAY": "Norway",
  "Nosto": "Withdrawal",
  "NOT_AVAILABLE": "Not available.",
  "NOT_DATE": "Not a date",
  "SELECT_EARLIER_DATE": "Select earlier date",
  "SELECT_LATER_DATE": "Select later date",
  "PORTFOLIO_START_DATE": "Portfolio's starting day",
  "DATE_FORMAT": "dd.mm.yyyy",
  "LOADING_MORE_INFO": "Loading more info...",
  "ERROR_WHILE_LOADING_CASH_INFO": "Error occurred while loading cash information. Cash information unavailable.",
  "NOT_ENOUGH_CASH_FOR_SUBSCRIPTION_MAKE_DEPOSITION": "Disposable cash is not enough to execute the subscription. The subscription order will be executed when there is enough cash on the account for the subscription. To execute the subscription orders, transfer at least",
  "NOT_ENOUGH_CASH_FOR_SUBSCRIPTION_MAKE_DEPOSITION_ENDING": "to the account",
  "NOT_ENOUGH_CASH_FOR_SUBSCRIPTION_MAKE_DEPOSITION_PLURAL": "Disposable cash is not enough to execute the subscriptions. The subscription orders will be executed when there is enough cash on the account for the subscriptions. To execute the subscription orders, transfer at least",
  "NOT_ENOUGH_CASH_FOR_SUBSCRIPTION_MAKE_DEPOSITION_PLURAL_ENDING": "to the account.",
  "NOT_ENOUGH_CASH_FOR_SUBSCRIPTION": "Disposable cash is not enough to execute the subscription. The subscription order will be executed when there is enough cash on the account for the subscription.",
  "NOT_ENOUGH_CASH_FOR_SUBSCRIPTION_ADD_MORE": "Disposable cash is not enough to execute the subscription.",
  "NOT_SELECTED": "Not selected",
  "NOT_YET_CUSTOMER": "Not our client yet? Become a client free of charge.",
  "NOTHING": "None",
  "NOW": "Now",
  "NUMBER_OF_SHARES": "Units",
  "OCCUPATION": "Occupation",
  "OCCUPATION_IS_REQUIRED": "Occupation is required information.",
  "Odottaa käsittelyä": "Waiting for processing",
  "OH_SNAP": "Oops!",
  "Ohjelmistot, konsultointi ja siihen liittyvä toiminta": "Computer programming, consultancy and related activities",
  "OK": "Ok",
  "OLD_PORTFOLIOS": "Old portfolios",
  "OMAN": "Oman",
  "Omien kiinteistöjen kauppa": "Buying and selling own real estate",
  "Omien tai leasing-kiinteistöjen vuokraus ja hallinta": "Renting and management of own or leased real estate",
  "ONE_MONTH_SHORT": "1 month",
  "ONE_YEAR_SHORT": "1 year",
  "TWO_YEARS_SHORT": "2 years",
  "ONLINE_SERVICE": "Online service",
  "ONLY_FOR_HOMELAND_TRANSFER": "In domestic payment transactions only",
  "ONLY_IF_ASKED": "Only fill in if required",
  "OPENED": "Open",
  "ONLINEID": "Online authentication",
  "OPEN_MONEY_TRANSACTIONS": "Open money transactions",
  "OPTIONAL_EMAIL": "Optional email address",
  "OPTIONAL_INFORMATION": "Optional information",
  "ORDERS": "Orders",
  "ORDER_NUMBER": "Order number",
  "ORDER_NEWSLETTERS": "Tilaamalla uutiskirjeen ja tiedotteet saat sähköpostiisi asiantuntijoidemme tuoreen näkemyksen markkinoista sekä rahanarvoisia neuvoja varallisuutesi hoitamiseen.",
  "ORDER_NEWSLETTERS_LANGUAGE": "Uutiskirjeen ja tiedotteet ovat saatavilla vain suomenkielisinä.",
  "ORDER_SELECTIONS":"Subscription options",
  "ORGANIZATION_NAME": "Organisation’s name",
  "ORGANIZATION_NAME_IS_REQUIRED": "Organisation’s name is required",
  "ORIGIN_OF_ASSETS": "Origin of the assets",
  "ORIGIN_OF_FUNDS": "Origin of the assets",
  "ORIGIN_OF_FUNDS_IS_REQUIRED": "Origin of the assets is required information",
  "ORIGIN_OF_INVESTMENT_ASSETS": "Origin of the investment assets",
  "Osakerahasto": "Equity fund",
  "OTHER": "Other",
  "Other": "Other",
  "Other bonds": "Other bond investments",
  "Other Fixed Income Instruments": "Other bond investments",
  "Other Strategies": "Other strategies",
  "Other unclassified instruments etc.": "Other unclassified instruments etc.",
  "OTHER_DESCRIPTION": "Other description",
  "OTHERS": "Other",
  "OVER": "Over",
  "PAGE_NOT_FOUND": "Page not found",
  "Painaminen ja tallenteiden jäljentäminen": "Printing and production of recorded media",
  "PAKISTAN": "Pakistan",
  "PALAU": "Palau",
  "PALESTINIAN TERRITORY, OCCUPIED": "Palestine",
  "PANAMA": "Panama",
  "Paperin ja paperi- ja kartonkituotteiden valmistus": "Manufacture of paper and paper &amp; cardboard products",
  "PAPUA NEW GUINEA": "Papua New Guinea",
  "PARAGUAY": "Paraguay",
  "PASSWORD": "Password",
  "PASSWORD_AGAIN": "Re-enter the password.",
  "PASSWORD_CONFIRMATION_DOES_NOT_MATCH": "The new password and confirmation are not the same.",
  "PASSWORD_HAS_TO_BE_6_CHARACTERS_LONG": "The password must be a minimum of 6 characters long.",
  "PASSWORD_SAVING_FAILED": "Saving password failed.",
  "PASSWORD_SAVING_SUCCESSFUL": "Password saved.",
  "YOU_WILL_BE_REDIRECTED_TO_GENERAL_SETTINGS": "You will be redirected back to General settings page.",
  "PASSWORD_CONFIRMATION": "Confirm password",
  "SAVING_PASSWORD": "Saving password",
  "PASSWORD_IS_INVALID": "Invalid password",
  "PASSWORD_IS_MANDATORY": "A password must be entered.",
  "PASSWORD_IS_ONLY_USED_WHEN_LOGIN_TO_ELITE": "You need a password for logging in the Elite Alfred Berg Service.",
  "PASSWORD_IS_REQUIRED": "Password is required",
  "PASSWORD_IS_REQUIRED_AGAIN": "The password must be re-entered.",
  "PASSWORD_TOO_SHORT": "The password must be at least 6 characters.",
  "PASSWORD_UPDATE": "Updating the password",
  "PASSWORDS_ARE_NOT_SAME": "The passwords do not match.",
  "PAY_DATE": "Payment date",
  "PAYER": "Payer",
  "PAYMENT_ACCOUNT_HANDLE_MONEY_TRANSFER": "The fund account agreement, including its appendices, forms a contract between the customer and Elite Alfred Berg for the purpose of agreeing upon investment services between the customer and Elite Alfred Berg regarding custodian services for financial instruments, transactions with financial instruments, electronic services, and pooled accounts.",
  "PAYMENT_NOW": "Immediately",
  "PENALTY_INTERESTS": "Penalty interest fees",
  "PENALTYINTEREST": "Penalty interest",
  "PERFORMANCE_FEE": "Performance fee",
  "PERIOD": "Chosen period",
  "PERSONAL_BANKER": "Personal banker",
  "PERSONAL_INFORMATION": "PERSONAL INFORMATION",
  "PERSONAL_SETTINGS": "Personal information and settings",
  "PERSONAL_INFO": "Personal information",
  "PERSONNEL_FUND_CUSTOMER_SERVICE": "Personnel Fund / Bonus Fund Customer Service",
  "PLEASE_CONTACT_ABOUT_PERSONNEL_FUNDS": "If you have any questions about Personnel Fund / Bonus Fund services, please contact our customer service.",
  "PERU": "Peru",
  "perusaste": "Basic education",
  "Peruttu": "Cancelled",
  "PHILIPPINES": "Philippines",
  "PHONE_NUMBER": "Telephone number",
  "PHONE_NUMBER_NEEDED": "(requires a valid phone number)",
  "PHONE_NUMBER_REQUIRED": "Telephone number is required",
  "PIECE": "shares",
  "PIECE_OF_SHARES": "total number",
  "PLEASE_CONTACT_IF_QUESTIONS": "If you have any questions, please contact our customer service.",
  "PLEASE_WAIT": "Please wait without closing the browser.",
  "Pohjois-Amerikka": "North America",
  "POLAND": "Poland",
  "POLITICALLY_INFLUENTIAL_ABROAD": "I live abroad and hold or have held significant public positions (politically influential person) in another country, or I am a family member or close business partner of such a person.",
  "PORTFOLIO": "Portfolio",
  "PORTFOLIO_NAME": "Portfolio name",
  "PORTFOLIO_DESELECT_ALL": "Delete the selections",
  "PORTFOLIO_FROM_TO_CLAIM": "First select the portfolio from which you wish to redeem fund units.",
  "PORTFOLIO_MANAGER": "Portfolio manager",
  "PORTFOLIO_MUST_BE_SELECTED": "Portfolio must be selected",
  "PORTFOLIO_SELECT": "Portfolio selection",
  "PORTFOLIO_SELECT_ALL": "Select all",
  "PORTFOLIO_TO_MARK": "First select the portfolio into which you wish to make a deposit.",
  "PORTFOLIO_TYPE": "Portfolio type",
  "PORTFOLIOS": "Portfolios",
  "PORTUGAL": "Portugal",
  "POST_OFFICE": "City/town",
  "POST_OFFICE_REQUIRED": "City/town is required",
  "Posti- ja kuriiritoiminta": "Postal and courier activities",
  "PRECISION": "Precision",
  "PREVIOUS": "Previous",
  "PRICE": "Price",
  "PRICE_DATE": "Value date",
  "PRICE_IN_ANOTHER_CURRENCY": "Price in instruments currency",
  "PRINT": "Print",
  "Private Equity": "Unlisted shares",
  "PROFILE_SETTINGS_CHANGED": "User’s profile settings changed",
  "PROFIT": "Portfolio return",
  "PROFIT_HISTORY": "Return history",
  "PROFIT_LOSS": "Profit / Loss",
  "PROFIT_PERCENTAGE_ONE_MONTH": "Return (EUR) 1 mo. %",
  "PROFIT_PERCENTAGE_ONE_YEAR": "Return (EUR) 1 y %",
  "PROFIT_SUMMARY": "Return summary",
  "Prospectus": "Prospectus",
  "PUERTO RICO": "Puerto Rico",
  "Puhelu_short": "tel",
  "PURCHASE_COST_ESTIMATE": "Deemed acquisition cost",
  "PURCHASE_DATE": "Purchase date",
  "PURCHASE_FEE": "Transaction costs",
  "PURCHASE_PRICES_TOGETHER": "Acquisition costs, total",
  "PURCHASE_VALUE": "Purchase price",
  "PURCHASE_DATE_FOR_TAX_PREPORT": "Acquisition date",
  "PURCHASE_VALUE_FOR_TAX_PREPORT": "Acquisition price",
  "PURPOSE_OF_ORDER": "Purpose of the subscription",
  "Pääkonttorien toiminta, liikkeenjohdon konsultointi": "Activities of head offices; management consultancy activities",
  "QATAR": "Qatar",
  "Raakaöljyn ja maakaasun tuotanto": "Extraction of crude petroleum and natural gas",
  "Radio- ja televisiotoiminta": "Programming and broadcasting activities",
  "Rahapeli- ja vedonlyöntipalvelut": "Gambling and betting services",
  "Rahoituspalvelut (pl. vakuutus- ja eläkevakuutustoiminta)": "Financial service activities, except insurance and pension funding",
  "Rahoitusta ja vakuuttamista palveleva toiminta": "Activities auxiliary to financial services and insurance activities",
  "Rakennuttaminen ja rakennushankkeiden kehittäminen": "Real estate development and development of building projects",
  "Ravitsemistoiminta": "Food and beverage service activities",
  "READ_MORE": "Read more",
  "Real Assets": "Real Assets",
  "Real assets": "Real Assets",
  "Real_Assets": "Real Assets",
  "Real Estate": "Real estate",
  "Real estates": "Real estate",
  "EUR": "EUR",
  "REAL_ESTATE_SALE": "Real estate sale",
  "REALIZED": "Settled",
  "REALIZED_GAINS/LOSSES": "Realised profits/losses",
  "Receivables": "Receivables",
  "RECIDENCY_COUNTRY": "Country of residence",
  "RECLAIM": "Redeem",
  "RECLAIM_FROM_PORTFOLIO": "sold from portfolio",
  "REDEMPTION": "Redemption",
  "REDEMPTION_FEE": "Redemption fee",
  "REDEMPTIONDEPOSITION": "Redemption deposit",
  "REDEMPTIONS": "Redemptions",
  "REFERENCE_INDEX": "Reference index",
  "REFERENCE_INDEX_CANNOT_BE_USED_WITH_THIS_FUND_AND_DATE_RANGE": "The selected reference index cannot be used in the chart with this fund and the selected date range.",
  "REFERENCE_NUMBER": "Reference number",
  "REGISTRATION_COUNTRY": "Country of registration",
  "REMARKED_FUND": "Fund to be re-subscribed to",
  "REQUIRED": "Required",
  "REPORT": "Report",
  "REPORTING": "Reporting",
  "RESELLER_CODE": "Retailer code",
  "RETRIEVE_YOUR_USER_ID": "Retrieve your user id or reset your password",
  "RETURN": "RETURN",
  "RETURN_%": "Return %",
  "RETURN_ORIENTED": "Return-oriented",
  "RETURN_TO_LOGIN": "Return to login",
  "RETURN_TO_BROWSE_ORDERS": "Return to browse orders",
  "RETURN_TO_START_PAGE_FROM": "Go back to the starting page.",
  "RÉUNION": "Réunion",
  "RISK_CLASS": "Risk class",
  "RISK_CLASS_FOR_ELITE_SERVICES": "Risk class for Elite Alfred Berg services",
  "RISK_CLASS_FOR_WHOLE_WEALTH": "Risk class for the total assets",
  "RISK_DISTRIBUTION": "Risk allocation",
  "RISK_STATISTICS": "Risk figures",
  "ROMANIA": "Romania",
  "Rulebook": "Rulebook",
  "RUSSIAN FEDERATION": "Russian Federation",
  "RWANDA": "Rwanda",
  "Sahatavaran sekä puu- ja korkkituotteiden valmistus": "Manufacture of sawn goods, wood and cork products",
  "SAINT BARTHÉLEMY": "Saint Barthélemy",
  "SAINT HELENA": "Saint Helena",
  "SAINT KITTS AND NEVIS": "Saint Kitts and Nevis",
  "SAINT LUCIA": "Saint Lucia",
  "SAINT MARTIN": "Saint Martin",
  "SAINT PIERRE AND MIQUELON": "Saint Pierre and Miquelon",
  "SAINT VINCENT AND THE GRENADINES": "Saint Vincent And The Grenadines",
  "SAMOA": "Samoa",
  "SAN MARINO": "San Marino",
  "SAO TOME AND PRINCIPE": "São Tomé and Príncipe",
  "SAUDI ARABIA": "Saudi Arabia",
  "SAVE": "Save",
  "SAVE_CHANGES": "Save changes",
  "SAVE_MONTHLY": "Save per month",
  "SAVE_PASSWORD": "SAVE PASSWORD",
  "SAVE_SUCCESS_MESSAGE": "Information saved successfully!",
  "SAVE_FAILED_MESSAGE": "Saving information failed.",
  "SAVING_INFORMATION": "Saving information",
  "SAVING_CUSTOMER_INFORMATION": "Saving customer information!",
  "SAVING_TAKES_TIME": "Creating client relationship may take 1-3 minutes.",
  "SAVINGS": "Savings",
  "SEARCH_BY_TYPING": "Search by typing",
  "SEARCH_FOR_FUND": "Search for fund",
  "SECTOR": "Sector",
  "SECTOR_IS_REQUIRED": "Sector is required",
  "SECURITY_ORIENTED": "Security oriented",
  "SELECT_PORTFOLIO": "Select portfolio",
  "SELECT_PORTFOLIOS": "Select portfolios",
  "SELECTED_SERVICE": "Selected service",
  "SELECT_PORTFOLIO_FIRST": "Select a portfolio first",
  "SELECT_SEND_TASK_REQUEST": "You can forward a contact request to your contact person, who will then contact you.",
  "SEND_TASK_REQUEST": "Send contact request",
  "SELECTED": "Selected",
  "SELF_SERVICE_INVESTING": "Self service investing",
  "Sell": "Sale",
  "SELL": "Sell",
  "SellDeposition": "Sales deposition",
  "SELLER_CODE": "Seller code",
  "SELL_DATE": "Transaction date",
  "SELL_FEE": "Sales costs",
  "SELL_PRICES_TOGETHER": "Sales proceeds, total",
  "SELL_VALUE": "Sales proceeds",
  "SELLRECEIVABLE": "Sales receivable",
  "SEND_INFORMATION": "Send information",
  "SENEGAL": "Senegal",
  "SERBIA": "Serbia",
  "SERVER_ERROR": "Server error",
  "SERVICE_RATES": "Service pricelist",
  "SERVICE_RATES_MUST_BE_ACCEPTED_BEFORE_CONTINUING": "You must accept the service pricelist before you can continue.",
  "SESSION_TIMEOUT_SOON_TITLE": "Session timeout",
  "SESSION_TIMEOUT_SOON_QUESTION": "The session will time out soon. Do you want to continue the session?",
  "SESSION_TIMEOUT_NOTICE_TITLE": "The session has timed out",
  "SESSION_TIMEOUT_NOTICE": "The session has timed out because you have not used the network service for a while.",
  "END_SESSION": "End session",
  "SETTLEMENT_DATE": "Settlement date",
  "SEYCHELLES": "Seychelles",
  "SHARE": "Holdings",
  "SHARE_PRICE": "Price € / unit",
  "SHARES": "The units",
  "SHOW": "Show",
  "SHOW_ALL_BRIEFS": "Show all bulletins.",
  "SHOW_PURCHASES": "Show by purchase items",
  "SHOW_RETURN_AND_LOSS_EXPECTATIONS": "Show return and loss expectations",
  "SHOW_TAX_REPORT_FOR_CURRENT_YEAR": "Show the tax report for the current year",
  "SHOWN": "Shown",
  "SHOWN_ON_PAGE": "Shown on the page",
  "SHOW_INFO_POPUP_TITLE_30092022": "EAB Online Service",
  "SHOW_INFO_POPUP_CONTENT1_30092022": "Update work is carried out in the EAB Online Service. However, you can see the values of your holdings in real-time. Fund subscriptions and redemptions can only be made by phone between September 30, and October 10, 2022.",
  "SHOW_INFO_POPUP_CONTENT2_30092022": "Your own contact person and our customer service are happy to help you with any questions as well as with the subscription or redemption of funds. You can reach our customer service by phone at +358 201 558 610 (weekdays from 9.00 a.m. to 5 p.m.) or by e-mail at customerservice@eabgroup.fi.",
  "SHOW_INFO_POPUP_TITLE": "EAB Group Plc is now part of Evli Plc",
  "SHOW_INFO_POPUP_CONTENT1": "In the EAB Online Service you can see the values of your holdings until October 10, 2022. Information about your holdings will be transferred to the My Evli online service, where you can, among other things, monitor the development of your assets and execute orders from approximately October 10, 2022.",
  "SHOW_INFO_POPUP_CONTENT2": "The EAB Personnel Funds online service will remain unchanged after the merger.",
  "SHOW_INFO_POPUP_CONTENT3": "Your own contact person and our customer service are happy to help you with any questions or if you wish to subscribe or redeem funds before October 10, 2022. You can reach our customer service by phone at +358 201 558 610 (weekdays 9.00 a.m. to 5 p.m.) or by e-mail at customerservice@eabgroup.fi.",
  "SHOW_INFO_POPUP_CONTENT_HRJ_1": "EAB has merged with Evli on October 1, 2022. The merger does not require any action from our clients. We will serve you as usual also after the merger. In the online service you can, among others, see your fund shares, your personal details and contact us.",
  "SHOW_INFO_POPUP_CONTENT_HRJ_2": "Our customer service team will gladly help you if you have any questions, tel. 0201 558 660 (weekdays 9.00-17.00) or jasenpalvelu@eai.fi.",
  "SHOW_INFO_POPUP_CLOSE_TEXT": "Close the window",
  "SIERRA LEONE": "Sierra Leone",
  "SIGN": "Log in",
  "SIGN_IN": "Log in",
  "SIGN_IN_HERE_USING_BANK_ACCOUNT": "Log in here with online banking credentials",
  "SIGN_IN_USING_BANK_ACCOUNT": "Log in with online banking credentials",
  "SERVICE_REQUIRES_STRONG_IDENTIFICATION": "Using this service requires strong identification.",
  "CHOOSE_IDENTIFICATION_METHOD": "Choose identification method",
  "REGISTER_BY_IDENTIFYING": "Register by identifying.",
  "LOGIN_FAILED": "Login failed.",
  "SIGN_OUT": "Log out",
  "SINGAPORE": "Singapore",
  "SIX_MONTHS_SHORT": "6 months",
  "SLOVAKIA": "Slovakia",
  "SLOVENIA": "Slovenia",
  "SMS": "Text message",
  "SOCIAL_SECURITY_NUMBER": "Personal identity code",
  "SOLD_ASSETS": "Property sold",
  "SOLD_HOLDINGS_AND_REPORT_FOR_WINS_AND_LOSES": "DESCRIPTION OF THE ASSET, AND COMPUTATION OF CAPITAL GAIN/LOSS",
  "SOLOMON ISLANDS": "Solomon Islands",
  "SOMALIA": "Somalia",
  "SOMETHING_WENT_WRONG_WITH_REGISTRATION": "Oops! An error occurred in registration.",
  "Sosiaalihuollon avopalvelut": "Social work services without accommodation",
  "Sosiaalihuollon laitospalvelut": "Residential care activities",
  "SOURCE_TAXES": "Source taxes",
  "SOURCETAXDEPOSITION": "Source tax credit",
  "SOURCETAXWITHDRAWAL": "Source tax debit",
  "SOUTH AFRICA": "South Africa",
  "SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS": "South Georgia and the South Sandwich Islands",
  "SPAIN": "Spain",
  "SRI LANKA": "Sri Lanka",
  "START_BALANCE": "Opening balance",
  "START_DATE": "Start date",
  "STARTING_DATE": "Date of inception",
  "STATUS": "Status",
  "STOCKS": "Stocks",
  "STREETADDRESS": "Street address",
  "STREETADDRESS_REQUIRED": "Street address is required",
  "Structured": "Structured products",
  "Structured products": "Structured products",
  "Structured Products": "Structured products",
  "STRUCTURED_PRODUCTS": "Structured investment products",
  "SUB_CLASS": "Sub class",
  "SUBSCRIBE_NEWS_LETTER": "I want to subscribe to the monthly newsletter",
  "SUBSCRIBE_INFO_LETTER": "I want information on products, events and services",
  "SUBSCRIBE_STOCK_EXCHANGE_RELEASES": "I want to subscribe to press releases and stock exchange releases",
  "SUBSCRIBE_STOCK_EXCHANGE_ANNOUNCEMENTS": "I want to subscribe to stock exchange releases",
  "SUBSCRIBE_WEEKLY_LETTER": "Jag prenumererar på veckobrevet",
  "SUBSCRIPTION": "Subscription",
  "SUBSCRIPTION_FEE": "Subscription fee",
  "SUBSCRIPTION_SMALL": "Subscription",
  "SUBSCRIPTIONS": "Subscriptions",
  "SUBSCRIPTIONWITHDRAWAL": "Subscription debit",
  "SUBSTRACTIONS": "Deductions",
  "SUDAN": "Sudan",
  "SUM_GROSS_EUR": "Gross total",
  "SUM_MUST_BE_AT_LEAST_MIN": "The amount must be at least the minimum subscription.",
  "SUM_MUST_BE_POSITIVE": "The amount must be a positive number.",
  "TAX_RATE_MUST_BE_POSITIVE": "Taxrate must be a positive number.",
  "SUM_NET_EUR": "Net total",
  "SUM_WITH_EMARK": "AMOUNT, €",
  "SUMMARY": "Summary",
  "SURE_YOU_WANT_TO_CANCEL": "Are you sure you want to cancel the selected",
  "SUSTAINABILITY_REPORTS": "Sustainability reports",
  "SUSTAINABILITY_REPORTS_EN": "Sustainability reports in English",
  "SVALBARD AND JAN MAYEN": "Svalbard and Jan Mayen",
  "SWAZILAND": "Swaziland",
  "SWEDEN": "Sweden",
  "SWEDISH": "swedish",
  "SWITZERLAND": "Switzerland",
  "SYRIAN ARAB REPUBLIC": "Syrian Arab Republic",
  "Sähkö-, kaasu- ja lämpöhuolto, jäähdytysliiketoiminta": "Electricity, gas, steam and air conditioning supply",
  "Sähkölaitteiden valmistus": "Manufacture of electrical equipment",
  "TAIWAN, PROVINCE OF CHINA": "Taiwan",
  "TAJIKISTAN": "Tajikistan",
  "Talonrakentaminen": "Construction of buildings",
  "TANZANIA, UNITED REPUBLIC OF": "United Republic of Tanzania",
  "TARGET": "Instrument",
  "TASMANIA": "Tasmania",
  "ADD_TAX_COUNTRY": "Add country of taxation",
  "ADD_TAX_COUNTRY_BUTTON": "Add country",
  "TAX_COUNTRY": "Country of taxation",
  "TAX_COUNTRY_IS_REQUIRED": "Country of taxation is required",
  "GET_TO_KNOW_INFORMATION": "Identification data",
  "UPDATE_GET_TO_KNOW_INFORMATION": "Updating of identification data",
  "TAX_LIABILITY": "Tax liability",
  "TAX_LIABILITY_SETTINGS": "Tax liability settings",
  "TAX_LIABILITY_SETTINGS_UPDATE": "Update tax liability settings",
  "TAX_LIABILITY_CHANGED": "Tax liability has been changed",
  "TAX_NUMBER_OR_ID": "Tax number / ID",
  "TAX_REPORT": "Tax report",
  "TAXPAYER_IN_UNITED_STATES_ADDITIONAL_REQUIREMENT": "A private individual shall also provide an applicable IRS w-8 / w-9 Form.",
  "TAXPAYER_IN_UNITED_STATES_MUST_PROVIDE_A_TAX_NUMBER_OR_ID": "Taxpayer in the United States must provide a tax number or ID.",
  "Tekstiilien valmistus": "Manufacture of textiles",
  "Televiestintä": "Telecommunications",
  "TEN_LARGEST_INVESTMENTS": "10 largest holdings",
  "TERMS": "Terms",
  "TERMS_FOR_MONTHLY_DEPOSIT_PART1": "By using the reference number you have received when you make a payment into EAB Group Plc. account No. FI94 3131 1000 2982 44, you confirm that you have read the simplified",
  "TERMS_FOR_MONTHLY_DEPOSIT_PART2": "prospectus and rules of the fund and EAB Group Plc’s brokerage and custody terms, and that you accept them.",
  "Terveyspalvelut": "Health services",
  "THAILAND": "Thailand",
  "THE_FIELD_IS_REQUIRED": "Required field",
  "THE_FUND_SEARCH": "fund search",
  "THE_FUNDS": "Assets",
  "THE_NEW_REDEMPTION": "New fund redemption",
  "THE_NEW_SUBSCRIPTION": "New fund subscription",
  "THE_ORDER": "",
  "THE_RECLAIM": "Redemption",
  "THE_VALUE_IS_NOT_A_DATE": "The value is not a date.",
  "THERE_ARE_VALIDATION_ERRORS": "The entered information is insufficient or includes errors. Please check the information.",
  "THERE_WERE_ERRORS_WHEN_SENDING_THE_INFORMATION": "An error occurred in sending information.",
  "THREE_MONTHS_SHORT": "3 months",
  "TWELVE_MONTHS_SHORT": "12 months",
  "Tieteellinen tutkimus ja kehittäminen": "Scientific research and development",
  "Tietokoneiden sekä elektronisten ja optisten tuotteiden valmistus": "Manufacture of computer, electronic and optical products",
  "Tietokoneiden, henkilökohtaisten ja kotitaloustavaroiden korjaus": "Repair of computers and personal and household goods",
  "Tietopalvelutoiminta": "Information service activities",
  "TIMOR-LESTE": "Timor-Leste",
  "TO_FUND": "for fund",
  "TODAY": "Today",
  "TOGO": "Togo",
  "Toimiala tuntematon": "Industry unknown",
  "TOKELAU": "Tokelau",
  "TONGA": "Tonga",
  "TOO_LONG_NAME": "Given name is too long.",
  "ONLY_LETTERS_NUMBERS_WHITESPACE": "Name can only contain letters, numbers and spaces",
  "TOO_MUCH_CLAIMS": "You cannot redeem more fund units than you own.",
  "TOP_TEN_MOST_PROFITABLE_FUNDS_ONE_MONTH": "Top 10 by return (one month)",
  "TOPICAL": "News",
  "TOTAL": "Total",
  "TOTAL_ALL": "Total all",
  "TOTAL_WEALTH": "Total assets",
  "TOTAL_WEALTH_IS_REQUIRED": "Total assets are required information.",
  "TOTAL_WEALTH_MUST_BE_A_NUMBER": "Total assets must be a number.",
  "TRADING_ACTIVITY": "Trading activity",
  "TRADING_ACTIVITY_IS_REQUIRED": "Trading activity is required information.",
  "TRANSACTION": "Transaction",
  "TRANSACTIONS": "Transactions",
  "TRANSACTION_DATE": "Transaction date",
  "TRANSACTIONS_FOUND_TOTAL": "Total found",
  "TRANSACTION_IS_NOT_READY": "Order entry not completed",
  "TRANSACTION_NUMBER": "Transaction number",
  "TRANSACTION_TYPE": "Transaction type",
  "TRANSACTION_TYPES": "Transaction types",
  "TRANSACTIONS_ARCHIEVENO": "Transaction archive number",
  "TRANSFER": "Transfer",
  "Transfer": "Transfer",
  "TRINIDAD AND TOBAGO": "Trinidad and Tobago",
  "TRUE_BENEFICIARIES": "Actual beneficiaries",
  "Tukkukauppa (pl. moottoriajoneuvojen ja moottoripyörien kauppa)": "Wholesale trade, except of motor vehicles and motorcycles",
  "TUNISIA": "Tunisia",
  "Tupakkatuotteiden valmistus": "Manufacture of tobacco products",
  "TUPASLOGININFO": "Log in using TUPAS",
  "TUPASLOGIN": "TUPAS-signin",
  "TUPASLOGIN_NO_USER": "No user account found.",
  "TURKEY": "Turkey",
  "TURKMENISTAN": "Turkmenistan",
  "TURKS AND CAICOS ISLANDS": "Turks and Caicos Islands",
  "TURNOVER": "Turnover",
  "Turvallisuus-, vartiointi- ja etsiväpalvelut": "Security and investigation services",
  "TUVALU": "Tuvalu",
  "TYPE": "Type",
  "TYPE_FOR_TRANSACTION": "Transaction type",
  "Työllistämistoiminta": "Employment activities",
  "UGANDA": "Uganda",
  "UKRAINE": "Ukraine",
  "UNFORTUNATELY_WE_COULD_NOT_FIND_WHAT_YOU_ARE_LOOKING_FOR": "Unfortunately we did not find what you searched.",
  "UNFINISHED_REDEMPTIONS_IN_PORTFOLIO_PART1": "The portfolio contains incomplete redemptions, which are estimated to generate",
  "UNFINISHED_REDEMPTIONS_IN_PORTFOLIO_PART2": " in cash, the accurate amount is available as the redemptions are confirmed. If the cash generated by the redemptions is enough for a subscription, the subscriptions will be executed with these funds.",
  "Unknown": "Others",
  "UNITED ARAB EMIRATES": "United Arab Emirates",
  "UNITED KINGDOM": "United Kingdom",
  "UNITED STATES": "United States",
  "UNITED STATES MINOR OUTLYING ISLANDS": "United States Minor Outlying Islands",
  "Unlisted": "Unlisted",
  "UPDATE_SUCCESS_MESSAGE": "Information updated successfully!",
  "UPDATE_FAIL_MESSAGE": "Information update failed.",
  "ALL_BUT_RESIDENCYCOUNTRY_UPDATED": "Contact information updated successfully. Updating residency country failed.",
  "UPDATE_TAX_INFORMATION": "We note that your taxation country information has not been updated. Please update the information concerned in order to continue using the service.",
  "UPDATE_TAX_AND_WEALTH_INFORMATION": "Some identification data is out of date. Please update them now to continue using the service.",
  "Urheilutoiminta sekä huvi- ja virkistyspalvelut": "Sports activities and amusement and recreation activities",
  "URUGUAY": "Uruguay",
  "USERNAME": "User ID",
  "USERNAME2": "Username",
  "USERNAME_AND_PASSWORD": "Username and password",
  "USERPROFILE_SETTINGS": "User profile settings",
  "USERPROFILE": "User profile",
  "UZBEKISTAN": "Uzbekistan",
  "Vaatteiden valmistus": "Manufacture of wearing apparel",
  "Vakuutus-, jälleenvakuutus- ja eläkevakuutustoiminta": "Insurance, reinsurance and pension funding activities",
  "WALLIS AND FUTUNA": "Wallis and Futuna",
  "VALUE": "Value",
  "VALUE_CHART": "Market value",
  "VALUE_DATE": "Value date",
  "VALUE_TOTAL": "Amount",
  "VANUATU": "Vanuatu",
  "WANT_TO_GET_BRIEF_EMAIL": "I wish to have the Elite Alfred Berg newsletter sent to my email address.",
  "Varastointi ja liikennettä palveleva toiminta": "Warehousing and support activities for transportation",
  "WARRANTS": "Warrants",
  "Veden otto, puhdistus ja jakelu": "Water collection, treatment and supply",
  "WEEKLY": "Weekly",
  "WEIGHT": "Weight",
  "WELCOME_TO_CUSTOMER": "We are pleased to have you as our customer. The contracts will be sent to you in the next few days.",
  "VENEZUELA": "Venezuela",
  "VERIFY_NEW_PASSWORD": "Confirm the new password",
  "VERIFY_ORDER_BY_GIVING_PASSWORD": "Confirm the order by entering your password in the below field",
  "VERIFY_ORDER_BY_ONLINE_IDENTIFICATION": "Confirm the assignment with online authentication",
  "VERIFY_PASSWORD_IS_REQUIRED": "The new password must be re-entered.",
  "Vesiliikenne": "Water transport",
  "WESTERN SAHARA": "Western Sahara",
  "WHAT?": "What?",
  "WHAT_IS_IT_ABOUT": "What does it mean?",
  "WHERE?": "Where?",
  "WHICH?": "Which?",
  "WHICH_ONE?": "What?",
  "WHOLE_HISTORY": "Whole history",
  "WHOLE_WEALTH": "Total assets",
  "Viemäri- ja jätevesihuolto": "Sewerage",
  "VIETNAM": "Vietnam",
  "VIRGIN ISLANDS, BRITISH": "British Virgin Islands",
  "VIRGIN ISLANDS, U.S.": "Virgin Islands U.S.",
  "WHAT_ARE_THE_BENEFITS": "What are the benefits of bond funding?",
  "WIN_OR_LOSE": "Profit or loss",
  "WINS_TOGETHER": "Total profits",
  "WITH_ONLINE_BANK_ACCOUNTS": "With bank access codes",
  "WITH_SUM": "to the amount of",
  "WITH_YOUR_USERNAME_AND_NEW_PASSWORD_YOU_CAN_LOGIN": "You can now log in the system by using your User ID and password.",
  "WITHDRAW_BACK": "Return",
  "WITHDRAW_PORTFOLIO_CASHBALANCE": "Portfolio balance / Withdrawable",
  "WITHDRAW_SAVE": "Withdraw",
  "WITHDRAW_SELECT_PORTFOLIO_INFOTEXT": "First select the portfolio from which you wish to make a withdrawal.",
  "WITHDRAW_SUM_ISMAXIMIUM": "You cannot withdraw more assets than the amount in the portfolio.",
  "WITHDRAW_SUM_ISMINIMIUM": "The amount to be withdrawn must be a positive number.",
  "WITHDRAW_SUM_ISNUMERIC": "The amount to be withdrawn must be numeric.",
  "WITHDRAW_SUM_REQUIRED": "The amount to be withdrawn must be entered.",
  "WITHDRAW_SUM_TITLE": "Enter the amount to be withdrawn",
  "WITHDRAWAL": "Withdrawal",
  "Withdrawal": "Withdrawal",
  "WITHDRAWAL_FROM_PORTFOLIO": "Withdrawal from portfolio",
  "WITHDRAWAL_SMALL": "Withdrawal",
  "WITHDRAWALS": "Withdrawals",
  "WITHDRAWALS_DURING_PERIOD": "Withdrawals during the period",
  "WORK_INCOME": "Earned income",
  "WORK_INCOME_MIFID": "Earned income",
  "WORKDAYS": "On weekdays",
  "World": "World",
  "WRITE_FUNDS_NAME": "Enter the name of the fund.",
  "WRONG_PASSWORD_OR_USERNAME": "Invalid password or username",
  "WRONG_CONFIRMATION_CODE": "Invalid confirmation code.",
  "Vuokraus- ja leasingtoiminta": "Rental and leasing activities",
  "Vähittäiskauppa (pl. moottoriajoneuvojen ja moottoripyörien kauppa": "Retail trade, except of motor vehicles and motorcycles",
  "X-RAYED_PORTFOLIO_HOLDINGS": "Portfolio (look through enabled)",
  "YEAR": "Year",
  "YEARS": "years",
  "YEMEN": "Yemen",
  "YES": "Yes",
  "Yleinen": "General",
  "ylempi korkeakouluaste": "Higher-degree level tertiary education",
  "YOU_ARE_CREATING": "You are creating",
  "YOU_CANNOT_BUY_ACCORDING_TO_YOUR_CONTRACT": "According to your contract, no purchases can be made from the portfolio via the online service. ",
  "YOU_CANNOT_SELL_ACCORDING_TO_YOUR_CONTRACT": "According to your contract, no sales can be made from the portfolio via the online service. ",
  "YOU_CANNOT_WITHDRAW_ACCORDING_TO_YOUR_CONTRACT": "According to your contract, no withdrawal assignments can be made from the portfolio via the online service. ",
  "YOU_CANNOT_DEPOSIT_ACCORDING_TO_YOUR_CONTRACT": "According to your contract, no deposits can be made from the portfolio via the online service. ",
  "YOU_CAN_NOW_LOGIN_TO_SYSTEM": "You can now log in the system by using your customer number and password.",
  "YOU_CAN_NOW_CLOSE_THIS_WINDOW": "You can now close this window.",
  "YOU_CAN_USE_ELITES_ANALYZES": "You will have a comprehensive range of funds with analyses, more than 1,000 funds, at your disposal.",
  "YOU_CAN_USE_ELITES_PERONAL_MANAGEMENT": "If you wish, you can attach the personal asset management service to your account.",
  "YOUR_ACCOUNT_NUMBER_IS": "Your customer number is",
  "YOUR_CURRENT_PASSWORD_IS_WRONG": "The current password is invalid.",
  "YOUR_PASSWORD_IS_CHANGED": "Your password has been changed.",
  "YOUR_USERACCOUNT_IS": "Your User ID is",
  "ZAMBIA": "Zambia",
  "ZIMBABWE": "Zimbabwe",
  "ZIP_CODE": "Postal code",
  "ZIP_CODE_REQUIRED": "Postal code is required",
  "ÅLAND": "Åland",

  "HOW_TO_SIGN_IN": "How to sign in to Web service",
  "CHECK_HOW_TO_SIGN_IN": "If you need help for signing in to Web service or you have forgotten your password, check the login instructions below.",
  "SEE_INSTRUCTIONS": "See instructions (in Finnish)",
  "ATTACHMENT_INSTRUCTION": "Drag attachment here or ulpoad from your files. Maximum file size is 20 Mb",
  "ADD_ATTACHMENT": "Add an attachment",
  "REMOVE_ATTACHMENT": "Remove an attachment",

  // kirjautumissivu
  "HOW_TO_SIGN_IN_AND_VIDEO": "How to sign in: Watch the video",
  "WELCOME_TO_EAB_WEB": "Welcome to the Elite Alfred Berg Web service.",
  "SIGN_IN_TO_SEE_OWN_INVESTMENTS": "After logging in, you can view your own investments.",
  "EAB_ONLINE_SERVICE": "EAB Online Service",
  "EAB_PERSONNEL_FUNDS": "EAB Personnel Funds",
  "TEXTMESSAGE_HAS_BEEN_SENT": "A text message has been sent to your phone that has a confirmation code.",
  "GIVE_CONFIRMATIONCODE": "Input the code to the following field.",
  "NO_MESSAGE_RECEIVED": "Didn't you receive the message?",
  "CONTACT_TO": "Contact to our",
  "OUR_CUSTOMER_SERVICE": "customer service",
  "ANY_QUESTIONS": "Any questions?",
  "CUSTOMER_SERVICE_INSTRUCTION": "Our customer service will help you with any questions about online services. Please do not hesitate to contact us!",
  "CUSTOMER_SERVICE_SERVICETIME": "9.00 - 17.00",
  "CUSTOMER_SERVICE_PHONENUMBER": "0201 558 610",
  "SIGN_IN_TO_EAB_WEB": "Sign in to web service",
  "SIGN_IN_TO_PERSONNEL_FUND": "Sign in to personnel fund",
  "SIGN_IN_USING_YOUR_CREDENTIALS": "Sign in using your credentials.",
  "SIGN_IN_USING_EXISTING_CREDENTIALS": "Sign in using existing credentials",

  // Henkilöstöpalveluiden kirjautuminen
  "PERSONNEL_FUNDS_WELCOME": "Welcome to the Personnel Fund / Bonus Fund Web Service. You can view details about your funds and ownerships in our Web Service.",
  "IDENTIFY_WITH_BANK_ACCOUNT_ID": "Please authenticate yourself",

  "IF_YOU_HAVE_FORGOTTEN_ID_OR_PASSWORD": "Retrieve your current User ID and or change your password by identifying yourself.",
  "FORGOT_ACCOUNT": "Login details forgotten",
  "USERS_FOUND_WITH_BANK_ACCOUNT": "Identification found",
  "USERS": "users",
  "USER": "user",
  "CHOOSE_USER": "Select a user",
  "GIVE_NEW_PASSWORD": "Enter your new password in the below fields.",
  "PASSWORD_CHANGED_OK": "Password changed",
  "PASSWORD_CHANGED_FAIL": "Password was not changed",
  "PASSWORD_FOR_ID": "Password for user",
  "HAS_BEEN_CHANGED": "has now been successfully exchanged.",
  "PASSWORD_WAS_NOT_CHANGED": "Password could not be changed to user",
  "GO_TO_LOGIN": "Go to login",

  //Navigaatio
  "GO_TO_WEBPAGE": "Go to web pages",
  "PERSONNEL_FUNDS": "Personnel and Bonus Fund",
  "ASSETS_AND_INVESTMENTS": "Asset Management and Investments",
  "SEB_LIFE_SERVICE": "SEB Life Web Service",
  "DETAILS_AND_SETTINGS": "Own details and settings",
  "CONTACT": "Contact",
  "HOME": "Home",
  "OWN_PORTFOLIOS": "My portfolios",
  "LOADING": "Loading",

  // Asiakkaaksitulon käännökset
  "I_DO_NOT_WANT_MY_ADDRESS_TO_BE_UPDATED": "I do not want my address to be updated on the basis of the information from the Finnish Population Register Centre. I will inform Elite Alfred Berg of a possible change of address myself.",
  "COUNTRY_OF_BIRTH": "Country of birth",
  "COUNTRY_OF_BIRTH_AND_NATIONALITY": "Country of birth and nationality",
  "ADD_NEW": "Add new",
  "SELECT_NATIONALITY": "Select nationality",
  "BY_PROVIDING_MY_EMAIL_ADDRESS": "By providing my e-mail address, I accept ",
  "ELECTRONIC_EXCHANGE": "the electronic exchange of information.",
  "ELITE_CAN_SEND_STATUTORY_BULLETINS": "Elite Alfred Berg can send statutory bulletins and other agreement material electronically. Elite Alfred Berg has the right to communicate amendments to the terms of agreement and service price list electronically by making them available to the Client in the electronic service, on the company website and/or by sending them to the e-mail address provided by the Client. The Client is aware of and accepts the fact that using electronic communication media, such as e-mail and fax, involves special risks, including, e.g., the possibility of the message not reaching the recipient and the content of the message being conveyed to or altered by an external party. Elite Alfred Berg has the right to expect that the messages received by electronic communication media are authentic and valid.",
  "I_WISH_TO_ORDER_MONTHLY_NEWSLETTER": "I wish to order Elite Alfred Berg’s monthly newsletter to my e-mail address.",

  "USUALLY_THE_GENERAL_TAX_LIABILITY": "Usually, the general tax liability (country of taxation) of a natural person is determined on the basis of primary residence or corresponding residency. If you are uncertain about your taxation status, please contact a tax expert. The Client must inform Elite of all their countries of taxation on their own initiative.",
  "LIABILITY_TO_PAY_TAXES_IN_FINLAND": "Liability to pay taxes in Finland",
  "I_CONFIRM_ONLY_FINLAND": "I confirm that I am only a tax resident of Finland.",
  "I_CONFIRM_ALSO_OTHER_THAN_FINLAND": "I confirm that I am (also) a tax resident of countries other than Finland.",
  "US_NATIONALITY": "US nationality",
  "I_CONFIRM_NOT_US": "I confirm that I am not a tax resident of the United States.",
  "I_CONFIRM_US": "I confirm that I am a tax resident of the United States.",
  "IF_YOU_ARE_A_US_CITIZEN": "NB! If you are a US citizen, you are a tax resident of the United States. If you were born in the United States, you are generally considered a US citizen, unless you have renounced your nationality.",
  "COUNTRIES_OF_TAX_RESIDENCY": "Countries of tax residency",
  "PLEASE_PROVIDE_INFORMATION_ON_ALL_THE_COUNTRIES": "Please provide information on all the countries in which you are tax resident. If a tax identification number is used in the country, please also enter the tax ID number. If you have questions concerning the determination of the country of tax residency, please contact a tax expert or the local tax authorities.",
  "THE_TAX_IDENTIFICATION_NUMBER_USA": "The tax identification number of the USA (TIN or equivalent)",
  "I_AM_SUBJECT_TO_TAXATION_IN_FINLAND": "I am subject to taxation in Finland.",
  "FINNISH_TAX_IDENTIFICATUON_NUMBER": "Finnish tax identification number (= personal identity number)",
  "TAX_IDENTIFICATION_NUMBER": "Tax identification number (TIN or equivalent)",
  "TIN_IS_REQUIRED": "TIN is required.",

  "INVESTMENT_COMPETENCE": "Investment competence",
  "OPERATING_IN_THE_FINANCIAL_SECTOR": "Operating in the financial sector",
  "I_OPERATE_IN_THE_FINANCIAL_SECTOR": "I operate / have operated in the financial sector in positions which require investment experience / knowledge.",
  "NUMBER_OF_YEARS": "Number of years",
  "ASSESSMENT_OF_THE_AVERAGE": "Assessment of the average size of the investment",
  "IN_USE": "In use",
  "FAMILIAR_WITH": "Familiar with",
  "NOT_FAMILIAR_WITH": "Not familiar with",
  "KNOWLEGDE_OF_INVESTMENT_SERVICES": "Knowledge of investment services",
  "LISTED_SHARES": "Listed shares",
  "UNLISTED_SHARES": "Unlisted shares",
  "STOCK_FUNDS": "Stock funds",
  "INTEREST_FUNDS": "Interest funds",
  "HEDGE_FUNDS": "Hedge funds",
  "REAL_ESTATE_FUNDS": "Real estate funds",
  "INSURANCE_INVESTMENT": "Insurance investment (endowment, pension, and savings insurances)",

  "POLITICALLY_INFLUENTIAL_PERSON": "Politically influential person",
  "ELITE_MUST_BE_INFORMED_IF_YOU_OCCUPY_CENTRAL_PUBLIC_POSITION": "EAB Asset Management Ltd must be informed if you occupy or have occupied a central public position in state administration or a managerial position in an international organisation. The same applies if a member of your family or a close business partner occupies or has occupied a similar position.",
  "CENTRAL_POSITIONS_INCLUDE": "Central positions include, for instance:",
  "HEAD_OF_STATE": "Head of State, Head of Government, Minister, Deputy Minister, or Assistant Minister",
  "MEMBER_OF_PARLIAMENT": "Member of Parliament",
  "MEMBER_OF_THE_SUPREME_COURT": "Member of the Supreme Court, Constitutional Court, or another high-level judicial body whose decisions cannot be appealed except in exceptional circumstances",
  "MEMBER_OF_THE_COURT_OF_AUDITORS": "Member of the Court of Auditors or a Board Member of a Central Bank",
  "AMBASSADOR": "Ambassador, Chargé d’Affaires, or a high-ranking officer in the armed forces",
  "MEMBER_OF_AN_ADMINISTRATIVE": "Member of an administrative, managerial or supervisory body of a state-owned enterprise",
  "MANAGERIAL_POSITION": "Managerial position in an international organisation.",
  "DO_YOU_HAVE_ANYTHING_TO_REPORT": "Do you have anything to report in relation to political influence?",
  "SOMETNING_TO_REPORT": "Something to report",
  "NOTHING_TO_REPORT": "Nothing to report",
  "IF_YOU_HAVE_SOMETHING_TO_REPORT": "If you have something to report, please fill in the Report on Political Influence and send it to the Elite Alfred Berg customer service.",
  "REPORT_ON_POLITICAL_INFLUENCE": "Report on Political Influence",

  "WITH_MY_SIGNATURE_I_CONFIRM": "With my signature, I confirm that the information I have provided above is correct and authorise Elite Alfred Berg to check the validity of my personal, credit, and bank account information, and I sign the agreement.",
  "THE_AGREEMENT_ENTERS_INTO_FORCE": "The agreement enters into force when Elite Alfred Berg has accepted the client relationship. Elite Alfred Berg has the right to reject a client relationship if the Client does not meet the statutory or Elite Alfred Berg’s requirements for the formation of a client relationship.",

  "WELCOME_TO_ELITE": "Welcome to Elite Alfred Berg!",
  "YOUR_CLIENT_RELATIONSHIP_HAS_SUCCESSFULLY_BEGUN": "Your client relationship has successfully begun!",
  "YOUR_USERNAME_IS_PRESENTED_BELOW": "Your username is presented below. Select a password that is at least 6 characters long. The password is used when logging in to the Elite Alfred Berg Online Service.",
  "YOUR_USERNAME_IS_PRESENTED_BELOW1": "Your username is presented below. Please memorize it.",
  "YOUR_USERNAME_IS_PRESENTED_BELOW2": "Select a password that is at least 6 characters long. The password is used when logging in to the Elite Alfred Berg Online Service.",
  "YOUR_CLIENT_RELATIONSHIP_IS_WAITING": "Your client relationship is waiting to be confirmed",
  "WE_WILL_PROCESS_THE_APPLICATION": "Thank you for your client application! We will process the application as soon as possible and contact you when the client relationship has been confirmed.",
  "YOUR_PASSWORD_HAS_BEEN_SAVED": "Your password has been saved",
  "NOW_YOU_CAN_LOG": "Now you can log in to the Elite Alfred Berg online service using your username and password.",
  "CLICK_THIS_TO_PROCEED_TO_THE_ELITE_ONLINE_SERVICE": "Click this to proceed to the Elite Alfred Berg Online Service.",
  "SAVING_PASSWORD": "Saving password",
  "CUSTOMER_ALREADY_EXISTS_TITLE": "An account has already been opened",
  "CUSTOMER_ALREADY_EXISTS_DESC": "You already have an existing account in web services.",

  // pankkiyhtetstieto
  "ADD_NEW_BANK_ACCOUNT": "Add new bank information",
  "ADD_NEW_BANK_ACCOUNT_THAT_CAN_BE_USED_TO_WITHDRAW_MONEY_FROM_PORTFOLIO": "Add new bank account number from which money can be withdrawn from portfolio",
  "ADD_INFORMATION": "Add information",
  "BANK_ACCOUNT_NUMBER_MODEL": "FI12 3456 7890 1234 56",
  "BANK_ACCOUNT_SAVE_SUCCESS_MESSAGE": "bank information saved successfully!",

  "PROMOTED_FUND": "Promoted fund",
  "NEWS": "News",
  "GO_TO_FRONTPAGE": "Go to frontpage",

  // Käännökset HRJ:n verotuksellinen asuinvaltio -kenttään
  "AFGANISTAN": "Afghanistan",
  "AHVENANMAA": "Åland",
  "ALANKOMAAT": "Netherlands",
  "ALANKOMAIDEN ANTILLIT": "Netherlands Antilles",
  "ANTARKTIS": "Antarctica",
  "ANTIGUA JA BARBUDA": "Antigua and Barbuda",
  "ARABIEMIIRIKUNNAT": "United Arab Emirates",
  "AZERBAIDZAN": "Azerbaijan",
  "BELGIA": "Belgium",
  "BOSNIA JA HERTSEGOVINA": "Bosnia and Hertsegovina",
"BOUVETNSAARI": "Bouvet Island",
  "BRASILIA": "Brazil",
  "BRITTILÄINEN INTIAN VALTAMEREN ALUE": "British Indian Ocean Territory",
  "BRITTILÄISET NEITSYTSAARET": "British Virgin Islands",
"CAYMANSAARET": "Cayman Islands",
  "DOMINIKAANINEN TASAVALTA": "Dominican Republic",
"EGYPTI": "Egypt",
"ESPANJA": "Spain",
"ETELÄ-AFRIKKA": "South Africa",
"ETELÄ-GEORGIA JA ETELÄISET SANDWICHSAARET": "South Georgia and the South Sandwich Islands",
"ETELÄ-KOREA": "South Korea",
"FALKLANDINSAARET": "Falkland Islands",
"FÄRSAARET": "Faroe Islands",
"FIDZI": "Fiji",
"FILIPPIINIT": "Philippines",
"GRÖNLANTI": "Greenland",
  "HEARD- JA MCDONALDSAARET": "Heard Island and McDonald Islands",
  "INTIA": "India",
  "IRAK": "Iraq",
  "IRAN": "Islamic Republic of Iran",
  "IRLANTI": "Ireland",
  "ISLANTI": "Iceland",
"ISO-BRITANNIA": "United Kingdom",
  "ITALIA": "Italy",
"ITÄ-TIMOR": "Timor-Leste",
"ITÄVALTA": "Austria",
  "JAMAIKA": "Jamaica",
  "JAPANI": "Japan",
  "JORDANIA": "Jordania",
"JOULUSAARI": "Christmas Island",
"KAMERUN": "Cameroon",
  "KANADA": "Canada",
"KAP VERDE": "Cape Verde",
"KAZAKSTAN": "Kazakhstan",
"KENIA": "Kenya",
"KIINA": "China",
"KIRGISIA": "Kyrgyzstan",
  "KOLUMBIA": "Colombia",
  "KOOKOSSAARET": "Cocos Islands",
"KREIKKA": "Greece",
  "KROATIA": "Croatia",
  "KUUBA": "Kuba",
  "KYPROS": "Cyprus",
  "LIBANON": "Lebanon",
  "LIETTUA": "Lithuania",
  "LUXEMBURG": "Luxembourg",
  "MAKEDONIA": "Macedonia",
"MALEDIIVIT": "Maledives",
  "MALESIA": "Malaysia",
"MANSAARI": "Isle of Man",
"MAROKKO": "Morocco",
"MEKSIKO": "Mexico",
"MIKRONESIA": "Mikronesia",
"NORJA": "Norway",
  "PALESTIINA": "Palestinian Territory",
  "POHJOIS-MARIAANIT": "Northern Mariana Islands",
  "PORTUGALI": "Portugal",
  "PUOLA": "Poland",
  "RANSKA": "France",
  "RANSKAN ETELÄISET ALUEET": "French Southern Territories",
"RUOTSI": "Sweden",
"SAINT BARTHELÉMY": "Saint Barthelémy",
  "SAINT KITTS JA NEVIS": "Saint Kitts and Nevis",
  "SAINT VINCENT JA GRENADIINIT": "Saint Vincent and The Grenadines",
  "SAINT-PIERRE JA MIQUELON": "Saint Pierre and Miquelon",
"SAKSA": "Germany",
"SAMBIA": "Zambia",
  "SÃO TOMÉ JA PRÍNCIPE": "São Tomé and Príncipe",
  "SAUDI-ARABIA": "Saudi Arabia",
"SUOMI": "Finland",
"SVALBARD JA JAN MAYEN": "Svalbard and Jan Mayen",
"SVEITSI": "Switzerland",
  "SWAZIMAA": "Swaziland",
  "TADZIKISTAN": "Tajikistan",
"TAIWAN": "Taiwan",
"TANSANIA": "Tanzania",
  "TANSKA": "Denmark",
  "THAIMAA": "Thailand",
  "TRINIDAD JA TOBAGO": "Trinidad and Tobago",
  "TSEKKI": "Czech Republic",
  "TURKKI": "Turkey",
  "TURKS- JA CAICOSSAARET": "Turks and Caicos Islands",
  "UKRAINA": "Ukraine",
"UNKARI": "Hungary",
  "UUSI-SEELANTI": "New Zealand",
"VATIKAANI": "Vatican",
"VENÄJÄ": "Russian Federation",
"VIRO": "Estonia",
  "WALLIS JA FUTUNA": "Wallis and Futuna",
  "YHDYSVALLAT": "United States",
  "VALKO-VENÄJÄ": "Belarus",
  "YHDYSVALTAIN NEITSYTSAARET": "Virgin Islands, U.S.",
  "YHDYSVALTAIN TYYNENMEREN ERILLISSAARET": "United States Minor Outlying Islands",

  "LOGIN": {
      "MOVE_TO_LOGIN": "Move to login",
      "CHECK_OUT_ONLINE_SERVICE": "Get acquainted with Elite Alfred Berg's services",
      "CHECK_OUT_PERSONNEL_FUNDS": "Get acquainted with the personnel funds",
      "ONLINE_SERVICE_IS_FOR_YOU": "A Web Service for those who have investments with Elite Alfred Berg. Fund preservation in Elite Alfred Berg is free-of-charge.",
      "THIS_SERVICE_IS_FOR_YOU": "This service is for those who have a personnel or bonus fund with Elite Alfred Berg. Here you can see the information connected with your own membership.",
      "EVLI_ONLINE_SERVICE": "EAB Online Service is now My Evli",
      "EVLI_ONLINE_SERVICE_IS_FOR_YOU": "The service allows clients to monitor their wealth development, subscribe to funds, buy shares, read company analyses and much more.",
      "EVLI_GO_TO_LOGIN": "Log in to My Evli",
      "EVLI_CHECK_OUT_ONLINE_SERVICE": "Get acquainted with Evli's services",
      "EVLI_MY_EVLI_LOGIN_LINK": "https://m2.evli.com/m2/wealth?lang=en",
      "EVLI_COM_LINK": "https://www.evli.com/en/",
  },

  "SHORT": {
      "DAY": "d",
      "WEEK": "wk",
      "MONTH": "mo.",
      "YEAR": "y",
      "PHONE_NUMBER": "Tel.",
      "MONDAY": "Mon",
      "TUESDAY": "Tue",
      "WEDNESDAY": "Wed",
      "THURSDAY": "Thu",
      "FRIDAY": "Fri",
      "SATURDAY": "Sat",
      "SUNDAY": "Sun",
      "EURO_PER_CALL": "€/call",
      "EURO_PER_MINUTE": "€/min"
  },

  "INFO_TEXTS": {
      "TRUE_BENEFICIARIES": "If a community or company has owners whose shareholding and/or the right to vote exceeds 25% or any natural person has the right to appoint or remove majority of the members of the board of directors of the community or company, their information must be given here.",
      "MIFID_REQUIRED": "Some customer information is missing or outdated. The law requires us to ask for some additional information. You can skip entering the information for the time being, but we recommend that you enter it at this stage.",
      "CONFIRMATION_CODE_REQUIRED": "A text message has been sent to your phone that has a confirmation code. Input the code to the following field and click confirm.",
      "404": "The file may have been transferred or deleted. Ensure that you have entered the address correctly.",
      "500": "An error occurred in processing your request, and the server could not resolve the problem. Please try again or contact our Customer Service.",
      "500_TITLE": "Oops!  An unexpected error occurred on the server.",
      "CONFIRMATION_CODE_REQUIRED_SMS_NOT_SENT": "A text message containing a confirmation code has already been sent to your mobile phone. Input the code to the following field and click confirm. A new message will not be sent until five minutes have elapsed from sending the message.",
      "ELECTRONIC_SIGNATURE_P1": "The client is assumed to be aware of and accept the fact that using electronic communication devices involves special risks, including the possibility that the message does not reach the recipient or that its content may be revealed to or altered by an outside party. Elite Alfred Berg has the right to assume that it can trust the authenticity and accuracy of the message it receives through electronic communication devices.",
      "ELECTRONIC_SIGNATURE_P2": "Elite Alfred Berg shall have the right to disclose information to a third party in accordance with the currently valid legislation.",
      "ELECTRONIC_SIGNATURE_P3_PART1": "The Client shall accept that, based on the disclosure obligation of securities brokers as per Investment Services Act, Elite Alfred Bergt publishes on its website information on the service provider, offered investment services, financial instruments and their risks, client classification, incentives, management of conflicts of interest, and procedures and principles applied to the offering of investment services, as well as prior distance marketing information as per the Consumer Protection Act",
      "ELECTRONIC_SIGNATURE_P3_PART2": "or by using another electronic communication device.",
      "ELECTRONIC_SIGNATURE_ON_THEIR_WEBSITE": "on their website",
      "ERROR_UPDATING_INFO": "An error occurred in updating your information. Possible reasons: An error has occurred in online identification, the user ID and password are incorrect, or the system contains inaccurate information. Please contact our Customer Service (contact information below) in order to have your contact information updated.",
      "ERROR_IN_ONLINE_AUTHENTICATION": "Online authentication failed. Possible reasons: An error has occurred in online identification, the user ID and password are incorrect, or the system contains inaccurate information. Please contact our Customer Service (contact information below) in order to have your contact information updated.",
      "NEW_CUSTOMER_SUCCESS_CONTRACT_PACKAGE_WILL_BE_SENT_BY_MAIL": "The client agreement documentation will be sent by mail to the address you submitted within the next few days.",
      "NEW_CUSTOMER_SUCCESS_TITLE": "We are pleased to welcome you as our client and thank you for the trust you have placed in us.",
      "NEW_CUSTOMER_USING_USERID": "From now on, you can log in to the service with your user ID and password. For the sake of data protection, we recommend that you keep your client number and password in different places.",
      "ORDER_CONFIRMATION_INFO": "Orders that are submitted before 10:00 a.m. on a banking day will be processed during the same day. The orders are processed in accordance with the rules of the funds concerned.",
      "SIGN_CONTRACTS": "Read the agreement terms carefully and sign them electronically.",
      "SUBSCRIPTION_CONFIRMATION_INFO": "When you subscribe for units in a Non-UCITS Fund in the name of a minor, you are required to submit a permission issued by the Local Register Office to the Fund Management Company.",
      "USERID_CREATED_TITLE": "A new user ID has been created",
      "YOU_CAN_NOW_LOGIN_TO_SYSTEM": "You can log in through this link now.",
      "ERROR_UPDATING_BANK_ACCOUNT": "Update of the destination account failed. Possible reasons: An error has occurred in online identification, the user ID and password are not correct, or the system contains incorrect information. Please contact customer service (contact information below) to have your contact information updated.",
      "ERRORS_WHEN_SENDING_THE_INFORMATION": "An error occurred in sending the form or other information. An error has been caused by some information being missing or in a wrong format. Below is a list of the error messages and the names of the fields containing errors."
  },

  "RISK_CLASS_DESCRIPTIONS": {
      "SECURITY_ORIENTED": "While holding the investment for the minimum recommended period, the risk of losing capital is low and accordingly target return is low (the amount of long-term inflation).",
      "CAUTIOUS": "While holding the investment for the minimum recommended period, the risk of losing capital is lower than the average equity investment and, accordingly, the target return is somewhat higher than the average return of mere fixed-income investments.",
      "MODERATE": "Within the minimum recommended period, the investment risk and return target correspond to the risk and return target of a balanced equity and fixed-income investment combination. The risk is somewhat lower than the risk of an equity investment, and the return target is higher than a fixed-income investment but lower than the return target of an equity investment.",
      "RETURN_ORIENTED": "While holding the investment for the minimum recommended period, the risk of the investment corresponds to the investment risk of the stock market. The return target for the same period is slightly higher than the return target of the stock market.",
      "AGGRESSIVE": "The return target within the recommended investment period substantially exceeds the average return of the stock market, and at the same time the risk of partial or total loss of capital is significantly higher than investing in the stock market."
  },

  "ORDER_TYPES": {
      "Withdrawal": "Withdrawal",
      "Buy": "Purchase ",
      "Subscription": "Purchase",
      "Sell": "Sale",
      "Redemption": "Sale"
  },

  "ORDERS_NAV": {
      "BROWSE_ORDERS": "Browse orders",
      "BUY_FUND": "Buy fund",
      "SELL_FUND": "Sell fund",
      "MAKE_ORDER": "Make an investment",
      "WITHRAW_MONEY": "Withdraw money from portfolio",
      "DEPOSIT_MONEY": "Transfer money to the portfolio",
      "BROWSE": "Browse",
      "BUY": "Buy",
      "SELL": "Sell",
      "WITHRAW": "Withdraw",
      "DEPOSIT": "Deposit"
  },

  "ORDERS_BUY": {
      "CHOOSE_PORTFOLIO": "Select a portfolio",
      "FUND_TO_BUY": "The fund to be redeemed",
      "PURPOSE_OF_BUY": "Purpose of purchase",
      "NEW_ORDER_PORTFOLIO_AVAILABLE_SUM": "Disposable cash",
      "NEW_ORDER_PORTFOLIO_BALANCE": "Portfolio balance",
      "BUY": "Buy",
      "DRAFT_NOTIFICATION": "The current value of your portfolio is not sufficient to make the purchase. We will process your purchase when funds have been transferred to your portfolio. Typically this will take 1-3 business days.",
      "CREATE_NEW_PORTFOLIO_TEXT": "At this moment you don't have a self-service portfolio. If you wish you may create one.",
      "HERE": "here",
      "CREATE_NEW_PORTFOLIO": "Create a self-service portfolio"
    },

  "ORDERS_SELL": {
      "FUND_TO_SELL": "Fund to be sold",
      "SELL_PORTIONS": "Units to be sold",
      "SELL_PORTIONS_AVAILABLE": "Units available to be sold",
      "SELL_ALL_PORTIONS": "Sell all",
      "SUM_ESTIMATE": "Estimate of sale amount",
      "ESTIMATE_INFO": "The estimate should be clearer later",
      "PERCENTAGE": "percentage",
      "MANAGEMENT_OF_REDEMPTION_FUND": "Way of processing redeemed funds",
      "WITHDRAW_FUNDS": "I want to have the funds on my bank account",
      "KEEP_FUNDS_IN_PORTFOLIO": "I will leave the funds in the portfolio",
      "TOOLTIP": "Sales orders are executed so that mutual fund units are taken from the portfolio in order of purchase. An order cannot target a specific purchase lot."
  },

  "ORDERS_DEPOSIT": {
      "CHOOSE_PORTFOLIO": "Select the portfolio",
      "DEPOSIT_HEADER": "Deposit money in your portfolio",
      "DEPOSIT_SUBHEADER_PAYTRAIL": "Here you can deposit money in your portfolio. The money you transfer will be immediately available to you on the EAB Online Service.",
      "DEPOSIT_SUBHEADER_TRANSFER": "You will receive information from here for depositing your fund portfolio from your bank account in your own fund portfolio.",
      "DEPOSIT_HELP": "You can deposit assets into an Elite Alfred Berg fund account by making a payment from your own bank using the information below. To make a payment, you need the payment reference and the receiver’s name and account number.",
      "BIC": "BIC",
      "RECEIVER_NAME": "Name of payee",
      "RECEIVER_ACCOUNT": "Payee’s account number",
      "BANK_NAME": "Bank",
      "PAYMENT_REFERENCE": "Payment reference",
      "ELITE_ACCOUNT_NAME": "EAB Asset Management Ltd / Client assets",
      "GIVE_AMOUNT": "Enter the amount to transfer",
      "CHOOSE_PAYMENT_METHOD": "Select payment method",
      "MOVE_TO_PAYMENT": "Make cash payment",
      "AMOUNT_TO_BE_TRANSFERRED": "Amount to be transferred",
      "AMOUNT_MUST_BE_BETWEEN": "The amount must be in between",
      "PAYMENT_METHOD": "Payment method",
      "DEPOSIT_INFORMATION": "Payment details",
      "RETURN_TO_ORDERS": "Return to orders",
      "PAYMENT_OK": "The cash payment in the portfolio was successful. The cash is immediately available for purchase orders.",
      "PAYMENT_NOK": "An error occurred and the payment was interrupted",
      "SAVING_DEPOSIT": "Processing your payment",
      "PAYMENT_CANCELLED": "The payment was interrupted",
      "CONTACT_CUSTOMER_SERVICE": "Please contact our customer service if necessary",
      "SUM_ISNUMERIC": "The amount must be numeric.",
      "PAYTRAIL_ONLINE_PAYMENT": "Paytrail online payment",
      "DEPOSIT_SUCCESSFUL": "Deposit successful"
  },

  "ORDERS_CONFIRMATION": {
      "AND_KEEP_FUNDS_IN_PORTFOLIO": "and the funds will stay in your portfolio.",
      "AND_WITHDRAW_FUNDS": "and the funds will be placed into your bank account.",
      "CONFIRM_BUY": "Confirm the purchase",
      "CONFIRM_SELL": "Confirm the sale",
      "CONFIRM_WITHDRAW": "Confirm the withdrawal order",
      "BUY_CONFIRMATION": "Purchase confirmation",
      "SELL_CONFIRMATION": "Sale confirmation",
      "WITHDRAW_CONFIRMATION": "Withdrawal order confirmation",
      "CONFIRM_KIID_MONTHLY_1": "I have read the ",
      "CONFIRM_KIID_MONTHLY_2": "contract terms and conditions",
      "CONFIRM_KIID_MONTHLY_3": ", ",
      "CONFIRM_KIID_1": "I have read the ",
      "CONFIRM_KIID_2": "Key Investor Information Document of the Fund",
      "CONFIRM_KIID_3": "General Terms and Conditions of Elite Alfred Berg's investment services",
      "CONFIRM_KIID_4": " and ",
      "CONFIRM_KIID_5": "the Fund Rules",
      "CONFIRM_KIID_6": " and I accept them.",
      "CONFIRM_KIID_DOWNLOAD_1": "Key Investor Information Document of the Fund (pdf)",
      "CONFIRM_KIID_DOWNLOAD_2": "General Terms and Conditions of Elite Alfred Berg's investment services (pdf)",
      "ENTER_PASSWORD": "Enter your password",
      "ENTER_CONFIRMATION_CODE": "Enter your confirmation code",
      "SELL": "sale",
      "WITHDRAW": "withdrawal",
      "TO_PORTFOLIO": "to portfolio",
      "CONFIRM_WITH_ONLINEID": "The order is confirmed by online authentication."
  },

  "ORDERS_WITHDRAW": {
      "BALANCE_AVAILABLE_FOR_WITHDRAW": "Disposable balance",
      "ACCOUNT_FOR_WITHDRAW": "Account to which the money will be raised ",
      "CHOOSE_BANK_ACCOUNT": "Choose account to which the money will be raised.",
  },

  "ORDERS_BUY_PIPE": {
      "YOURE_BUYING": "You are creating a subscription order for mutual fund",
      "CHOOSE_CUSTOMER": "Choose customership, to which portfolio you want to make the fund subscription",
      "NO_CUSTOMERS": "No suitable accounts were found.",
      "CHOOSE": "Choose customership",
      "WHAT_AMOUNT": "Amount",
      "TARGET_PORTFOLIO": "The units will be subscribed into portfolio",
      "SEE_DEPOSIT_INFO": "View information on deposition",
      "NOT_ENOUGH_MONEY": "The amount is greater than available funds. Transfer more money to fund account.",
      "CANCEL_BUY": "Cancel subscription",
      "WRITE_NAME_HERE": "Enter portfolio name here",
      "LOADING_DOCUMENTS": "Loading documents",
      "LOADING_INFORMATION": "Loading customerships",
      "BUY_SUCCESSFUL": "Fund subscription successful",
      "YOUVE_BOUGHT": "You have subscribed in the fund",
      "SUBSCRIPTION_PORTFOLIO": "Units were subscribed into portfolio",
      "GO_TO_WEB_SERVICE": "Proceed to the Online Service",
      "PORTFOLIO_NUMBER": "Portfolio number",
      "SINGLEPURCHASE": "One-time purchace",
      "MONTHLYSAVING": "Monthy savings agreement",
      "CHANGETOSINGLE": "Switch to one-time purchace",
      "CHANGETOMONTHLY": "Switch to monthy savings agreement"
  },

  "ORDERS_MONTHLY": {
      "START_MONTHLY_SAVING": "Start monthly saving", // "Aloita kuukausisäästäminen", 
      "START_MONTHLY_SAVING_HERE": "You can start monthly saving here.",
      "START_MONTHLY_SAVING_HERE_DESCRIPTION": "Monthly saving is an easy and simple way to start saving regularly. By saving monthly you diversify the consecutive risk when you save the amount you have chosen automatically every month.",
      "GIVE_NAME_TO_CONTRACT": "Give contract a name",
      "CONTRACT_NAME": "Name",
      "CONTRACT_NAME_MUST_BE_GIVEN": "Name is too long",
      "CONTRACT_NAME_EXAMPLE": "E.g. Joe's savings contract",
      "SAVE_FOR_ANOTHER_PERSON": "I want to start saving for another person",
      "SAVE_FOR_ANOTHER_PERSON_DESCRIPTION": "You can easily start saving for another person too. In that case contact our customer service by phone or by sending a contact request through the “Contact us” choice. Our customer service will give advice how to proceed with the matter.",
      "PAYMENT_FREQUENCY": "Payment occurrence",
      "PAYMENT_DATE": "Payment date",
      "CONFIRNG": "Confirm",
      "MONTHLY": "monthly",
      "EVERY_3_MONTHS": "every 3 months",
      "EVERY_6_MONTHS": "every 6 months",
      "FIRST_DAY": "first day of month",
      "15TH_DAY": "15th day of month",
      "LAST_DAY": "last day of month",
      "CREATING_MONTHLY_SUBSCRIPTION": "You are about to create a savings contract and a subscription into fund",
      "FREQUENCY_IS": "Occurrence of this order is",
      "DATE_IS": "and purchase is executed",
      "CONTRACT_CREATED_HEADER": "The savings agreement has been successfully concluded!",
      "CONTRACT_CREATED_TEXT": "You have now successfully completed your savings agreement. To complete your subscriptions, log on to your online bank and make a recurring payment using the information below. When the payment has arrived, we will make the subscription to the fund covered by your savings agreement.",
      "WANT_TO_MAKE_A_SUBSCRIPTION": "Do you also want to make a single subscription into the fund?",
      "MAKE_SUBSCRIPTION": "Make a single subscription",
      "RETURN_TO_ORDERS": "Return to orders",
      "CONTACT_CUSTOMER_SERVICE": "Contact us",
      "ELITE_ACCOUNT_NAME": "EAB Asset Management Ltd / Customer Assets",
      "ACCOUNT_NUMBER": "FI61 3131 1001 2315 90",
      "BANK_NAME": "Handelsbanken",
      "BIC": "HANDFIHH",
      "UNKNOWN": "XXXXX",
      "STATUS": {
          "DRAFT": "Draft"
      }
  },

  "ORDERS_MISC": {
      "HERE_YOU_CAN_BUY_FUND": "Here you can buy funds and mark them to your portfolio.",
      "HERE_YOU_CAN_SELL_FUND": "Here you can sell funds and receive the money from your units to your account.",
      "HERE_YOU_CAN_WITHDRAW_MONEY": "Here you can make a withdrawal to your bank account.",
      "CANCEL_ORDER": "Cancel order",
  },


  "LONG": {
      "DAY": "Day",
      "WEEK": "Week",
      "MONTH": "Month"
  },

  "FENNIA": {
      "FENNIA_ASSET_MANAGEMENT_LTD_INNER_CIRCLE": "Fennia Varainhoito Oy’s related parties",
      "REASON_FOR_BEING_INNER_CIRCLE": "Criteria for being considered a related party",
      "SUBSCRIPTION_CONFIRMATION_INFO": "If you intend to make a subscription in the name of a third party (e.g. a minor child or a company), please contact Fennia Asset Management Ltd’s customer service either by telephone, +358 10 503 7990, or via email, info.varainhoito@fennia.fi.",
      "DEFINITION_OF_INNER_CIRCLE": "A client is considered an entity or foundation that is Fennia Asset Management Ltd’s related party if Fennia Asset Management Ltd, Mutual Insurance Company Fennia, Fennia Life Insurance Company or a Supervisory Board member, Board member or deputy member, Managing Director or Deputy Managing Director, Auditor, Deputy Auditor or Principal Auditor, operative management member of any of these companies, or if a child, spouse, person living in circumstances similar to marriage (common law spouse or registered partner) of a person mentioned above exercises control over it alone or jointly in compliance with the Accounting Act."
  },

  "TRANSACTION_TYPENAMES": {
      "ADD": "Add",
      "ASSETMANAGEMENTFEE": "Management fee",
      "CFDENTER": "CFD enter",
      "CFDSETTLE": "CFD settle",
      "COST": "Service charge",
      "CREDITINTERESTDEPOSITION": "Investment loan interest credit",
      "CREDITINTERESTWITHDRAWAL": "Investment loan interest",
      "DELETE": "Deletion",
      "DELETED": "Deletion",
      "DEPOSITINTERESTDEPOSITION": "Deposit interest credit",
      "DEPOSITINTERESTWITHDRAWAL": "Deposit interest debit",
      "DEPOSITION": "Deposit",
      "DIVIDEND": "Dividend",
      "FEEREBATE": "Fee rebate",
      "FUTURESCONTRACTENTER": "Futures contract enter",
      "FUTURESCONTRACTSETTLE": "Futures contract settle",
      "FXTRADE": "FX trade",
      "INTEREST": "Interest",
      "MASTERBUY": "MasterBuy",
      "MASTERSELL": "MasterSell",
      "MERGE": "Merger",
      "MGTFEEREB": "Management fee",
      "PENALTYINTEREST": "Penalty interest",
      "REDEMPTION": "Sale",
      "REDEMPTIONDEPOSITION": "Sales deposition",
      "SELLRECEIVABLE": "Sales receivable",
      "SOURCETAXDEPOSITION": "Source tax credit",
      "SOURCETAXWITHDRAWAL": "Source tax debit",
      "SUBSCRIPTION": "Purchase",
      "SUBSCRIPTIONWITHDRAWAL": "Purchase settlement",
      "TRANSFER": "Transfer",
      "WITHDRAWAL": "Withdrawal",

      "PE_INTEREST_RETURN": "Additional interest",
      "PE_INTEREST_ADDITIONAL_RETURN": "Extra additional interest",
      "PE_INTEREST_FIXED": "Fixed rate",
      "CASH_DEPOSIT_LATE_ENTRY_COMPENSATION": "Late entry compensation",
      "CASH_DEPOSIT_RETROCESSION": "Fee reimbursements",
      "CASH_WITHDRAW_FOUNDING_COST": "Foundation fee",
      "CASH_WITHDRAW_LATE_ENTRY_FEE": "Late entry fee",
      "BUY_EQUALIZATION_DEBT": "Add",
      "PE_DIVIDEND": "Distribution",
      "PE_SUBSCRIPTION": "Purchase (Private Equity Fund)",

      "BUY": "Purchase",
      "BUY_PRIVATE_EQUITY_FUND": "Aftermarket purchase",
      "SELL": "Sale",

      "CA_DEPOSIT": "Fund Event Deposit",
      "CA_WITHDRAW": "Fund Event Withdraw",
      "KICKBACK_SUBSCRIPTION":"Kickback Subscription",
      "DIVIDEND_SUBSCRIPTION": "Dividend Subscription",
      "RIGHTS_ISSUE": "Rights Issue",
      "RETURN_OF_CAPITAL": "Return of commitment",
      "COUPON_INTEREST": "Coupon Interest",
      "DEPOSIT": "Deposit",
      "WITHDRAW": "Withdraw",
      "OTHER": "Other",
      "INSTALLMENT": "Installment",

      // Used by the statement report
      "BALANCE": "Balance",
      "ASSET_MANAGEMENT_FEE": "Asset Management Fee",
      "ASSET_MANAGEMENT_FEE2": "Asset Management Fee",

      "AMORTIZATION": "Amortization",
      "DEPOSIT_SWAP": "Deposit (Swap)",
      "DONATION_REDEMPTION": "Donation Redemption",
      "DONATION_SUBSCRIPTION": "Donation Subscription",
      "EXPIRATION_SELL": "Expiration Sell",
      "SWITCH_REDEMPTION": "Switch Redemption",
      "SWITCH_SUBSCRIPTION": "Switch Subscription",
      "TRANSFER_DEPOSIT": "Transfer Deposit",
      "TRANSFER_WITHDRAW": "Transfer Withdraw",
      "WITHDRAW_SWAP": "Withdraw (Swap)",
  },

  "MONTHLYREPORTS": {
      "REPORTS": "Reports",
      "MONTHLY_REPORTS": "Monthly reports",
      "FUNDS_SEARCH": "Fund search",
      "REPORTDATE": "Reporting date",
      "HEDGE": "AJ EAB Value Hedge",
      "OSAKE": "Evli Equity",
      "KORKO": "Evli Bond",
      "OPTIMAALIVARAINHOITO": "Evli Optimal Allocation",
      "FOKUS_EUROOPPA": "Elite Alfred Berg Eurooppa Fokus",
      "FOKUS_SUOMI": "Elite Alfred Berg Suomi Fokus",
      "QUARTERLY_REPORTS": "Quarterly reports",
      "QUARTERLY_REPORT": "Quarterly report",
      "VUOKRATUOTTO": "Elite Alfred Berg Vuokratuotto",
      "ALYENERGIA": "Elite Älyenergia",
      "DOWNLOAD_PDF": "Download monthly report (pdf)",
      "DOWNLOAD_QUARTERLY_PDF": "Download the latest quarterly report (pdf)"
  },

  "FUNDSEARCH": {
    "FUND_DOCUMENTS_INFO": "Read the fund documents (in Finnish)",
    "FUND_DOCUMENTS_INFO_URL": "https://www.evli.com/tuotteet-ja-palvelut/rahastot/rahastot/eab-dokumentit",
    "SFDR_TOOLTIP_TITLE": "Vastuullisuus ympäristöön, yhteiskuntavastuuseen ja hallintotapaan liittyvissä asioissa.",
    "GOOD": "Gray",
    "BETTER": "Light Green",
    "BEST": "Dark Green",
    "SFDR": "ESG",
    "PROFIT_3_MONTHS": "3 mos",
    "PROFIT_6_MONTHS": "6 mos",
    "PROFIT_YTD": "YTD",
    "PROFIT_1_YEARS": "1 y",
    "PROFIT_3_YEARS": "3 y",
    "SHOWN_FUNDS": "Shown funds",
    "FUNDS_FOUND_TOTAL": "Total found",
    "FUNDS": "funds.",
    "SEARCH_BY_KEYWORD": "Search by keyword",
    "3_MONTHS": "3 mos",
    "12_MONTHS": "12 mos",
  },

  // Jäsenpalvelun käännökset, CRM:stä tulevat arvot
  "Rahasto-osuuden arvo": "Value of fund unit",
  "Maksettu rahasto-osuus": "Fund unit paid",
  "Voittopalkkio": "Profit remuneration",
  "Yli/Alijäämä": "Portion of the profits for the financial period",
  "Tuotto": "Profit",
  "Tulospalkkio": "Performance-based bonus",
  "Edellisenä maksupäivänä maksettu rahasto-osuus": "Fund unit paid",
  "Lisäosa": "Supplement to fund contribution",
  "Rahasto-osuus yhteensä": "Your total fund unit after payment",
  "Sidotusta nostettavaan siirto": "Portion transferred for withdrawal",
  "Arvonmuutos": "Change in the value",
  "Oikaisu": "Rectification",

  "Sähköisesti": "On the web service",
  "Kirjeenä": "By post",

  "Irtisanoutuminen": "Giving notice",
  "Esteellisyys / Siirto rahastoon kuulumattomaan yhtiöön": "Disqualification /Transferring to a company not belonging to the Personnel Fund",
  "Määräaikainen": "End of fixed-term employment",
  "Eläkkeelle siirtyminen": "Retirement",
  "Kuolema": "Death of the employee",
  "Irtisanottu tuotannollisista tai taloudellisista syistä / Yritysjärjestely": "Redundancy / Merger or acquisition",
  "Lepäävä jäsenyys": "Auxiliary membership",
  "Siirto toiseen rahastoon kuuluvaan yhtiöön": "Transferring to a company also belonging to the Personnel Fund",

  "Rahastoin palkkion kokonaan": "will in full be transferred into the Personnel Fund",
  "Nostan käteisenä palkan yhteydessä": "will be withdrawn in cash",
  "Rahastoin palkkiosta 75%": "75% will be transferred into the Personnel Fund and 25% will be withdrawn in cash",
  "Rahastoin palkkiosta puolet (50%)": "50% will be transferred into the Personnel Fund and 50% will be withdrawn in cash",
  "Rahastoin palkkiosta 25%": "25% will be transferred into the Personnel Fund and 75% will be withdrawn in cash",
  "Rahastoin palkkiosta 2/3": "2/3 will be transferred into the Personnel Fund and 1/3 will be withdrawn in cash",
  "Rahastoin palkkiosta 1/3": "1/3 will be transferred into the Personnel Fund and 2/3 will be withdrawn in cash",

  "Vahvistan, että olen Yhdysvaltojen kansalainen": "I hereby certify that I am a tax resident of the U.S (If you are U.S citizen, you are a tax resident in the U.S)",
  "Vahvistan, että en ole Yhdysvaltojen kansalainen": "I hereby certify that I am not a tax resident in the U.S",

  "Valinta on lukittu": "The selection has been locked",
  "Valintaa ei ole lukittu": "The selection has not been locked",

  /*
   * Jäsenpalvelun käännökset
   */
  "PERSONNELFUND": {
      "PERSONNELFUND_NAME": "Personnel/Bonus Fund",
      "FINANCIAL_PERIOD": "Financial period",
      "MY_FUND": "My fund",

      // Ilmoitukset tallentamattomista tiedoista
      "NOT_SAVED": "The information has not been saved",
      "ARE_YOU_SURE": "Are you sure you want to exit the page before saving the information?",
      "EXIT": "Yes",
      "CANCEL": "Cancel",

      // Login
      "HRJ_LOGIN_FAIL": "Your membership details cannot be found. You are either not a member of the Personnel/ Bonus Fund or your details have not yet been imported to the system. Please contact our customer service if needed:",

      // Etusivu
      "HOME": "Home",
      "WELCOME": "Welcome!",
      "WELCOME_TO_THE_PERSONNEL_FUND": "Welcome to the Personnel Fund’s/Bonus Fund’s member service!",
      "IF_YOU_HAVE_ANY_QUESTIONS": "If you have any questions about our services or you have noticed a mistake in the information concerning your employment relationship or the grounds for determining your payments, please let us know.",
      "YOU_CAN_LEARN_MORE_ABOUT_THE_RULES": "You can learn more about the rules of the fund by downloading the file below.",
      "MEMBER_SERVICE_PROVIDES": "Member service provides you with up-to-date information on the Personnel Fund and the value of your fund unit. In addition, the service provides an easy and secure channel for informing us of all of your membership and fund unit’s matters.",
      "WE_RECEIVE_YOUR_ADDRESS1": "We receive your address information from your employer. However, if you want notices and instructions related to your fund membership sent to another address remember to update it.",
      "WE_RECEIVE_YOUR_ADDRESS2": "We also send notices and instructions via email, so remember to update your email address on the Personal details page.",
      "WITHDRAWAL": "Withdrawal",
      "HOLDINGS_IN_THE_FUND": "My holdings in the Fund",
      "RESTRICTED_FUND_UNIT": "Restricted fund unit",
      "WITHDRAWABLE_FUND_UNIT": "Withdrawable fund unit",
      "TOTAL_FUND_UNIT": "Total value",
      "YOU_MAY_NOT_WITHDRAW1": "Notice for the withdrawal is possible only within fixed period of time annually. Time to make the withdrawal notice for the latest valuation has terminated.",
      "YOU_MAY_NOT_WITHDRAW2": "Remember to update your mobile number and you will get an SMS when the notice for withdrawal is possible to do next year.",
      "MAKE_A_FUNDING_NOTIFICATION": "Make a funding notification",
      "UPDATE_YOUR_CONTACT_INFORMATION": "Update your contact information",
      "EDIT_YOUR_CONTACT_INFORMATION": "Edit my contact information",
      "CONTACT_CUSTOMER_SERVICE": "Contact us",
      "TO_CUSTOMER_SERVICE": "Customer service",
      "MAKE_A_FUND_UNIT_WITHDRAWAL_NOTIFICATION": "Make a withdrawal notification",
      "DOWNLOAD_BYLAWS": "Download bylaws",
      "A_NOTIFICATION_OF_FUNDING": "A notification of funding the commissions or making cash withdrawals must be made annually.",
      "THE_REPORTING_PERIOD_IS_IN_PROGRESS": "The reporting period of the funding notification is in progress and the last notification date is",
      "THE_LATEST_FUND_REPORTING_PERIOD_HAS_ENDED": "The latest fund reporting period has ended.",
      "YOU_CAN_NOW_WITHDRAW_THE_AVAILABLE_SHARE": "You can now withdraw the available share of your fund share or a part of it. The withdrawal notification period is in progress and will end on ",
      "INSPECTED_FUND": "Selected fund",

      // Rahasto-osuuteni
      "MY_FUND_UNIT": "My fund unit details",
      "MY_FUND_UNIT2": "My fund unit",
      "MEMBERSHIP_DETAILS": "Membership details",
      "FUND_AND_MEMBERSHIP_DETAILS": "Fund and membership details",
      "FUND_ESTABLISHED": "The Fund established",
      "FUND_FINANCIAL_PERIOD": "The Fund’s financial period",
      "MEMBERSHIP_PERIOD": "Membership period",
      "EMPLOYMENT_END_DATE": "Employment end date",
      "EMPLOYMENT_END_REASON": "Employment relationship has ended due to",
      "MEMBERSHIP_DETAILS_DELIVERY": "Membership details: form of delivery",
      "WWW_SERVICE": "On the web service",
      "HOME_BY_POST": "By post",
      "IBAN": "IBAN",
      "BIC": "BIC",
      "WORKING_HOURS": "Working hours",
      "SALARY": "Salary",
      "BONUSES_WHICH_ARE_FUNDED_DURING": "Bonuses funded during the company’s financial period",
      "TAX_PERCENTAGE": "Tax percentage applied to income",
      "GIVE_TAXRATE_HALF_PERCENT_ACCURACY": "Provide taxrate with half a percent accuracy",
      "FUNDING_DATE": "Funding date",
      "FUND_VALUE_DATE": "Fund’s value date",
      "BONUS": "Bonus",
      "YOUR_PERSONAL_FUND_UNIT": "Your personal fund unit",
      "DATE": "Date",
      "EXPLANATION": "Explanation",
      "RESTRICTED_AMOUNT": "Restricted amount",
      "WITHDRAWABLE_AMOUNT": "Withdrawable amount",
      "VALUE_OF_FUND_UNIT": "Value of fund unit",
      "CHANGE_IN_VALUE_TEXT": "The change in the value of a fund unit depicts the change in the value of those financial assets that have not been liquidated into money. It tells how the value of the assets has changed during the fiscal year.",
      "PROFIT_TEXT": "The returns of a fund unit depict the proceeds, liquidated into money, gained from a fund’s investments. The returns include gains, losses, and expenses.",
      "FUND_UNIT_PAID": "Fund unit paid",
      "PROFIT_REMUNERATION": "Profit remuneration",
      "PERFORMANCE_BASED_BONUS": "Performance-based bonus",
      "SUPPLEMENT": "Supplement to fund contribution",
      "PORTION_OF_THE_PROFITS": "Portion of the profits for the financial period:",
      "PORTION_TRANSFERRED": "Portion transferred for withdrawal",
      "RESTRICTED_AND_WITHDRAWABLE_IN_TOTAL": "Restricted and withdrawable in total",
      "TOTAL_FUND_UNIT_AFTER_PAYMENT": "Your total fund unit after payment",
      "FUND_CONTRIBUTIONS_MADE": "Fund contributions made during accounting period",
      "CALCS_NONE": "No available calculations",
      "MIDYEAR_NONE": "No available bonuses",
      "SHARES_NONE": "No available shares",
      "SHARES_NOT_AVAILABLE_NOW": "No available shares at the moment",

      "TRANSACTION_INFORMATION": "Transaction details",
      "WITHDRAWN_FUND_SHARE1": "Withdrawn fund share",
      "WITHDRAWN_FUND_SHARE2": "withdrawn fund share",
      "BACK_TO_THE_CALCULATION": "Back to the calculation",

      "NO_ACTIVE_FINANCIAL_PERIOD": "No valid fund fiscal year",

      // Rahasto-osuuden nosto
      "FUND_UNIT_WITHDRAW": "Withdrawal of fund unit",
      "READ_MORE_ABOUT_TAXATION": "Read more about taxation",
      "ABOUT_TAXATION": "About taxation",
      "TO_PERSONAL_DETAILS": "To personal details",

      "WITHDRAWABLE_FUND_UNIT1": "Withdrawable fund unit",

      "MORE_INFORMATION": "More information",
      "READ_MORE_ABOUT_FUND_UNIT_WITHDRAWAL": "Read more about fund unit withdrawals",
      "READ_MORE_ABOUT_EMPLOYMENT_END_REASON": "Details on employment contract expiration",

      "EDIT_NOTIFICATION": "Edit notification",
      "MAKE_FUND_UNIT_WITHDRAWAL_NOTIFICATON": "Give withdrawal notification",
      "TEMPORARILY_UNABLE_TO_MAKE_NOTICES_OF_WITHDRAWAL": "Temporarily unable to make notices of withdrawal.",
      "WITHDRAWABLE_AMOUNT_1": "Unit available for withdrawal",
      "I_WILL_WITHDRAW": "I withdraw from my fund unit",
      "OR": "or",
      "TOTAL_VALUE": "Amount",
      "PAYABLE_FUND_UNIT_AMOUNT": "Your payable fund unit",
      "PAID_FUND_UNIT": "Your paid fund unit",
      "FROM_FUND": "from fund",
      "TAXFREE": "Tax-free",
      "NOT_TAXFREE": "Taxable fund unit",
      "WITHHOLDING": "Withholding tax",
      "TAX_INFORMATION1": "When you withdraw assets from a fund, 20% of the withdrawn amount is completely tax-free for you, while the remaining 80% of the withdrawal will be taxed according to your income rate. Check that your account and tax information are correct. There is no need to send a copy of your tax card, unless it is 0% or source-tax card: in this case, the tax card must be sent to the personnel/bonus fund customer service as a scanned document or by mail.",
      "TAX_INFORMATION2": "If the tax rate is unknown, tax will be withheld at a 60% rate from the taxable part.",
      "TAX_INFORMATION3": "We will inform the tax authorities about the paid fund unit for you.",

      "FUND_BALANCE_SHEET_DAY": "Fund balance sheet day and date of payment",
      "THE_VALUE_OF_THE_FUND_UNIT": "The value of the fund unit is defined annually on the fund balance sheet day, i.e. evaluation day. The date of payment is defined according to the balance sheet day. According to the Act on Personnel Funds, the date of payment of fund units must be within 4 months from the fund balance sheet day.",
      "IF_THE_EMPLOYMENT_CONTINUES": "If the employment continues",
      "THE_FUND_WILL_TRANSFER": "The fund will transfer 1 to 15% to the withdrawable part of the fund unit annually, according to the rules of the fund. A member may withdraw the withdrawable part of the fund unit as a whole or in part annually. A member may also leave the withdrawable part of the fund unit as an investment in the fund. The member may withdraw the invested parts with accrued returns in later years. The decision on the payable part is made annually.",
      "IF_THE_EMPLOYMENT_ENDS": "If the employment ends",
      "AT_THE_END_OF_THE_EMPLOYMENT_RELATIONSHIP1": "At the end of the employment relationship, the fund unit is paid to the member as a whole, as ruled by the Act on Personnel Funds. The investment returns accrue until the evaluation day preceding the date of payment of the fund unit. The date of payment of the fund unit depends on the last day of the employment and on the reason for ending the employment.",
      "AT_THE_END_OF_THE_EMPLOYMENT_RELATIONSHIP2": "Payment of the fund unit in the following situations:",
      "OWN_REQUEST": "Termination of employment by own request",
      "OTHER_INDIVIDUAL_GROUNDS_FOR_DISMISSAL": "Termination of employment or other individual grounds for dismissal",
      "END_OF_FIXED_TERM": "End of fixed term employment",
      "TRANSFER_TO_COMPANY_WITH_NO_FUND_MEMBERSHIP": "Transfer to company with no fund membership",
      "DEATH": "Death",
      "TRANSFER_TO_DUTIES_NOT_ENTITLED": "Transfer to duties not entitled to fund membership",
      "AT_THE_END_OF_THE_EMPLOYMENT_RELATIONSHIP3": "The fund unit is paid within 4 months from the next fund evaluation day.",
      "AT_THE_END_OF_THE_EMPLOYMENT_RELATIONSHIP4": "In the following situations:",
      "DISMISSED_FOR_PRODUCTION_OR_FINANCIAL_REASONS": "Dismissed for production/financial reasons",
      "RETIREMENT": "Retirement",
      "COMPANY_REORGANISATION": "Company reorganisation",
      "AT_THE_END_OF_THE_EMPLOYMENT_RELATIONSHIP5": "A member may divide the fund unit payment in four parts (date of payment once per year). The amount of each payment is defined by the member.",
      "AT_THE_END_OF_THE_EMPLOYMENT_RELATIONSHIP6": "Those who opted for support package may also divide the fund unit withdrawal to four years, if the rules of the fund allow. Check the rules of the fund on tab Rules and notifications to see if those who opted for support package have the right to divide the payments.",
      "DORMANT_MEMBERSHIP": "Dormant membership",
      "CHECK_IF_THE_FUND_IS_USING_DORMANT_MEMBERSHIP": "Check the rules of the fund on tab Rules and notifications to see if the fund is using dormant membership and the terms thereof.",
      "YOUR_FUND_MEMBERSHIP_CONTINUES": "Your fund membership continues, but no new bonus will accrue in the fund.",
      "DORMANT_MEMBER_MAY_MAKE_WITHDRAWALS": "A dormant member may make withdrawals from the fund unit annually on the date of payment of the fund. Continued membership requires that the member withdraws no more than the amount available for withdrawal during membership.",
      "DURING_DORMANT_MEMBERSHIP": "During a dormant membership, the fund unit still participates in investments.",
      "YOU_MAY_ALSO_WITHDRAW_YOUR_FUND_UNIT_AS_A_WHOLE": "You may also withdraw your fund unit as a whole, which terminates your fund membership.",

      "YOU_MAY_WITHDRAW_WILL_BE_PAID_AUTOMATICALLY": "",
      "YOU_MAY_WITHDRAW_WILL_NOT_BE_PAID_AUTOMATICALLY": "You can withdraw your withdrawable fund unit as a whole or any desired part of it. Notify the withdrawal in the field on the right-side column, and confirm the notification. You can change your notification until the end of the withdrawal notification period.",
      "YOUR_MEMBERSHIP_HAS_TERMINATED_1": "Your membership is ending and your entire fund unit is payable. If you wish, you also have the right to divide your fund unit withdrawal to the next four years.",
      "YOUR_MEMBERSHIP_HAS_TERMINATED_2": "If you use your right to divide the payments, the withdrawal division notification must be given annually during the fund notification period. You can choose the amount you wish to withdraw each year, according to the value of your fund unit.",
      "YOUR_MEMBERSHIP_HAS_TERMINATED_3": "If you divide the withdrawal to several years, your fund membership will continue.  The value of the non-withdrawn part will be defined annually according to each evaluation day. Notify the withdrawal in the field below, and confirm the notification.",
      "IF_YOU_DONT_REPORT_WILL_BE_PAID_AUTOMATICALLY": "If you do not notify of payment division, your whole fund unit will be paid to you on the date of payment. ",
      "IF_YOU_DONT_REPORT_WILL_BE_PAID_LAST_YEAR": "",
      "WITHDRAWAL_NOTICE_TIME_ENDS": "Withdrawal notification period ends on ",
      "FUND_SHARE_WILL_BE_PAID": " and the fund unit will be paid to your bank account on ",
      "WITHDRAWAL_CONFIRM_MESSAGE_AND_EDIT_YOUR_DETAILS": "You will receive an e-mail message for confirming the withdrawal notification. Check your e-mail address and change your bank account number and tax rate on the page ",
      "YOUR_MEMBERSHIP_HAS_TERMINATED_WILL_BE_PAID": "Your membership is ending and your entire fund unit is payable. You can see the amount payable to your bank account in the column below. ",
      "YOU_CAN_EDIT_YOUR_IBAN_AND_TAXRATE": "You can change your bank account number and tax rate on the page ",
      "AUXILIARY_MEMBERSHIP_1": "According to payroll information, you have been transferred to an affiliated company or bonus system that does not entitle you to fund membership. Thus your entire accrued fund unit will be paid to your bank account on ",
      "AUXILIARY_MEMBERSHIP_2": "ending your fund membership.",
      "AUXILIARY_MEMBERSHIP_3": "If you wish, you can also retain a so-called dormant fund membership for no more than ",
      "AUXILIARY_MEMBERSHIP_4": " years. The dormant membership requires that you do not withdraw more from your fund unit than what you could withdraw during continued membership.",
      "AUXILIARY_MEMBERSHIP_5": "Dormant membership means that the fund membership continues. The value of the non-withdrawn part will be defined annually according to each evaluation day.",
      "AUXILIARY_MEMBERSHIP_6": "The notification about continuing dormant membership must be given annually during the fund notification period. During a dormant membership, no company personnel fund bonus accrues for you.",
      "AUXILIARY_MEMBERSHIP_7": "If you return to a company or bonus system entitled to fund membership during the dormant membership, your fund membership will continue with no interruption or waiting period.",
      "FUND_UNIT_WITHDRAW_NOTIFICATION_PERIOD_IS_NOT": "The withdrawal notification period is not ongoing!",
      "YOU_CAN_MAKE_WITHDRAWAL_NOTICE": "The withdrawal notification can only be given during the annual withdrawal notification period of the fund. The last withdrawal notification period has ended on ",
      "REPORT_YOUR_PHONE_NUMBER_1": "Remember to update your mobile phone number on the page ",
      "REPORT_YOUR_PHONE_NUMBER_2": " to be notified about the starting withdrawal notification period by sms!",
      "YOUR_MEMBERSHIP_HAS_TERMINATED_AND_FUND_SHARE_IS_PAID": "Your entire fund unit has been paid to your bank account, and your fund membership has ended!",
      "YOU_HAVE_NO_SHARES_YET": "You do not have accrued withdrawable fund unit yet.",
      "SHARES_WILL_BE_TRANSFERED_1": "The next transfer of bonuses to fund units will take place in the capitalisation on the fund evaluation day ",
      "SHARES_WILL_BE_TRANSFERED_2": ".",
      "YOU_HAVE_REPORTED_YOUR_WITHDRAWAL": "You have given the withdrawal notification shown below",
      "YOUR_FUND_UNIT_WILL_BE_PAID_1": "The following amount will be paid ",
      "YOUR_FUND_UNIT_WILL_BE_PAID_2": " from your fund unit:",
      "YOUR_FUND_UNIT_HAVE_BEEN_PAID": "The following amount has been paid ",
      "YOU_WILL_BE_PAID": "Payable to your account ",
      "YOU_HAVE_BEEN_PAID": "Paid to account ",

      "INFORMATION_ON_THE_CALCULATION": "Tax information",
      "ADDITIONAL_TAX_RATE": "Additional tax rate",
      "ADDITIONAL_TAX_RATE_LIMIT": "Additional tax rate limit",
      "TAX_AT_SOURCE": "Tax at source",

      "REMEMBER_TO_KEEP_YOUR_EMAIL_UP_TO_DATE": "Remember to keep your e-mail address up-to-date. If you wish, you can change your bank account number for the share to be withdrawn through your personal information.",

      // Rahastointi/Käteisnosto
      "YOUR_MEMBERSHIP_HAS_FINISHED_YOU_CANNOT_GIVE_FUNDING_NOTIFICATION": "Your membership has finished, and therefore you cannot give funding/cash withdrawal notification. Contact customer service if necessary.",
      "THIS_FEATURE_IS_AVAILABLE_ONLY_WHERE_FUNDING_IS_VOLUNARY": "This function is available to only funds where funding is voluntary.",
      "YOU_PREVIOUS_NOTICE": "Your previous notification",
      "PREVIOUS_NOTICE1": "Notification given in the previous notification period ",
      "PREVIOUS_NOTICE2": " ",
      "FUNDING_OPTION_LOCKING": "Lock selection of funding",
      "NO_PREVIOUS_NOTICE": "No data available from previous period",
      "CURRENT_FUNDING_NOTICE_CHOICE": "Your funding selections for ongoing statement period",
      "YOU_HAVE_LOCKED_YOUR_CHOICES": "You have locked the funding selections shown below. If you wish to change your selections, unlock them, make new selections and confirm the selections.",
      "REMOVE_LOCKING_AND_CHANGE_CHOICES": "Unlock and change selections",
      "IF_YOU_WANT_TO_LOCK": "I want to lock my selection for an open ended period",
      "I_WANT_TO_LOCK": "I want to lock my selection.",
      "CONFIRM_MESSAGE": "You will receive a notice of confirmation to your email address. Please check your email address on the page ",
      "NOT_NOTIFICATION_PERIOD": "The notification period for funding/ cash withdrawals has ended.",
      "REWARD": "Profit bonus item",
      "FUND100": "will in full be transferred into the Personnel Fund",
      "FUND75": "75% will be transferred into the Personnel Fund and 25% will be withdrawn in cash",
      "FUND50": "50% will be transferred into the Personnel Fund and 50% will be withdrawn in cash",
      "FUND25": "25% will be transferred into the Personnel Fund and 75% will be withdrawn in cash",
      "FUND67": "2/3 will be transferred into the Personnel Fund and 1/3 will be withdrawn in cash",
      "FUND33": "1/3 will be transferred into the Personnel Fund and 2/3 will be withdrawn in cash",
      "FUND300EUR": "300 eur will be transferred into the Personnel Fund",
      "FUND100EUR": "100 eur will be transferred into the Personnel Fund",
      "FUND150EUR": "150 eur will be transferred into the Personnel Fund",
      "FUND50EUR": "50 eur be transferred into the Personnel Fund",
      "WITHDRAWCASH": "will be withdrawn in cash",
      "RISK_STRATEGY": "Investment strategy",
      "SELECTION_OF_INVESTMENT_STRATEGY": "Selection of investment strategy",
      "CAREFUL": "Cautious strategy",
      "NEUTRAL": "Neutral strategy",
      "AGGRESSIVE": "High risk strategy",
      "OWN_SHARE": "Company's own shares",
      "STRATEGY_YOU_HAVE_CHOSEN_1": "You have chosen a ",
      "STRATEGY_YOU_HAVE_CHOSEN_2": ". The decision is permanent, and it cannot be changed. In the future all profit bonus items transferred into the Personnel Fund will be invested to the selected strategy.",
      "SAVE_SUCCESS_MESSAGE": "Information saved successfully!",
      "SAVED_SUCCESSFULLY_NOT_SEND_EMAIL_MESSAGE": "Information was saved successfully, but email confirmation could not be sent. Please check your email address.",
      "NOT_SAVED_MESSAGE": "Information could not be saved. Please try again, or contact customer service at jasenpalvelu@eai.fi",
      "NOTIFICATION_PERIOD_HAS_ENDED": "Funding notification time has ended",
      "YOU_HAVE_REPORTED_EARLIER": "Notifications you have sent during previous notification periods",
      "YOU_HAVE_NOT_REPORTED_EARLIER": "You have not sent any notifications on funding / cash withdrawals during previous notification periods, so the payment method for your profit bonus items will be changed to the default choice of the fund.",
      "FUNDING_AND_WITHDRAWAL_NOTIFICATION": "Funding notification",
      "YOU_HAVE_REPORTED_EARLIER_FUNDING_AND_WITHDRAWAL": "You have sent the following notifications on your funding during previous reporting seasons:",
      "FUNDING_AND_WITHDRAWAL_CHOICE": "The choice of funding and withdrawal",
      "LOCKING": "Locking",
      "YOU_HAVE_LOCKED": "You have locked in your choice, so the payment method for your profit bonus items is the latest funding / cash withdrawal choice you have reported.",
      "YOU_HAVE_NOT_LOCKED": "You have not locked in your choice, so the payment method for your profit bonus items will be changed to the default choice of the fund unless you send in a new funding / cash withdrawal notification when the notification period opens.",
      "YOU_HAVE_NOTIFIED_AS_RISK_STRATEGY": "You have chosen the following investment strategy ",
      "YOU_HAVE_NOTIFIED_AS_RISK_STRATEGIES": "You have chosen the following investment strategy / strategies ",
      "RISK_STRATEGY_CHOICE_1": "The choice of investment strategy encloses all your previously funded units and your upcoming profit bonus items.",
      "RISK_STRATEGY_CHOICE_2": "The choice of investment strategy encloses all your previously funded units and your upcoming profit bonus items unless you change your choice when the notification period opens.",
      "IF_YOU_DID_NOT_CHOSEN_RISK_STRATEGY": "If you have not chosen an investment strategy yourself, a neutral investment strategy has been automatically chosen by default.",
      "YOU_CANNOT_CHOOSE_RISK_STRATEGY_ANYMORE": "Once you have chosen an investment strategy it can no longer be changed.",
      "YOU_CAN_CHANGE_RISK_STRATEGY": "The investment strategy can be changed again when the notification period opens.",

      // Säännöt ja tiedotteet
      "BYLAWS": "Bylaws in Finnish",
      "CHOOSE_FUND_RULE_YOU_WANT_TO_DOWNLOAD": "Choose rule you want to download",
      "DOWNLOAD_FUND_RULE": "Download rule",
      "BYLAWS_NONE": "No available regulations",
      "NOTIFICATIONS": "Notifications in Finnish",
      "NOTIFICATIONS_NONE": "No available notifications",

      // Omat tiedot
      "PERSONAL_DETAILS": "Personal Details",
      "MY_CONTACT_INFORMATION": "My contact information",
      "NAME": "Name",
      "STREET_ADDRESS": "Street name",
      "POSTAL_CODE": "Postal code",
      "POST_OFFICE": "Post office",
      "MEMBERSHIP_LETTER_DELIVERY_METHOD": "Membership details: form of delivery ",
      "MOBILE_NUMBER": "Mobile number",
      "EMAIL": "E-mail address",
      "WE_SEND_EMAIL_CONFIRMATION": "We will send you confirmation of your withdrawal and funding notifications via email.",
      "DIRECT_MARKETING_PERMISSION": "Permission for direct marketing",
      "DO_NOT_BULK_MESSAGE": "Elite Alfred Berg may send me direct marketing material by email and may contact me for direct marketing purposes by phone.",
      "TAX_RATE": "Tax-% for income",
      "TAX_RATE_EXTERNAL": "Tax-% for income (tax authority)",
      "TAX_RATE_INFO": "Out of the payable fund units, 20% is tax-free. The remaining 80% is subject to withholding tax according to your income tax rate. If the tax rate is unknown, tax will be withheld at a 60% rate from the taxable part.",
      "ACCOUNT_NUMBER": "Account number",
      "COMMUNICATION_WITH_ELITE": "Communication with Elite Alfred Berg",
      "I_WANT_MY_MEMBERSHIP_INFORMATION": "I want my membership information to be delivered",
      "ADD_TAX_COUNTRY": "Add new tax country",
      "I_CONFIRM_US_NATIONALITY": "I confirm that I am a US citizen.",
      "I_CONFIRM_NOT_US_NATIONALITY": "I confirm that I am not a US citizen.",

      "TAXATION_COUNTRY": "Country of Tax Residence",
      "US_CITIZENSHIP": "U.S. Citizenship",
      "US": "United States",
      "TIN": "Taxpayer Identification Number",
      "TIN_NUMBER": "Taxpayer Identification Number (TIN)",
      "TIN_NUMBER_US": "Taxpayer Identification Number, U.S. (TIN)",
      "AFTER_CONFIRMATION_MESSAGE": "After confirmation these details will be used as your default details. Please remember to keep your information up to date!",
      "OPTIONALLY_REPORT_ADDRESS_AND_ACCOUNT_INFORMATION": "Optionally inform another contact information or bank account number (Not mandatory)",
      "PERSONNEL_FUNDS_ARE_WITHIN_THE_REALM_OF": "Personnel Funds / Bonus Funds are within the realm of international tax obligations which is why the fund must determine the Country of Tax Residence of its members.",
      "US_CITIZENSHIP_NOT_CONFIRMED": "Information related to tax obligation has not been confirmed. ",
      "CONFIRM_US_CITIZENSHIP": "Please confirm the information regarding the Country of Tax Residence and the citizenship of the USA before you move away from the Personal Information page.",
      "CONFIRM_US_CITIZENSHIP_BEFORE_FUNDING_NOTIFICATION": "Please confirm the information regarding the Country of Tax Residence and the citizenship of the USA before you can make funding and withdrawal notification.",
      "YOUR_PREVIOUSLY_MADE_NOTICE_CHANGED": "Please be aware, that your previously made notice of withdrawal has had its details changed.",
      "YOU_HAVE_BEEN_REDIRECTED_MISSING_INFO": "You have been redirected to Personal Details -page, because some of the required information is missing.",

      // Asiakaspalvelu
      "OFFICE_INFO": "Evli Plc",
      "EVLI_ADDRESS": "Aleksanterinkatu 19, 4th floor",
      "EVLI_POSTALCODE_CITY": "P.O. Box 1081, FI-00101\n Helsinki",
      "INVESTOR_SERVICE": "Investor Service",
      "INVESTOR_SERVICE_OPENING_HOURS": "9:30 a.m. - 4:30 p.m.",
      "INVESTOR_SERVICE_PHONE": "+358 9 4766 9701",
      "CONTACT": "Contact",
      "CUSTOMER_SERVICE": "Customer service",
      "GIVE_FEEDBACK": "Give feedback",
      "FEEDBACK_OR_RECTIFUCATION": "Feedback or Rectification",
      "CUSTOMER_SERVICE_CONTACT_NAME": "Elite Palkitsemispalvelut Oy",
      "CUSTOMER_SERVICE_ADDRESS": "Kluuvikatu 3, 3rd floor",
      "CUSTOMER_SERVICE_POSTALCODE_CITY": "00100 Helsinki",
      "WRITE_YOUR_COMMENT": "Write your comments or questions in the open space below. If you find an error in the basis of your profit remuneration or employment data please note them below as well.",
      "MESSAGE": "Message",
      "CONTACT_INFO_FOOTER": "Personnel/Bonus Fund Customer Service",
      "SEND_SUCCESS_MESSAGE": "Information sent successfully!",
      "SEND_ERROR_MESSAGE": "An error occurred in sending information.",
      "SEND": "Send",
      "SENDING_INFORMATION": "Sending information",
      "ATTACHMENT": "Attachment",
      "CONFIRM": "Confirm",
      "MY_MESSAGE_IS_ABOUT_FUND": "My message is about fund:",
      "FOOTER_CUSTOMER_SERVICE": "Customer service for personnel fund customers",

      // tarjoussivu
      "SALE_HEADER": "Membership benefits of remuneration fund",
      "SALE_INFO_1": "Dear member of a remuneration fund!",
      "SALE_INFO_2": "To our remuneration fund members we offer a unique opportunity to invest in fund series, which are usually only available to professional investors. As a benefit you may now purchase these funds without a subscription fee and a very low management fee. This benefit is only available to remuneration fund members and only applies to funds listed below. All of the funds listed are managed by Elite Alfred Berg.",
      "SALE_INFO_3": "Elite Palkitsemispalvelut Oy, who offers remuneration systems is a part of Elite Alfred Berg. Elite Alfred berg is a versatile financial services expert, which helps individuals, professional investors and companies to invest in a comprehensive, planned and sustainable way.",
      "SET_VISIBILITY": "I do not wish to see this page again. (Selection is saved by logging out)"
  },

  // These should be a part of the WEB_REPORTS category, but that would break other translations in the tableGroupingService and portfolioReport.html
  // so they are here instead, because the other instrument types and classifications are not categorized either
  "Fund": "Funds",
  "FundAM": "Funds",
  "FundOLD": "Funds",
  "Share": "Shares",

  "PE_Fund_Equalization_Debt": "Distributions Receivable",
  "Private_Equity_Fund": "Private Equity Funds",

"WEB_REPORTS": {
  // Salkkuraportti
  "PORTFOLIO_REPORT": "Portfolio Report",
  "INSTRUMENT": "Instrument",
  "MARKET_PRICE": "Market Price",
  "DATE_OF_RATE": "Date",
  "ACQUISITION_VALUE": "Acquisition value",
  "UNREALIZED_PROFIT_OR_LOSS": "Unrealized Profit / Loss",
  "UNREALIZED_PROFIT_OR_LOSS_SHORT": "Unrealized P/L (€)",
  "PORTION_OF_ASSETS": "Of assets",
  "ALL_ASSETS": "Assets",
  "TOTAL_SUM": "Total",
  "GROUPING": "Grouping",
  "ASSET_TYPE": "Asset Type",
  "CLASSIFICATION": "Classification",
  "INSTRUMENT_TYPE": "Instrument Type",
  "CURRENCY": "Currency",
  "REPORT_DATE": "Reporting date",
  "NO_TRANSACTIONS": "No transactions found for the selected portfolio.",
  "DOWNLOAD_REPORT": "Download as file",
  "COMMITMENT": "Commitment", 
  "COMPANY_WAGER": "Capital contribution",
  "TOTAL_REVENUE": "Total return percentage",
  "PROFIT_DISTRIBUTIONS": "Total profit distribution",
  "CAPITAL_RETURNS": "Returned capital",
  "SUBSCRIPTION_FEES": "Subscription fee",
  "INFORMATION_THROUGH_INVESTMENT": "Details of the entire investment period",
  "UNCALLED": "Uncalled commitment",
  "CALLED": "Called commitment",
  "LATE_ENTRY_COMPENSATION": "Late entry compensation",
  "FEE_RETURNS": "Retrocession",
  "LATE_ENTRY_FEE": "Late entry fee",
  "TOTAL_EQUALIZATION": "Equalization of called commitment",  
  "RECALLED_CAPITAL": "Total recalled capital",
  "FOUNDING_COSTS": "Founding fee",
  "TWR_PROFIT": "TWR",
  "TWR_DISCLAIMER1": "Return % is the time-weighted return (TWR).",
  "TWR_DISCLAIMER2" : "TWR measures the investment’s performance before taxes and withholdings without the distorting effects on growth rates of cash inflows and outflows.",
  "TWR_DISCLAIMER3": "In other words, TWR measures the compounding growth for an investment where no contributions or withdrawals have been made during the investment period and where any dividends have been reinvested.",


  // Tapahtumaraportti
  "TRANSACTION_REPORT": "Transaction Report",
  "PURCHASE_DATE": "Trade Date",
  "SETTLEMENT_DATE" : "Settlement Date",
  "PIECE": "Shares",
  "PRICE_OF_SHARE": "Price / Share",
  "DELIVERY_FEE": "Delivery fee",
  "TAX": "Tax",

  // Toteutuneet tuotot
  "REALIZED_PROFITS": "Realized Profits",
  "NO_PROFITS_FOUND_WITH_CURRENT_PARAMETERS": "No Profits Found With Current Parameters",
  "PROFITS_AND_LOSSES": "Profits and Losses",
  "PROFITS_AND_LOSSES_TOTAL": "Profits and Losses Total",
  "SELL_VALUE": "Sell Value",
  "PORTFOLIO_TOTAL": "Portfolio Total",
  "OTHER_PROFITS_TOTAL": "Other Profits Total",
  "NET_PROFIT": "Net Profit",
  "ACQUISITION_PRICE": "Purchase price",
  "PROFIT_OR_LOSS": "Profit or loss",
  "OTHER_REALIZED_PROFITS_HEADER": "Dividends, mutual fund income, and interest payments",
  "OTHER_REALIZED_PROFITS_SUMMARY": "Total amount of dividends, mutual fund income, and interest payments",
  "RELEASE_DATE": "Transfer date",
  "PRIVATE_EQUITY_REALIZED_PROFITS": "Fund's realized returns",
  "PRIVATE_EQUITY_TAX_INFO": "Pääomarahastojen tiedot verotusta varten",
  "CAPITAL_INVESTMENT": "Capital investment",
  "SHARE_OF_INCOME": "Portion of LLP's financial records",
  "EVL_SHARE_OF_INCOME": "Trade tax law Portion of LLP's financial records",
  "TAX_YEAR": "Fiscal year",

  // Tiliote
  "TRANSACTION_NUMBER_ABBR": "Trans. no",
  "BALANCE": "Balance",
  "DEPOSITS_TOTAL": "Deposits Total",
  "WITHDRAWS_TOTAL": "Withdraws Total",
  "TRANSACTION_INFO": "Transaction details",
  "VALUE_INFO": "Calculation details",
  "COMPARED_BALANCE": "Amount reserved for purchases",
  "INCOME_ESTIMATE": "Estimate of sales from available cash", 

  // Asiakasyhteenveto
  "CUSTOMER_SUMMARY": "Customer Summary",
  "ACCOUNT": "Account",
  "ASSETS_TOTAL": "Assets Total",
  "REALIZED_PROFITS_HEADER": "Realized Profits",
  "REALIZED_LOSSES": "Realized Losses",
  "REALIZED_PROFITS_AND_LOSSES": "Realized Profits (+) / Losses (-)",
  "OTHER_REALIZED_PROFITS": "Other Realized Profits",
  "GROSS": "Gross",
  "NET": "Net",
  "COUPONS": "Cupons",
  "AFTER_TAX": "After Tax",
  "DISTRIBUTION_BY_ASSET_TYPE": "Distribution by asset class",
  "DISTRIBUTION_BY_CLASSIFICATION": "Distribution by subclass",
  "LARGEST_INVESTMENTS": "Largest Investments",

  // Salkkuyhteenveto
  "PORTFOLIO_SUMMARY": "Portfolio summary",
  "REPORT_VALUE": "EUR (inside brackets in bonds currency)",

  // Varainhoidon yhteenveto
  "ASSETMANAGEMENT_SUMMARY": "Asset management summary",
  "INSTRUMENT_NAME": "Instrument",
  "RISK_DISTRIBUTION_BY_ASSET_TYPE": "Portfolio risk by asset class",
  "RISK_DISTRIBUTION_BY_CLASSIFICATION": "Portfolio risk by subclass",
  "RISK_DISTRIBUTION_BY_INSTRUMENT": "Portfolio risk by instrument",
  "PORTION": "Portion",
  "BELOW": "under 0.01 %",

  // Riskiluvut
  "VOLATILITY": "Volatility",
  "SHARPEN_RATIO": "Sharpe Ratio",
  "BETA": "Beta",
  "R2": "R-squared",
  "TRACKING_ERROR": "Tracking error",
  "INFORMATION_RATION": "Information Ratio",
  "READ_MORE_ABOUT_RISK_NUMBERS": "Read more about risk figures",
  "ADDITIONAL_INFORMATION_ABOUT_RISK_NUMBERS": "Additional information about risk figures",
  "VOLATILITY_INFO": "Volatility measures the average variation, i.e. standard deviation, of the investment’s return. The higher the volatility, the more the value of the investment has varied.",
  "SHARPEN_RATIO_INFO": "Sharpe Ratio measures the risk-adjusted return of the investment. Sharpe Ratio is retrieved by relating the excess return over the risk-free interest rate against the investment’s volatility, i.e. risk. A positive figure indicates a profitable risk-taking, whereas a negative figure indicates a return lower than the risk-free interest rate.",
  "BETA_INFO": "Beta measures the sensitivity of the investment’s value for variations in the benchmark index’s value. For example, if the Beta on the investment is 0.6 and the value of the benchmark index changes with 1 %, the investment’s value is changed by average with 0.6 % in the same direction.",
  "R2_INFO": "R-squared describes the percentual share of the variation of the investment’s value that can be explained with the variation of the benchmark index’s value. A low R-squared indicates an investment value that has developed relatively independently in relation to the benchmark index.",
  "TRACKING_ERROR_INFO": "Tracking error measures the active risk of the investment, i.e. how closely the investment return follows the benchmark index’s return. With a high Tracking error, the investment’s return varies significantly in relation to the benchmark index’s return. Consequently, with a low Tracking error the investment’s return follows the benchmark index’s return to a relatively high degree",
  "INFORMATION_RATION_INFO": "Information ratio measures the difference on the investment and benchmark index return in relation to the investment’s Tracking error. It indicates how much excess return has been acquired in relation to active risk taken.",
  "SHOWN_PERIOD": "Showing the last year from the end of the selected period.",
  "INDEX": "Index",
  "RISK_STATISTICS_DISCLAIMER": "The risk figures are calculated on the basis of the information on the last date of the reporting period or, if there is no information available for that date, on the basis of the information on the last banking day before that date.",
  "LOADING_RISK_VALUES": "Loading risk figures",

  // Yleisiä
  "CUSTOMER": "Customer",
  "REPORT_CURRENCY": "Report Currency",
  "TIME_SPAN": "Time Period",
  "REPORT_CURRENCY_EUR": "EUR",
  "VALUES_IN_INSTRUMENT_CURRENCY_IN_PARENTHESES": "Values in Instrument Currency in Parentheses",
  "PORTFOLIO_SUMMARY": "Portfolio overview",
  "SUMMARY": "Overview",
  "CAPITA_GAIN_AND_LOSS": "Capital gains and losses (9a)",
  "ACCOUNT_STATEMENT": "Statement",
  "SELECTED_PORTFOLIO": "Selected portfolio",
  "TIME_PERIOD": "Displayed period",
  "3_MONTHS": "Three months",
  "6_MONTHS": "Six months",
  "A_YEAR": "A year",
  "CURRENT_YEAR": "From the beginning of the year",
  "SINCE_BEGINNING": "From the beginning of operations",
  "SELECT_DATES": "Choose dates",
  "ADJUSTED_DATES": "Adjusted",
  "PORTFOLIO_EVENTS_BEFORE_2017_FIRST": "The transactions are temporarily available in the Web Service for the selected portfolio starting ",
  "PORTFOLIO_EVENTS_BEFORE_2017_SECOND": ". Transactions before this will become available as soon as possible."
},

  // todo: remove this when history data is available
  "HISTORY_DATA_NOT_AVAILABLE": "History data before 1.1.2016 is not yet available.",


  // Statement transaction types
  "Service Fee": "Service Fee",
  "Asset Management Fee": "Asset Management Fee",
  "Asset Management Fee2": "Asset Management Fee",
  "Return of Capital": "Return of Capital",
  "Installment": "Installment",
  "Interest": "Interest",
  "Fund Subscription Fee": "Fund Subscription Fee",
  "Fund Subscription": "Fund Subscription",
  "Fund Redemption": "Fund Redemption",
  "Transfer in": "Transfer In",
  "Transfer In": "Transfer In",
  "Transfer out": "Transfer Out",
  "Transfer Out": "Transfer Out",
  "Transfer Deposit": "Transfer Deposit",
  "Transfer Withdraw": "Transfer Withdraw",
  "Security Buy": "Security Buy",
  "Security Sell": "Security Sell",
  "Custody Fee": "Custody Fee",
  "Coupon Interest": "Coupon Interest",
  "Customer Withdraw": "",
  "Customer Deposit": "",
  "Withdraw": "",
  "Deposit": "",
  "Correction": "Correction",
  "Interest Debit": "Interest Debit",
  "Internal Money Withdraw": "Internal Money Withdraw",
  "Bank Costs": "Bank Costs",
  "Fund Transaction Fee": "Fund Transaction Fee",
  "Payment / Management Fee": "Payment / Management Fee",
  "Custody Transaction Fee": "Custody Transaction Fee",
  "Payment / Subscription Net": "Payment / Subscription Net",
  "Dividend Payout": "Dividend Payout",
  "Fund Management Fee": "Fund Management Fee",
  "Tax Reclaim": "Tax Reclaim",
  "Expiration Sell": "Expiration Sell",
  "Payment / Redemption Net": "Payment / Redemption Net",
  "Internal Money Deposition": "Internal Money Deposition",
  "Fee Rebate": "Fee Rebate",


  "NOT_APPROPRIATE_INVESTMENT_FOR_USER": "Based on what you have told us about your investing experience and knowledge, we believe that this financial instrument is not necessarily appropriate for you. However, if you would still like to continue with this order, please take note that the instrument may involve risks of which you are unaware and in terms of which you may not be able to assess the related consequences.",

  "FEE_INFO_HEADER": "Advance information about costs, incentives, and auxiliary charges according to the Act on Investment Services, chapter 10",
  "FEE_INFO_INTRO": "The preliminary information regarding costs is based on assumptions and the final costs may differ from the actual costs and charges. The information is based on sources which are deemed by Elite Alfred Berg to be reliable. The information may be partially or entirely based on information which has been supplied by a third party. Elite Alfred Berg is not responsible for the accuracy of any information which has been received from a third party. The information stated below applies to the situation which prevails at the time at which the document is issued, and the information may or may not have changed since then. Costs actually incurred are later reported to the customer on an annual basis. This content is not marketing material but information required by regulations, and you are obliged to read it through carefully so that you can make your investment decision.",
  "FEE_INFO_REGARDING_INSTRUMENTS": "The advance information applies to the following services and financial instruments:",
  "FEE_INFO_OFFERED_INSTRUMENT": "The investment service or financial instrument which is being offered",
  "FEE_INFO_INVESTED_AMOUNT": "Amount invested (EUR):",
  "FEE_INFO_INVESTED_AMOUNT_NO_EUR": "Amount invested",
  "FEE_INFO_SUMMARY_OF_FEES": "Summary of expenses",
  "FEE_INFO_INVESTMENT_SERVICE_FEES": "Costs related to investment services and auxiliary services",
  "FEE_INFO_FUND_FEES": "Costs related to financial instruments",
  "FEE_INFO_TOTAL_FEE": "Total amount",
  "FEE_INFO_INCENTIVES": "Incentives",
  "FEE_INFO_THIRD_PARTY_INCENTIVES": "Payments received from a third party (incentives)",
  "FEE_INFO_INCENTIVES_DISCLAIMER": "The incentives received meet the regulatory quality improvement criterion and therefore do not have to be transferred to the client.",
  "FEE_INFO_DETAILS": "Specification",
  "FEE_INFO_EAB_FEES": "Amounts charged by the EAB Group Concern",
  "FEE_INFO_THIRD_PARTY_FEES": "Amounts charged by a third party",
  "FEE_INFO_FEE_COLLECTION": "Charging costs",
  "FEE_INFO_ONE_TIME_FEES": "1. Non-recurring costs and charges (opening fees, termination fees, exchange costs, and transfer costs)",
  "FEE_INFO_CONTINUOUS_FEES": "2. Continuous costs (custodian fees, asset management fee, and counselling fee)",
  "FEE_INFO_TRANSACTION_FEES": "3. Other expenses which are related to the executed transactions",
  "FEE_INFO_SUBSCRIPTION_FEE": "Subscription fee",
  "FEE_INFO_DURING_SUBSCRIPTION": "In connection with the subscription",
  "FEE_INFO_REDEMPTION_FEE": "Redemption fee",
  "FEE_INFO_DURING_REDEMPTION": "In connection with the redemption",
  "FEE_INFO_SUBSCRIPTION_REDEMPTION_CURRENCY_EXCHANGE_FEES": "FX trade costs of subscription and redemption",
  "FEE_INFO_INCLUDED_IN_PRICES": "Included in settled transaction prices or payable from the client’s assets when executing the trade",
  "FEE_INFO_OTHER_TRANSACTION_FEES": "Other expenses which are related to executed transactions",
  "FEE_INFO_OTHER_SERVICE_FEES": "4. Costs related to auxiliary services",
  "FEE_INFO_MISCELANEOUS_FEES": "5. Other extraordinary expenses (potential performance fees)",
  "FEE_INFO_INSTRUMENT_RELATED_FEES": "Costs related to financial instruments",
  "FEE_INFO_INCLUDED_IN_INSTRUMENT_PRICE": "included in the instrument’s price, with no separate charge",
  "FEE_INFO_OTHER_INSTRUMENT_RELATED_FEES": "4. Costs related to auxiliary services (custodian fees)",
  "FEE_INFO_CUMULATIVE_FEES_HEADER": "A description of how costs cumulatively affect the return",
  "FEE_INFO_CUMULATIVE_FEES_INTRO1": "See below for examples of how costs affect the return. Fees subtracted from each fund affect the total return of the investment. Although we cannot guarantee any returns, we can present examples of how expenses affect the end result of investment activities. Past performance cannot be used to predict future returns. The value of investments can rise or fall, and the investor may receive an amount which is lower than their initial investment.",
  "FEE_INFO_CUMULATIVE_FEES_INTRO2": "The net return target of 0% is just an example, not a binding figure.",
  "FEE_INFO_ANNUAL_NET_RETURN": "Annual net return:",
  "FEE_INFO_TOTAL_CUMULATIVE_FEES": "Continuous costs, total:",
  "FEE_INFO_CUMULATIVE_EFFECT_OF_FEES": "The cumulative effect of costs on the return",
  "FEE_INFO_YEAR1": "Year 1",
  "FEE_INFO_YEAR2": "Year 2",
  "FEE_INFO_YEAR3": "Year 3",
  "FEE_INFO_YEAR4": "Year 4",
  "FEE_INFO_YEAR5": "Year 5",
  "FEE_INFO_VALUE_OF_INVESTMENT_AT_YEAR_END": "The value of the investment at the end of the year minus costs",
  "FEE_INFO_VALUE_OF_INVESTMENT_WITH_FEES_AT_YEAR_END": "The value of the investment at the end of the year with costs deducted",
  "FEE_INFO_CUMULATIVE_FEE_EFFECT_ON_RETURNS": "The cumulative effect of costs on the return",
  "FEE_INFO_CUMULATIVE_FEE_EFFECT_ON_RETURN_ANNUALLY": "The cumulative effect of costs on the return calculated by year",
  "FEE_INFO_BACK": "Return",
  "MONDAYTOFRIDAY": "Mon–Fri",
  "LOADING_CONFIRMATION": "Loading order confirmation",

  // Customer Service section
  "CUSTOMERSERVICE": {
      "TITLE": "Customer Service",

      "SUMMARY": {
          "TITLE": "Customer Service",
          "MESSAGES": {
              "TITLE": "Messages",
              "NOTIFICATION1": "You have",
              "NOTIFICATION2": "new message in our online service.",
              "NOTIFICATION3": "new messages in our online service.",
              "NOTIFICATION4": "You have no new messages.",
              "INSTRUCTION": "Send us an online message regarding saving, investment or asset management matters. We will assist you as soon as possible!",
              "SEE_ALL": "See all messages",
          },
          "ANNOUNCEMENTS": {
              "TITLE": "Latest Announcements",
              "SEE_ALL": "See all announcements",
          },
          "FEEDBACK": {
              "TITLE": "Leave feedback",
              "GIVEFEEDBACK": "Happy with our services? Is there room for improvement? Please give us feedback regarding our operations.",
              "REPORTPROBLEM": "Did you have problems using Elite Alfred Berg’s online service? Please let us know and we will fix the issue."
          },
          "CONTACTS": {
              "TITLE": "Contact Information",
          },
          "INSTRUCTIONS": {
              "TITLE": "Ohjeet"
          }
      },
      "MESSAGES": {
          "TITLE": "Messages",
          "MESSAGES": {
              "TITLE": "Received messages",
              "INSTRUCTION": "Here you can find the messages you have received. For immediate assistance, please call our customer service tel. 0201 558 610",
              "LOADING": "Loading messages",
              "UPDATING": "Updating messages"
          },
          "NEWMESSAGE": {
              "TITLE": "New message",
              "INSTRUCTION": "Please, write your message to our customer service or to your private banker here. We will contact you as soon as possible.",
              "SUBJECT": "Subject",
              "RECEIVER": "Recipient",
              "SELECTRECEIVER": "Choose recipient",
              "MESSAGETITLE": "Subject",
              "MESSAGE": "Message",
              "OR": "OR",
              "MORE": "Browse",
              "DRAGDROP": "You can drag & drop files here",
              "LOADING_ATTACHMENT_FILE": "Loading attachment files",
              "MESSAGEATTACHMENTINSTRUCTION": "Upload the attachment from your own files. ",
              "ATTACHMENTMAXMIMUMSIZE": "The maximum size for the attachment is 20Mb.",
              "SENDMESSAGEBUTTON": "Send message",
              "RECEIVEDMESSAGES": "Messages",
              "SENDINGNEWMESSAGE": "Sending the message",
              "MESSAGESENDSUCCESFUL": "The message was sent successfully. Returning to messages", 
              "MESSAGESENDSUCCESFUL_SHORT": "The message was sent successfully!",
              "RETURN_TO_MESSAGES": "Back to Messages",
              "SENDINGFAILED": "Message sending failed. Try again.",
              "MESSAGEPLACEHOLDER": "Write your message here",
              "BANKER": "Banker",
              "TOO_LONG_SUBJECT": "The subject you entered is too long",
              "TOO_LONG_MESSAGE": "The message you entered is too long",
              "MAX_LENGTH": "Maximum length",
              "CHARACTERLIMIT": "characters",
              "RESPOND_TO_MESSAGE": "Reply to message",
              "DISCLAIMER": "Kindly note that placing an order per message through this function is not possible. However, you can request per message to have us contact you, in order to place an order.",
              "DRAGDROPNOTSUPPORTED": "This browser does not support the file drag and drop feature",
              "ADDINGATTACHMENTFAiLED": "Adding of an attachment failed. The attachment may be of an unauthorized file type or its size was too large."
          },
          "MODAL": {
              "MESSAGE_NOT_FINISHED": "You have not sent the message yet",
              "LEAVE_MESSAGE_PAGE_CONFIRMATION": "You have not sent the message yet. Do you want to leave the page anyway?",
              "STAY_ON_PAGE": "Stay",
              "LEAVE_PAGE": "Leave"
          }
      },
      "DOCUMENTS": {
          "TITLE": "Documents",
          "DOWNLOAD": "Download",
          "LOADING": "Loading documents",
          "NOCONTENT": "No documents were found"
      },
      "ANNOUNCEMENTS": {
          "TITLE": "Announcements",
          "LOADING": "Loading announcements",
          "UPDATING": "Updating announcements",
          "NOCONTENT": "Could not find any announcements",
          "NONEWCONTENT": "No new announcements",
          "OPEN_ANNOUNCEMENT": "Open the announcement",
      },
      "CONTACTS": {
          "TITLE": "Contacts",
          "BANKERINSTRUCTION": "Any questions? Contact your personal banker.",
          "CUSTOMERSERVICEINSTRUCTION": "If you have any questions, please contact our customer service.",
          "MEMBERSERVICEINSTRUCTION": "If you have any questions about Personnel Fund / Bonus Fund services, please contact our customer service.",
          "LOADING": "Loading contacts",
          "YOUR_BANKER": "Your banker"
      },
      "DASHBOARD": {
          "TITLE": "Customer Service",
      },
      "MONTHLYSAVINGS": {
          "TITLE": "Savings contracts",
          "SAVING_CONTRACTS_DESCRIPTION": "From here you can review your created savings contracts. Please contact our customer service, if you want to make changes to a contract or terminate it.",
          "FOR_WHOM": "For whom is funds being saved?",
          "CONTRACT_IN_FORCE": "Contract in force:",
          "CONTRACT_TERMS": "Contract terms and conditions",
          "SAVING_CONTRACT_TERMS": "Savings contract terms and conditions",
          "IN_FORCE": "In force",
          "TERMINATED": "Terminated",
          "SEND_MESSAGE": "Send a message",
          "LOADING_CONTRACTS": "Loading savings contracts",
          "NO_CONTRACTS_FOUND": "You do not have any savings contracts",
          "TO_SELF": "self"
      },
      "GO_TO_PERSONAL_SETTINGS": "Go to own details and settings"
  },
  "BUYFUNDPIPE": {
      "MONEYTRANSFERSUCCESSFUL": "Money deposition to portfolio successful. Money is immediately available for fund subscriptions",
      "MONEYTRANSFERFAILED": "Money deposition to portfolio failed.",
      "MONEYTRANSFERINFOLINK": "View information on deposition",
      "NORIGHTSTOCREATEAPORTFOLIO": "You do not have permission to create a new portfolio for the customership in question",
      "SELF_SERVICE_PORTFOLIO_NEEDED": "You need a self-administered portfolio in order to subscribe in the fund.",
      "CREATE_SELF_SERVICE_PORTFOLIO_HERE": "You can create it here. You can choose to give your self-administered portfolio a name.",
      "PLEASE_CONTACT": "Please contact customer service when needed",
      "PLEASE_CONTACT_1": "Please contact ",
      "PLEASE_CONTACT_2": "when needed",
      "CUSTOMER_SERVICE": "customer service ",
      "LOGIN": {
          "BUYINGFUND": "You are creating a subscription order for mutual fund",
          "NEEDTOBEACUSTOMER": "In order to subscribe in the fund you need to be a customer at Elite Alfred Berg and log in with web service username and password",
          "HAVECREDENTIALS": "I am already a customer", //"I have username and password",
          "NEWCUSTOMER": "I am a new customer",
          "BACK": "Back",
          "LOGINWITHTUPAS": "Become a customer by identifying yourself.",
          "CHOOSEBANK": "Select the bank",
          "LOGINTOBUYFUND": "Log in to continue with the fund subscription.",
          "LOGIN": "Log in",
          "LOADINGINFORMATION": "Loading data",
          "SIGNINNOW": "You can now log in to continue with the fund subscription.",
          "IDENTIFYTOCONTINUE": "Identify yourself to continue with the fund subscription.",
          "IDENTIFYCONSENT": "By selecting an ID provider in the table below, I accept that Signicat transfer my personal data (personal identification code, name) to the service provider.",
          "IDENTIFICATIONPRINCIPLES": "Identification principles",
      }
  },
  "NEWPORTFOLIO": {
      "NEWPORTFOLIO": "Create a new portfolio",
      "NEWPORTFOLIOSHORT": "New portfolio",
      "CHOOSEACCOUNT": "Choose an account",
      "CHOOSECUSTOMER": "Select account to which the portfolio is created",
      "CREATENEWPORTFOLIOHERE": "Here you can create a new customer managed portfolio.",
      "NEWPORTFOLIONAME": "Give a name for the portfolio",
      "VOLUNTARY": "Voluntary",
      "CREATEPORFOLIO": "Create a portfolio",
      "CREATEPORFOLIONOTFINISHED": "Portfolio creation in progress",
      "EXITFROMPORTFOLIOCREATION": "Are you sure you want leave the page before new portfolio has been created?",
      "PORTFOLIOCREATED": "New portfolio ",
      "SUCCESSFULLYFORCUSTOMER": " was created successfully for the customer ",
      "PORTFOLIOCREATIONFAILED": " Portfolio creation failed",
      "NOSUITABLEACCOUNTS": "Sorry, no suitable accounts were found.",
      "PLEASE_CONTACT": "Please contact",
      "PLEASE_CONTACT_2": "when needed",
      "CUSTOMER_SERVICE": "customer service",
      "CREATINGPORTFOLIO": "Creating a portfolio",
      "GOINGBACKTOBUYPAGE": "Switching automatically back to the buy fund page in a few moments.",
      "CREATENEWPORTFOLIOLINK": "+ Create a new portfolio",
      "GOINGBACKTOBROWSEORDERS": "Switching automatically back to the browse orders page in a few moments.",
  },
  "BANKER": {
    "WELCOME_TO_BANKER_VIEW": "Welcome to banker view",
    "WELCOME_TO_BANKER_VIEW_INFO": "Continue to web service or inspect customer's portfolio.",
    "OWN_WEB_SERVICE": "My web service",
    "CUSTOMER_PORTFOLIOS": "Customer portfolios",
    "SELECT_CUSTOMER": "Select customer",
    "SEARCH_FOR_PERSON": "Search for person",
    "SEARCH_WITH_KEYWORD": "Search with keyword",
    "CUSTOMER_NAME": "Customer name",
    "SOCIAL_SECURITY_NUMBER": "Social security number",
    "ACCOUNT_NUMBER": "Customer number",
    "BROWSE_CUSTOMERS": "Browse customers",
    "NO_SEARCH_RESULTS": "No search results",
    "BANKER_VIEW": "Banker view",
    "CHANGE_USER_INFO": "You can change customer by relogging in."
  },
  "FUND_OPTIONS" : {
    "TITLE": "Funds",
    "DESCRIPTION1": "Our funds offer an easy way to save money and diversify your investments. Our fund managers actively follow the market and care about your investments on your behalf.",
    "DESCRIPTION2": "",
  },
  "REWARD_CALCULATOR": {
    "YOUR_PERFORMANCE_BONUS": "Syötä tulospalkkiosi",
    "YOUR_TAX_PERCENT": "Syötä veroprosenttisi",
    "INFO_TEXT": "Esimerkkilaskelma perustuu voimassaolevan henkilöstörahastolakiin sekä voimassa olevaan vero- ja muuhun lainsäädäntöön, joka voi muuttua. Laskelmassa on käytetty seuraavia arvoja: työnantajan sivukulut 23 %, työntekijän sivukulut 10 % ja sijoitustoiminnan tavoitteena 4 % vuotuinen tuotto. Tuottotavoite ei ole tae tulevasta tuotosta. Esimerkissä edunsaaja nostaa rahastosäästöjä 3. vuodesta eteenpäin käteistä tulospalkkiota vastaavan rahamäärän. Tämä on mahdollista henkilön tuloveroprosentin ollessa min 25. Alhaisemmalla tuloveroprosentilla vastaavan noston voin tehdä 4. vuodesta eteenpäin."
  }
};
