import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import i18next from 'i18next';
import axios from '../api/axiosSetup';

export const fetchFundingNotificationTime = createAsyncThunk('fundingAndWithdrawals/fetchFundingNotificationTime', async ({ membershipId }) => {
  const response = await axios.get(`/api/fundingandwithdrawal/fundingnotificationtime/${membershipId}`);
  return response.data;
});

export const fetchCurrentNotice = createAsyncThunk('fundingAndWithdrawals/fetchCurrentNotice', async ({ membershipId }) => {
  const response = await axios.get(`api/fundingandwithdrawal/currentnotice/${membershipId}`);
  return response.data;
});

export const fetchPreviousNotice = createAsyncThunk('fundingAndWithdrawals/fetchPreviousNotice', async ({ membershipId }) => {
  const response = await axios.get(`api/fundingandwithdrawal/previousnotice/${membershipId}`);
  return response.data;
});

export const fetchTemplate = createAsyncThunk('fundingAndWithdrawals/fetchTemplate', async ({ membershipId }) => {
  const response = await axios.get(`api/fundingandwithdrawal/templates/${membershipId}?language=${i18next.language}`);
  return response.data;
});

export const fetchRules = createAsyncThunk('fundingAndWithdrawals/fetchRules', async ({ membershipId }) => {
  const response = await axios.get(`api/fundingandwithdrawal/rules/${membershipId}?language=${i18next.language}`);
  return response.data;
});

export const postMembershipOccurenceAndRiskstrategy = createAsyncThunk('fundingAndWithdrawals/postMembershipOccurenceAndRiskstrategy', async ({ membershipId, formData }) => {
  const response = await axios.post(
    `api/fundingandwithdrawal/membershipoccurenceandriskstrategy/${membershipId}/${i18next.language}`,
    formData,
  );
  return response.status;
});

export const fundingAndWithdrawalsSlice = createSlice({
  name: 'fundingAndWithdrawals',
  initialState: {
    fundingNotificationTime: null,
    fetchingFundingNotificationTime: false,
    currentNotice: null,
    fetchingCurrentNotice: false,
    previousNotice: null,
    fetchingPreviousNotice: false,
    template: null,
    fetchingTemplate: false,
    rules: null,
    fetchingRules: false,
    membershipOccurenceAndRiskstrategyStatus: null,
    postingMembershipOccurenceAndRiskstrategy: false,
  },
  reducers: {
    resetPostStatus: (state) => {
      state.membershipOccurenceAndRiskstrategyStatus = null;
      state.postingMembershipOccurenceAndRiskstrategy = false;
    },
  },
  extraReducers: {
    [fetchFundingNotificationTime.pending]: (state) => {
      state.fetchingFundingNotificationTime = true;
    },
    [fetchFundingNotificationTime.fulfilled]: (state, { payload }) => {
      state.fetchingFundingNotificationTime = false;
      state.fundingNotificationTime = payload;
    },
    [fetchFundingNotificationTime.rejected]: (state) => {
      state.fetchingFundingNotificationTime = false;
    },
    [fetchCurrentNotice.pending]: (state) => {
      state.fetchingCurrentNotice = true;
    },
    [fetchCurrentNotice.fulfilled]: (state, { payload }) => {
      state.fetchingCurrentNotice = false;
      state.currentNotice = payload;
    },
    [fetchCurrentNotice.rejected]: (state) => {
      state.fetchingCurrentNotice = false;
    },
    [fetchPreviousNotice.pending]: (state) => {
      state.fetchingPreviousNotice = true;
    },
    [fetchPreviousNotice.fulfilled]: (state, { payload }) => {
      state.fetchingPreviousNotice = false;
      state.previousNotice = payload;
    },
    [fetchPreviousNotice.rejected]: (state) => {
      state.fetchingPreviousNotice = false;
    },
    [fetchTemplate.pending]: (state) => {
      state.fetchingTemplate = true;
    },
    [fetchTemplate.fulfilled]: (state, { payload }) => {
      state.fetchingTemplate = false;
      state.template = payload;
    },
    [fetchTemplate.rejected]: (state) => {
      state.fetchingTemplate = false;
    },
    [fetchRules.pending]: (state) => {
      state.fetchingRules = true;
    },
    [fetchRules.fulfilled]: (state, { payload }) => {
      state.fetchingRules = false;
      state.rules = payload;
    },
    [fetchRules.rejected]: (state) => {
      state.fetchingRules = false;
    },
    [postMembershipOccurenceAndRiskstrategy.pending]: (state) => {
      state.postingMembershipOccurenceAndRiskstrategy = true;
    },
    [postMembershipOccurenceAndRiskstrategy.fulfilled]: (state, { payload }) => {
      state.postingMembershipOccurenceAndRiskstrategy = false;
      state.membershipOccurenceAndRiskstrategyStatus = payload;
    },
    [postMembershipOccurenceAndRiskstrategy.rejected]: (state) => {
      state.postingMembershipOccurenceAndRiskstrategy = false;
    },
  },
});

export const { resetPostStatus } = fundingAndWithdrawalsSlice.actions;

export default fundingAndWithdrawalsSlice.reducer;
