import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  hideSubNav: false,
  redirectToAnnouncement: false,
  inPersonnelFundView: false,
  inLoginView: false,
  showAddBankInfoModal: false,
  scrollUp: false,
};

export const UIcontrolSlice = createSlice({
  name: 'UIstate',
  initialState,
  reducers: {
    toggleHideSubNav: (state, { payload }) => {
      state.hideSubNav = payload;
    },
    setInPersonnelFundView: (state, { payload }) => {
      state.inPersonnelFundView = payload;
    },
    setInLoginView: (state, { payload }) => {
      state.inLoginView = payload;
    },
    setRedirectToAnnouncement: (state, { payload }) => {
      state.redirectToAnnouncement = payload;
    },
    setShowAddBankInfoModal: (state, { payload }) => {
      state.showAddBankInfoModal = payload;
    },
    setScrollUp: (state, { payload }) => {
      state.scrollUp = payload;
    },
  },
  extraReducers: {
  },
});

export const {
  toggleHideSubNav,
  setInPersonnelFundView,
  setInLoginView,
  setRedirectToAnnouncement,
  setShowAddBankInfoModal,
  setScrollUp,
} = UIcontrolSlice.actions;

export default UIcontrolSlice.reducer;
