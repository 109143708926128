const AUTH_DATA = 'authData';
const USER_SECURITY_IDENTIFIER = 'userSecurityIdentifier';
const PASSWORD_CHANGE_DATA = 'passwordChangeData';
const LOGIN_FUND_ID = 'loginFundId';
const LOGIN_HRJ_SALE_AVAILABLE = 'loginHrjSaleAvailable';
const ORDER_PORTFOLIO_ID = 'order_portfolioId';
const ORDER_FUND_NAME = 'order_fundName';
const ORDER_INSTRUMENT_ID = 'order_instrumentId';
const ORDER_TYPE = 'order_orderType';
const ORDER_AMOUNT = 'order_amount';
const ORDER_DISPOSABLE_CASH = 'order_disposableCash';
const ORDER_ESTIMATED_CASH_FROM_OPEN_SELLS = 'order_estimatedCashFromOpenSells';
const ORDER_RESUBSCRIBE_FUND_ID = 'order_resubscribeFundId';
const ORDER_DECIMALS_IN_SHARES = 'order_decimalsInShares';
const ORDER_PURPOSE = 'order_purpose';
const ORDER_BANK_ACCOUNT_ID = 'order_bankAccountId';
const ORDER_PARTNER_ID = 'order_partnerId';
const CUSTOMER_ID = 'customerId'; // Used for banker view.
const ORDER_CREATE_AS_DRAFT = 'order_createAsDraft';
const ORDER_CASH_BALACE = 'order_cashBalance';
const ORDER_IS_MANAGEMENT_OF_REDEMPTION_WITHDRAW = 'order_isManagementOfRedemptionWithdraw';
const ORDER_PAYMENT_DATE = 'order_paymentDate';
const ORDER_PAYMENT_FREQUENCY = 'order_paymentFrequency';
const ORDER_CONTRACT_NAME = 'order_contractName';
const ORDER_PORTFOLIO_ID_GUID = 'order_portfolioIdGuid';
const ORDER_FEE_INFO_LOG_ITEM = 'order_feeInfoLogItem';
const ORDER_HRJ_SALE_AVAILABLE = 'order_hrjSaleAvailable';
const ORDER_PORTFOLIO_NAME = 'order_portfolioName';
export const LOGIN_PAGE_SHOW_POP_UP = 'loginPageShowPopUp';
export const SHOW_POP_UP = 'showPopUp';
export const SHOW_POP_UP_HRJ = 'showPopUpHrj';

/**
 * Updates user auth data in session storage.
 * @param {string} token - Auth token.
 */
export function setAuthData(token) {
  const authJson = JSON.stringify({ token });
  window.sessionStorage.setItem(AUTH_DATA, authJson);
}

/**
 * Retrieves user auth data from session storage.
 * @returns {Object} authData - Auth token.
 */
export function getAuthData() {
  const authJson = window.sessionStorage.getItem(AUTH_DATA);
  return JSON.parse(authJson);
}

/**
 * Clears auth data from session storage.
 */
export function clearAuthData() {
  window.sessionStorage.removeItem(AUTH_DATA);
}

/**
 * Retrieves Tupas flow identifying value from session storage.
 * @returns {any} - Current value.
 */
export function getTupasSecurityIdentifier() {
  return window.sessionStorage.getItem(USER_SECURITY_IDENTIFIER);
}

/**
 * Update value in session storage, which is used for identifying on-going Tupas flow.
 * @param {any} value - New value.
 */
export function setTupasSecurityIdentifier(value) {
  window.sessionStorage.setItem(USER_SECURITY_IDENTIFIER, value);
}

/**
 * Clear Tupas flow value from session storage.
 */
export function clearTupasSecurityIdentifier() {
  window.sessionStorage.removeItem(USER_SECURITY_IDENTIFIER);
}

/**
 * Retrieves order data from session storage.
 * @returns {Object} - Data related to order.
 */
export function getOrderData() {
  const orderData = {
    // for withdraw, buy and monthly
    portfolioId: window.sessionStorage.getItem(ORDER_PORTFOLIO_ID),
    amount: window.sessionStorage.getItem(ORDER_AMOUNT),
    instrumentId: window.sessionStorage.getItem(ORDER_INSTRUMENT_ID),
    orderType: window.sessionStorage.getItem(ORDER_TYPE),
    purpose: window.sessionStorage.getItem(ORDER_PURPOSE),
    hrjSaleAvailable: window.sessionStorage.getItem(ORDER_HRJ_SALE_AVAILABLE),
    portfolioName: window.sessionStorage.getItem(ORDER_PORTFOLIO_NAME),

    // for withdraw and buy once
    fundName: window.sessionStorage.getItem(ORDER_FUND_NAME),
    disposableCash: window.sessionStorage.getItem(ORDER_DISPOSABLE_CASH),
    estimatedCashFromOpenSells: window.sessionStorage.getItem(ORDER_ESTIMATED_CASH_FROM_OPEN_SELLS),
    resubscribeFundId: window.sessionStorage.getItem(ORDER_RESUBSCRIBE_FUND_ID),
    decimalsInShares: window.sessionStorage.getItem(ORDER_DECIMALS_IN_SHARES),
    partnerId: window.sessionStorage.getItem(ORDER_PARTNER_ID),

    // for withdraw only
    bankAccountId: window.sessionStorage.getItem(ORDER_BANK_ACCOUNT_ID),

    // for monthly only
    paymentDate: window.sessionStorage.getItem(ORDER_PAYMENT_DATE),
    paymentFrequency: window.sessionStorage.getItem(ORDER_PAYMENT_FREQUENCY),
    contractName: window.sessionStorage.getItem(ORDER_CONTRACT_NAME),
    portfolioIdGuid: window.sessionStorage.getItem(ORDER_PORTFOLIO_ID_GUID),

    // for buy and monthly
    feeInfoLogItem: window.sessionStorage.getItem(ORDER_FEE_INFO_LOG_ITEM),

    // only buy
    createAsDratf: window.sessionStorage.getItem(ORDER_CREATE_AS_DRAFT),
    cashBalance: window.sessionStorage.getItem(ORDER_CASH_BALACE),
    // eslint-disable-next-line max-len
    isManagementOfRedemptionWithdraw: window.sessionStorage.getItem(ORDER_IS_MANAGEMENT_OF_REDEMPTION_WITHDRAW),

  };
  Object.keys(orderData).forEach((key) => {
    if (orderData[key] === 'null' || orderData[key] === undefined) {
      orderData[key] = null;
    }
  });
  if (orderData.feeInfoLogItem) {
    orderData.feeInfoLogItem = JSON.parse(orderData.feeInfoLogItem);
  }
  return orderData;
}

/**
 * Update withdrawal order data in session storage.
 * @param {*} portfolioId - ID of the portfolio.
 * @param {*} amount - Order amount.
 * @param {*} disposableCash - Disposable cash amount.
 * @param {*} estimatedCashFromOpenSells - Estimated cash amount from open sells.
 * @param {*} bankAccountId - ID of the bank account.
 */
export function setWithdrawalOrderData(
  portfolioId,
  amount,
  disposableCash,
  estimatedCashFromOpenSells,
  bankAccountId,
) {
  window.sessionStorage.setItem(ORDER_PORTFOLIO_ID, portfolioId);
  window.sessionStorage.setItem(ORDER_FUND_NAME, null);
  window.sessionStorage.setItem(ORDER_INSTRUMENT_ID, 'A1ED352E-889C-E611-80C2-DAB4F8767EC4');
  window.sessionStorage.setItem(ORDER_TYPE, 'nosto');
  window.sessionStorage.setItem(ORDER_AMOUNT, amount);
  window.sessionStorage.setItem(ORDER_DISPOSABLE_CASH, disposableCash);
  window.sessionStorage.setItem(ORDER_ESTIMATED_CASH_FROM_OPEN_SELLS, estimatedCashFromOpenSells);
  window.sessionStorage.setItem(ORDER_RESUBSCRIBE_FUND_ID, null);
  window.sessionStorage.setItem(ORDER_DECIMALS_IN_SHARES, 2);
  window.sessionStorage.setItem(ORDER_PURPOSE, 'Yleinen sijoitustoiminta (tuoton tavoittelu)');
  window.sessionStorage.setItem(ORDER_BANK_ACCOUNT_ID, bankAccountId);
}

/**
 * Update order data in session storage.
 * @param {*} orderData - information of the buy order to be placed.
 */
export function setBuySellFundOrderData(
  orderData,
) {
  window.sessionStorage.setItem(ORDER_PORTFOLIO_ID, orderData.portfolioId);
  window.sessionStorage.setItem(ORDER_AMOUNT, orderData.amount);
  window.sessionStorage.setItem(ORDER_INSTRUMENT_ID, orderData.instrumentId);
  window.sessionStorage.setItem(ORDER_TYPE, orderData.orderType);
  window.sessionStorage.setItem(ORDER_PURPOSE, orderData.purpose);
  window.sessionStorage.setItem(ORDER_HRJ_SALE_AVAILABLE, orderData.hrjSaleAvailable);
  window.sessionStorage.setItem(ORDER_PORTFOLIO_NAME, orderData.portfolioName);
  if (orderData.feeInfoLogItem) {
    window.sessionStorage.setItem(
      ORDER_FEE_INFO_LOG_ITEM, JSON.stringify(orderData.feeInfoLogItem),
    );
  }
  if (orderData.orderType === 'monthly') {
    sessionStorage.setItem(ORDER_PAYMENT_DATE, orderData.paymentDate);
    sessionStorage.setItem(ORDER_PAYMENT_FREQUENCY, orderData.paymentFrequency);
    sessionStorage.setItem(ORDER_CONTRACT_NAME, orderData.contractName);
    sessionStorage.setItem(ORDER_PORTFOLIO_ID_GUID, orderData.portfolioIdGuid);
  } else {
    window.sessionStorage.setItem(ORDER_FUND_NAME, orderData.fundName);
    window.sessionStorage.setItem(ORDER_DISPOSABLE_CASH, orderData.disposableCash);
    // eslint-disable-next-line max-len
    window.sessionStorage.setItem(ORDER_ESTIMATED_CASH_FROM_OPEN_SELLS, orderData.estimatedCashFromOpenSells);
    window.sessionStorage.setItem(ORDER_RESUBSCRIBE_FUND_ID, orderData.resubscribeFundId);
    window.sessionStorage.setItem(ORDER_DECIMALS_IN_SHARES, orderData.decimalsInShares);
    window.sessionStorage.setItem(ORDER_PARTNER_ID, orderData.partnerId);
    window.sessionStorage.setItem(ORDER_CREATE_AS_DRAFT, orderData.createAsDraft);
    window.sessionStorage.setItem(ORDER_CASH_BALACE, orderData.cashBalance);
    // eslint-disable-next-line max-len
    window.sessionStorage.setItem(ORDER_IS_MANAGEMENT_OF_REDEMPTION_WITHDRAW, orderData.isManagementOfRedemptionWithdraw);
  }
}

/**
 * Clears order data from session storage.
 */
export function clearOrderData() {
  // all withdraw, buy and monthly
  window.sessionStorage.removeItem(ORDER_PORTFOLIO_ID);
  window.sessionStorage.removeItem(ORDER_AMOUNT);
  window.sessionStorage.removeItem(ORDER_INSTRUMENT_ID);
  window.sessionStorage.removeItem(ORDER_TYPE);
  window.sessionStorage.removeItem(ORDER_PURPOSE);
  window.sessionStorage.removeItem(ORDER_HRJ_SALE_AVAILABLE);
  window.sessionStorage.removeItem(ORDER_PORTFOLIO_NAME);

  // withdraw and buy once
  window.sessionStorage.removeItem(ORDER_FUND_NAME);
  window.sessionStorage.removeItem(ORDER_DISPOSABLE_CASH);
  window.sessionStorage.removeItem(ORDER_ESTIMATED_CASH_FROM_OPEN_SELLS);
  window.sessionStorage.removeItem(ORDER_RESUBSCRIBE_FUND_ID);
  window.sessionStorage.removeItem(ORDER_DECIMALS_IN_SHARES);
  window.sessionStorage.removeItem(ORDER_PARTNER_ID);

  // withdraw only
  window.sessionStorage.removeItem(ORDER_BANK_ACCOUNT_ID);

  // monthly only
  sessionStorage.removeItem(ORDER_PAYMENT_DATE);
  sessionStorage.removeItem(ORDER_PAYMENT_FREQUENCY);
  sessionStorage.removeItem(ORDER_CONTRACT_NAME);
  sessionStorage.removeItem(ORDER_PORTFOLIO_ID_GUID);

  // buy and monthly
  window.sessionStorage.removeItem(ORDER_FEE_INFO_LOG_ITEM);

  // only buy
  window.sessionStorage.removeItem(ORDER_CREATE_AS_DRAFT);
  window.sessionStorage.removeItem(ORDER_CASH_BALACE);
  window.sessionStorage.removeItem(ORDER_IS_MANAGEMENT_OF_REDEMPTION_WITHDRAW);
}

/**
 * Retrieves customer ID from session storage.
 * @returns {string} - Customer ID.
 */
export function getCustomerId() {
  return window.sessionStorage.getItem(CUSTOMER_ID);
}

/**
 * Updates customer ID in session storage.
 * @param {string} value - New value for customer ID.
 */
export function setCustomerId(value) {
  window.sessionStorage.setItem(CUSTOMER_ID, value);
}

/**
 * Clears customer ID from session storage.
 */
export function clearCustomerId() {
  window.sessionStorage.removeItem(CUSTOMER_ID);
}

/**
 * Retrieves password change data from session storage.
 * @returns {string} - Password change data.
 */
export function getPasswordChangeData() {
  return window.sessionStorage.getItem(PASSWORD_CHANGE_DATA);
}

/**
 * Updates password change data in session storage.
 * @param {string} value - New value for password change data.
 */
export function setPasswordChangeData(value) {
  window.sessionStorage.setItem(PASSWORD_CHANGE_DATA, value);
}

/**
 * Clears password change data from session storage.
 */
export function clearPasswordChangeData() {
  window.sessionStorage.removeItem(PASSWORD_CHANGE_DATA);
}

/**
 * Retrieves buy fund pipe data from session storage.
 * @returns {Object} - Buy fund data saved for login process.
 */
export function getBuyFundPipeData() {
  return {
    fundId: window.sessionStorage.getItem(LOGIN_FUND_ID),
    hrjSaleAvailable: window.sessionStorage.getItem(LOGIN_HRJ_SALE_AVAILABLE) === 'true',
  };
}

/**
 * Updates buy fund pipe data in session storage.
 * @param {string} fundIdValue - New value fund ID.
 * @param {string} hrjSaleAvailable - New value fund ID.
 */
export function setBuyFundPipeData(fundIdValue, hrjSaleAvailable) {
  window.sessionStorage.setItem(LOGIN_FUND_ID, fundIdValue);
  window.sessionStorage.setItem(LOGIN_HRJ_SALE_AVAILABLE, hrjSaleAvailable ? 'true' : 'false');
}

/**
 * Clears buy fund pipe data from session storage.
 */
export function clearBuyFundPipeData() {
  window.sessionStorage.removeItem(LOGIN_FUND_ID);
  window.sessionStorage.removeItem(LOGIN_HRJ_SALE_AVAILABLE);
}

/**
 * Retrieves chosen pop-up state from session storage.
 * @param {string} popUp - popUp name.
 * @param {string} lang - selected language.
 * @returns {boolean} - popUp state.
 */
export function getPopUpState(popUp, lang) {
  // if popUp state is not set in the session storage, return true
  const isPopUpOpen = JSON.parse(window.sessionStorage.getItem(popUp));
  return isPopUpOpen && isPopUpOpen[lang] !== undefined ? isPopUpOpen[lang] : true;
}

/**
 * Updates chosen pop-up state in session storage.
 * @param {string} popUp - popUp name.
 * @param {string} lang - selected language.
 * @param {boolean} isOpen - show popUp, if true.
 */
export function setPopUpState(popUp, lang, isOpen) {
  const popUpState = JSON.parse(window.sessionStorage.getItem(popUp));
  const popUpStatus = popUpState ? { ...popUpState, [lang]: isOpen } : { [lang]: isOpen };
  window.sessionStorage.setItem(popUp, JSON.stringify(popUpStatus));
}

/**
 * Clears pop-up state from session storage.
 * @param {string} popUp - popUp name to clear the state data.
 */
export function clearPopUpState(popUp) {
  window.sessionStorage.removeItem(popUp);
}
