// eslint-disable-next-line no-unused-vars
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// eslint-disable-next-line no-unused-vars
import axios from '../api/axiosSetup';
import { summaryReportTimeSpanOptions } from '../lib/constants';
import { compareDateToVPFreezeDate } from '../lib/commonFunctions';

const initialState = {
  selectedTimeSpan: summaryReportTimeSpanOptions(() => {})[2].value,
  startDate: new Date(compareDateToVPFreezeDate(new Date())
    .setFullYear(compareDateToVPFreezeDate(new Date()).getFullYear() - 1)).toString(),
  endDate: compareDateToVPFreezeDate(new Date()).toString(),
  portfolioStartDate: null,
  portfolioReportDate: new Date(compareDateToVPFreezeDate(new Date())
    .setDate(compareDateToVPFreezeDate(new Date()).getDate() - 1)).toString(),
};

export const datePickerSlice = createSlice({
  name: 'datePicker',
  initialState,
  reducers: {
    setPortfolioReportDate: (state, { payload }) => {
      state.portfolioReportDate = payload;
    },
    resetDatePicker: () => initialState,
    setPortfolioDate: (state, { payload }) => {
      // set portfolio start date
      state.portfolioStartDate = payload;
      // if timespan selection is tied to portfolio date, update also start date
      if (state.selectedTimeSpan === 'SINCE_BEGINNING') {
        state.startDate = payload;
      }
    },
    setTimeSpan: (state, { payload }) => {
      state.selectedTimeSpan = payload.selectedTimeSpan;
      let startDate = new Date();
      let endDate = new Date();
      switch (payload.selectedTimeSpan) {
        case '3_MONTHS':
          startDate = new Date(compareDateToVPFreezeDate(new Date())
            .setMonth(compareDateToVPFreezeDate(new Date()).getMonth() - 3));
          endDate = compareDateToVPFreezeDate(new Date());
          break;
        case '6_MONTHS':
          startDate = new Date(compareDateToVPFreezeDate(new Date())
            .setMonth(compareDateToVPFreezeDate(new Date()).getMonth() - 6));
          endDate = compareDateToVPFreezeDate(new Date());
          break;
        case '12_MONTHS':
          startDate = new Date(compareDateToVPFreezeDate(new Date())
            .setFullYear(compareDateToVPFreezeDate(new Date()).getFullYear() - 1));
          endDate = compareDateToVPFreezeDate(new Date());
          break;
        case 'CURRENT_YEAR':
          startDate = new Date(new Date(compareDateToVPFreezeDate(new Date())
            .setMonth(0)).setDate(1));
          endDate = compareDateToVPFreezeDate(new Date());
          break;
        case 'SINCE_BEGINNING':
          // this will be set as current portfolio's start date where reducer is called
          startDate = new Date(state.portfolioStartDate);
          endDate = compareDateToVPFreezeDate(new Date());
          break;
        default:
          // these will be set as dates chosen by user where reducer is called
          // if there is no dates in the payload, use dates in the state
          startDate = new Date(payload.startDate || state.startDate);
          endDate = new Date(payload.endDate || state.endDate);
          break;
      }
      state.endDate = endDate.toString();
      state.startDate = startDate.toString();
    },
  },
  extraReducers: {
  },
});

export default datePickerSlice.reducer;

export const {
  resetDatePicker,
  setTimeSpan,
  setPortfolioDate,
  setPortfolioReportDate,
} = datePickerSlice.actions;
