import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../api/axiosSetup';

// Fetches personnel fund account information.
export const fetchAccountInformation = createAsyncThunk('users/fetchAccountInformation', async () => {
  const response = await axios.get('api/accountInformation/getAccountInformation');
  return response.data;
});

export const fetchMembershipLetterDeliveryOptions = createAsyncThunk('users/fetchMembershipLetterDeliveryOptions', async () => {
  const response = await axios.get('api/accountInformation/getMembershipLetterDeliveryOptions');
  return response.data;
});

export const fetchTaxCountries = createAsyncThunk('users/fetchTaxCountries', async (language) => {
  const response = await axios.get(`api/accountInformation/taxcountries${language ? `?lang=${language}` : ''}`);
  return response.data;
});

export const postUpdatedAccountInformation = createAsyncThunk('users/postUpdatedAccountInformation', async ({ formData }) => {
  const response = await axios.post(
    'api/accountInformation/saveAccountInformation',
    formData,
  );
  return response.status;
});

export const fetchLandingPageSetting = createAsyncThunk('auth/fetchLandingPageSetting', async () => {
  const response = await axios.get('api/userAccount/showLandingPage');
  return response.data;
});

export const updateLandingPageSetting = createAsyncThunk('auth/updateLandingPageSetting', async ({ showLandingPage }) => {
  const response = await axios.post(`/api/userAccount/setShowLandingPage/${showLandingPage}`);
  return response.status;
});

export const fetchDiscountEntitlement = createAsyncThunk('auth/fetchDiscountEntitlement', async () => {
  const response = await axios.get('api/userAccount/entitledForDiscount');
  return response.data;
});

export const fetchCurrentUserWriteMifidAuthorizationCustomers = createAsyncThunk('users/fetchCurrentUserWriteMifidAuthorizationCustomers', async (_, thunkAPI) => {
  const { customerId } = thunkAPI.getState().auth;
  const response = await axios.get(`/api/useraccount/writemifidcustomers${customerId ? `?customerId=${customerId}` : ''}`);
  return response.data;
});

export const checkCurrentUserWriteMifidAuthorization = createAsyncThunk('users/checkCurrentUserWriteMifidAuthorization', async (accountGuid) => {
  const response = await axios.get(`/api/useraccount/writemifid/${accountGuid}`);
  return response.data;
});

export const fetchCustomerContactInfo = createAsyncThunk('users/fetchCustomerContactInfo', async (_, thunkAPI) => {
  const { customerId } = thunkAPI.getState().auth;
  const response = await axios.get(`/api/userSettings/customercontactinfo${customerId ? `?customerId=${customerId}` : ''}`);
  return response.data;
});

export const fetchCustomerAccountInfo = createAsyncThunk('users/fetchCustomerAccountInfo', async ({ userContactId, source }) => {
  const response = await axios.get('api/userSettings/userAccount', { params: { userContactId, source } });
  return response.data;
});

export const fetchUserOrderSecurity = createAsyncThunk('users/fetchUserOrderSecurity', async () => {
  const response = await axios.get('api/userSettings/additionalOrderSecurity/');
  return response.data;
});

export const saveContactInformation = createAsyncThunk('auth/saveContactInformation', async (contactInformation) => {
  const response = await axios.put('/api/session/tupasCustomerContacts/react', contactInformation);
  return response.status;
});

export const fetchUserSettings = createAsyncThunk('users/fetchUserSettings', async () => {
  const response = await axios.get('api/userSettings').catch((err) => console.log(err));
  return response.data;
});

export const saveUserSettings = createAsyncThunk('users/saveUserSettings', async (userSetting) => {
  const response = await axios.post('/api/userSettings', userSetting);
  return response.status;
});

export const updatePassword = createAsyncThunk('users/updatePassword', async (changePasswordData, thunkAPI) => {
  try {
    const response = await axios.put('/api/password/react', changePasswordData);
    return response.status;
  } catch (error) {
    return thunkAPI.rejectWithValue(error?.response?.status || error.message);
  }
});

export const fetchCustomerTaxCountries = createAsyncThunk('users/fetchCustomerTaxCountries', async (language) => {
  const response = await axios.get(`api/customer/taxcountries${language ? `?lang=${language}` : ''}`);
  return response.data;
});

export const fetchShowTaxInformation = createAsyncThunk('users/fetchShowTaxInformation', async (_, thunkAPI) => {
  const { customerId } = thunkAPI.getState().auth;
  const response = await axios.get(`api/userSettings/showTaxInformation${customerId ? `?customerId=${customerId}` : ''}`);
  return response.data;
});

export const fetchTaxInformation = createAsyncThunk('users/fetchTaxInformation', async (_, thunkAPI) => {
  const { customerId } = thunkAPI.getState().auth;
  const response = await axios.get(`api/mifids/taxInformation${customerId ? `?customerId=${customerId}` : ''}`);
  return response.data;
});

export const saveTaxInformation = createAsyncThunk('users/saveTaxInformation', async (taxSettings) => {
  const response = await axios.post('/api/mifids/taxAndWealth', taxSettings);
  return response.status;
});

export const updateResidencyCountry = createAsyncThunk('users/updateResidencyCountry', async (residencyCountry) => {
  const response = await axios.post('/api/userSettings/updateResidencyCountry', ({ Guid: residencyCountry }));
  return response.status;
});

export const fetchIsMifidValid = createAsyncThunk('users/fetchIsMifidValid', async () => {
  const response = await axios.get('api/mifids/isValid');
  return response.data;
});

export const fetchTotalWealth = createAsyncThunk('users/fetchTotalWealth', async (_, thunkAPI) => {
  const { customerId } = thunkAPI.getState().auth;
  const response = await axios.get(`api/mifids/totalWealth${customerId ? `?customerId=${customerId}` : ''}`);
  let wealth = 0;
  if (response.data !== null) {
    wealth = response.data;
  }
  return wealth;
});

export const fetchCurrencies = createAsyncThunk('users/fetchCurrencies', async () => {
  const response = await axios.get('api/customer/currencies');
  return response.data;
});

export const addBankAccount = createAsyncThunk('users/addBankAccount', async (bankInformation) => {
  const response = await axios.post('/api/userAccount/addBankAccountNumber', bankInformation);
  return response.status;
});

const initialState = {
  accountInformation: null,
  fetchingAccountInformation: false,
  membershipLetterDeliveryOptions: [],
  fetchingMembershipLetterDeliveryOptions: false,
  taxCountries: [],
  fetchingTaxCountries: false,
  updatedAccountInformationStatus: null,
  postingUpdatedAccountInformation: false,
  currentUserWriteMifidAuthorizationCustomers: null,
  fetchingCurrentUserWriteMifidAuthorizationCustomers: false,
  checkingCurrentUserWriteMifidAuthorization: false,
  currentUserHasMifidRights: null,
  fetchingCustomerContactinfo: false,
  customerContactinfo: null,
  fetchingUserOrderSecurity: false,
  userOrderSecurity: null,
  fetchingCustomerTaxCountries: false,
  customerTaxCountries: [],
  showTaxInformation: false,
  fetchingTaxInformation: false,
  taxInformation: null,
  userSettings: null,
  fetchingUserSettings: false,
  savingUserSettings: false,
  saveUserSettingsStatus: null,
  updatingPassword: false,
  updatePasswordStatus: null,
  savingTaxInformation: false,
  saveTaxInformationStatus: null,
  savingContactInformation: false,
  saveContactInformationStatus: null,
  fetchingCustomerAccountInfo: false,
  customerAccountInfo: null,
  updatingResidencyCountry: false,
  residencyCountryUpdateStatus: null,
  fetchingIsMifidValid: false,
  isMifidValid: null,
  fetchingTotalWealth: false,
  totalWealth: null,
  currencies: [],
  fetchingCurrencies: false,
  postingBankInformation: false,
  postBankInformationStatus: null,
  originOfAssetError: false,
  termsReadError: false,
};

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    resetSaveUserSettingStatuses: (state) => {
      state.saveUserSettingsStatus = null;
      state.updatePasswordStatus = null;
      state.saveContactInformationStatus = null;
      state.residencyCountryUpdateStatus = null;
      state.saveTaxInformationStatus = null;
      state.postBankInformationStatus = null;
    },
    resetMifidData: (state) => {
      state.currentUserWriteMifidAuthorizationCustomers = null;
      state.fetchingCurrentUserWriteMifidAuthorizationCustomers = false;
      state.checkingCurrentUserWriteMifidAuthorization = false;
      state.currentUserHasMifidRights = null;
    },
    resetPersonnelFundContactFormData: (state) => {
      state.accountInformation = null;
      state.fetchingAccountInformation = false;
      state.membershipLetterDeliveryOptions = [];
      state.fetchingMembershipLetterDeliveryOptions = false;
      state.taxCountries = [];
      state.fetchingTaxCountries = false;
      state.updatedAccountInformationStatus = null;
      state.postingUpdatedAccountInformation = false;
    },
    setOriginOfAssetError: (state, { payload }) => {
      state.originOfAssetError = payload;
    },
    setTermsReadError: (state, { payload }) => {
      state.termsReadError = payload;
    },
  },
  extraReducers: {
    [fetchAccountInformation.pending]: (state) => {
      state.fetchingAccountInformation = true;
    },
    [fetchAccountInformation.fulfilled]: (state, { payload }) => {
      state.fetchingAccountInformation = false;
      state.accountInformation = payload;
    },
    [fetchAccountInformation.rejected]: (state) => {
      state.fetchingAccountInformation = false;
    },
    [fetchMembershipLetterDeliveryOptions.pending]: (state) => {
      state.fetchingMembershipLetterDeliveryOptions = true;
    },
    [fetchMembershipLetterDeliveryOptions.fulfilled]: (state, { payload }) => {
      state.fetchingMembershipLetterDeliveryOptions = false;
      state.membershipLetterDeliveryOptions = payload;
    },
    [fetchMembershipLetterDeliveryOptions.rejected]: (state) => {
      state.fetchingMembershipLetterDeliveryOptions = false;
    },
    [fetchTaxCountries.pending]: (state) => {
      state.fetchingTaxCountries = true;
    },
    [fetchTaxCountries.fulfilled]: (state, { payload }) => {
      state.fetchingTaxCountries = false;
      state.taxCountries = payload;
    },
    [fetchTaxCountries.rejected]: (state) => {
      state.fetchingTaxCountries = false;
    },
    [postUpdatedAccountInformation.pending]: (state) => {
      state.postingUpdatedAccountInformation = true;
    },
    [postUpdatedAccountInformation.fulfilled]: (state, { payload }) => {
      state.postingUpdatedAccountInformation = false;
      state.updatedAccountInformationStatus = payload;
    },
    [postUpdatedAccountInformation.rejected]: (state) => {
      state.postingUpdatedAccountInformation = false;
    },
    [fetchCurrentUserWriteMifidAuthorizationCustomers.pending]: (state) => {
      state.fetchingCurrentUserWriteMifidAuthorizationCustomers = true;
    },
    [fetchCurrentUserWriteMifidAuthorizationCustomers.fulfilled]: (state, { payload }) => {
      state.fetchingCurrentUserWriteMifidAuthorizationCustomers = false;
      state.currentUserWriteMifidAuthorizationCustomers = payload;
    },
    [fetchCurrentUserWriteMifidAuthorizationCustomers.rejected]: (state) => {
      state.fetchingCurrentUserWriteMifidAuthorizationCustomers = false;
    },
    [checkCurrentUserWriteMifidAuthorization.pending]: (state) => {
      state.checkingCurrentUserWriteMifidAuthorization = true;
    },
    [checkCurrentUserWriteMifidAuthorization.fulfilled]: (state, { payload }) => {
      state.checkingCurrentUserWriteMifidAuthorization = false;
      state.currentUserHasMifidRights = payload;
    },
    [checkCurrentUserWriteMifidAuthorization.rejected]: (state) => {
      state.checkingCurrentUserWriteMifidAuthorization = false;
    },
    [fetchCustomerContactInfo.pending]: (state) => {
      state.fetchingCustomerContactinfo = true;
    },
    [fetchCustomerContactInfo.fulfilled]: (state, { payload }) => {
      state.fetchingCustomerContactinfo = false;
      state.customerContactinfo = payload;
    },
    [fetchCustomerContactInfo.rejected]: (state) => {
      state.fetchingCustomerContactinfo = false;
    },
    [fetchCustomerAccountInfo.pending]: (state) => {
      state.fetchingCustomerAccountInfo = true;
    },
    [fetchCustomerAccountInfo.fulfilled]: (state, { payload }) => {
      state.fetchingCustomerAccountInfo = false;
      state.customerAccountInfo = payload;
    },
    [fetchCustomerAccountInfo.rejected]: (state) => {
      state.fetchingCustomerAccountInfo = false;
    },
    [fetchUserOrderSecurity.pending]: (state) => {
      state.fetchingUserOrderSecurity = true;
    },
    [fetchUserOrderSecurity.fulfilled]: (state, { payload }) => {
      state.fetchingUserOrderSecurity = false;
      state.userOrderSecurity = payload;
    },
    [fetchUserOrderSecurity.rejected]: (state) => {
      state.fetchingUserOrderSecurity = false;
    },
    [fetchCustomerTaxCountries.pending]: (state) => {
      state.fetchingCustomerTaxCountries = true;
    },
    [fetchCustomerTaxCountries.fulfilled]: (state, { payload }) => {
      state.fetchingCustomerTaxCountries = false;
      state.customerTaxCountries = payload;
    },
    [fetchCustomerTaxCountries.rejected]: (state) => {
      state.fetchingCustomerTaxCountries = false;
    },
    [fetchShowTaxInformation.fulfilled]: (state, { payload }) => {
      state.showTaxInformation = payload;
    },
    [fetchTaxInformation.pending]: (state) => {
      state.fetchingTaxInformation = true;
    },
    [fetchTaxInformation.fulfilled]: (state, { payload }) => {
      state.fetchingTaxInformation = false;
      state.taxInformation = payload;
    },
    [fetchTaxInformation.rejected]: (state) => {
      state.fetchingTaxInformation = false;
    },
    [fetchUserSettings.pending]: (state) => {
      state.fetchingUserSettings = true;
      state.userSetting = null;
    },
    [fetchUserSettings.fulfilled]: (state, { payload }) => {
      state.fetchingUserSettings = false;
      state.userSettings = payload;
    },
    [fetchUserSettings.rejected]: (state) => {
      state.fetchingUserSettings = false;
    },
    [saveUserSettings.pending]: (state) => {
      state.savingUserSettings = true;
    },
    [saveUserSettings.fulfilled]: (state, { payload }) => {
      state.savingUserSettings = false;
      state.saveUserSettingsStatus = payload;
    },
    [saveUserSettings.rejected]: (state) => {
      state.savingUserSettings = false;
    },
    [updatePassword.pending]: (state) => {
      state.updatingPassword = true;
    },
    [updatePassword.fulfilled]: (state, { payload }) => {
      state.updatingPassword = false;
      state.updatePasswordStatus = payload;
    },
    [updatePassword.rejected]: (state) => {
      state.updatingPassword = false;
    },
    [saveTaxInformation.pending]: (state) => {
      state.savingTaxInformation = true;
    },
    [saveTaxInformation.fulfilled]: (state, { payload }) => {
      state.savingTaxInformation = false;
      state.saveTaxInformationStatus = payload;
    },
    [saveTaxInformation.rejected]: (state) => {
      state.savingTaxInformation = false;
      state.saveTaxInformationStatus = 'fail';
    },
    [saveContactInformation.pending]: (state) => {
      state.savingContactInformation = true;
    },
    [saveContactInformation.fulfilled]: (state, { payload }) => {
      state.savingContactInformation = false;
      state.saveContactInformationStatus = payload;
    },
    [saveContactInformation.rejected]: (state) => {
      state.savingContactInformation = false;
    },
    [fetchCustomerTaxCountries.pending]: (state) => {
      state.fetchingCustomerTaxCountries = true;
    },
    [fetchCustomerTaxCountries.fulfilled]: (state, { payload }) => {
      state.fetchingCustomerTaxCountries = false;
      state.customerTaxCountries = payload;
    },
    [fetchCustomerTaxCountries.rejected]: (state) => {
      state.fetchingCustomerTaxCountries = false;
    },
    [updateResidencyCountry.pending]: (state) => {
      state.updatingResidencyCountry = true;
    },
    [updateResidencyCountry.fulfilled]: (state, { payload }) => {
      state.updatingResidencyCountry = false;
      state.residencyCountryUpdateStatus = payload;
    },
    [updateResidencyCountry.rejected]: (state) => {
      state.updatingResidencyCountry = false;
    },
    [fetchIsMifidValid.pending]: (state) => {
      state.fetchingIsMifidValid = true;
    },
    [fetchIsMifidValid.fulfilled]: (state, { payload }) => {
      state.fetchingIsMifidValid = false;
      state.isMifidValid = payload;
    },
    [fetchIsMifidValid.rejected]: (state) => {
      state.fetchingIsMifidValid = false;
    },
    [fetchTotalWealth.pending]: (state) => {
      state.fetchingTotalWealth = true;
    },
    [fetchTotalWealth.fulfilled]: (state, { payload }) => {
      state.fetchingTotalWealth = false;
      state.totalWealth = payload;
    },
    [fetchTotalWealth.rejected]: (state) => {
      state.fetchingTotalWealth = false;
      state.totalWealth = 0;
    },
    [fetchCurrencies.pending]: (state) => {
      state.fetchingCurrencies = true;
    },
    [fetchCurrencies.fulfilled]: (state, { payload }) => {
      state.fetchingCurrencies = false;
      state.currencies = payload;
    },
    [fetchCurrencies.rejected]: (state) => {
      state.fetchingCurrencies = false;
    },
    [addBankAccount.pending]: (state) => {
      state.postingBankAccount = true;
    },
    [addBankAccount.fulfilled]: (state, { payload }) => {
      state.postingBankAccount = false;
      state.postBankInformationStatus = payload;
    },
    [addBankAccount.rejected]: (state) => {
      state.postingBankAccount = false;
    },
  },
});

export const {
  resetMifidData,
  resetPersonnelFundContactFormData,
  resetSaveUserSettingStatuses,
  setOriginOfAssetError,
  setTermsReadError,
} = usersSlice.actions;

export default usersSlice.reducer;
