import {
  fetchTupasUsers,
  sendAuthCode,
  tupasLogin,
  logout,
  fetchTupasLoginInfo,
  sendAuthCodeReact,
  fetchBankerCustomers,
  updateCustomerId,
} from '../redux/authSlice';
import { fetchLandingPageSetting, fetchDiscountEntitlement } from '../redux/usersSlice';
import {
  SHOW_POP_UP,
  SHOW_POP_UP_HRJ,
  getTupasSecurityIdentifier,
  clearTupasSecurityIdentifier,
  clearAuthData,
  clearCustomerId,
  clearPopUpState,
} from '../app/sessionStorage';
import { resetDatePicker } from '../redux/datePickerSlice';
import { resetDatePicker as resetFundDatePicker } from '../redux/fundsSlice';

/**
 * Log Elite user in using data received from Tupas.
 * @param {function} dispatch - Redux dispatch function.
 * @param {string} guid - GUID created to identify login flow.
 * @param {string} userId - ID of user to be logged in (GUID).
 * @param {string} username - Username of user to be logged in.
 * @param {function} redirect - Function to redirect user to different URL.
 * @param {string} tupasId - Tupas ID received from API.
 */
export function loginTupasEliteUser(dispatch, guid, userId, username, redirect, tupasId) {
  dispatch(sendAuthCode({
    guid, isEliteUserLogin: true, userId, tupasId,
  }))
    .unwrap()
    .then((authCodeResult) => {
      if (authCodeResult) {
        dispatch(tupasLogin({ code: authCodeResult, guid, username }))
          .unwrap()
          .then(() => {
            // Check if user has customers.
            dispatch(fetchBankerCustomers())
              .unwrap()
              .then((customers) => {
                if (customers?.length > 0) {
                  // Redirect user to customer select view.
                  redirect('/asiakkaat');
                } else {
                  // Otherwise redirect to front page.
                  redirect('/varallisuus');
                }
              });
          })
          .catch(() => {
            redirect('/kirjaudu');
          });
      } else {
        redirect('/kirjaudu');
      }
    })
    .catch(() => {
      redirect('/kirjaudu');
    });
}

/**
 * Fetches Elite users using Tupas data, and logs user in if only one user found.
 * @param {function} dispatch - Redux dispatch function.
 * @param {string} guid - GUID created to identify login flow.
 * @param {function} setUsers - User data saving function.
 * @param {function} setError - Function for setting error (boolean).
 * @param {function} redirect - Function to redirect user to different URL.
 * @param {string} signicatCode - Code received from Signicat.
 * @param {function} setTupasId - Function for saving Tupas ID received from API.
 */
export function tupasEliteReturnFlow(
  dispatch, guid, setUsers, setError, redirect, signicatCode, setTupasId,
) {
  try {
    // Check if Tupas auth is in progress.
    if (!getTupasSecurityIdentifier()) {
      throw Error;
    }
    clearTupasSecurityIdentifier();

    // Fetch IDs and usernames.
    dispatch(fetchTupasUsers({ signicatCode }))
      .unwrap()
      .then((usersResult) => {
        // Parse and save user data.
        const userList = usersResult.Users ? usersResult.Users.map((user) => ({
          username: user.UserName,
          userId: user.Id,
        })) : [];
        setUsers(userList);
        const tupasId = usersResult.TupasId;
        setTupasId(tupasId);

        // One user found - log user in automatically.
        if (userList.length === 1) {
          const { userId, username } = userList[0];
          loginTupasEliteUser(dispatch, guid, userId, username, redirect, tupasId);
        }
      });
  } catch {
    setError(true);
  }
}

/**
 * Logs user out and clears cache of authentication data.
 * @param {function} dispatch - Redux dispatch function.
 */
export function logUserOut(dispatch) {
  dispatch(logout());
  clearAuthData();
  clearCustomerId();
  clearPopUpState(SHOW_POP_UP);
  clearPopUpState(SHOW_POP_UP_HRJ);
  dispatch(updateCustomerId(null));
  dispatch(resetDatePicker());
  dispatch(resetFundDatePicker());
}

/**
 * Checks if logout landing page should be shown to user, then redirects accordingly.
 * @param {function} dispatch - Redux dispatch function.
 * @param {Object} currentUser - Data for logged-in user.
 * @param {string} currentUrl - URL for current view.
 * @param {function} redirect - Function to redirect user to different URL.
 */
export function checkLogoutPage(dispatch, currentUser, currentUrl, redirect) {
  const isBuyFundLocation = currentUrl.toLowerCase().indexOf('/ostarahastoa') !== -1;
  const isLogoutLocation = currentUrl.toLowerCase().indexOf('/kirjauduuloshrj') !== -1;

  if (currentUser?.isPersonnelFundCustomer && !isBuyFundLocation && !isLogoutLocation) {
    dispatch(fetchLandingPageSetting())
      .unwrap()
      .then((showLandingPage) => {
        if (showLandingPage) {
          dispatch(fetchDiscountEntitlement())
            .unwrap()
            .then((entitledForDiscount) => {
              if (entitledForDiscount) {
                redirect('/kirjauduuloshrj');
              } else {
                logUserOut(dispatch);
              }
            });
        } else {
          logUserOut(dispatch);
        }
      })
      .catch(() => {
        logUserOut(dispatch);
      });
  } else {
    logUserOut(dispatch);
  }
}

/**
 * Log personnel fund user in using data received from Tupas.
 * @param {function} dispatch - Redux dispatch function.
 * @param {string} guid - GUID created to identify login flow.
 * @param {function} redirect - Function to redirect user to different URL.
 * @param {string} signicatCode - Code received from Signicat.
 */
export function loginPersonnelFundUser(dispatch, guid, redirect, signicatCode) {
  // Check if Tupas auth is in progress.
  if (!getTupasSecurityIdentifier()) {
    throw Error;
  }
  clearTupasSecurityIdentifier();

  dispatch(sendAuthCodeReact({ guid, isPersonnelFundUserLogin: true, signicatCode }))
    .unwrap()
    .then((authCodeResult) => {
      if (authCodeResult) {
        dispatch(tupasLogin({ code: authCodeResult, guid }))
          .unwrap()
          .then(() => {
            dispatch(fetchTupasLoginInfo())
              .unwrap()
              .then((data) => {
                if (data) {
                  redirect('/henkilostorahasto');
                } else {
                  redirect('/omattiedot');
                }
              });
          })
          .catch(() => {
            redirect('/jasenpalvelu');
          });
      } else {
        redirect('/jasenpalvelu');
      }
    })
    .catch(() => {
      redirect('/jasenpalvelu/error');
    });
}

/**
 * Adds bank method and new return URL in Signicat URL.
 * @param {string} signicatUrl - Signicat URL received from API.
 * @param {string} bankMethod - ID of selected bank method.
 * @param {string} language - UI language (fi, se, en);
 * @returns {string} - Updated Signicat URL.
 */
export function parseSignicatUrl(signicatUrl, bankMethod, language) {
  const urlWithMethod = signicatUrl.replace('urn:signicat:oidc:portal:ftn', `urn:signicat:oidc:method:${bankMethod}`);
  const urlWithReturnUrl = urlWithMethod.replace('/tupasReturn', '/tupasReturn/react');
  const urlWithLanguage = `${urlWithReturnUrl}&ui_locales=${language === 'se' ? 'sv' : language}`;
  return urlWithLanguage;
}

/**
 * Log Elite user in using data received from Tupas.
 * @param {function} dispatch - Redux dispatch function.
 * @param {string} flowGuid - GUID created to identify login flow.
 * @param {string} userId - ID of user to be logged in (GUID).
 * @param {string} username - Username of user to be logged in.
 * @param {function} redirect - Function to redirect user to different URL.
 * @param {string} tupasId - Tupas ID received from API.
 * @param {string} fundGuid - Fund GUID which user is buying.
 * @param {boolean} [hrjSaleAvailable=false] - HRJ sale availability for fund.
 */
export function loginBuyFundUser(
  dispatch,
  flowGuid,
  userId,
  username,
  redirect,
  tupasId,
  fundGuid,
  hrjSaleAvailable = false,
) {
  const successPath = `/ostaRahastoa/${fundGuid}${hrjSaleAvailable ? '/false/hrjSale' : ''}`;
  const errorPath = `/ostaRahastoa/kirjautuminen/${fundGuid}${hrjSaleAvailable ? '/false/hrjSale' : ''}`;

  dispatch(sendAuthCode({
    guid: flowGuid, isEliteUserLogin: true, userId, tupasId,
  }))
    .unwrap()
    .then((authCodeResult) => {
      if (authCodeResult) {
        dispatch(tupasLogin({ code: authCodeResult, guid: flowGuid, username }))
          .unwrap()
          .then(() => {
            redirect(successPath);
          })
          .catch(() => {
            redirect(errorPath);
          });
      } else {
        redirect(errorPath);
      }
    })
    .catch(() => {
      redirect(errorPath);
    });
}

/**
 * Fetches Elite users using Tupas data, and logs user in if only one user found.
 * @param {function} dispatch - Redux dispatch function.
 * @param {string} guid - GUID created to identify login flow.
 * @param {function} setUsers - User data saving function.
 * @param {function} setError - Function for setting error (boolean).
 * @param {function} redirect - Function to redirect user to different URL.
 * @param {string} signicatCode - Code received from Signicat.
 * @param {function} setTupasId - Function for saving Tupas ID received from API.
 * @param {string} fundGuid - Fund GUID which user is buying.
 * @param {boolean} hrjSaleAvailable - HRJ sale availability for fund.
 */
export function tupasBuyFundReturnFlow(
  dispatch,
  flowGuid,
  setUsers,
  setError,
  redirect,
  signicatCode,
  setTupasId,
  fundGuid,
  hrjSaleAvailable,
) {
  try {
    // Check if Tupas auth is in progress.
    if (!getTupasSecurityIdentifier()) {
      throw Error;
    }
    clearTupasSecurityIdentifier();

    // Fetch IDs and usernames.
    dispatch(fetchTupasUsers({ signicatCode }))
      .unwrap()
      .then((usersResult) => {
        // Parse and save user data.
        const userList = usersResult.Users ? usersResult.Users.map((user) => ({
          username: user.UserName,
          userId: user.Id,
        })) : [];
        setUsers(userList);
        const tupasId = usersResult.TupasId;
        setTupasId(tupasId);

        // One user found - log user in automatically.
        if (userList.length === 1) {
          const { userId, username } = userList[0];
          loginBuyFundUser(
            dispatch,
            flowGuid,
            userId,
            username,
            redirect,
            tupasId,
            fundGuid,
            hrjSaleAvailable,
          );
        }
      });
  } catch {
    setError(true);
  }
}
