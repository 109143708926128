import { format } from 'date-fns';
import { setStatementOpenTransactionData, setStatementSummaryData, setStatementTableData } from '../redux/transactionsSlice';
import { sortObjectsByValueOf } from './commonFunctions';
import { toDecimalWithComma, percentFormatter } from './validationModelsAndFunctions';

/* eslint-disable max-len */
const ASSET_TYPE = 'assetType';
const CLASSIFICATION = 'classification';
const INSTRUMENT_TYPE = 'instrumentType';
const CURRENCY = 'currency';
const CURRENCY_WITH_INSTRUMENT_TYPE = 'currencyWithInstrumentType';
const DATA = 'data';
const INSTRUMENT_TYPE_CURRENCY = 'Currency';
const INSTRUMENT_TYPE_CASH = 'Cash';
const FIFO_BUY_ITEMS = 'fifoBuyItems'; // FIFO-Buy-items related to a sell-transaction.
const HIDDEN = 'hidden';
const ITEM_NAME_EQUITY = 'Equities';
const ITEM_NAME_ALTERNATIVES = 'Alternatives';
const ITEM_NAME_FIXED_INCOME = 'Fixed Income';
const ITEM_TYPE_SUMMARY = 'summary';
const ASSET_TYPE_BALANCED_FUNDS = 'Balanced Funds';
const INSTRUMENT_TYPE_FUND = 'Fund';
const ELEMENT_TYPE_ROOT = 'root';
const SORT_BY_HOLDINGS = 'sortByHoldings';
const SORT_BY_DATE = 'sortByDate';
const CURRENCY_EUR = 'EUR';
const PURCHASES = 'purchases';

export const groupingConstants = {
  ASSET_TYPE,
  CLASSIFICATION,
  INSTRUMENT_TYPE,
  CURRENCY,
  CURRENCY_WITH_INSTRUMENT_TYPE,
  DATA,
  INSTRUMENT_TYPE_CASH,
  INSTRUMENT_TYPE_CURRENCY,
  FIFO_BUY_ITEMS,
  HIDDEN,
  ITEM_NAME_ALTERNATIVES,
  ITEM_NAME_EQUITY,
  ITEM_NAME_FIXED_INCOME,
  ITEM_TYPE_SUMMARY,
  ASSET_TYPE_BALANCED_FUNDS,
  INSTRUMENT_TYPE_FUND,
  ELEMENT_TYPE_ROOT,
  SORT_BY_HOLDINGS,
  SORT_BY_DATE,
  CURRENCY_EUR,
  PURCHASES,
};

export const groupedBy = (groupingSelections) => (
  {
    groupedbyAssetType: groupingSelections.includes(ASSET_TYPE),
    groupedbyClass: groupingSelections.includes(CLASSIFICATION),
    groupedByInstrumentType: groupingSelections.includes(INSTRUMENT_TYPE),
    groupedByCurrency: groupingSelections.includes(CURRENCY),
  });

// A helper function for tree generation
// Finds if a group node is already found on the tree,
// then either creates the node and inserts it to the tree, or returns the found node
function createGroupingBy(groupBy, array, holdingToHandle, t) {
  let holding = holdingToHandle;
  let grouped = {};

  switch (groupBy) {
    case ASSET_TYPE:
      grouped = array.find((item) => item.name === holding.assetType);
      if (!grouped) {
        grouped = { name: holding.assetType, children: [], type: ASSET_TYPE };
        array.push(grouped);
      }
      break;

    case CLASSIFICATION:
      grouped = array.find((item) => item.name === holding.classification);
      if (!grouped) {
        const newGroup = { name: holding.classification, children: [], type: CLASSIFICATION };

        // Classification for Balanced funds is always hidden, ignore the row
        if (holding.assetType !== ASSET_TYPE_BALANCED_FUNDS) {
          grouped = newGroup;
          array.push(grouped);
        }
      }
      break;

    case INSTRUMENT_TYPE:
      // Group all different funds under Fund
      if (holding.instrument.instrumentType === INSTRUMENT_TYPE_FUND || holding.instrument.instrumentType === 'FundAM' || holding.instrument.instrumentType === 'FundOLD') {
        holding = { ...holding, instrument: { ...holding.instrument, instrumentType: INSTRUMENT_TYPE_FUND } };
      }
      grouped = array.find((item) => item.name === holding.instrument.instrumentType);
      if (!grouped) {
        grouped = { name: holding.instrument.instrumentType, children: [], type: INSTRUMENT_TYPE };
        array.push(grouped);
      }
      break;

    case CURRENCY_WITH_INSTRUMENT_TYPE:
      grouped = array.find((item) => item.name === `${t(holding.instrument.instrumentType)} (${holding.instrument.currency})`);
      if (!grouped) {
        grouped = { name: `${t(holding.instrument.instrumentType)} (${holding.instrument.currency})`, children: [], type: CURRENCY };
        array.push(grouped);
      }
      break;

    case CURRENCY:
      grouped = array.find((item) => item.name === holding.instrument.currency);
      if (!grouped) {
        grouped = { name: holding.instrument.currency, children: [], type: CURRENCY };
        array.push(grouped);
      }
      break;

    default:
  }
  return grouped;
}

// Creates a tree structure of table data to group them according to grouping controls
// The tree is used to create an array for html table to display data that is grouped correctly
export const createGroupingTree = (
  holdings, groupingSelections, t,
) => {
  const {
    groupedbyAssetType, groupedbyClass, groupedByInstrumentType, groupedByCurrency,
  } = groupedBy(groupingSelections);

  // The root node of the tree. All other nodes are similar to this
  const tree = {
    name: 'WEB_REPORTS.ALL_ASSETS', children: [], type: ELEMENT_TYPE_ROOT, summary: {},
  };

  holdings.forEach((holding) => {
    // Here are nine if-branches that create the tree based on the nine different combinations of groupings
    // according to the boolean parameters groupedbyAssetType, groupedbyClass, groupedByInstrumentType, groupedByCurrency
    // All nine if-branches follow the same logic

    let newItem = null;

    if (groupedbyAssetType && !groupedbyClass && !groupedByInstrumentType && !groupedByCurrency) {
      // Create the node for the grouping by asset type
      const byAssetType = createGroupingBy(ASSET_TYPE, tree.children, holding, t);
      // Append the holdings to its place on the tree
      newItem = { name: '', type: DATA, children: holding };
      byAssetType.children.push(newItem);
    } else if (groupedbyAssetType && groupedbyClass && !groupedByInstrumentType && !groupedByCurrency) {
      const byAssetType = createGroupingBy(ASSET_TYPE, tree.children, holding, t);
      const byClass = createGroupingBy(CLASSIFICATION, byAssetType.children, holding, t);
      newItem = { name: '', type: DATA, children: holding };

      // Balanced Funds ignore classification
      if (byClass) {
        byClass.children.push(newItem);
      } else {
        byAssetType.children.push(newItem);
      }
    } else if (groupedbyAssetType && !groupedbyClass && groupedByInstrumentType && !groupedByCurrency) {
      const byAssetType = createGroupingBy(ASSET_TYPE, tree.children, holding, t);
      const byInstrumentType = createGroupingBy(INSTRUMENT_TYPE, byAssetType.children, holding, t);

      newItem = { name: '', type: DATA, children: holding };
      byInstrumentType.children.push(newItem);
    } else if (groupedbyAssetType && groupedbyClass && groupedByInstrumentType && !groupedByCurrency) {
      const byAssetType = createGroupingBy(ASSET_TYPE, tree.children, holding, t);
      const byClass = createGroupingBy(CLASSIFICATION, byAssetType.children, holding, t);
      if (byClass && byClass.children) {
        const byInstrumentType = createGroupingBy(INSTRUMENT_TYPE, byClass.children, holding, t);
        newItem = { name: '', type: DATA, children: holding };
        byInstrumentType.children.push(newItem);
      } else {
        newItem = { name: '', type: DATA, children: holding };
        byAssetType.children.push(newItem);
      }
    } else if (groupedbyAssetType && !groupedbyClass && groupedByInstrumentType && groupedByCurrency) {
      const byAssetType = createGroupingBy(ASSET_TYPE, tree.children, holding, t);
      const byInstrumentType = createGroupingBy(INSTRUMENT_TYPE, byAssetType.children, holding, t);
      const byCurrency = createGroupingBy(CURRENCY_WITH_INSTRUMENT_TYPE, byInstrumentType.children, holding, t);
      newItem = { name: '', type: DATA, children: holding };
      byCurrency.children.push(newItem);
    } else if (groupedbyAssetType && groupedbyClass && groupedByInstrumentType && groupedByCurrency) {
      const byAssetType = createGroupingBy(ASSET_TYPE, tree.children, holding, t);
      const byClass = createGroupingBy(CLASSIFICATION, byAssetType.children, holding, t);
      if (byClass && byClass.children) {
        const byInstrumentType = createGroupingBy(INSTRUMENT_TYPE, byClass.children, holding, t);
        const byCurrency = createGroupingBy(CURRENCY_WITH_INSTRUMENT_TYPE, byInstrumentType.children, holding, t);
        newItem = { name: '', type: DATA, children: holding };
        byCurrency.children.push(newItem);
      } else {
        newItem = { name: '', type: DATA, children: holding };
        byAssetType.children.push(newItem);
      }
    } else if (!groupedbyAssetType && !groupedbyClass && groupedByInstrumentType && !groupedByCurrency) {
      const byInstrumentType = createGroupingBy(INSTRUMENT_TYPE, tree.children, holding, t);

      newItem = { name: '', type: DATA, children: holding };
      byInstrumentType.children.push(newItem);
    } else if (!groupedbyAssetType && !groupedbyClass && groupedByInstrumentType && groupedByCurrency) {
      const byInstrumentType = createGroupingBy(INSTRUMENT_TYPE, tree.children, holding, t);
      const byCurrency = createGroupingBy(CURRENCY_WITH_INSTRUMENT_TYPE, byInstrumentType.children, holding, t);

      newItem = { name: '', type: DATA, children: holding };
      byCurrency.children.push(newItem);
    } else if (!groupedbyAssetType && !groupedbyClass && !groupedByInstrumentType && groupedByCurrency) {
      const byCurrency = createGroupingBy(CURRENCY, tree.children, holding, t);

      newItem = { name: '', type: DATA, children: holding };
      byCurrency.children.push(newItem);
    // eslint-disable-next-line brace-style
    }
    // If all grouping variables are false holdings are shown in alphabetical order
    else {
      newItem = { name: '', type: DATA, children: holding };

      tree.children.push(newItem);
    }
  });
  return tree;
};
// Asset types have a hard coded order, all other sorting is done alphabetically (except the cash holdings are always last)
function sortHoldingsTreeChildren(children, t) {
  if (children.length === 0) {
    return children;
  }
  // All children have same type
  const childType = children[0].type;
  let sortedChildren = children;

  const equities = children.filter((item) => item.name === ITEM_NAME_EQUITY);
  const alternatives = children.filter((item) => item.name === ITEM_NAME_ALTERNATIVES);
  const fixedIncome = children.filter((item) => item.name === ITEM_NAME_FIXED_INCOME);
  const other = children.filter((item) => !equities.includes(item) && !alternatives.includes(item)
    && !fixedIncome.includes(item));
  switch (childType) {
    case ASSET_TYPE:
      sortedChildren = [...equities, ...alternatives, ...other, ...fixedIncome];
      break;
    case CLASSIFICATION:
      // Sorted alphabetically, but cash last
      sortedChildren = children.slice().sort((a, b) => {
        if (a.name === INSTRUMENT_TYPE_CASH && b.name !== INSTRUMENT_TYPE_CASH) {
          return 1;
        } if (b.name === INSTRUMENT_TYPE_CASH && a.name !== INSTRUMENT_TYPE_CASH) {
          return -1;
        } if (t(a.name) === t(b.name)) {
          return 0;
        }
        return t(a.name) < t(b.name) ? -1 : 1;
      });
      break;
    case INSTRUMENT_TYPE:
      // Sorted alphabetically, but cash last
      sortedChildren = children.slice().sort((a, b) => {
        if (a.name === INSTRUMENT_TYPE_CASH && b.name !== INSTRUMENT_TYPE_CASH) {
          return 1;
        } if (b.name === INSTRUMENT_TYPE_CASH && a.name !== INSTRUMENT_TYPE_CASH) {
          return -1;
        } if (t(a.name) === t(b.name)) {
          return 0;
        }
        return t(a.name) < t(b.name) ? -1 : 1;
      });
      break;
    case CURRENCY:
      sortedChildren = sortObjectsByValueOf(children, 'name', true);
      break;
    case DATA:
      // Sorted alphabetically, but cash last
      sortedChildren = children.slice().sort((a, b) => {
        if (a.children.instrument.instrumentType === INSTRUMENT_TYPE_CASH && b.children.instrument.instrumentType !== INSTRUMENT_TYPE_CASH) {
          return 1;
        } if (b.children.instrument.instrumentType === INSTRUMENT_TYPE_CASH && a.children.instrument.instrumentType !== INSTRUMENT_TYPE_CASH) {
          return -1;
        } if (a.children.instrument.name === b.children.instrument.name) {
          if (a.children.purchaseDate) {
            return Date.parse(a.children.purchaseDate) < Date.parse(b.children.purchaseDate) ? -1 : 1;
          }
          return 0;
        }
        return a.children.instrument.name < b.children.instrument.name ? -1 : 1;
      });
      break;
    default:
  }
  return sortedChildren;
}

function sortTreeChildrenByConfirmDate(children, t) {
  if (children.length === 0) {
    return children;
  }
  // All children have same type
  const childType = children[0].type;
  let sortedChildren = children;

  switch (childType) {
    case INSTRUMENT_TYPE:
      // Sorted alphabetically
      sortedChildren = children.slice().sort((a, b) => {
        if (t(a.name) === t(b.name)) {
          return 0;
        }
        return t(a.name) < t(b.name) ? -1 : 1;
      });
      break;
    case CURRENCY:
      // Sorted alphabetically
      sortedChildren = sortObjectsByValueOf(children, 'name', true);
      break;
    case DATA:
      // Sorted by confirmation date
      sortedChildren = children.slice().sort((a, b) => {
        if (Date.parse(a.children.confirmationDate) === Date.parse(b.children.confirmationDate)) {
          return 0;
        }
        return Date.parse(a.children.confirmationDate) < Date.parse(b.children.confirmationDate) ? 1 : -1;
      });
      break;
    default:
  }
  return sortedChildren;
}
function pushDataToViewArray(dataNode, viewArray, treeDepth) {
  // Append the actual data to the viewArray
  const cssClasses = [`indent-level-${treeDepth}`];
  viewArray.push({
    type: dataNode.type, data: dataNode.children, cssClasses,
  });
}

function pushHeaderToViewArray(headerNode, viewArray, groupedByCurrency, treeDepth) {
  // Append the group header in to the viewArray
  // InstrumentType header is not used, if currency grouping is on, because the instrumentType is printed along with the currency header
  if (!(groupedByCurrency && headerNode.type === INSTRUMENT_TYPE)) {
    const cssClasses = [`indent-level-${treeDepth}`];

    // First two levels are bolded to differentiate them better from deeper levels
    if (treeDepth === 0) {
      if (headerNode.type === INSTRUMENT_TYPE || headerNode.type === CURRENCY || headerNode.type === CURRENCY_WITH_INSTRUMENT_TYPE) {
        cssClasses.push('report-table-header-2');
      } else {
        cssClasses.push('report-table-header-1');
      }
    } else if (treeDepth === 1) {
      if (headerNode.type === INSTRUMENT_TYPE || headerNode.type === CURRENCY || headerNode.type === CURRENCY_WITH_INSTRUMENT_TYPE) {
        cssClasses.push('report-table-header-2');
      } else {
        cssClasses.push('report-table-header-3');
      }
    } else {
      cssClasses.push('report-table-header-2');
    }

    viewArray.push({
      type: headerNode.type, data: headerNode.name, cssClasses,
    });
  }
}

// Creates an array that is used by the html-template to show the holdings in a table
// This is a recursive function that traverses the whole grouping tree and appends all the nodes to a single array
// The html template can display three kinds of items: headers, holdings and summaries. All of these result in rows in the html table
// Also calculates the value summaries for all the nodes in the tree and sorts the viewArray
// Params:
//   treeNode, Object, the tree node to process. This is used to iterate through the whole tree
//   viewArray, Array, the resulting array that is used by angular to populate the html table to display data
//   groupedByCurrency, Boolean, true if the table is grouped by currency. This is used to not show certain headers if currency grouping is on
//   includeSummaries, Boolean, true if group summaries are calculated and inserted to viewArray
//   treeDepth , how many levels deep we are currently in the tree. This is used for indentation of the table
export const createViewArrayFromGroupingTree = (initialTreeNode, existingViewArray, groupedByCurrency, includeFifoBuyItems, includeSummaries, sortBy, initialTreeDepth, t) => {
  // contains items like: {type: <string>, data: <object> or <string>, cssClasses: <array>}
  // The different types are: header, holding, summary
  const treeNode = initialTreeNode;
  const viewArray = existingViewArray || [];
  const treeDepth = initialTreeDepth || 0;

  let { children } = treeNode;
  const summaryOfNode = {
    name: treeNode.name, purchaseValue: 0, value: 0, changeInEuros: 0, changePercentage: 0,
  };

  // Sort this level of the tree
  if (sortBy === SORT_BY_HOLDINGS) {
    children = sortHoldingsTreeChildren(children, t);
  } else if (sortBy === SORT_BY_DATE) {
    children = sortTreeChildrenByConfirmDate(children, t);
  }

  children.forEach((child) => {
    const childNode = child;
    // If the type is holding, then we are at the bottom of the tree
    if (childNode.type === DATA) {
      pushDataToViewArray(childNode, viewArray, treeDepth);

      // Calculate the bottom level summary
      if (includeSummaries) {
        summaryOfNode.purchaseValue += childNode.children.purchaseValue;
        summaryOfNode.value += childNode.children.value;
        summaryOfNode.changeInEuros += childNode.children.changeInEuros;
      }

      // FIFO buy items (if exist)
      if (includeFifoBuyItems && childNode.children.associatedFifoBuyItems) {
        childNode.children.associatedFifoBuyItems.forEach((fifo, index) => {
          // Use itemId in table row keys
          const fifoWithItemId = {
            ...fifo,
            itemId: `${fifo.sellTransactionId}-${index}`,
          };
          const fifoNode = { type: FIFO_BUY_ITEMS, children: fifoWithItemId };
          pushDataToViewArray(fifoNode, viewArray, treeDepth + 1);
        });
      }
    // eslint-disable-next-line brace-style
    }

    // If the type is anything other, then we are somewhere in the middle or the top of the tree
    else {
      pushHeaderToViewArray(childNode, viewArray, groupedByCurrency, treeDepth);

      // Use recursion to go one level deeper in to the tree
      createViewArrayFromGroupingTree(childNode, viewArray, groupedByCurrency, includeFifoBuyItems, includeSummaries, sortBy, treeDepth + 1, t);

      // Summaries of child nodes can be calculated after the recursive function returns here
      // because by that time the childrens summaries have been calculated
      if (includeSummaries) { // TODO 2022 check that these actually get calculated??
        summaryOfNode.purchaseValue += childNode.summary?.purchaseValue;
        summaryOfNode.value += childNode.summary?.value;
        summaryOfNode.changeInEuros += childNode.summary?.changeInEuros;
      }
    }
  });
  if (includeSummaries) {
    summaryOfNode.changePercentage = summaryOfNode.changeInEuros / summaryOfNode.purchaseValue;
    treeNode.summary = summaryOfNode;
    // Append the summary to the viewArray
    // InstrumentType summary is not used, if currency grouping is on, because the instrumentType header is not used either in this case
    if (!(groupedByCurrency && treeNode.type === INSTRUMENT_TYPE)) {
      const cssClasses = [`indent-level-${treeDepth - 1}`];
      const cssClassesNoIndent = [];

      if ((treeDepth - 1) === 0) {
        if (treeNode.type === INSTRUMENT_TYPE || treeNode.type === CURRENCY || treeNode.type === CURRENCY_WITH_INSTRUMENT_TYPE) {
          cssClasses.push('report-table-header-2');
          cssClassesNoIndent.push('report-table-header-2');
        } else {
          cssClasses.push('report-table-header-1');
          cssClassesNoIndent.push('report-table-header-1');
        }
      } else if ((treeDepth - 1) === 1) {
        if (treeNode.type === INSTRUMENT_TYPE || treeNode.type === CURRENCY || treeNode.type === CURRENCY_WITH_INSTRUMENT_TYPE) {
          cssClasses.push('report-table-header-2');
        } else {
          cssClasses.push('report-table-header-3');
          cssClassesNoIndent.push('report-table-header-3');
        }
      } else {
        cssClasses.push('report-table-header-2');
        cssClassesNoIndent.push('report-table-header-2');
      }

      const newSummary = {
        type: ITEM_TYPE_SUMMARY, data: treeNode.summary, cssClasses, cssClassesNoIndent, summaryOf: treeNode.type,
      };

      if (treeNode.type === HIDDEN) {
        newSummary.type = HIDDEN;
      }
      viewArray.push(newSummary);
    }
  }
  return viewArray;
};

// Both classification and instrumentType groupings have INSTRUMENT_TYPE_CASH as header. We don't want duplicate headers in the table so this function removes them
// HOWEVER, these duplicate headers are removed in createViewArrayFromGroupingTree if groupedByCurrency is true, because in that case the instrumentType header is not used at all in the table
export const removeDuplicateCashHeadersAndSummaries = (viewArray, groupingSelections) => {
  viewArray.forEach((element, index) => {
    const {
      groupedbyClass, groupedByInstrumentType, groupedByCurrency,
    } = groupedBy(groupingSelections);
    // Remove duplicate cash headers and summaries
    if (element.type === INSTRUMENT_TYPE && element.data === INSTRUMENT_TYPE_CASH
      && groupedbyClass && groupedByInstrumentType && !groupedByCurrency) {
      viewArray.splice(index, 1);
    } else if (element.type === ITEM_TYPE_SUMMARY && element.data.name === INSTRUMENT_TYPE_CASH
      && groupedbyClass && groupedByInstrumentType && !groupedByCurrency) {
      viewArray.splice(index, 1);
    }
  });
};

// Makes the cash header always shown
export const addCashClassificationHeadersAndSummaries = (
  viewArray, groupingSelections,
) => {
  const {
    groupedbyClass, groupedByInstrumentType,
  } = groupedBy(groupingSelections);
  // Headers are already shown if either of these are true
  if (groupedbyClass || groupedByInstrumentType) {
    return;
  }

  let firstCashHoldingFound = false;
  let summaryIndex = 0;
  const summaryOfCash = {
    name: INSTRUMENT_TYPE_CASH, purchaseValue: 0, value: 0, changeInEuros: 0, changePercentage: 0,
  };
  let indentLevel = 0;

  viewArray.forEach((arrayElement, index) => {
    const element = arrayElement;
    // Find the end of cash holdings
    if (element.type !== DATA && firstCashHoldingFound) {
      summaryIndex = index;
    } else {
      // Find the first cash holding. Holdings are sorted so that cash holdings are always together
      if (element.type === DATA && element.data.classification === INSTRUMENT_TYPE_CASH && !firstCashHoldingFound) {
        firstCashHoldingFound = true;

        // Find the current indentLevel of cash holdings
        if (element.cssClasses[0].search('indent-level') !== -1) {
          indentLevel = element.cssClasses[0].charAt(element.cssClasses[0].length - 1);
        }

        // Add Cash header
        viewArray.splice(index, 0, {
          type: CLASSIFICATION,
          data: INSTRUMENT_TYPE_CASH,
          cssClasses: [`indent-level-${indentLevel}`, 'report-table-header-1'],
        });
      }
      // Add indent level to holdings and calculate summary
      if (element.type === DATA && element.data.classification === INSTRUMENT_TYPE_CASH) {
        element.cssClasses = [`indent-level-${Number(indentLevel) + 1}`];

        summaryOfCash.purchaseValue += element.data.purchaseValue;
        summaryOfCash.value += element.data.value;
        summaryOfCash.changeInEuros += element.data.changeInEuros;
      }
    }
  });
  if (firstCashHoldingFound) {
    // Add Cash summary
    summaryOfCash.changePercentage = summaryOfCash.changeInEuros / summaryOfCash.purchaseValue;
    const cssClasses = [`indent-level-${indentLevel}`, 'report-table-header-1'];
    viewArray.splice(summaryIndex, 0, {
      type: ITEM_TYPE_SUMMARY, data: summaryOfCash, cssClasses,
    });
  }
};

export const makeTableData = (
  portfolioReportViewArray, thisPortfolio, showPurchases, t, groupByCurrency, linkToInstrument,
) => {
  const summaryRow = portfolioReportViewArray[portfolioReportViewArray.length - 1];
  const data = [];
  portfolioReportViewArray.forEach((item) => {
    // move this function to separate file
    let name = null;
    let purchaseDate = null;
    let purchaseValue = null;
    let marketValue = null;
    let unrealized = null; // needs coloring, if includes currency color taken from currency value!!
    let change = null;
    let TWRProfit = null;
    const collapseData = {};
    const allowBuyAndSell = thisPortfolio?.ordersAllowed && thisPortfolio?.portfolioType !== 'AM';
    const showBuyAndSellButtons = item.type === DATA && item.data.instrument.instrumentType !== INSTRUMENT_TYPE_CURRENCY && allowBuyAndSell && item.data.peValues === null && item.data.instrument.instrumentType.indexOf('PE_Fund') === -1
    && item.data.instrument.instrumentId !== '' && item.data.instrument.instrumentId !== 0 && item.data.instrument.instrumentId && item.data.buyable && item.data.sellable;
    // Item type is DATA or SUMMARY, create row content
    if (item.type === DATA || item.type === ITEM_TYPE_SUMMARY) {
      // shared data handling
      purchaseValue = toDecimalWithComma(item.data.purchaseValue, 2);
      marketValue = toDecimalWithComma(item.data.value, 2);
      unrealized = toDecimalWithComma(item.data.changeInEuros, 2);
      if (item.data.purchaseValue) {
        change = percentFormatter.format(item.data.changePercentage);
      }

      // data handling dependent of type
      if (item.type === DATA) { // Item type is DATA
        purchaseDate = format(new Date(item.data.purchaseDate), 'dd.MM.yyyy');
        collapseData.purchasePrice = toDecimalWithComma(item.data.purchasePrice,
          item.data.instrument.priceDecimals ? item.data.instrument.priceDecimals : 2);
        collapseData.marketPrice = toDecimalWithComma(item.data.sharePrice,
          item.data.instrument.priceDecimals ? item.data.instrument.priceDecimals : 2);
        collapseData.amount = thisPortfolio.source === 3 && showPurchases
          ? toDecimalWithComma(item.data.numberOfSharesAvailable, 6)
          : toDecimalWithComma(item.data.numberOfShares, 6);
        collapseData.date = item.data.priceDate ? format(new Date(item.data.priceDate), 'dd.MM.yyyy') : null;
        collapseData.portion = percentFormatter.format(item.data.value / summaryRow.data.value);
        TWRProfit = item.data.changePercentageWithDividends !== null && item.data.changePercentageWithDividends !== 0
          ? percentFormatter.format(item.data.changePercentageWithDividends)
          : '';
        if (item.data.instrument.currency !== CURRENCY_EUR
          && groupByCurrency) {
          purchaseValue = `${purchaseValue} ${toDecimalWithComma(item.data.purchaseValueInInstrumentCurrency, 2)}`;
          marketValue = `${marketValue} ${toDecimalWithComma(item.data.valueInInstrumentCurrency, 2)}`;
          unrealized = `${unrealized} ${toDecimalWithComma(item.data.changeInInstrumentCurrency, 2)}`;
          collapseData.marketPrice = `${collapseData.marketPrice} ${toDecimalWithComma(item.data.purchasePriceInInstrumentCurrency,
            item.data.instrument.priceDecimals ? item.data.instrument.priceDecimals : 2)}`;
          collapseData.amount = `${collapseData.amount} ${toDecimalWithComma(item.data.sharePriceInInstrumentCurrency,
            item.data.instrument.priceDecimals ? item.data.instrument.priceDecimals : 2)}`;
          if (item.data.changePercentageInInstrumentCurrency
            || item.data.changePercentageInInstrumentCurrency === 0) {
            unrealized = `${percentFormatter.format(item.data.changeInEuros)} ${percentFormatter.format(item.data.changePercentageInInstrumentCurrency)}`;
          }
        }
        name = item.data.instrument.name.trim();
        if (item.data.fundSerieWebTradeable === false) {
          name = item.data.instrument.name.trim();
        } else if (item.data.fundSerieWebTradeable === true) {
          name = linkToInstrument(item.data.instrument.name.trim(),
            item.data.instrument.instrumentId);
        }
      } else if (item.type === ITEM_TYPE_SUMMARY) { // item type is SUMMARY
        name = `${t(item.data.name)} ${t('WEB_REPORTS.TOTAL_SUM')}`;
      }
    } else { // item type is NOT DATA OR SUMMARY - it is some level of heading
      name = t(item.data);
    }

    const row = {
      ...item,
      name,
      id: item.data.fundSerieWebTradeable === true ? item.data.instrument.name : name,
      purchaseDate,
      purchaseValue,
      marketValue,
      unrealized,
      change,
      showBuyAndSellButtons,
      collapseData,
      TWRProfit,
    };

    data.push(row);
  });
  return data;
};

/**
 * legacy function from old web service to handle missing instrument name
 * @param {Object[]} holdingsOrPurchases - holdings or purchases to turn into table data.
 * @param {String[]} groupingSelections - list of grouping selections.
 * @param {Function} t - translate operation.
 * @returns {Object[]} - array of table rows.
 */
export const createPortfolioReportViewArray = (holdingsOrPurchases, groupingSelections, t) => {
  const tree = createGroupingTree(holdingsOrPurchases, groupingSelections, t);
  const viewArray = createViewArrayFromGroupingTree(tree, null, groupingSelections.includes(CURRENCY), null, true, SORT_BY_HOLDINGS, null, t);
  removeDuplicateCashHeadersAndSummaries(viewArray, groupingSelections);
  addCashClassificationHeadersAndSummaries(viewArray, groupingSelections);
  return viewArray;
};

const additionalTotalBalanceFromEndDateTransactions = (statementTransactions, endDate, statementBalance) => {
  let additionalTotalBalance = 0;
  const formattedEndDate = format(new Date(endDate), 'yyyy-MM-ddT00:00:00');
  const finalBalance = {
    totalBalance: 0,
    pendingDeposits: 0,
    pendingWithdraws: 0,
  };

  if (statementTransactions?.length > 0) {
    statementTransactions.forEach((element) => {
      if (element.clearingDate === formattedEndDate) {
        additionalTotalBalance += element.totalAmount;
      }
    });
  }

  finalBalance.totalBalance = statementBalance?.endingBalance?.confirmedBalance || 0;
  finalBalance.pendingDeposits = statementBalance?.endingBalance?.pendingDeposits || 0;
  finalBalance.pendingWithdraws = statementBalance?.endingBalance?.pendingWithdraws || 0;

  finalBalance.totalBalance += additionalTotalBalance;
  return finalBalance;
};

const calculateStatementSummaryAndBalances = (initialViewArray, startingBalance, startDate, endDate) => {
  if (!initialViewArray) {
    return [];
  }

  let currentBalance = startingBalance;
  const statementSummary = {
    type: 'summary', depositsCount: 0, depositsSum: 0, withdrawsCount: 0, withdrawsSum: 0,
  };

  const viewArray = initialViewArray.map((element) => {
    if (element.totalAmount < 0) {
      statementSummary.withdrawsCount += 1;
      statementSummary.withdrawsSum += element.totalAmount;
    } else {
      statementSummary.depositsCount += 1;
      statementSummary.depositsSum += element.totalAmount;
    }
    currentBalance += element.totalAmount;
    return { ...element, balance: currentBalance };
  });

  const startBalanceItem = { type: 'BALANCE', description: startDate, balance: startingBalance };
  viewArray.splice(0, 0, startBalanceItem);
  const endBalanceItem = { type: 'BALANCE', description: endDate, balance: currentBalance };
  viewArray.push(endBalanceItem);

  viewArray.push(statementSummary);

  return viewArray;
};

export const createStatementTable = (transactions, startingBalance, startingBalanceDate, endingBalanceDate) => {
  if (transactions?.length > 0) {
    const statementTransactions = transactions;
    const statementViewArray = calculateStatementSummaryAndBalances(statementTransactions, startingBalance.totalBalance, startingBalanceDate, endingBalanceDate);
    return statementViewArray;
  } return [];
};

const calculateOpenTransactions = (openMoneyTransactionViewArray) => {
  if (openMoneyTransactionViewArray?.length > 0) {
    let redemptionEstimate = 0;
    let subscriptionEstimate = 0;

    openMoneyTransactionViewArray.forEach((element) => {
      if (element.transactionType_EN === 'Cash Withdraw') {
        redemptionEstimate += element.purchaseValue;
      }
      if (element.transactionType_EN === 'Cash Deposit') {
        subscriptionEstimate += element.purchaseValue;
      }
    });
    return { redemptionEstimate, subscriptionEstimate };
  }
  return null;
};

export const createStatementSummaryInfo = (statementViewArray, endDate) => {
  if (statementViewArray) {
    let statementSummaryArray = null;
    let statementBalanceArray = null;
    statementViewArray.forEach((element) => {
      if (element.type === 'BALANCE' && new Date(element.description).getTime() === new Date(endDate).getTime()) {
        statementBalanceArray = element;
      }
      if (element.type === 'summary') {
        statementSummaryArray = element;
      }
    });
    return { statementBalanceArray, statementSummaryArray };
  } return null;
};

/**
 * creates statement report data structures and saves them to redux
 * @param {Object[] || string} pendingMoneyTransactionsForPortfolios - List of transactions or empty string
 * @param {Object[]} statementTransactionsForPortfolios - List of transactions
 * @param {Object} startingBalance - starting balance object
 * @param {string} startDate - time span end date
 * @param {string} endDate - time span end date
 * @param {Object} statementBalance - statement balance object
 * @param {function} dispatch - react-redux dispatch method.
 * @returns {Object[]} - sorted array
 */
export const createStatementSummaryViewData = (pendingMoneyTransactionsForPortfolios,
  statementTransactionsForPortfolios, startingBalance, startDate, endDate, statementBalance, dispatch) => {
  const openTransactions = calculateOpenTransactions(pendingMoneyTransactionsForPortfolios);
  const statementTable = calculateStatementSummaryAndBalances(statementTransactionsForPortfolios, startingBalance?.confirmedBalance || 0, startDate, endDate);
  const statementSummaryInfo = createStatementSummaryInfo(statementTable, endDate);
  const finalBalance = additionalTotalBalanceFromEndDateTransactions(statementTransactionsForPortfolios, endDate, statementBalance);

  const summaryTableData = {
    summaryDate: statementSummaryInfo.statementBalanceArray?.description,
    totalBalance: finalBalance?.totalBalance || 0,
    depositsTotal: statementSummaryInfo?.statementSummaryArray?.depositsSum || 0,
    withdrawsTotal: statementSummaryInfo?.statementSummaryArray?.withdrawsSum || 0,
    comparedBalance: finalBalance?.pendingWithdraws || 0,
    incomeEstimate: finalBalance?.pendingDeposits || 0,
  };
  dispatch(setStatementTableData(statementTable));
  dispatch(setStatementSummaryData(summaryTableData));
  dispatch(setStatementOpenTransactionData(openTransactions));
};

export function checkIfFeesAndTaxColumnsAreEmpty(viewArray) {
  const result = {
    showSubscriptionFee: false,
    showFee: false,
    showTax: false,
  };

  viewArray.some((value) => {
    if (value.type === DATA) {
      if (value.data.subscriptionFee > 0) {
        result.showSubscriptionFee = true;
      }
      if (value.data.fee > 0) {
        result.showFee = true;
      }
      if (value.data.tax > 0) {
        result.showTax = true;
      }
    }
    // Break loop if all columns are shown.
    return result.showSubscriptionFee && result.showFee && result.showTax;
  });

  return result;
}

/**
 * Calculates summary for transactions report.
 * @param {Object[]} viewArray - Transaction table data
 * @returns {Object[]} - Summary data
 */
export function getTransactionsSummary(viewArray) {
  const transactionTypeSums = [];

  // Calculate sums
  viewArray.forEach((item) => {
    if (item.type === DATA) {
      let typeSum = transactionTypeSums.find((sumItem) => sumItem.type === item.data.type.toUpperCase());
      if (!typeSum) {
        typeSum = {
          type: item.data.type.toUpperCase(),
          subscriptionFeeSum: 0,
          feeSum: 0,
          taxSum: 0,
          valueSum: 0,
        };
        transactionTypeSums.push(typeSum);
      }

      typeSum.subscriptionFeeSum += item.data.subscriptionFee;
      typeSum.feeSum += item.data.fee;
      typeSum.taxSum += item.data.tax;
      typeSum.valueSum += item.data.value;
    }
  });

  if (transactionTypeSums.length === 0) {
    return [];
  }

  const result = transactionTypeSums.map((sumItem) => ({
    type: 'transactionSummary',
    data: {
      transactionType: `TRANSACTION_TYPENAMES.${sumItem.type.toUpperCase()}`,
      subscriptionFee: sumItem.subscriptionFeeSum,
      fee: sumItem.feeSum,
      tax: sumItem.taxSum,
      value: sumItem.valueSum,
    },
  }));
  return result;
}

/**
 * Calculates summary for the profits report.
 * @param {Object[]} viewArray - Profits table data
 * @returns {Object[]} - Summary data
 */
export function getProfitsSummary(viewArray) {
  if (viewArray.length === 0) {
    return [];
  }

  const portfolioSummary = {
    purchaseValue: 0,
    value: 0,
    valueChange: 0,
    returnPercentage: 0,
  };
  let totalProfits = 0;
  let totalLosses = 0;

  // Calculate sums
  viewArray.forEach((item) => {
    if (item.type === DATA) {
      if (item.data.valueChange > 0) {
        totalProfits += item.data.valueChange;
      } else {
        totalLosses += item.data.valueChange;
      }
      portfolioSummary.purchaseValue += item.data.purchaseValue;
      portfolioSummary.value += item.data.value;
    }
  });

  portfolioSummary.valueChange = totalProfits + totalLosses;
  portfolioSummary.returnPercentage = portfolioSummary.valueChange / portfolioSummary.purchaseValue;

  return [
    { header: 'WINS_TOGETHER', valueChange: totalProfits },
    { header: 'LOSSES_TOGETHER', valueChange: totalLosses },
    {
      header: 'WEB_REPORTS.PORTFOLIO_TOTAL',
      valueChange: portfolioSummary.valueChange,
      purchaseValue: portfolioSummary.purchaseValue,
      value: portfolioSummary.value,
      returnPercentage: portfolioSummary.returnPercentage,
    },
  ];
}

/**
 * Calculates summary for the other profits.
 * @param {Object[]} viewArray - Other profits table data
 * @returns {Object} - Summary data
 */
export function getOtherProfitsSummary(viewArray) {
  const transactionTypeSums = [];

  viewArray.forEach((item) => {
    if (item.type === DATA) {
      let typeSum = transactionTypeSums.find((sumItem) => sumItem.type === item.data.type.toUpperCase());
      if (!typeSum) {
        typeSum = {
          type: item.data.type.toUpperCase(),
          value: 0,
          tax: 0,
        };
        transactionTypeSums.push(typeSum);
      }
      typeSum.value += item.data.value;
      typeSum.tax += item.data.tax;
    }
  });

  if (transactionTypeSums.length === 0) {
    return [];
  }

  const otherProfitsTotal = { value: 0, tax: 0 };

  const result = transactionTypeSums.map((sumItem) => {
    otherProfitsTotal.value += sumItem.value;
    otherProfitsTotal.tax += sumItem.tax;
    return {
      header: `TRANSACTION_TYPENAMES.${sumItem.type.toUpperCase()}`,
      value: sumItem.value,
      tax: sumItem.tax,
    };
  });

  return [...result, {
    header: 'WEB_REPORTS.PORTFOLIO_TOTAL',
    value: otherProfitsTotal.value,
    tax: otherProfitsTotal.tax,
  }];
}
