/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

/**
 * Renders login route. If user is already authenticated, redirects them to front page.
 */
function LoginRoute({ children, ...rest }) {
  const token = useSelector((state) => state.auth.token);

  return (
    <Route
      {...rest}
      render={({ location }) => {
        // Prevent automatic redirect when returning from Signicat.
        const isWebServiceTupasReturn = location.pathname.toLowerCase() === '/tupasloginelitereturn';
        return (token && !isWebServiceTupasReturn ? (
          <Redirect to={{ pathname: '/', state: { from: location } }} />
        ) : (
          children
        ));
      }}
    />
  );
}

LoginRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LoginRoute;
