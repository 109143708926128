/* eslint-disable dot-notation */
import axios from 'axios';
import { setupCache } from 'axios-cache-adapter';
import { getAuthData, clearAuthData, clearCustomerId } from '../app/sessionStorage';

// Setup axios cache.
const cache = setupCache({
  maxAge: 15 * 60 * 1000,
  exclude: { query: false },
  invalidate: async (cfg, req) => {
    const method = req.method.toLowerCase();
    if (method !== 'get') {
      await cfg.store.clear();
    }
  },
});

const axiosInstance = axios.create({
  // Set backend base URL which is defined in environment variable.
  baseURL: process.env.REACT_APP_API,
  withCredentials: true,
  adapter: cache.adapter,
});
export default axiosInstance;

/**
 * Setups Axios interceptors.
 * @param {Object} store - Redux store.
 */
export function setupInterceptors(store) {
  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401) {
        delete axiosInstance.defaults.headers.common['Authorization'];
        clearAuthData();
        clearCustomerId();
        store.dispatch({ type: 'auth/userUnauthenticated' });
      } else if (error.response.status === 500) {
        store.dispatch({ type: 'error/serverError' });
      } else if (error.response.status === 400) {
        const urlParts = error.request?.responseURL?.split('/') || [];
        const isTokenUrl = urlParts.length > 0 && urlParts[urlParts.length - 1] === 'token';
        if (!isTokenUrl) {
          store.dispatch({ type: 'error/badRequestError' });
        }
      }
      return Promise.reject(error);
    },
  );
}

/**
 * Initializes auth data in Redux store and Axios.
 * @param {Object} store - Redux store.
 */
export function initAuthData(store) {
  const data = getAuthData();
  if (data?.token) {
    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${data.token}`;
    store.dispatch({ type: 'auth/userAuthenticated', payload: data });
  }
}
