import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { format } from 'date-fns';
import i18next from 'i18next';
import axios from '../api/axiosSetup';
import { getPreviousQuarter } from '../lib/commonFunctions';
import { groupedBy } from '../lib/portfolioGroupingFunctions';
import { localeMap } from '../lib/constants';

/* move all file downloads to same place to make management easier - otherwise there would be
very similar file downloads in at least 4 different reducers */

export const fetchReportingAddress = createAsyncThunk(
  'fileDownloads/fetchReportingAddress',
  async () => {
    const response = await axios.get('api/common/reportingaddress');
    return response.data;
  },
);

// download accountant report
export const fetchAccountantReport = createAsyncThunk('fileDownloads/fetchAccountantReport', async ({
  portfolioId, transactionTypes, startDate, endDate, customerName,
}, thunkAPI) => {
  const { customerId } = thunkAPI.getState().auth;
  const transactionParams = new URLSearchParams();
  if (transactionTypes && transactionTypes.length > 0) {
    transactionTypes.forEach((type) => transactionParams.append('transactionTypes', type));
  }
  const fetchURL = 'api/transactions/portfolioExcel'
    + `?customerName=${customerName.replace(' ', '+')}`
    + `&endDate=${new Date(endDate).toISOString()}`
    + `&portfolioIds=${portfolioId}`
    + `&startDate=${new Date(startDate).toISOString()}`
    + `${customerId ? `&customerId=${customerId}` : ''}`;
  // allow user to download file again at will, not once in every 15 minutes
  const response = await axios.get(fetchURL, {
    responseType: 'arraybuffer',
    transactionParams,
    headers: {
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    cache: {
      maxAge: 300,
      exclude: { query: false },
    },
  });
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  const fileName = `Koontiraportti_${portfolioId}_${format(new Date(), 'ddMMyyyy')}_EUR.xlsx`;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
});

export const fetchReportAttachment = createAsyncThunk('fileDownloads/fetchReportAttachment', async ({ reportId, fileName }) => {
  // allow user to download file again at will, not once in every 15 minutes
  const response = await axios.get(`api/report/${reportId}/content/`, {
    responseType: 'arraybuffer',
    cache: {
      maxAge: 300,
      exclude: { query: false },
    },
  });
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${fileName}.pdf`);
  document.body.appendChild(link);
  link.click();
});

export const fetchAnnouncementAttachment = createAsyncThunk('fileDownloads/fetchAnnouncementAttachment', async ({ attachmentId, fileName }) => {
  // allow user to download file again at will, not once in every 15 minutes
  const response = await axios.get(`api/customerservice/getattachment/${attachmentId}`, {
    responseType: 'arraybuffer',
    cache: {
      maxAge: 300,
      exclude: { query: false },
    },
  });
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
});

export const fetchNotesAttachment = createAsyncThunk('fileDownloads/fetchNotesAttachment', async ({ membershipId, noteId, fileName }) => {
  // allow user to download file again at will, not once in every 15 minutes
  const response = await axios.get(`/api/notes/getNoteFile/${membershipId}/${noteId}`, {
    responseType: 'arraybuffer',
    cache: {
      maxAge: 300,
      exclude: { query: false },
    },
  });
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
});

export const fetchRuleFile = createAsyncThunk('fileDownloads/fetchRuleFile', async ({ membershipId, fileGuid, fileName }) => {
  // allow user to download file again at will, not once in every 15 minutes
  const response = await axios.get(`/api/notes/getRuleFile/${membershipId}/${fileGuid}`, {
    responseType: 'arraybuffer',
    cache: {
      maxAge: 300,
      exclude: { query: false },
    },
  });
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
});

export const fetchMonthlyReport = createAsyncThunk('fileDownloads/fetchMonthlyReport', async ({ instrumentId, name }) => {
  // allow user to download file again at will, not once in every 15 minutes
  const response = await axios.get(`/api/funds/monthlyreport/${instrumentId}`, {
    responseType: 'arraybuffer',
    cache: {
      maxAge: 300,
      exclude: { query: false },
    },
  });

  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  const fileName = `Kuukausiraportti_${name.replace(/ /g, '')}_${format(new Date(), 'ddMMyyyy')}.pdf`;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
});

export const fetchQuarterlyReport = createAsyncThunk('fileDownloads/fetchQuarterlyReport', async ({ instrumentId, name }) => {
  // allow user to download file again at will, not once in every 15 minutes
  const response = await axios.get(`/api/funds/quarterlyReport/${instrumentId}`, {
    responseType: 'arraybuffer',
    cache: {
      maxAge: 300,
      exclude: { query: false },
    },
  });

  const previousQuarterDate = getPreviousQuarter();
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  const fileName = `Kvartaaliraportti_${name.replace(/ /g, '')}_${previousQuarterDate}.pdf`;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
});

// download accountant report
export const fetchFundDocumentFile = createAsyncThunk('fileDownloads/fetchFundDocumentFile', async ({ documentId, documentName, fundName }) => {
  // allow user to download file again at will, not once in every 15 minutes
  const response = await axios.get(`api/funds/fundDocument/${documentId}`, {
    responseType: 'arraybuffer',
    cache: {
      maxAge: 300,
      exclude: { query: false },
    },
  });
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  const trimmedDocumentName = documentName.replace(/ /g, '');
  const fileName = fundName.replace(/ /g, '') + (trimmedDocumentName !== 'KeyInvestorInformationDocument(KIID)' ? trimmedDocumentName : '_KID');
  link.setAttribute('download', `${fileName}.pdf`);
  document.body.appendChild(link);
  link.click();
});

export const fetchPortfolioReportPdf = createAsyncThunk('fileDownloads/fetchPortfolioReportPdf', async ({
  externalPortfolioId, source, date, purchases, groupingSelections,
}, thunkAPI) => {
  const {
    groupedbyAssetType, groupedbyClass, groupedByInstrumentType, groupedByCurrency,
  } = groupedBy(groupingSelections);
  const { customerId } = thunkAPI.getState().auth;
  const response = await axios.get('/api/portfolios/fetchReport', {
    responseType: 'arraybuffer',
    withCredentials: false,
    cache: {
      maxAge: 300,
      exclude: { query: false },
    },
    params: {
      externalPortfolioId,
      date: format(new Date(date), 'yyyy-M-d'),
      getPurchases: purchases,
      lang: localeMap[i18next.language],
      groupedbyAssetType,
      groupedbyClass,
      groupedByInstrumentType,
      groupedByCurrency,
      source,
      customerId,
    },
  });
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  const fileName = `salkkuraportti_${externalPortfolioId}_${format(new Date(date), 'yyyMMdd')}`;
  link.setAttribute('download', `${fileName}.pdf`);
  document.body.appendChild(link);
  link.click();
});

export const fetchHoldingsExcel = createAsyncThunk('fileDownloads/fetchHoldingsExcel',
  async ({ urlParams, portfolioId }, thunkAPI) => {
    // allow user to download file again at will, not once in every 15 minutes
    const { customerId } = thunkAPI.getState().auth;
    const response = await axios.get(`/api/portfolios/${portfolioId}/${urlParams}`, {
      responseType: 'arraybuffer',
      params: { lang: i18next.language, customerId },
      headers: {
        'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
      cache: {
        maxAge: 300,
        exclude: { query: false },
      },
    });
    const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/octet-stream' }));
    const link = document.createElement('a');
    link.href = url;
    const fileName = `Salkkuraportti_${portfolioId}_${format(new Date(), 'ddMMyyyy')}_EUR`;
    link.setAttribute('download', `${fileName}.xlsx`);
    document.body.appendChild(link);
    link.click();
  });

export const fetchTransactionReport = createAsyncThunk('fileDownloads/fetchTransactionReport', async ({
  portfolioIds, startDate, endDate, customerName,
}) => {
  const fetchURL = 'api/transactions/transactionsExcel';
  const params = {
    portfolioIds,
    startDate: new Date(startDate).toISOString(),
    endDate: new Date(endDate).toISOString(),
    customerName,
  };
  // allow user to download file again at will, not once in every 15 minutes
  const response = await axios.get(fetchURL, {
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    params,
    cache: {
      maxAge: 300,
      exclude: { query: false },
    },
  });
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  const fileName = `Tapahtumaraportti_${portfolioIds}_${format(new Date(), 'ddMMyyyy')}_EUR.xlsx`;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
});

export const fetchStatementReportExcel = createAsyncThunk('fileDownloads/fetchStatementReportExcel',
  async ({ urlParams, portfolioId }) => {
    // allow user to download file again at will, not once in every 15 minutes
    const response = await axios.get('api/transactions/statementTransactionsExcel', {
      responseType: 'arraybuffer',
      params: { ...urlParams, language: i18next.language },
      headers: {
        'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
      cache: {
        maxAge: 300,
        exclude: { query: false },
      },
    });
    const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/octet-stream' }));
    const link = document.createElement('a');
    link.href = url;
    const fileName = `Tiliote_${portfolioId}_${format(new Date(), 'ddMMyyyy')}_EUR`;
    link.setAttribute('download', `${fileName}.xlsx`);
    document.body.appendChild(link);
    link.click();
  });

export const fetchProfitsReport = createAsyncThunk('fileDownloads/fetchProfitsReport', async ({
  portfolioIds, startDate, endDate, customerName,
}, thunkAPI) => {
  const { customerId } = thunkAPI.getState().auth;
  const fetchURL = `api/transactions/profitsExcel${customerId ? `?customerId=${customerId}` : ''}`;
  const params = {
    portfolioIds,
    startDate: new Date(startDate).toISOString(),
    endDate: new Date(endDate).toISOString(),
    customerName,
    transactionTypes: ['Redemption', 'Sell'],
    getBuyCostInfo: true,
    getFifoBuyItems: true,
  };
  // allow user to download file again at will, not once in every 15 minutes
  const response = await axios.get(fetchURL, {
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    params,
    cache: {
      maxAge: 300,
      exclude: { query: false },
    },
  });
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  const fileName = `Tuottoraportti_${portfolioIds}_${format(new Date(), 'ddMMyyyy')}_EUR.xlsx`;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
});

const initialState = {
  fetchingAccountantReport: false,
  fetchingAnnouncementAttachment: false,
  fetchingReportAttachment: false,
  fetchingMonthlyReport: false,
  fetchingQuarterlyReport: false,
  fetchingFundDocumentFile: false,
  downloadingFundGuid: '',
  downloadingFundDocumentId: '',
  fetchingReportingAddress: false,
  reportingAddress: null,
  fetchingPortfolioReportPdf: false,
  fetchingHoldingsExcel: false,
  fetchingTransactionReport: false,
  fetchingStatementReportExcel: false,
  fetchingProfitsReport: false,
};

export const fileDownloadsSlice = createSlice({
  name: 'fileDownloads',
  initialState,
  reducers: {
    setDownloadingFundGuid: (state, { payload }) => {
      state.downloadingFundGuid = payload.guid;
    },
    setDownloadingFundDocumentId: (state, { payload }) => {
      state.downloadingFundDocumentId = payload.documentId;
    },
  },
  extraReducers: {
    [fetchAccountantReport.pending]: (state) => {
      state.fetchingAccountantReport = true;
    },
    [fetchAccountantReport.fulfilled]: (state) => {
      state.fetchingAccountantReport = false;
    },
    [fetchAccountantReport.rejected]: (state) => {
      state.fetchingAccountantReport = false;
    },
    [fetchAnnouncementAttachment.pending]: (state) => {
      state.fetchingAnnouncementAttachment = true;
    },
    [fetchAnnouncementAttachment.fulfilled]: (state) => {
      state.fetchingAnnouncementAttachment = false;
    },
    [fetchAnnouncementAttachment.rejected]: (state) => {
      state.fetchingAnnouncementAttachment = false;
    },
    [fetchReportAttachment.pending]: (state) => {
      state.fetchingReportAttachment = true;
    },
    [fetchReportAttachment.fulfilled]: (state) => {
      state.fetchingReportAttachment = false;
    },
    [fetchReportAttachment.rejected]: (state) => {
      state.fetchingReportAttachment = false;
    },
    [fetchMonthlyReport.pending]: (state) => {
      state.fetchingMonthlyReport = true;
    },
    [fetchMonthlyReport.fulfilled]: (state) => {
      state.fetchingMonthlyReport = false;
      state.downloadingFundGuid = '';
    },
    [fetchMonthlyReport.rejected]: (state) => {
      state.fetchingMonthlyReport = false;
      state.downloadingFundGuid = '';
    },
    [fetchQuarterlyReport.pending]: (state) => {
      state.fetchingQuarterlyReport = true;
    },
    [fetchQuarterlyReport.fulfilled]: (state) => {
      state.fetchingQuarterlyReport = false;
      state.downloadingFundGuid = '';
    },
    [fetchQuarterlyReport.rejected]: (state) => {
      state.fetchingQuarterlyReport = false;
      state.downloadingFundGuid = '';
    },
    [fetchFundDocumentFile.pending]: (state) => {
      state.fetchingFundDocumentFile = true;
    },
    [fetchFundDocumentFile.fulfilled]: (state) => {
      state.fetchingFundDocumentFile = false;
    },
    [fetchFundDocumentFile.rejected]: (state) => {
      state.fetchingFundDocumentFile = false;
    },
    [fetchReportingAddress.pending]: (state) => {
      state.fetchingReportingAddress = true;
    },
    [fetchReportingAddress.fulfilled]: (state, { payload }) => {
      state.fetchingReportingAddress = false;
      state.reportingAddress = payload;
    },
    [fetchReportingAddress.rejected]: (state) => {
      state.fetchingReportingAddress = false;
    },
    [fetchPortfolioReportPdf.pending]: (state) => {
      state.fetchingPortfolioReportPdf = true;
    },
    [fetchPortfolioReportPdf.fulfilled]: (state) => {
      state.fetchingPortfolioReportPdf = false;
    },
    [fetchPortfolioReportPdf.rejected]: (state) => {
      state.fetchingPortfolioReportPdf = false;
    },
    [fetchHoldingsExcel.pending]: (state) => {
      state.fetchingHoldingsExcel = true;
    },
    [fetchHoldingsExcel.fulfilled]: (state) => {
      state.fetchingHoldingsExcel = false;
    },
    [fetchHoldingsExcel.rejected]: (state) => {
      state.fetchingHoldingsExcel = false;
    },
    [fetchTransactionReport.pending]: (state) => {
      state.fetchingTransactionReport = true;
    },
    [fetchTransactionReport.fulfilled]: (state) => {
      state.fetchingTransactionReport = false;
    },
    [fetchTransactionReport.rejected]: (state) => {
      state.fetchingTransactionReport = false;
    },
    [fetchStatementReportExcel.pending]: (state) => {
      state.fetchingStatementReportExcel = true;
    },
    [fetchStatementReportExcel.fulfilled]: (state) => {
      state.fetchingStatementReportExcel = false;
    },
    [fetchStatementReportExcel.rejected]: (state) => {
      state.fetchingStatementReportExcel = false;
    },
    [fetchProfitsReport.pending]: (state) => {
      state.fetchingProfitsReport = true;
    },
    [fetchProfitsReport.fulfilled]: (state) => {
      state.fetchingProfitsReport = false;
    },
    [fetchProfitsReport.rejected]: (state) => {
      state.fetchingProfitsReport = false;
    },
  },
});

export const {
  setDownloadingFundGuid,
  setDownloadingFundDocumentId,
} = fileDownloadsSlice.actions;

export default fileDownloadsSlice.reducer;
