import { createTheme } from '@mui/material/styles';
import { fiFI } from '@mui/material/locale';

const BLUE = '#225F92';
// const GREY = '#4D5758'; // 'rgba(77, 87, 88, 1)'
const OCEAN = '#00BED5';
const WHITE = '#FFFFFF';
const GREEN = '#00A19B';
// const LILAC = '#655DC6';
// const YELLOW = '#FFC27A';
const BLACK = '#000000';
// const LIGHT_GREEN = '#79DDA7';
const RED = '#FF5C35';
const BORDER_GREY = '#E0E0E0';
const GREY_MEDIUM = 'rgba(77, 87, 88, 0.1)';
const GREY_LIGHT = 'rgba(77, 87, 88, 0.05)';
const GREY_DARK = 'rgba(77, 87, 88, 0.54)';
const GREY_BASIC = '#4D5758';
const EVLI_BLUE = '#0F2D46';
const EVLI_TURQUOISE = '#45EBD8';
const EVLI_GREY = 'rgba(235, 235, 235, 1)';

const SPACING = 8;

export const colorMap = {
  BLUE,
  GREY: '#4D5758', // 'rgba(77, 87, 88, 1)'
  OCEAN,
  WHITE: '#FFFFFF',
  GREEN: '#00A19B',
  LILAC: '#655DC6',
  YELLOW: '#FFC27A',
  BLACK: '#000000',
  LIGHT_GREEN: '#79DDA7',
  RED: '#FF5C35',
  BORDER_GREY: '#E0E0E0',
  GREY_MEDIUM: 'rgba(77, 87, 88, 0.1)',
  GREY_LIGHT: 'rgba(77, 87, 88, 0.05)',
  GREY_DARK: 'rgba(77, 87, 88, 0.54)',
  GREY_BASIC: '#4D5758',
  EVLI_BLUE,
  EVLI_TURQUOISE,
};

// eslint-disable-next-line operator-linebreak
export const chartColors = [
  colorMap.EVLI_TURQUOISE,
  colorMap.GREEN,
  colorMap.EVLI_BLUE,
  colorMap.LILAC,
  colorMap.LIGHT_GREEN,
  colorMap.GREY,
];

const defaultTheme = createTheme();

const theme = createTheme(
  {
    palette: {
      background: {
        default: EVLI_GREY,
        contrastText: GREY_DARK,
      },
      primary: {
        main: EVLI_BLUE,
        contrastText: WHITE,
      },
      secondary: {
        main: EVLI_TURQUOISE,
        contrastText: WHITE,
      },
      action: {
        main: EVLI_TURQUOISE,
        disabled: GREY_MEDIUM,
      },
      white: {
        main: WHITE,
      },
      black: {
        main: BLACK,
      },
      error: {
        main: RED,
      },
      green: {
        main: GREEN,
        contrastText: WHITE,
      },
    },
    typography: {
      fontFamily: ['Open Sans', 'sans-serif'].join(','),
      h1: {
        position: 'relative',
        fontWeight: 300,
        fontSize: '3.375rem',
        lineHeight: '4.188rem',
        letterSpacing: '-0.031rem',
        alignItems: 'center',
        paddingBottom: '1.25rem',
        '&:after': {
          content: '""',
          width: '80px',
          height: '5px',
          background: EVLI_TURQUOISE,
          position: 'absolute',
          left: '0%',
          right: '69.7%',
          top: '94.12%',
          bottom: '0%',
        },
        [defaultTheme.breakpoints.down('sm')]: {
          fontSize: '2.5rem',
        },
      },
      h2: {
        position: 'relative',
        fontWeight: 400,
        fontSize: '2.25rem',
        lineHeight: '2.563rem',
        letterSpacing: '0.044rem',
        alignItems: 'center',
        paddingBottom: '0.625rem',
        margin: '5px 0',
        '&:after': {
          content: '""',
          width: '80px',
          height: '5px',
          background: EVLI_TURQUOISE,
          position: 'absolute',
          left: '0%',
          right: '69.7%',
          top: '94.12%',
          bottom: '0%',
        },
      },
      h3: {
        position: 'relative',
        fontWeight: 300,
        fontSize: '1.875rem',
        lineHeight: '2.563rem',
        alignItems: 'center',
        paddingBottom: '0.625rem',
        margin: '5px 0',
        '&:after': {
          content: '""',
          width: '80px',
          height: '5px',
          background: EVLI_TURQUOISE,
          position: 'absolute',
          left: '0%',
          right: '69.7%',
          top: '94.12%',
          bottom: '0%',
        },
      },
      h4: {
        fontWeight: 300,
        fontSize: '1.5rem',
        lineHeight: '140%',
        letterSpacing: '-0.025rem',
        alignItems: 'center',
      },
      h5: {
        fontWeight: 600,
        fontSize: '1.25rem',
        lineHeight: '130%',
        alignItems: 'center',
      },
      h6: {
        fontWeight: 400,
        fontSize: '1.375rem',
        lineHeight: '140%',
        alignItems: 'center',
      },
      subtitle1: {
        fontWeight: 'bold',
        fontSize: '1.125rem',
        lineHeight: '135%',
        letterSpacing: '0.009rem',
        alignItems: 'center',
      },
      subtitle2: {
        fontWeight: 600,
        fontSize: '1rem',
        lineHeight: '135%',
        letterSpacing: '0.006rem',
        alignItems: 'center',
      },
      body1: {
        fontWeight: 'normal',
        marginTop: SPACING,
        marginBottom: SPACING,
        fontSize: '1rem',
        letterSpacing: '0.009rem',
        alignItems: 'center',
      },
      body2: {
        fontWeight: 'normal',
        marginTop: SPACING,
        marginBottom: SPACING,
        fontSize: '0.875rem',
        letterSpacing: '0.009rem',
        alignItems: 'center',
      },
      caption: {
        fontWeight: 'normal',
        fontSize: '0.75rem',
        lineHeight: '135%',
        letterSpacing: '0.025rem',
        alignItems: 'center',
      },
      overline: {
        fontWeight: 'normal',
        fontSize: '0.875rem',
        lineHeight: '135%',
        letterSpacing: '0.063rem',
        textTransform: 'uppercase',
        alignItems: 'center',
      },
      navi: {
        fontSize: '0.75rem',
        fontWeight: 600,
        lineHeight: '1rem',
        textTransform: 'uppercase',
        color: BLACK,
      },
    },
    spacing: SPACING,
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: 0,
            fontWeight: 600,
            fontSize: '0.75rem',
            letterSpacing: '0.5px',
            minHeight: 30,
            minWidth: 55,
            paddingLeft: 22,
            paddingRight: 22,
            '&.naviDropdown': {
              padding: SPACING * 3,
              paddingTop: '12px',
              paddingBottom: SPACING * 2,
              width: '100%',
              justifyContent: 'space-between',
            },
            '&.white': {
              color: WHITE,
            },
            '&.underline': {
              textDecoration: 'underline',
            },
          },
          sizeSmall: {
            paddingLeft: 14,
            paddingRight: 14,
          },
          containedPrimary: {
            '& .MuiButton-endIcon': {
              color: WHITE,
            },
            ':hover': {
              backgroundColor: EVLI_TURQUOISE,
              '& .MuiButton-endIcon, .oceanColorIcon': {
                color: WHITE,
              },
            },
            ':disabled': {
              backgroundColor: GREY_MEDIUM,
              border: `1px solid ${GREY_DARK}`,
              color: GREY_DARK,
            },
          },
          outlinedSecondary: {
            backgroundColor: WHITE,
            borderColor: BLACK,
            color: BLACK,
            '& .MuiButton-endIcon': {
              color: EVLI_BLUE,
            },
            ':hover': {
              borderColor: EVLI_TURQUOISE,
              color: EVLI_TURQUOISE,
              backgroundColor: WHITE,
              '& .MuiButton-endIcon': {
                color: EVLI_TURQUOISE,
              },
            },
            ':disabled': {
              backgroundColor: GREY_MEDIUM,
              borderColor: GREY_DARK,
              color: GREY_DARK,
            },
          },
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          justifyContent: 'flex-start',
        },
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            padding: SPACING * 3,
          },
        },
      },
      MuiButtonBase: {
        styleOverrides: {
          root: {
            borderRadius: 0,
            '&.navigation': {
              padding: `${SPACING} ${SPACING * 2}`,
            },
            '&.MuiPaginationItem-root': {
              borderRadius: 0,
            },
            '&.MuiAccordionSummary-root.Mui-expanded': {
              borderTop: `1px solid ${BORDER_GREY}`,
              '&>.MuiAccordionSummary-content': {
                marginRight: defaultTheme.spacing(2),
              },
            },
            '&.MuiButton-root': {
              '&.Mui-disabled': {
                color: GREY_DARK,
              },
            },
          },
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            '&.oceanColorIcon': {
              color: EVLI_TURQUOISE,
            },
            '&.blueColorIcon': {
              color: EVLI_BLUE,
            },
            '&.greenColorIcon': {
              color: colorMap.GREEN,
            },
            '&.redColorIcon': {
              color: colorMap.RED,
            },
          },
        },
      },
      MuiContainer: {
        styleOverrides: {
          root: {
            paddingTop: defaultTheme.spacing(8),
            paddingBottom: defaultTheme.spacing(3),
            [defaultTheme.breakpoints.down('sm')]: {
              paddingTop: defaultTheme.spacing(4),
            },
          },
        },
      },
      MuiGrid: {
        styleOverrides: {
          root: {
            margin: 0,
            '&#headerContainer': {
              '&:first-of-type': {
                paddingLeft: 0,
              },
            },
            '& > fieldset': {
              border: 0,
              padding: 0,
              margin: 0,
              width: '100%',
              ':disabled': {
                color: GREY_DARK,
              },
            },
          },
          container: {
            width: '100%',
          },
        },
      },
      MuiFormControl: {
        styleOverrides: {
          root: {
            marginBottom: SPACING,
            marginTop: SPACING,
            '&.radioGroup': {
              marginLeft: SPACING,
            },
            '&.checkbox': {
              marginLeft: SPACING,
            },
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          asterisk: {
            color: EVLI_TURQUOISE,
            '&$error': {
              color: 'red',
            },
          },
          root: {
            marginBottom: 0,
            '&.Mui-focused': {
              color: GREY_DARK,
            },
          },
        },
      },
      MuiFilledInput: {
        styleOverrides: {
          root: {
            padding: 0,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',

            position: 'static',
            left: '0px',
            top: '0px',

            background: WHITE,

            border: `1px solid ${BORDER_GREY}`,
            boxSizing: 'border-box',
            borderRadius: '0px',

            flex: 'none',
            order: 0,
            alignSelf: 'stretch',
            flexGrow: 0,
            margin: '0px 0px',

            color: GREY_BASIC,
            '&>textarea': {
              padding: '12px',
              width: 'calc(100% - 24px)',
            },
            '&:before': {
              borderBottom: '1px solid black',
              transition:
                'border-bottom 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            },
            '&.Mui-focused': {
              backgroundColor: GREY_LIGHT,
              border: `solid 1px ${GREY_DARK}`,
              borderBottom: `solid 3px ${EVLI_BLUE}`,
              transition:
                'border-bottom 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            },
            '&.selectedFund': {
              '&:before': {
                borderBottom: `1px solid ${GREY_DARK}`,
              },
            },
            '&>.MuiInputAdornment-positionEnd': {
              position: 'absolute',
              right: '16px',
              top: '30px',
            },
          },
          input: {
            padding: '16px',
            paddingLeft: '12px',
            paddingRight: '12px',
            width: 'calc(100% - 24px)',
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          select: {
            width: '100%',
            color: GREY_BASIC,
            '&:focus': {
              backgroundColor: 'transparent',
            },
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            borderRadius: 0,
            '&.cardBody': {
              '& > h6': {
                paddingTop: SPACING,
                paddingBottom: SPACING,
              },
              paddingTop: SPACING * 3,
            },
            '&.MuiAccordion-root:before': {
              backgroundColor: BORDER_GREY,
              transition: 'none',
            },
            '&.MuiAccordion-root.Mui-expanded': {
              margin: 0,
            },
            '&.eabLogo > img': {
              width: '7.813rem',
              height: '2.813rem',
            },
            '&.naviDropdown': {
              position: 'absolute',
              width: '18.125rem',
            },
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            [defaultTheme.breakpoints.down('sm')]: {
              padding: '2px',
              paddingTop: defaultTheme.spacing(1),
              paddingBottom: defaultTheme.spacing(1),
            },
          },
          head: {
            fontWeight: 600,
          },
          body: {
            fontWeight: 'normal',
            marginTop: SPACING,
            marginBottom: SPACING,
            fontSize: '1rem',
            letterSpacing: '0.009rem',
            alignItems: 'center',
          },
          sizeMedium: {
            paddingTop: '1.375rem',
            paddingBottom: '1.375rem',
          },
        },
      },
      MuiTableRow: {
        styleOverrides: {
          root: {
            '&>td': {
              paddingTop: '14px',
              paddingBottom: '14px',
            },
            '&.evenRow': {
              backgroundColor: GREY_LIGHT,
            },
            '&.portfolioReportTableHeader': {
              backgroundColor: 'rgba(35, 95, 146, 0.15)',
              '&>th, >td': {
                border: 0,
                padding: '14px',
                '&:first-of-type': { width: '33%' },
                '&:nth-of-type(2)': { width: '11%' },
                '&:nth-of-type(3)': { width: '11%' },
                '&:nth-of-type(4)': { width: '10%' },
                '&:nth-of-type(5)': { width: '9%' },
                '&:nth-of-type(6)': { width: '10%' },
                '&:nth-of-type(7)': { width: '10%' },
                '&:nth-of-type(8)': { width: '6%' },
              },
              '&.collapseRowContent': {
                '&>th, >td': {
                  paddingTop: 0,
                  paddingBottom: 0,
                },
              },
            },
            // summary row
            '&.tableRowType-summary': {
              '&>th, >td': {
                position: 'relative',
                '&:after': {
                  content: '""',
                  width: '100%',
                  height: '1px',
                  background: colorMap.BORDER_GREY,
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                },
              },
              '&:last-of-type': {
                backgroundColor: 'rgba(34, 95, 146, 0.05)',
                '&>th, >td': {
                  padding: 18,
                  paddingLeft: 14,
                  '&>p': {
                    margin: 0,
                    fontWeight: 600,
                    fontSize: 16,
                  },
                  '&>p:first-of-type': {
                    margin: 0,
                    fontWeight: 700,
                    fontSize: 18,
                  },
                },
              },
            },
            // assetType header and summary
            '&.tableRowType-assetType': {
              backgroundColor: GREY_MEDIUM,
              '&>th, >td': {
                padding: 14,
                position: 'relative',
                '&:after': {
                  content: '""',
                  width: '100%',
                  height: '1px',
                  background: colorMap.BORDER_GREY,
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                },
                '&>p': {
                  margin: 0,
                  textTransform: 'uppercase',
                  fontWeight: 700,
                  fontSize: 18,
                },
              },
            },
            '&.summaryOf-assetType': {
              backgroundColor: 'rgba(77, 87, 88, 0.03)',
              '&>th, >td': {
                '&:first-of-type': {
                  '&>p': {
                    margin: 0,
                    fontWeight: 700,
                    fontSize: 14,
                  },
                },
              },
            },
            // classification header and summary
            '&.tableRowType-classification, &.summaryOf-classification': {
              '&>th, >td': {
                '&:first-of-type': {
                  paddingLeft: 48,
                },
                padding: 14,
                position: 'relative',
                '&>p': {
                  margin: 0,
                  textTransform: 'uppercase',
                },
              },
            },
            '&.tableRowType-classification': {
              backgroundColor: 'rgba(0, 190, 213, 0.03)',
              '&>th, >td': {
                '&:after': {
                  content: '""',
                  width: '100%',
                  height: '1px',
                  background: colorMap.BORDER_GREY,
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                },
                '&>p': {
                  fontWeight: 600,
                  fontSize: 16,
                },
              },
            },
            '&.summaryOf-classification': {
              '&>th, >td': {
                '&:before': {
                  content: '""',
                  width: '100%',
                  height: '1px',
                  background: colorMap.GREY_DARK,
                  position: 'absolute',
                  top: -1,
                  left: 0,
                },
                '&>p': {
                  fontWeight: 400,
                  fontSize: 12,
                },
                '&:first-of-type': {
                  '&:before': {
                    marginLeft: 48,
                  },
                },
              },
            },
            // instrumentType header and summary
            '&.tableRowType-instrumentType, &.tableRowType-currency, &.summaryOf-instrumentType, &.summaryOf-currency': {
              '&>th, >td': {
                '&:first-of-type': {
                  paddingLeft: 72,
                },
                padding: 14,
                position: 'relative',
              },
              '&.indent-level-0': {
                '&>th, >td': {
                  '&:first-of-type': {
                    paddingLeft: 14,
                    '&:before, &:after': {
                      marginLeft: 0,
                    },
                  },
                },
              },
              '&.indent-level-1': {
                '&>th, >td': {
                  '&:first-of-type': {
                    paddingLeft: 28,
                    '&:before, &:after': {
                      marginLeft: 28,
                    },
                  },
                },
              },
              '&.indent-level-3': {
                '&>th, >td': {
                  '&:first-of-type': {
                    paddingLeft: 88,
                    '&:before, &:after': {
                      marginLeft: 88,
                    },
                  },
                },
              },
            },
            '&.tableRowType-instrumentType, &.tableRowType-currency': {
              '&>th, >td': {
                '&:first-of-type': {
                  '&:after': {
                    marginLeft: 72,
                  },
                },
                '&:after': {
                  content: '""',
                  width: '100%',
                  height: '1px',
                  background: colorMap.BORDER_GREY,
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                },
                '&>p': {
                  margin: 0,
                  fontWeight: 700,
                  fontSize: 14,
                },
              },
            },
            '&.summaryOf-instrumentType, &.summaryOf-currency': {
              '&>th, >td': {
                '&:first-of-type': {
                  '&:before': {
                    marginLeft: 72,
                  },
                  '&:after': {
                    marginLeft: 48,
                  },
                },
                '&:before': {
                  content: '""',
                  width: '100%',
                  height: '1px',
                  background: colorMap.GREY_DARK,
                  position: 'absolute',
                  top: -1,
                  left: 0,
                },
              },
            },
            // data row
            '&.tableRowType-data, &.collapseContentRow': {
              '&>th, >td': {
                '&:first-of-type': {
                  paddingLeft: 72,
                  '&:before, &:after': {
                    marginLeft: 72,
                  },
                },
                position: 'relative',
                '&:after': {
                  content: '""',
                  width: '100%',
                  height: '1px',
                  background: colorMap.BORDER_GREY,
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                },
              },
              '&.collapseOpen': {
                '&>th, >td': {
                  '&:after': {
                    height: 0,
                  },
                },
              },
              '&.indent-level-0': {
                '&>th, >td': {
                  '&:first-of-type': {
                    paddingLeft: 14,
                    '&:before, &:after': {
                      marginLeft: 0,
                    },
                  },
                },
              },
              '&.indent-level-1': {
                '&>th, >td': {
                  '&:first-of-type': {
                    paddingLeft: 48,
                    '&:before, &:after': {
                      marginLeft: 48,
                    },
                  },
                },
              },
              '&.indent-level-4': {
                '&>th, >td': {
                  '&:first-of-type': {
                    paddingLeft: 88,
                    '&:before, &:after': {
                      marginLeft: 88,
                    },
                  },
                },
              },
            },
          },
        },
      },
      MuiTable: {
        styleOverrides: {
          root: {
            [defaultTheme.breakpoints.down('sm')]: {
              '&.MuiTable-root>thead>tr>th, .MuiTable-root>tbody>tr>td': {
                fontSize: '0.8rem',
              },
            },
          },
        },
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            backgroundColor: BORDER_GREY,
            marginTop: SPACING * 2,
            marginBottom: SPACING * 2,
            '&.MuiDivider-light': {
              backgroundColor: EVLI_TURQUOISE,
              width: '25%',
              height: '5px',
            },
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            padding: 0,
            borderRadius: 0,
          },
        },
      },
      MuiCardContent: {
        styleOverrides: {
          root: {
            padding: SPACING * 5,
            '&:last-of-type': {
              paddingBottom: SPACING * 5,
            },
            [defaultTheme.breakpoints.down('sm')]: {
              padding: defaultTheme.spacing(2),
            },
            '&.noSidePadding': {
              paddingLeft: 0,
              paddingRight: 0,
            },
          },
        },
      },
      MuiAccordion: {
        styleOverrides: {
          root: {
            padding: 0,
            '&.navigationDropdown': {
              minWidth: '19rem',
              height: '2.5rem',
              border: 0,
              '&.Mui-expanded': {
                minHeight: '2rem',
                height: '2.5rem',
              },
            },
          },
        },
      },
      MuiAccordionSummary: {
        styleOverrides: {
          root: {
            '&.navigationDropdown': {
              minHeight: '2rem',
              height: '2.5rem',
            },
          },
          content: {
            marginRight: 2 * SPACING,
            flexDirection: 'column',
          },
          expandIconWrapper: {
            color: EVLI_BLUE,
          },
        },
      },
      MuiAccordionDetails: {
        styleOverrides: {
          root: {
            paddingLeft: 0,
            paddingRight: 0,
            paddingBottom: defaultTheme.spacing(5),
            backgroundColor: WHITE,
            '&.navigationDropdown': {
              padding: SPACING * 2,
            },
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            '&.pageTitle': {
              padding: 2 * SPACING,
            },
            '&#selectedFund': {
              [defaultTheme.breakpoints.up('lg')]: {
                textAlign: 'right',
              },
            },
            '&.link, &.loadingMessage': {
              color: EVLI_BLUE,
            },
          },
        },
      },
      MuiAppBar: {
        styleOverrides: {
          root: {
            backgroundColor: WHITE,
            color: BLACK,
            '&.mainNavigation': {
              height: 90,
              '& > div': {
                height: 90,
              },
              [defaultTheme.breakpoints.down('md')]: {
                height: 70,
                '& > div': {
                  height: 70,
                },
              },
            },
            '&.subNavigation': {
              backgroundColor: `${WHITE}80`, // white with 0.5 opacity
            },
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          root: {},
          indicator: {
            height: '5px',
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: 'transparent',
            '& > span': {
              width: `calc(100% - ${SPACING * 2 * 2}px)`,
              backgroundColor: EVLI_BLUE,
            },
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            minWidth: 0,
            '&.navigation': {
              padding: `${SPACING} ${SPACING * 2}`,
            },
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            fontWeight: 600,
            textTransform: 'uppercase',
            fontSize: '0.75rem',
            lineHeight: '1.188rem',
            letterSpacing: '0.5px',
            textDecoration: 'underline',
            color: BLACK,
          },
        },
      },
      MuiListItem: {
        styleOverrides: {
          root: {
            padding: 0,
            paddingLeft: SPACING * 2,
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            backgroundColor: WHITE,
            color: 'rgba(0, 0, 0, 0.87)',
            boxShadow:
              '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)',
            fontSize: 14,
          },
        },
      },
      MuiCircularProgress: {
        styleOverrides: {
          indeterminate: {
            animationDuration: '3s',
            '&>svg': {
              '&>circle': {
                animationDuration: '3s',
              },
            },
          },
        },
      },
      MuiCssBaseline: {
        styleOverrides: {
          html: {
            height: '100vh',
            fontSize: 16,
            [defaultTheme.breakpoints.down('sm')]: {
              fontSize: 14,
            },
            [defaultTheme.breakpoints.down('xs')]: {
              fontSize: 12,
            },
          },
          body: {
            margin: 0,
            height: '100%',
            '& > div#root': {
              height: '100%',
            },
          },
          '.forceBlackFont': {
            color: 'black',
          },
          '.forceWhiteFont': {
            color: 'white',
          },
          '.hideAnimationOnHeight': {
            height: 0,
            transition: 'height 300ms',
          },
          '.rotated': {
            transform: 'rotate(180deg)',
          },
          // FOR ACCCESSIBILITY TESTING
          // COMMENT OUT WHEN NOT TESTING!
          /* '*': {
          float: 'none !important',
          position: 'static !important',
        },
        'table, tbody, td, tfoot, th, thead, tr': {
          display: 'block !important',
        }, */
        },
      },
    },
  },
  fiFI,
);

export default theme;
