/* eslint-disable dot-notation */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../api/axiosSetup';
import { setAuthData } from '../app/sessionStorage';

export const fetchToken = createAsyncThunk('auth/fetchToken', async ({ username, password }) => {
  try {
    const params = new URLSearchParams();
    params.append('grant_type', 'password');
    params.append('username', username);
    params.append('password', password);

    // Get auth token using user credentials.
    const response = await axios.post('/token', params);
    const accessToken = response?.data?.access_token;
    if (!accessToken) {
      throw Error('INVALID_CREDENTIALS');
    }

    // Update Axios auth header and save data to session storage.
    axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
    setAuthData(accessToken);

    return { accessToken, username };
  } catch (error) {
    // Use custom error message for invalid username/password.
    if (error?.request?.status === 400) {
      throw Error('INVALID_CREDENTIALS');
    }
    throw error;
  }
});

export const fetchCurrentUser = createAsyncThunk('auth/fetchCurrentUser', async () => {
  const response = await axios.get('api/account/currentUser');
  return response.data;
});

export const logout = createAsyncThunk('auth/logout', async () => {
  const response = await axios.post('api/account/logout');
  return response.data;
});

export const fetchSignicatUrl = createAsyncThunk('auth/fetchSignicatUrl', async ({ returnTag }) => {
  const response = await axios.get(`api/authentication/getUrl/${returnTag}`);
  return response.data;
});

export const fetchTupasUsers = createAsyncThunk('auth/fetchTupasUsers', async ({ signicatCode }) => {
  const response = await axios.post('/api/session/tupasUsers/react', { signicatCode });
  return response.data;
});

export const sendAuthCode = createAsyncThunk('auth/sendAuthCode', async ({
  guid,
  isPersonnelFundUserLogin,
  isEliteUserLogin,
  userId,
  tupasId,
}) => {
  try {
    const response = await axios.post('api/tupaslogin/authcode', {
      guid,
      isPersonnelFundUserLogin,
      isEliteUserLogin,
      userId,
      tupasId,
    });
    return response.data;
  } catch (error) {
    if (error?.request?.status === 400 && error?.request?.response) {
      const json = JSON.parse(error?.request?.response);
      if (json.message === 'login') {
        throw Error('USER_NOT_FOUND');
      }
    }
    throw error;
  }
});

export const sendAuthCodeReact = createAsyncThunk('auth/sendAuthCodeReact', async ({
  guid,
  isPersonnelFundUserLogin,
  isEliteUserLogin,
  signicatCode,
}) => {
  try {
    const response = await axios.post('api/tupaslogin/authcode/react', {
      guid,
      isPersonnelFundUserLogin,
      isEliteUserLogin,
      signicatCode,
    });
    return response.data;
  } catch (error) {
    if (error?.request?.status === 400 && error?.request?.response) {
      const json = JSON.parse(error?.request?.response);
      if (json.message === 'login') {
        throw Error('USER_NOT_FOUND');
      }
    }
    throw error;
  }
});

export const tupasLogin = createAsyncThunk('auth/tupasLogin', async ({ code, guid, username }) => {
  try {
    const params = new URLSearchParams();
    params.append('grant_type', 'tupas');
    params.append('code', code);
    params.append('client_id', guid);

    // Get access token.
    const response = await axios.post('token', params);
    const accessToken = response?.data?.access_token;
    if (!accessToken) {
      throw Error('INVALID_CREDENTIALS');
    }

    // Update Axios auth header and save data to session storage.
    axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
    setAuthData(accessToken);

    return { accessToken, username };
  } catch (error) {
    // Use custom error message for invalid username/password.
    if (error?.request?.status === 400) {
      throw Error('INVALID_CREDENTIALS');
    }
    throw error;
  }
});

export const fetchTupasLoginInfo = createAsyncThunk('auth/fetchTupasLoginInfo', async () => {
  const response = await axios.get('api/tupaslogin/info');
  return response.data;
});

export const fetchBankerCustomers = createAsyncThunk('auth/fetchBankerCustomers', async () => {
  const response = await axios.get('api/useraccount/customers');
  return response.data;
});

export const fetchCustomerUser = createAsyncThunk('auth/fetchCustomerUser', async (_, thunkAPI) => {
  const { customerId } = thunkAPI.getState().auth;
  const response = await axios.get(`api/account/customer/${customerId}`);
  return response.data;
});

export const fetchTupasUserInfo = createAsyncThunk('auth/fetchTupasUserInfo', async ({ signicatCode }) => {
  const response = await axios.post('/api/session/tupasUsers/newUser', { signicatCode });
  return response.data;
});

export const checkSignicatCode = createAsyncThunk('auth/checkSignicatCode', async (signicatCode, thunkAPI) => {
  try {
    const response = await axios.get(`api/authentication/signicatAuthValid?signicatCode=${signicatCode}`);
    return response.status;
  } catch (error) {
    return thunkAPI.rejectWithValue(error?.response?.status || error.message);
  }
});

const initialState = {
  token: '',
  username: '',
  currentUser: null,
  fetchingSignicatUrl: false,
  bankerCustomers: [],
  customerId: null, // For banker view.
  customerUser: null,
  fetchingTupasUserInfo: false,
  tupasUserInfo: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    userUnauthenticated: () => initialState,
    userAuthenticated: (state, { payload }) => {
      state.token = payload.token;
      state.username = payload.username;
    },
    updateCustomerId: (state, { payload }) => {
      state.customerId = payload;
    },
    clearBankerCustomers: (state) => {
      state.bankerCustomers = initialState.bankerCustomers;
    },
  },
  extraReducers: {
    [fetchToken.fulfilled]: (state, { payload }) => {
      state.token = payload.accessToken;
      state.username = payload.username;
    },
    [fetchCurrentUser.fulfilled]: (state, { payload }) => {
      state.currentUser = payload;
    },
    [logout.fulfilled]: (state) => {
      state.token = '';
      state.username = '';
      state.currentUser = null;
    },
    [fetchSignicatUrl.pending]: (state) => {
      state.fetchingSignicatUrl = true;
    },
    [fetchSignicatUrl.fulfilled]: (state) => {
      state.fetchingSignicatUrl = false;
    },
    [fetchSignicatUrl.rejected]: (state) => {
      state.fetchingSignicatUrl = false;
    },
    [tupasLogin.fulfilled]: (state, { payload }) => {
      state.token = payload.accessToken;
      state.username = payload.username;
    },
    [fetchBankerCustomers.fulfilled]: (state, { payload }) => {
      state.bankerCustomers = payload;
    },
    [fetchCustomerUser.fulfilled]: (state, { payload }) => {
      state.customerUser = payload;
    },
    [fetchTupasUserInfo.pending]: (state) => {
      state.fetchingTupasUserInfo = true;
    },
    [fetchTupasUserInfo.fulfilled]: (state, { payload }) => {
      state.fetchingTupasUserInfo = false;
      state.tupasUserInfo = payload;
    },
    [fetchTupasUserInfo.rejected]: (state) => {
      state.fetchingTupasUserInfo = false;
    },
  },
});

export const {
  userUnauthenticated,
  userAuthenticated,
  updateCustomerId,
  clearBankerCustomers,
} = authSlice.actions;

export default authSlice.reducer;
