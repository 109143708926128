import * as React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  List, ListItem, ListItemIcon, ListItemText,
} from '@mui/material';
import PersonSharpIcon from '@mui/icons-material/PersonSharp';
import AccessTimeSharpIcon from '@mui/icons-material/AccessTimeSharp';
import LocalPhoneSharpIcon from '@mui/icons-material/LocalPhoneSharp';
import EmailSharpIcon from '@mui/icons-material/EmailSharp';

function ContactsList({
  contactsInfo, ariaLabel, primaryInfo,
}) {
  const { t } = useTranslation();
  return (
    <List dense sx={{ '&>li': { paddingLeft: 0 } }} aria-label={ariaLabel}>
      {contactsInfo.fullname !== undefined && (
        <ListItem>
          <ListItemIcon>
            <PersonSharpIcon color="primary" />
          </ListItemIcon>
          <ListItemText
            primaryTypographyProps={{
              variant: 'body1',
              fontWeight: primaryInfo === 'fullname' ? 600 : 'inherit',
            }}
            primary={t(contactsInfo.fullname)}
          />
        </ListItem>
      )}
      {contactsInfo.serviceTime !== undefined && (
        <ListItem>
          <ListItemIcon>
            <AccessTimeSharpIcon color="primary" />
          </ListItemIcon>
          <ListItemText
            primaryTypographyProps={{
              variant: 'body1',
              fontWeight: primaryInfo === 'serviceTime' ? 600 : 'inherit',
            }}
            primary={contactsInfo.serviceTime}
          />
        </ListItem>
      )}
      {contactsInfo.phone !== undefined && (
        <ListItem>
          <ListItemIcon>
            <LocalPhoneSharpIcon color="primary" />
          </ListItemIcon>
          <ListItemText
            primaryTypographyProps={{
              variant: 'body1',
              fontWeight: primaryInfo === 'phone' ? 600 : 'inherit',
            }}
            primary={contactsInfo.phone}
          />
        </ListItem>
      )}
      {contactsInfo.email !== undefined && (
        <ListItem>
          <ListItemIcon>
            <EmailSharpIcon color="primary" />
          </ListItemIcon>
          <ListItemText
            primaryTypographyProps={{
              variant: 'body1',
              fontWeight: primaryInfo === 'email' ? 600 : 'inherit',
            }}
            primary={contactsInfo.email}
          />
        </ListItem>
      )}
    </List>
  );
}

ContactsList.propTypes = {
  contactsInfo: PropTypes.shape({
    fullname: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
    serviceTime: PropTypes.string,
  }).isRequired,
  ariaLabel: PropTypes.string.isRequired,
  primaryInfo: PropTypes.string,
};

ContactsList.defaultProps = {
  primaryInfo: '',
};

export default ContactsList;
