import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material';
import PrimaryButton from './PrimaryButton';
import ContentCard from './ContentCard';
import ContactsList from './ContactsList';
import { contacts } from '../../lib/constants';

/* NOTES:
- Currently DialogContent only displays info about error 500.
However, more options can be added if needed. */

function DialogError() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const errorMessage = useSelector((state) => state.errors.errorMessage);
  const errorCode = useSelector((state) => state.errors.errorCode);

  return (
    <Dialog
      open={!!errorMessage}
      onClose={() => dispatch({ type: 'error/resetErrors' })}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{ square: true }}
    >
      <ContentCard
        ariaLabel="customer-service-info"
        title={`${errorCode ? `${errorCode}: ` : ''} ${t(errorMessage) || ''}`}
      >
        {errorCode === 500 && (
        <DialogContent sx={{ padding: 0 }} id="alert-dialog-description">
          <Typography variant="subtitle1">
            {errorCode === 500 && t('INFO_TEXTS.500_TITLE')}
          </Typography>
          <Typography variant="body1">
            {errorCode === 500 && t('INFO_TEXTS.500')}
          </Typography>
          <Typography variant="subtitle2">
            {t('CUSTOMER_SERVICE')}
          </Typography>
          <ContactsList contactsInfo={contacts(t).customerService} ariaLabel={t('ARIA.CUSTOMERSERVICE_CONTACT_INFO')} />
        </DialogContent>
        )}
        <DialogActions>
          <PrimaryButton onClick={() => dispatch({ type: 'error/resetErrors' })}>
            {t('close')}
          </PrimaryButton>
        </DialogActions>
      </ContentCard>
    </Dialog>
  );
}

DialogError.propTypes = {};

DialogError.defaultProps = {};

export default DialogError;
