import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

const StyledButton = styled(Button)(({ theme }) => ({
  endIcon: {
    color: theme.palette.primary.main,
  },
}));

function SecondaryButton({
  onClick,
  disabled,
  startIcon,
  endIcon,
  size,
  children,
  type,
  round,
  component,
  to,
}) {
  return (
    <StyledButton
      type={type}
      variant="outlined"
      color="secondary"
      onClick={onClick}
      disableElevation
      disabled={disabled}
      startIcon={startIcon}
      endIcon={endIcon}
      size={size}
      style={{ borderRadius: round ? '16px' : 0 }}
      component={component}
      to={to}
    >
      {children}
    </StyledButton>
  );
}

SecondaryButton.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  startIcon: PropTypes.node,
  endIcon: PropTypes.node,
  size: PropTypes.string,
  children: PropTypes.node.isRequired,
  type: PropTypes.string,
  round: PropTypes.bool,
  component: PropTypes.elementType,
  to: PropTypes.string,
};

SecondaryButton.defaultProps = {
  onClick: () => {},
  disabled: false,
  startIcon: null,
  endIcon: null,
  size: 'medium',
  type: 'button',
  round: false,
  component: null,
  to: null,
};

export default SecondaryButton;
