/* eslint-disable */
export default {
  "anErrorOccurred": "Ett fel inträffade.",
  "reloadPage": "Ladda om sidan",

  // Original translations.
  "1 Security-oriented": "1 Säkerhetsorienterad",
  "11101, Non-financial corporations, excl. housing corporations, public": "11101, Offentliga icke-finansiella företag exkl. bostadssamfund",
  "11102, Non-financial corporations, excl. housing corporations, national private": "11102, Nationella privata icke-finansiella företag exkl. bostadssamfund",
  "11103, Non-financial corporations, excl. housing corporations, foreign controlled": "11103, Utlandskontrollerade icke-finansiella företag exkl. bostadssamfund",
  "112, Housing corporations": "112, Bostadssamfund",
  "11211, Housing companies, public": "11211, Bostadsaktiebolag, offentliga",
  "11212, Housing companies, national private": "11212, Bostadsaktiebolag, nationella privata",
  "11213, Housing companies, foreign controlled": "11213, Bostadsaktiebolag, utlandskontrollerade",
  "11221, Other housing corporations, public": "11221, Övriga bostadssamfund, offentliga",
  "11222, Other housing corporations, national private": "11222, Övriga bostadssamfund, nationella privata",
  "11223, Other housing corporations, foreign controlled": "11223, Övriga bostadssamfund, utlandskontrollerade",
  "121, Central bank": "121, Centralbanken",
  "1221, Deposit banks": "1221, Inlåningsbanker",
  "12211, Deposit banks, public": "12211, Inlåningsbanker, offentliga",
  "12212, Deposit banks, national private": "12212, Inlåningsbanker, nationella privata",
  "12213, Deposit banks, foreign controlled": "12213, Inlåningsbanker, utlandskontrollerade",
  "1222, Other credit institutions": "1222, Övriga kreditinstitut",
  "12221, Other credit institutions, public": "12221, Övriga kreditinstitut, offentliga",
  "12222, Other credit institutions, national private": "12222, Övriga kreditinstitut, nationella privata",
  "12223, Other credit institutions, foreign controlled": "12223, Övriga kreditinstitut, utlandskontrollerade",
  "1223, Other monetary financial institutions, excl. credit institutions": "1223, Monetära finansinstitut, utom kreditinstitut",
  "12231, Other monetary financial institutions, excl. credit institutions, public": "12231, Monetära finansinstitut, utom kreditinstitut, offentliga",
  "12232, Other monetary financial institutions, excl. credit institutions, national private": "12232, Monetära finansinstitut, utom kreditinstitut, nationella privata",
  "12233, Other monetary financial institutions, excl. credit institutions, foreign controlled": "12233, Monetära finansinstitut, utom kreditinstitut, utlandskontrollerade",
  "123, Money market funds (MMF)": "123, Penningmarknadsfonder",
  "1241, Investment funds (UCITS)": "1241, Placeringsfonder (UCITS)",
  "1242, Other collective investment schemes": "1242, Övriga kollektiva fondföretag",
  "125, Other financial intermediaries": "125, Övriga finansinstitut",
  "12501, Other financial intermediaries, public": "12501, Övriga finansinstitut, offentliga",
  "12502, Other financial intermediaries, national private": "12502, Övriga finansinstitut, nationella privata",
  "12503, Other financial intermediaries, foreign controlled": "12503, Övriga finansinstitut, utlandskontrollerade",
  "126, Financial auxiliaries": "126, Finansiella serviceföretag",
  "12601, Financial auxiliaries, public": "12601, Finansiella serviceföretag, offentliga",
  "12602, Financial auxiliaries, national private": "12602, Finansiella serviceföretag, nationella privata",
  "12603, Financial auxiliaries, foreign controlled": "12603, Finansiella serviceföretag, utlandskontrollerade",
  "127, Captive financial institutions and money lenders": "127, Koncerninterna finansinstitut och utlåningsföretag",
  "12701, Captive financial institutions and money lenders, public": "12701, Koncerninterna finansinstitut och utlåningsföretag, offentliga",
  "12702, Captive financial institutions and money lenders, national private": "12702, Koncerninterna finansinstitut och utlåningsföretag, nationella",
  "12703, Captive financial institutions and money lenders, foreign controlled": "12703, Koncerninterna finansinstitut och utlåningsföretag, utlandskontrollerade",
  "128, Insurance corporations": "128, Försäkringsföretag",
  "12801, Insurance corporations, public": "12801, Försäkringsföretag, offentliga",
  "12802, Insurance corporations, national private": "12802, Försäkringsföretag, nationella privata",
  "12803, Insurance corporations, foreign controlled": "12803, Försäkringsföretag, utlandskontrollerade",
  "129, Pension funds": "129, Pensionsinstitut",
  "1311, Central government": "1311, Staten",
  "13111, Budget economy of central government": "13111, Statens budgetekonomi",
  "13119, Other units of central government": "13119, Övriga enheter hänförliga till staten",
  "1312, State government (not used in Finland)": "1312, Delstatsförvaltning (inte i bruk i Finland)",
  "1313, Local government": "1313, Lokalförvaltning",
  "13131, Municipalities": "13131, Kommuner",
  "13132, Joint municipal authorities": "13132, Samkommuner",
  "13133, The Ã…land Government": "13133, Ålands landskapsregering",
  "13139, Other units of local government": "13139, Övriga enheter hänförliga till lokalförvaltning",
  "1314, Social security funds": "1314, Socialskyddsfonder",
  "13141, Employment pension schemes": "13141, Arbetspensionsanstalter",
  "13149, Other social security funds": "13149, Övriga socialskyddsfonder",
  "14, Households": "14, Hushåll",
  "141, Employers and own-account workers": "141, Arbetsgivarhushåll och andra näringsidkarhushåll",
  "143, Employees": "143, Hushåll med anställda",
  "1441, Recipients of property income": "1441, Hushåll med mottagare av kapitalinkomster",
  "1442, Recipients of pensions": "1442, Hushåll med mottagare av pensioner",
  "1443, Recipients of other transfers": "1443, Hushåll med mottagare av övriga transfereringsinkomster",
  "15, Non-profit institutions serving households": "15, Hushållens icke-vinstsyftande organisationer",
  "2 Cautious": "2 Försiktig",
  "3 Moderate": "3 Måttlig",
  "4 Return-oriented": "4 Avkastningsorienterad",
  "5 Aggressive": "5 Aggressiv",
  "ACCOUNT_IS_FREE": "Fondförvaring är avgiftsfri",
  "ACCOUNT_STATEMENT": "Kontoutdrag",
  "ACCOUNT_TRANSFER": "Kontoöverföring",
  "ACCOUNTANT_REPORT": "Sammanfattande rapporten",
  "ACCOUNTANT_REPORT_SUMMARY": "Sammanfattande rapporten innehåller alla rapporter för period som visas.",
  "ACTIVE_PORTFOLIOS": "Aktiva portföljer",
  "ADDED_NEW_ORDER": "Lagt till nytt uppdrag.",
  "ADDITIONAL_CUSTOMER_INFORMATION": "Tilläggsinformation om kunden",
  "ADDITIONAL_INFORMATION": "Tilläggsinformation",
  "ADDITIONAL_INFORMATION_ADDED_SUCCESSFULLY": "Tilläggsinformation sparad",
  "ADDITIONAL_INVESTMENTS": "Depositioner",
  "ADDITIONAL_INVESTMENTS_DURING_PERIOD": "Tilläggsplaceringar under perioden",
  "ADDITIONAL_TAX_COUNTRY": "Annat beskattningsland",
  "ADDITIONS": "Tilläggningar",
  "ADDRESS": "Adress",
  "COUNTRY_OF_RESIDENCE": "Bosättningsland",
  "AFGHANISTAN": "Afghanistan",
  "AGGRESSIVE": "Aggressiv",
  "ALBANIA": "Albanien",
  "alempi korkeakouluaste": "Lägre högskolenivå",
  "ALGERIA": "Algeriet",
  "ALL": "Alla",
  "ALL_FUND_FAMILIES": "Alla fondfamiljer",
  "CHOOSE_FUND_FAMILY": "Välj fondfamilj",
  "FUND_FAMILIES": "Fondfamiljer",
  "ALL_MAIN_CLASSES": "Alla huvudklasser",
  "ALL_SUB_CLASSES": "Alla underklasser",
  "ALL_TOTAL": "Alla totalt",
  "Alternative": "Alternativa placeringar",
  "ALTERNATIVEFUND": "Alternativ fond",
  "Alternatives": "Alternativa placeringar",
  "AMERICAN SAMOA": "Amerikanska Samoa",
  "AMOUNT": "Antal",
  "AMOUNT_PER_SHARE": "Antal (€/st.)",
  "AND_YOUR_NEW_PASSWORD": "och det nya lösenordet har du själv angett.",
  "AND_YOUR_PASSWORD": "och lösenordet har du själv angett",
  "ANDORRA": "Andorra",
  "ANGOLA": "Angola",
  "ANGUILLA": "Anguilla",
  "ANNUALLY": "Årligen",
  "ANTARCTICA": "Antarktis",
  "ANTIGUA AND BARBUDA": "Antigua och Barbuda",
  "ARE_YOU_SURE_YOU_WANT_TO_EXIT_FROM_TRANSACTION": "Är du säker på att du vill lämna uppdraget innan det har gjorts och bekräftats?",
  "ARGENTINA": "Argentina",
  "Arkkitehti- ja insinööripalvelut, tekninen testaus ja analysointi": "Arkitekt- och teknisk konsultverksamhet; teknisk provning och analys",
  "ARMENIA": "Armenien",
  "ARUBA": "Aruba",
  "Asia": "Asien",
  "Asia-Pacific": "Asien och Stilla havet",
  "Asset management fee": "Kapitalförvaltningsarvode",
  "ASSET_CHANGES": "Förmögenhetsutveckling",
  "ASSET_MANAGEMENT": "Kapitalförvaltning",
  "ASSET_MANAGEMENT_FEES": "Kapitalförvaltningsarvoden",
  "ASSET_VALUE_IN_BEGINNING": "Tillgångarnas värde i början",
  "ASSET_VALUE_IN_BEGINNING_OF_PERIOD": "Egendomens värde i början av perioden",
  "ASSET_VALUE_IN_END": "Tillgångarnas värde i slutet",
  "ASSET_VALUE_IN_END_OF_PERIOD": "Egendomens värde i slutet av perioden",
  "ASSETMANAGEMENTFEE": "Kapitalförvaltningsarvode",
  "ASSETS": "Förmögenhet",
  "ASSETS_SUMMARY": "Sammandrag över tillgångarna",
  "Asuin- ja muiden rakennusten rakentaminen": "Byggande av bostadshus och andra byggnader",
  "AUSTRALIA": "Australien",
  "AUSTRIA": "Österrike",
  "AZERBAIJAN": "Azerbajdzjan",
  "BACK_TO_ASSET_REPORT": "Tillbaka till förmögenhetsöversikt",
  "BACK_TO_HOLDINGS": "Tillbaka till innehav",
  "BACK_TO_SEARCH": "Tillbaka till sökningen",
  "BAHAMAS": "Bahamas",
  "BAHRAIN": "Bahrain",
  "Balanced funds": "Blandfonder",
  "Balanced Funds": "Blandfonder",
  "BALANCEDFUND": "Blandfond",
  "BANGLADESH": "Bangladesh",
  "BANK_ACCOUNT": "Motkonto (IBAN)",
  "BANK_ACCOUNT_NUMBER": "Motkonto (IBAN)",
  "BANK_ACCOUNTS": "Bankinformation",
  "BARBADOS": "Barbados",
  "BECOME_A_CUSTOMER_WITH_BANK_ACCESS_CODES": "Bli kund med online-identifieringen.",
  "BELARUS": "Vitryssland",
  "BELGIUM": "Belgien",
  "BELIZE": "Belize",
  "BENEFICIARIES": "Förmånstagare",
  "BENEFICIARY": "Mottagare",
  "BENEFICIARY_ACCOUNT_NUMBER": "Mottagarens kontonummer",
  "BENIN": "Benin",
  "BERMUDA": "Bermuda",
  "BHUTAN": "Bhutan",
  "BLOOMBERG": "Bloomberg",
  "BLOOMBERG_CODE": "Bloomberg-kod",
  "BOLIVIA": "Bolivia",
  "BONDFUND": "Räntefonden",
  "BONDS": "Masskuldebrevslån",
  "BOSNIA AND HERTSEGOVINA": "Bosnien och Hercegovina",
  "BOTSWANA": "Botswana",
  "BOUGHT_TO_PORTFOLIO": "köpas för portfölj",
  "BOUVET ISLAND": "Bouvetön",
  "BRAZIL": "Brasilien",
  "BRIEFS": "Meddelanden",
  "BRITISH INDIAN OCEAN TERRITORY": "Brittiska territoriet i Indiska oceanen",
  "BROWSE_ORDERS": "Sök transaktioner",
  "BRUNEI DARUSSALAM": "Brunei",
  "BULGARIA": "Bulgarien",
  "BURKINA FASO": "Burkina Faso",
  "BURUNDI": "Burundi",
  "BUSINESS_ID": "FO-nummer",
  "BUSINESS_INCOME": "Affärsverksamhet",
  "BUY": "Köp",
  "Buy": "Inköp",
  "BUYRECEIVABLE": "Inköpsfordring",
  "BuyWithdrawal": "Debitering av inköp",
  "BYPASS_FOR_NOW": "Hoppa över",
  "CALCULATORY_PRICE": "Kalkylmässigt pris inklusive kostnader (EUR/andel)",
  "CALCULATION_OF_SECURITY_PROFITS_FOR_TAXREPORT": "UTRÄKNING AV ÖVERLÅTELSEVINST ELLER -FÖRLUST AV VÄRDEPAPPER OCH VÄRDEANDELAR",
  "CALL_RATES": "Samtalspriser",
  "CAMBODIA": "Kambodja",
  "CAMEROON": "Kamerun",
  "CANADA": "Kanada",
  "CANCEL": "Ångra",
  "CANCEL_TRANSACTIONS": "Avbryt transaktion",
  "CAPE VERDE": "Kap Verde",
  "CAPITAL_GAIN": "Överlåtelsevinster",
  "CAPITAL_INCOME": "Kapitalinkomst",
  "CAPITAL_LOSS": "Överlåtelseförluster",
  "CASH": "Kontanter",
  "Cash": "Kontanter",
  "Cash and others": "Likvida medel och andra",
  "Cash and Others": "Kontanter och andra poster",
  "CASH_RESERVED_FOR_BUYS": "Inköpsskuld",
  "CAUTIOUS": "Försiktig",
  "CAYMAN ISLANDS": "Caymanöarna",
  "CENTRAL AFRICAN REPUBLIC": "Centralafrikanska republiken",
  "CHAD": "Tchad",
  "CHANGE": "Ändring",
  "CHANGE_%": "Ändring %",
  "CHANGE_AFTER_COSTS": "Ändring efter kostnader",
  "CHANGE_AND_OTHER_TRANSACTIONS": "Värdeförändring",
  "CHANGE_ARGUMENTS_AND_SEARCH_AGAIN": "Ändra valet och sök igen.",
  "CHANGE_PASSWORD": "Ändra lösenord",
  "CHANGE_YOUR_PASSWORD": "Ändra ditt lösenord",
  "CHANGING_PASSWORD": "Byte av lösenord",
  "CHECK_OUT_MORE_NEWS": "Läs fler nyheter här",
  "CHILE": "Chile",
  "CHINA": "Kina",
  "CHOOSE": "Välj",
  "CHOOSE_DATE": "Välj datum",
  "CHOOSE_AT_LEAST_ONE_PORTFOLIO": "Välj minst en portfölj",
  "CHOOSE_AT_LEAST_ONE_TRANSACTION_TYPE": "Välj minst en transaktionstyp",
  "CHOOSE_BANK_YOU_WANT_USE_FOR_IDENTIFYING": "Välj den bank du vill använda för identifiering.",
  "CHOOSE_CUSTOMER_YOU_WANT_TO_CREATE_USERID_FOR": "Välj kund som du vill skapa ett användarnamn åt",
  "CHOOSE_FOR_VALUE_COMPARISON": "Välj för avkastningsjämfödelse",
  "CHOOSE_FUND": "Välj fond",
  "CHOOSE_PORTFOLIO_FOR_DEPOSIT": "Välj först den portfölj i vilken du vill göra en insättning",
  "CHOOSE_PORTFOLIO_FOR_MONTHLY_DEPOSIT": "Välj först den portfölj i vilken du vill göra en månatlig insättning",
  "CHOOSE_RISK_CLASS": "Välj riskklass",
  "CHOOSE_TRANSACTION_TYPES": "Välj transaktionstyper",
  "CHOOSE_USER_YOU_WANT_TO_UPDATE": "Välj den användare vars lösenord du vill ändra",
  "CHOOSE_USER_YOU_WANT_TO_LOGIN": "Välj den användare du vill logga in",
  "CHOOSING_PASSWORD": "Välj lösenord",
  "CHOSEN_PORTFOLIO_HAS_NOT_HOLDINGS": "Den valda portföljen har inga innehav så att inlösen inte kan göras. Välj en annan portfölj",
  "CHOSEN_PORTFOLIO_HAS_NO_BALANCE": "Den valda portföljen har ingen balans så att uttag inte kan göras. Välj en annan portfölj",
  "CHOSEN_PORTFOLIO_HAS_NO_BALANCE_FOR_PURCHASES": "Den valda portföljen har ingen balans så att inköp inte kan göras. Välj en annan portfölj",
  "CHRISTMAS ISLAND": "Julön",
  "CITIZENSHIP": "Nationalitet",
  "CITIZENSHIP_REQUIRED": "Nationalitet är en obligatorisk uppgift",
  "CLEAR": "Töm",
  "CLEARSELECTIONS": "Tömma val",
  "CLICK": "Klick",
  "CLICK_ON_FLAG_TO_DOWNLOAD_INSTRUMENT_DOCUMENT": "Klicka på flaggan för att ladda ner fonddokumenten.",
  "CLOSE": "Stäng",
  "CLOSE_WINDOW": "Stäng fönstret",
  "COCOS (KEELING) ISLANDS": "Kokosöarna",
  "COLOMBIA": "Colombia",
  "COME_TO": "kommer att",
  "COME_TO_CUSTOMER": "Bli kund",
  "COME_TO_ELITES_CUSTOMER": "Varför lönar det sig att vara kund hos Elite Alfred Berg?",
  "COMMISSION": "Uppdrag",
  "COMMISSION_CONFIRMATION": "Autentiseringsmetod för uppdrag",
  "COMMISSION_DATE": "Uppdragsdatum",
  "Commodities": "Råvaror",
  "COMMODITYFUND": "Råvarufond",
  "COMMON_CONTRACT_TERMS": "Allmänna avtalsvillkor",
  "COMMON_FUND_DESCRIPTION": "För tillgångarna i fonden kan förutom den fasta förvaltningsavgiften även tas ut andra avgifter, såsom avkastningsbaserad förvaltningsavgift eller arvoden för förvaring och handel. Mer detaljerad information om kostnaderna för fonden finns i det faktablad, den fondbroschyr och de regler som publicerats av fondbolaget.",
  "COMOROS": "Komorerna",
  "COMPANY_SALE": "Företagsförvärv",
  "CONFIRM": "Bekräfta",
  "CONFIRM_MARK_TRANSACTION": "Bekräfta teckningsuppdrag",
  "CONFIRM_RECLAIM_TRANSACTION": "Bekräfta inlösningsuppdrag",
  "CONFIRM_SIGN_IN": "Bekräfta inloggning",
  "CONFIRM_WITHDRAWAL_TRANSACTION": "Bekräfta uttagsuppdrag",
  "CONFIRMATION_CODE": "Bekräftelsekod",
  "CONFIRMATION_CODE_IS_INVALID": "Bekräftelsekoden är ogiltig",
  "CONFIRMATION_CODE_IS_MANDATORY": "Du måste ange bekräftelsekoden",
  "CONGO": "Kongo",
  "CONGO THE DEMOCRATIC REPUBLIC OF THE": "Demokratiska republiken Kongo",
  "CONTACT_AND_BANK_INFORMATION": "Kontaktuppgifter och bankinformation",
  "CUSTOMER_INFORMATION": "Kundinformation",
  "CONTACT_INFORMATION": "Kontaktuppgifter",
  "CONTACT_INFORMATION_CHANGED": "Kontaktuppgifterna har sparats.",
  "CONTACT_PERSON": "Kontaktperson",
  "CONTACT_REQUEST": "Kontaktbegäran",
  "CONTACT_REQUEST_SUCCESSFUL": "Kontaktbegäran förmedlas framgångsrikt!",
  "CONTACT_REQUEST_FAILED": "Det gick inte att vidarebefordra kontaktförfrågan. Kontakta kundtjänst.",
  "CONTINUE": "FORTSÄTT",
  "CONTRACT_FOR_COMMUNITY": "Avtal för organisationskund",
  "CONTRACT_FOR_ORGANIZATION_CLIENTS": "Avtal för organisationskunder",
  "CONTRACT_FOR_PERSON": "Avtal för privatkund",
  "CONTRACT_FOR_PRIVATE_CLIENTS": "Avtal för privatkunder",
  "CONTRACT_MUST_BE_SIGNED_BEFORE_CONTINUING": "Du måste underteckna avtalet innan du kan fortsätta.",
  "CONTRACT_TERMS_MUST_BE_ACCEPTED_BEFORE_CONTINUING": "Du måste godkänna avtalsvillkoren innan du kan fortsätta.",
  "CONTRARIANFUND": "Kontrafond",
  "COOK ISLANDS": "Cooköarna",
  "Corporate bonds (convertible bonds)": "Företagslån (konvertibla)",
  "Corporate bonds (high yield)": "Företagsobligation (high yield)",
  "Corporate bonds (investment grade)": "Företagsobligationer (investment grade)",
  "Corporate bonds (variable rate)": "Företagslån (rörlig ränta)",
  "Corporate Debt (Convertibles)": "Företagslån (konvertibla)",
  "Corporate Debt (Floating Rate)": "Företagslån (rörlig ränta)",
  "Corporate Debt (High Yield)": "Företagsobligation (high yield)",
  "Corporate Debt (Investment Grade)": "Företagsobligationer (investment grade)",
  "Corporate Debt (Non-Rated)": "Företagsobligationer (Oklassificerade)",
  "COST": "Serviceavgift",
  "COSTA RICA": "Costa Rica",
  "COSTS": "Serviceavgifter",
  "COUNTER_ACCOUNT": "Motkonto",
  "COUNTRY": "Land",
  "COUNTRY_REQUIRED": "Land är en obligatorisk uppgift",
  "CREATE_A_NEW_USERID": "Skapa nytt användarnamn",
  "CREATING_NEW_PASSWORD": "Skapa ett nytt lösenord",
  "CREATING_NEW_USERID": "Skapa användarnamn",
  "CREDIT_INTERESTS": "Låneräntor",
  "CREDITINTERESTDEPOSITION": "Redovisning av placeringslåneränta",
  "CREDITINTERESTWITHDRAWAL": "Debitering av investeringslåneränta",
  "CreditInterestWithdrawal": "Debitering av investeringslåneränta",
  "CROATIA": "Kroatien",
  "CUBA": "Kuba",
  "CURRENCY": "Valuta",
  "CHOOSE_CURRENCY": "Välja valuta",
  "Currency": "Kontanter",
  "CURRENT_CUSTOMER": "Befintlig kund",
  "CURRENT_CUSTOMER_GET_USERID": "Är du redan kund och vill få användarnamn och lösenord för webbtjänsten?",
  "CURRENT_INVESTMENT_SERVICES": "Nuvarande investeringstjänster",
  "CURRENT_PASSWORD": "Aktuellt lösenord",
  "CURRENT_YEAR": "I år",
  "CURRENTLY_USED_SERVICES": "Aktuella tjänster",
  "CUSTOMER_ADDITIONAL_INFORMATION": "Tilläggsinformation om kunden",
  "CUSTOMER_FUNDS": "Kundtillgångar",
  "CUSTOMER_INFORMATION": "Kunduppgifter",
  "CUSTOMER_SERVICE": "Kundtjänst",
  "Customer Service": "Kundtjänst",
  "Customer service": "Kundtjänst",
  "CUSTOMERSHIP_CONSISTS": "Fondkontoavtal",
  "CYPRUS": "Cypern",
  "CZECH REPUBLIC": "Tjeckien",
  "DAILY": "Dagligen",
  "DATA": "Data",
  "DATE": "Datum",
  "DATE_FILTERS": "Datumval:",
  "DATE_RANGE": "Tidsintervall",
  "DAY": "Dag",
  "DEBT": "Skulder",
  "Debt": "Skulder",
  "DEFAULT_PORTFOLIO": "Standardportfölj",
  "DENMARK": "Danmark",
  "DEPOSITINTERESTDEPOSITION": "Kontering av depositionsränta",
  "DEPOSITINTERESTWITHDRAWAL": "Debitering av depositionsränta",
  "DEPOSITION": "Deposition",
  "Deposition": "Deposition",
  "DEPOSITION_INTERESTS": "Depositionsräntor",
  "DEPOSITIONS": "Depositioner",
  "DEPOSITS": "Depositioner",
  "DERIVATIVES": "Derivat",
  "DERIVATIVESFUND": "Hävstångsfond",
  "DESCRIPTION": "Beskrivning",
  "DID_YOU_FORGET_YOUR_ACCOUNT": "Har du glömt inloggningsuppgifterna",
  "DIFFERENCE": "Skillnad",
  "DISTRIBUTION_HISTORY_NOT_FOUND": "Kunde inte hitta historisk distribution.",
  "DIVIDEND": "Dividend",
  "DIVIDENDS": "Dividender",
  "DJIBOUTI": "Djibouti",
  "DOCUMENTS": "Dokument",
  "DOMINICA": "Dominica",
  "DOMINICAN REPUBLIC": "Dominikanska republiken",
  "DOWNLOAD_SUSTAINABILITY_REPORT_PDF": "Download sustainability report (PDF)",
  "DUE_DATE": "Förfallodag",
  "EAB_ASSET_MANAGEMENT": "EAB Kapitalförvaltning",
  "ECUADOR": "Ecuador",
  "EDUCATION": "Utbildning",
  "EDUCATION_AND_OCCUPATION": "Utbildning och yrke",
  "EDUCATION_IS_REQUIRED": "Utbildning är en obligatorisk uppgift.",
  "EG": "T.ex.",
  "EGYPT": "Egypten",
  "ei koulutusta": "Ingen utbildning",
  "EL SALVADOR": "El Salvador",
  "ELECTRONIC_SIGNATURE": "Elektronisk underskrift",
  "ELECTRONIC_TRADING": "Elektronisk handel",
  "Elintarvikkeiden valmistus": "Livsmedelsframställning",
  "ELITE_ASSET_MANAGEMENT_LTD": "EAB Group Abp",
  "ELITE_CUSTOMER_SERVICE": "Elite Alfred Berg kundtjänst",
  "Elokuva- ja televisio-ohjelmatuotanto, musiikin kustantaminen": "Film-, video- och tv-programverksamhet, ljudinspelningar",
  "Eläinlääkintäpalvelut": "Veterinärverksamhet",
  "EMAIL": "E-post",
  "Emerging market bonds": "Räntor (tillväxtmarknader)",
  "Emerging Market Debt": "Räntor (tillväxtmarknader)",
  "Emerging markets": "Tillväxtmarknader",
  "Emerging Markets": "Tillväxtmarknader",
  "EMPTY": "Tom",
  "ENGLISH": "engelska",
  "END_BALANCE": "Slutsaldo",
  "END_DATE": "Slutdatum",
  "EQUATORIAL GUINEA": "Ekvatorialguinea",
  "Equities": "Aktier",
  "Other Equities": "Andra aktier",
  "EQUITY": "Egna tillgångar",
  "EQUITYFUND": "Aktiefond",
  "Erikoistunut rakennustoiminta": "Specialiserad bygg- och anläggningsverksamhet",
  "ERITREA": "Eritrea",
  "ERROR_CANCELLING_TRANSACTION": "Hej, något gick fel när du försökte ångra transaktionen eller transaktionen kan inte längre ångras.",
  "ERROR_DETAILS": "Felmeddelanden",
  "ERROR_IN_CREATING_USERID": "Ett fel uppstod när användarkontot skulle skapas. Du kan försöka igen genom att klicka på Ångra.",
  "ERROR_SENDING_CONFIRMATION_CODE_BY_SMS": "Det uppstod ett fel när verifikationskoden skulle skickas med SMS. Kontrollera i dina egna inställningar att ditt telefonnummer står rätt skrivet.",
  "ERROR_SENDING_INFORMATION": "Fel vid sändning av information",
  "ERROR_UPDATING_INFO": "Ett fel uppstod när uppgifterna skulle uppdateras",
  "ERROR_IN_ONLINE_AUTHENTICATION": "Fel med autentisering online",
  "ERROR_IN_NEW_ORDER": "Vi beklagar, ett oväntat fel.",
  "ESTONIA": "Estland",
  "ETHIOPIA": "Etiopien",
  "EUR_MARK": "€",
  "Europe": "Europa",
  "EXCECUTING_ORDER": "Ordern utförs",
  "EXPECTED_END_VALUE_OF_100000_€_INVESTMENT": "Förväntat slutvärde för en placering på 100 000 €",
  "EXPECTED_END_VALUE_OF_THE_WORST_CASE_SCENARIO": "Förväntat slutvärde i värsta fall (5 % sannolikhet)",
  "EXPENSE": "Arvode",
  "EXTERNAL_ACCOUNT": "Externt konto.",
  'Factsheet': "Faktablad",
  "FALKLAND ISLANDS (MALVINAS)": "Falklandsöarna",
  "FAROE ISLANDS": "Färöarna",
  "FEE": "Arvode",
  "FEE_DEBT": "Arvodesskuld",
  "FEE_EUR": "Arvode",
  "FEE_REBATES": "Återbetalning av arvoden",
  "FEE_SUMMARY": "Arvodesöversikt",
  "FEEREBATE": "Arvodesrabatt",
  "FIELD_CANT_BE_EMPTY": "Fältet får inte vara tomt",
  "FIJI": "Fiji",
  "FIN": "Finland",
  "FINANCIAL_INFORMATION": "Finansiell information",
  "FINLAND": "Finland",
  "FINNISH": "finska",
  "FINNISH_DATE": "Slutdatum",
  "FIRST_NAME": "Förnamn",
  "FIRST_NAME_IS_REQUIRED": "Förnamn är en obligatorisk uppgift.",
  "Fixed income": "Räntor",
  "Fixed Income": "Räntor",
  "Flexible Fixed Income Strategies": "Flexibla räntestrategier",
  "Flexible interest rate strategies": "Flexibla räntestrategier",
  "FOR_SERVICES_OF_ELITE": "Till Elite Alfred Bergs tjänster",
  "FOR_TRANSACTION": "transaktionen",
  "FOR_WHOLE_WEALTH": "Hela förmögenheten",
  "FOREKNOWLEDGE_OF_DISTANCE_SELLING_AND_SERVICES": "Preliminär information om distansförsäljning och tjänster",
  "FORGOT_YOUR_ACCOUNT": "Glömt inloggningsuppgifter",
  "FOUND_CUSTOMERS_WITH_THE_GIVEN_IDENTIFICATION": "Kunderna hittades med den gjorda autentiseringen",
  "FOUND_USERS_WITH_BANK_ACCOUNT": "Användare som hittades med online-identifieringen",
  "FRANCE": "Frankrike",
  "FREE_RANGE": "Fritt val",
  "FRENCH GUIANA": "Franska Guyana",
  "FRENCH POLYNESIA": "Franska Polynesien",
  "FRENCH SOUTHERN TERRITORIES": "Franska södra territorierna",
  "FREQUENT_PAYMENT": "Återkommande betalning",
  "FROM": "Från",
  "FROM_BEGINNING": "Från början",
  "FROM_BEGINNING_OF_CONTRACT": "Från början",
  "FROM_BEGINNING_OF_CONTRACT_ANNUAL": "Från portföljens start, årlig",
  "FROM_BEGINNING_OF_YEAR": "Från början av året",
  "FROM_PORTFOLIO": "från portfölj",
  "FUND": "Fond",
  "FUND_BASIC_INFORMATION": "Grunduppgifter",
  "FUND_FAMILY": "Fondfamilj",
  "FUND_KIID_MUST_BE_ACCEPTED_BEFORE_CONTINUING": "Du måste godkänna innan du kan fortsätta",
  "FUND_MUST_BE_SELECTED": "Du måste välja fond",
  "FUND_MUST_BE_SELECTED_FROM_LIST": "Du måste välja fond på listan",
  "FUND_OF_FUNDS": "Fond-i-fonder",
  "FUND_SEARCH": "Fonder",
  "FUND_SEARCH_LINK": "Bekanta dig med fondurvalet",
  "FUND_SEARCH_TOOLTIP_CURRENCY": "Fondens teckningsvaluta Elite Kapitalförvaltning gör de nödvändiga valutaaffärerna automatiskt för dig.",
  "FUND_SEARCH_TOOLTIP_PROFIT_ONE_MONTH": "Månatlig förändring av fondandelen i euro i procent.",
  "FUND_SEARCH_TOOLTIP_PROFIT_ONE_YEAR": "Årlig förändring av fondandelens värde i euro i procent.",
  "FUND_SEARCH_TOOLTIP_VALUE": "Noterat pris för fondandelen i teckningsvalutan.",
  "FUND_TO_CLAIM": "Fond som ska inlösas",
  "FUND_TO_MARK": "Fond som ska tecknas",
  "FUNDS_NON_UCITS": "Fonder (icke-UCITS)",
  "FUNDS_TOTAL_FOUND": "fonder. Totalt hittades",
  "FUNDS_UCITS": "Fonder (UCITS)",
  "GABON": "Gabon",
  "GAMBIA": "Gambia",
  "GENERAL": "Allmänt",
  "GENERAL_CONTRACT_TERMS": "Allmänna avtalsvillkor",
  "CONTRACT_TERMS_IMPORTANT_INFO": "Villkor och viktig information",
  "CONTRACT_CUSTOMER_INFO": "Kundinformation",
  "PERSONAL_DATA_PROCESSING": "Om personuppgifter",
  "ACCESSIBILITY_STATEMENT": "Tillgänglighet",
  "CUSTOMER_SERVICE_OPENING_HOURS": "kl 9.00-17.00",
  "GENERAL_INVESTMENT_PURSUIT_OF_PROFIT": "Allmän placeringsverksamhet (eftersträvar avkastning)",
  "GEORGIA": "Georgien",
  "GERMANY": "Tyskland",
  "GENERAL_SETTINGS": "Allmänna inställningar",
  "GET_USERID": "Skapa koder",
  "GHANA": "Ghana",
  "GIBRALTAR": "Gibraltar",
  "GIFT": "Gåva",
  "GIVE_YOUR_NEW_PASSWORD": "Ange ditt nya lösenord i fälten nedan och sänd information.",
  "GIVE_YOUR_PASSWORD": "Ange ditt lösenord i fälten nedan.",
  "Global": "Världen",
  "GO_TO_CUSTOMER_SERVICE_PAGE": "Gå till sidan med kundtjänst",
  "YOU_WILL_BE_REDIRECTED": "Du omdirigeras",
  "TO_CUSTOMER_SERVICE_PAGE": "till kundtjänstsidan.",
  "TO_TAX_LIABILITY_PAGE": "till skatteskyldighet-sidan.",
  "TO_CUSTOMER_INFO_PAGE": "till kunduppgifter-sidan.",
  "Government bonds": "Statsobligationer",
  "Government Bonds": "Statsobligationer",
  "GREECE": "Grekland",
  "GREENLAND": "Grönland",
  "GRENADA": "Grenada",
  "GROSS_SALARIES": "Bruttolön",
  "GROUPING_AND_ADMINISTRATION": "Gruppering och administration",
  "GUADELOUPE": "Guadeloupe",
  "GUAM": "Guam",
  "GUATEMALA": "Guatemala",
  "GUERNSEY": "Guernsey",
  "GUINEA": "Guinea",
  "GUYANA": "Guyana",
  "HAITI": "Haiti",
  "Hallinto- ja tukipalvelut liike-elämälle": "Kontorstjänster och andra företagstjänster",
  "HEADER_DANGER": "Fel!",
  "HEADER_SAVED": "Sparat!",
  "HEARD ISLAND AND MCDONALD ISLANDS": "Heard-och McDonaldsöarna",
  "Hedge funds": "Hedgefonder",
  "Hedge Funds": "Hedgefonder",
  "HEDGEFUND": "Hedgefond",
  "HERE": "här",
  "HISTORICAL_PROFIT_IS_NOT_GUARANTEE_FOR_FUTURE_PROFIT": "Historisk avkastning är ingen garanti för framtida avkastning.",
  "HOLDINGS": "Innehav",
  "HOLDINGS_DISTRIBUTION": "Allokering",
  "HOLDINGS_DISTRIBUTION_HISTORY": "Historisk portföljfördelning",
  "HOLY SEE (VATICAN CITY STATE)": "Vatikanen",
  "HONDURAS": "Honduras",
  "HONG KONG": "Hongkong",
  "HUNGARY": "Ungern",
  "Huonekalujen valmistus": "Tillverkning av möbler",
  "I_AM_GENERALLY_A_TAXPAYER_IN_ANOTHER_COUNTRY_THAN_FINLAND": "Jag är allmänt skattskyldig i ett annat land än Finland",
  "I_HAVE_KNOWLEDGE_OF_THE_FOLLOWING_INSTRUMENTS": "Jag känner till följande finansiella instrument",
  "I_HAVE_READ_FUND_KIID": "Jag har bekantat mig med fondens prospekt, faktablad samt med fondens stadgar.",
  "I_HAVE_READ_GENERAL_INVESTMENT_SERVICE_TERMS": "Jag har läst",
  "I_HAVE_READ_GENERAL_INVESTMENT_SERVICE_TERMS_2": "de allmänna villkoren",
  "I_HAVE_READ_GENERAL_INVESTMENT_SERVICE_TERMS_3": "för placeringstjänster och jag godkänner dem som bindande för mig.",
  "I_HAVE_READ_SERVICE_RATES": "Jag har läst",
  "I_HAVE_READ_SERVICE_RATES_2": "serviceprislistan",
  "I_HAVE_READ_SERVICE_RATES_3": "och godkänner den.",
  "I_SIGN_THE_CONTRACT": "Jag försäkrar att uppgifterna ovan är korrekta och jag undertecknar avtalet.",
  "ICELAND": "Island",
  "ID": "Kod",
  "IDENTIFICATION_INFORMATION": "Inloggningsuppgifter",
  "IDENTIFY": "Identifiera dig",
  "IDENTIFY_WITH_BANK_ACCOUNTS": "Välj bank",
  "IDENTIFY_TO_MODIFY_INFORMATION": "Identifiera dig",
  "IDENTIFYING_CANCELLED": "Online-identifiering avbröts",
  "IDENTIFYING_DIDNT_SUCCESS": "Online-identifieringen antingen lyckades inte eller har inte gjorts, försök på nytt genom att gå tillbaka till Tupas-sidan",
  "IDENTIFYING_FAILED_PLEASE_TRY_AGAIN": "Hej, Online-identifieringen misslyckades. Försök igen eller gå tillbaka till huvudsidan",
  "IDENTIFYING_IS_CANCELLED": "Online-identifieringen avbröts mitt i, var god och försök igen",
  "IDENTIFYING_IS_REJECTED": "Online-identifieringen avslogs, var god och kontrollera uppgifterna och försök igen",
  "IDENTIFYING_REJECTED": "Online-identifiering avbröts",
  "IDENTIFYING_FAILED": "Autentikeringen misslyckades.",
  "IDENTIFYING_SUCCEEDED_YOUR_ACCOUNT_IS": "Online-identifieringen lyckades. Ditt användarnamn är",
  "IDENTIFYING_SUCCESS_YOUR_ACCOUNT_IS": "Användare för vilken lösenordet återställs: ",
  "IDENTIFYING_SUCCESS_YOUR_CONTACT_IS": "Kund för vilken nytt användarnamn skapas",
  "IF_ANOTHER_WHAT": "Om annat, vad?",
  "IF_ANY_QUESTIONS": "Har du frågor? Din egen bankir betjänar dig.",
  "IF_YOU_CANT_FIND_FUND_TRY": "Om du inte hittar den önskade fonden, prova på",
  "IF_YOU_HAVE_FORGOTTEN_ACCOUNTS": "Om du har glömt ditt användarnamn eller lösenord, kan du med online-identifiering söka ett befintligt användarnamn och/eller byta till ett nytt lösenord",
  "Ilmaliikenne": "Lufttransport",
  "INDEXED_PROFIT_CHART_OF_PORTFOLIO": "Indexerad avkastningsutveckling för tillgångarna",
  "INDEXLINKEDBONDFUND": "Realobligationsfond ",
  "INDIA": "Indien",
  "INDONESIA": "Indonesien",
  "INDUSTRY": "Bransch",
  "INDUSTRY_IS_REQUIRED": "Bransch är en obligatorisk uppgift.",
  "Inflation-linked": "Inflationslänkade",
  "INFO": "Info",
  "INHERITANCE": "Arv",
  "INPUTTING_ADDITIONAL_INFOMATION": "Skriva in ytterligare information",
  "INPUTTING_INVESTMENT_EXPERIENCE_INFORMATION": "Skriva in information om placeringserfarenhet",
  "INSTRUCTIONS": "Instruktion",
  "INSTRUCTIONS_FOR_MONTHLY_DEPOSIT": "Månadssparande kräver inte ett separat avtal med Elite Alfred Berg eller din kontobank, utan du kan definiera en återkommande betalning direkt i din banks nätbank. Ange nedanstående information på betalningsblanketten i din banks nätbank och välj att betalningen ska upprepas till exempel månatligen när du skriver in den. När du betalar beloppet kommer motsvarande summa att sparas på ditt fondkonto, varefter systemet automatiskt gör en teckning i den valda fonden till motsvarande belopp.",
  "INSTRUMENT_KNOWLEDGE": "Kännedom om finansiella instrument",
  "INSTRUMENTS_NAME": "Instrumentnamn",
  "INSTRUMENTS_NAME_AND_QUANTITY": "Överlåtet värdepapper/överlåten värdeandel och antal",
  "Inverse": "Inverterade",
  "INVESTMENT_ADVICE": "Investeringsrådgivning",
  "INVESTMENT_AREA": "Placeringsområde",
  "INVESTMENT_EXPERIENCE": "Placeringserfarenhet",
  "INVESTMENT_EXPERIENCE_AND_KNOWLEDGE": "Investeringserfarenhet- och kännedom",
  "INVESTMENT_EXPERIENCE_EXPLANATION": "Lagen förpliktar oss också att fråga om placeringserfarenhet och -kännedom.",
  "INVESTMENT_EXPERIENCE_IS_REQUIRED": "Placeringserfarenhet är en obligatorisk uppgift",
  "INVESTMENT_EXPERIENCE_MAXIMUM": "Placeringserfarenheten kan vara högst 100 år.",
  "INVESTMENT_EXPERIENCE_MINIMUM": "Placeringserfarenheten måste vara minst 0.",
  "INVESTMENT_EXPERIENCE_MUST_BE_A_NUMBER": "Placeringserfarenheten måste vara ett tal.",
  "INVESTMENT_EXPERIENCE_MUST_BE_AN_INTEGER": "Placeringserfarenheten måste vara ett heltal.",
  "INVESTMENT_GOALS": "Placeringsmål",
  "INVESTMENT_GOALS_EXPLANATION": "Välj den riskkategori som bäst motsvarar dina placeringsmål.",
  "INVESTMENT_INCOME_EG_DIVIDEND_INCOME_RENT_INCOME": "Inkomster från placeringar (t.ex. dividendintäkter, hyresintäkter)",
  "INVESTMENT_MONITORING": "Uppföljning av placeringar",
  "INVESTMENT_MONITORING_IS_REQUIRED": "Uppföljning  av placeringar är en obligatorisk uppgift.",
  "INVESTMENT_POLICY": "Placeringspolitike",
  "INVESTMENT_SALES": "Försäljning av placeringar",
  "INVESTMENT_SECTOR": "Placeringssektor",
  "INVESTMENT_STYLE": "Placeringsstil",
  "INVESTMENT_WEALTH": "Placeringsförmögenhet",
  "INVESTMENT_WEALTH_AMOUNT": "Placeringsförmögenhet",
  "INVESTMENT_WEALTH_IS_REQUIRED": "Placeringsförmögenhet är en obligatorisk uppgift.",
  "INVESTMENT_WEALTH_MUST_BE_A_NUMBER": "Investeringsförmögenheten måste vara ett tal.",
  "INVESTMENT_WEALTH_MUST_BE_POSITIVE": "Placeringsförmögenheten måste vara ett positivt tal.",
  "IRAN, ISLAMIC REPUBLIC OF": "Iran",
  "IRAQ": "Irak",
  "IRELAND": "Irland",
  "IS": "är",
  "IS_INVALID": "är felaktigt",
  "FILE": "Fil",
  "IS_TOO_HIGH": "är för stor",
  "IS_TOO_LOW": "är för liten",
  "IS_TOO_LARGE": "är för stor",
  "TYPE_A_NUMBER": "ange siffra",
  "ON_WEEKDAYS": "(mån - fre)",
  "MAX_TWO_DECIMALS": "Ange siffra med max. två decimaler.",
  "MAX_N_DECIMALS_1": "Ange belopp max.",
  "MAX_N_DECIMALS_2": "decimals noggrannhet.",
  "IS_REQUIRED": "är en obligatorisk uppgift",
  "ISLE OF MAN": "Isle of Man",
  "ISRAEL": "Israel",
  "ITALY": "Italien",
  "ITS_EASY_TO_OPEN_ACCOUNT": "Det är enkelt att öppna ett konto. Du kan göra det antingen med online-identifiering eller alternativt genom att beställa avtalspaketet per post",
  "JAMAICA": "Jamaica",
  "Japan": "Japan",
  "JAPAN": "Japan",
  "JERSEY": "Jersey",
  "JORDAN": "Jordanien",
  "Julkinen hallinto ja maanpuolustus, pakollinen sosiaalivakuutus": "Offentlig förvaltning och försvar; obligatorisk socialförsäkring",
  "Juomien valmistus": "Framställning av drycker",
  "Järjestöjen toiminta": "Intressebevakning; religiös verksamhet",
  "Jätteen keruu, käsittely ja loppusijoitus, materiaalien kierrätys": "Avfallshantering; återvinning",
  "Kaivostoimintaa palveleva toiminta": "Service till utvinning",
  "Kalastus ja vesiviljely": "Fiske och vattenbruk",
  "Kansainvälisten organisaatioiden ja toimielinten toiminta": "Verksamhet vid internationella organisationer, utländska ambassader o.d.",
  "Kasvinviljely ja kotieläintalous, riistatalous ja niihin liittyvät palvelut": "Jordbruk och jakt samt service i anslutning härtill",
  "KAZAKHSTAN": "Kazakstan",
  "Kemikaalien ja kemiallisten tuotteiden valmistus": "Tillverkning av kemikalier och kemiska produkter",
  "KENYA": "Kenya",
  "keskiaste": "Mellanstadiet",
  "Key Investor Information Document (KIID)": "KID",
  "Kiinteistöalan toiminta": "Fastighetsverksamhet",
  "Kiinteistön- ja maisemanhoito": "Fastighetsservice samt skötsel och underhåll av grönytor",
  "KIRIBATI": "Kiribati",
  "Kirjastojen, arkistojen, museoiden ja muiden kulttuurilaitosten toiminta": "Biblioteks-, arkiv- och museiverksamhet m.m.",
  "Kivihiilen ja ruskohiilen kaivu": "Kolutvinning",
  "Koksin ja jalostettujen öljytuotteiden valmistus": "Tillverkning av stenkolsprodukter och raffinerade petroleumprodukter",
  "Koneiden ja laitteiden korjaus, huolto ja asennus": "Reparation och installation av maskiner och apparater",
  "KOREA, REPUBLIC OF": "Sydkorea",
  "Kotitalouksien eriyttämätön toiminta": "Hushållens produktion av diverse tjänster för eget bruk",
  "Kotitalouksien toiminta kotitaloustyöntekijöiden työnantajina": "Förvärvsarbete i hushåll",
  "Koulutus": "Utbildning",
  "Kulttuuri- ja viihdetoiminta": "Konstnärlig och kulturell verksamhet samt nöjesverksamhet",
  "Kumi- ja muovituotteiden valmistus": "Tillverkning av gummi- och plastvaror",
  "Kustannustoiminta": "Förlagsverksamhet",
  "KUWAIT": "Kuwait",
  "KYRGYZSTAN": "Kirgizistan",
  "Käsitelty": "Behandlad",
  "Käsittelyssä": "Behandlas",
  "Käteinen": "Kontanter",
  "Lakiasiain- ja laskentatoimen palvelut": "Juridisk och ekonomisk konsultverksamhet",
  "Landline": "Från det fasta nätet",
  "LANGUAGE_SELECTION": "Språkval",
  "LANGUAGE": "Språk",
  "WEB_SERVICE_LANGUAGE": "Webbtjänstens språk",
  "LANGUAGE_POPUP_TEXT": "Användargränssnittet för EAB Nättjänst är tillgängligt även på engelska och svenska. Det huvudsakliga betjäningsspråket hos Elite Alfred Berg är ändå finska och det har därmed ingen skyldighet at erbjuda tjänster på andra språk. Fondernas faktablad är tillgängliga på finska och svenska. Tillgängligheten av fondmaterial på övriga språk kan inte garanteras.",
  "LAST_NAME": "Efternamn",
  "LAST_NAME_IS_REQUIRED": "Efternamn är en obligatorisk uppgift.",
  "LATVIA": "Lettland",
  "LAW_FOR_MONEY_LAUNDERING_AND_TERRORISM": "Lagen om förhindrande och utredning av penningtvätt och av finansiering av terrorism förpliktar oss att fråga våra kunder om de placerade tillgångarnas ursprung.",
  "LEBANON": "Libanon",
  "LESOTHO": "Lesotho",
  "LESS_THAN": "Mindre än",
  "LESS_THAN_ANNUALLY": "Mindre än en gång om året",
  "LIABILITIES": "Skulder",
  "LIBERIA": "Liberia",
  "LIBYAN ARAB JAMAHIRIYA": "Libyen",
  "LIECHTENSTEIN": "Liechtenstein",
  "LITHUANIA": "Litauen",
  "LOADING_ASSET_CHANGES": "Laddar förmögenhetsförändring",
  "LOADING_ASSET_SUMMARY": "Helhetstillgångarna laddas ner",
  "LOADING_ACCOUNTS": "Laddar uppgifter om klientskap",
  "LOADING_BALANCE": "Laddar saldo",
  "LOADING_BANKER_INFO": "Laddar bankir information", 
  "LOADING_FEE_SUMMARY": "Laddar arvodesöversikt",
  "LOADING_FILE": "Laddar fil",
  "LOADING_FUNDS": "Laddar fonder",
  "LOADING_FUND": "Laddar fond",
  "LOADING_FUNDS_FAILED": "Kunde inte ladda fonder.",
  "LOADING_GAINS_AND_LOSSES": "Laddar realiserade vinster/förluster",
  "LOADING_HOLDINGS": "Laddar innehav",
  "LOADING_INSTRUMENT_DOCUMENTS": "Fondens dokument laddas",
  "LOADING_INFORMATION": "Laddar data",
  "LOADING_TAXREPORT": "Laddar skatterapport",
  "LOADING_HOLDINGS_DISTRIBUTION": "Laddar fördelning av innehav",
  "LOADING_HOLDINGS_DISTRIBUTION_HISTORY_CHART": "Laddar historisk distribution av innehav",
  "LOADING_HOLDINGS_FAILED": "Kunde inte ladda innehav.",
  "LOADING_TAXREPORT_FAILED": "Laddar indexerad avkastningsutveckling för portföljen",
  "LOADING_INDEXED_PROFIT_CHART_OF_PORTFOLIO": "Laddar portföljens indexerade avkastningsutveckling",
  "LOADING_PORTFOLIOS": "Laddar avkastningöversikt",
  "LOADING_NEWSLETTERS": "Laddar nyhetsbrev",
  "LOADING_NOTES": "Laddar meddelanden",
  "LOADING_RULES": "Laddar stadgar",
  "LOADING_RULES_AND_NOTES": "Laddar stadgar och meddelanden",
  "LOADING_CALCS": "Laddar kalkyl ",
  "LOADING_MIDYEAR": "Laddar bonus",
  "LOADING_SHARES": "Laddar andelar",
  "LOADING_PROFIT_SUMMARY": "Laddar värden för jämförelseindex",
  "LOADING_REFERENCE_INDEX_VALUES": "Laddar jämförelseindex.",
  "LOADING_REFERENCE_INDICES": "Kunde inte ladda jämförelseindex.",
  "LOADING_REFERENCE_INDICES_FAILED": "Det gick inte att ladda jämförelseindex",
  "LOADING_RISK_DISTRIBUTION": "Laddar riskfördelning",
  "LOADING_SUMMARY": "Laddar översikt",
  "LOADING_TEN_LARGEST_INSTRUMENTS": "Laddar 10 största placeringar",
  "LOADING_TRANSACTIONS": "Laddar transaktioner",
  "UPDATING_TRANSACTIONS": "Uppdateringen transaktioner",
  "LOADING_TRANSACTIONS_FAILED": "Kunde inte ladda transaktioner.",
  "LOADING_USER_INFO": "Laddar kund information",
  "LOADING_VALUE_CHART": "Laddar värden",
  "LOADING_VALUE_CHART_FAILED": "Kunde inte ladda värden.",
  "LOAN": "Lån",
  "LOCKED_SHARES": "låsta andelar",
  "LOG_OUT": "Logga ut",
  "LOGIN_EXTRA_CONFIRMATION": "Ytterligare bekräftelse för inloggning",
  "LOGIN_INFO": "Inloggningsuppgifter",
  "LOGIN_INFORMATION_CHANGED": "Användarnamn och lösenord har ändrats.",
  "LOGIN_PERSONNEL_FUND": "Logga in till personalfondens webtjänst",
  "LOGIN_PERSONNEL_FUND_INFO": "På webtjänsten ser du all information om fonden, dina egna uppgifter, din egen fondandel och ändringar i den.  Du kan även göra alla nödvändiga meddelanden och ändra dina personuppgifter via webtjänsten.",
  "LOGGING_IN": "Logga in, en stund ...",
  "LOSSES_TOGETHER": "Förluster totalt",
  "LUXEMBOURG": "Luxemburg",
  "Lääkeaineiden ja lääkkeiden valmistus": "Tillverkning av farmaceutiska basprodukter och läkemedel",
  "Maa- ja vesirakentaminen": "Mark- och vattenbyggnad",
  "Maaliikenne ja putkijohtokuljetus": "Landtransport; transport i rörsystem",
  "Maaperän ja vesistöjen kunnostus ja muut ympäristönhuoltopalvelut": "Sanering, efterbehandling av jord och vatten samt annan verksamhet för föroreningsbekämpning",
  "MACAO": "Macao",
  "MACEDONIA, THE FORMER  YUGOSLAV REPUBLIC OF": "Republiken Makedonien",
  "MADAGASCAR": "Madagaskar",
  "MAIN_CLASS": "Huvudkategori",
  "Mainostoiminta ja markkinatutkimus": "Reklam och marknadsundersökning",
  "Majoitus": "Hotell- och logiverksamhet",
  "MALAWI": "Malawi",
  "MALAYSIA": "Malaysia",
  "MALDIVES": "Maldiverna",
  "MALI": "Mali",
  "MALTA": "Malta",
  "MANAGEMENT_FEE": "Förvaltningsarvode",
  "MANDATORY_FIELD": "Obligatoriskt fält",
  "MARK": "Teckna",
  "MARK_AGAIN_CLAIMED_FUNDS": "Markera de tillgångar som återinlösts",
  "MARK_TO_PORTFOLIO": "att teckna i portfölj",
  "MARKET_VALUE": "Marknadsvärde",
  "MARSHALL ISLANDS": "Marshallöarna",
  "MARTINIQUE": "Martinique",
  "Matkatoimistojen ja matkanjärjestäjien toiminta varauspalvelut": "Resebyrå- och researrangörsverksamhet och andra resetjänster och relaterade tjänster",
  "MAURITANIA": "Mauretanien",
  "MAURITIUS": "Mauritius",
  "MAXIMUM_100": "högst 100 %",
  "MAYOTTE": "Mayotte",
  "MERGE": "Fusion",
  "Metallien jalostus": "Stål- och metallframställning",
  "Metallimalmien louhinta": "Utvinning av metallmalmer",
  "Metallituotteiden valmistus (pl. koneet ja laitteet)": "Tillverkning av metallvaror utom maskiner och apparater",
  "Metsätalous ja puunkorjuu": "Skogsbruk",
  "MEXICO": "Mexiko",
  "MICRONESIA, FEDERATED STATES OF": "Mikronesien",
  "MIFID_EXPLANATION": "Lagen om förhindrande och utredning av penningtvätt och av finansiering av terrorism förpliktar oss att be våra kunder om följande information.",
  "MIN_MARK_FOR_FUND": "Den minsta teckningen för fonden är",
  "MINIMUM": "(minimum)",
  "MINIMUM_0": "minst 0",
  "MINIMUM_1": "minst 1",
  "MINIMUM_25": "minst 25 %",
  "MINIMUM_INVESTMENT": "Minimiteckning",
  "MINIMUM_INVESTMENT_AND_FEES": "Minimiplacering och arvoden",
  "MINIMUM_INVESTMENT_AT_MOST": "Minimiplacering högst",
  "MINIMUM_INVESTMENT_TIME": "Minimiplacering",
  "MINIMUM_REDEMPTION_FEE": "Minsta inlösenavgift",
  "MINIMUM_SUBSCRIPTION_FEE": "Minsta teckningsarvode",
  "Mobile": "Med mobiltelefon",
  "MODERATE": "Måttlig",
  "MODIFY": "Redigera",
  "MODIFY_INFORMATION": "Ändra information",
  "MODIFYING_USER_INFO": "Redigera information",
  "MOLDOVA": "Moldavien",
  "MONACO": "Monaco",
  "Money Market": "Penningmarknaden",
  "MONGOLIA": "Mongoliet",
  "MONTENEGRO": "Montenegro",
  "MONTHLY": "Månatligen",
  "MONTHLY_EXPEDITURE": "Regelbundna finansiella åtaganden",
  "MONTHLY_REPORT": "Månadsrapport",
  "MONTHLY_REPORTS": "Månadsrapporter",
  "MONTHLY_REPORTS_OWNED": "Månadsrapporter om ägda fonder",
  "TO_MONTHLY_REPORTS": "Se månadsrapporter för alla aktieserier här",
  "MONTHS": "månader",
  "MONTSERRAT": "Montserrat",
  "Moottoriajoneuvojen tukku- ja vähittäiskauppa sekä korjaus": "Parti- och detaljhandel med samt reparation av motorfordon och motorcyklar",
  "Moottoriajoneuvojen, perävaunujen ja puoliperävaunujen valmistus": "Tillverkning av motorfordon, släpfordon och påhängsvagnar",
  "MORE": "Mer",
  "MORE_THAN": "Över",
  "MORE_OPTIONS": "Fler alternativ",
  "MORNINGSTAR": "Morningstar",
  "MORNINGSTAR_RATING": "Morningstar Rating",
  "MOROCCO": "Marocko",
  "MOZAMBIQUE": "Mocambique",
  "Muiden ei-metallisten mineraalituotteiden valmistus": "Tillverkning av andra icke-metalliska mineraliska produkter",
  "Muiden koneiden ja laitteiden valmistus": "Tillverkning av övriga maskiner",
  "Muiden kulkuneuvojen valmistus": "Tillverkning av andra transportmedel",
  "MUST_BE_A_NUMBER": "måste vara ett tal",
  "MUST_BE_GIVEN": "måste anges",
  "MUST_BE_NUMERIC": "måste vara numeriskt",
  "Muu kaivostoiminta ja louhinta": "Annan utvinning av mineral",
  "Muu valmistus": "Annan tillverkning",
  "Muut erikoistuneet palvelut liike-elämälle": "Annan specialiserad konsultverksamhet till företag",
  "Muut henkilökohtaiset palvelut": "Andra konsumenttjänster",
  "MYANMAR": "Myanmar",
  "Nahan ja nahkatuotteiden valmistus": "Tillverkning av läder, läder- och skinnvaror m.m.",
  "NAME": "Namn",
  "NAME_YOUR_OWN_PORTFOLIOS": "Namnge de egna portföljerna",
  "NAME_YOUR_PORTFOLIOS_TO_FIND_THEM_EASIER": "Du kan byta namnet på dina portföljer för att hitta dem lättare i portföljvyn.",
  "NAMIBIA": "Namibia",
  "NAURU": "Nauru",
  "NEPAL": "Nepal",
  "NET_PROFIT": "Rörelseresultat",
  "NETHERLANDS": "Nederländerna",
  "NETHERLANDS ANTILLES": "Nederländska Antillerna",
  "NEWSLETTERS": "Nyhetsbrev och utgivningar",
  "NEW CALEDONIA": "Nya Kaledonien",
  "NEW ZEALAND": "Nya Zeeland",
  "NEW_CUSTOMER": "Ny kund",
  "NEW_DEPOSIT": "Skapa ny deposition",
  "NEW_MONTHLY_SAVING": "Skapa ny månatlig besparing",
  "NEW_PASSWORD": "Nytt lösenord",
  "NEW_PASSWORD_MATCHES_OLD": "Det nya lösenordet är lika med det gamla lösenordet",
  "NEW_PASSWORD_UPDATED": "Ditt lösenord har uppdaterats.",
  "NEW_REDEMPTION": "Skapa ny inlösen",
  "NEW_SUBSCRIPTION": "Skapa ny teckning",
  "NEW_WITHDRAW": "Gör ett nytt uttag",
  "NEW_ORDER_PORTFOLIO_CASHBALANCE": "Portföljens saldo / Disponibelt",
  "NEW_ORDER_ESTIMATED_CASH_FROM_OPEN_SELLS": "Uppskattning om inlösen inkommande kontanter",
  "NEW_ORDER_TOTAL_AVAILABLE_CASH": "Disponibla kontanter",
  "NEW_ORDER_ESTIMATE": "uppskattning",
  "NEW_ORDER_PRECISE_SUM_WHEN_CONFIRMED": "Summan fastställs vid bekräftelse av inlösen",
  "NEW": "Ny",
  "Nosto Elite Varainhoidon verkkopalvelusta": "Uttag från Elite Alfred Bergs webbtjänst",
  "NEXT": "Nästa",
  "NICARAGUA": "Nicaragua",
  "NIGER": "Niger",
  "NIGERIA": "Nigeria",
  "NIUE": "Niue",
  "NO": "Nej",
  "NO_CONTACTS_FOUND": "Kunde inte hitta någon kund med dessa inloggningsuppgifter. Du kan gå tillbaka till identifieringen",
  "NO_FEES_FOUND": "Kunde inte hitta arvoden.",
  "NO_FUNDS_FOUND": "Kunde inte hitta fonder med de angivna sökvillkoren.",
  "NO_HOLDINGS_FOUND": "Kunde inte hitta innehav.",
  "NO_HOLDINGS_FOR_TAXREPORT": "Inga överlåtelsevinster/förluster under skatteperioden.",
  "NO_INSTRUMENT_DOCUMENTS_FOUND": "Fondens dokument hittades inte.",
  "NO_NEWSLETTERS": "Inga meddelanden...",
  "NO_NEWSLETTERS_FOUND": "Inga meddelanden hittades.",
  "NO_PORTFOLIOS_FOUND_FOR_WHICH_THIS_OPERATION_IS_ALLOWED": "Du har inte en enda portfölj för vilken denna funktion är godkänd.",
  "NO_PREVIOUS_INVESTMENT_EXPERIENCE": "Saknar placeringskunskaper/-erfarenhet",
  "NO_PROFITS_FOUND": "Kunde inte hitta avkastningar.",
  "NO_TRANSACTIONS_FOUND": "Kunde inte hitta transaktioner med de angivna sökvillkoren.",
  "NO_VALUES_FOUND_WITH_THE_GIVEN_DATE_RANGE": "Kunde inte hitta värdena under den valda perioden. Välj en kortare granskningsperiod, fritt val eller från början",
  "NO_VALUES_FOUND_WITH_THE_DATE_RANGE": "Kunde inte hitta värdena under den valda perioden.",
  "Nordic Countries": "Norden",
  "NORFOLK ISLAND": "Norfolkön",
  "North America": "Nordamerika",
  "NORTHERN MARIANA ISLANDS": "Nordmarianerna",
  "NORWAY": "Norge",
  "Nosto": "Uttag",
  "NOT_AVAILABLE": "Ej tillgänglig.",
  "NOT_DATE": "Är inte ett datum",
  "SELECT_EARLIER_DATE": "Välj ett tidigare datum",
  "SELECT_LATER_DATE": "Välj ett senare datum",
  "PORTFOLIO_START_DATE": "Startdatum på portföljen",
  "DATE_FORMAT": "dd.mm.åååå",
  "LOADING_MORE_INFO": "Ytteligare information laddas ner...",
  "ERROR_WHILE_LOADING_CASH_INFO": "Något gick fel i behandlingen av din begäran. Information om kontokontanter hittas inte.",
  "NOT_ENOUGH_CASH_FOR_SUBSCRIPTION_MAKE_DEPOSITION": "Disponibla kontanter räcker inte för teckningen. Teckningsordern verkställs, när det finns tillräckligt med kontanter på kontot för teckningen. För att teckningsorderna ska kunna verkställas, överför minst",
  "NOT_ENOUGH_CASH_FOR_SUBSCRIPTION_MAKE_DEPOSITION_ENDING": "till kontot.",
  "NOT_ENOUGH_CASH_FOR_SUBSCRIPTION_MAKE_DEPOSITION_PLURAL": "Disponibla kontanter räcker inte för teckningarna. Teckningsorderna verkställs, när det finns tillräckligt med kontanter på kontot för teckningarna. För att teckningsorderna ska kunna verkställas, överför minst",
  "NOT_ENOUGH_CASH_FOR_SUBSCRIPTION_MAKE_DEPOSITION_PLURAL_ENDING": "till kontot.",
  "NOT_ENOUGH_CASH_FOR_SUBSCRIPTION": "Disponibla kontanter räcker inte för teckningen. Teckningsordern verkställs, när det finns tillräckligt med kontanter på kontot för teckningen.",
  "NOT_ENOUGH_CASH_FOR_SUBSCRIPTION_ADD_MORE": "Disponibla kontanter räcker inte för teckningen.",
  "NOT_SELECTED": "Ej valt",
  "NOT_YET_CUSTOMER": "Inte kund ännu? Bli kund gratis.",
  "NOTHING": "Inget",
  "NOW": "Nu",
  "NUMBER_OF_SHARES": "Antal",
  "OCCUPATION": "Yrke",
  "OCCUPATION_IS_REQUIRED": "Yrke är en obligatorisk uppgift.",
  "Odottaa käsittelyä": "Väntar på behandling",
  "OH_SNAP": "Vad synd",
  "Ohjelmistot, konsultointi ja siihen liittyvä toiminta": "Program, konsultering och tillhörande verksamhet",
  "OK": "Ok",
  "OLD_PORTFOLIOS": "Stängda portföljer",
  "OMAN": "Oman",
  "Omien kiinteistöjen kauppa": "Handel med egna fastigheter",
  "Omien tai leasing-kiinteistöjen vuokraus ja hallinta": "Uthyrning och förvaltning av egna eller arrenderade fastigheter",
  "ONE_MONTH_SHORT": "1 mån",
  "ONE_YEAR_SHORT": "1 år",
  "TWO_YEARS_SHORT": "2 år",
  "ONLINE_SERVICE": "Nättjänst",
  "ONLY_FOR_HOMELAND_TRANSFER": "Endast inhemska betalningar",
  "ONLY_IF_ASKED": "Fyll i endast på begäran",
  "OPENED": "Lediga",
  "ONLINEID": "Online-autentisering",
  "OPEN_MONEY_TRANSACTIONS": "Öppna valutatransaktioner",
  "OPTIONAL_EMAIL": "Valfri e-post",
  "OPTIONAL_INFORMATION": "Valfri information",
  "ORDERS": "Uppdrag",
  "ORDER_NUMBER": "Uppdrag nummer",
  "ORDER_NEWSLETTERS": "Genom att beställa nyhetsbrevet och notiserna får du till din e-post våra experters färska syn på marknaderna, samt penningvärda råd om skötseln av din förmögenhet.",
  "ORDER_NEWSLETTERS_LANGUAGE": "Nyhetsbrevet och utgivningarna är endast tillgängliga på finska.",
  "ORDER_SELECTIONS": "Prenumerationsalternativ",
  "ORGANIZATION_NAME": "Organisationens namn",
  "ORGANIZATION_NAME_IS_REQUIRED": "Organisationsnamn krävs",
  "ORIGIN_OF_ASSETS": "Tillgångarnas ursprung",
  "ORIGIN_OF_FUNDS": "Tillgångarnas ursprung",
  "ORIGIN_OF_FUNDS_IS_REQUIRED": "Tillgångarnas ursprung är en obligatorisk uppgift.",
  "ORIGIN_OF_INVESTMENT_ASSETS": "Ursprunget av de placerade tillgångarna",
  "Osakerahasto": "Aktiefond",
  "OTHER": "Annat",
  "Other": "Övriga",
  "Other bonds": "Övriga ränteplaceringar",
  "Other Fixed Income Instruments": "Övriga ränteplaceringar",
  "Other Strategies": "Andra strategier",
  "Other unclassified instruments etc.": "Övriga såsom icke-klassificerade instrument",
  "OTHER_DESCRIPTION": "Annan beskrivning",
  "OTHERS": "Övriga",
  "OVER": "Över",
  "PAGE_NOT_FOUND": "Kunde inte hitta sidan",
  "Painaminen ja tallenteiden jäljentäminen": "Grafisk produktion och reproduktion av inspelningar",
  "PAKISTAN": "Pakistan",
  "PALAU": "Palau",
  "PALESTINIAN TERRITORY, OCCUPIED": "Palestina",
  "PANAMA": "Panama",
  "Paperin ja paperi- ja kartonkituotteiden valmistus": "Pappers- och pappersvarutillverkning",
  "PAPUA NEW GUINEA": "Papua Nya Guinea",
  "PARAGUAY": "Paraguay",
  "PASSWORD": "Lösenord",
  "PASSWORD_AGAIN": "Ge lösenordet på nytt",
  "PASSWORD_CONFIRMATION_DOES_NOT_MATCH": "Det nya lösenordet och verifieringen stämmer inte överens.",
  "PASSWORD_HAS_TO_BE_6_CHARACTERS_LONG": "Lösenordet ska vara minst 6 tecken långt.",
  "PASSWORD_SAVING_FAILED": "Uppdateringen av lösenordet misslyckades.",
  "PASSWORD_SAVING_SUCCESSFUL": "Uppdateringen av lösenordet misslyckades.",
  "YOU_WILL_BE_REDIRECTED_TO_GENERAL_SETTINGS": "Tillbaka till övriga inställningar",
  "PASSWORD_CONFIRMATION": "Bekräfta lösenordet",
  "SAVING_PASSWORD": "Sparar lösenordet",
  "PASSWORD_IS_INVALID": "Lösenordet är ogiltigt",
  "PASSWORD_IS_MANDATORY": "Du måste ange lösenordet",
  "PASSWORD_IS_ONLY_USED_WHEN_LOGIN_TO_ELITE": "Lösenordet används för att logga in på Elites tjänst",
  "PASSWORD_IS_REQUIRED": "Lösenordet är en obligatorisk uppgift",
  "PASSWORD_IS_REQUIRED_AGAIN": "Lösenordet måste anges på nytt",
  "PASSWORD_TOO_SHORT": "Lösenordet måste innehålla minst 6 tecken.",
  "PASSWORD_UPDATE": "Uppdatera lösenord",
  "PASSWORDS_ARE_NOT_SAME": "Lösenorden stämmer inte överens",
  "PAY_DATE": "Betalningsdatum",
  "PAYER": "Betalare",
  "PAYMENT_ACCOUNT_HANDLE_MONEY_TRANSFER": "Med fondkontoavtalet avses ett avtal, med sina tillhörande bilagor, som ingåtts mellan kunden och Elite och i vilket man kommer överens om investeringstjänsten mellan kunden och Elite, där förvaringstjänster för Finansieringsinstrument, handeln med Finansiella instrument, Elektroniska tjänster och kundmedelskonton ingår.",
  "PAYMENT_NOW": "Genast",
  "PENALTY_INTERESTS": "Kompensationsräntor",
  "PENALTYINTEREST": "Dröjsmålsränta",
  "PERFORMANCE_FEE": "Avkastningsbaserat arvode",
  "PERIOD": "Period",
  "PERSONAL_BANKER": "Min bankir",
  "PERSONAL_INFORMATION": "PERSONUPPGIFTER",
  "PERSONAL_SETTINGS": "Mina uppgifter och inställningar",
  "PERSONAL_INFO": "Mina uppgifter",
  "PERSONNEL_FUND_CUSTOMER_SERVICE": "Personal- / Bonus Fond kundtjänst",
  "PLEASE_CONTACT_ABOUT_PERSONNEL_FUNDS": "Ta kontakt med vår kundtjänst om du har något att fråga om Personal- / Bonus Fond.",
  "PERU": "Ångra",
  "perusaste": "Grundstadiet",
  "Peruttu": "Ångrat",
  "PHILIPPINES": "Filippinerna",
  "PHONE_NUMBER": "Telefonnummer",
  "PHONE_NUMBER_NEEDED": "(kräver ett giltigt telefonnummer)",
  "PHONE_NUMBER_REQUIRED": "Telefonnummer är en obligatorisk uppgift",
  "PIECE": "st",
  "PIECE_OF_SHARES": "andelar",
  "PLEASE_CONTACT_IF_QUESTIONS": "Ta kontakt med vår kundtjänst om du har något att fråga.",
  "PLEASE_WAIT": "Vänligen vänta utan att stänga webbläsaren.",
  "Pohjois-Amerikka": "Nordamerika",
  "POLAND": "Polen",
  "POLITICALLY_INFLUENTIAL_ABROAD": "Jag bor utomlands och jag har eller har haft en betydande offentlig tjänst (vait en politiskt inflytelserik person) i en annan stat eller jag är familjemedlem eller nära affärspartner till en sådan person.",
  "PORTFOLIO": "Portfölj",
  "PORTFOLIO_NAME": "Portföljnamn",
  "PORTFOLIO_DESELECT_ALL": "Töm valen",
  "PORTFOLIO_FROM_TO_CLAIM": "Välj först den portfölj från vilken du vill lösa in andelar.",
  "PORTFOLIO_MANAGER": "Portföljförvaltare",
  "PORTFOLIO_MUST_BE_SELECTED": "Du måste välja portfölj",
  "PORTFOLIO_SELECT": "Val av portfölj",
  "PORTFOLIO_SELECT_ALL": "Välj alla",
  "PORTFOLIO_TO_MARK": "Välj först den portfölj i vilken du vill göra en teckning.",
  "PORTFOLIO_TYPE": "Portföljtyp",
  "PORTFOLIOS": "Portföljer",
  "PORTUGAL": "Portugal",
  "POST_OFFICE": "Postanstalt",
  "POST_OFFICE_REQUIRED": "Postanstalt är en obligatorisk uppgift",
  "Posti- ja kuriiritoiminta": "Post- och kurirverksamhet",
  "PRECISION": "Frekvens",
  "PREVIOUS": "Föregående",
  "PRICE": "Pris",
  "PRICE_DATE": "Prisdatum",
  "PRICE_IN_ANOTHER_CURRENCY": "Pris i valuta",
  "PRINT": "Skriv ut",
  "Private Equity": "Onoterade aktier",
  "PROFILE_SETTINGS_CHANGED": "Användarprofilinställningarna har ändrats.",
  "PROFIT": "Avkastning",
  "PROFIT_HISTORY": "Avkastning",
  "PROFIT_LOSS": "Vinst / Förlust",
  "PROFIT_PERCENTAGE_ONE_MONTH": "Avkastning (EUR) 1 mån %",
  "PROFIT_PERCENTAGE_ONE_YEAR": "Avkastning (EUR) 1 år %",
  "PROFIT_SUMMARY": "Avkastningstabell",
  "Prospectus": "Prospekt",
  "PUERTO RICO": "Puerto Rico",
  "Puhelu_short": "samtal",
  "PURCHASE_COST_ESTIMATE": "Antagande om anskaffningsutgift",
  "PURCHASE_DATE": "Inköpsdatum",
  "PURCHASE_FEE": "Inköpskostnader",
  "PURCHASE_PRICES_TOGETHER": "Inköpssumma totalt",
  "PURCHASE_VALUE": "Inköpsvärde",
  "PURCHASE_DATE_FOR_TAX_PREPORT": "Inköpsdatum",
  "PURCHASE_VALUE_FOR_TAX_PREPORT": "Inköpspris",
  "PURPOSE_OF_ORDER": "Syftet med teckningen",
  "Pääkonttorien toiminta, liikkeenjohdon konsultointi": "Verksamheter som utövas av huvudkontor; konsulttjänster till företag",
  "QATAR": "Qatar",
  "Raakaöljyn ja maakaasun tuotanto": "Utvinning av råpetroleum och naturgas",
  "Radio- ja televisiotoiminta": "Planering och sändning av program",
  "Rahapeli- ja vedonlyöntipalvelut": "Spel- och vadhållningsverksamhet",
  "Rahoituspalvelut (pl. vakuutus- ja eläkevakuutustoiminta)": "Finansförmedling utom försäkring och pensionsfondsverksamhet",
  "Rahoitusta ja vakuuttamista palveleva toiminta": "Stödtjänster till finansiella tjänster och försäkring",
  "Rakennuttaminen ja rakennushankkeiden kehittäminen": "Utformning av byggprojekt",
  "Ravitsemistoiminta": "Restaurang-, catering och barverksamhet",
  "READ_MORE": "Läs mera",
  "Real Assets": "Fast egendom",
  "Real assets": "Fast egendom",
  "Real_Assets": "Fast egendom",
  "Real Estate": "Fastigheter",
  "Real estates": "Fastigheter",
  "EUR": "EUR",
  "REAL_ESTATE_SALE": "Försäljning av fastighet",
  "REALIZED": "Förverkligade",
  "REALIZED_GAINS/LOSSES": "Realiserade vinster/förluster",
  "Receivables": "Fordringar",
  "RECIDENCY_COUNTRY": "Bosättningsland",
  "RECLAIM": "Lös in",
  "RECLAIM_FROM_PORTFOLIO": "säljas från portfölj",
  "REDEMPTION": "Inlösen",
  "REDEMPTION_FEE": "Inlösenavgift",
  "REDEMPTIONDEPOSITION": "Deponering av inlösen",
  "REDEMPTIONS": "Inlösningar",
  "REFERENCE_INDEX": "Jämförelseindex",
  "REFERENCE_INDEX_CANNOT_BE_USED_WITH_THIS_FUND_AND_DATE_RANGE": "Det valda jämförelseindexet kan inte användas i grafen för denna fond under den valda perioden.",
  "REFERENCE_NUMBER": "Referensnummer",
  "REGISTRATION_COUNTRY": "Registreringsland",
  "REMARKED_FUND": "Fond som ska omtecknas",
  "REQUIRED": "Obligatorisk uppgift",
  "REPORT": "Rapport",
  "REPORTING": "Rapportering",
  "RESELLER_CODE": "Återförsäljar-ID",
  "RETRIEVE_YOUR_USER_ID": "Hämta ditt användarnamn eller återställ ditt lösenord",
  "RETURN": "TILLBAKA",
  "RETURN_%": "Ändrings-%",
  "RETURN_ORIENTED": "Avkastningsorienterad",
  "RETURN_TO_START_PAGE_FROM": "Gå tillbaka till startsidan",
  "RETURN_TO_LOGIN": "Gå tillbaka till inloggningen",
  "RETURN_TO_BROWSE_ORDERS": "Gå tillbaka till bläddra bland uppdragen",
  "RÉUNION": "Reunion",
  "RISK_CLASS": "Riskkategori",
  "RISK_CLASS_FOR_ELITE_SERVICES": "Riskkategori för Elite Alfred Bergs tjänster",
  "RISK_CLASS_FOR_WHOLE_WEALTH": "Riskkategori för hela förmögenheten",
  "RISK_DISTRIBUTION": "Riskfördelning",
  "RISK_STATISTICS": "Risktal",
  "ROMANIA": "Rumänien",
  "Rulebook": "Regler",
  "RUSSIAN FEDERATION": "Ryssland",
  "RWANDA": "Rwanda",
  "Sahatavaran sekä puu- ja korkkituotteiden valmistus": "Tillverkning av sågat virke och varor av trä och kork",
  "SAINT BARTHÉLEMY": "Saint-Barthélemy",
  "SAINT HELENA": "Sankta Helena",
  "SAINT KITTS AND NEVIS": "Saint Kitts och Nevis",
  "SAINT LUCIA": "Saint Lucia",
  "SAINT MARTIN": "Saint Martin",
  "SAINT PIERRE AND MIQUELON": "Saint-Pierre och Miquelon",
  "SAINT VINCENT AND THE GRENADINES": "Saint Vincent och Grenadinerna",
  "SAMOA": "Samoa",
  "SAN MARINO": "San Marino",
  "SAO TOME AND PRINCIPE": "São Tomé och Príncipe",
  "SAUDI ARABIA": "Saudiarabien",
  "SAVE": "Spara",
  "SAVE_CHANGES": "Spara ändringar",
  "SAVE_MONTHLY": "Månadssparande",
  "SAVE_PASSWORD": "SPARA LÖSENORDET",
  "SAVE_SUCCESS_MESSAGE": "Uppgifterna har registrerats!",
  "SAVING_INFORMATION": "Sparar uppgifter",
  "SAVING_CUSTOMER_INFORMATION": "Sparar kunduppgifter!",
  "SAVING_TAKES_TIME": "Anläggning av kundrelation kan ta 1-3 minuter.",
  "SAVINGS": "Besparingar",
  "SEARCH_BY_TYPING": "Sök genom att skriva in",
  "SEARCH_FOR_FUND": "Sök fond",
  "SECTOR": "Sektor",
  "SECTOR_IS_REQUIRED": "Sektor är en obligatorisk uppgift.",
  "SECURITY_ORIENTED": "Säkerhetsorienterad",
  "SELECT_PORTFOLIO": "Välj portfölj",
  "SELECT_PORTFOLIOS": "Välj portföljerna",
  "SELECTED_SERVICE": "Vald tjänst",
  "SELECT_PORTFOLIO_FIRST": "Välj en portfölj först",
  "SELECT_SEND_TASK_REQUEST": "Du kan förmedla en kontaktbegäran till din kontaktperson som kontaktar dig.",
  "SEND_TASK_REQUEST": "Förmedla kontaktbegäran",
  "SELECTED": "Vald",
  "SELF_SERVICE_INVESTING": "Självständig placering",
  "Sell": "Sälj",
  "SELL": "Sälj",
  "SellDeposition": "Försäljning",
  "SELLER_CODE": "Försäljar-ID",
  "SELL_DATE": "Överlåtelsedatum",
  "SELL_FEE": "Försäljningskostnader ",
  "SELL_PRICES_TOGETHER": "Överlåtelsesumma totalt",
  "SELL_VALUE": "Överlåtelsesumma",
  "SELLRECEIVABLE": "Försäljningsfordran",
  "SEND_INFORMATION": "Skicka information",
  "SENEGAL": "Senegal",
  "SERBIA": "Serbien",
  "SERVER_ERROR": "Serverfel",
  "SERVICE_RATES": "Tjänsteprislista",
  "SERVICE_RATES_MUST_BE_ACCEPTED_BEFORE_CONTINUING": "Du måste godkänna tjänsteprislistan innan du kan fortsätta.",
  "SESSION_TIMEOUT_SOON_TITLE": "Tidsbunden avslutning av sessionen",
  "SESSION_TIMEOUT_SOON_QUESTION": "Sessionen är tidsbunden och avslutas om en stund. Vill du fortsätta sessionen?",
  "SESSION_TIMEOUT_NOTICE_TITLE": "Sessionen har avslutats p.g.a. att tiden gått ut",
  "SESSION_TIMEOUT_NOTICE": "Sessionen har avslutats,eftersom du inte har använt nättjänsten på en tid.",
  "END_SESSION": "Avsluta session",
  "SETTLEMENT_DATE": "Betalningsdatum",
  "SEYCHELLES": "Seychellerna",
  "SHARE": "Andel",
  "SHARE_PRICE": "Pris €/andel",
  "SHARES": "Andelarna",
  "SHOW": "Visa",
  "SHOW_ALL_BRIEFS": "Visa alla meddelanden",
  "SHOW_PURCHASES": "Visa per inköp",
  "SHOW_RETURN_AND_LOSS_EXPECTATIONS": "Visa avkastnings- och förlustförväntningar",
  "SHOW_TAX_REPORT_FOR_CURRENT_YEAR": "Visa skatterapporten för det gångna året",
  "SHOWN": "Visas",
  "SHOWN_ON_PAGE": "Visas på sidan",
  "SHOW_INFO_POPUP_TITLE_30092022": "EAB Webbtjänst",
  "SHOW_INFO_POPUP_CONTENT1_30092022": "Uppdateringsarbete utförs i EAB Webbtjänst. I webbtjänsten kan du dock se värdet av dina innehav i realtid. Teckning och inlösen av fonder kan endast göras per telefon mellan 30.9.-10.10.2022.",
  "SHOW_INFO_POPUP_CONTENT2_30092022": "Din egen kontaktperson och vår kundtjänst hjälper dig gärna med eventuella frågor samt med teckning och inlösen av fonder. Du kan nå vår kundtjänst per telefon på 0201 558 610 (vardagar från 9:00 till 17:00) eller via e-post på kundtjanst@eabgroup.fi.",
  "SHOW_INFO_POPUP_TITLE": "EAB Group Abp är nu en del av Evli Abp",
  "SHOW_INFO_POPUP_CONTENT1": "På EAB Webbtjänsten kan du se värdet av dina innehav fram till den 10.10.2022. Informationen om dina innehav överförs till My Evli onlinetjänsten, där du bland annat kan följa utvecklingen av dina tillgångar och utföra order från och med uppskattningsvis 10.10.2022.",
  "SHOW_INFO_POPUP_CONTENT2": "EAB Personalfondens webbtjänst förblir oförändrad efter sammanslagningen.",
  "SHOW_INFO_POPUP_CONTENT3": "Din kontaktperson och vår kundtjänst hjälper dig gärna vid eventuella frågor eller om du vill teckna eller lösa in fonder före den 10.10.2022. Du kan nå vår kundtjänst per telefon på 0201 558 610 (vardagar 9.00-17.00) eller via e-post på kundtjanst@eabgroup.fi.",
  "SHOW_INFO_POPUP_CONTENT_HRJ_1": "EAB har fusionerats med Evli den 1.10.2022. Fusionen kräver inga åtgärder av våra kunder. Vi betjänar dig som vanligt efter fusionen. På webbtjänsten kan du bl.a. se dina fondandelar, dina personuppgifter och kontakta oss.",
  "SHOW_INFO_POPUP_CONTENT_HRJ_2": "Vår kundtjänst hjälper dig gärna om du har några frågor, tel. 0201 558 660 (vardagar 9.00-17.00) eller jasenpalvelu@eai.fi.",
  "SHOW_INFO_POPUP_CLOSE_TEXT": "Stäng fönstret",
  "SIERRA LEONE": "Sierra Leone",
  "SIGN": "Logga in",
  "SIGN_IN": "Logga in",
  "SIGN_IN_HERE_USING_BANK_ACCOUNT": "Logga in här med bankkoder",
  "SIGN_IN_USING_BANK_ACCOUNT": "Logga in med bankkoder",
  "SERVICE_REQUIRES_STRONG_IDENTIFICATION": "Användning av tjänsten krävs bankkoder.",
  "CHOOSE_IDENTIFICATION_METHOD": "Välj identifieringssätt",
  "REGISTER_BY_IDENTIFYING": "Bli kund med bankkoder.",
  "LOGIN_FAILED": "Inloggningen misslyckades.",
  "SIGN_OUT": "Logga ut",
  "SINGAPORE": "Singapore",
  "SIX_MONTHS_SHORT": "6 mån",
  "SLOVAKIA": "Slovakien",
  "SLOVENIA": "Slovenien",
  "SMS": "SMS",
  "SOCIAL_SECURITY_NUMBER": "Personbeteckning",
  "SOLD_ASSETS": "Såld egendom",
  "SOLD_HOLDINGS_AND_REPORT_FOR_WINS_AND_LOSES": "DEN ÖVERLÅTNA EGENDOMEN OCH EN  UTREDNING OM BELOPPET PÅ ÖVERLÅTELSEVINST ELLER -FÖRLUST",
  "SOLOMON ISLANDS": "Salomonöarna",
  "SOMALIA": "Somalia",
  "SOMETHING_WENT_WRONG_WITH_REGISTRATION": "Hej, något gick fel vid registreringen.",
  "Sosiaalihuollon avopalvelut": "Öppna sociala insatser",
  "Sosiaalihuollon laitospalvelut": "Vård och omsorg med boende",
  "SOURCE_TAXES": "Källskatt",
  "SOURCETAXDEPOSITION": "Redovisning av källskatt",
  "SOURCETAXWITHDRAWAL": "Debitering av källskatt",
  "SOUTH AFRICA": "Sydafrika",
  "SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS": "Sydgeorgien och Sydsandwichöarna",
  "SPAIN": "Spanien",
  "SRI LANKA": "Sri Lanka",
  "START_BALANCE": "Startsaldo",
  "START_DATE": "Startdatum",
  "STARTING_DATE": "Startdag",
  "STATUS": "Status",
  "STOCKS": "Aktier",
  "STREETADDRESS": "Gatuadress",
  "STREETADDRESS_REQUIRED": "Gatuadress är en obligatorisk uppgift",
  "Structured": "Strukturerade produkter",
  "Structured products": "Strukturerade produkter",
  "Structured Products": "Strukturerade produkter",
  "STRUCTURED_PRODUCTS": "Strukturerade placeringsprodukter",
  "SUB_CLASS": "Underkategori",
  "SUBSCRIBE_NEWS_LETTER": "Jag prenumerar på nyhetsbrev",
  "SUBSCRIBE_INFO_LETTER": "Jag prenumererar information om produkter och tjänster",
  "SUBSCRIBE_STOCK_EXCHANGE_RELEASES": "Jag prenumerera på börs- och pressmeddelanden",
  "SUBSCRIBE_STOCK_EXCHANGE_ANNOUNCEMENTS": "Jag prenumerera på börs anmälan",
  "SUBSCRIBE_WEEKLY_LETTER": "Jag prenumererar på veckobrevet",
  "SUBSCRIPTION": "Teckning",
  "SUBSCRIPTION_FEE": "Teckningsarvode",
  "SUBSCRIPTION_SMALL": "teckning",
  "SUBSCRIPTIONS": "Teckningar",
  "SUBSCRIPTIONWITHDRAWAL": "Debitering av teckning",
  "SUBSTRACTIONS": "Minskningar",
  "SUDAN": "Sudan",
  "SUM_GROSS_EUR": "Summa brutto",
  "SUM_MUST_BE_AT_LEAST_MIN": "Summan måste minst uppgå till minimiteckningen.",
  "SUM_MUST_BE_POSITIVE": "Summan måste vara ett positivt tal.",
  "SUSTAINABILITY_REPORTS": "Sustainability reports",
  "SUSTAINABILITY_REPORTS_EN": "Sustainability reports in English",
  "TAX_RATE_MUST_BE_POSITIVE": "Skatteprocent måste vara ett positivt tal.",
  "SUM_NET_EUR": "Summa netto",
  "SUM_WITH_EMARK": "BELOPP €",
  "SUMMARY": "Sammandrag",
  "SURE_YOU_WANT_TO_CANCEL": "Är du säker på att du vill ångra ditt val",
  "SVALBARD AND JAN MAYEN": "Svalbard och Jan Meyen",
  "SWAZILAND": "Swaziland",
  "SWEDEN": "Sverige",
  "SWEDISH": "svenska",
  "SWITZERLAND": "Schweiz",
  "SYRIAN ARAB REPUBLIC": "Syrien",
  "Sähkö-, kaasu- ja lämpöhuolto, jäähdytysliiketoiminta": "Försörjning av el, gas, värme och kyla",
  "Sähkölaitteiden valmistus": "Tillverkning av elapparatur",
  "TAIWAN, PROVINCE OF CHINA": "Taiwan",
  "TAJIKISTAN": "Tadzjikistan",
  "Talonrakentaminen": "Byggande av hus",
  "TANZANIA, UNITED REPUBLIC OF": "Tanzania",
  "TARGET": "Instrument",
  "TASMANIA": "Tasmanien",
  "ADD_TAX_COUNTRY": "Lägg till beskattningsland",
  "ADD_TAX_COUNTRY_BUTTON": "Lägg till beskattningsland",
  "TAX_COUNTRY": "Beskattningsland",
  "TAX_COUNTRY_IS_REQUIRED": "Beskattningsland är en obligatorisk uppgift",
  "GET_TO_KNOW_INFORMATION": "Identifieringsdata",
  "UPDATE_GET_TO_KNOW_INFORMATION": "Uppdatering av identifieringsdata",
  "TAX_LIABILITY": "Skattskyldighet",
  "TAX_LIABILITY_SETTINGS": "Uppdatering av informationen om beskattningsland",
  "TAX_LIABILITY_SETTINGS_UPDATE": "Uppdatering av informationen om beskattningsland",
  "TAX_LIABILITY_CHANGED": "Uppgifterna om skatteskyldighet har ändrats",
  "TAX_NUMBER_OR_ID": "Skattenummer/-id",
  "TAX_REPORT": "Skatterapport",
  "TAXPAYER_IN_UNITED_STATES_ADDITIONAL_REQUIREMENT": "Personen måste även lämna in en lämplig IRD w-8/w-9-blankett.",
  "TAXPAYER_IN_UNITED_STATES_MUST_PROVIDE_A_TAX_NUMBER_OR_ID": "I USA ska den skattskyldiga ange sitt skattenummer eller -id.",
  "Tekstiilien valmistus": "Textilvarutillverkning",
  "Televiestintä": "Telekommunikation",
  "TEN_LARGEST_INVESTMENTS": "10 största placeringar",
  "TERMS": "Villkor",
  "TERMS_FOR_MONTHLY_DEPOSIT_PART1": "När du använder det referensnummer du erhållit vid betalning till EAB Group Abp:s konto FI94 3131 1000 2982 44, bekräftar du att du har läst",
  "TERMS_FOR_MONTHLY_DEPOSIT_PART2": "det förenklade prospektet och reglerna för fonden samt EAB Group Abp:s förmedlings- och förvaringsvillkor och att du accepterar dem.",
  "Terveyspalvelut": "Hälso- och sjukvård",
  "THAILAND": "Thailand",
  "THE_FIELD_IS_REQUIRED": "Fältet är en obligatorisk uppgift.",
  "THE_FUND_SEARCH": "fondsökningen",
  "THE_FUNDS": "Tillgångar",
  "THE_NEW_REDEMPTION": "Ny fondinlösen",
  "THE_NEW_SUBSCRIPTION": "Ny fondteckning",
  "THE_ORDER": "uppdrag",
  "THE_RECLAIM": "inlösen",
  "THE_VALUE_IS_NOT_A_DATE": "Värdet är inte ett datum",
  "THERE_ARE_VALIDATION_ERRORS": "De inmatade uppgifterna är bristfälliga eller felaktiga. Kontrollera uppgifterna.",
  "THERE_WERE_ERRORS_WHEN_SENDING_THE_INFORMATION": "Ett fel inträffade vid sändning av information.",
  "THREE_MONTHS_SHORT": "3 mån",
  "TWELVE_MONTHS_SHORT": "12 mån",
  "Tieteellinen tutkimus ja kehittäminen": "Vetenskaplig forskning och utveckling",
  "Tietokoneiden sekä elektronisten ja optisten tuotteiden valmistus": "Tillverkning av datorer, elektronikvaror och optik",
  "Tietokoneiden, henkilökohtaisten ja kotitaloustavaroiden korjaus": "Reparation av datorer, hushållsartiklar och personliga artiklar",
  "Tietopalvelutoiminta": "Informationstjänster",
  "TIMOR-LESTE": "Östtimor",
  "TO_FUND": "för fond",
  "TODAY": "I dag",
  "TOGO": "Togo",
  "Toimiala tuntematon": "Branschen okänd",
  "TOKELAU": "Tokelau",
  "TONGA": "Tonga",
  "TOO_LONG_NAME": "Get angivna namnet är för långt.",
  "ONLY_LETTERS_NUMBERS_WHITESPACE": "Namnet kan innehålla bara bokstäver, nummer och blanksteg",
  "TOO_MUCH_CLAIMS": "Du kan inte lösa in fler andelar än du besitter",
  "TOP_TEN_MOST_PROFITABLE_FUNDS_ONE_MONTH": "De 10 bäst avkastande fonderna (en månad)",
  "TOPICAL": "Aktuellt",
  "TOTAL": "Totalt",
  "TOTAL_ALL": "Alla totalt",
  "TOTAL_WEALTH": "Totala tillgångar",
  "TOTAL_WEALTH_IS_REQUIRED": "Totala tillgångar är en obligatorisk uppgift.",
  "TOTAL_WEALTH_MUST_BE_A_NUMBER": "Den totala förmögenheten måste vara ett tal.",
  "TRADING_ACTIVITY": "Handelsaktivitet",
  "TRADING_ACTIVITY_IS_REQUIRED": "Handelsaktivitet är en obligatorisk uppgift.",
  "TRANSACTION": "Transaktion",
  "TRANSACTIONS": "Transaktioner",
  "TRANSACTION_DATE": "Transaktionsdatum",
  "TRANSACTIONS_FOUND_TOTAL": "Totalt hittades",
  "TRANSACTION_IS_NOT_READY": "Uppdraget har inte skrivits in klart",
  "TRANSACTION_NUMBER": "Transaktionsnummer",
  "TRANSACTION_TYPE": "Transaktionstyp",
  "TRANSACTION_TYPES": "Transaktionstyper",
  "TRANSACTIONS_ARCHIEVENO": "Transaktionens arkivnummer",
  "TRANSFER": "Överföring",
  "Transfer": "Överföring",
  "TRINIDAD AND TOBAGO": "Trinidad och Tobago",
  "TRUE_BENEFICIARIES": "Verkliga förmånstagare",
  "Tukkukauppa (pl. moottoriajoneuvojen ja moottoripyörien kauppa)": "Parti- och provisionshandel utom med motorfordon",
  "TUNISIA": "Tunisien",
  "TUPASLOGIN_NO_USER": "Inga online-identifierare hittades",
  "Tupakkatuotteiden valmistus": "Tobaksvarutillverkning",
  "TURKEY": "Turkiet",
  "TURKMENISTAN": "Turkmenistan",
  "TURKS AND CAICOS ISLANDS": "Turks- och Caicosöarna",
  "TURNOVER": "Omsättning",
  "Turvallisuus-, vartiointi- ja etsiväpalvelut": "Säkerhets- och bevakningsverksamhet",
  "TUVALU": "Tuvalu",
  "TYPE": "Typ",
  "TYPE_FOR_TRANSACTION": "Transaktionstyp",
  "Työllistämistoiminta": "Arbetsförmedling, bemanning och andra personalrelaterade tjänster",
  "UGANDA": "Uganda",
  "UKRAINE": "Ukraina",
  "UNFORTUNATELY_WE_COULD_NOT_FIND_WHAT_YOU_ARE_LOOKING_FOR": "Vi kunde tyvärr inte hitta det du sökte.",
  "UNFINISHED_REDEMPTIONS_IN_PORTFOLIO_PART1": "Portföljen innehåller pågående inlösen, varav kontanter beräknas uppgå till",
  "UNFINISHED_REDEMPTIONS_IN_PORTFOLIO_PART2": ", summan fastställs vid bekräftelse av inlösen. Om de kontanter som erhölls från inlösen inte räcker för teckningen, verkställs teckningarna med dessa medel.",
  "Unknown": "Övriga",
  "UNITED ARAB EMIRATES": "Förenade Arabemiraten",
  "UNITED KINGDOM": "Storbritannien",
  "UNITED STATES": "USA",
  "UNITED STATES MINOR OUTLYING ISLANDS": "Förenta staternas mindre öar i Oceanien och Västindien",
  "Unlisted": "Olistade",
  "UPDATE_SUCCESS_MESSAGE": "Uppgifterna uppdaterades!",
  "ALL_BUT_RESIDENCYCOUNTRY_UPDATED": "Kontaktinformationens uppdatering lyckades. Bosättningslandets uppdatering misslyckades.",
  "UPDATE_FAIL_MESSAGE": "Datauppdatering misslyckades.",
  "UPDATE_TAX_INFORMATION": "Vi lade märke till att uppgifterna om beskattningsland inte hade uppdaterats. Vänligen uppdatera dem så att du kan fortsätta använda tjänsten.",
  "UPDATE_TAX_AND_WEALTH_INFORMATION": "Huomasimme, että tiedot sijoitusvarallisuudestasi ja verotusmaistasi ovat päivittämättä. Päivitäthän ne tässä jatkaaksesi palvelun käyttöä.",
  "Urheilutoiminta sekä huvi- ja virkistyspalvelut": "Sport-, fritids- och nöjesverksamhet",
  "URUGUAY": "Uruguay",
  "USERNAME": "Användarnamn",
  "USERNAME2": "Användarnamn",
  "USERNAME_AND_PASSWORD": "Användarnamn och lösenord",
  "USERPROFILE_SETTINGS": "Användarprofilinställningar",
  "USERPROFILE": "Användarprofil",
  "UZBEKISTAN": "Uzbekistan",
  "Vaatteiden valmistus": "Tillverkning av kläder",
  "Vakuutus-, jälleenvakuutus- ja eläkevakuutustoiminta": "Försäkring, återförsäkring och pensionsfondsverksamhet",
  "WALLIS AND FUTUNA": "Wallis- och Futunaöarna",
  "VALUE": "Värde",
  "VALUE_CHART": "Värdeutveckling",
  "VALUE_DATE": "Värderingsdatum",
  "VALUE_TOTAL": "Summa",
  "WANT_TO_GET_BRIEF_EMAIL": "Jag vill få Elite Alfred Bergs kundmeddelande via e-post",
  "VANUATU": "Vanuatu",
  "Varastointi ja liikennettä palveleva toiminta": "Magasinering och stödtjänster till transport",
  "WARRANTS": "Warranter",
  "Veden otto, puhdistus ja jakelu": "Vattenförsörjning",
  "WEEKLY": "Per vecka",
  "WEIGHT": "Vikt",
  "WELCOME_TO_CUSTOMER": "Välkommen som kund, avtalet kommer att skickas till dig under de närmaste dagarna",
  "VENEZUELA": "Venezuela",
  "VERIFY_NEW_PASSWORD": "Bekräfta ditt nya lösenord",
  "VERIFY_ORDER_BY_GIVING_PASSWORD": "Bekräfta uppdraget genom att ange ditt lösenord i fältet nedan",
  "VERIFY_ORDER_BY_ONLINE_IDENTIFICATION": "Bekräfta uppdraget med online-autentisering",
  "VERIFY_PASSWORD_IS_REQUIRED": "Du måste ange det nya lösenordet en gång till.",
  "Vesiliikenne": "Sjötransport",
  "WESTERN SAHARA": "Västsahara",
  "WHAT?": "Vad?",
  "WHAT_ARE_THE_BENEFITS": "Vilka är fördelarna med belöningens fondering?",
  "WHAT_IS_IT_ABOUT": "Vad handlar det om?",
  "WHERE?": "Var?",
  "WHICH?": "Vilka?",
  "WHICH_ONE?": "Vad?",
  "WHOLE_HISTORY": "Hela historiken",
  "WHOLE_WEALTH": "Hela förmögenheten",
  "Viemäri- ja jätevesihuolto": "Avloppsrening",
  "VIETNAM": "Vietnam",
  "VIRGIN ISLANDS, BRITISH": "Brittiska Jungfruöarna",
  "VIRGIN ISLANDS, U.S.": "Amerikanska Jungfruöarna",
  "WIN_OR_LOSE": "Vinst eller förlust",
  "WINS_TOGETHER": "Vinster totalt",
  "WITH_ONLINE_BANK_ACCOUNTS": "Med online-identifieringen",
  "WITH_SUM": "till en summa av",
  "WITH_YOUR_USERNAME_AND_NEW_PASSWORD_YOU_CAN_LOGIN": "Du kan nu logga in på systemet med ditt användarnamn och lösenord.",
  "WITHDRAW_BACK": "Tillbaka",
  "WITHDRAW_PORTFOLIO_CASHBALANCE": "Portföljens saldo/Kan tas ut",
  "WITHDRAW_SAVE": "Ta ut",
  "WITHDRAW_SELECT_PORTFOLIO_INFOTEXT": "Välj först den portfölj från vilken du vill göra ett uttag.",
  "WITHDRAW_SUM_ISMAXIMIUM": "Du kan inte ta ut mer medel än vad det finns tillgångar i portföljen.",
  "WITHDRAW_SUM_ISMINIMIUM": "Summan som tas ut måste vara ett positivt heltal.",
  "WITHDRAW_SUM_ISNUMERIC": "Summan måste vara ett numeriskt värde.",
  "WITHDRAW_SUM_REQUIRED": "Du måste ange den summa som ska tas ut.",
  "WITHDRAW_SUM_TITLE": "Ange den summa som ska tas ut",
  "WITHDRAWAL": "Uttag",
  "Withdrawal": "Uttag",
  "WITHDRAWAL_FROM_PORTFOLIO": "att ta ut från portfölj",
  "WITHDRAWAL_SMALL": "uttag",
  "WITHDRAWALS": "Uttag",
  "WITHDRAWALS_DURING_PERIOD": "Uttag under perioden",
  "WORK_INCOME": "Förvärvsinkomst",
  "WORK_INCOME_MIFID": "Förvärvsinkomst",
  "WORKDAYS": "Vardagar",
  "World": "Världen",
  "WRITE_FUNDS_NAME": "Ange namnet på fonden",
  "WRONG_PASSWORD_OR_USERNAME": "Fel lösenord eller användarnamn",
  "WRONG_CONFIRMATION_CODE": "Fel bekräftelsekod.",
  "Vuokraus- ja leasingtoiminta": "Uthyrning och leasing",
  "Vähittäiskauppa (pl. moottoriajoneuvojen ja moottoripyörien kauppa": "Detaljhandel utom med motorfordon och motorcyklar",
  "X-RAYED_PORTFOLIO_HOLDINGS": "Portföljinnehavet genomlyst",
  "YEAR": "år",
  "YEARS": "år",
  "YEMEN": "Jemen",
  "YES": "Ja",
  "Yleinen": "Generell",
  "ylempi korkeakouluaste": "Högre högskolenivå",
  "YOU_ARE_CREATING": "Du tar som bäst fram",
  "YOU_CANNOT_BUY_ACCORDING_TO_YOUR_CONTRACT": "Enligt ditt avtal är det inte möjligt att i nättjänsten göra köptransaktioner via portföljen. ",
  "YOU_CANNOT_SELL_ACCORDING_TO_YOUR_CONTRACT": "Enligt ditt avtal är det inte möjligt att i nättjänsten göra köptransaktioner via portföljen. ",
  "YOU_CANNOT_WITHDRAW_ACCORDING_TO_YOUR_CONTRACT": "Enligt ditt avtal är det inte möjligt att i nättjänsten begära uttag via portföljen. ",
  "YOU_CANNOT_DEPOSIT_ACCORDING_TO_YOUR_CONTRACT": "Enligt ditt avtal är det inte möjligt att i nättjänsten göra insättningar via portföljen. ",
  "YOU_CAN_NOW_LOGIN_TO_SYSTEM": "Du kan nu logga in på systemet med ditt kundnummer och lösenord.",
  "YOU_CAN_NOW_CLOSE_THIS_WINDOW": "Du kan nu stänga det här fönstret.",
  "YOU_CAN_USE_ELITES_ANALYZES": "Du får tillgång breda utbud av fonder inklusive analyser",
  "YOU_CAN_USE_ELITES_PERONAL_MANAGEMENT": "Om du vill kan du även ansluta individuella kapitalförvaltning till ditt konto",
  "YOUR_ACCOUNT_NUMBER_IS": "Ditt kundnummer är",
  "YOUR_CURRENT_PASSWORD_IS_WRONG": "Det nuvarande lösenordet är fel",
  "YOUR_PASSWORD_IS_CHANGED": "Ditt lösenord har ändrats.",
  "YOUR_USERACCOUNT_IS": "Ditt användarnamn är",
  "ZAMBIA": "Zambia",
  "ZIMBABWE": "Zimbabwe",
  "ZIP_CODE": "Postnummer",
  "ZIP_CODE_REQUIRED": "Postnummer är en obligatorisk uppgift",
  "ÅLAND": "Åland",

  "HOW_TO_SIGN_IN": "Så här loggar du in på webbtjänsten",
  "CHECK_HOW_TO_SIGN_IN": "Om du behöver hjälp med logga in till webbtjänsten eller du har glömt ditt lösenord, se instruktioner nedan.",
  "SEE_INSTRUCTIONS": "Se instruktioner (på Finska)",
  "ATTACHMENT_INSTRUCTION": "Dra bilagan här eller ladda upp från dina filer. Bilagans max. Storlek är 20 Mb.",
  "ADD_ATTACHMENT": "Lägg till en fil",
  "REMOVE_ATTACHMENT": "Ta bort en fil",

   // kirjautumissivu
  "HOW_TO_SIGN_IN_AND_VIDEO": "Så här loggar du in, se videon",
  "WELCOME_TO_EAB_WEB": "Välkommen till Elite Alfred Bergs webbtjänst.",
  "SIGN_IN_TO_SEE_OWN_INVESTMENTS": "När du har loggat in kan du se dina egna investeringar.",
  "EAB_ONLINE_SERVICE": "EAB Webbtjänst",
  "EAB_PERSONNEL_FUNDS": "EAB Personalfond",
  "TEXTMESSAGE_HAS_BEEN_SENT": "Vi har skickat dig ett SMS med bekräftelse.",
  "GIVE_CONFIRMATIONCODE": "Ange koden i fältet nedan.",
  "NO_MESSAGE_RECEIVED": "Inte haft SMS?",
  "CONTACT_TO": "Ta kontakt med vår",
  "OUR_CUSTOMER_SERVICE": "kundtjänst",
  "ANY_QUESTIONS": "Har du frågor?",
  "CUSTOMER_SERVICE_INSTRUCTION": "Vår kundservice hjälper dig gärna med alla frågor om webbtjänst. Tveka inte att kontakta oss!",
  "CUSTOMER_SERVICE_SERVICETIME": "9.00 - 17.00",
  "CUSTOMER_SERVICE_PHONENUMBER": "0201 558 610",
  "SIGN_IN_TO_EAB_WEB": "Logga in till webbtjänsten",
  "SIGN_IN_TO_PERSONNEL_FUND": "Logga in till personalfonden",
  "SIGN_IN_USING_YOUR_CREDENTIALS": "Logga in med dina inloggningsuppgifter.",
  "SIGN_IN_USING_EXISTING_CREDENTIALS": "Logga in med existerande inloggningsuppgifter.",

  // Henkilöstopalveluiden kirjautuminen
  "PERSONNEL_FUNDS_WELCOME": "Välkommen till personal- och belöningsfondens webbtjänst. I webbtjänsten ser du all information om fonden och om ditt eget medlemskap.",
  "IDENTIFY_WITH_BANK_ACCOUNT_ID": "Identifiera dig",

  "IF_YOU_HAVE_FORGOTTEN_ID_OR_PASSWORD": "Sök användarnamnet och byt lösenord med hjälp av online-identifieringen.",
  "FORGOT_ACCOUNT": "Jag har glömt mina inloggningsuppgifter",
  "USERS_FOUND_WITH_BANK_ACCOUNT": "Identifieringen hittade",
  "USERS": "användare",
  "USER": "användare",
  "CHOOSE_USER": "Välj användare",
  "GIVE_NEW_PASSWORD": "Ange ditt nya lösenord i fälten nedan.",
  "PASSWORD_CHANGED_OK": "Lösenordet ändrades",
  "PASSWORD_CHANGED_FAIL": "Lösenordet ändrades inte",
  "PASSWORD_FOR_ID": "Lösenord för användare",
  "HAS_BEEN_CHANGED": "har nu ändrats.",
  "PASSWORD_WAS_NOT_CHANGED": "Lösenordet kunde inte ändras till användaren",
  "PASSWORD_WAS_NOT_CHANGED_1": "Lösenordet för användare",
  "PASSWORD_WAS_NOT_CHANGED_2": "kunde inte ändras",
  "GO_TO_LOGIN": "Gå till inloggningen",

  //Navigaatio
  "GO_TO_WEBPAGE": "Gå till webbplatsen",
  "PERSONNEL_FUNDS": "Personal- och belöningsfond",
  "ASSETS_AND_INVESTMENTS": "Förmögenhetsförvaltning och Investeringar",
  "SEB_LIFE_SERVICE": "SEB Life Webbtjänst",
  "DETAILS_AND_SETTINGS": "Mina uppgifter och inställningar",
  "CONTACT": "Kontakta oss",
  "HOME": "Ingångssidan",
  "OWN_PORTFOLIOS": "Egna portföljer",
  "LOADING": "Laddas ner",

  // Asiakkaaksitulon käännökset
  "I_DO_NOT_WANT_MY_ADDRESS_TO_BE_UPDATED": "Jag vill inte att min adress uppdateras på basis av Befolkningsregistercentralens uppgifter. Jag meddelar själv Elite Alfred Berg om eventuella adressändringar.",
  "COUNTRY_OF_BIRTH": "Födelseland",
  "COUNTRY_OF_BIRTH_AND_NATIONALITY": "Födelseland och nationalitet",
  "ADD_NEW": "Lägg till nytt",
  "SELECT_NATIONALITY": "Välj nationalitet",
  "BY_PROVIDING_MY_EMAIL_ADDRESS": "Genom att uppge min e-postadress godkänner jag ",
  "ELECTRONIC_EXCHANGE": "elektroniskt informationsutbyte.",
  "ELITE_CAN_SEND_STATUTORY_BULLETINS": "Elite Alfred Berg får skicka lagstadgade meddelanden och övrigt avtalsmaterial elektroniskt. Elite Alfred Berg har rätt att informera om ändringar i avtalsvillkoren och tjänsteprislistan elektroniskt antingen genom att göra dem tillgängliga för Kunden i den elektroniska tjänsten, på företagets webbsida och/eller genom att skicka dem till den e-postadress som Kunden har uppgett. Kunden är medveten om och godkänner att användningen av elektroniska kommunikationsmedel, såsom e-post och telefax, är förknippad med särskilda risker bland annat på grund av att meddelandet eventuellt inte kommer fram eller kan komma till utomståendes kännedom eller att utomstående kan modifiera meddelandets innehåll. Elite Alfred Berg har rätt att lita på att de meddelanden som företaget har tagit emot via elektroniska kommunikationsmedel är äkta och har rätt innehåll.",
  "I_WISH_TO_ORDER_MONTHLY_NEWSLETTER": "Jag vill beställa Elite Alfred Bergs månadsbrev till min e-post.",

  "USUALLY_THE_GENERAL_TAX_LIABILITY": "En fysisk persons allmänna skattskyldighet (beskattningsland) bestäms i allmänhet utifrån boende eller motsvarande vistelse. Om du är osäker på din beskattningsstatus ber vi dig kontakta en skatterådgivare. Kunden ska själv meddela alla sina beskattningsländer till Elite.",
  "LIABILITY_TO_PAY_TAXES_IN_FINLAND": "Skattskyldighet i Finland",
  "I_CONFIRM_ONLY_FINLAND": "Jag bekräftar att jag skattemässigt är bosatt enbart i Finland.",
  "I_CONFIRM_ALSO_OTHER_THAN_FINLAND": "Jag bekräftar att jag skattemässigt är bosatt (även) i andra länder än Finland.",
  "US_NATIONALITY": "Amerikanskt medborgarskap",
  "I_CONFIRM_NOT_US": "Jag bekräftar att jag skattemässigt inte är bosatt i USA.",
  "I_CONFIRM_US": "Jag bekräftar att jag skattemässigt är bosatt i USA.",
  "IF_YOU_ARE_A_US_CITIZEN": "OBS! Om du är amerikansk medborgare är du skattemässigt bosatt i USA. Om du är född i USA anses du generellt vara amerikansk medborgare, om du inte har avsagt dig ditt medborgarskap.",
  "COUNTRIES_OF_TAX_RESIDENCY": "Skattemässiga bosättningsländer",
  "PLEASE_PROVIDE_INFORMATION_ON_ALL_THE_COUNTRIES": "Uppge alla länder där du skattemässigt är bosatt. Om landet använder skatteregistreringsnummer, uppge även det. Om du har frågor angående definitionen av skattemässigt bosättningsland, vänligen kontakta en skatterådgivare eller den lokala skattemyndigheten.",
  "THE_TAX_IDENTIFICATION_NUMBER_USA": "Skatteregistreringsnummer för USA (TIN eller motsvarande)",
  "I_AM_SUBJECT_TO_TAXATION_IN_FINLAND": "Jag är skattskyldig i Finland.",
  "FINNISH_TAX_IDENTIFICATUON_NUMBER": "Skatteregistreringsnummer för Finland (= personbeteckning)",
  "TAX_IDENTIFICATION_NUMBER": "Skatteregistreringsnummer (TIN eller motsvarande)",
  "TIN_IS_REQUIRED": "TIN är en obligatorisk uppgift.",

  "INVESTMENT_COMPETENCE": "Investeringskunskap",
  "OPERATING_IN_THE_FINANCIAL_SECTOR": "Arbete inom finanssektorn",
  "I_OPERATE_IN_THE_FINANCIAL_SECTOR": "Jag arbetar / har arbetat inom finanssektorn med uppgifter som kräver erfarenhet av / kunskap om investeringsverksamhet.",
  "NUMBER_OF_YEARS": "Antal år",
  "ASSESSMENT_OF_THE_AVERAGE": "Uppskattning av storleken på investeringarna i medeltal",
  "IN_USE": "Jag använder",
  "FAMILIAR_WITH": "Jag känner till",
  "NOT_FAMILIAR_WITH": "Jag känner inte till",
  "KNOWLEGDE_OF_INVESTMENT_SERVICES": "Kännedom om investeringstjänster",
  "LISTED_SHARES": "Noterade aktier",
  "UNLISTED_SHARES": "Onoterade aktier",
  "STOCK_FUNDS": "Aktiefonder",
  "INTEREST_FUNDS": "Räntefonder",
  "HEDGE_FUNDS": "Hedgefonder",
  "REAL_ESTATE_FUNDS": "Fastighetsfonder",
  "INSURANCE_INVESTMENT": "Försäkringsplacering (investerings-, pensions- och sparförsäkringar)",

  "POLITICALLY_INFLUENTIAL_PERSON": "Politiskt inflytelserik person",
  "ELITE_MUST_BE_INFORMED_IF_YOU_OCCUPY_CENTRAL_PUBLIC_POSITION": "EAB Kapitalförvaltning Ab behöver veta om du har eller har haft ett betydande offentligt uppdrag inom staten eller i ledningen för en internationell organisation. Det samma gäller om en familjemedlem eller nära affärspartner har eller har haft ett motsvarande uppdrag.",
  "CENTRAL_POSITIONS_INCLUDE": "Betydande uppdrag är exempelvis",
  "HEAD_OF_STATE": "statschef, regeringschef, minister eller biträdande eller viceminister",
  "MEMBER_OF_PARLIAMENT": "riksdagsledamot",
  "MEMBER_OF_THE_SUPREME_COURT": "medlem i högsta domstol, författningsdomstol eller annat högre rättsligt organ, i vilkas beslut det inte är möjligt att söka ändring, förutom i undantagsfall",
  "MEMBER_OF_THE_COURT_OF_AUDITORS": "ledamot i revisionsrätt eller centralbanksdirektion",
  "AMBASSADOR": "ambassadör, chargé-d’affaires samt högt uppsatt officer inom försvarsmakten",
  "MEMBER_OF_AN_ADMINISTRATIVE": "medlem i styrelse, lednings- eller tillsynsorgan i ett statsägt bolag",
  "MANAGERIAL_POSITION": "uppdrag i ledningen för en internationell organisation.",
  "DO_YOU_HAVE_ANYTHING_TO_REPORT": "Har du uppgifter angående politiskt inflytande att meddela?",
  "SOMETNING_TO_REPORT": "Jag har uppgifter jag vill meddela",
  "NOTHING_TO_REPORT": "Ingenting att meddela",
  "IF_YOU_HAVE_SOMETHING_TO_REPORT": "Om ni har uppgifter att meddela, vänligen fyll i Utredning av politiskt inflytande och skicka den till Elite Alfred Bergs kundtjänst.",
  "REPORT_ON_POLITICAL_INFLUENCE": "Utredning av politiskt inflytande",

  "WITH_MY_SIGNATURE_I_CONFIRM": "Med min underskrift bekräftar jag att ovanstående uppgifter är korrekta och ger Elite Alfred Berg fullmakt att kontrollera att mina person- och kredituppgifter samt bankkontouppgifter är korrekta, och jag undertecknar avtalet.",
  "THE_AGREEMENT_ENTERS_INTO_FORCE": "Avtalet börjar gälla när Elite Alfred Berg har godkänt kundrelationen. Elite Alfred Berg har rätt att avslå ansökan om Kunden inte uppfyller de krav som Elite Alfred Berg och lagen ställer på upprättande av kundrelation.",

  "WELCOME_TO_ELITE": "Välkommen som kund hos Elite Alfred Berg!",
  "YOUR_CLIENT_RELATIONSHIP_HAS_SUCCESSFULLY_BEGUN": "Du har nu antagits som kund!",
  "YOUR_USERNAME_IS_PRESENTED_BELOW": "Nedan ser du ditt användarnamn. Välj ett lösenord som innehåller minst sex tecken. Lösenordet används för att logga in i Elite Alfred Bergs webbtjänst.",
  "YOUR_USERNAME_IS_PRESENTED_BELOW1": "Nedan ser du ditt användarnamn. Lägg det på minnet.",
  "YOUR_USERNAME_IS_PRESENTED_BELOW2": "Välj ett lösenord som innehåller minst sex tecken. Lösenordet används för att logga in i Elite Alfred Bergs webbtjänst.",
  "YOUR_CLIENT_RELATIONSHIP_IS_WAITING": "Din kundansökan väntar på bekräftelse",
  "WE_WILL_PROCESS_THE_APPLICATION": "Tack för din kundansökan! Vi behandlar den så snart som möjligt och kontaktar dig när din kundrelation har bekräftats.",
  "YOUR_PASSWORD_HAS_BEEN_SAVED": "Ditt lösenord sparades",
  "NOW_YOU_CAN_LOG": "Du kan nu logga in i Elite Alfred Bergs webbtjänst med ditt användarnamn och lösenord.",
  "CLICK_THIS_TO_PROCEED_TO_THE_ELITE_ONLINE_SERVICE": "Gå till Elite Alfred Bergs webbtjänst här.",
  "SAVING_PASSWORD": "Lösenordet sparas",
  "CUSTOMER_ALREADY_EXISTS_TITLE": "Ert kundförhållande är redan öppnat",
  "CUSTOMER_ALREADY_EXISTS_DESC": "Ert kundförhållande är redan öppnat till Elite Alfred Bergs webbtjänst.",

  // pankkiyhtetstieto
  "ADD_NEW_BANK_ACCOUNT": "Lisää uusi pankkiyhteystieto",
  "ADD_NEW_BANK_ACCOUNT_THAT_CAN_BE_USED_TO_WITHDRAW_MONEY_FROM_PORTFOLIO": "Lägg till ett nytt bankkontonummer som vi kan använda för att ta ut pengar från ditt portföljkonto",
  "ADD_INFORMATION": "Lisää yhteystieto",
  "BANK_ACCOUNT_NUMBER_MODEL": "FI12 3456 7890 1234 56",
  "BANK_ACCOUNT_SAVE_SUCCESS_MESSAGE": "Bank uppgifterna har registrerats!",

  "PROMOTED_FUND": "Fond i fokus",
  "NEWS": "Nyheter",
  "GO_TO_FRONTPAGE": "Gå till framsidan",

  // Käännökset HRJ:n verotuksellinen asuinvaltio -kenttään
  "AFGANISTAN": "Afghanistan",
  "AHVENANMAA": "Åland",
  "ALANKOMAAT": "Nederländerna",
  "ALANKOMAIDEN ANTILLIT": "Nederländska Antillerna",
  "ANTARKTIS": "Antarktis",
  "ANTIGUA JA BARBUDA": "Antigua och Barbuda",
  "ARABIEMIIRIKUNNAT": "Förenade Arabemiraten",
  "AZERBAIDZAN": "Azerbajdzjan",
  "BELGIA": "Belgien",
  "BOSNIA JA HERTSEGOVINA": "Bosnien och Hercegovina",
  "BOUVETNSAARI": "Bouvetön",
  "BRASILIA": "Brasilien",
  "BRITTILÄINEN INTIAN VALTAMEREN ALUE": "Brittiska territoriet i Indiska oceanen",
  "BRITTILÄISET NEITSYTSAARET": "Brittiska Jungfruöarna",
  "CAYMANSAARET": "Caymanöarna",
  "DOMINIKAANINEN TASAVALTA": "Dominikanska republiken",
  "EGYPTI": "Egypten",
  "ESPANJA": "Spanien",
  "ETELÄ-AFRIKKA": "Sydafrika",
  "ETELÄ-GEORGIA JA ETELÄISET SANDWICHSAARET": "Sydgeorgien och Sydsandwichöarna",
  "ETELÄ-KOREA": "Sydkorea",
  "FALKLANDINSAARET": "Falklandsöarna",
  "FÄRSAARET": "Färöarna",
  "FIDZI": "Fiji",
  "FILIPPIINIT": "Filippinerna",
  "GRÖNLANTI": "Grönland",
  "HEARD- JA MCDONALDSAARET": "Heard-och McDonaldsöarna",
  "INTIA": "Indien",
  "IRAK": "Irak",
  "IRAN": "Iran",
  "IRLANTI": "Irland",
  "ISLANTI": "Island",
  "ISO-BRITANNIA": "Storbritannien",
  "ITALIA": "Italien",
  "ITÄ-TIMOR": "Östtimor",
  "ITÄVALTA": "Österrike",
  "JAMAIKA": "Jamaica",
  "JAPANI": "Japan",
  "JORDANIA": "Jordania",
  "JOULUSAARI": "Julön",
  "KAMERUN": "Kamerun",
  "KANADA": "Kanada",
  "KAP VERDE": "Kap Verde",
  "KAZAKSTAN": "Kazakstan",
  "KENIA": "Kenya",
  "KIINA": "Kina",
  "KIRGISIA": "Kirgizistan",
  "KOLUMBIA": "Colombia",
  "KOOKOSSAARET": "Kokosöarna",
  "KREIKKA": "Grekland",
  "KROATIA": "Kroatien",
  "KUUBA": "Kuba",
  "KYPROS": "Cypern",
  "LIBANON": "Libanon",
  "LIETTUA": "Litauen",
  "LUXEMBURG": "Luxemburg",
  "MAKEDONIA": "Makedonien",
  "MALEDIIVIT": "Maledives",
  "MALESIA": "Malaysia",
  "MANSAARI": "Isle of Man",
  "MAROKKO": "Marocko",
  "MEKSIKO": "Mexiko",
  "MIKRONESIA": "Mikronesia",
  "NORJA": "Norge",
  "PALESTIINA": "Palestina",
  "POHJOIS-MARIAANIT": "Nordmarianerna",
  "PORTUGALI": "Portugal",
  "PUOLA": "Polen",
  "RANSKA": "Frankrike",
  "RANSKAN ETELÄISET ALUEET": "Franska södra territorierna",
  "RUOTSI": "Sverige",
  "SAINT BARTHELÉMY": "Saint Barthelémy",
  "SAINT KITTS JA NEVIS": "Saint Kitts and Nevis",
  "SAINT VINCENT JA GRENADIINIT": "Saint Vincent och Grenadinerna",
  "SAINT-PIERRE JA MIQUELON": "Saint Pierre och Miquelon",
  "SAKSA": "Tyskland",
  "SAMBIA": "Sambia",
  "SÃO TOMÉ JA PRÍNCIPE": "São Tomé och Príncipe",
  "SAUDI-ARABIA": "Saudiarabien",
  "SUOMI": "Finland",
  "SVALBARD JA JAN MAYEN": "Svalbard och Jan Mayen",
  "SVEITSI": "Schweiz",
  "SWAZIMAA": "Swaziland",
  "TADZIKISTAN": "Tadzjikistan",
  "TAIWAN": "Taiwan",
  "TANSANIA": "Tanzania",
  "TANSKA": "Danmark",
  "THAIMAA": "Thailand",
  "TRINIDAD JA TOBAGO": "Trinidad och Tobago",
  "TSEKKI": "Tjeckien",
  "TURKKI": "Turkiet",
  "TURKS- JA CAICOSSAARET": "Turks- och Caicosöarna",
  "UKRAINA": "Ukraina",
  "UNKARI": "Ungern",
  "UUSI-SEELANTI": "Nya Zeeland",
  "VATIKAANI": "Vatikanen",
  "VENÄJÄ": "Ryssland",
  "VIRO": "Estland",
  "WALLIS JA FUTUNA": "Wallis- och Futunaöarna",
  "YHDYSVALLAT": "USA",
  "VALKO-VENÄJÄ": "Vitryssland",
  "YHDYSVALTAIN NEITSYTSAARET": "Amerikanska Jungfruöarna",
  "YHDYSVALTAIN TYYNENMEREN ERILLISSAARET": "Förenta staternas mindre öar i Oceanien och Västindien",

  "LOGIN": {
      "MOVE_TO_LOGIN": "Gå till inloggningen",
      "CHECK_OUT_ONLINE_SERVICE": "Läs mer om Elite Alfred Bergs tjänster",
      "CHECK_OUT_PERSONNEL_FUNDS": "Läs mer om personalfonderna",
      "ONLINE_SERVICE_IS_FOR_YOU": "Webbtjänst för dig som har placeringar hos Elite Alfred Berg. Fondförvaringen hos Elite Alfred Berg är avgiftsfri.",
      "THIS_SERVICE_IS_FOR_YOU": "Den här tjänsten är för dig som har en personal- och belöningsfond hos Elite Alfred Berg. Här ser du uppgifterna om ditt eget medlemskap.",
      "EVLI_ONLINE_SERVICE": "EAB Webbtjänsten är nu My Evli",
      "EVLI_ONLINE_SERVICE_IS_FOR_YOU": "Tjänsten gör det möjligt för kunderna att följa med utvecklingen av sin förmögenhet, teckna fonder, köpa aktier, läsa företagsanalyser och mycket mer.",
      "EVLI_GO_TO_LOGIN": "Logga in på My Evli",
      "EVLI_CHECK_OUT_ONLINE_SERVICE": "Bekanta dig med Evlis tjänster",
      "EVLI_MY_EVLI_LOGIN_LINK": "https://m2.evli.com/m2/wealth?lang=sv",
      "EVLI_COM_LINK": "https://www.evli.com/sv/",
  },

  "SHORT": {
      "DAY": "d",
      "WEEK": "v",
      "MONTH": "mån",
      "YEAR": "år",
      "PHONE_NUMBER": "Tfn",
      "MONDAY": "Må",
      "TUESDAY": "Ti",
      "WEDNESDAY": "On",
      "THURSDAY": "To",
      "FRIDAY": "Fr",
      "SATURDAY": "Lö",
      "SUNDAY": "Sö",
      "EURO_PER_CALL": "€/samtal",
      "EURO_PER_MINUTE": "€/min"
  },

  "INFO_TEXTS": {
      "TRUE_BENEFICIARIES": "Om företaget eller samfundet har ägare vars andel  och/eller röstantal överstiger 25 % eller om en fysisk person har rätt att utse eller avsätta majoriteten av ledamöterna i styrelsen eller ett liknande organ, måste deras uppgifter anges här.",
      "MIFID_REQUIRED": "En del av kunduppgifterna saknas eller är föråldrade. Lagen förpliktar oss att fråga efter uppgifterna. Du kan för tillfället låta bli att ange uppgifterna om du vill, men vi rekommenderar att du skriver in dem redan nu.",
      "CONFIRMATION_CODE_REQUIRED": "Vi har skickat dig ett SMS med bekräftelse-ID. Ange koden i fältet nedan och klicka på Bekräfta.",
      "404": "Filen kan ha flyttats eller tagits bort. Kontrollera att du skrev adressen rätt.",
      "500": "Något gick fel i behandlingen av din begäran och servern kunde inte lösa problemet. Vänligen försök på nytt eller kontakta oss.",
      "500_TITLE": "Vi beklagar, servern råkade ut för ett oväntat fel.",
      "CONFIRMATION_CODE_REQUIRED_SMS_NOT_SENT": "Vi har redan tidigare skickat dig ett SMS med bekräftelse-ID. Ange koden i fältet nedan och klicka på Bekräfta. Vi skickar inte ett nytt meddelande förrän det gått fem minuter sedan det föregående meddelandet skickades.",
      "ELECTRONIC_SIGNATURE_P1": "Kunden är medveten om och godkänner att användningen av elektroniska kommunikationsmedel är förknippad med särskilda risker bland annat på grund av att meddelandet eventuellt inte kommer fram eller kan komma till utomståendes kännedom eller att utomstående kan modifiera meddelandets innehåll. Elite Alfred Berg har rätt att lita på att de meddelanden som företaget mottagit via elektroniska kommunikationsmedel är äkta och har rätt innehåll.",
      "ELECTRONIC_SIGNATURE_P2": "Elite Alfred Berg har rätt att lämna ut uppgifter till tredje part i enlighet med gällande lagstiftning.",
      "ELECTRONIC_SIGNATURE_P3_PART1": "Kunden godkänner att Elite Alfred Berg lämnar ut den uppgifter om tjänsteleverantör, tillhandahållna placeringstjänster, finansiella instrument och deras risker, kundklassificering, incitament, hantering av konflikter samt de förfaranden och principer som tillämpas vid tillhandahållandet av placeringstjänsten som omfattas av tjänsteleverantörens informationsskyldighet enligt lagen om investeringstjänster, samt förhandsinformationen om distansförsäljningen enligt konsumentskyddslagen",
      "ELECTRONIC_SIGNATURE_ON_THEIR_WEBSITE": "på sin webbplats",
      "ELECTRONIC_SIGNATURE_P3_PART2": "eller via annat elektroniskt kommunikationsmedel.",
      "ERROR_UPDATING_INFO": "Uppdateringen av uppgifterna misslyckades. Det kan bero på att ett fel inträffade vid online-identifieringen, koderna inte är desamma som användes då användarnamnet skapades eller på att systemet innehåller felaktig information.Kontakta vår kundtjänst för uppdatering av kontaktuppgifterna.",
      "ERROR_IN_ONLINE_AUTHENTICATION": "Onlineverifiering misslyckades. Det kan bero på att ett fel inträffade vid online-identifieringen, koderna inte är desamma som användes då användarnamnet skapades eller på att systemet innehåller felaktig information.Kontakta vår kundtjänst för uppdatering av kontaktuppgifterna.",
      "NEW_CUSTOMER_SUCCESS_CONTRACT_PACKAGE_WILL_BE_SENT_BY_MAIL": "Ditt kundavtalspaket skickas under de närmaste dagarna till den adress du angett.",
      "NEW_CUSTOMER_SUCCESS_TITLE": "Välkommen som kund och tack för förtroendet.",
      "NEW_CUSTOMER_USING_USERID": "I fortsättningen kan du logga in på tjänsten med ditt användarnamn och det lösenord du valt. Vi rekommenderar att du håller användarnamnet och lösenordet separat för att garantera ditt dataskydd.",
      "ORDER_CONFIRMATION_INFO": "Uppdrag som getts på bankdagar före kl. 10 hanteras under samma dag. Uppdragen genomförs enligt fondernas regler.",
      "SIGN_CONTRACTS": "Läs avtalsvillkoren noga och underteckna dem elektroniskt.",
      "SUBSCRIPTION_CONFIRMATION_INFO": "När du tecknar en specialplaceringsfond för en minderårig förbinder du dig vid teckningen att tillställa fondbolaget magistratens tillstånd till teckningen.",
      "USERID_CREATED_TITLE": "Nytt användarnamn skapat",
      "YOU_CAN_NOW_LOGIN_TO_SYSTEM": "Du kan nu logga in på tjänsten via denna länk",
      "ERROR_UPDATING_BANK_ACCOUNT": "Kunde inte uppdatera motkontot för portföljen. Det kan bero på att ett fel inträffade vid online-identifieringen, koderna inte är de samma som användes för att skapa användarnamnet eller systemet innehåller felaktig information. Du kan kontakta vår kundtjänst för att uppdatera kontaktinformationen. Kontaktuppgifterna visas nedan.",
      "ERRORS_WHEN_SENDING_THE_INFORMATION": "Ett fel inträffade vid sändning av formulär eller annan information. Felet beror på att en del av de inmatade uppgifterna saknas eller är i fel format. På listan nedan finns namnen på de fält som innehåller fel samt mera detaljerade felmeddelanden."
  },

  "RISK_CLASS_DESCRIPTIONS": {
      "SECURITY_ORIENTED": "När placeringen innehas den rekommenderade minimitiden, motsvarar risken placeringsrisken på aktiemarknaden. Avkastningsmålet för samma period är något högre än avkastningsmålet på aktiemarknaden.",
      "CAUTIOUS": "När placeringen innehas den rekommenderade minimitiden, är risken att förlora kapitalet lägre än för genomsnittliga aktieplaceringar och därför är avkastningsmålet något högre än den genomsnittliga avkastningen på enbart ränteplaceringar.",
      "MODERATE": "Inom den kortaste rekommenderade tiden motsvarar placeringens risk och avkastningsmål en balanserad kombination av placeringar i aktier och räntor. Risken är något lägre än för en kapitalplacering och avkastningsmålet är högre än för en ränteplacering men lägre än avkastningsmålet för en kapitalplacering.",
      "RETURN_ORIENTED": "När placeringen innehas den rekommenderade minimitiden, motsvarar risken placeringsrisken på aktiemarknaden. Avkastningsmålet för samma period är något högre än avkastningsmålet på aktiemarknaden.",
      "AGGRESSIVE": "Avkastningsmålet inom den rekommenderade placeringstiden överstiger avsevärt den genomsnittliga avkastningen på aktiemarknaden och samtidigt är risken för partiell eller total förlust av kapitalet betydligt högre än när man placerar på aktiemarknaden."
  },

  "ORDER_TYPES": {
      "Withdrawal": "Uttag",
      "Buy": "Inköp",
      "Subscription": "Inköp",
      "Sell": "Försäljning",
      "Redemption": "Försäljning"
  },

  "ORDERS_NAV": {
      "BROWSE_ORDERS": "Bläddra bland uppdragen",
      "BUY_FUND": "Köp fonden",
      "SELL_FUND": "Sälj fonden",
      "MAKE_ORDER": "Gör en investering",
      "WITHRAW_MONEY": "Ta ut pengar ur portföljen",
      "DEPOSIT_MONEY": "Sätt in pengar till portföljen",
      "BROWSE": "Bläddra",
      "BUYD": "Köp",
      "SELL": "Sälj",
      "WITHRAW": "Ta ut",
      "DEPOSIT": "Deponera",
  },

  "ORDERS_BUY": {
      "CHOOSE_PORTFOLIO": "Välj en portfölj",
      "FUND_TO_BUY": "Fond att förvärva",
      "PURPOSE_OF_BUY": "Förvärvets syfte",
      "NEW_ORDER_PORTFOLIO_AVAILABLE_SUM": "Disponibelt saldo",
      "NEW_ORDER_PORTFOLIO_BALANCE": "Portföljens saldo",
      "BUY": "Köp",
      "DRAFT_NOTIFICATION": "Portföljens nuvarande värde räcker inte till en teckning. Vi slutför teckningen, då teckningsbeloppet har flyttats till er portfölj. Överföring av likvida medel tar oftast 1-3 bankdagar.",
      "CREATE_NEW_PORTFOLIO_TEXT": "Du har ännu inte en portfölj med möjlighet att utföra fondköp. Du kan dock skapa en portfölj vid behov",
      "HERE": "här",
      "CREATE_NEW_PORTFOLIO": "Skapa portfölj"
    },

  "ORDERS_SELL": {
      "FUND_TO_SELL": "Fond som ska säljas",
      "SELL_PORTIONS": "Andelar som ska säljas",
      "SELL_PORTIONS_AVAILABLE": "Tillgängligt andelar som ska säljas",
      "SELL_ALL_PORTIONS": "Sälj allt",
      "SUM_ESTIMATE": "Uppskattning om beloppet som säljs",
      "ESTIMATE_INFO": "Uppskattningen preciseras senare",
      "PERCENTAGE": "procent",
      "MANAGEMENT_OF_REDEMPTION_FUND": "Behandlingssätt för inlösta medel ",
      "WITHDRAW_FUNDS": "Jag vill ha medlen på mitt bankkonto",
      "KEEP_FUNDS_IN_PORTFOLIO": "Jag lämnar medlen i portföljen",
      "TOOLTIP": "Vid en försäljningsorder säljs fondens andelar ur portföljen i den ordning andelarna skaffats. En order kan inte allokeras till ett specifikt inköpsparti."
  },

  "ORDERS_DEPOSIT": {
      "CHOOSE_PORTFOLIO": "Välj portfölj",
      "DEPOSIT_HEADER": "Sätt in pengar till portföljen",
      "DEPOSIT_SUBHEADER_PAYTRAIL": "Härifrån kan du sätta in pengar för din portfölj. Pengarna du överfört är genast tillgängliga i EAB Nättjänsten",
      "DEPOSIT_SUBHEADER_TRANSFER": "Här får du information om hur du deponerar pengar ii din fondportfölj hos Elite Alfred Berg från ditt bankkonto.",
      "DEPOSIT_HELP": "Du kan deponera pengar på ditt fondkonto hos Elite Alfred Berg genom att betala avgiften i din egen bank med nedanstående uppgifter. För betalningen behöver du en betalningsreferens, mottagarens namn och kontonummer.",
      "BIC": "BIC",
      "RECEIVER_NAME": "Mottagarens namn",
      "RECEIVER_ACCOUNT": "Mottagarens kontonummer",
      "BANK_NAME": "Bank",
      "PAYMENT_REFERENCE": "Betalningsreferens",
      "ELITE_ACCOUNT_NAME": "EAB Kapitalförvaltning Ab / Kundtillgångar",
      "GIVE_AMOUNT": "Ange beloppet som ska överföras",
      "CHOOSE_PAYMENT_METHOD": "Välj betalningsmetod",
      "MOVE_TO_PAYMENT": "Gå till insättning",
      "AMOUNT_TO_BE_TRANSFERRED": "Beloppet som ska överföras",
      "AMOUNT_MUST_BE_BETWEEN": "Beloppet måste vara mellan",
      "PAYMENT_METHOD": "Betalningsmetod",
      "DEPOSIT_INFORMATION": "Insättningens detaljer",
      "RETURN_TO_ORDERS": "Återgå till uppdragen",
      "PAYMENT_OK": "Insättningen av medel i portföljen lyckades. Medlen är omedelbart tillgängliga för inköpsorder.",
      "PAYMENT_NOK": "Ett fel inträffade och insättningen avbröts",
      "SAVING_DEPOSIT": "Bearbetar din insättning",
      "PAYMENT_CANCELLED": "Insättningen avbröts",
      "CONTACT_CUSTOMER_SERVICE": "Kontakta vår kundservice vid behov",
      "SUM_ISNUMERIC": "Summan måste vara ett numeriskt värde.",
      "PAYTRAIL_ONLINE_PAYMENT": "Paytrail online betalning",
      "DEPOSIT_SUCCESSFUL": "Insättningen lyckades"
  },

  "ORDERS_CONFIRMATION": {
      "AND_KEEP_FUNDS_IN_PORTFOLIO": "och medlen blir kvar i din portfölj.",
      "AND_WITHDRAW_FUNDS": "och medlen sätts in på ditt bankkonto.",
      "CONFIRM_BUY": "Bekräfta köpuppdrag",
      "CONFIRM_SELL": "Bekräfta säljuppdrag",
      "CONFIRM_WITHDRAW": "Bekräfta uttagsuppdrag",
      "BUY_CONFIRMATION": "Bekräftelse av köpuppdrag",
      "SELL_CONFIRMATION": "Bekräftelse av säljuppdrag",
      "WITHDRAW_CONFIRMATION": "Bekräftelse av uttagsuppdrag",
      "CONFIRM_KIID_MONTHLY_1": "Jag har läst ",
      "CONFIRM_KIID_MONTHLY_2": "avtalsvillkor",
      "CONFIRM_KIID_MONTHLY_3": ", ",
      "CONFIRM_KIID_1": "Jag har läst ",
      "CONFIRM_KIID_2": "faktabladet om fonden",
      "CONFIRM_KIID_3": "de allmänna villkoren för Elite Alfred Bergs placeringstjänst",
      "CONFIRM_KIID_4": " och ",
      "CONFIRM_KIID_5": "fondens regler",
      "CONFIRM_KIID_6": " och godkänner dem.",
      "CONFIRM_KIID_DOWNLOAD_1": "faktabladet om fonden (pdf)",
      "CONFIRM_KIID_DOWNLOAD_2": "fondens regler (pdf)",
      "ENTER_PASSWORD": "Ange ditt lösenord",
      "ENTER_CONFIRMATION_CODE": "Ange bekräftelsekoden",
      "SELL": "försäljning",
      "WITHDRAW": "uttag",
      "TO_PORTFOLIO": "till portföljen",
      "CONFIRM_WITH_ONLINEID": "Beställningen bekräftas genom online-autentisering."
  },

  "ORDERS_WITHDRAW": {
      "BALANCE_AVAILABLE_FOR_WITHDRAW": "Saldo som kan tas ut",
      "ACCOUNT_FOR_WITHDRAW": "Konto som pengarna sätts in på",
      "CHOOSE_BANK_ACCOUNT": "Välja konto som pengarna sätts in på.",
  },

  "ORDERS_BUY_PIPE": {
      "YOURE_BUYING": "Du är påväg att skapa ett teckningsuppdrag i fonden",
      "CHOOSE_CUSTOMER": "Välj klientskap, till vars portfölj du vill göra fondteckingen",
      "NO_CUSTOMERS": "Inga lämpliga konton hittades.",
      "CHOOSE": "Välj klientskap",
      "WHAT_AMOUNT": "Summa",
      "TARGET_PORTFOLIO": "Andelarna kommer att tecknas i portföljen",
      "SEE_DEPOSIT_INFO": "Se uppgifterna för deponeringen",
      "NOT_ENOUGH_MONEY": "Summan är större än tillgängligt saldo. Överför mera pengar till fondkontot.",
      "CANCEL_BUY": "Avboka teckning",
      "WRITE_NAME_HERE": "Ange portföljens namn här",
      "LOADING_DOCUMENTS": "Laddar dokument",
      "LOADING_INFORMATION": "Laddar uppgifter om klientskap",
      "BUY_SUCCESSFUL": "Fondteckningen lyckades",
      "YOUVE_BOUGHT": "Du har tecknat fonden",
      "SUBSCRIPTION_PORTFOLIO": "Andelar tecknades i portföljen",
      "GO_TO_WEB_SERVICE": "Gå till webbtjänst här",
      "PORTFOLIO_NUMBER": "Portföljnummer",
      "SINGLEPURCHASE": "Engångsköp",
      "MONTHLYSAVING": "Månatligt sparavtal",
      "CHANGETOSINGLE": "Byt till engångsköp",
      "CHANGETOMONTHLY": "Byt till månatligt sparavtal"
  },

  "ORDERS_MONTHLY": {
      "START_MONTHLY_SAVING": "Starta månadssparande", // "Aloita kuukausisäästäminen", 
      "START_MONTHLY_SAVING_HERE": "Här kan du börja spara månatligt.",
      "START_MONTHLY_SAVING_HERE_DESCRIPTION": "Månatligt sparande är ett lätt och enkelt sätt att börja spara regelbundet. Genom att spara månatligt sprider du tidsmässig risk då du sparar summan som du har själv valt automatiskt varje månad.",
      "GIVE_NAME_TO_CONTRACT": "Namnge avtalet",
      "CONTRACT_NAME": "Namn",
      "CONTRACT_NAME_MUST_BE_GIVEN": "Namnet är för långt",
      "CONTRACT_NAME_EXAMPLE": "T.ex. Kalles sparavtal",
      "SAVE_FOR_ANOTHER_PERSON": "Jag vill börja spara för en annan person",
      "SAVE_FOR_ANOTHER_PERSON_DESCRIPTION": "Du kan lätt börja spara även för en annan person. Kontakta i så fall vår kundservice per telefon eller skicka kontaktbegäran genom att välja ”Ta kontakt”. Vår kundservice ger råd i hur man skall fortskrida med ärendet.",
      "PAYMENT_FREQUENCY": "Upprepning av betalning",
      "PAYMENT_DATE": "Betalningsdatum",
      "CONFIRNG": "Bekräfta",
      "MONTHLY": "månatligen",
      "EVERY_3_MONTHS": "Var 3:e månad",
      "EVERY_6_MONTHS": "Var 6:e månad",
      "FIRST_DAY": "Första dagen i månaden",
      "15TH_DAY": "15:e dagen i månaden",
      "LAST_DAY": "Sista dagen i månaden",
      "CREATING_MONTHLY_SUBSCRIPTION": "Du är påväg att skapa ett sparavtal och en teckning i fonden",
      "FREQUENCY_IS": "Uppdragets upprepning är",
      "DATE_IS": "och köpet verkställs",
      "CONTRACT_CREATED_HEADER": "Sparavtalet har slutits framgångsrikt!",
      "CONTRACT_CREATED_TEXT": "Du har nu slutfört ditt sparavtal. För att genomföra dina teckningar, gå till din nätbank och gör en återkommande betalning med hjälp av informationen nedan. När betalningen har mottagits kommer vi att teckna den fond som täcks av ditt sparavtal.",
      "WANT_TO_MAKE_A_SUBSCRIPTION": "Vill du ännu gära en enskild teckning i fonden?",
      "MAKE_SUBSCRIPTION": "Gör en enskild teckning",
      "RETURN_TO_ORDERS": "Återvänd till uppdragen",
      "CONTACT_CUSTOMER_SERVICE": "Kontakta oss",
      "ELITE_ACCOUNT_NAME": "EAB Kapitalförvaltning Ab / Kundmedel",
      "ACCOUNT_NUMBER": "FI61 3131 1001 2315 90",
      "BANK_NAME": "Handelsbanken",
      "BIC": "HANDFIHH",
      "UNKNOWN": "XXXXX",
      "STATUS": {
          "DRAFT": "Väntar på behandling"
      }
  },

  "ORDERS_MISC": {
      "HERE_YOU_CAN_BUY_FUND": "Här kan du sälja av fonden och ange det i din egen portfölj.",
      "HERE_YOU_CAN_SELL_FUND": "Här kan du sälja av din fond så att beloppet som de sålda andelarna ger överförs till din fond.",
      "HERE_YOU_CAN_WITHDRAW_MONEY": "Här kan du ta ut pengar från din portfölj och sätta in beloppet på ditt eget bankkonto.",
      "CANCEL_ORDER": "Ångra uppdrag",
  },

  "LONG": {
      "DAY": "Dag",
      "WEEK": "Vecka",
      "MONTH": "Månad",
  },

  "FENNIA": {
      "FENNIA_ASSET_MANAGEMENT_LTD_INNER_CIRCLE": "Fennia Kapitalförvaltning Ab:s insiderkrets",
      "REASON_FOR_BEING_INNER_CIRCLE": "Kriteriet för att betraktas som insider",
      "SUBSCRIPTION_CONFIRMATION_INFO": "Om du håller på att göra en teckning för någon annan (t.ex. minderåriga barn eller företag) ber vi dig kontakta Fennia Varainhoito Oy:s kundtjänst antingen per telefon på nummer 010 503 7990 eller per e-post på adressen info.varainhoito@fennia.fi.",
      "DEFINITION_OF_INNER_CIRCLE": "Kunden är en sammanslutning eller en stiftelse som hör till Fennia Kapitalförvaltning Ab:s närmaste krets och i vilken den bestämmanderätt som avses i bokföringslagen innehas av Fennia Kapitalförvaltning Ab, Försäkringsbolaget Fennia, Försäkringsaktiebolaget Fennia Liv eller en förvaltningsrådsledamot, styrelseledamot eller suppleant, verkställande direktör, biträdande VD, revisor, biträdande revisor eller huvudansvarig revisor, person i den operativa ledningen i ett av de ovannämnda bolagen, eller den ovannämnda personens make eller maka eller person i äktenskapsliknande förhållande (sambo eller registrerat parförhållande)."
  },

  "TRANSACTION_TYPENAMES": {
      "ADD": "Tillägg",
      "ASSETMANAGEMENTFEE": "Debitering av inköp",
      "CFDENTER": "CFD öppnande",
      "CFDSETTLE": "CFD stängning",
      "COST": "Serviceavgift",
      "CREDITINTERESTDEPOSITION": "Redovisning av placeringslåneränta",
      "CREDITINTERESTWITHDRAWAL": "Debitering av investeringslåneränta",
      "DELETE": "Borttagning",
      "DELETED": "Borttagning",
      "DEPOSITINTERESTDEPOSITION": "Kontering av depositionsränta",
      "DEPOSITINTERESTWITHDRAWAL": "Debitering av depositionsränta",
      "DEPOSITION": "Deposition",
      "DIVIDEND": "Dividend",
      "FEEREBATE": "Arvodesrabatt",
      "FUTURESCONTRACTENTER": "Öppnande av derivatposition",
      "FUTURESCONTRACTSETTLE": "Stängning av derivatposition",
      "FXTRADE": "Valutahandel",
      //"INTEREST": "Dröjsmålsränta",
      "MASTERBUY": "MasterBuy",
      "MASTERSELL": "MasterSell",
      "MERGE": "Fusion",
      "MGTFEEREB": "Förvaltningsarvode",
      "PENALTYINTEREST": "Dröjsmålsränta",
      "REDEMPTION": "Sälj",
      "REDEMPTIONDEPOSITION": "Försäljning",
      "SELLRECEIVABLE": "Försäljningsfordran",
      "SOURCETAXDEPOSITION": "Redovisning av källskatt",
      "SOURCETAXWITHDRAWAL": "Debitering av källskatt",
      "SUBSCRIPTION": "Inköp",
      "SUBSCRIPTIONWITHDRAWAL": "Debitering av inköp",
      "TRANSFER": "Överföring",
      "WITHDRAWAL": "Uttag",

      "PE_INTEREST_RETURN": "Additional interest",
      "PE_INTEREST_ADDITIONAL_RETURN": "Extra additional interest",
      "PE_INTEREST_FIXED": "Fast ränta",
      "CASH_DEPOSIT_LATE_ENTRY_COMPENSATION": "Late entry compensation",
      "CASH_DEPOSIT_RETROCESSION": "Rabatt på arvode",
      "CASH_WITHDRAW_FOUNDING_COST": "Etableringskostnader",
      "CASH_WITHDRAW_LATE_ENTRY_FEE": "Late entry fee",
      "BUY_EQUALIZATION_DEBT": "Tillägg",
      "PE_DIVIDEND": "Distribution",
      "PE_SUBSCRIPTION": "Inköp (Private Equity Fond)",

      "BUY": "Inköp",
      "BUY_PRIVATE_EQUITY_FUND": "Eftermarknad inköp",
      "SELL": "Sälj",

      "CA_DEPOSIT": "Fond Händelse Insättning",
      "CA_WITHDRAW": "Fond Händelse Uttag",
      "KICKBACK_SUBSCRIPTION": "Rabat på avgift",
      "DIVIDEND_SUBSCRIPTION": "Dividend",
      "RIGHTS_ISSUE": "Nyemission",
      "RETURN_OF_CAPITAL": "Återbetalat kapital",
      "COUPON_INTEREST": "Coupon Interest",
      "DEPOSIT": "Inlämning",
      "WITHDRAW": "Uttag",
      "OTHER": "Övrig",
      "INSTALLMENT": "Installment",

      // Used by the statement report
      "BALANCE": "Saldo",
      "ASSET_MANAGEMENT_FEE": "Kapitalförvaltningsarvode",
      "ASSET_MANAGEMENT_FEE2": "Kapitalförvaltningsarvode",

      "AMORTIZATION": "Amotering",
      "DEPOSIT_SWAP": "Överföring (Deponering)",
      "DONATION_REDEMPTION": "Donation (Uttag)",
      "DONATION_SUBSCRIPTION": "Donation (Deponering)",
      "EXPIRATION_SELL": "Förfall",
      "INTEREST": "Ränta",
      "SWITCH_REDEMPTION": "Byte av andelsserie (Inlösen)",
      "SWITCH_SUBSCRIPTION": "Byte av andelsserie (Teckning)",
      "TRANSFER_DEPOSIT": "Överföring till Portfolj",
      "TRANSFER_WITHDRAW": "Överföring från Portfölj",
      "WITHDRAW_SWAP": "Överföring (Uttag)",
  },

  "MONTHLYREPORTS": {
      "REPORTS": "Rapporter",
      "MONTHLY_REPORTS": "Månadsrapporter",
      "FUNDS_SEARCH": "Fondsökning",
      "REPORTDATE": "Rapporteringsdatum",
      "HEDGE": "AJ EAB Value Hedge",
      "OSAKE": "Evli Aktie",
      "KORKO": "Evli Ränta",
      "OPTIMAALIVARAINHOITO": "Evli Optimalförvaltning",
      "FOKUS_EUROOPPA": "Elite Alfred Berg Eurooppa Fokus",
      "FOKUS_SUOMI": "Elite Alfred Berg Suomi Fokus",
      "QUARTERLY_REPORTS": "Kvartalsrapporter",
      "QUARTERLY_REPORT": "Kvartalsrapport",
      "VUOKRATUOTTO": "Elite Alfred Berg Vuokratuotto",
      "ALYENERGIA": "Elite Älyenergia",
      "DOWNLOAD_PDF": "Ladda ner månadsrapport (pdf)",
      "DOWNLOAD_QUARTERLY_PDF": "Ladda ner den senaste kvartalsrapporten (pdf)"
  },

  "FUNDSEARCH": {
    "FUND_DOCUMENTS_INFO": "Bekanta dig med fondens dokument (på finska)",
    "FUND_DOCUMENTS_INFO_URL": "https://www.evli.com/tuotteet-ja-palvelut/rahastot/rahastot/eab-dokumentit",
    "SFDR_TOOLTIP_TITLE": "Vastuullisuus ympäristöön, yhteiskuntavastuuseen ja hallintotapaan liittyvissä asioissa.",
    "GOOD": "Grått",
    "BETTER": "Ljusgrön",
    "BEST": "Mörkgrön",
    "SFDR": "ESG",
    "PROFIT_3_MONTHS": "3 m",
    "PROFIT_6_MONTHS": "6 m",
    "PROFIT_YTD": "YTD",
    "PROFIT_1_YEARS": "1 y",
    "PROFIT_3_YEARS": "3 y",
    "SHOWN_FUNDS": "Visade medel",
    "FUNDS_FOUND_TOTAL": "Totalt hittat",
    "FUNDS": "medel.",
    "SEARCH_BY_KEYWORD": "Sök efter nyckelord",
    "3_MONTHS": "3 m",
    "12_MONTHS": "12 m",
  },

  // Jäsenpalvelun käännökset, CRM:stä tulevat arvot
  "Rahasto-osuuden arvo": "Fondandelens värde",
  "Maksettu rahasto-osuus": "Utbetalad andel",
  "Voittopalkkio": "Vinstdelning",
  "Yli/Alijäämä": "Över-/underskott",
  "Tuotto": "Avkastning",
  "Tulospalkkio": "Resultatpremie",
  "Edellisenä maksupäivänä maksettu rahasto-osuus": "Utbetalad andel på föregående betalningsdag",
  "Lisäosa": "Tilläggsdel",
  "Rahasto-osuus yhteensä": "Fondandelens värde efter betalning",
  "Sidotusta nostettavaan siirto": "Överförd disponibel del",
  "Arvonmuutos": "Värdeförändring",
  "Oikaisu": "Rättelse",

  "Sähköisesti": "På webbtjänsten",
  "Kirjeenä": "Per post",

  "Irtisanoutuminen": "Uppsägning",
  "Esteellisyys / Siirto rahastoon kuulumattomaan yhtiöön": "Jävighet/ Överflyttning till ett bolag som inte hör till personalfonden",
  "Määräaikainen": "Tidsbundet arbetsavtal",
  "Eläkkeelle siirtyminen": "Pensionering",
  "Kuolema": "Dödsfall",
  "Irtisanottu tuotannollisista tai taloudellisista syistä / Yritysjärjestely": "Uppsägning på grund av produktionstekniska skäl/Företagsarrangemang",
  "Lepäävä jäsenyys": "Vilande medlemskap i fonden",
  "Siirto toiseen rahastoon kuuluvaan yhtiöön": "Överflyttning till ett bolag som också hör till personalfonden",

  "Rahastoin palkkion kokonaan": "Fondering av premien i sin helhet",
  "Nostan käteisenä palkan yhteydessä": "Uttag kontant",
  "Rahastoin palkkiosta 75%": "Fondering av premien 75 %",
  "Rahastoin palkkiosta puolet (50%)": "Fondering av premien 50 % och  50 % uttag kontant",
  "Rahastoin palkkiosta 25%": "Fondering av premien 25 %",
  "Rahastoin palkkiosta 2/3": "Fondering av premien 2/3",
  "Rahastoin palkkiosta 1/3": "Fondering av premien 1/3",

  "Vahvistan, että olen Yhdysvaltojen kansalainen": "Jag intygar härmed att jag är amerikansk medborgare och/eller att jag har skatterättslig hemsvist i USA.",
  "Vahvistan, että en ole Yhdysvaltojen kansalainen": "Jag intygar härmed att jag varken är amerikansk medborgare eller har skatterättslig hemvist i USA.",

  "Valinta on lukittu": "Valet är låst",
  "Valintaa ei ole lukittu": "Valet är inte låst",

  /*
   * Jäsenpalvelun käännökset
   */
  "PERSONNELFUND": {
      "PERSONNELFUND_NAME": "Personal-/Bonus Fond",
      "FINANCIAL_PERIOD": "Räkenskapsperioden",
      "MY_FUND": "Min fond",

      // Ilmoitukset tallentamattomista tiedoista
      "NOT_SAVED": "Uppgifterna har inte sparats",
      "ARE_YOU_SURE": "Är du säker på att du vill lämna sidan utan att spara uppgifterna?",
      "EXIT": "Ja",
      "CANCEL": "Ångra",

      // Login
      "HRJ_LOGIN_FAIL": "Dina medlemskapsuppgifter hittas inte. Antingen är du inte medlem av personal fonden eller så har dina medlemskapsuppgifter inte än blivit genomförda till fonden. Vid behov vänligen kontakta vår kundservice:",

      // Etusivu
      "HOME": "Hem",
      "WELCOME": "Välkommen!",
      "WELCOME_TO_THE_PERSONNEL_FUND": "Välkommen till personalfondens web tjänst!",
      "IF_YOU_HAVE_ANY_QUESTIONS": "Om du har frågor om våra tjänster, eller om du har hittat ett fel i dina anställningsuppgifter eller bestämningen av din bonus, meddela oss.",
      "YOU_CAN_LEARN_MORE_ABOUT_THE_RULES": "Du kan bekanta dig med fondens regler genom att ladda ner filen nedan.",
      "MEMBER_SERVICE_PROVIDES": "På web tjänsten ser du din fondandels värde och du kan enkelt och säkert meddela allt gällande ditt medlemskap och din fondandel. ",
      "WE_RECEIVE_YOUR_ADDRESS1": "Vi får via din arbetsgivare dina adressuppgifter. Om du ändå vill ha posten gällande ditt medlemskap till en annan adress, anmäl din nya adress.",
      "WE_RECEIVE_YOUR_ADDRESS2": "Kom ihåg att uppdatera din e-postadress, så får du bl.a. en bekräftelse till din e-post på meddelanden gjorda på webbtjänsten.",
      "WITHDRAWAL": "Meddelande om lyftning",
      "HOLDINGS_IN_THE_FUND": "Sammanfattning om din fondandels värde",
      "RESTRICTED_FUND_UNIT": "Bundet sammanlagt",
      "WITHDRAWABLE_FUND_UNIT": "Disponibelt sammanlagt",
      "TOTAL_FUND_UNIT": "Värdet totalt",
      "YOU_MAY_NOT_WITHDRAW1": "Meddelandet om lyftning kan göras årligen endast under en bestämd tidsperiod. Tiden för att meddela om lyftning för den senaste värdebestämningen har gått ut.",
      "YOU_MAY_NOT_WITHDRAW2": "Kom ihåg att uppdatera ditt mobilnummer  så du kommer att få ett SMS när kallelsen till lyftning är möjligt att göra nästa år.",
      "MAKE_A_FUNDING_NOTIFICATION": "Meddela om fondering",
      "UPDATE_YOUR_CONTACT_INFORMATION": "Uppdatera dina kontaktuppgifter",
      "EDIT_YOUR_CONTACT_INFORMATION": "Ändra mina kontaktuppgifter",
      "CONTACT_CUSTOMER_SERVICE": "Kontakta oss",
      "TO_CUSTOMER_SERVICE": "Kundtjänst",
      "MAKE_A_FUND_UNIT_WITHDRAWAL_NOTIFICATION": "Meddela om uttag av fondandel",
      "DOWNLOAD_BYLAWS": "Ladda ner stadgar",
      "A_NOTIFICATION_OF_FUNDING": "Anmälan om fondering eller kontantuttag måste göras årligen.",
      "THE_REPORTING_PERIOD_IS_IN_PROGRESS": "Anmälningstiden för att anmäla fondering pågår och sista anmälningsdagen är ",
      "THE_LATEST_FUND_REPORTING_PERIOD_HAS_ENDED": "Den senaste anmälningstiden för fondering är slut.",
      "YOU_CAN_NOW_WITHDRAW_THE_AVAILABLE_SHARE": "Du kan ta ut den uttagbara andelen av din fondandel eller en del av den. Anmälningstiden för uttag pågår, och anmälningstiden tar slut ",
      "INSPECTED_FUND": "Utvald portfölj",

      // Rahasto-osuuteni
      "MY_FUND_UNIT": "Min fondandel",
      "MY_FUND_UNIT2": "Min fondandel",
      "MEMBERSHIP_DETAILS": "Medlemsuppgifter",
      "FUND_AND_MEMBERSHIP_DETAILS": "Fond- och medlemsuppgifter",
      "FUND_ESTABLISHED": "Fonden är grundad",
      "FUND_FINANCIAL_PERIOD": "Räkenskapsperioden",
      "MEMBERSHIP_PERIOD": "Din medlemstid i fonden",
      "EMPLOYMENT_END_DATE": "Anställningsförhållandets slutdatum",
      "EMPLOYMENT_END_REASON": "Orsak till att anställningsförhållandet upphör",
      "MEMBERSHIP_DETAILS_DELIVERY": "Utskick av medlemsuppgifter",
      "WWW_SERVICE": "På webbtjänsten",
      "HOME_BY_POST": "Per post",
      "IBAN": "IBAN",
      "BIC": "BIC",
      "WORKING_HOURS": "Arbetstid",
      "SALARY": "Behållning",
      "BONUSES_WHICH_ARE_FUNDED_DURING": "Fonderade premier per räkenskapsperiod",
      "TAX_PERCENTAGE": "Skatteprocent",
      "GIVE_TAXRATE_HALF_PERCENT_ACCURACY": "Ange skatteprocent med halv-% noggranhet",
      "FUNDING_DATE": "Premie överförs till fonden",
      "FUND_VALUE_DATE": "Värdebestämningsdag",
      "BONUS": "Belopp",
      "YOUR_PERSONAL_FUND_UNIT": "Beräkningen av min fondandel",
      "DATE": "Datum",
      "EXPLANATION": "Förklaring",
      "RESTRICTED_AMOUNT": "Bunden sammanlagt",
      "WITHDRAWABLE_AMOUNT": "Disponibelt sammanlagt",
      "VALUE_OF_FUND_UNIT": "Fondandelens värde",
      "CHANGE_IN_VALUE_TEXT": "Fondandelens värdeförändring beskriver värdeförändringen på fondens investeringsobjekt, vilken inte har realiserats till pengar. Den berättar, hur värdet av fondens förmögenhet har förändrats under räkenskapsperioden.",
      "PROFIT_TEXT": "Fondandelens avkastning beskriver från fondens investeringsobjekt erhållen avkastning, som har realiserats till pengar. Avkastningen inkluderar vinster, förluster och kostnader.",
      "FUND_UNIT_PAID": "Utbetalad andel på föregående betalningsdag",
      "PROFIT_REMUNERATION": "Vinstdelning",
      "PERFORMANCE_BASED_BONUS": "Resultatpremie",
      "SUPPLEMENT": "Tilläggsdel",
      "PORTION_OF_THE_PROFITS": "Andel av resultatet för senaste räkenskap (över-/ underskott + värdeförändring )",
      "PORTION_TRANSFERRED": "Överförd disponibel del",
      "RESTRICTED_AND_WITHDRAWABLE_IN_TOTAL": "Bunden och disponibel sammanlagt",
      "TOTAL_FUND_UNIT_AFTER_PAYMENT": "Fondandelens värde efter betalning",
      "FUND_CONTRIBUTIONS_MADE": "Under räkenskapsperioden betalade fondandelar",
      "CALCS_NONE": "Ingen tillgänglig kalkyl",
      "MIDYEAR_NONE": "Ingen tillgänglig bonus ",
      "SHARES_NONE": "Ingen tillgänglig andel",
      "SHARES_NOT_AVAILABLE_NOW": "Ingen tillgänglig andel just nu",

      "TRANSACTION_INFORMATION": "Transaktionsuppgifter",
      "WITHDRAWN_FUND_SHARE1": "Uttagen fondandel",
      "WITHDRAWN_FUND_SHARE2": "uttagen fondandel",
      "BACK_TO_THE_CALCULATION": "Tillbaka till beräkningen",

      "NO_ACTIVE_FINANCIAL_PERIOD": "Inget giltigt räkenskapsperiod",

      // Rahasto-osuuden nosto
      "FUND_UNIT_WITHDRAW": "Lyft av fondandelar",
      "READ_MORE_ABOUT_TAXATION": "Läs mera om beskattningen",
      "ABOUT_TAXATION": "Om beskattningen",
      "TO_PERSONAL_DETAILS": "Mina uppgifter",

      "WITHDRAWABLE_FUND_UNIT1": "Disponibelt sammanlagt",

      "MORE_INFORMATION": "Läs mer",
      "READ_MORE_ABOUT_FUND_UNIT_WITHDRAWAL": "Läs mera om lyft av fondandel",
      "READ_MORE_ABOUT_EMPLOYMENT_END_REASON": "Läs mer om avslutat arbetsförhållande",

      "EDIT_NOTIFICATION": "Redigera anmälan",
      "MAKE_FUND_UNIT_WITHDRAWAL_NOTIFICATON": "Gör en anmälan om lyft",
      "TEMPORARILY_UNABLE_TO_MAKE_NOTICES_OF_WITHDRAWAL": "Meddelande om uttag kan inte göras just nu.",
      "WITHDRAWABLE_AMOUNT_1": "Andel som kan lyftas",
      "I_WILL_WITHDRAW": "Jag lyfter från min fondandel",
      "OR": "eller",
      "TOTAL_VALUE": "Summa",
      "PAYABLE_FUND_UNIT_AMOUNT": "Fondandel som utbetalas",
      "PAID_FUND_UNIT": "Utbetald fondandel",
      "FROM_FUND": "från fonden",
      "TAXFREE": "Skattefri",
      "NOT_TAXFREE": "Skattepliktig fondandel",
      "WITHHOLDING": "Förskottsinnehållning",
      "TAX_INFORMATION1": "När du gör ett lyft ur fonden, är 20 % av summan helt skattefri inkomst och resterande 80 % beskattas enligt skattekortet. Kontrollera att du angett korrekta konto- och skatteuppgifter. Ingen kopia på skattekortet behöver sändas in, om det inte är frågan om ett 0 % eller källskattekort: i dessa fall bör skattekortet skannas in eller sändas per post till kundtjänsten för personal-/bonusfonder.",
      "TAX_INFORMATION2": "Ifall skattesatsen är okänd, uppbärs förskottsinnehållning på den skattepliktiga andelen enligt 60 %.",
      "TAX_INFORMATION3": "Uppgifterna om utbetald fondandel vidarebefordras till skattemyndigheten.",

      "FUND_BALANCE_SHEET_DAY": "Fondens balans- och utbetalningsdag",
      "THE_VALUE_OF_THE_FUND_UNIT": "Fondandelens värde fastställs årligen på fondens balansdag, dvs på värderingsdagen. Utbetalningsdagen fastställs på basen av balansdagen. Personalfondslagen stipulerar att betalningsdagen för fondandelar bör vara senast 4 månader efter fondens balansdag.",
      "IF_THE_EMPLOYMENT_CONTINUES": "När arbetsförhållandet fortsätter",
      "THE_FUND_WILL_TRANSFER": "I enlighet med fondens stadgar, överför fonden årligen 1-15 % till den andel som kan lyftas. Om en medlem så önskar, kan medlemmen antingen ta ut den årliga andelen i sin helhet, eller endast en del av den. En medlem kan avstå från att ta ut andelen som kan lyftas, och låta fonden placera andelen. Medlemmen kan under de efterföljande åren lyfta andelarna inklusive den placeringsavkastning som ackumulerats. Ett beslut om den andel som går till utbetalning fattas årligen.",
      "IF_THE_EMPLOYMENT_ENDS": "När arbetsförhållandet upphör",
      "AT_THE_END_OF_THE_EMPLOYMENT_RELATIONSHIP1": "I enlighet med personfondlagen utbetalas fondandelen i sin helhet till en medlem när arbetsförhållandet upphör. Avkastning på placeringarna samlas fram till den värderingsdag som föregår utbetalningsdagen. Utbetalningsdag för fondandelen bestäms på basen av datum och orsak till att arbetsförhållandet upphör.",
      "AT_THE_END_OF_THE_EMPLOYMENT_RELATIONSHIP2": "Utbetalning av fondandelar i situationer där arbetsförhållandet upphör:",
      "OWN_REQUEST": "Uppsägning på eget initiativ",
      "OTHER_INDIVIDUAL_GROUNDS_FOR_DISMISSAL": "Upphävande av arbetsavtal eller annan personlig grund för uppsägning",
      "END_OF_FIXED_TERM": "Tidsbegränsad anställning upphör",
      "TRANSFER_TO_COMPANY_WITH_NO_FUND_MEMBERSHIP": "Flyttning till bolag som inte tillhör fonden",
      "DEATH": "Dödsfall",
      "TRANSFER_TO_DUTIES_NOT_ENTITLED": "Övergång till arbetsuppgifter som inte berättigar till medlemskap i fonden",
      "AT_THE_END_OF_THE_EMPLOYMENT_RELATIONSHIP3": "Fondandelen utbetalas senast 4 månader efter fondens följande värderingsdag.",
      "AT_THE_END_OF_THE_EMPLOYMENT_RELATIONSHIP4": "Situationer där arbetsförhållandet upphör:",
      "DISMISSED_FOR_PRODUCTION_OR_FINANCIAL_REASONS": "Uppsägning på grund av produktionstekniska/ekonomiska orsaker",
      "RETIREMENT": "Pensionering",
      "COMPANY_REORGANISATION": "Omstrukturering av företaget",
      "AT_THE_END_OF_THE_EMPLOYMENT_RELATIONSHIP5": "En medlem så önskar, kan utbetalningen av fondandelar delas på fyra utbetalningsdagar (utbetalning en gång per år). Medlemmen kan själv bestämma storleken på raterna.",
      "AT_THE_END_OF_THE_EMPLOYMENT_RELATIONSHIP6": "Även de som mottagit stödpaketet kan dela upp utbetalningen av andelar över fyra år, ifall fondens stadgar tillåter detta. Fondens stadgar finns på mellanbladet Regler och meddelanden, här kan du kontrollera om den som mottagit stödpaketet har rätt till en periodisering.",
      "DORMANT_MEMBERSHIP": "Vilande medlemskap",
      "CHECK_IF_THE_FUND_IS_USING_DORMANT_MEMBERSHIP": "Fondens stadgar som finns på mellanbladet Regler och meddelanden, anger om om fonden tillåter vilande medlemskap samt villkoren för detta.",
      "YOUR_FUND_MEMBERSHIP_CONTINUES": "Ditt medlemskap i fonden upphör inte, men inga nya premier ackumuleras i fonden.",
      "DORMANT_MEMBER_MAY_MAKE_WITHDRAWALS": "Under ett vilande medlemskap kan man ta ut andelar årligen på fondens utbetalningsdag. En förutsättning för fortsatt medlemskap är, att en medlem lyfter maximalt den summa som kunde lyftas för om medlemskapet skulle fortgå.",
      "DURING_DORMANT_MEMBERSHIP": "Under ett vilande medlemskap är fondandelen med i placeringsverksamheten.",
      "YOU_MAY_ALSO_WITHDRAW_YOUR_FUND_UNIT_AS_A_WHOLE": "Du kan även lyfta hela din fondandel, i detta fall upphör medlemskapet i fonden.",

      "YOU_MAY_WITHDRAW_WILL_BE_PAID_AUTOMATICALLY": "",
      "YOU_MAY_WITHDRAW_WILL_NOT_BE_PAID_AUTOMATICALLY": "Du kan lyfta din fondandel i sin helhet eller endast en del av den. Gör anmälan om uttaget i fältet till höger i kolumnen, och bekräfta anmälan. Du kan modifiera din anmälan tills anmälningstiden för lyft går ut.",
      "YOUR_MEMBERSHIP_HAS_TERMINATED_1": "Ditt medlemskap går ut och hela din fondandel går till utbetalning. Om du så önskar, har du rätt att periodisera lyften över följande fyra år.",
      "YOUR_MEMBERSHIP_HAS_TERMINATED_2": "När du väljer rätten till periodiserat lyft, måste en ny anmälan om detta göras årligen under fondens anmälningstid. Du kan själv välja den summa från din andel som du årligen vill lyfta.",
      "YOUR_MEMBERSHIP_HAS_TERMINATED_3": "En periodisering av utbetalningen betyder att medlemskapet i fonden fortsätter. Värdet på den kvarblivande andelen fastställs årligen på basen av ifrågavarande värderingsdag.",
      "IF_YOU_DONT_REPORT_WILL_BE_PAID_AUTOMATICALLY": "Skulle du inte anmäla om periodisering, utbetalas din fondandel i sin helhet på betalningsdagen.",
      "IF_YOU_DONT_REPORT_WILL_BE_PAID_LAST_YEAR": "",
      "WITHDRAWAL_NOTICE_TIME_ENDS": "Tidsfristen för anmälan om lyft upphör ",
      "FUND_SHARE_WILL_BE_PAID": " och fondandelen utbetalas till ditt konto den ",
      "WITHDRAWAL_CONFIRM_MESSAGE_AND_EDIT_YOUR_DETAILS": "Du får ett bekräftelsemeddelande till din e-post. Kontrollera din e-postadress och ändra ditt kontonummer och skatteprocent på sidan ",
      "YOUR_MEMBERSHIP_HAS_TERMINATED_WILL_BE_PAID": "Ditt medlemskap håller på att upphöra och hela din fondandel går till utbetalning. Summan som utbetalas är synlig i nedan. ",
      "YOU_CAN_EDIT_YOUR_IBAN_AND_TAXRATE": "Du kan ändra din skatteprocent och kontonummer på sidan ",
      "AUXILIARY_MEMBERSHIP_1": "Enligt uppgifterna från löneadministrationen har du flyttat över till ett koncernbolag eller belöningssystem som inte berättigar till medlemskap i fonden. Därmed utbetalas den ackumulerade fondandelen i sin helhet till ditt konto den ",
      "AUXILIARY_MEMBERSHIP_2": ", och ditt medlemskap i fonden upphör.",
      "AUXILIARY_MEMBERSHIP_3": "Om du önskar kan du omvandla ditt medlemskap i fonden till ett s.k. vilande medlemskap i högst ",
      "AUXILIARY_MEMBERSHIP_4": " år. En förutsättning för vilande medlemskap är, att du av din fondandel lyfter maximalt den andel som du kunde lyfta om medlemskapet skulle fortsätta.",
      "AUXILIARY_MEMBERSHIP_5": "Ett vilande medlemskap innebär att medlemskapet i fonden fortsätter. Värdet på den andel som förblir outtagen, bestäms årligen i enlighet med ifrågavarande värderingsdag.",
      "AUXILIARY_MEMBERSHIP_6": "Anmälning om fortsatt vilande medlemskap bör göras på nytt årligen under fondens anmälningstid. Under ett vilande medlemskap ackumuleras ingen personalfondrat som företaget betalar till fonden.",
      "AUXILIARY_MEMBERSHIP_7": "Om du under ditt vilande medlemskap återvänder till ett bolag eller belöningssystem som omfattas av fonden, fortsätter ditt medlemskap i fonden utan avbrott, och utan den karenstid som stipuleras i stadgarna.",
      "FUND_UNIT_WITHDRAW_NOTIFICATION_PERIOD_IS_NOT": "Anmälningstiden för lyft är ej i användning!",
      "YOU_CAN_MAKE_WITHDRAWAL_NOTICE": "Anmälning om lyft kan endast göras under den årliga stipulerade anmälningstiden för lyft. Den senaste anmälningstiden för lyft har upphört ",
      "REPORT_YOUR_PHONE_NUMBER_1": "Kom ihåg att uppdatera ditt mobiltelefonnummer på sidan ",
      "REPORT_YOUR_PHONE_NUMBER_2": " så erhåller du i fortsättningen ett textmeddelande då anmälningstiden för lyft börjar!",
      "YOUR_MEMBERSHIP_HAS_TERMINATED_AND_FUND_SHARE_IS_PAID": "Din fondandel har utbetalats i sin helhet till ditt konto och ditt medlemskap i fonden har upphört!",
      "YOU_HAVE_NO_SHARES_YET": "Ingen fondandel som kan lyftas har ännu ackumulerats för dig i fonden.",
      "SHARES_WILL_BE_TRANSFERED_1": "Premien flyttas till fondandelarna vid uträkningen under följande värderingsdag den  ",
      "SHARES_WILL_BE_TRANSFERED_2": ".",
      "YOU_HAVE_REPORTED_YOUR_WITHDRAWAL": "Du har gjort nedanstående anmälan om lyft",
      "YOUR_FUND_UNIT_WILL_BE_PAID_1": "Din fondandel utbetalas ",
      "YOUR_FUND_UNIT_WILL_BE_PAID_2": " enligt följande:",
      "YOUR_FUND_UNIT_HAVE_BEEN_PAID": "Fondandelar har utbetalats till dig ",
      "YOU_WILL_BE_PAID": "Till ditt konto utbetalas ",
      "YOU_HAVE_BEEN_PAID": "Utbetalt till konto ",

      "INFORMATION_ON_THE_CALCULATION": "Skatteinformation",
      "ADDITIONAL_TAX_RATE": "Tilläggsskatteprocent",
      "ADDITIONAL_TAX_RATE_LIMIT": "Gräns för tilläggsskatt",
      "TAX_AT_SOURCE": "Källskattepliktig",

      "REMEMBER_TO_KEEP_YOUR_EMAIL_UP_TO_DATE": "Kom ihåg att hålla din e-postadress uppdaterad, och ändra om du vill ditt kontonummer för den uttagbara andelen i dina uppgifter.",

      // Rahastointi/Käteisnosto
      "YOUR_MEMBERSHIP_HAS_FINISHED_YOU_CANNOT_GIVE_FUNDING_NOTIFICATION": "Ditt medlemskap har upphört, du kan inte göra en anmälan om fondering-/kontantuttag. Kontakta kundtjänsten vid behov.",
      "THIS_FEATURE_IS_AVAILABLE_ONLY_WHERE_FUNDING_IS_VOLUNARY": "Denna funktion är tillgänglig endast för fonder där finansiering är frivillig.",
      "YOU_PREVIOUS_NOTICE": "Anmälan som gjorts under senaste anmälningstid",
      "PREVIOUS_NOTICE1": "Anmälan som gjorts under senaste anmälningstid ",
      "PREVIOUS_NOTICE2": " ",
      "FUNDING_OPTION_LOCKING": "Lås valet av fondering",
      "NO_PREVIOUS_NOTICE": "Inga uppgifter från föregående period",
      "CURRENT_FUNDING_NOTICE_CHOICE": "Ditt val av fondering för pågående anmälningstid",
      "YOU_HAVE_LOCKED_YOUR_CHOICES": "Du har låst fonderingen nedan. Om du vill ändra de val du gjort, avlägsna låsningen, gör nya val och bekräfta ditt val.",
      "REMOVE_LOCKING_AND_CHANGE_CHOICES": "Avlägsna låsning och ändra valen",
      "IF_YOU_WANT_TO_LOCK": "Du kan även välja och låsa en betalningsmetod tillsvidare.",
      "I_WANT_TO_LOCK": "Jag vill låsa mitt val.",
      "CONFIRM_MESSAGE": "Du får ett meddelande om bekräftelse till din e-post. Kontrollera e-postadressen i ",
      "NOT_NOTIFICATION_PERIOD": "Anmälningstiden för fondering/ kontantuttag har slutat.",
      "REWARD": "Premie andel",
      "FUND100": "Fondering av premien i sin helhet",
      "FUND75": "Fondering av premien 75 %",
      "FUND50": "Fondering av premien 50 % och  50 % uttag kontant",
      "FUND25": "Fondering av premien 25 %",
      "FUND67": "Fondering av premien 2/3",
      "FUND33": "Fondering av premien 1/3",
      "FUND300EUR": "Fondering av premien 300 eur",
      "FUND100EUR": "Fondering av premien 100 eur",
      "FUND150EUR": "Fondering av premien 150 eur",
      "FUND50EUR": "Fondering av premien 50 eur",
      "WITHDRAWCASH": "Uttag kontant",
      "RISK_STRATEGY": "Investerings strategi",
      "SELECTION_OF_INVESTMENT_STRATEGY": "Val av investerings strategi",
      "CAREFUL": "Försiktig strategi",
      "NEUTRAL": "Neutral strategi",
      "AGGRESSIVE": "Riskfylld strategi",
      "OWN_SHARE": "Bolagets egna aktier",
      "STRATEGY_YOU_HAVE_CHOSEN_1": "Du har valt en ",
      "STRATEGY_YOU_HAVE_CHOSEN_2": " för din fondandel av placeringsportföljen. Beslutet är permanent och kan inte ändras. I framtiden kommer alla premie andelar att placeras i vald investeringsstrategi.",
      "SAVE_SUCCESS_MESSAGE": "Uppgifterna har registrerats!",
      "SAVE_FAILED_MESSAGE": "Sparande av information misslyckades.",
      "SAVED_SUCCESSFULLY_NOT_SEND_EMAIL_MESSAGE": "Uppgifterna har registrerats, men e-post bekräftelse gick inte att skicka. Var vänlig och kontrollera din e-post adress.",
      "NOT_SAVED_MESSAGE": "Uppgifterna har inte registrerats. Var vänlig och försök på nytt eller ta kontakt med kundtjänsten med e-post jasenpalvelu@eai.fi.",
      "NOTIFICATION_PERIOD_HAS_ENDED": "Tiden för anmälan om fondering/kontantuttag är slut",
      "YOU_HAVE_REPORTED_EARLIER": "Dina anmälningar för tidigare perioder",
      "YOU_HAVE_NOT_REPORTED_EARLIER": "Du har inga anmälningar om fondering/kontantuttag för tidigare perioder, därmed kommer dina premier att betalas ut enligt fondens standardinställning.",
      "FUNDING_AND_WITHDRAWAL_NOTIFICATION": "Fondering/kontantuttag",
      "YOU_HAVE_REPORTED_EARLIER_FUNDING_AND_WITHDRAWAL": "Under tidigare räkenskapsperioder har du anmält följande angående din fondering:",
      "FUNDING_AND_WITHDRAWAL_CHOICE": "Valet av fondering/kontantuttag",
      "LOCKING": "Låsning",
      "YOU_HAVE_LOCKED": "Du har låst ditt val, därmed kommer dina premier att utbetalas enligt din senaste anmälan om fondering/kontantuttag.",
      "YOU_HAVE_NOT_LOCKED": "Du har inte låst ditt val, därmed kommer dina premier att betalas ut enligt fondens standardinställning, om du inte gör en ny anmälan om fondering/kontantuttag, då tiden för anmälan öppnas.",
      "YOU_HAVE_NOTIFIED_AS_RISK_STRATEGY": "Du har valt investeringsstrategi ",
      "YOU_HAVE_NOTIFIED_AS_RISK_STRATEGIES": "Du har valt investeringsstrategi/-strategier ",
      "RISK_STRATEGY_CHOICE_1": "Valet av investeringsstrategi gäller alla tidigare fonderade andelar samt kommande premier",
      "RISK_STRATEGY_CHOICE_2": "Valet av investeringsstrategi gäller alla tidigare fonderade andelar samt kommande premier, om du inte byter investeringsstrategi då tiden för anmälan öppnas.",
      "IF_YOU_DID_NOT_CHOSEN_RISK_STRATEGY": "Om du inte själv har valt investeringsstrategi, kommer neutral investeringsstrategi automatiskt att tillämpas.",
      "YOU_CANNOT_CHOOSE_RISK_STRATEGY_ANYMORE": "Den valda investeringsstrategin kan inte bytas.",
      "YOU_CAN_CHANGE_RISK_STRATEGY": "Investeringsstrategin kan bytas då tiden för anmälan åter öppnas.",

      // Säännöt ja tiedotteet
      "BYLAWS": "Fondens stadgar",
      "CHOOSE_FUND_RULE_YOU_WANT_TO_DOWNLOAD": "Välj stadga som du vill ladda",//needs translation
      "DOWNLOAD_FUND_RULE": "Ladda ner stadgar",
      "BYLAWS_NONE": "Inga tillgängliga stadgar",
      "NOTIFICATIONS": "Meddelanden på finska",
      "NOTIFICATIONS_NONE": "Ingen tillgänglig annons",

      // Omat tiedot
      "PERSONAL_DETAILS": "Mina uppgifter",
      "MY_CONTACT_INFORMATION": "Mina kontaktuppgifter",
      "NAME": "Namn",
      "STREET_ADDRESS": "Gatuadress",
      "POSTAL_CODE": "Postnummer",
      "POST_OFFICE": "Postanstalt",
      "MEMBERSHIP_LETTER_DELIVERY_METHOD": "Utskick av medlemsuppgifter",
      "MOBILE_NUMBER": "Mobilnummer",
      "EMAIL": "E-postadress",
      "WE_SEND_EMAIL_CONFIRMATION": "Vi skickar till dig per e-post en bekräftelse när du gjort anmälan om uttag eller fondering.",
      "DIRECT_MARKETING_PERMISSION": "Direktmarknadföringstillstånd",
      "DO_NOT_BULK_MESSAGE": "EAB Group Abp får skicka direktmarknadsföring till mig per e-post och får kontakta mig per telefon i direktmarknadsföringssyfte.",
      "TAX_RATE": "Skatteprocent",
      "TAX_RATE_EXTERNAL": "Skatteprocent (skattemyndighet)",
      "TAX_RATE_INFO": "20 % av fondandelar som utbetalas är skattefri inkomst. Resterande 80 % beskattas enligt skattekortet. Ifall skatteprocenten inte är känd, görs förskottsinnehållningen på den skattepliktiga andelen enligt 60 %.",
      "ACCOUNT_NUMBER": "Kontonummer",
      "COMMUNICATION_WITH_ELITE": "Kommunikation med Elite Alfred Berg",
      "I_WANT_MY_MEMBERSHIP_INFORMATION": "",
      "ADD_TAX_COUNTRY": "Lägg till beskattningsland",
      "I_CONFIRM_US_NATIONALITY": "Jag intygar härmed att jag är amerikansk medborgare.",
      "I_CONFIRM_NOT_US_NATIONALITY": "Jag intygar härmed att jag inte är amerikansk medborgare.",

      "TAXATION_COUNTRY": "Skatterättslig hemvist",
      "US_CITIZENSHIP": "Amerikanska medborgarskap",
      "US": "USA",
      "TIN": "Skatteregistreringsnummer",
      "TIN_NUMBER": "Skatteregistreringsnummer (skattegnr)/motsvarande",
      "TIN_NUMBER_US": "Skatteregistreringsnummer (skattegnr)/motsvarande, USA",
      "AFTER_CONFIRMATION_MESSAGE": "Efter att du bekräftat dessa uppgifter använder vi dem som standard. Kom ihåg att uppdatera dina kontaktuppgifter i fall de ändrar sig.",
      "OPTIONALLY_REPORT_ADDRESS_AND_ACCOUNT_INFORMATION": "I fall du vill använda annan kontaktuppgifter eller kontoinformation (inte obligatorisk)",
      "PERSONNEL_FUNDS_ARE_WITHIN_THE_REALM_OF": "Personalfonder/bonusfonder står under regelverket för internationell beskattning, och på grund av detta måste fonden utreda medlemmarnas skatterättsliga hemvist.",
      "US_CITIZENSHIP_NOT_CONFIRMED": "Uppgifter angående skattskyldighet har inte bekräftats.",
      "CONFIRM_US_CITIZENSHIP": "Var vänlig och bekräfta uppgifter om hemvist och uppgifter om amerikanskt medborgarskap före du lämnar sidan Egna Uppgifter.",
      "CONFIRM_US_CITIZENSHIP_BEFORE_FUNDING_NOTIFICATION": "Var vänlig och bekräfta uppgifter om hemvist och uppgifter om amerikanskt medborgarskap före du gör anmälan om fondering/kontantuttag.",
      "YOUR_PREVIOUSLY_MADE_NOTICE_CHANGED": "Observera att också uppgifterna i tidigare gjort meddelande om uttag har ändrats.",
      "YOU_HAVE_BEEN_REDIRECTED_MISSING_INFO": "Du har omdirigerats till dina uppgifter, eftersom det fattas obligatorisk information.",

      // Asiakaspalvelu
      "OFFICE_INFO": "Evli Abp",
      "EVLI_ADDRESS": "Alexandersgatan 19, 4 vån.",
      "EVLI_POSTALCODE_CITY": "P.O. Box 1081, 00101\n Helsingfors",
      "INVESTOR_SERVICE": "Investerartjänst",
      "INVESTOR_SERVICE_OPENING_HOURS": "kl 9.30-16.30",
      "INVESTOR_SERVICE_PHONE": "(09) 4766 9701",
      "CONTACT": "Kontakta oss",
      "CUSTOMER_SERVICE": "Kundservice",
      "GIVE_FEEDBACK": "Ge feedback",
      "CUSTOMER_SERVICE_CONTACT_NAME": "Elite Palkitsemispalvelut Oy",
      "CUSTOMER_SERVICE_ADDRESS": "Glogatan 3, 3:e vån",
      "CUSTOMER_SERVICE_POSTALCODE_CITY": "00100 Helsingfors",
      "FEEDBACK_OR_RECTIFUCATION": "Feedback eller rättelseyrkande",
      "WRITE_YOUR_COMMENT": "Skriv din fråga eller kommentar i det nedanstående textfältet. Du kan också meddela ifall du hittar felaktigheter i dina arbetsuppgifter eller grunderna av din premieandel.",
      "MESSAGE": "Meddelandet",
      "CONTACT_INFO_FOOTER": "Personal-/Bonus Fond kundtjänst",
      "SEND_SUCCESS_MESSAGE": "Uppgifterna har sänt!",
      "SEND_ERROR_MESSAGE": "Ett fel inträffade vid sändning av information.",
      "SEND": "Sänd",
      "SENDING_INFORMATION": "Sänder information",
      "ATTACHMENT": "Bilaga",
      "CONFIRM": "Bekräfta",
      "MY_MESSAGE_IS_ABOUT_FUND": "Mitt budskap handlar om fonden:",
      "FOOTER_CUSTOMER_SERVICE": "Kundtjänst för personalfonden",

      // tarjoussivu
      "SALE_HEADER": "Personal- och belöningsfondens medlemsförmåner",
      "SALE_INFO_1": "Bästa medlem i personal- och belöningsfonden!",
      "SALE_INFO_2": "Vi erbjuder nu medlemmar i personal- och belöningsfonder en unik möjlighet att teckna aktieserier i fonder som förvaltas av vår grupp, som vanligtvis endast riktar sig till professionella investerare. Som medlemsförmån kan du nu teckna dessa aktieserier utan teckningsavgift. Även förvaltningsavgifterna är förmånliga. Förmånen är endast tillgänglig för personal- och belöningsfonder och gäller endast fonderna nedanom.",
      "SALE_INFO_3": "Elite Palkitsemispalvelut Oy, som erbjuder personal- och belöningsfonder, är en del av Elite Alfred Berg. Elite Alfred Berg är en mångsidig investeringsexpert som hjälper finländska individer, professionella investerare och företag att förvalta sina tillgångar på ett heltäckande, systematiskt och ansvarsfullt sätt.",
      "SET_VISIBILITY": "Jag vill inte se den här sidan igen. (Valet sparas vid utloggning)"
  },

  // These should be a part of the WEB_REPORTS category, but that would break other translations in the tableGroupingService and portfolioReport.html
  // so they are here instead
  "Fund": "Fonder",
  "FundAM": "Fonder",
  "FundOLD": "Fonder",
  "Share": "Aktier",

  "PE_Fund_Equalization_Debt": "Distributions Receivable",
  "Private_Equity_Fund": "Private Equity Fonds",

"WEB_REPORTS": {
  // Salkkuraportti
  "PORTFOLIO_REPORT": "Portföljrapport",
  "INSTRUMENT": "Värdepapper",
  "MARKET_PRICE": "Inköpspris",
  "DATE_OF_RATE": "Datum",
  "ACQUISITION_VALUE": "Inköpsvärde",
  "UNREALIZED_PROFIT_OR_LOSS": "Orealiserad vinst / förlust",
  "UNREALIZED_PROFIT_OR_LOSS_SHORT": "Orealiserad V/F (€)",
  "PORTION_OF_ASSETS": "Andel av tillgångar",
  "ALL_ASSETS": "Alla tillgånger",
  "TOTAL_SUM": "tillsammans",
  "GROUPING": "Gruppering",
  "ASSET_TYPE": "Instrumentkategori",
  "CLASSIFICATION": "Instruments underkategori",
  "INSTRUMENT_TYPE": "Instrumenttyp",
  "CURRENCY": "Valuta",
  "REPORT_DATE": "Rapporteringsdatum",
  "NO_TRANSACTIONS": "Inga transaktioner i den valda portföljen.",
  "DOWNLOAD_REPORT": "Ladda ner fil",
  "COMMITMENT": "Åtagande",
  "COMPANY_WAGER": "Kapitalplacering",
  "TOTAL_REVENUE": "Helhetsavkastning",
  "PROFIT_DISTRIBUTIONS": "Vinstutdelning",
  "CAPITAL_RETURNS": "Teckningsprovision kapital",
  "SUBSCRIPTION_FEES": "Teckningsprovision",
  "INFORMATION_THROUGH_INVESTMENT": "Detaljer för hela investeringsperioden",
  "UNCALLED": "Icke-inkallat",
  "CALLED": "Inkallat",
  "LATE_ENTRY_COMPENSATION": "Late entry compensation",
  "FEE_RETURNS": "Rabatt på arvode",
  "LATE_ENTRY_FEE": "Late entry fee",
  "TOTAL_EQUALIZATION": "Utjämning",  
  "RECALLED_CAPITAL": "Recallable distribution",
  "FOUNDING_COSTS": "Etableringskostnader",
  "TWR_PROFIT": "TWR Avkastning",
  "TWR_DISCLAIMER1": "Avkastningen % är den tidsviktade avkastningen (eng. time-weighted return, TWR).",
  "TWR_DISCLAIMER2": "TWR mäter placeringsobjektets avkastning innan skatter och förskottsinnehållning, utan att ta hänsyn till insättningar eller uttag.",
  "TWR_DISCLAIMER3": "Med andra ord beskriver TWR värdeförändringen för ett finansiellt instrument som inte har köpts eller sålts ytterligare under investeringsperioden och där alla dividender återinvesterats.",

  // Tapahtumaraportti
  "TRANSACTION_REPORT": "Transaktionsrapport",
  "PURCHASE_DATE": "Inköpsdag",
  "SETTLEMENT_DATE" : "Betalningsdatum",
  "PIECE": "Andel",
  "PRICE_OF_SHARE": "Pris / Andel",
  "DELIVERY_FEE": "Leveransavgift",
  "TAX": "Skatt",

  // Toteutuneet tuotot
  "REALIZED_PROFITS": "Realiserade vinster",
  "NO_PROFITS_FOUND_WITH_CURRENT_PARAMETERS": "Inga resultat hittades med angivna parametrar",
  "PROFITS_AND_LOSSES": "Vinster och förluster",
  "PROFITS_AND_LOSSES_TOTAL": "Vinster och förluster totalt",
  "SELL_VALUE": "Försälningsvärde",
  "PORTFOLIO_TOTAL": "Portfölj totalt",
  "OTHER_PROFITS_TOTAL": "Övriga vinster totalt",
  "NET_PROFIT": "Nettovinst",
  "ACQUISITION_PRICE": "Anskaffningspris",
  "PROFIT_OR_LOSS": "Vinst / Förlust",
  "OTHER_REALIZED_PROFITS_HEADER": "Dividender, placeringsfondernas avkastningsbetalning och räntor",
  "OTHER_REALIZED_PROFITS_SUMMARY": "Dividender, placeringsfondernas avkastningsbetalning  och räntor sammanlagt",
  "RELEASE_DATE": "Överlåtelsedag",
  "PRIVATE_EQUITY_REALIZED_PROFITS": "Fonden realiserade avkastning",
  "PRIVATE_EQUITY_TAX_INFO": "Pääomarahastojen tiedot verotusta varten",
  "CAPITAL_INVESTMENT": "Kapitalplacering",
  "SHARE_OF_INCOME": "Andel av resultat",
  "EVL_SHARE_OF_INCOME": "Income share",
  "TAX_YEAR": "Skatteår",

  // Tiliote
  "TRANSACTION_NUMBER_ABBR": "Trans. nr",
  "BALANCE": "Saldo",
  "DEPOSITS_TOTAL": "Insättningar totalt",
  "WITHDRAWS_TOTAL": "Uttag totalt",
  "TRANSACTION_INFO": "Transaktions detaljer",
  "VALUE_INFO": "Tilläggsinformation om summan",
  "COMPARED_BALANCE": "Saldo reserverat för förvärv",
  "INCOME_ESTIMATE": "Uppskattning av kontanterna som försäljningen ger", 

  // Asiakasyhteenveto
  "CUSTOMER_SUMMARY": "Sammandrag",
  "ACCOUNT": "Konto",
  "ASSETS_TOTAL": "Förmögenhet",
  "REALIZED_PROFITS_HEADER": "Realiserade vinster",
  "REALIZED_LOSSES": "Realiserade förluster",
  "REALIZED_PROFITS_AND_LOSSES": "Realiserade vinster (+) / förluster (-)",
  "OTHER_REALIZED_PROFITS": "Övriga realiserade vinster",
  "GROSS": "Brutto",
  "NET": "Netto",
  "COUPONS": "Kuponger",
  "AFTER_TAX": "Efter skatt",
  "DISTRIBUTION_BY_ASSET_TYPE": "Fördelning per tillgång",
  "DISTRIBUTION_BY_CLASSIFICATION": "Fördelning per instrumentklass",
  "LARGEST_INVESTMENTS": "Största placeringar",

  // Salkkuyhteenveto
  "PORTFOLIO_SUMMARY": "Portfölj sammandrag",
  "REPORT_VALUE": "EUR (inside brackets in bonds currency)",

  // Varainhoidon yhteenveto
  "ASSETMANAGEMENT_SUMMARY": "Kapitalförvaltning sammandrag",
  "INSTRUMENT_NAME": "Instrument",
  "RISK_DISTRIBUTION_BY_ASSET_TYPE": "Riskfördelning per tillgång",
  "RISK_DISTRIBUTION_BY_CLASSIFICATION": "Riskfördelning per instrumentklass",
  "RISK_DISTRIBUTION_BY_INSTRUMENT": "Riskifördelning per instrument",
  "PORTION": "Portion",
  "BELOW": "under 0.01 %",

  // Riskiluvut
  "VOLATILITY": "Volatilitet",
  "SHARPEN_RATIO": "Sharpekvot",
  "BETA": "Beta",
  "R2": "R2",
  "TRACKING_ERROR": "Tracking error",
  "INFORMATION_RATION": "Informationskvot",
  "READ_MORE_ABOUT_RISK_NUMBERS": "Läs mer om risktal",
  "ADDITIONAL_INFORMATION_ABOUT_RISK_NUMBERS": "Ytterligare information om risktal",
  "VOLATILITY_INFO": "Volatiliteten mäter den genomsnittliga variationen, standardavvikelsen, på placeringens avkastning. Desto större volatilitet, desto mer har placeringens värde varierat.",
  "SHARPEN_RATIO_INFO": "Sharpekvoten mäter den riskkorrigerade avkastningen på placeringen. Sharpekvoten fås genom att förhålla avkastningen utöver den riskfria räntan mot placeringens volatilitet, dvs. risk. Ett positivt tal talar om en lönsam risktagning, ett negativt tal om en avkastning som är sämre än den riskfria räntan.",
  "BETA_INFO": "Beta mäter känsligheten på placeringens värde för svängningar i jämförelseindexets värde. Exempelvis, ifall placeringens Beta är 0.6 och jämförelseindexets värde ändrar med 1 %, förändras placeringens värde i genomsnitt med 0.6 % i samma riktning.",
  "R2_INFO": "Förklaringsvärdet R2 beskriver den procentandel av variationen i placeringens värde, som kan förklaras med variationen i jämförelseindexets värde. Ett lågt R2-värde talar om ett värde på placeringen som har utvecklats relativt oberoende i förhållande till jämförelseindexet.",
  "TRACKING_ERROR_INFO": "Tracking error mäter den aktiva risken på placeringen dvs. hur noggrant placeringens avkastning följer jämförelseindexets avkastning. Med en hög Tracking error varierar placeringens avkastning kraftigt i förhållande till jämförelseindexets avkastning. Följaktligen, med en låg Tracking error följer placeringens avkastning jämförelseindexets avkastning i förhållandevis hög grad.",
  "INFORMATION_RATION_INFO": "Informationskvoten mäter placeringens och jämförelseindexets avkastningsdifferens i förhållande till placeringens Tracking error. Nyckeltalet anger hur mycket årlig tilläggsavkastning har erhållits i förhållande till tagen aktiv risk.",
  "SHOWN_PERIOD": "Visar det sista året från slutet av den valda perioden.",
  "INDEX": "Index",
  "RISK_STATISTICS_DISCLAIMER": "Risktalen har på rapporten beräknats utifrån uppgifterna för den sista dagen under den period som ska rapporteras, eller om det för den aktuella dagen inte finns tillgängliga uppgifter, utifrån de uppgifter som fanns tillgängliga under den bankdag som föregick den sista dagen.",
  "LOADING_RISK_VALUES": "Laddar risktal",

  // Yleisiä
  "CUSTOMER": "Kund",
  "REPORT_CURRENCY": "Rapporteringsvaluta",
  "TIME_SPAN": "Tid",
  "REPORT_CURRENCY_EUR": "EUR",
  "VALUES_IN_INSTRUMENT_CURRENCY_IN_PARENTHESES": "I värdepappers valuta inom parentes",
  "PORTFOLIO_SUMMARY": "Portfölj sammanfattning",
  "SUMMARY": "Sammanfattning",
  "CAPITA_GAIN_AND_LOSS": "Överlåtelsevinster och -förluster (9a)",
  "ACCOUNT_STATEMENT": "Kontoutdrag",
  "SELECTED_PORTFOLIO": "Utvald portfölj",
  "TIME_PERIOD": "Period som visas",
  "3_MONTHS": "Tre månader",
  "6_MONTHS": "Sex månader",
  "A_YEAR": "Ett år",
  "CURRENT_YEAR": "Från början av året",
  "SINCE_BEGINNING": "Från början av verksamheten",
  "SELECT_DATES": "Välj datum",
  "ADJUSTED_DATES": "Adapterat",
  "PORTFOLIO_EVENTS_BEFORE_2017_FIRST": "Transaktionerna för utvald portfölj är tillfälligt tillgängliga i nättjänsten från ",
  "PORTFOLIO_EVENTS_BEFORE_2017_SECOND": ". Tidigare transaktioner blir tillgängliga så fort som möjligt."
},

  // todo: remove this when history data is available
  "HISTORY_DATA_NOT_AVAILABLE": "History data before 1.1.2016 is not yet available.",

  // Statement transaction types
  "Service Fee": "Arvode",
  "Asset Management Fee": "Kapitalförvaltningsarvode",
  "Asset Management Fee2": "Kapitalförvaltningsarvode",
  "Return of Capital": "Return of Capital",
  "Installment": "Installment",
  "Interest": "Dröjsmålsränta",
  "Fund Subscription Fee": "Teckningsarvode",
  "Fund Subscription": "Debitering av inköp",
  "Fund Redemption": "Försäljning",
  "Transfer in": "Överföring",
  "Transfer out": "Överföring",
  "Transfer In": "Överföring",
  "Transfer Out": "Överföring",
  "Transfer Deposit": "Överföring",
  "Transfer Withdraw": "Överföring",
  "Security Buy": "Debitering av inköp",
  "Security Sell": "Försäljning",
  "Custody Fee": "Vårdnadsarvode",
  "Coupon Interest": "Coupon Interest",
  "Customer Withdraw": "",
  "Customer Deposit": "",
  "Withdraw": "",
  "Deposit": "",
  "Correction": "Justering",
  "Interest Debit": "Skuldränta",
  "Internal Money Withdraw": "Internt kontantuttag",
  "Bank Costs": "Bankavgifter",
  "Fund Transaction Fee": "Transaktionsavgift för fonden",
  "Payment / Management Fee": "Betalning / Förvaltningsavgift",
  "Custody Transaction Fee": "Depåavgift",
  "Payment / Subscription Net": "Betalning / Nettoteckning",
  "Dividend Payout": "Utdelning",
  "Fund Management Fee": "Förvaltningsavgift",
  "Tax Reclaim": "Skatteåterbäring",
  "Expiration Sell": "Försäljning vid förfallodatum",
  "Payment / Redemption Net": "Betalning / Nettoinlösen",
  "Internal Money Deposition": "Intern kontantinsättning",
  "Fee Rebate": "Avgiftsfri rabatt",

  "NOT_APPROPRIATE_INVESTMENT_FOR_USER": "På basis av de uppgifter om placeringserfarenhet och placeringskunnande som du uppgett anser vi att detta finansiella instrument inte nödvändigtvis är lämpligt för dig. Om du trots detta önskar fortsätta med uppdraget, bör du beakta att det finansiella instrumentet kan innehålla för dig okända risker vars följder du inte nödvändigtvis kan förutse.",

  "FEE_INFO_HEADER": "Förhandsinformation om kostnader, incitament och tillhörande avgifter enligt lagen om investeringstjänster",
  "FEE_INFO_INTRO": "Följande uppgifter baserar sig på skäliga antaganden och de slutliga kostnaderna kan avvika från de faktiska kostnaderna och debiteringarna. Informationen baseras på källor som Elite Alfred Berg anser vara tillförlitliga. Informationen kan basera sig delvis eller helt på uppgifter som levererats av tredje part. Elite Alfred Berg ansvarar inte för korrektheten hos uppgifter från tredje part. Informationen som presenteras nedan gäller situationen då dokumentet upprättades och uppgifterna kan ha ändrats efter detta. De faktiska kostnaderna rapporteras till kunden i efterskott en gång i året. Detta är inget marknadsföringsmaterial, utan information i enlighet med bestämmelser och du behöver fördjupa dig noggrant i materialet för att kunna fatta placeringsbeslutet.",
  "FEE_INFO_REGARDING_INSTRUMENTS": "Förhandsinformationen gäller följande tjänster och finansiella instrument:",
  "FEE_INFO_OFFERED_INSTRUMENT": "Placeringstjänst / finansiellt instrument som erbjuds",
  "FEE_INFO_INVESTED_AMOUNT": "Belopp som placeras (EUR):",
  "FEE_INFO_INVESTED_AMOUNT_NO_EUR": "Belopp som placeras",
  "FEE_INFO_SUMMARY_OF_FEES": "Sammanfattning av kostnaderna",
  "FEE_INFO_INVESTMENT_SERVICE_FEES": "Kostnader i anslutning till placerings- och tilläggstjänst",
  "FEE_INFO_FUND_FEES": "Kostnader i anslutning till finansiella instrument",
  "FEE_INFO_TOTAL_FEE": "Total mängd",
  "FEE_INFO_INCENTIVES": "Incitament",
  "FEE_INFO_THIRD_PARTY_INCENTIVES": "Avgifter från tredje part (incitament)",
  "FEE_INFO_INCENTIVES_DISCLAIMER": "Mottagandet av incitament uppfyller kvalitetsförbättringskriteriet i enlighet med bestämmelserna och dessa behöver därför inte överföras till kunden.",
  "FEE_INFO_DETAILS": "Specifikation",
  "FEE_INFO_EAB_FEES": "Kostnader debiterade av EAB Group koncernen",
  "FEE_INFO_THIRD_PARTY_FEES": "Kostnader debiterade av tredje part",
  "FEE_INFO_FEE_COLLECTION": "Debitering av kostnader",
  "FEE_INFO_ONE_TIME_FEES": "1. Kostnader och debiteringar av engångskaraktär (öppningsavgifter, avslutningsavgifter, växelkostnader, överföringskostnader)",
  "FEE_INFO_CONTINUOUS_FEES": "2. Fortlöpande kostnader (förvaringskostnader, kapitalförvaltningsarvode, rådgivningsavgift)",
  "FEE_INFO_TRANSACTION_FEES": "3. Övriga kostnader i anslutning till utförd affärsverksamhet",
  "FEE_INFO_SUBSCRIPTION_FEE": "Teckningsarvode",
  "FEE_INFO_DURING_SUBSCRIPTION": "I samband med teckning",
  "FEE_INFO_REDEMPTION_FEE": "Inlösenarvode",
  "FEE_INFO_DURING_REDEMPTION": "I samband med inlösen",
  "FEE_INFO_SUBSCRIPTION_REDEMPTION_CURRENCY_EXCHANGE_FEES": "Kostnader för valutahandel vid teckning och inlösen",
  "FEE_INFO_INCLUDED_IN_PRICES": "Ingår i de realiserade köpesummorna eller debiteras från kundens tillgångar i samband med köp",
  "FEE_INFO_OTHER_TRANSACTION_FEES": "Övriga kostnader i anslutning till utförd affärsverksamhet",
  "FEE_INFO_OTHER_SERVICE_FEES": "4. Kostnader för tilläggstjänster",
  "FEE_INFO_MISCELANEOUS_FEES": "5. Övriga extraordinära kostnader (ev. resultatbetingade arvoden)",
  "FEE_INFO_INSTRUMENT_RELATED_FEES": "Kostnader i anslutning till finansiella instrument",
  "FEE_INFO_INCLUDED_IN_INSTRUMENT_PRICE": "ingår i det finansiella instrumentets pris, ingen separat debitering",
  "FEE_INFO_OTHER_INSTRUMENT_RELATED_FEES": "4. Kostnader för tilläggstjänster (förvaringsavgifter)",
  "FEE_INFO_CUMULATIVE_FEES_HEADER": "Beskrivning av kostnadernas kumulativa effekt på avkastningen",
  "FEE_INFO_CUMULATIVE_FEES_INTRO1": "Nedan presenteras exempel på kostnadernas effekt på avkastningen. Arvodena som avdras från varje fond påverkar hela placeringens avkastning. Trots att avkastning inte kan garanteras, kan vi visa exempel på hur kostnaderna påverkar placeringsverksamhetens slutresultat. Kommande avkastning kan inte förutspås på basis av historisk avkastning. Värdet på placeringarna kan både stiga och sjunka och placeraren kan få tillbaka en summa som är lägre än den ursprungliga placeringen.",
  "FEE_INFO_CUMULATIVE_FEES_INTRO2": "0 %: nettoavkastningsförväntningen är inte bindande utan ett exempel.",
  "FEE_INFO_ANNUAL_NET_RETURN": "Årlig nettoavkastning:",
  "FEE_INFO_TOTAL_CUMULATIVE_FEES": "Fortlöpande kostnader totalt:",
  "FEE_INFO_CUMULATIVE_EFFECT_OF_FEES": "Kostnadernas kumulativa effekt på avkastningen",
  "FEE_INFO_YEAR1": "År 1",
  "FEE_INFO_YEAR2": "År 2",
  "FEE_INFO_YEAR3": "År 3",
  "FEE_INFO_YEAR4": "År 4",
  "FEE_INFO_YEAR5": "År 5",
  "FEE_INFO_VALUE_OF_INVESTMENT_AT_YEAR_END": "Placeringens värde vid årets slut utan kostnader",
  "FEE_INFO_VALUE_OF_INVESTMENT_WITH_FEES_AT_YEAR_END": "Placeringens uttagsvärde vid årets slut inklusive kostnader",
  "FEE_INFO_CUMULATIVE_FEE_EFFECT_ON_RETURNS": "Kostnadernas kumulativa effekt på avkastningen",
  "FEE_INFO_CUMULATIVE_FEE_EFFECT_ON_RETURN_ANNUALLY": "Kostnadernas kumulativa effekt på avkastningen årsvis",
  "FEE_INFO_BACK": "Tillbaka",
  "MONDAYTOFRIDAY": "Må–Fr",
  "LOADING_CONFIRMATION": "Laddar bekräftelsesida för transaktionen",
  
  // Customer Service section
  "CUSTOMERSERVICE": {
      "TITLE": "Kundtjänst",
      "SUMMARY": {
          "TITLE": "Kundtjänst",
          "MESSAGES": {
              "TITLE": "Meddelanden",
              "NOTIFICATION1": "Du har",
              "NOTIFICATION2": "nytt meddelande i vår nättjänst.",
              "NOTIFICATION3": "nya meddelanden i vår nättjänst.",
              "NOTIFICATION4": "Du har inga nya meddelanden.",
              "INSTRUCTION": "Kontakta oss med ett nätmeddelande i ärenden som gäller sparande, placerande och kapitalförvaltning. Vi hjälper dig så snabbt som möjligt!",
              "SEE_ALL": "Läs alla meddelanden",
          },
          "ANNOUNCEMENTS": {
              "TITLE": "De nyaste kundmeddelandena",
              "SEE_ALL": "Läs alla kundmeddelandena",
          },
          "FEEDBACK": {
              "TITLE": "Ge respons",
              "GIVEFEEDBACK": "Lyckades vi ge dig bra service? Finns det något som vi kunde utveckla? Ge oss respons om vår verksamhet.",
              "REPORTPROBLEM": "Stötte du på problem då du använde Elite Alfred Bergs nättjänst? Vänligen berätta för oss om det, så ska vi åtgärda saken."
          },
          "CONTACTS": {
              "TITLE": "Kontakter",
          },
          "INSTRUCTIONS": {
              "TITLE": "Ohjeet"
          }
      },
      "MESSAGES": {
          "TITLE": "Meddelanden",
          "MESSAGES": {
              "TITLE": "Meddelanden",
              "INSTRUCTION": "Här ser du dina inkomna meddelanden. I fall av brådskande ärende, kontakta vår kundservice per telefon 0201 558 610",
              "LOADING": "Läser in meddelanden",
              "UPDATING": "Meddelanden uppdateras"
          },
          "NEWMESSAGE": {
              "TITLE": "Nytt meddelande",
              "INSTRUCTION": "Här kan du skriva ett meddelande till vår kundservice eller till din egen bankir. Vi kontaktar dig snarast möjligt.",
              "SUBJECT": "Meddelande titel",
              "RECEIVER": "Mottagare",
              "SELECTRECEIVER": "Val mottagare",
              "MESSAGETITLE": "Rubrik",
              "MESSAGE": "Meddelande",
              "OR": "ELLER",
              "MORE": "Tillägga",
              "DRAGDROP": "Dra bilagan här",
              "LOADING_ATTACHMENT_FILE":"Laddar bilaganfil",
              "MESSAGEATTACHMENTINSTRUCTION": "Ladda upp bilagan från dina egna filer.",
              "ATTACHMENTMAXMIMUMSIZE": "Den maximala storleken för bilagan är 20Mb.",
              "SENDMESSAGEBUTTON": "Skicka meddelande",
              "RECEIVEDMESSAGES": "Meddelanden",
              "SENDINGNEWMESSAGE": "Sänder meddelandet",
              "MESSAGESENDSUCCESFUL": "Sändning av meddelandet lyckades. Återgår till mottagna meddelanden.",
              "MESSAGESENDSUCCESFUL_SHORT": "Sändning av meddelandet lyckades!",
              "RETURN_TO_MESSAGES": "Tillbaka till meddelanden",
              "SENDINGFAILED": "Meddelandesändningen misslyckades. Försök igen.",
              "MESSAGEPLACEHOLDER": "Skriv ditt meddelande här",
              "BANKER": "Bankir",
              "TOO_LONG_SUBJECT": "Rubriken du angav är för långt",
              "TOO_LONG_MESSAGE": "Meddelandet du angav är för långt",
              "MAX_LENGTH": "Max längd",
              "CHARACTERLIMIT": "tecken",
              "RESPOND_TO_MESSAGE": "Svara på meddelandet",
              "DISCLAIMER": "Vänligen notera att uppdragsgivning per meddelande via denna funktion inte är möjlig. Du kan ändå per meddelande be att vi är i kontakt med dig för att lämna in uppdrag.",
              "DRAGDROPNOTSUPPORTED": "Den här webbläsaren stöder inte funktionen för dra och släpp av filer",
              "ADDINGATTACHMENTFAiLED": "Det gick inte att lägga till en bilaga. Bilagan kan vara av en obehörig filtyp eller dess storlek var för stor."
          },
          "MODAL": {
              "MESSAGE_NOT_FINISHED": "Meddelandet har inte skickats ännu.",
              "LEAVE_MESSAGE_PAGE_CONFIRMATION": "Meddelandet har inte skickats ännu. Vill du lämna sidan ändå?",
              "STAY_ON_PAGE": "Stanna",
              "LEAVE_PAGE": "Lämna"
          }
      },
      "DOCUMENTS": {
          "TITLE": "Dokument",
          "LOADING": "Laddar dokumenter",
          "DOWNLOAD": "Ladda ner",
          "NOCONTENT": "Inga dokument hittades."
      },
      "ANNOUNCEMENTS": {
          "TITLE": "Kundmeddelande",
          "LOADING": "Laddar kundmeddelanden",
          "UPDATING": "Kundmeddelanden uppdateras",
          "NOCONTENT": "Det gick inte att hitta några kundmeddelanden.",
          "NONEWCONTENT": "Inga nya kundmeddelanden",
          "OPEN_ANNOUNCEMENT": "Öppna kundmeddelandet",
      },
      "CONTACTS": {
          "TITLE": "Kontakter",
          "BANKERINSTRUCTION": "Har du frågor? Din egen bankir betjänar dig.",
          "CUSTOMERSERVICEINSTRUCTION": "Ta kontakt med vår kundtjänst om du har något att fråga.",
          "MEMBERSERVICEINSTRUCTION": "Ta kontakt med vår kundtjänst om du har något att fråga om Personal- / Bonus Fond.",
          "LOADING": "Laddar kontakter",
          "YOUR_BANKER": "Din egen pankir"
      },
      "DASHBOARD": {
          "TITLE": "Kundtjänst",
      },
      "MONTHLYSAVINGS": {
          "TITLE": "Sparavtal",
          "SAVING_CONTRACTS_DESCRIPTION": "Härifrån kan du granska sparavtalen du har skapat. Vänligen kontakta vår kundservice, ifall du vill göra ändringar i ett avtal eller avsluta det.",
          "FOR_WHOM": "För vem sparas det?",
          "CONTRACT_IN_FORCE": "Avtalet i kraft:",
          "CONTRACT_TERMS": "Avtalsvillkor",
          "SAVING_CONTRACT_TERMS": "Sparavtalsvillkor",
          "IN_FORCE": "I kraft",
          "TERMINATED": "Avslutat",
          "SEND_MESSAGE": "Skicka ett meddelande",
          "LOADING_CONTRACTS": "Laddar sparavtal",
          "NO_CONTRACTS_FOUND": "Du har inga sparavtal",
          "TO_SELF": "sig själv"
      },
      "GO_TO_PERSONAL_SETTINGS": "Gå till egen information och inställningar"
  },
  "BUYFUNDPIPE": {
      "MONEYTRANSFERSUCCESSFUL": "Deponering av pengar till portföljen lyckades. Pengarna är genast tillgängliga för teckningsuppdrag.",
      "MONEYTRANSFERFAILED": "Deponering av pengar till portföljen misslyckades.",
      "MONEYTRANSFERINFOLINK": "Se uppgifterna för deponeringen",
      "NORIGHTSTOCREATEAPORTFOLIO": "Du har inte rättigheter att skapa en ny portfölj för ifrågavarande klientskap",
      "SELF_SERVICE_PORTFOLIO_NEEDED": "Du behöver en egenförvaltad portfölj för att teckna fonden.",
      "CREATE_SELF_SERVICE_PORTFOLIO_HERE": "Du kan skapa den här. Du kan välja att namnge din egenförvaltade portfölj.",
      "PLEASE_CONTACT": "Vänligen kontakta kundservicen vid behov",
      "PLEASE_CONTACT_1": "Vänligen kontakta ",
      "PLEASE_CONTACT_2": "vid behov",
      "CUSTOMER_SERVICE": "kundservicen ",
      "LOGIN": {
          "BUYINGFUND": "Du är påväg att skapa ett teckningsuppdrag i fonden",
          "NEEDTOBEACUSTOMER": "För att teckna fonden ska du vara kund hos Elite Alfred Berg och skriva dig in med nättjänstens användarnamn och lösenord",
          "HAVECREDENTIALS": "Jag är redan kund", //"Jag har användarnamn och lösenord",
          "NEWCUSTOMER": "Jag är en ny kund",
          "BACK": "Tillbaka",
          "LOGINWITHTUPAS": "Bli kund genom att identifiera dig.",
          "CHOOSEBANK": "Välj bank",
          "LOGINTOBUYFUND": "Skriv dig in för att fortsätta med fondteckningen.",
          "LOGIN": "Logga in",
          "LOADINGINFORMATION": "Laddar data",
          "SIGNINNOW": "Du kan nu skriva dig in för att fortsätta med fondteckningen.",
          "IDENTIFYTOCONTINUE": "Identifiera dig för att fortsätta med fondorder.",
          "IDENTIFYCONSENT": "Genom att välja eID-leverantör i tabellen nedan accepterar jag att Signicat skickar mina personuppgifter (personnummer och namn) till tjänsteleverantören.",
          "IDENTIFICATIONPRINCIPLES": "Identifikationsprinciper",
      }
  },
  "NEWPORTFOLIO": {
      "NEWPORTFOLIO": "Skapa en ny portfölj",
      "NEWPORTFOLIOSHORT": "Ny portfölj",
      "CHOOSEACCOUNT": "Välj klientskap",
      "CHOOSECUSTOMER": "Välj den klientskap som portföljen skapas för",
      "CREATENEWPORTFOLIOHERE": "Här kan du skapa en ny egenförvaltade portfölj.",
      "NEWPORTFOLIONAME": "Namnge portföljen",
      "VOLUNTARY": "frivillig",
      "CREATEPORFOLIO": "Skapa en portfölj",
      "CREATEPORFOLIONOTFINISHED": "Portföljskapning pågår",
      "EXITFROMPORTFOLIOCREATION": "Är du säker på att du vill lämna portföljskapandet innan du skapar en ny portfölj?",
      "PORTFOLIOCREATED": "Ny portfölj ",
      "SUCCESSFULLYFORCUSTOMER": " skapades framgångsrikt för kunden ",
      "PORTFOLIOCREATIONFAILED": " Portföljskapandet misslyckades",
      "NOSUITABLEACCOUNTS": "Tyvärr hittades inga lämpliga konton.",
      "PLEASE_CONTACT": "Vänligen kontakta",
      "PLEASE_CONTACT_2": "vid behov",
      "CUSTOMER_SERVICE": "kundservicen",
      "CREATINGPORTFOLIO": "En portfölj skapas",
      "GOINGBACKTOBUYPAGE": "Vi byter automatiskt tillbaka till köpfondssidan om några ögonblick.",
      "CREATENEWPORTFOLIOLINK": "+ Skapa en ny portfölj",
      "GOINGBACKTOBROWSEORDERS": "Vi byter automatiskt tillbaka till Bläddra bland uppdragen sidan om några ögonblick.",
  },
  "BANKER": {
    "WELCOME_TO_BANKER_VIEW": "Välkommen till bankirutsikten",
    "WELCOME_TO_BANKER_VIEW_INFO": "Fortsätt till din egen webbtjänst eller till kundens portfölj.",
    "OWN_WEB_SERVICE": "Egen webbtjänst",
    "CUSTOMER_PORTFOLIOS": "Kundernas portföljder",
    "SELECT_CUSTOMER": "Välj kund",
    "SEARCH_FOR_PERSON": "Sök person",
    "SEARCH_WITH_KEYWORD": "Sök med sökord",
    "CUSTOMER_NAME": "Kundens namn",
    "SOCIAL_SECURITY_NUMBER": "Personnummer",
    "ACCOUNT_NUMBER": "Kontonummer",
    "BROWSE_CUSTOMERS": "Bläddra kunder",
    "NO_SEARCH_RESULTS": "Inga sökresultat",
    "BANKER_VIEW": "Bankirens utsikt",
    "CHANGE_USER_INFO": "Du kan byta användare genom att logga in på nytt."
  },
  "FUND_OPTIONS" : {
    "TITLE": "Fonder",
    "DESCRIPTION1": "Våra fonder erbjuder ett smidigt sätt att spara och investera i en diversifierad portfölj. Våra portföljförvaltare följer aktivt med marknaden och gör nödvändiga justeringar för dig.",
    "DESCRIPTION2": "",
  },
  "REWARD_CALCULATOR": {
    "YOUR_PERFORMANCE_BONUS": "Syötä tulospalkkiosi",
    "YOUR_TAX_PERCENT": "Syötä veroprosenttisi",
    "INFO_TEXT": "Esimerkkilaskelma perustuu voimassaolevan henkilöstörahastolakiin sekä voimassa olevaan vero- ja muuhun lainsäädäntöön, joka voi muuttua. Laskelmassa on käytetty seuraavia arvoja: työnantajan sivukulut 23 %, työntekijän sivukulut 10 % ja sijoitustoiminnan tavoitteena 4 % vuotuinen tuotto. Tuottotavoite ei ole tae tulevasta tuotosta. Esimerkissä edunsaaja nostaa rahastosäästöjä 3. vuodesta eteenpäin käteistä tulospalkkiota vastaavan rahamäärän. Tämä on mahdollista henkilön tuloveroprosentin ollessa min 25. Alhaisemmalla tuloveroprosentilla vastaavan noston voin tehdä 4. vuodesta eteenpäin."
  }
};
