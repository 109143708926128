/* eslint-disable */
export default {
  // New translations.
  // TODO: replace these with original translations?
  "home": "Etusivu",
  "users": "Käyttäjät",
  "notifications": "Tiedotteet",
  "contactUs": "Ota meihin yhteyttä",
  "myFundUnit": "Rahasto-osuuteni",
  "byLaws": "Rahaston säännöt",
  "withdrawal": "Nostoilmoitus",
  "updateContactInfo": "Päivitä yhteystietosi",
  "customerService": "Asiakaspalvelu",
  "chooseRules": "Valitse ladatttavat säännöt",
  "personalDetails": "Omat tiedot",
  "myContactInfo": "Yhteystietoni",
  "name": "Nimi",
  "streetName": "Katuosoite",
  "postalCode": "Postinumero",
  "postOffice": "Postitoimipaikka",
  "emailAddress": "Sähköpostiosoite",
  "mobileNumber": "Matkapuhelinnumero",
  "accountNumber": "Tilinumero",
  "taxforIncome": "Veroprosentti",
  "taxLiability": "Verovelvollisuus",
  "usNationality": "Verovelvollisuus Yhdysvalloissa",
  "usaCitizen": {
    "yes": "Vahvistan, että olen Yhdysvaltojen kansalainen.",
    "no": "Vahvistan, että en ole Yhdysvaltojen kansalainen."
  },
  "countriesOfTaxResidency": "Verotukselliset asuinvaltiot",
  "countryOfTax": "Verotusmaa",
  "usaTaxId": "Yhdysvaltojen verotunniste (TIN tai vastaava)",
  "addNewTaxCountry": "Lisää verotusmaa",
  "taxLiabilityFIN": "Suomen verovelvollisuus",
  "taxId": "Verotunniste (TIN tai vastaava)",
  "taxOnlyInFinland": {
    "yes": "Vahvistan, että olen verotuksellisesti ainoastaan Suomessa asuva.",
    "no": "Vahvistan, että olen verotuksellisesti asuva (myös) muissa valtioissa kuin Suomessa."
  },
  "communicationWithEAB": "Yhteydenpito Elite Alfred Bergin kanssa",
  "formOfDelivery": "Jäsentietojen toimitustapa",
  "deliverIn": "Haluan, että jäsentietoni toimitetaan",
  "confirm": "Vahvista",
  "fundingNotification": "Rahastointi-ilmoitus",
  "selectedFund": "Tarkasteltava rahasto",
  "fundingNotificationEnded": "Rahastointi-ilmoitusaika on päättynyt",
  "cancel": "Peruuta",
  "ok": "OK",
  "error": "Virhe",
  "close": "Sulje",
  "withdrawalOfFundUnit": "Rahasto-osuuden nosto",
  "giveWithdrawalNotification": "Tee nostoilmoitus",
  "moreInformation": "Lisätietoja",
  "readMoreAboutTaxation": "Lue lisää verotuksesta",
  "detailsOnEmploymentContractExpiration" : "Lue lisää työsuhteen päättymisestä",
  "withdrawalNotification": "Nostoilmoitus",
  "availableForWithdrawal": "Nostettavissa oleva summa",
  "willWithdrawSum": "Nostan rahasto-osuudestani (€)",
  "helperText" : {
    "required": {
      "requiredField": "Pakollinen kenttä"
    },
    "typeError": {
      "useOnlyNumbers": "Käytä numeroita"
    }
  },
  "sumToBeWithdrawn": "Nostettava rahasto-osuus",
  "noVAT": "Veroton",
  "yesVAT": "Veronalainen rahasto-osuus",
  "withholding": "Ennakonpidätys",
  "toBePaidToAccount": "Maksetaan tilille",
  "taxingInfo": "Verotustiedot",
  "taxRate": "Veroprosentti",
  "fund": "Rahasto",
  "fundEstablished": "Rahasto perustettu",
  "fundFinancialPeriod": "Rahaston tilikausi",
  "membershipPeriod": "Jäsenyysaika",
  "fundUnitValue": "Rahasto-osuuden arvo",
  "restrictedAmount": "Sidottu osuus",
  "unitAvailableForWithdrawal": "Nostettavissa oleva osuus",
  "date": "Päivämäärä",
  "explanation": "Selite",
  "withdrawableAmount": "Nostettavissa",
  "bonusesDuringFinancialPeriod": "Kesken tilikauden rahastoidut palkkiot",
  "fundingDate": "Palkkion maksu rahastoon",
  "fundValueDate": "Arvopäivä",
  "bonus": "Summa",
  "language" : "Kieli",
  "fundingOptions": {
    "fund100": "Rahastoin palkkion kokonaan",
    "fund75": "Rahastoin palkkiosta 75%",
    "fund50": "Rahastoin puolet ja nostan puolet käteisenä",
    "fund25": "Rahastoin palkkiosta 25%",
    "fund67": "Rahastoin palkkiosta 2/3",
    "fund33": "Rahastoin palkkiosta 1/3",
    "withdrawCash": "Nostan käteisenä",
    "fund50eur": "Rahastoin palkkiosta 50€",
    "fund100eur": "Rahastoin palkkiosta 100€",
    "fund150eur": "Rahastoin palkkiosta 150€",
    "fund300eur": "Rahastoin palkkiosta 300€"
  },
  "profitBonusItem": "Palkkio-osuus",
  "willBeTransferred" : "Siitterään",
  "riskStrategy": "Riskistrategia",
  "locking": "Haluan lukita tekemäni valinnan.",
  "riskStrategies": {
    "careful": "Varovainen",
    "neutral": "Neutraali",
    "aggressive" : "Tuottohakuinen",
    "ownShare": "Own share"
  },
  "previousFundingNotice": "Edellisen kauden rahastointivalintasi",
  "noPreviousFundingNotice": "Ei edellisiä rahastointivalintoja",
  "fundingNoticeLocked": "Olet lukinnut lla näkyvät rahastointivalinnat. Jos haluat muuttaa tekemiäsi valintoja, poista lukitus, tee uudet valinnat ja vahvista valintasi.",
  "unlockAndChangeFundingNotice": "Poista lukitus ja muuta valintoja",
  "anErrorOccurred": "Tapahtui virhe.",
  "reloadPage": "Lataa sivu uudelleen",

  // Original translations.
  "1 Security-oriented": "1 Turvallisuushakuinen",
  "11101, Non-financial corporations, excl. housing corporations, public": "11101, Julkiset yritykset pl. Asuntoyhteisöt",
  "11102, Non-financial corporations, excl. housing corporations, national private": "11102, Yksityiset kotimaiset yritykset pl. Asuntoyhteisöt",
  "11103, Non-financial corporations, excl. housing corporations, foreign controlled": "11103, Ulkomaisomisteiset yritykset pl. Asuntoyhteisöt",
  "112, Housing corporations": "112, Asuntoyhteisöt",
  "11211, Housing companies, public": "11211, Asunto-osakeyhtiöt, julkiset",
  "11212, Housing companies, national private": "11212, Asunto-osakeyhtiöt, yksityiset kotimaiset",
  "11213, Housing companies, foreign controlled": "11213, Asunto-osakeyhtiöt, ulkomaisomisteiset",
  "11221, Other housing corporations, public": "11221, Muut asuntoyhteisöt, julkiset",
  "11222, Other housing corporations, national private": "11222, Muut asuntoyhteisöt, yksityiset kotimaiset",
  "11223, Other housing corporations, foreign controlled": "11223, Muut asuntoyhteisöt, ulkomaisomisteiset",
  "121, Central bank": "121, Keskuspankki",
  "1221, Deposit banks": "1221, Talletuspankit",
  "12211, Deposit banks, public": "12211, Talletuspankit, julkiset",
  "12212, Deposit banks, national private": "12212, Talletuspankit, yksityiset kotimaiset",
  "12213, Deposit banks, foreign controlled": "12213, Talletuspankit, ulkomaisomisteiset",
  "1222, Other credit institutions": "1222, Muut luottolaitokset",
  "12221, Other credit institutions, public": "12221, Muut luottolaitokset, julkiset",
  "12222, Other credit institutions, national private": "12222, Muut luottolaitokset, yksityiset kotimaiset",
  "12223, Other credit institutions, foreign controlled": "12223, Muut luottolaitokset, ulkomaisomisteiset",
  "1223, Other monetary financial institutions, excl. credit institutions": "1223, Muut rahalaitokset kuin luottolaitokset",
  "12231, Other monetary financial institutions, excl. credit institutions, public": "12231, Muut rahalaitokset kuin luottolaitokset, julkiset",
  "12232, Other monetary financial institutions, excl. credit institutions, national private": "12232, Muut rahalaitokset kuin luottolaitokset, yksityiset kotimaiset",
  "12233, Other monetary financial institutions, excl. credit institutions, foreign controlled": "12233, Muut rahalaitokset kuin luottolaitokset, ulkomaisomisteiset",
  "123, Money market funds (MMF)": "123, Rahamarkkinarahastot",
  "1241, Investment funds (UCITS)": "1241, Sijoitusrahastot (UCITS)",
  "1242, Other collective investment schemes": "1242, Muut yhteissijoitusyritykset",
  "125, Other financial intermediaries": "125, Muut rahoituksen välittäjät",
  "12501, Other financial intermediaries, public": "12501, Muut rahoituksen välittäjät, julkiset",
  "12502, Other financial intermediaries, national private": "12502, Muut rahoituksen välittäjät, yksityiset kotimaiset",
  "12503, Other financial intermediaries, foreign controlled": "12503, Muut rahoituksen välittäjät, ulkomaisomisteiset",
  "126, Financial auxiliaries": "126, Rahoituksen ja vakuutuksen välitystä avustavat laitokset",
  "12601, Financial auxiliaries, public": "12601, Rahoituksen ja vakuutuksen välitystä avustavat laitokset, julkiset",
  "12602, Financial auxiliaries, national private": "12602, Rahoituksen ja vakuutuksen välitystä avustavat laitokset, yksityiset kotimaiset",
  "12603, Financial auxiliaries, foreign controlled": "12603, Rahoituksen ja vakuutuksen välitystä avustavat laitokset, ulkomaisomisteiset",
  "127, Captive financial institutions and money lenders": "127, Konserninsisäiset rahoitusyksiköt ja rahanlainaajat",
  "12701, Captive financial institutions and money lenders, public": "12701, Konserninsisäiset rahoitusyksiköt ja rahanlainaajat, julkiset",
  "12702, Captive financial institutions and money lenders, national private": "12702, Konserninsisäiset rahoitusyksiköt ja rahanlainaajat, kotimaiset",
  "12703, Captive financial institutions and money lenders, foreign controlled": "12703, Konserninsisäiset rahoitusyksiköt ja rahanlainaajat, ulkomaisomisteiset",
  "128, Insurance corporations": "128, Vakuutuslaitokset",
  "12801, Insurance corporations, public": "12801, Vakuutuslaitokset julkiset",
  "12802, Insurance corporations, national private": "12802, Vakuutuslaitokset, yksityiset kotimaiset",
  "12803, Insurance corporations, foreign controlled": "12803, Vakuutuslaitokset, ulkomaisomisteiset",
  "129, Pension funds": "129, Eläkerahastot",
  "1311, Central government": "1311, Valtionhallinto",
  "13111, Budget economy of central government": "13111, Valtion budjettitalous",
  "13119, Other units of central government": "13119, Muut valtionhallinnon yksiköt",
  "1312, State government (not used in Finland)": "1312, Osavaltiohallinto (ei käytössä Suomessa)",
  "1313, Local government": "1313, Paikallishallinto",
  "13131, Municipalities": "13131, Kunnat",
  "13132, Joint municipal authorities": "13132, Kuntayhtymät",
  "13133, The Ã…land Government": "13133, Ahvenanmaan maakunnan hallitus",
  "13139, Other units of local government": "13139, Muut paikallishallinnon yksiköt",
  "1314, Social security funds": "1314, Sosiaaliturvarahastot",
  "13141, Employment pension schemes": "13141, Työeläkelaitokset",
  "13149, Other social security funds": "13149, Muut sosiaaliturvarahastot",
  "14, Households": "14, Kotitaloudet",
  "141, Employers and own-account workers": "141, Työnantaja- ja muut elinkeinonharjoittajien kotitaloudet",
  "143, Employees": "143, Palkansaajakotitaloudet",
  "1441, Recipients of property income": "1441, Omaisuustulojen saajakotitaloudet",
  "1442, Recipients of pensions": "1442, Eläkkeensaajakotitaloudet",
  "1443, Recipients of other transfers": "1443, Muiden tulonsiirtojen saajakotitaloudet",
  "15, Non-profit institutions serving households": "15, Kotitalouksia palvelevat voittoa tavoittelemattomat",
  "2 Cautious": "2 Varovainen",
  "3 Moderate": "3 Maltillinen",
  "4 Return-oriented": "4 Tuottohakuinen",
  "5 Aggressive": "5 Aggressiivinen",
  "ACCOUNT_IS_FREE": "Rahastosäilytys on maksuton",
  "ACCOUNT_STATEMENT": "Tiliote",
  "ACCOUNT_TRANSFER": "Tilisiirto",
  "ACCOUNT_NUMBER": "Tilinumero",
  "ACCOUNTANT_REPORT": "Koontiraportti",
  "ACCOUNTANT_REPORT_SUMMARY": "Koontiraportti sisältää kaikki raportit salkustasi kirjanpitoa varten valitsemallesi ajanjaksolle.",
  "ACTIVE_PORTFOLIOS": "Aktiiviset salkut",
  "ADDED_NEW_ORDER": "Lisätty uusi toimeksianto.",
  "ADDITIONAL_CUSTOMER_INFORMATION": "Asiakkaan lisätiedot",
  "ADDITIONAL_INFORMATION": "Lisätiedot",
  "ADDITIONAL_INFORMATION_ADDED_SUCCESSFULLY": "Lisätiedot tallennettu onnistuneesti",
  "ADDITIONAL_INVESTMENTS": "Lisäsijoitukset",
  "ADDITIONAL_INVESTMENTS_DURING_PERIOD": "Lisäsijoitukset kauden aikana",
  "ADDITIONAL_TAX_COUNTRY": "Muu verotusmaa",
  "ADDITIONS": "Lisäykset",
  "ADDRESS": "Osoite",
  "COUNTRY_OF_RESIDENCE": "Asuinmaa",
  "AFGHANISTAN": "Afganistan",
  "AGGRESSIVE": "Aggressiivinen",
  "ALBANIA": "Albania",
  "alempi korkeakouluaste": "Alempi korkeakouluaste",
  "ALGERIA": "Algeria",
  "ALL": "Kaikki",
  "ALL_FUND_FAMILIES": "Kaikki rahastoperheet",
  "CHOOSE_FUND_FAMILY": "Valitse rahastoperhe",
  "FUND_FAMILIES": "Rahastoperheet",
  "ALL_MAIN_CLASSES": "Kaikki pääluokat",
  "ALL_SUB_CLASSES": "Kaikki aliluokat",
  "ALL_TOTAL": "Kaikki yhteensä",
  "Alternative": "Vaihtoehtoiset",
  "ALTERNATIVEFUND": "Vaihtoehtorahasto",
  "Alternatives": "Vaihtoehtoiset sijoitukset",
  "AMERICAN SAMOA": "Amerikan Samoa",
  "AMOUNT": "Määrä",
  "AMOUNT_PER_SHARE": "Määrä (€/kpl)",
  "AND_YOUR_NEW_PASSWORD": "ja uuden salasanan olet itse syöttänyt.",
  "AND_YOUR_PASSWORD": "ja salasanan olet itse syöttänyt",
  "ANDORRA": "Andorra",
  "ANGOLA": "Angola",
  "ANGUILLA": "Anguilla",
  "ANNUALLY": "Vuosittain",
  "ANTARCTICA": "Antarktis",
  "ANTIGUA AND BARBUDA": "Antigua ja Barbuda",
  "ARE_YOU_SURE_YOU_WANT_TO_EXIT_FROM_TRANSACTION": "Oletko varma, että haluat poistua toimeksiannon syöttämisestä ennen kuin toimeksianto on tehty ja vahvistettu?",
  "ARGENTINA": "Argentiina",
  "Arkkitehti- ja insinööripalvelut, tekninen testaus ja analysointi": "Arkkitehti- ja insinööripalvelut, tekninen testaus ja analysointi",
  "ARMENIA": "Armenia",
  "ARUBA": "Aruba",
  "Asia": "Aasia",
  "Asia-Pacific": "Aasian ja Tyynenmeren alue",
  "Asset management fee": "Omaisuudenhoitopalkkio",
  "ASSET_CHANGES": "Varallisuuden muutos",
  "ASSET_MANAGEMENT": "Omaisuudenhoito",
  "ASSET_MANAGEMENT_FEES": "Omaisuudenhoitopalkkiot",
  "ASSET_VALUE_IN_BEGINNING": "Varallisuuden arvo alussa",
  "ASSET_VALUE_IN_BEGINNING_OF_PERIOD": "Varallisuuden arvo kauden alussa",
  "ASSET_VALUE_IN_END": "Varallisuuden arvo lopussa",
  "ASSET_VALUE_IN_END_OF_PERIOD": "Varallisuuden arvo kauden lopussa",
  "ASSETMANAGEMENTFEE": "Omaisuudenhoitopalkkio",
  "ASSETS": "Varallisuus",
  "ASSETS_SUMMARY": "Varallisuuden yhteenveto",
  "Asuin- ja muiden rakennusten rakentaminen": "Asuin- ja muiden rakennusten rakentaminen",
  "AUSTRALIA": "Australia",
  "AUSTRIA": "Itävalta",
  "AZERBAIJAN": "Azerbaidzan",
  "BACK_TO_ASSET_REPORT": "Takaisin varallisuusyhteenvetoon",
  "BACK_TO_HOLDINGS": "Takaisin omistuksiin",
  "BACK_TO_SEARCH": "Takaisin hakuun",
  "BAHAMAS": "Bahama",
  "BAHRAIN": "Bahrain",
  "Balanced funds": "Yhdistelmärahastot",
  "Balanced Funds": "Yhdistelmärahastot",
  "BALANCEDFUND": "Yhdistelmärahasto",
  "BANGLADESH": "Bangladesh",
  "BANK_ACCOUNT": "Vastatili (IBAN)",
  "BANK_ACCOUNT_NUMBER": "Pankkitilin numero (IBAN)",
  "BANK_ACCOUNTS": "Pankkiyhteystiedot",
  "BARBADOS": "Barbados",
  "BECOME_A_CUSTOMER_WITH_BANK_ACCESS_CODES": "Tule asiakkaaksi verkkotunnistautumisella.",
  "BELARUS": "Valko-Venäjä",
  "BELGIUM": "Belgia",
  "BELIZE": "Belize",
  "BENEFICIARIES": "Edunsaajat",
  "BENEFICIARY": "Saaja",
  "BENEFICIARY_ACCOUNT_NUMBER": "Saajan tilinumero",
  "BENIN": "Benin",
  "BERMUDA": "Bermuda",
  "BHUTAN": "Bhutan",
  "BIC": "Pankin BIC",
  "BLOOMBERG": "Bloomberg",
  "BLOOMBERG_CODE": "Bloomberg tunniste",
  "BOLIVIA": "Bolivia",
  "BONDFUND": "Korkorahasto",
  "BONDS": "Joukkovelkakirjalainat",
  "BOSNIA AND HERTSEGOVINA": "Bosnia ja Hertsegovina",
  "BOTSWANA": "Botswana",
  "BOUGHT_TO_PORTFOLIO": "ostamaan salkkuun",
  "BOUVET ISLAND": "Bouvetnsaari",
  "BRAZIL": "Brasilia",
  "BRIEFS": "Tiedotteet",
  "BRITISH INDIAN OCEAN TERRITORY": "Brittiläinen Intian valtameren alue",
  "BROWSE_ORDERS": "Toimeksiantojen selaus",
  "BRUNEI DARUSSALAM": "Brunei",
  "BULGARIA": "Bulgaria",
  "BURKINA FASO": "Burkina Faso",
  "BURUNDI": "Burundi",
  "BUSINESS_ID": "Y-tunnus",
  "BUSINESS_INCOME": "Liiketoiminta",
  "BUY": "Osta",
  "Buy": "Osto",
  "BUYRECEIVABLE": "Ostosaatava",
  "BuyWithdrawal": "Oston veloitus",
  "BYPASS_FOR_NOW": "Ohita toistaiseksi",
  "CALCULATORY_PRICE": "Laskennallinen hinta kuluineen (EUR/osuus)",
  "CALCULATION_OF_SECURITY_PROFITS_FOR_TAXREPORT": "LASKELMA ARVOPAPERIEN JA ARVO-OSUUKSIEN LUOVUTUS- VOITOSTA TAI -TAPPIOSTA",
  "CALL_RATES": "Puheluhinnat",
  "CAMBODIA": "Kambodza",
  "CAMEROON": "Kamerun",
  "CANADA": "Kanada",
  "CANCEL": "Peruuta",
  "CANCEL_TRANSACTIONS": "Peruuta tapahtuma",
  "CAPE VERDE": "Kap Verde",
  "CAPITAL_GAIN": "Luovutusvoitot",
  "CAPITAL_INCOME": "Pääomatulot",
  "CAPITAL_LOSS": "Luovutustappiot",
  "CASH": "Käteinen",
  "Cash": "Käteinen",
  "Cash and others": "Käteinen ja muut",
  "Cash and Others": "Valuutat ja muut erät",
  "CASH_RESERVED_FOR_BUYS": "Ostovelka",
  "CAUTIOUS": "Varovainen",
  "CAYMAN ISLANDS": "Caymansaaret",
  "CENTRAL AFRICAN REPUBLIC": "Keski-Afrikan tasavalta",
  "CHAD": "Tsad",
  "CHANGE": "Muutos",
  "CHANGE_%": "Muutos-%",
  "CHANGE_AFTER_COSTS": "Muutos kulujen jälkeen",
  "CHANGE_AND_OTHER_TRANSACTIONS": "Arvonmuutos",
  "CHANGE_ARGUMENTS_AND_SEARCH_AGAIN": "Muuta valintoja ja tee haku uudelleen.",
  "CHANGE_PASSWORD": "Vaihda salasana",
  "CHANGE_YOUR_PASSWORD": "Vaihda salasanasi",
  "CHANGING_PASSWORD": "Salasanan vaihtaminen",
  "CHECK_OUT_MORE_NEWS": "Lue lisää ajankohtaisista aiheista",
  "CHILE": "Chile",
  "CHINA": "Kiina",
  "CHOOSE": "Valitse",
  "CHOOSE_DATE": "Valitse päivä",
  "CHOOSE_AT_LEAST_ONE_PORTFOLIO": "Valitse vähintään yksi salkku",
  "CHOOSE_AT_LEAST_ONE_TRANSACTION_TYPE": "Valitse vähintään yksi tapahtumatyyppi",
  "CHOOSE_BANK_YOU_WANT_USE_FOR_IDENTIFYING": "Valitse pankki jolla haluat tunnistautua.",
  "CHOOSE_CUSTOMER_YOU_WANT_TO_CREATE_USERID_FOR": "Valitse asiakas, jolle haluat luoda käyttäjätunnuksen",
  "CHOOSE_FOR_VALUE_COMPARISON": "Valitse arvojen vertailua varten",
  "CHOOSE_FUND": "Valitse rahasto",
  "CHOOSE_PORTFOLIO_FOR_DEPOSIT": "Valitse ensin salkku, johon haluat tehdä talletuksen",
  "CHOOSE_PORTFOLIO_FOR_MONTHLY_DEPOSIT": "Valitse ensin salkku, johon haluat tehdä kuukausitalletuksen",
  "CHOOSE_RISK_CLASS": "Valitse riskiluokka",
  "CHOOSE_TRANSACTION_TYPES": "Valitse tapahtumatyypit",
  "CHOOSE_USER_YOU_WANT_TO_UPDATE": "Valitse käyttäjistä se, jonka salasanan haluat vaihtaa",
  "CHOOSE_USER_YOU_WANT_TO_LOGIN": "Valitse käyttäjistä, jonka haluat kirjautua sisään",
  "CHOOSING_PASSWORD": "Salasanan valinta",
  "CHOSEN_PORTFOLIO_HAS_NOT_HOLDINGS": "Valitussa salkussa ei ole yhtään omistuksia, joten myyntiä ei voi tehdä. Valitse jokin toinen salkku",
  "CHOSEN_PORTFOLIO_HAS_NO_BALANCE": "Valitussa salkussa ei ole saldoa, joten nostoa ei voi tehdä. Valitse jokin toinen salkku",
  "CHOSEN_PORTFOLIO_HAS_NO_BALANCE_FOR_PURCHASES": "Valitussa salkussa ei ole saldoa, joten ostoja ei voi tehdä. Valitse jokin toinen salkku",
  "CHRISTMAS ISLAND": "Joulusaari",
  "CITIZENSHIP": "Kansalaisuus",
  "CITIZENSHIP_REQUIRED": "Kansalaisuus on pakollinen",
  "CLEAR": "Tyhjennä",
  "CLEARSELECTIONS": "Tyhjennä valinnat",
  "CLICK": "Klikkaa",
  "CLICK_ON_FLAG_TO_DOWNLOAD_INSTRUMENT_DOCUMENT": "Klikkaa lippua ladataksesi rahaston dokumentin.",
  "CLOSE": "Sulje",
  "CLOSE_WINDOW": "Sulje ikkuna",
  "COCOS (KEELING) ISLANDS": "Kookossaaret",
  "COLOMBIA": "Kolumbia",
  "COME_TO": "tullaan",
  "COME_TO_CUSTOMER": "Tule asiakkaaksi",
  "COME_TO_ELITES_CUSTOMER": "Miksi Elite Alfred Bergin asiakkuus kannattaa?",
  "COMMISSION": "Toimeksianto",
  "COMMISSION_CONFIRMATION": "Toimeksiantojen varmennustapa",
  "COMMISSION_DATE": "Toimeksiantopäivä",
  "Commodities": "Hyödykkeet",
  "COMMODITYFUND": "Raaka-ainerahasto",
  "COMMON_CONTRACT_TERMS": "Yleiset sopimusehdot",
  "COMMON_FUND_DESCRIPTION": "Rahaston varoista saatetaan periä kiinteän hallinnointipalkkion lisäksi myös muita kuluja, esimerkiksi tuottosidonnaista hallinnointipalkkiota tai säilytystä ja kaupankäyntiä koskevia palkkioita. Tarkemmat tiedot rahaston kuluista löytyvät rahastoyhtiön julkaisemasta avaintietoesitteestä, rahastoesitteestä sekä säännöistä.",
  "COMOROS": "Komorit",
  "COMPANY_SALE": "Yrityskauppa",
  "CONFIRM": "Vahvista",
  "CONFIRM_MARK_TRANSACTION": "Vahvista merkintätoimeksianto",
  "CONFIRM_RECLAIM_TRANSACTION": "Vahvista lunastustoimeksianto",
  "CONFIRM_SIGN_IN": "Vahvista sisäänkirjautuminen",
  "CONFIRM_WITHDRAWAL_TRANSACTION": "Vahvista nostotoimeksianto",
  "CONFIRMATION_CODE": "Vahvistustunnus",
  "CONFIRMATION_CODE_IS_INVALID": "Vahvistustunnus ei kelpaa",
  "CONFIRMATION_CODE_IS_MANDATORY": "Vahvistustunnus on syötettävä",
  "CONGO": "Kongo",
  "CONGO THE DEMOCRATIC REPUBLIC OF THE": "Kongon demokraattinen tasavalta",
  "CONTACT_AND_BANK_INFORMATION": "Yhteystiedot ja pankkitiedot",
  "CUSTOMER_INFORMATION": "Asiakastiedot",
  "CONTACT_INFORMATION": "Yhteystiedot",
  "CONTACT_INFORMATION_CHANGED": "Yhteystiedot tallennettu.",
  "CONTACT_PERSON": "Yhteyshenkilö",
  "CONTACT_REQUEST": "Yhteydenottopyyntö",
  "CONTACT_REQUEST_SUCCESSFUL": "Yhteydenottopyyntö välitetty onnistuneesti!",
  "CONTACT_REQUEST_FAILED": "Yhteydenottopyynnön välitys epäonnistui. Pyydämme ottamaan yhteyttä asiakaspalvelun kautta.",
  "CONTINUE": "JATKA",
  "CONTRACT_FOR_COMMUNITY": "Sopimus yhteisöasiakkaalle",
  "CONTRACT_FOR_ORGANIZATION_CLIENTS": "Sopimus yhteisöasiakkaille",
  "CONTRACT_FOR_PERSON": "Sopimus henkilöasiakkaalle",
  "CONTRACT_FOR_PRIVATE_CLIENTS": "Sopimus henkilöasiakkaille",
  "CONTRACT_MUST_BE_SIGNED_BEFORE_CONTINUING": "Sopimus tulee allekirjoittaa ennen jatkamista.",
  "CONTRACT_TERMS_MUST_BE_ACCEPTED_BEFORE_CONTINUING": "Sopimusehdot tulee hyväksyä ennen kuin voi jatkaa.",
  "CONTRARIANFUND": "Kontrarahasto",
  "COOK ISLANDS": "Cookinsaaret",
  "Corporate bonds (convertible bonds)": "Yrityslainat (vaihtovelkakirjat)",
  "Corporate bonds (high yield)": "Yrityslainat (high yield)",
  "Corporate bonds (investment grade)": "Yrityslainat (investment grade)",
  "Corporate bonds (variable rate)": "Yrityslainat (vaihtuvakorkoiset)",
  "Corporate Debt (Convertibles)": "Yrityslainat (vaihtovelkakirjat)",
  "Corporate Debt (Floating Rate)": "Yrityslainat (vaihtuvakorkoiset)",
  "Corporate Debt (High Yield)": "Yrityslainat (high yield)",
  "Corporate Debt (Investment Grade)": "Yrityslainat (investment grade)",
  "Corporate Debt (Non-Rated)": "Yrityslainat (Luokittelematon)",
  "COST": "Palvelumaksu",
  "COSTA RICA": "Costa Rica",
  "COSTS": "Palvelumaksut",
  "COUNTER_ACCOUNT": "Vastatili",
  "COUNTRY": "Maa",
  "COUNTRY_REQUIRED": "Maa on pakollinen",
  "CREATE_A_NEW_USERID": "Luo uusi käyttäjätunnus",
  "CREATING_NEW_PASSWORD": "Uuden salasanan luominen",
  "CREATING_NEW_USERID": "Käyttäjätunnuksen luonti",
  "CREDIT_INTERESTS": "Lainakorot",
  "CREDITINTERESTDEPOSITION": "Sijoituslainan koron tilitys",
  "CREDITINTERESTWITHDRAWAL": "Sijoituslainan koron veloitus",
  "CreditInterestWithdrawal": "Sijoituslainan koron veloitus",
  "CROATIA": "Kroatia",
  "CUBA": "Kuuba",
  "CHOOSE_CURRENCY": "Valitse valuutta",
  "CURRENCY": "Valuutta",
  "Currency": "Käteinen",
  "CURRENT_CUSTOMER": "Nykyinen asiakas",
  "CURRENT_CUSTOMER_GET_USERID": "Oletko jo asiakas ja haluat saada tunnukset verkkopalveluun?",
  "CURRENT_INVESTMENT_SERVICES": "Nykyiset sijoituspalvelut",
  "CURRENT_PASSWORD": "Nykyinen salasana",
  "CURRENT_YEAR": "Vuoden alusta",
  "CURRENTLY_USED_SERVICES": "Nykyään käytetyt palvelut",
  "CUSTOMER_ADDITIONAL_INFORMATION": "Asiakkaan lisätiedot",
  "CUSTOMER_FUNDS": "Asiakasvarat",
  "CUSTOMER_INFORMATION": "Asiakastiedot",
  "CUSTOMER_SERVICE": "Asiakaspalvelu",
  "Customer Service": "Asiakaspalvelu",
  "Customer service": "Asiakaspalvelu",
  "CUSTOMERSHIP_CONSISTS": "Rahastotilisopimus",
  "CYPRUS": "Kypros",
  "CZECH REPUBLIC": "Tsekki",
  "DAILY": "Päivittäin",
  "DATA": "Data",
  "DATE": "Päivämäärä",
  "DATE_FILTERS": "Päivämäärä valinnat:",
  "DATE_RANGE": "Aikaväli",
  "DAY": "Päivä",
  "DEBT": "Velat",
  "Debt": "Velat",
  "DEFAULT_PORTFOLIO": "Oletussalkku",
  "DENMARK": "Tanska",
  "DEPOSITINTERESTDEPOSITION": "Talletuskoron tilitys",
  "DEPOSITINTERESTWITHDRAWAL": "Talletuskoron veloitus",
  "DEPOSITION": "Talletus",
  "Deposition": "Talletus",
  "DEPOSITION_INTERESTS": "Talletuskorot",
  "DEPOSITIONS": "Talletukset",
  "DEPOSITS": "Talletukset",
  "DERIVATIVES": "Johdannaiset",
  "DERIVATIVESFUND": "Vipurahasto",
  "DESCRIPTION": "Kuvaus",
  "DID_YOU_FORGET_YOUR_ACCOUNT": "Unohtuiko tunnukset",
  "DIFFERENCE": "Erotus",
  "DISTRIBUTION_HISTORY_NOT_FOUND": "Historiallista jakaumaa ei löytynyt.",
  "DIVIDEND": "Osinko",
  "DIVIDENDS": "Osingot",
  "DJIBOUTI": "Djibouti",
  "DOCUMENTS": "Dokumentit",
  "DOMINICA": "Dominica",
  "DOMINICAN REPUBLIC": "Dominikaaninen tasavalta",
  "DOWNLOAD_SUSTAINABILITY_REPORT_PDF": "Lataa vastuullisuusraportti (PDF)",
  "DUE_DATE": "Eräpäivä",
  "EAB_ASSET_MANAGEMENT": "EAB Varainhoito",
  "ECUADOR": "Ecuador",
  "EDUCATION": "Koulutus",
  "EDUCATION_AND_OCCUPATION": "Koulutus ja ammatti",
  "EDUCATION_IS_REQUIRED": "Koulutus on pakollinen tieto.",
  "EG": "Esim.",
  "EGYPT": "Egypti",
  "ei koulutusta": "Ei koulutusta",
  "EL SALVADOR": "El Salvador",
  "ELECTRONIC": "Sähköinen",
  "ELECTRONIC_SIGNATURE": "Sähköinen allekirjoitus",
  "ELECTRONIC_TRADING": "Sähköinen kaupankäynti",
  "Elintarvikkeiden valmistus": "Elintarvikkeiden valmistus",
  "ELITE_ASSET_MANAGEMENT_LTD": "EAB Group Oyj",
  "ELITE_CUSTOMER_SERVICE": "Elite Alfred Bergin asiakaspalvelu",
  "Elokuva- ja televisio-ohjelmatuotanto, musiikin kustantaminen": "Elokuva- ja televisio-ohjelmatuotanto, musiikin kustantaminen",
  "Eläinlääkintäpalvelut": "Eläinlääkintäpalvelut",
  "EMAIL": "Sähköposti",
  "Emerging market bonds": "Kehittyvät korkomarkkinat",
  "Emerging Market Debt": "Kehittyvät korkomarkkinat",
  "Emerging markets": "Kehittyvät markkinat",
  "Emerging Markets": "Kehittyvät markkinat",
  "EMPTY": "Tyhjä",
  "ENGLISH": "englanti",
  "END_BALANCE": "Loppusaldo",
  "END_DATE": "Loppupäivä",
  "EQUATORIAL GUINEA": "Päiväntasaajan Guinea",
  "Equities": "Osakesijoitukset",
  "Other Equities": "Muut Osakesijoitukset",
  "EQUITY": "Omat varat",
  "EQUITYFUND": "Osakerahasto",
  "Erikoistunut rakennustoiminta": "Erikoistunut rakennustoiminta",
  "ERITREA": "Eritrea",
  "ERROR_CANCELLING_TRANSACTION": "Hei, jokin meni vikaan tapahtuman peruuttamisessa tai tapahtumaa ei enää ole mahdollista perua.",
  "ERROR_DETAILS": "Virheilmoitukset",
  "ERROR_IN_CREATING_USERID": "Käyttäjätunnuksen luonnissa tapahtui virhe. Voit yrittää uudelleen valitsemalla peruuta.",
  "ERROR_SENDING_CONFIRMATION_CODE_BY_SMS": "Vahvistustunnuksen lähettämisessä SMS viestillä tapahtui virhe. Tarkista omista asetuksista että puhelinnumerosi on kirjoitettu oikein.",
  "ERROR_SENDING_INFORMATION": "Virhe lähetettäessä tietoja",
  "ERROR_UPDATING_INFO": "Virhe tietojen päivittämisessä",
  "ERROR_IN_ONLINE_AUTHENTICATION": "Virhe verkkotunnistautumisessa",
  "ERROR_IN_NEW_ORDER": "Toimeksiannon luonnissa tapahtui virhe.",
  "ESTONIA": "Viro",
  "ETHIOPIA": "Etiopia",
  "EUR_MARK": "€",
  "Europe": "Eurooppa",
  "EXCECUTING_ORDER": "Suoritetaan toimeksiantoa",
  "EXPECTED_END_VALUE_OF_100000_€_INVESTMENT": "100 000 € sijoituksen odotettu loppuarvo",
  "EXPECTED_END_VALUE_OF_THE_WORST_CASE_SCENARIO": "Pahin tapaus (5% todennäköisyys) odotettu loppuarvo",
  "EXPENSE": "Kulu",
  "EXTERNAL_ACCOUNT": "Ulkoinen tili",
  "Factsheet": "Tietosivu",
  "FALKLAND ISLANDS (MALVINAS)": "Falklandinsaaret",
  "FAROE ISLANDS": "Färsaaret",
  "FEE": "Palkkio",
  "FEE_DEBT": "Palkkiovelka",
  "FEE_EUR": "Palkkio",
  "FEE_REBATES": "Palkkionpalautukset",
  "FEE_SUMMARY": "Palkkioyhteenveto",
  "FEEREBATE": "Palkkioalennus",
  "FIELD_CANT_BE_EMPTY": "Kenttä ei voi olla tyhjä",
  "FIJI": "Fidzi",
  "FIN": "Suomi",
  "FINANCIAL_INFORMATION": "Taloudelliset tiedot",
  "FINLAND": "Suomi",
  "FINNISH": "suomi",
  "FINNISH_DATE": "Päättymispäivä",
  "FIRST_NAME": "Etunimi",
  "FIRST_NAME_IS_REQUIRED": "Etunimi on pakollinen.",
  "Fixed income": "Korkosijoitukset",
  "Fixed Income": "Korkosijoitukset",
  "Flexible Fixed Income Strategies": "Joustavat korkostrategiat",
  "Flexible interest rate strategies": "Joustavat korkostrategiat",
  "FOR_TRANSACTION": "tapahtuman",
  "FOR_WHOLE_WEALTH": "Koko varallisuus",
  "FOREKNOWLEDGE_OF_DISTANCE_SELLING_AND_SERVICES": "Etämyynnin ja palvelujen ennakkotiedot",
  "FORGOT_YOUR_ACCOUNT": "Tunnukset unohtuneet",
  "FOUND_CUSTOMERS_WITH_THE_GIVEN_IDENTIFICATION": "Löydettiin asiakkaat tehdyllä tunnistautumisella",
  "FOUND_USERS_WITH_BANK_ACCOUNT": "Verkkotunnistautumisella löytyneet käyttäjät",
  "FRANCE": "Ranska",
  "FREE_RANGE": "Vapaa valinta",
  "FRENCH GUIANA": "Ranskan Guayana",
  "FRENCH POLYNESIA": "Ranskan Polynesia",
  "FRENCH SOUTHERN TERRITORIES": "Ranskan eteläiset alueet",
  "FREQUENT_PAYMENT": "Toistuva maksu",
  "FROM": "Alkaen",
  "FROM_BEGINNING": "Alusta",
  "FROM_BEGINNING_OF_CONTRACT": "Salkun alusta",
  "FROM_BEGINNING_OF_CONTRACT_ANNUAL": "Salkun alusta, vuotuinen",
  "FROM_BEGINNING_OF_YEAR": "Vuoden alusta",
  "FROM_PORTFOLIO": "salkusta",
  "FUND": "Rahasto",
  "FUND_BASIC_INFORMATION": "Perustiedot",
  "FUND_FAMILY": "Rahastoperhe",
  "FUND_KIID_MUST_BE_ACCEPTED_BEFORE_CONTINUING": "Hyväksyminen tulee suorittaa ennen jatkamista",
  "FUND_MUST_BE_SELECTED": "Rahasto on valittava",
  "FUND_MUST_BE_SELECTED_FROM_LIST": "Rahasto on valittava listasta",
  "FUND_OF_FUNDS": "Rahastojen rahasto",
  "FUND_SEARCH": "Rahastot",
  "FUND_SEARCH_LINK": "Tutustu rahastovalikoimaamme",
  "FUND_SEARCH_TOOLTIP_PROFIT_ONE_MONTH": "Rahasto-osuuden euromääräisen arvon kuukausimuutos prosentteina.",
  "FUND_SEARCH_TOOLTIP_PROFIT_ONE_YEAR": "Rahasto-osuuden euromääräisen arvon vuosimuutos prosentteina.",
  "FUND_SEARCH_TOOLTIP_VALUE": "Rahasto-osuuden merkintävaluutassa noteerattu hinta.",
  "FUND_TO_CLAIM": "Lunastettava rahasto",
  "FUND_TO_MARK": "Merkittävä rahasto",
  "FUNDS_NON_UCITS": "Funds (Non-UCITS)",
  "FUNDS_TOTAL_FOUND": "rahastot. Yhteensä löytyi",
  "FUNDS_UCITS": "Rahastot (UCITS)",
  "GABON": "Gabon",
  "GAMBIA": "Gambia",
  "GENERAL": "Yleiset",
  "GENERAL_CONTRACT_TERMS": "Yleiset sopimusehdot",
  "CONTRACT_TERMS_IMPORTANT_INFO": "Sopimusehdot ja tärkeää tietoa",
  "CONTRACT_CUSTOMER_INFO": "Asiakasinformaatio",
  "PERSONAL_DATA_PROCESSING": "Henkilötietojen käsittely",
  "ACCESSIBILITY_STATEMENT": "Saavutettavuusseloste",
  "CUSTOMER_SERVICE_OPENING_HOURS": "klo 9.00-17.00",
  "GENERAL_DESCRIPTION": "Rahaston yleiskuvaus",
  "GENERAL_INVESTMENT_PURSUIT_OF_PROFIT": "Yleinen sijoitustoiminta (tuoton tavoittelu)",
  "GEORGIA": "Georgia",
  "GERMANY": "Saksa",
  "GENERAL_SETTINGS": "Yleiset asetukset",
  "GET_USERID": "Luo tunnukset",
  "GHANA": "Ghana",
  "GIBRALTAR": "Gibraltar",
  "GIFT": "Lahja",
  "GIVE_YOUR_NEW_PASSWORD": "Syötä alla oleviin kenttiin uusi salasana ja lähetä tiedot.",
  "GIVE_YOUR_PASSWORD": "Syötä alla oleviin kenttiin salasanasi.",
  "Global": "Maailma",
  "GO_TO_CUSTOMER_SERVICE_PAGE": "Siirry asiakaspalvelusivulle",
  "YOU_WILL_BE_REDIRECTED": "Sinut uudelleenohjataan ",
  "TO_CUSTOMER_SERVICE_PAGE": "asiakaspalvelusivulle.",
  "TO_CUSTOMER_INFO_PAGE": "Asiakastiedot-sivulle.",
  "TO_TAX_LIABILITY_PAGE": "Verovelvollisuus-sivulle.",

  "Government bonds": "Valtionlainat",
  "Government Bonds": "Valtionlainat",
  "GREECE": "Kreikka",
  "GREENLAND": "Grönlanti",
  "GRENADA": "Grenada",
  "GROSS_SALARIES": "Bruttopalkat",
  "GROUPING_AND_ADMINISTRATION": "Ryhmittely ja hallinnointi",
  "GUADELOUPE": "Guadeloupe",
  "GUAM": "Guam",
  "GUATEMALA": "Guatemala",
  "GUERNSEY": "Guernsey",
  "GUINEA": "Guinea",
  "GUYANA": "Guyana",
  "HAITI": "Haiti",
  "Hallinto- ja tukipalvelut liike-elämälle": "Hallinto- ja tukipalvelut liike-elämälle",
  "HEADER_DANGER": "Virhe!",
  "HEADER_SAVED": "Tallennettu!",
  "HEARD ISLAND AND MCDONALD ISLANDS": "Heard- ja McDonaldsaaret",
  "Hedge funds": "Suojarahastot",
  "Hedge Funds": "Suojarahastot",
  "HEDGEFUND": "Hedgerahasto",
  "HERE": "tästä",
  "HISTORICAL_PROFIT_IS_NOT_GUARANTEE_FOR_FUTURE_PROFIT": "Historiallinen tuotto ei ole tae tulevasta tuotosta.",
  "HOLDINGS": "Omistukset",
  "HOLDINGS_DISTRIBUTION": "Omistusten jakauma",
  "HOLDINGS_DISTRIBUTION_HISTORY": "Omistusten historiallinen jakauma",
  "HOLY SEE (VATICAN CITY STATE)": "Vatikaani",
  "HONDURAS": "Honduras",
  "HONG KONG": "Hongkong",
  "HUNGARY": "Unkari",
  "Huonekalujen valmistus": "Huonekalujen valmistus",
  "I_AM_GENERALLY_A_TAXPAYER_IN_ANOTHER_COUNTRY_THAN_FINLAND": "Olen yleisesti verovelvollinen jossain muussa maassa kuin Suomessa",
  "I_HAVE_KNOWLEDGE_OF_THE_FOLLOWING_INSTRUMENTS": "Tunnen seuraavat rahoitusvälineet",
  "I_HAVE_READ_FUND_KIID": "Olen tutustunut rahastoesitteeseen, rahaston avaintietoesitteeseen sekä rahaston sääntöihin.",
  "I_HAVE_READ_GENERAL_INVESTMENT_SERVICE_TERMS": "Olen tutustunut sijoituspalvelujen",
  "I_HAVE_READ_GENERAL_INVESTMENT_SERVICE_TERMS_2": "yleisiin ehtoihin",
  "I_HAVE_READ_GENERAL_INVESTMENT_SERVICE_TERMS_3": "ja hyväksyn ne itseäni sitoviksi.",
  "I_HAVE_READ_SERVICE_RATES": "Olen tutustunut",
  "I_HAVE_READ_SERVICE_RATES_2": "palveluhinnastoon",
  "I_HAVE_READ_SERVICE_RATES_3": "ja hyväksyn sen.",
  "I_SIGN_THE_CONTRACT": "Vakuutan että edellä annetut tiedot ovat oikein ja allekirjoitan sopimuksen.",
  "ICELAND": "Islanti",
  "ID": "Tunnus",
  "IDENTIFICATION_INFORMATION": "Tunnistautumistiedot",
  "IDENTIFY": "Tunnistaudu",
  "IDENTIFY_WITH_BANK_ACCOUNTS": "Valitse pankkisi",
  "IDENTIFY_TO_MODIFY_INFORMATION": "Tunnistaudu muuttaaksesi tietoja",
  "IDENTIFYING_CANCELLED": "Verkkotunnistautuminen keskeytettiin",
  "IDENTIFYING_DIDNT_SUCCESS": "Verkkotunnistautuminen ei joko mennyt läpi tai sitä ei ole tehty, yritä uudestaan palaamalla takaisin Tupas-tunnistautumiseen",
  "IDENTIFYING_FAILED_PLEASE_TRY_AGAIN": "Hei, Verkkotunnistautuminen epäonnistui. Ole hyvä ja yritä uudelleen tai palaa takaisin pääsivulle",
  "IDENTIFYING_IS_CANCELLED": "Verkkotunnistautuminen keskeytettiin kesken operaation, ole hyvä ja yritä uudestaan",
  "IDENTIFYING_IS_REJECTED": "Verkkotunnistautuminen hylättiin, ole hyvä ja tarkista tunnukset ja yritä uudestaan",
  "IDENTIFYING_REJECTED": "Verkkotunnistautuminen keskeytyi",
  "IDENTIFYING_FAILED": "Verkkotunnistautuminen epäonnistui.",
  "IDENTIFYING_SUCCEEDED_YOUR_ACCOUNT_IS": "Verkkotunnistautuminen onnistui. Käyttäjätunnuksesi on",
  "IDENTIFYING_SUCCESS_YOUR_ACCOUNT_IS": "Käyttäjä, jolle salasana resetoidaan: ",
  "IDENTIFYING_SUCCESS_YOUR_CONTACT_IS": "Asiakas, jolle uusi käyttäjätunnus luodaan",
  "IF_ANOTHER_WHAT": "Jos muu, mikä?",
  "IF_ANY_QUESTIONS": "Jäikö kysyttävää? Oma pankkiirisi palvelee sinua.",
  "IF_YOU_CANT_FIND_FUND_TRY": "Jos et löydä haluamaasi rahastoa, kokeile",
  "IF_YOU_HAVE_FORGOTTEN_ACCOUNTS": "Jos olet unohtanut käyttäjätunnuksesi tai salasanasi, voit verkkotunnistautumisen avulla hakea olemassa olevan käyttäjätunnuksen tai/ja vaihtaa uuden salasanan.",
  "Ilmaliikenne": "Ilmaliikenne",
  "INDEXED_PROFIT_CHART_OF_PORTFOLIO": "Varallisuuden indeksoitu tuottokehitys",
  "INDEXLINKEDBONDFUND": "Indeksilainarahasto",
  "INDIA": "Intia",
  "INDONESIA": "Indonesia",
  "INDUSTRY": "Toimiala",
  "INDUSTRY_IS_REQUIRED": "Toimiala on pakollinen tieto.",
  "Inflation-linked": "Inflaatiolinkatut",
  "INFO": "Info",
  "INHERITANCE": "Perintö",
  "INPUTTING_ADDITIONAL_INFOMATION": "Lisätietojen syöttäminen",
  "INPUTTING_INVESTMENT_EXPERIENCE_INFORMATION": "Sijoituskokemustietojen syöttäminen",
  "INSTRUCTIONS": "Ohje",
  "INSTRUCTIONS_FOR_MONTHLY_DEPOSIT": "Kuukausisäästäminen ei vaadi erillistä sopimusta Elite Alfred Bergin eikä tilipankkisi kanssa, vaan voit määrittää toistuvan maksun suoraan tilipankkisi verkkopankissa. Syötä alla olevan maksulomakkeen mukaiset tiedot oman tilipankkisi verkkopalvelussa ja valitse maksua syötettäessä maksun toistuvuus esimerkiksi kuukausittain. Maksamalla maksun, vastaava summa talletetaan rahastotilillesi jonka jälkeen järjestelmä luo sinulle automaattisesti vastaavalla summalla merkinnän valitsemaasi rahastoon.",
  "INSTRUMENT_KNOWLEDGE": "Rahoitusvälinetietämys",
  "INSTRUMENTS_NAME": "Instrumentin nimi",
  "INSTRUMENTS_NAME_AND_QUANTITY": "Luovutettu arvopaperi/arvo-osuus ja kpl-määrä",
  "Inverse": "Käänteiset",
  "INVESTMENT_ADVICE": "Sijoitusneuvonta",
  "INVESTMENT_AREA": "Sijoitusalue",
  "INVESTMENT_EXPERIENCE": "Sijoituskokemus",
  "INVESTMENT_EXPERIENCE_AND_KNOWLEDGE": "Sijoituskokemus- ja tietämys",
  "INVESTMENT_EXPERIENCE_EXPLANATION": "Laki velvoittaa meitä myös kysymään sijoituskokemuksesta ja -tietämyksestä.",
  "INVESTMENT_EXPERIENCE_IS_REQUIRED": "Sijoituskokemus on pakollinen tieto.",
  "INVESTMENT_EXPERIENCE_MAXIMUM": "Sijoituskokemus enintään 100 vuotta",
  "INVESTMENT_EXPERIENCE_MINIMUM": "Sijoituskokemus vähintään 0",
  "INVESTMENT_EXPERIENCE_MUST_BE_A_NUMBER": "Sijoituskokemuksen on oltava numero.",
  "INVESTMENT_EXPERIENCE_MUST_BE_AN_INTEGER": "Sijoituskokemuksen on oltava kokonaisluku.",
  "INVESTMENT_GOALS": "Sijoitustavoitteet",
  "INVESTMENT_GOALS_EXPLANATION": "Valitse sijoitustavoitteillesi parhaiten sopiva riskiluokka.",
  "INVESTMENT_INCOME_EG_DIVIDEND_INCOME_RENT_INCOME": "Tulovirta sijoituksista (esim. osinkotulot, vuokratulo)",
  "INVESTMENT_MONITORING": "Sijoitusten seuranta",
  "INVESTMENT_MONITORING_IS_REQUIRED": "Sijoitusten seuranta on pakollinen tieto.",
  "INVESTMENT_POLICY": "Sijoituspolitiikka",
  "INVESTMENT_SALES": "Sijoitusten myynti",
  "INVESTMENT_SECTOR": "Sijoitussektori",
  "INVESTMENT_STYLE": "Sijoitustyyli",
  "INVESTMENT_WEALTH": "Sijoitusvarallisuus",
  "INVESTMENT_WEALTH_AMOUNT": "Sijoitusvarallisuuden määrä",
  "INVESTMENT_WEALTH_IS_REQUIRED": "Sijoitusvarallisuus on pakollinen tieto.",
  "INVESTMENT_WEALTH_MUST_BE_A_NUMBER": "Sijoitusvarallisuuden on oltava numero.",
  "INVESTMENT_WEALTH_MUST_BE_POSITIVE": "Sijoitusvarallisuuden on oltava positiivinen luku.",
  "IRAN, ISLAMIC REPUBLIC OF": "Iran",
  "IRAQ": "Irak",
  "IRELAND": "Irlanti",
  "IS": "on",
  "IS_INVALID": "on virheellinen",
  "FILE": "Tiedosto",
  "IS_TOO_HIGH": "on liian suuri",
  "IS_TOO_LOW": "on liian pieni",
  "IS_TOO_LARGE": "on liian suuri",
  "TYPE_A_NUMBER": "syötä luku",
  "ON_WEEKDAYS": "(ma - pe)",
  "MAX_TWO_DECIMALS": "Anna summa max. kahden desimaalin tarkkuudella.",
  "MAX_N_DECIMALS_1": "Anna summa max. ",
  "MAX_N_DECIMALS_2": " desimaalin tarkkuudella.",
  "IS_REQUIRED": "on pakollinen tieto",
  "ISLE OF MAN": "Mansaari",
  "ISRAEL": "Israel",
  "ITALY": "Italia",
  "ITS_EASY_TO_OPEN_ACCOUNT": "Rahastosalkun avaaminen on helppoa, voit tehdä sen joko verkkotunnistautumisella tai vaihtoehtoisesti tilaamalla sopimuspaketin postitse",
  "JAMAICA": "Jamaika",
  "Japan": "Japani",
  "JAPAN": "Japani",
  "JERSEY": "Jersey",
  "JORDAN": "Jordania",
  "Julkinen hallinto ja maanpuolustus, pakollinen sosiaalivakuutus": "Julkinen hallinto ja maanpuolustus, pakollinen sosiaalivakuutus",
  "Juomien valmistus": "Juomien valmistus",
  "Järjestöjen toiminta": "Järjestöjen toiminta",
  "Jätteen keruu, käsittely ja loppusijoitus, materiaalien kierrätys": "Jätteen keruu, käsittely ja loppusijoitus, materiaalien kierrätys",
  "Kaivostoimintaa palveleva toiminta": "Kaivostoimintaa palveleva toiminta",
  "Kalastus ja vesiviljely": "Kalastus ja vesiviljely",
  "Kansainvälisten organisaatioiden ja toimielinten toiminta": "Kansainvälisten organisaatioiden ja toimielinten toiminta",
  "Kasvinviljely ja kotieläintalous, riistatalous ja niihin liittyvät palvelut": "Kasvinviljely ja kotieläintalous, riistatalous ja niihin liittyvät palvelut",
  "KAZAKHSTAN": "Kazakstan",
  "Kemikaalien ja kemiallisten tuotteiden valmistus": "Kemikaalien ja kemiallisten tuotteiden valmistus",
  "KENYA": "Kenia",
  "keskiaste": "Keskiaste",
  "Key Investor Information Document (KIID)": "KID",
  "Kiinteistöalan toiminta": "Kiinteistöalan toiminta",
  "Kiinteistön- ja maisemanhoito": "Kiinteistön- ja maisemanhoito",
  "KIRIBATI": "Kiribati",
  "Kirjastojen, arkistojen, museoiden ja muiden kulttuurilaitosten toiminta": "Kirjastojen, arkistojen, museoiden ja muiden kulttuurilaitosten toiminta",
  "Kivihiilen ja ruskohiilen kaivu": "Kivihiilen ja ruskohiilen kaivu",
  "Koksin ja jalostettujen öljytuotteiden valmistus": "Koksin ja jalostettujen öljytuotteiden valmistus",
  "Koneiden ja laitteiden korjaus, huolto ja asennus": "Koneiden ja laitteiden korjaus, huolto ja asennus",
  "KOREA, REPUBLIC OF": "Etelä-Korea",
  "Kotitalouksien eriyttämätön toiminta": "Kotitalouksien eriyttämätön toiminta",
  "Kotitalouksien toiminta kotitaloustyöntekijöiden työnantajina": "Kotitalouksien toiminta kotitaloustyöntekijöiden työnantajina",
  "Koulutus": "Koulutus",
  "Kulttuuri- ja viihdetoiminta": "Kulttuuri- ja viihdetoiminta",
  "Kumi- ja muovituotteiden valmistus": "Kumi- ja muovituotteiden valmistus",
  "Kustannustoiminta": "Kustannustoiminta",
  "KUWAIT": "Kuwait",
  "KYRGYZSTAN": "Kirgisia",
  "Käsitelty": "Käsitelty",
  "Käsittelyssä": "Käsittelyssä",
  "Käteinen": "Käteinen",
  "Lakiasiain- ja laskentatoimen palvelut": "Lakiasiain- ja laskentatoimen palvelut",
  "Landline": "Kiinteästä liittymästä",
  "LANGUAGE_SELECTION": "Kielivalinta",
  "LANGUAGE": "Kieli",
  // DONE lisätty 19.11.2021
  "WEB_SERVICE_LANGUAGE": "Verkkopalvelun kieli",

  "LANGUAGE_POPUP_TEXT": "EAB Verkkopalvelun käyttöliittymä on saatavilla myös englannin ja ruotsin kielellä. Elite Alfred Bergin pääasiallinen palvelukieli on kuitenkin suomi, eikä sillä ole velvollisuutta tarjota palveluita muilla kielillä. Rahastojen avaintietoesitteet ovat saatavilla suomeksi tai ruotsiksi. Rahastojen materiaalien saatavuutta muilla kielillä ei voida taata.",
  "LAST_NAME": "Sukunimi",
  "LAST_NAME_IS_REQUIRED": "Sukunimi on pakollinen.",
  "LATVIA": "Latvia",
  "LAW_FOR_MONEY_LAUNDERING_AND_TERRORISM": "Laki rahanpesun ja terrorismin rahoittamisen estämisestä ja selvittämisestä velvoittaa meitä kysymään asiakkailtamme sijoitettavan varallisuuden alkuperän",
  "LEBANON": "Libanon",
  "LESOTHO": "Lesotho",
  "LESS_THAN": "Alle",
  "LESS_THAN_ANNUALLY": "Harvemmin kuin kerran vuodessa",
  "LIABILITIES": "Velat",
  "LIBERIA": "Liberia",
  "LIBYAN ARAB JAMAHIRIYA": "Libya",
  "LIECHTENSTEIN": "Liechtenstein",
  "LITHUANIA": "Liettua",
  "LOADING_ACCOUNTS": "Ladataan asiakkuuksia",
  "LOADING_ASSET_CHANGES": "Ladataan varallisuuden muutosta",
  "LOADING_ASSET_SUMMARY": "Ladataan kokonaisvarallisuutta",
  "LOADING_BANKER_INFO": "Ladataan oman pankkiirin tietoja",
  "LOADING_BALANCE": "Ladataan saldoa",
  "LOADING_FEE_SUMMARY": "Ladataan palkkioyhteenvetoa",
  "LOADING_FILE": "Ladataan tiedostoa",
  "LOADING_FUNDS": "Ladataan rahastoja",
  "LOADING_FUND": "Ladataan rahastoa",
  "LOADING_FUNDS_FAILED": "Rahastojen lataaminen epäonnistui.",
  "LOADING_GAINS_AND_LOSSES": "Ladataan realisoituneita voittoja / tappioita",
  "LOADING_HOLDINGS": "Ladataan omistuksia",
  "LOADING_INSTRUMENT_DOCUMENTS": "Ladataan rahaston dokumentteja",
  "LOADING_INFORMATION": "Ladataan tietoja",
  "LOADING_TAXREPORT": "Ladataan veroraporttia",
  "LOADING_HOLDINGS_DISTRIBUTION": "Ladataan omistusten jakaumaa",
  "LOADING_HOLDINGS_DISTRIBUTION_HISTORY_CHART": "Ladataan omistusten historiallista jakaumaa",
  "LOADING_HOLDINGS_FAILED": "Omistusten lataaminen epäonnistui.",
  "LOADING_TAXREPORT_FAILED": "Veroraportin lataaminen epäonnistui.",
  "LOADING_INDEXED_PROFIT_CHART_OF_PORTFOLIO": "Ladataan salkun indeksoitua tuottokehitystä",
  "LOADING_PORTFOLIOS": "Ladataan salkkuja",
  "LOADING_NEWSLETTERS": "Ladataan tiedotteita",
  "LOADING_NOTES": "Ladataan tiedotteita",
  "LOADING_RULES": "Ladataan sääntöjä",
  "LOADING_RULES_AND_NOTES": "Ladataan sääntöjä ja tiedotteita",
  "LOADING_CALCS": "Ladataan laskelmia",
  "LOADING_MIDYEAR": "Ladataan palkkioita",
  "LOADING_SHARES": "Ladataan osuuksia",
  "LOADING_PROFIT_SUMMARY": "Ladataan tuottoyhteenvetoa",
  "LOADING_REFERENCE_INDEX_VALUES": "Ladataan vertailuindeksin arvoja",
  "LOADING_REFERENCE_INDICES": "Ladataan vertailuindeksejä.",
  "LOADING_REFERENCE_INDICES_FAILED": "Vertailuindeksien lataaminen epäonnistui.",
  "LOADING_RISK_DISTRIBUTION": "Ladataan riskijakaumaa",
  "LOADING_RISK_STATISTICS": "Ladataan riskilukuja",
  "LOADING_SUMMARY": "Ladataan yhteenvetoa",
  "LOADING_TEN_LARGEST_INSTRUMENTS": "Ladataan 10 suurinta sijoitusta",
  "LOADING_TRANSACTIONS": "Ladataan tapahtumia",
  "UPDATING_TRANSACTIONS": "Päivitetään tapahtumia",
  "LOADING_TRANSACTIONS_FAILED": "Transaktioiden lataaminen epäonnistui.",
  "LOADING_USER_INFO": "Ladataan käyttäjätietoja",
  "LOADING_VALUE_CHART": "Ladataan arvoja",
  "LOADING_VALUE_CHART_FAILED": "Arvojen lataaminen epäonnistui.",
  "LOAN": "Laina",
  "LOCKED_SHARES": "lukitut osuudet",
  "LOG_OUT": "Kirjaudu ulos",
  "LOGIN_EXTRA_CONFIRMATION": "Kirjautumisen lisävarmistus",
  "LOGIN_INFO": "Kirjautumistiedot",
  "LOGIN_INFORMATION_CHANGED": "Kirjautumistunnisteet muutettu.",
  "LOGGING_IN": "Kirjaudutaan sisään, hetki ...",
  "LOGIN_PERSONNEL_FUND": "Kirjaudu henkilöstö-/palkkiorahaston verkkopalveluun",
  "LOGIN_PERSONNEL_FUND_INFO": "Verkkopalvelussa näet rahastoon ja omaan jäsenyytesi liittyvät tiedot, oman rahasto-osuutesi arvon ja sen muutokset. Voit myös tehdä kaikki rahaston jäsenyyteesi liittyvät vuosittaiset ilmoitukset ja omien henkilötietojen muutokset. ",
  "LOSSES_TOGETHER": "Tappiot yhteensä",
  "LUXEMBOURG": "Luxemburg",
  "Lääkeaineiden ja lääkkeiden valmistus": "Lääkeaineiden ja lääkkeiden valmistus",
  "Maa- ja vesirakentaminen": "Maa- ja vesirakentaminen",
  "Maaliikenne ja putkijohtokuljetus": "Maaliikenne ja putkijohtokuljetus",
  "Maaperän ja vesistöjen kunnostus ja muut ympäristönhuoltopalvelut": "Maaperän ja vesistöjen kunnostus ja muut ympäristönhuoltopalvelut",
  "MACAO": "Macao",
  "MACEDONIA, THE FORMER  YUGOSLAV REPUBLIC OF": "Makedonia",
  "MADAGASCAR": "Madagaskar",
  "MAIN_CLASS": "Pääluokka",
  "Mainostoiminta ja markkinatutkimus": "Mainostoiminta ja markkinatutkimus",
  "Majoitus": "Majoitus",
  "MALAWI": "Malawi",
  "MALAYSIA": "Malesia",
  "MALDIVES": "Malediivit",
  "MALI": "Mali",
  "MALTA": "Malta",
  "MANAGEMENT_FEE": "Hallinnointipalkkio",
  "MANDATORY_FIELD": "Pakollinen kenttä",
  "MARK": "Merkitse",
  "MARK_AGAIN_CLAIMED_FUNDS": "Merkitse uudelleen lunastetut varat",
  "MARK_TO_PORTFOLIO": "merkitsemään salkkuun",
  "MARKET_VALUE": "Markkina-arvo",
  "MARSHALL ISLANDS": "Marshallinsaaret",
  "MARTINIQUE": "Martinique",
  "Matkatoimistojen ja matkanjärjestäjien toiminta varauspalvelut": "Matkatoimistojen ja matkanjärjestäjien toiminta varauspalvelut",
  "MAURITANIA": "Mauritania",
  "MAURITIUS": "Mauritius",
  "MAXIMUM_100": "korkeintaan 100%",
  "MAYOTTE": "Mayotte",
  "MERGE": "Sulautuminen",
  "Metallien jalostus": "Metallien jalostus",
  "Metallimalmien louhinta": "Metallimalmien louhinta",
  "Metallituotteiden valmistus (pl. koneet ja laitteet)": "Metallituotteiden valmistus (pl. koneet ja laitteet)",
  "Metsätalous ja puunkorjuu": "Metsätalous ja puunkorjuu",
  "MEXICO": "Meksiko",
  "MICRONESIA, FEDERATED STATES OF": "Mikronesia",
  "MIFID_EXPLANATION": "Laki rahanpesun ja terrorismin rahoittamisen estämisestä ja selvittämisestä velvoittaa meitä kysymään asiakkailtamme seuraavat tiedot.",
  "MIN_MARK_FOR_FUND": "Rahaston minimimerkintä on",
  "MINIMUM": "(minimi)",
  "MINIMUM_0": "vähintään 0",
  "MINIMUM_1": "vähintään 1",
  "MINIMUM_25": "vähintään 25%",
  "MINIMUM_INVESTMENT": "Minimimerkintä",
  "MINIMUM_INVESTMENT_AND_FEES": "Minimisijoitus ja palkkiot",
  "MINIMUM_INVESTMENT_AT_MOST": "Minimisijoitus korkeintaan",
  "MINIMUM_INVESTMENT_TIME": "Sijoitus min.",
  "MINIMUM_REDEMPTION_FEE": "Minimilunastuspalkkio",
  "MINIMUM_SUBSCRIPTION_FEE": "Minimimerkintäpalkkio",
  "Mobile": "Matkapuhelimesta",
  "MODERATE": "Maltillinen",
  "MODIFY": "Muokkaa",
  "MODIFY_INFORMATION": "Muokkaa tietoja",
  "MODIFYING_USER_INFO": "Tietojen muokkaaminen",
  "MOLDOVA": "Moldova",
  "MONACO": "Monaco",
  "Money Market": "Rahamarkkinat",
  "MONGOLIA": "Mongolia",
  "MONTENEGRO": "Montenegro",
  "MONTHLY": "Kuukausittain",
  "MONTHLY_EXPEDITURE": "Säännölliset taloudelliset sitoumukset",
  "MONTHLY_REPORT": "Kuukausikatsaus",
  "MONTHLY_REPORTS": "Kuukausikatsaukset",
  "MONTHLY_REPORTS_OWNED": "Omistamiesi rahastojen kuukausikatsaukset",
  "TO_MONTHLY_REPORTS": "Katso kaikkien osuussarjojen kuukausikatsaukset täältä",
  "MONTHS": "kuukautta",
  "MONTSERRAT": "Montserrat",
  "Moottoriajoneuvojen tukku- ja vähittäiskauppa sekä korjaus": "Moottoriajoneuvojen tukku- ja vähittäiskauppa sekä korjaus",
  "Moottoriajoneuvojen, perävaunujen ja puoliperävaunujen valmistus": "Moottoriajoneuvojen, perävaunujen ja puoliperävaunujen valmistus",
  "MORE": "Lisää",
  "MORE_THAN": "Yli",
  "MORE_OPTIONS": "Lisää vaihtoehtoja",
  "MORNINGSTAR": "Morningstar",
  "MORNINGSTAR_RATING": "Morningstar Rating",
  "MOROCCO": "Marokko",
  "MOZAMBIQUE": "Mosambik",
  "Muiden ei-metallisten mineraalituotteiden valmistus": "Muiden ei-metallisten mineraalituotteiden valmistus",
  "Muiden koneiden ja laitteiden valmistus": "Muiden koneiden ja laitteiden valmistus",
  "Muiden kulkuneuvojen valmistus": "Muiden kulkuneuvojen valmistus",
  "MUST_BE_A_NUMBER": "täytyy olla numero",
  "MUST_BE_GIVEN": "on syötettävä",
  "MUST_BE_NUMERIC": "on oltava numeerinen",
  "Muu kaivostoiminta ja louhinta": "Muu kaivostoiminta ja louhinta",
  "Muu valmistus": "Muu valmistus",
  "Muut erikoistuneet palvelut liike-elämälle": "Muut erikoistuneet palvelut liike-elämälle",
  "Muut henkilökohtaiset palvelut": "Muut henkilökohtaiset palvelut",
  "MYANMAR": "Myanmar",
  "Nahan ja nahkatuotteiden valmistus": "Nahan ja nahkatuotteiden valmistus",
  "NAME": "Nimi",
  "NAME_YOUR_OWN_PORTFOLIOS": "Nimeä omat salkut",
  "NAME_YOUR_PORTFOLIOS_TO_FIND_THEM_EASIER": "Voit nimetä omat salkusi, jolloin löydät ne helpommin salkkunäkymistä.",
  "NAMIBIA": "Namibia",
  "NAURU": "Nauru",
  "NEPAL": "Nepal",
  "NET_PROFIT": "Liiketulos",
  "NETHERLANDS": "Alankomaat",
  "NETHERLANDS ANTILLES": "Alankomaiden Antillit",
  "NEWSLETTERS": "Uutiskirje ja tiedotteet",
  "NEW CALEDONIA": "Uusi-Kaledonia",
  "NEW ZEALAND": "Uusi-Seelanti",
  "NEW_CUSTOMER": "Uusi asiakas",
  "NEW_DEPOSIT": "Tee uusi talletus",
  "NEW_MONTHLY_SAVING": "Tee uusi kuukausisäästö",
  "NEW_ORDER_ESTIMATE": "arvio",
  "NEW_ORDER_ESTIMATED_CASH_FROM_OPEN_SELLS": "Arvio lunastuksista tulevasta käteisestä",
  "NEW_ORDER_PORTFOLIO_CASHBALANCE": "Salkun saldo / Käytettävissä",
  "NEW_ORDER_PRECISE_SUM_WHEN_CONFIRMED": "Summa tarkentuu lunastuksien vahvistuessa",
  "NEW_ORDER_TOTAL_AVAILABLE_CASH": "Käytettävissä oleva käteinen",
  "NEW_PASSWORD": "Uusi salasana",
  "NEW_PASSWORD_MATCHES_OLD": "Uusi salasana on sama kuin vanha salasana",
  "NEW_PASSWORD_UPDATED": "Uusi salasanasi on päivitetty.",
  "NEW_REDEMPTION": "Tee uusi lunastus",
  "NEW_SUBSCRIPTION": "Tee uusi merkintä",
  "NEW_WITHDRAW": "Tee uusi nosto",
  "NEW": "Uusi",
  "NEXT": "Seuraava",
  "NICARAGUA": "Nicaragua",
  "NIGER": "Niger",
  "NIGERIA": "Nigeria",
  "NIUE": "Niue",
  "NO": "Ei",
  "NO_CONTACTS_FOUND": "Yhtään asiakkuutta ei löytynyt tunnistautumisen perusteella. Voit palata takaisin tunnistautumiseen",
  "NO_FEES_FOUND": "Palkkioita ei löytynyt.",
  "NO_FUNDS_FOUND": "Rahastoja ei löytynyt annetuilla hakuehdoilla.",
  "NO_HOLDINGS_FOUND": "Omistuksia ei löytynyt.",
  "NO_HOLDINGS_FOR_TAXREPORT": "Ei luovutusvoittoja/tappioita verotuskaudella.",
  "NO_INSTRUMENT_DOCUMENTS_FOUND": "Rahaston dokumentteja ei löytynyt.",
  "NO_NEWSLETTERS": "Ei tiedotteita...",
  "NO_NEWSLETTERS_FOUND": "Tiedotteita ei löytynyt.",
  "NO_PORTFOLIOS_FOUND_FOR_WHICH_THIS_OPERATION_IS_ALLOWED": "Sinulla ei ole yhtään salkkua jossa tämä toiminto on sallittu.",
  "NO_PREVIOUS_INVESTMENT_EXPERIENCE": "Ei lainkaan sijoitustietämystä/-kokemusta",
  "NO_PROFITS_FOUND": "Tuottoja ei löytynyt.",
  "NO_TRANSACTIONS_FOUND": "Tapahtumia ei löytynyt annetuilla hakuehdoilla.",
  "NO_VALUES_FOUND_WITH_THE_GIVEN_DATE_RANGE": "Arvoja ei löytynyt valitulla aikavälillä. Valitse lyhyempi tarkastelujakso, vapaa valinta tai alusta.",
  "NO_VALUES_FOUND_WITH_THE_DATE_RANGE": "Arvoja ei löytynyt valitulla aikavälillä.",
  "Nordic Countries": "Pohjoismaat",
  "NORFOLK ISLAND": "Norfolkinsaari",
  "North America": "Pohjois-Amerikka",
  "NORTHERN MARIANA ISLANDS": "Pohjois-Mariaanit",
  "NORWAY": "Norja",
  "Nosto": "Nosto",
  "NOT_AVAILABLE": "Ei saatavilla.",
  "NOT_DATE": "Ei ole päivämäärä",
  "SELECT_EARLIER_DATE": "Valitse aikaisempi päivämäärä",
  "SELECT_LATER_DATE": "Valitse myöhäisempi päivämäärä",
  "PORTFOLIO_START_DATE": "Salkun aloituspäivä",
  "DATE_FORMAT": "pp.kk.vvvv",
  "LOADING_MORE_INFO": "Ladataan lisätietoja...",
  "ERROR_WHILE_LOADING_CASH_INFO": "Tietojen haussa tapahtui virhe. Tilin käteistietoja ei saatavilla.",

  "NOT_ENOUGH_CASH_FOR_SUBSCRIPTION_MAKE_DEPOSITION": "Käytettävissä oleva käteinen ei riitä merkintään. Merkintätoimeksianto toteutetaan, kun tilillä on riittävästi käteistä merkintään. Merkintätoimeksiantojen toteuttamiseksi siirrä tilille vähintään",
  "NOT_ENOUGH_CASH_FOR_SUBSCRIPTION_MAKE_DEPOSITION_ENDING": "",
  "NOT_ENOUGH_CASH_FOR_SUBSCRIPTION_MAKE_DEPOSITION_PLURAL": "Käytettävissä oleva käteinen ei riitä merkintöihin. Merkintätoimeksiannot toteutetaan, kun tilillä on riittävästi käteistä merkintöihin. Merkintätoimeksiantojen toteuttamiseksi siirrä tilille vähintään",
  "NOT_ENOUGH_CASH_FOR_SUBSCRIPTION_MAKE_DEPOSITION_PLURAL_ENDING": "",
  "NOT_ENOUGH_CASH_FOR_SUBSCRIPTION": "Käytettävissä oleva käteinen ei riitä merkintään. Merkintätoimeksianto toteutetaan, kun tilillä on riittävästi käteistä merkintään.",
  "NOT_ENOUGH_CASH_FOR_SUBSCRIPTION_ADD_MORE": "Käytettävissä oleva käteinen ei riitä merkintään.",
  "NOT_SELECTED": "Ei valittu",
  "NOT_YET_CUSTOMER": "Etkö ole vielä asiakas? Avaa asiakkuus ilmaiseksi.",
  "NOTHING": "Ei mitään",
  "NOW": "Nyt",
  "NUMBER_OF_SHARES": "Lukumäärä",
  "OCCUPATION": "Ammatti",
  "OCCUPATION_IS_REQUIRED": "Ammatti on pakollinen tieto.",
  "Odottaa käsittelyä": "Odottaa käsittelyä",
  "OH_SNAP": "Voi harmi",
  "Ohjelmistot, konsultointi ja siihen liittyvä toiminta": "Ohjelmistot, konsultointi ja siihen liittyvä toiminta",
  "OK": "Selvä",
  "OLD_PORTFOLIOS": "Vanhat salkut",
  "OMAN": "Oman",
  "Omien kiinteistöjen kauppa": "Omien kiinteistöjen kauppa",
  "Omien tai leasing-kiinteistöjen vuokraus ja hallinta": "Omien tai leasing-kiinteistöjen vuokraus ja hallinta",
  "ONE_IS_REQUIRED": "Toinen vaihtoehdoista on pakollinen!",
  "ONE_MONTH_SHORT": "1kk",
  "ONE_YEAR_SHORT": "1v",
  "TWO_YEARS_SHORT": "2v",

  "ONLINEID": "Verkkotunnistautuminen",
  "ONLINE_SERVICE": "Verkkopalvelu",
  "ONLY_FOR_HOMELAND_TRANSFER": "Vain kotimaan maksuliikenteessä",
  "ONLY_IF_ASKED": "Täytä vain pyydettäessä",
  "OPENED": "Avoimet",
  "OPEN_MONEY_TRANSACTIONS": "Avoimet rahatapahtumat",
  "OPTIONAL_EMAIL": "Vapaaehtoinen sähköposti",
  "OPTIONAL_INFORMATION": "Vapaaehtoinen tieto",
  "ORDERS": "Toimeksiannot",
  "ORDER_NUMBER": "Toimeksiannon numero",
  "ORDER_NEWSLETTERS": "Tilaamalla uutiskirjeen ja tiedotteet saat sähköpostiisi asiantuntijoidemme tuoreen näkemyksen markkinoista sekä rahanarvoisia neuvoja varallisuutesi hoitamiseen.",
  "ORDER_NEWSLETTERS_LANGUAGE": "Uutiskirje ja tiedotteet ovat saatavilla vain suomenkielisinä.",
  "ORDER_SELECTIONS": "Tilausvalinnat",
  "ORGANIZATION_NAME": "Yhteisön nimi",
  "ORGANIZATION_NAME_IS_REQUIRED": "Yhteisön nimi on pakollinen",
  "ORIGIN_OF_ASSETS": "Varojen alkuperä",
  "ORIGIN_OF_FUNDS": "Varojen alkuperä",
  "ORIGIN_OF_FUNDS_IS_REQUIRED": "Varojen alkuperä on pakollinen tieto",
  "ORIGIN_OF_INVESTMENT_ASSETS": "Sijoitettavien varojen alkuperä",
  "Osakerahasto": "NEED TO TRANSLATE!",
  "OTHER": "Muu",
  "Other": "Muut",
  "Other bonds": "Muut korkosijoitukset",
  "Other Fixed Income Instruments": "Muut korkosijoitukset",
  "Other Strategies": "Muut strategiat",
  "Other unclassified instruments etc.": "Muut kuten luokittelemattomat instrumentit",
  "OTHER_DESCRIPTION": "Muu kuvaus",
  "OTHERS": "Muut",
  "OVER": "Yli",
  "PAGE_NOT_FOUND": "Sivua ei löydy",
  "Painaminen ja tallenteiden jäljentäminen": "Painaminen ja tallenteiden jäljentäminen",
  "PAKISTAN": "Pakistan",
  "PALAU": "Palau",
  "PALESTINIAN TERRITORY, OCCUPIED": "Palestiina",
  "PANAMA": "Panama",
  "Paperin ja paperi- ja kartonkituotteiden valmistus": "Paperin ja paperi- ja kartonkituotteiden valmistus",
  "PAPUA NEW GUINEA": "Papua-Uusi-Guinea",
  "PARAGUAY": "Paraguay",
  "PASSWORD": "Salasana",
  "PASSWORD_AGAIN": "Salasana uudelleen",
  "PASSWORD_CONFIRMATION_DOES_NOT_MATCH": "Uusi salasana ja vahvistus eivät ole samoja.",
  "PASSWORD_HAS_TO_BE_6_CHARACTERS_LONG": "Salasana pitää olla vähintään 6 merkkiä pitkä",
  "PASSWORD_SAVING_FAILED": "Salasanan tallennus epäonnistui.",
  "PASSWORD_SAVING_SUCCESSFUL": "Salasana päivitetty.",
  "YOU_WILL_BE_REDIRECTED_TO_GENERAL_SETTINGS": " Sinut ohjataan takaisin Yleiset asetukset-sivulle",
  "PASSWORD_CONFIRMATION": "Salasanan vahvistaminen",
  "SAVING_PASSWORD": "Salasanaa tallennetaan",
  "PASSWORD_IS_INVALID": "Salasana on väärä",
  "PASSWORD_IS_MANDATORY": "Salasana on syötettävä",
  "PASSWORD_IS_ONLY_USED_WHEN_LOGIN_TO_ELITE": "Salasanaa käytetään Elite Alfred Berginn palveluun kirjautuessa",
  "PASSWORD_IS_REQUIRED": "Salasana on pakollinen",
  "PASSWORD_IS_REQUIRED_AGAIN": "Salasana pitää syöttää uudelleen",
  "PASSWORD_TOO_SHORT": "Salasanan on oltava vähintään kuusi merkkiä pitkä.",
  "PASSWORD_UPDATE": "Salasanan päivittäminen",
  "PASSWORDS_ARE_NOT_SAME": "Salasanat eivät täsmää",
  "PAY_DATE": "Maksupäivä",
  "PAYER": "Maksaja",
  "PAYMENT_ACCOUNT_HANDLE_MONEY_TRANSFER": "Rahastotilisopimus on asiakkaan ja Elite Alfred Bergin välinen sopimus liitteineen, jossa sovitaan asiakkaan ja Eliten välisestä sijoituspalvelusta sisältäen Rahoitusvälineiden säilytyspalvelut, kaupankäynnin Rahoitusvälineillä, Sähköiset palvelut ja asiakasvaratilit.",
  "PAYMENT_NOW": "Heti",
  "PENALTY_INTERESTS": "Hyvityskorot",
  "PENALTYINTEREST": "Hyvityskorko",
  "PERFORMANCE_FEE": "Tuottosidonnainen palkkio",
  "PERIOD": "Valittu ajanjakso",
  "PERSONAL_BANKER": "Oma pankkiiri",
  "PERSONAL_INFORMATION": "HENKILÖTIEDOT",
  "PERSONAL_SETTINGS": "Omat tiedot ja asetukset",
  "PERSONAL_INFO": "Omat tiedot",
  "PERSONNEL_FUND_CUSTOMER_SERVICE": "Henkilöstö- ja palkkiorahaston asiakaspalvelu",
  "PLEASE_CONTACT_ABOUT_PERSONNEL_FUNDS": "Asiakaspalvelumme auttaa sinua, kun asiasi koskee henkilöstö- ja palkkiorahastoa.",
  "PLEASE_WAIT": "Odotathan rauhassa sulkematta selainta.",
  "PERU": "Peru",
  "perusaste": "Perusaste",
  "Peruttu": "Peruttu",
  "PHILIPPINES": "Filippiinit",
  "PHONE_NUMBER": "Puhelinnumero",
  "PHONE_NUMBER_NEEDED": "(vaatii voimassaolevan puhelinnumeron)",
  "PHONE_NUMBER_REQUIRED": "Puhelinnumero on pakollinen",
  "PIECE": "kpl",
  "PIECE_OF_SHARES": "osuuksia",
  "PLEASE_CONTACT_IF_QUESTIONS": "Ole yhteydessä asiakaspalveluumme, kun jokin mietityttää.",
  "Pohjois-Amerikka": "Pohjois-Amerikka",
  "POLAND": "Puola",
  "POLITICALLY_INFLUENTIAL_ABROAD": "Asun ulkomailla ja olen tai olen ollut toisessa valtiossa merkittävässä julkisessa tehtävässä (poliittisesti vaikutusvaltainen henkilö) tai sellaisen henkilön perheenjäsen tai läheinen yhtiökumppani.",
  "PORTFOLIO": "Salkku",
  "PORTFOLIO_NAME": "Salkun nimi",
  "PORTFOLIO_DESELECT_ALL": "Poista valinnat",
  "PORTFOLIO_FROM_TO_CLAIM": "Valitse ensin salkku, josta haluat lunastaa omistuksia.",
  "PORTFOLIO_MANAGER": "Salkunhoitaja",
  "PORTFOLIO_MUST_BE_SELECTED": "Salkku on valittava",
  "PORTFOLIO_SELECT": "Salkkuvalinta",
  "PORTFOLIO_SELECT_ALL": "Valitse kaikki",
  "PORTFOLIO_TO_MARK": "Valitse ensin salkku, johon haluat tehdä merkinnän.",
  "PORTFOLIO_TYPE": "Salkkutyyppi",
  "PORTFOLIOS": "Salkut",
  "PORTUGAL": "Portugali",
  "POSTAL": "Postitse kotiin",
  "POST_OFFICE": "Postitoimipaikka",
  "POST_OFFICE_REQUIRED": "Postitoimipaikka on pakollinen",
  "Posti- ja kuriiritoiminta": "Posti- ja kuriiritoiminta",
  "PRECISION": "Tarkkuus",
  "PREVIOUS": "Edellinen",
  "PRICE": "Hinta",
  "PRICE_DATE": "Hintapäivä",
  "PRICE_IN_ANOTHER_CURRENCY": "Hinta valuutassa",
  "PRINT": "Tulosta",
  "Private Equity": "Listaamattomat osakkeet",
  "PROFILE_SETTINGS_CHANGED": "Käyttäjän profiiliasetukset muutettu.",
  "PROFIT": "Tuotto",
  "PROFIT_HISTORY": "Tuottohistoria",
  "PROFIT_LOSS": "Voitto / Tappio",
  "PROFIT_PERCENTAGE_ONE_MONTH": "Tuotto (EUR) 1kk %",
  "PROFIT_PERCENTAGE_ONE_YEAR": "Tuotto (EUR) 1v %",
  "PROFIT_SUMMARY": "Tuottoyhteenveto",
  "Prospectus": "Esite",
  "PUERTO RICO": "Puerto Rico",
  "Puhelu_short": "puh",
  "PURCHASE_COST_ESTIMATE": "Hankintameno-olettama",
  "PURCHASE_DATE": "Ostopäivä",
  "PURCHASE_FEE": "Hankintakulut",
  "PURCHASE_PRICES_TOGETHER": "Ostohinnat yhteensä",
  "PURCHASE_VALUE": "Ostohinta",
  "PURCHASE_DATE_FOR_TAX_PREPORT": "Hankinta-aika",
  "PURCHASE_VALUE_FOR_TAX_PREPORT": "Hankintahinta",
  "PURPOSE_OF_ORDER": "Oston tarkoitus",
  "Pääkonttorien toiminta, liikkeenjohdon konsultointi": "Pääkonttorien toiminta, liikkeenjohdon konsultointi",
  "QATAR": "Qatar",
  "Raakaöljyn ja maakaasun tuotanto": "Raakaöljyn ja maakaasun tuotanto",
  "Radio- ja televisiotoiminta": "Radio- ja televisiotoiminta",
  "Rahapeli- ja vedonlyöntipalvelut": "Rahapeli- ja vedonlyöntipalvelut",
  "Rahoituspalvelut (pl. vakuutus- ja eläkevakuutustoiminta)": "Rahoituspalvelut (pl. vakuutus- ja eläkevakuutustoiminta)",
  "Rahoitusta ja vakuuttamista palveleva toiminta": "Rahoitusta ja vakuuttamista palveleva toiminta",
  "Rakennuttaminen ja rakennushankkeiden kehittäminen": "Rakennuttaminen ja rakennushankkeiden kehittäminen",
  "Ravitsemistoiminta": "Ravitsemistoiminta",
  "READ_MORE": "Lue lisää",
  "Real Assets": "Reaaliomaisuus",
  "Real assets": "Reaaliomaisuus",
  "Real_Assets": "Reaaliomaisuus",
  "Real Estate": "Kiinteistöt",
  "Real estates": "Kiinteistöt",
  "EUR": "EUR",
  "REAL_ESTATE_SALE": "Kiinteistön myynti",
  "REALIZED": "Toteutuneet",
  "REALIZED_GAINS/LOSSES": "Toteutuneet voitot / tappiot",
  "Receivables": "Saamiset",
  "RECIDENCY_COUNTRY": "Asuinmaa",
  "RECLAIM": "Lunasta",
  "RECLAIM_FROM_PORTFOLIO": "myymään salkusta",
  "REDEMPTION": "Lunastus",
  "REDEMPTION_FEE": "Lunastuspalkkio",
  "REDEMPTIONDEPOSITION": "Lunastuksen talletus",
  "REDEMPTIONS": "Lunastukset",
  "REFERENCE_INDEX": "Vertailuindeksi",
  "REFERENCE_INDEX_CANNOT_BE_USED_WITH_THIS_FUND_AND_DATE_RANGE": "Valittua vertailuindeksiä ei voi käyttää graafissa tälle rahastolle valitulla aikavälillä.",
  "REFERENCE_NUMBER": "Viitenumero",
  "REGISTRATION_COUNTRY": "Rekisteröintimaa",
  "REMARKED_FUND": "Uudelleenmerkittävä rahasto",
  "REQUIRED": "Pakollinen tieto",
  "REPORT": "Raportti",
  "REPORTING": "Raportointi",
  "RESELLER_CODE": "Jälleenmyyjätunnus",
  "RETRIEVE_YOUR_USER_ID": "Hae käyttäjätunnuksesi tai resetoi salasanasi",
  "RETURN": "TAKAISIN",
  "RETURN_%": "Muutos %",
  "RETURN_ORIENTED": "Tuottohakuinen",
  "RETURN_TO_LOGIN": "Takaisin kirjautumiseen",
  "RETURN_TO_BROWSE_ORDERS": "Palaa toimeksiantojen selaamiseen",
  "RETURN_TO_START_PAGE_FROM": "Palaa takaisin aloitussivulle",
  "RÉUNION": "Reunion",
  "RISK_CLASS": "Riskiluokka",
  "RISK_CLASS_FOR_ELITE_SERVICES": "Riskiluokka Elite Alfred Bergin palveluille",
  "RISK_CLASS_FOR_WHOLE_WEALTH": "Riskiluokka koko varallisuudelle",
  "RISK_DISTRIBUTION": "Riskijakauma",
  "RISK_STATISTICS": "Riskiluvut",
  "ROMANIA": "Romania",
  "Rulebook": "Säännöt",
  "RUSSIAN FEDERATION": "Venäjä",
  "RWANDA": "Ruanda",
  "Sahatavaran sekä puu- ja korkkituotteiden valmistus": "Sahatavaran sekä puu- ja korkkituotteiden valmistus",
  "SAINT BARTHÉLEMY": "Saint Barthelémy",
  "SAINT HELENA": "Saint Helena",
  "SAINT KITTS AND NEVIS": "Saint Kitts ja Nevis",
  "SAINT LUCIA": "Saint Lucia",
  "SAINT MARTIN": "Saint Martin",
  "SAINT PIERRE AND MIQUELON": "Saint-Pierre ja Miquelon",
  "SAINT VINCENT AND THE GRENADINES": "Saint Vincent ja Grenadiinit",
  "SAMOA": "Samoa",
  "SAN MARINO": "San Marino",
  "SAO TOME AND PRINCIPE": "São Tomé ja Príncipe",
  "SAUDI ARABIA": "Saudi-Arabia",
  "SAVE": "Tallenna",
  "SAVE_CHANGES": "Tallenna muutokset",
  "SAVE_MONTHLY": "KK-säästä",
  "SAVE_PASSWORD": "TALLENNA SALASANA",
  "SAVE_SUCCESS_MESSAGE": "Tiedot tallennettiin onnistuneesti!",
  "SAVE_FAILED_MESSAGE": "Tietojen tallentaminen epäonnistui.",
  "SAVING_INFORMATION": "Tallennetaan tietoja",
  "SAVING_CUSTOMER_INFORMATION": "Asiakkuustietojasi tallennetaan!",
  "SAVING_TAKES_TIME": "Tilin luominen saattaa kestää 1 - 3 minuuttia.",
  "SAVINGS": "Säästöt",
  "SEARCH_BY_TYPING": "Hae kirjoittamalla",
  "SEARCH_FOR_FUND": "Etsi rahastoa",
  "SECTOR": "Sektori",
  "SECTOR_IS_REQUIRED": "Sektori on pakollinen",
  "SECURITY_ORIENTED": "Turvallisuushakuinen",
  "SELECT_PORTFOLIO": "Valitse salkku",
  "SELECT_PORTFOLIOS": "Valitse salkut",

  "SELECTED_SERVICE": "Valittu palvelu",
  "SELECT_PORTFOLIO_FIRST": "Valitse salkku ensin",
  "SELECT_SEND_TASK_REQUEST": "Voit välittää yhteydenottopyynnön yhteyshenkilöllenne, joka on teihin yhteydessä.",
  "SEND_TASK_REQUEST": "Lähetä yhteydenottopyyntö",
  "SELECTED": "Valittu",
  "SELF_SERVICE_INVESTING": "Omatoiminen sijoittaminen",
  "Sell": "Myynti",
  "SELL": "Myy",
  "SellDeposition": "Myynnin talletus",
  "SELLER_CODE": "Myyjätunnus",
  "SELL_DATE": "Luovutusaika",
  "SELL_FEE": "Myyntikulut",
  "SELL_PRICES_TOGETHER": "Luovutushinnat yhteensä",
  "SELL_VALUE": "Luovutushinta",
  "SELLRECEIVABLE": "Myyntisaatava",
  "SEND_INFORMATION": "Lähetä tiedot",
  "SENEGAL": "Senegal",
  "SERBIA": "Serbia",
  "SERVER_ERROR": "Palvelinvirhe",
  "SERVICE_RATES": "Palveluhinnasto",
  "SERVICE_RATES_MUST_BE_ACCEPTED_BEFORE_CONTINUING": "Palveluhinnasto tulee hyväksyä ennen kuin voi jatkaa.",
  "SESSION_TIMEOUT_SOON_TITLE": "Istunnon aikakatkaisu",
  "SESSION_TIMEOUT_SOON_QUESTION": "Istunto aikakatkaistaan hetken kuluttua. Haluatko jatkaa istuntoa?",
  "SESSION_TIMEOUT_NOTICE_TITLE": "Istunto on aikakatkaistu",
  "SESSION_TIMEOUT_NOTICE": "Istunto on aikakatkaistu, koska et ole käyttänyt verkkopalvelua vähään aikaan.",
  "END_SESSION": "Lopeta istunto",
  "SETTLEMENT_DATE": "Maksu pvm",
  "SEYCHELLES": "Seychellit",
  "SHARE": "Omistusosuus",
  "SHARE_PRICE": "Hinta € / kpl",
  "SHARES": "Osuudet",
  "SHOW": "Näytä",
  "SHOW_ALL_BRIEFS": "Näytä kaikki tiedotteet",
  "SHOW_MORE": "Näytä lisää",
  "SHOW_LESS": "Näytä vähemmän",
  "SHOW_PURCHASES": "Näytä ostoerittäin",
  "SHOW_RETURN_AND_LOSS_EXPECTATIONS": "Näytä tuotto- ja tappio-odotukset",
  "SHOW_TAX_REPORT_FOR_CURRENT_YEAR": "Näytä veroraportti kuluvalle vuodelle",
  "SHOWN": "Näytettynä",
  "SHOWN_ON_PAGE": "Näytetään sivulla",
  "SHOW_INFO_POPUP_TITLE_30092022": "EAB Verkkopalvelu",
  "SHOW_INFO_POPUP_CONTENT1_30092022": "EAB Verkkopalvelussa tehdään päivitystöitä. Näet kuitenkin omistuksesi arvot reaaliajassa. Rahastojen merkintä ja lunastus onnistuvat ainoastaan puhelimitse 30.9.-10.10.2022 välisenä aikana.",
  "SHOW_INFO_POPUP_CONTENT2_30092022": "Oma yhteyshenkilösi ja asiakaspalvelumme auttavat mielellään mahdollisissa kysymyksissä sekä rahastojen merkinnässä ja lunastuksessa. Asiakaspalvelumme tavoitat puhelimitse numerosta 0201 558 610 (arkisin klo 9.00-17.00) tai sähköpostitse osoitteella asiakaspalvelu@eabgroup.fi.",
  "SHOW_INFO_POPUP_TITLE": "EAB Group Oyj on nyt osa Evli Oyj:tä",
  "SHOW_INFO_POPUP_CONTENT1": "EAB Verkkopalvelussa näet omistuksesi arvot 10.10.2022 saakka. Tiedot omistuksistasi siirretään My Evli -verkkopalveluun, jossa pääset muun muassa seuraamaan varallisuutesi kehitystä ja toteuttamaan toimeksiantoja arviolta 10.10.2022 alkaen.",
  "SHOW_INFO_POPUP_CONTENT2": "EAB Henkilöstörahaston verkkopalvelu pysyy ennallaan yhdistymisen jälkeen.",
  "SHOW_INFO_POPUP_CONTENT3": "Oma yhteyshenkilösi ja asiakaspalvelumme auttavat mielellään, mikäli sinulla on kysymyksiä tai haluat merkitä tai lunastaa rahastoja ennen 10.10.2022. Tavoitat asiakaspalvelumme puhelimitse numerosta 0201 558 610 (arkisin klo 9.00-17.00) tai sähköpostitse osoitteella asiakaspalvelu@eabgroup.fi.",
  "SHOW_INFO_POPUP_CONTENT_HRJ_1": "EAB on 1.10.2022 yhdistynyt Evlin kanssa. Yhdistyminen ei vaadi toimenpiteitä asiakkailtamme. Palvelemme sinua tuttuun tapaan yhdistymisen jälkeen. Verkkopalvelussa näet mm. rahasto-osuutesi, omat tietosi ja voit olla yhteydessä meihin.",
  "SHOW_INFO_POPUP_CONTENT_HRJ_2": "Asiakaspalvelumme auttaa mielellään mikäli sinulla on kysymyksiä, puh. 0201 558 660 (arkisin klo 9.00-17.00) tai jasenpalvelu@eai.fi.",
  "SHOW_INFO_POPUP_CLOSE_TEXT": "Sulje ikkuna",
  "SIERRA LEONE": "Sierra Leone",
  "SIGN": "Kirjaudu",
  "SIGN_IN": "Kirjaudu sisään",
  "SIGN_IN_HERE_USING_BANK_ACCOUNT": "Kirjaudu tästä pankkitunnuksilla",
  "SIGN_IN_USING_BANK_ACCOUNT": "Kirjaudu sisään verkkopankkitunnuksilla",
  "SERVICE_REQUIRES_STRONG_IDENTIFICATION": "Palvelun käyttö vaatii vahvan tunnistautumisen.",
  "CHOOSE_IDENTIFICATION_METHOD": "Valitse tunnistautumistapa",
  "REGISTER_BY_IDENTIFYING": "Aloita asiakkuus tunnistautumalla.",
  "LOGIN_FAILED": "Kirjautuminen epäonnistui.",
  "SIGN_OUT": "Kirjaudu ulos",
  "SINGAPORE": "Singapore",
  "SIX_MONTHS_SHORT": "6 kk",
  "SLOVAKIA": "Slovakia",
  "SLOVENIA": "Slovenia",
  "SMS": "Tekstiviesti",
  "SOCIAL_SECURITY_NUMBER": "Henkilötunnus",
  "SOLD_ASSETS": "Myyty omaisuus",
  "SOLD_HOLDINGS_AND_REPORT_FOR_WINS_AND_LOSES": "LUOVUTETTU OMAISUUS JA SELVITYS LUOVUTUSVOITON TAI -TAPPION MÄÄRÄSTÄ",
  "SOLOMON ISLANDS": "Salomonsaaret",
  "SOMALIA": "Somalia",
  "SOMETHING_WENT_WRONG_WITH_REGISTRATION": "Hei, jokin meni vikaan rekisteröinnissä.",
  "Sosiaalihuollon avopalvelut": "Sosiaalihuollon avopalvelut",
  "Sosiaalihuollon laitospalvelut": "Sosiaalihuollon laitospalvelut",
  "SOURCE_TAXES": "Lähdeverot",
  "SOURCETAXDEPOSITION": "Lähdeveron tilitys",
  "SOURCETAXWITHDRAWAL": "Lähdeveron veloitus",
  "SOUTH AFRICA": "Etelä-Afrikka",
  "SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS": "Etelä-Georgia ja Eteläiset Sandwichsaaret",
  "SPAIN": "Espanja",
  "SRI LANKA": "Sri Lanka",
  "START_BALANCE": "Alkusaldo",
  "START_DATE": "Alkupäivä",
  "STARTING_DATE": "Aloituspäivä",
  "STATUS": "Tila",
  "STOCKS": "Osakkeet",
  "STREETADDRESS": "Katuosoite",
  "STREETADDRESS_REQUIRED": "Katuosoite on pakollinen",
  "Structured": "Strukturoidut tuotteet",
  "Structured products": "Strukturoidut tuotteet",
  "Structured Products": "Strukturoidut tuotteet",
  "STRUCTURED_PRODUCTS": "Strukturoidut sijoitustuotteet",
  "SUB_CLASS": "Aliluokka",
  "SUBSCRIBE_NEWS_LETTER": "Tilaan uutiskirjeen ja ajankohtaiset tiedotteet",
  "SUBSCRIBE_INFO_LETTER": "Tilaan tietoa tuotteista, tapahtumista ja palveluista",
  "SUBSCRIBE_STOCK_EXCHANGE_RELEASES": "Tilaan pörssi- ja lehdistötiedotteet",
  "SUBSCRIBE_STOCK_EXCHANGE_ANNOUNCEMENTS": "Tilaan pörssi-ilmoitukset",
  "SUBSCRIBE_WEEKLY_LETTER": "Tilaan viikkokatsauksen",
  "SUBSCRIPTION": "Merkintä",
  "SUBSCRIPTION_FEE": "Merkintäpalkkio",
  "SUBSCRIPTION_SMALL": "merkintä",
  "SUBSCRIPTIONS": "Merkinnät",
  "SUBSCRIPTIONWITHDRAWAL": "Merkinnän veloitus",
  "SUBSTRACTIONS": "Vähennykset",
  "SUDAN": "Sudan",
  "SUM_GROSS_EUR": "Summa brutto",
  "SUM_MUST_BE_AT_LEAST_MIN": "Summan on oltava vähintään minimimerkintä.",
  "SUM_MUST_BE_POSITIVE": "Summan on oltava positiivinen luku.",
  "SUSTAINABILITY_REPORTS": "Rahastojen vastuullisuusraportit",
  "SUSTAINABILITY_REPORTS_EN": "Rahastojen vastuullisuusraportit englanniksi",
  // DONE lisätty 20.12.2021
  "TAX_RATE_MUST_BE_POSITIVE": "Veroprosentin on oltava positiivinen luku",

  "SUM_NET_EUR": "Summa netto",
  "SUM_WITH_EMARK": "SUMMA €",
  "SUMMARY": "Yhteenveto",
  "SURE_YOU_WANT_TO_CANCEL": "Oletko varma että haluat perua valitun",
  "SVALBARD AND JAN MAYEN": "Svalbard ja Jan Mayen",
  "SWAZILAND": "Swazimaa",
  "SWEDEN": "Ruotsi",
  "SWEDISH": "ruotsi",
  "SWITZERLAND": "Sveitsi",
  "SYRIAN ARAB REPUBLIC": "Syyria",
  "Sähkö-, kaasu- ja lämpöhuolto, jäähdytysliiketoiminta": "Sähkö-, kaasu- ja lämpöhuolto, jäähdytysliiketoiminta",
  "Sähkölaitteiden valmistus": "Sähkölaitteiden valmistus",
  "TAIWAN, PROVINCE OF CHINA": "Taiwan",
  "TAJIKISTAN": "Tadzikistan",
  "Talonrakentaminen": "Talonrakentaminen",
  "TANZANIA, UNITED REPUBLIC OF": "Tansania",
  "TARGET": "Kohde",
  "TASMANIA": "Tasmania",
  "ADD_TAX_COUNTRY": "Lisää verotuksellinen asuinvaltio",
  "ADD_TAX_COUNTRY_BUTTON": "Lisää verotusmaa",
  "TAX_COUNTRY": "Verotusmaa",
  "TAX_COUNTRY_IS_REQUIRED": "Verotusmaa on pakollinen",
  "GET_TO_KNOW_INFORMATION": "Tuntemistiedot",
  "UPDATE_GET_TO_KNOW_INFORMATION": "Tuntemistietojen päivittäminen",
  "TAX_LIABILITY": "Verovelvollisuus",
  "TAX_LIABILITY_SETTINGS": "Verotusmaatietojen päivittäminen",
  "TAX_LIABILITY_SETTINGS_UPDATE": "Verotustietojen päivittäminen",
  "TAX_LIABILITY_CHANGED": "Verovelvollisuustiedot muutettu",
  "TAX_NUMBER_OR_ID": "Veronumero / -tunniste",
  "TAX_REPORT": "Veroraportti",
  "TAXPAYER_IN_UNITED_STATES_ADDITIONAL_REQUIREMENT": "Henkilön tulee toimittaa myös soveltuva IRD w-8 / w-9 lomake.",
  "TAXPAYER_IN_UNITED_STATES_MUST_PROVIDE_A_TAX_NUMBER_OR_ID": "Yhdysvalloissa verovelvollisen tulee antaa veronumero tai -tunniste.",
  "Tekstiilien valmistus": "Tekstiilien valmistus",
  "Televiestintä": "Televiestintä",
  "TEN_LARGEST_INVESTMENTS": "10 suurinta sijoitusta",
  "TERMS": "Ehdot",
  "TERMS_FOR_MONTHLY_DEPOSIT_PART1": "Käyttämällä sinulle annettua viitenumeroa maksussasi EAB Group Oyj:n tilille FI94 3131 1000 2982 44 vahvistat, että olet tutustunut rahastoa",
  "TERMS_FOR_MONTHLY_DEPOSIT_PART2": "koskevaan yksinkertaistettuun esitteeseen ja sääntöihin sekä EAB Group Oyj:n välitys- ja säilytysehtoihin, ja hyväksyt ne.",
  "Terveyspalvelut": "Terveyspalvelut",
  "THAILAND": "Thaimaa",
  "THE_FIELD_IS_REQUIRED": "Kenttä on pakollinen",
  "THE_FUND_SEARCH": "rahastohakua",
  "THE_FUNDS": "Varat",
  "THE_NEW_REDEMPTION": "Myy rahastoa",
  "THE_NEW_SUBSCRIPTION": "Osta rahastoa",
  "THE_ORDER": "toimeksiantoa",
  "THE_RECLAIM": "lunastus",
  "THE_VALUE_IS_NOT_A_DATE": "Arvo ei ole päivämäärä",
  "THERE_ARE_VALIDATION_ERRORS": "Syötetyt tiedot ovat puutteelliset tai niissä on virheitä. Tarkista tiedot.",
  "THERE_WERE_ERRORS_WHEN_SENDING_THE_INFORMATION": "Tietoja lähetettäessä tapahtui virhe.",
  "THREE_MONTHS_SHORT": "3 kk",
  "TWELVE_MONTHS_SHORT": "12 kk",
  "Tieteellinen tutkimus ja kehittäminen": "Tieteellinen tutkimus ja kehittäminen",
  "Tietokoneiden sekä elektronisten ja optisten tuotteiden valmistus": "Tietokoneiden sekä elektronisten ja optisten tuotteiden valmistus",
  "Tietokoneiden, henkilökohtaisten ja kotitaloustavaroiden korjaus": "Tietokoneiden, henkilökohtaisten ja kotitaloustavaroiden korjaus",
  "Tietopalvelutoiminta": "Tietopalvelutoiminta",
  "TIMOR-LESTE": "Itä-Timor",
  "TO_FUND": "rahastoon",
  "TODAY": "Tänään",
  "TOGO": "Togo",
  "Toimiala tuntematon": "Toimiala tuntematon",
  "TOKELAU": "Tokelau",
  "TONGA": "Tonga",
  "TOO_LONG_NAME": "Antamasi nimi on liian pitkä.",
  "ONLY_LETTERS_NUMBERS_WHITESPACE": "Nimessä voi olla vain kirjaimia, numeroita ja välilyöntejä",
  "TOO_MUCH_CLAIMS": "Osuuksia ei voi lunastaa enemmän kuin on omistuksessa",
  "TOP_TEN_MOST_PROFITABLE_FUNDS_ONE_MONTH": "Top 10 tuottoisimmat rahastot (yksi kuukausi)",
  "TOPICAL": "Ajankohtaista",
  "TOTAL": "Yhteensä",
  "TOTAL_ALL": "Yhteensä kaikki",
  "TOTAL_WEALTH": "Kokonaisvarallisuus",
  "TOTAL_WEALTH_IS_REQUIRED": "Kokonaisvarallisuus on pakollinen tieto.",
  "TOTAL_WEALTH_MUST_BE_A_NUMBER": "Kokonaisvarallisuuden on oltava numero.",
  "TRADING_ACTIVITY": "Kaupankäynnin aktiivisuus",
  "TRADING_ACTIVITY_IS_REQUIRED": "Kaupankäynnin aktiivisuus on pakollinen tieto.",
  "TRANSACTION": "Tapahtuma",
  "TRANSACTIONS": "Tapahtumat",
  "TRANSACTION_DATE": "Tapahtuman päivämäärä",
  "TRANSACTIONS_FOUND_TOTAL": "Tapahtumia yhteensä",
  "TRANSACTION_IS_NOT_READY": "Toimeksiannon syöttäminen kesken",
  "TRANSACTION_NUMBER": "Tapahtumanumero",
  "TRANSACTION_TYPE": "Tapahtumatyyppi",
  "TRANSACTION_TYPES": "Tapahtumatyypit",
  "TRANSACTIONS_ARCHIEVENO": "Tapahtuman arkistointinumero",
  "TRANSFER": "Siirto",
  "Transfer": "Siirto",
  "TRINIDAD AND TOBAGO": "Trinidad ja Tobago",
  "TRUE_BENEFICIARIES": "Tosiasialliset edunsaajat",
  "Tukkukauppa (pl. moottoriajoneuvojen ja moottoripyörien kauppa)": "Tukkukauppa (pl. moottoriajoneuvojen ja moottoripyörien kauppa)",
  "TUNISIA": "Tunisia",
  "Tupakkatuotteiden valmistus": "Tupakkatuotteiden valmistus",
  "TUPASLOGIN": "Verkkokirjautumiseen",
  "TUPASLOGININFO": "Kirjaudu verkkotunnistautumisella",
  "TUPASLOGIN_NO_USER": "Verkkotunnistautumisella ei löytynyt käyttäjää.",
  "TURKEY": "Turkki",
  "TURKMENISTAN": "Turkmenistan",
  "TURKS AND CAICOS ISLANDS": "Turks- ja Caicossaaret",
  "TURNOVER": "Liikevaihto",
  "Turvallisuus-, vartiointi- ja etsiväpalvelut": "Turvallisuus-, vartiointi- ja etsiväpalvelut",
  "TUVALU": "Tuvalu",
  "TYPE": "Tyyppi",
  "TYPE_FOR_TRANSACTION": "Tapahtuman tyyppi",
  "Työllistämistoiminta": "Työllistämistoiminta",
  "UGANDA": "Uganda",
  "UKRAINE": "Ukraina",
  "UNFORTUNATELY_WE_COULD_NOT_FIND_WHAT_YOU_ARE_LOOKING_FOR": "Valitettavasti etsimääsi ei löytynyt.",
  "UNFINISHED_REDEMPTIONS_IN_PORTFOLIO_PART1": "Salkussa on keskeneräisiä lunastuksia, joista arvioidaan tuloutuvan käteistä",
  "UNFINISHED_REDEMPTIONS_IN_PORTFOLIO_PART2": ", summa tarkentuu lunastuksen vahvistuessa. Mikäli lunastuksista tuleva käteinen riittää merkintään, toteutetaan merkinnät näillä varoilla.",
  "Unknown": "Muut",
  "UNITED ARAB EMIRATES": "Arabiemiirikunnat",
  "UNITED KINGDOM": "Iso-Britannia",
  "UNITED STATES": "Yhdysvallat",
  "UNITED STATES MINOR OUTLYING ISLANDS": "Yhdysvaltain Tyynenmeren erillissaaret",
  "Unlisted": "Listaamattomat",
  "UPDATE_SUCCESS_MESSAGE": "Tiedot päivitettiin onnistuneesti!",
  "UPDATE_FAIL_MESSAGE": "Tietojen päivittäminen epäonnistui.",
  "ALL_BUT_RESIDENCYCOUNTRY_UPDATED": "Yhteystiedot päivitettiin onnistuneesti. Asuinmaan päivitys epäonnistui.",
  "UPDATE_TAX_INFORMATION": "Huomasimme, että verotusmaatietosi ovat päivittämättä. Päivitäthän ne tässä jatkaaksesi palvelun käyttöä.",
  "UPDATE_TAX_AND_WEALTH_INFORMATION": "Huomasimme, että tiedot sijoitusvarallisuudestasi ja verotusmaistasi ovat päivittämättä. Päivitäthän ne tässä jatkaaksesi palvelun käyttöä.",
  "Urheilutoiminta sekä huvi- ja virkistyspalvelut": "Urheilutoiminta sekä huvi- ja virkistyspalvelut",
  "URUGUAY": "Uruguay",
  "USERNAME": "Käyttäjätunnus",
  "USERNAME2": "Käyttäjätunnus",
  "USERNAME_AND_PASSWORD": "Käyttäjätunnus ja salasana",
  "USERPROFILE_SETTINGS": "Käyttäjäprofiiliasetukset",
  "USERPROFILE": "Käyttäjäprofiili",
  "UZBEKISTAN": "Uzbekistan",
  "Vaatteiden valmistus": "Vaatteiden valmistus",
  "Vakuutus-, jälleenvakuutus- ja eläkevakuutustoiminta": "Vakuutus-, jälleenvakuutus- ja eläkevakuutustoiminta",
  "WALLIS AND FUTUNA": "Wallis ja Futuna",
  "VALUE": "Arvo",
  "VALUE_CHART": "Arvonkehitys",
  "VALUE_DATE": "Arvopäivä",
  "VALUE_TOTAL": "Summa",
  "WANT_TO_GET_BRIEF_EMAIL": "Haluan saada Elite Varainhoidon asiakastiedotteen sähköpostiini",
  "VANUATU": "Vanuatu",
  "Varastointi ja liikennettä palveleva toiminta": "Varastointi ja liikennettä palveleva toiminta",
  "WARRANTS": "Warrantit",
  "Veden otto, puhdistus ja jakelu": "Veden otto, puhdistus ja jakelu",
  "WEEKLY": "Viikoittain",
  "WEIGHT": "Paino",
  "WELCOME_TO_CUSTOMER": "Tervetuloa asiakkaaksi, sopimukset lähetetään sinulle lähipäivinä",
  "VENEZUELA": "Venezuela",
  "VERIFY_NEW_PASSWORD": "Vahvista uusi salasana",
  "VERIFY_ORDER_BY_GIVING_PASSWORD": "Vahvista toimeksianto syöttämällä salasanasi alla olevaan kenttään",
  "VERIFY_ORDER_BY_ONLINE_IDENTIFICATION": "Vahvista toimeksianto verkkotunnistautumisella",
  "VERIFY_PASSWORD_IS_REQUIRED": "Uusi salasana on kirjoitettava toiseen kertaan.",
  "Vesiliikenne": "Vesiliikenne",
  "WESTERN SAHARA": "Länsi-Sahara",
  "WHAT?": "Mikä?",
  "WHAT_IS_IT_ABOUT": "Mistä on kyse?",
  "WHAT_ARE_THE_BENEFITS": "Mitä hyötyä palkkion rahastoinnista on?",
  "WHERE?": "Missä?",
  "WHICH?": "Mitkä?",
  "WHICH_ONE?": "Mikä?",
  "WHOLE_HISTORY": "Koko historia",
  "WHOLE_WEALTH": "Koko varallisuus",
  "Viemäri- ja jätevesihuolto": "Viemäri- ja jätevesihuolto",
  "VIETNAM": "Vietnam",
  "VIEW_DESCRIPTION": "Salkunhoitajan katsaus",
  "VIRGIN ISLANDS, BRITISH": "Brittiläiset Neitsytsaaret",
  "VIRGIN ISLANDS, U.S.": "Yhdysvaltain Neitsytsaaret",
  "WIN_OR_LOSE": "Voitto tai tappio",
  "WINS_TOGETHER": "Voitot yhteensä",
  "WITH_ONLINE_BANK_ACCOUNTS": "Verkkotunnistautumisella",
  "WITH_SUM": "summalla",
  "WITH_YOUR_USERNAME_AND_NEW_PASSWORD_YOU_CAN_LOGIN": "Käyttäjätunnuksellasi ja salasanallasi voit nyt kirjautua järjestelmään.",
  "WITHDRAW_BACK": "Palaa",
  "WITHDRAW_PORTFOLIO_CASHBALANCE": "Salkun saldo / Nostettavissa",
  "WITHDRAW_SAVE": "Nosta",
  "WITHDRAW_SELECT_PORTFOLIO_INFOTEXT": "Valitse ensin salkku, josta haluat tehdä noston.",
  "WITHDRAW_SUM_ISMAXIMIUM": "Et voi nostaa varoja enemmän kuin salkussa on olemassa varoja.",
  "WITHDRAW_SUM_ISMINIMIUM": "Nostettava summa on oltava positiivinen luku.",
  "WITHDRAW_SUM_ISNUMERIC": "Nostettava summa on oltava numeerinen.",
  "WITHDRAW_SUM_REQUIRED": "Nostettava summa on syötettävä.",
  "WITHDRAW_SUM_TITLE": "Syötä nostettava summa",
  "WITHDRAWAL": "Nosto",
  "Withdrawal": "Nosto",
  "WITHDRAWAL_FROM_PORTFOLIO": "nostamaan salkusta",
  "WITHDRAWAL_SMALL": "nosto",
  "WITHDRAWALS": "Nostot",
  "WITHDRAWALS_DURING_PERIOD": "Nostot kauden aikana",
  "WORK_INCOME": "Ansiotulot",
  "WORK_INCOME_MIFID": "Työstä saatava ansiotulo",
  "WORKDAYS": "Arkisin",
  "World": "Maailma",
  "WRITE_FUNDS_NAME": "Kirjoita rahaston nimi",
  "WRONG_PASSWORD_OR_USERNAME": "Väärä salasana tai käyttäjätunnus",
  "WRONG_CONFIRMATION_CODE": "Vahvistustunnus ei täsmää.",
  "Vuokraus- ja leasingtoiminta": "Vuokraus- ja leasingtoiminta",
  "Vähittäiskauppa (pl. moottoriajoneuvojen ja moottoripyörien kauppa": "Vähittäiskauppa (pl. moottoriajoneuvojen ja moottoripyörien kauppa",
  "X-RAYED_PORTFOLIO_HOLDINGS": "Salkun omistukset läpivalaistuna",
  "YEAR": "vuosi",
  "YEARS": "vuotta",
  "YEMEN": "Jemen",
  "YES": "Kyllä",
  "Yleinen": "Yleinen",
  "ylempi korkeakouluaste": "Ylempi korkeakouluaste",
  "YOU_ARE_CREATING": "Olet luomassa",
  "YOU_CANNOT_BUY_ACCORDING_TO_YOUR_CONTRACT": "Sopimuksesi mukaan salkusta ei voi tehdä ostoja verkkopalvelussa. ",
  "YOU_CANNOT_SELL_ACCORDING_TO_YOUR_CONTRACT": "Sopimuksesi mukaan salkusta ei voi tehdä myyntejä verkkopalvelussa. ",
  "YOU_CANNOT_WITHDRAW_ACCORDING_TO_YOUR_CONTRACT": "Sopimuksesi mukaan salkusta ei voi tehdä nostoja verkkopalvelussa. ",
  "YOU_CANNOT_DEPOSIT_ACCORDING_TO_YOUR_CONTRACT": "Sopimuksesi mukaan salkuun ei voi tehdä talletuksia verkkopalvelussa. ",
  "YOU_CAN_NOW_LOGIN_TO_SYSTEM": "Asiakasnumerolla ja salasanallasi voit nyt kirjautua järjestelmään.",
  "YOU_CAN_NOW_CLOSE_THIS_WINDOW": "Voit nyt sulkea tämän ikkunan.",
  "YOU_CAN_USE_ELITES_ANALYZES": "Saat käyttöösi kattavan rahastovalikoiman analyyseineen, yli 1000 rahastoa",
  "YOU_CAN_USE_ELITES_PERONAL_MANAGEMENT": "Halutessasi voit yhdistää rahastosalkkuusi myös yksilöllisen varainhoidon",
  "YOUR_ACCOUNT_NUMBER_IS": "Asiakasnumerosi on",
  "YOUR_CURRENT_PASSWORD_IS_WRONG": "Nykyinen salasana on väärin",
  "YOUR_PASSWORD_IS_CHANGED": "Salasanasi on vaihdettu.",
  "YOUR_USERACCOUNT_IS": "Käyttäjätunnuksesi on",
  "ZAMBIA": "Sambia",
  "ZIMBABWE": "Zimbabwe",
  "ZIP_CODE": "Postinumero",
  "ZIP_CODE_REQUIRED": "Postinumero on pakollinen",
  "ÅLAND": "Ahvenanmaa",

  "HOW_TO_SIGN_IN": "Näin kirjaudut verkkopalveluun",
  "CHECK_HOW_TO_SIGN_IN": "Katso, kuinka kirjaudut verkkopalveluun ensimmäistä kertaa ja miten toimit, jos olet unohtanut käyttäjätunnuksesi tai salasanasi.",
  "SEE_INSTRUCTIONS": "Katso ohjeet",
  // DONE lisätty 25.11.2021
  "ATTACHMENT_INSTRUCTION": "Vedä liitetiedosto tähän tai lisää liitetiedosto omista tiedostoistasi. Liitteen suurin sallittu koko on 20 Mb.",
  "ADD_ATTACHMENT": "Lisää tiedosto",
  "REMOVE_ATTACHMENT": "Poista tiedosto",

  // kirjautumissivu
  "HOW_TO_SIGN_IN_AND_VIDEO": "Näin kirjaudut sisään, katso video",
  "WELCOME_TO_EAB_WEB": "Tervetuloa Elite Alfred Bergin verkkopalveluun.",
  "SIGN_IN_TO_SEE_OWN_INVESTMENTS": "Kirjauduttuasi sisään voit tarkastella omia sijoituksiasi.",
  "EAB_ONLINE_SERVICE": "EAB Verkkopalvelu",
  "EAB_PERSONNEL_FUNDS": "EAB Henkilöstörahasto",
  "TEXTMESSAGE_HAS_BEEN_SENT": "Sinulle on lähetetty  tekstiviestinä vahvistustunnus antamaasi puhelinnumeroon.",
  "GIVE_CONFIRMATIONCODE": "Syötä saamasi tunnus tähän.",
  "NO_MESSAGE_RECEIVED": "Etkö saanut viestiä?",
  "CONTACT_TO": "Ota yhteys",
  "OUR_CUSTOMER_SERVICE": "asiakaspalveluumme",
  "ANY_QUESTIONS": "Heräsikö kysyttävää?",
  "CUSTOMER_SERVICE_INSTRUCTION": "Asiakaspalvelumme auttaa sinua mielellään kaikissa verkkopalveluun liittyvissä kysymyksissä. Ethän epäröi ottaa meihin yhteyttä!",
  "CUSTOMER_SERVICE_SERVICETIME": "9.00 - 17.00",
  "CUSTOMER_SERVICE_PHONENUMBER": "0201 558 610",
  "SIGN_IN_TO_EAB_WEB": "Kirjaudu verkkopalveluun",
  "SIGN_IN_TO_PERSONNEL_FUND": "Kirjaudu henkilöstörahastoon",
  "SIGN_IN_USING_YOUR_CREDENTIALS": "Kirjaudu sisään omilla tunnuksillasi.",
  "SIGN_IN_USING_EXISTING_CREDENTIALS": "Kirjaudu olemassa olevilla tunnuksilla",

  // Henkilöstopalveluiden kirjautuminen
  "PERSONNEL_FUNDS_WELCOME": "Tervetuloa henkilöstö- ja palkkiorahaston verkkopalveluun. Verkkopalvelussa näet rahastoon ja omaan jäsenyyteesi liittyvät tiedot.",
  "IDENTIFY_WITH_BANK_ACCOUNT_ID": "Tunnistaudu",

  "IF_YOU_HAVE_FORGOTTEN_ID_OR_PASSWORD": "Käyttäjätunnus voidaan hakea ja salasana resetoida verkkotunnistautumisen avulla.",
  "FORGOT_ACCOUNT": "Unohtuneet tunnukset",
  "USERS_FOUND_WITH_BANK_ACCOUNT": "Verkkotunnistautumisen avulla löytyi",
  "USERS": "käyttäjää",
  "USER": "käyttäjä",
  "CHOOSE_USER": "Valitse käyttäjä",
  "GIVE_NEW_PASSWORD": "Syötä alla oleviin kenttiin uusi salasanasi.",
  "PASSWORD_CHANGED_OK": "Salasana vaihdettu",
  "PASSWORD_CHANGED_FAIL": "Salasanaa ei vaihdettu",
  "PASSWORD_FOR_ID": "Salasana käyttäjälle",
  "HAS_BEEN_CHANGED": "on nyt vaihdettu onnistuneesti.",
  "PASSWORD_WAS_NOT_CHANGED": "Salasanaa ei onnistuttu vaihtamaan käyttäjälle",
  "GO_TO_LOGIN": "Siirry kirjautumiseen",

  //Navigaatio
  "GO_TO_WEBPAGE": "Siirry verkkosivuille",
  "PERSONNEL_FUNDS": "Henkilöstö- ja palkkiorahasto",
  "ASSETS_AND_INVESTMENTS": "Varainhoito ja sijoitukset",
  "SEB_LIFE_SERVICE": "SEB Life Verkkopalvelu",
  "DETAILS_AND_SETTINGS": "Omat tiedot ja asetukset",
  "CONTACT": "Ota yhteyttä",
  "HOME": "Etusivu",
  "OWN_PORTFOLIOS": "Omat salkkuni",
  // DONE lisätty ???
  "LOADING": "Ladataan",

  // Asiakkaaksitulon käännökset
  "I_DO_NOT_WANT_MY_ADDRESS_TO_BE_UPDATED": "En halua osoitettani päivitettävän Väestörekisterikeskuksen tietojen perusteella. Ilmoitan mahdollisen osoitteenmuutoksen itse Elite Alfred Bergille.",
  "COUNTRY_OF_BIRTH": "Synnyinmaa",
  "COUNTRY_OF_BIRTH_AND_NATIONALITY": "Synnyinmaa ja kansalaisuus",
  "ADD_NEW": "Lisää uusi",
  "SELECT_NATIONALITY": "Valitse kansalaisuus",
  "BY_PROVIDING_MY_EMAIL_ADDRESS": "Antamalla sähköpostiosoitteen hyväksyn ",
  "ELECTRONIC_EXCHANGE": "sähköisen tietojenvaihdon.",
  "ELITE_CAN_SEND_STATUTORY_BULLETINS": "Elite Alfred Berg saa lähettää lakisääteiset tiedotteet ja muut sopimusmateriaalit sähköisesti. Elite Alfred Bergillä on oikeus tiedottaa sopimusehtojen ja palveluhinnaston muutoksista sähköisesti joka asettamalla ne Asiakkaan saataville sähköisessä palvelussa, yhtiön internetsivuilla ja/tai lähettämällä ne Asiakkaan ilmoittamaan sähköpostiosoitteeseen. Asiakas on tietoinen ja hyväksyy, että sähköisten viestintävälineiden, kuten sähköpostin ja telefaksin, käyttämiseen viestintävälineenä liittyy erityisiä riskejä muun muassa sen vuoksi, että viesti ei mahdollisesti saavu perille, viesti saattaa joutua ulkopuolisen tietoon tai ulkopuolinen saattaa muunnella viestin sisältöä. Elite Alfred Bergillä on oikeus luottaa sähköisten viestintävälineiden välityksellä saamiensa viestien aitouteen ja oikeellisuuteen.",
  "I_WISH_TO_ORDER_MONTHLY_NEWSLETTER": "Haluan tilata Elite Alfred Bergin kuukausikirjeen sähköpostiini.",

  "USUALLY_THE_GENERAL_TAX_LIABILITY": "Luonnollisen henkilön yleinen verovelvollisuus (verotusmaa) määräytyy yleensä pääasiallisen asumisen tai vastaavan oleskelun perusteella. Jos olet epävarma verotusstatuksestanne, pyydämme olemaan yhteydessä veroasiantuntijaan. Asiakkaan tulee omatoimisesti ilmoittaa kaikki verotusmaansa Elite Alfred Bergille.",
  "LIABILITY_TO_PAY_TAXES_IN_FINLAND": "Suomen verovelvollisuus",
  "I_CONFIRM_ONLY_FINLAND": "Vahvistan, että olen verotuksellisesti ainoastaan Suomessa asuva.",
  "I_CONFIRM_ALSO_OTHER_THAN_FINLAND": "Vahvistan, että olen verotuksellisesti asuva (myös) muissa valtioissa kuin Suomessa.",
  "US_NATIONALITY": "Verovelvollisuus Yhdysvalloissa",
  "I_CONFIRM_NOT_US": "Vahvistan, että en ole verotuksellisesti Yhdysvalloissa asuva.",
  "I_CONFIRM_US": "Vahvistan, että olen verotuksellisesti Yhdysvalloissa asuva.",
  "IF_YOU_ARE_A_US_CITIZEN": "HUOM! Jos olet Yhdysvaltojen kansalainen, olet verotuksellisesti asuva Yhdysvalloissa. Jos olet syntynyt Yhdysvalloissa, sinua pidetään yleisesti Yhdysvaltojen kansalaisena, ellet ole luopunut kansalaisuudestasi.",
  "COUNTRIES_OF_TAX_RESIDENCY": "Verotukselliset asuinvaltiot",
  "PLEASE_PROVIDE_INFORMATION_ON_ALL_THE_COUNTRIES": "Anna tiedot kaikista maista, joissa olet verotuksellisesti asuva. Jos maa käyttää verotunnistetta, lisää myös verotunniste. Jos sinulla on kysymyksiä verotuksellisen asuinvaltion määrittämisestä, ota yhteyttä veroasiantuntijaan tai paikallisiin veroviranomaisiin.",
  "THE_TAX_IDENTIFICATION_NUMBER_USA": "Yhdysvaltojen verotunniste (TIN tai vastaava)",
  "I_AM_SUBJECT_TO_TAXATION_IN_FINLAND": "Olen verovelvollinen Suomeen.",
  "FINNISH_TAX_IDENTIFICATUON_NUMBER": "Suomen verotunniste (henkilötunnus)",
  "TAX_IDENTIFICATION_NUMBER": "Verotunniste (TIN tai vastaava)",
  "TIN_IS_REQUIRED": "TIN on pakollinen tieto.",

  "INVESTMENT_COMPETENCE": "Sijoitusosaaminen",
  "OPERATING_IN_THE_FINANCIAL_SECTOR": "Rahoitusalalla toimiminen",
  "I_OPERATE_IN_THE_FINANCIAL_SECTOR": "Toimin / olen toiminut rahoitusalalla tehtävissä, joissa vaaditaan kokemusta / tietämystä sijoitustoiminnasta.",
  "NUMBER_OF_YEARS": "Vuosien lukumäärä",
  "ASSESSMENT_OF_THE_AVERAGE": "Arvio sijoituksen keskimääräisestä koosta",
  "IN_USE": "Käytössä",
  "FAMILIAR_WITH": "Tunnen",
  "NOT_FAMILIAR_WITH": "En tunne",
  "KNOWLEGDE_OF_INVESTMENT_SERVICES": "Sijoituspalveluiden tuntemus",
  "LISTED_SHARES": "Listatut osakkeet",
  "UNLISTED_SHARES": "Listaamattomat osakkeet",
  "STOCK_FUNDS": "Osakerahastot",
  "INTEREST_FUNDS": "Korkorahastot",
  "HEDGE_FUNDS": "Hedge-rahastot",
  "REAL_ESTATE_FUNDS": "Kiinteistörahastot",
  "INSURANCE_INVESTMENT": "Vakuutussijoittaminen (sijoitus-, eläke- ja säästövakuutukset)",

  "POLITICALLY_INFLUENTIAL_PERSON": "Poliittisesti vaikutusvaltainen henkilö",
  "ELITE_MUST_BE_INFORMED_IF_YOU_OCCUPY_CENTRAL_PUBLIC_POSITION": "EAB Varainhoito Oy:n tulee tietää, oletko tai oletko joskus ollut merkittävässä julkisessa tehtävässä valtiolla tai kansainvälisen organisaation johdossa. Sama pätee, jos perheenjäsenesi tai läheinen yhtiökumppanisi on tai on ollut vastaavassa tehtävässä.",
  "CENTRAL_POSITIONS_INCLUDE": "Merkittäviä tehtäviä ovat esimerkiksi",
  "HEAD_OF_STATE": "valtionpäämies, hallituksen päämies, ministeri tai vara- ja apulaisministeri",
  "MEMBER_OF_PARLIAMENT": "kansanedustaja",
  "MEMBER_OF_THE_SUPREME_COURT": "korkeimman oikeuden, perustuslakituomioistuimen tai muun sellaisen korkean tason oikeuselimen jäsen, jonka päätöksiin ei voida hakea muutosta, poikkeustapauksia lukuun ottamatta",
  "MEMBER_OF_THE_COURT_OF_AUDITORS": "tilintarkastustuomioistuimen tai keskuspankin johtokunnan jäsen",
  "AMBASSADOR": "suurlähettiläs, asiainhoitaja sekä puolustusvoimien korkea-arvoinen upseeri",
  "MEMBER_OF_AN_ADMINISTRATIVE": "valtion omistaman yrityksen hallinto-, johto- tai valvontaelimen jäsen",
  "MANAGERIAL_POSITION": "tehtävä kansainvälisen organisaation johdossa.",
  "DO_YOU_HAVE_ANYTHING_TO_REPORT": "Onko sinulla poliittiseen vaikutusvaltaan liittyvää ilmoitettavaa?",
  "SOMETNING_TO_REPORT": "Minulla on ilmoitettavaa",
  "NOTHING_TO_REPORT": "Ei ilmoitettavaa",
  "IF_YOU_HAVE_SOMETHING_TO_REPORT": "Jos sinulla on ilmoitettavaa, täytä poliittinen vaikutusvaltaselvitys ja toimita se Elite Alfred Bergin asiakaspalveluun.",
  "REPORT_ON_POLITICAL_INFLUENCE": "Poliittinen vaikutusvaltaselvitys",

  "WITH_MY_SIGNATURE_I_CONFIRM": "Vahvistan allekirjoituksellani edellä antamani tiedot oikeiksi ja valtuutan Elite Alfred Bergin tarkistamaan henkilö- ja luottotietoni sekä pankkitilitietojeni oikeellisuuden, ja allekirjoitan sopimuksen.",
  "THE_AGREEMENT_ENTERS_INTO_FORCE": "Sopimus tulee voimaan, kun Elite Alfred Berg on hyväksynyt asiakkuuden. Elite Alfred Bergillä on oikeus kieltäytyä asiakassuhteesta, jos asiakas ei täytä Elite Alfred Bergin tai lain asettamia vaatimuksia asiakassuhteen muodostamiselle.",

  "WELCOME_TO_ELITE": "Tervetuloa Elite Alfred Bergin asiakkaaksi!",
  "YOUR_CLIENT_RELATIONSHIP_HAS_SUCCESSFULLY_BEGUN": "Asiakkuutesi on nyt avattu onnistuneesti!",
  "YOUR_USERNAME_IS_PRESENTED_BELOW": "Alla näet käyttäjätunnuksesi. Valitse salasana, joka on vähintään 6 merkkiä pitkä. Salasanaa käytetään Elite Alfred Bergin verkkopalveluun kirjauduttaessa.",
  "YOUR_USERNAME_IS_PRESENTED_BELOW1": "Alla näet käyttäjätunnuksesi. Laita se muistiin.",
  "YOUR_USERNAME_IS_PRESENTED_BELOW2": "Valitse salasana, joka on vähintään 6 merkkiä pitkä. Salasanaa käytetään Elite Alfred Bergin verkkopalveluun kirjauduttaessa.",
  "YOUR_CLIENT_RELATIONSHIP_IS_WAITING": "Asiakkuutesi odottaa vahvistusta",
  "WE_WILL_PROCESS_THE_APPLICATION": "Kiitos asiakashakemuksestasi. Käsittelemme sen mahdollisimman pian ja otamme yhteyttä, kun asiakkuutesi on vahvistettu.",
  "YOUR_PASSWORD_HAS_BEEN_SAVED": "Salasanasi on tallennettu",
  "NOW_YOU_CAN_LOG": "Voit nyt kirjautua Elite Alfred Bergin verkkopalveluun käyttäjätunnuksellasi ja salasanallasi.",
  "CLICK_THIS_TO_PROCEED_TO_THE_ELITE_ONLINE_SERVICE": "Siirry Elite Alfred Bergin verkkopalveluun tästä.",
  "SAVING_PASSWORD": "Tallennetaan salasanaa",
  "CUSTOMER_ALREADY_EXISTS_TITLE": "Asiakkuutenne on jo avattu",
  "CUSTOMER_ALREADY_EXISTS_DESC": "Teille on jo avattu asiakkuus Elite Alfred Bergin verkkopalveluun.",

  // pankkiyhtetstieto
  "ADD_NEW_BANK_ACCOUNT": "Lisää uusi pankkiyhteystieto",
  "ADD_NEW_BANK_ACCOUNT_THAT_CAN_BE_USED_TO_WITHDRAW_MONEY_FROM_PORTFOLIO": "Lisää uusi pankkitilinumero, jota voimme käyttää rahojen nostoon salkkutililtä",
  "ADD_INFORMATION": "Lisää yhteystieto",
  "BANK_ACCOUNT_NUMBER_MODEL": "FI12 3456 7890 1234 56",
  "BANK_ACCOUNT_SAVE_SUCCESS_MESSAGE": "pankki tiedot tallennettiin onnistuneesti",

  "PROMOTED_FUND": "Rahastoesittely",
  "NEWS": "Uutiset",
  "GO_TO_FRONTPAGE": "Siirry etusivulle",

  // Käännökset HRJ:n verotuksellinen asuinvaltio -kenttään
  "AFGANISTAN": "Afganistan",
  "AHVENANMAA": "Ahvenanmaa",
  "ALANKOMAAT": "Alankomaat",
  "ALANKOMAIDEN ANTILLIT": "Alankomaiden Antillit",
  "ANTARKTIS": "Antarktis",
  "ANTIGUA JA BARBUDA": "Antigua ja Barbuda",
  "ARABIEMIIRIKUNNAT": "Arabiemiirikunnat",
  "AZERBAIDZAN": "Azerbaidzan",
  "BELGIA": "Belgia",
  "BOSNIA JA HERTSEGOVINA": "Bosnia ja Hertsegovina",
  "BOUVETNSAARI": "Bouvetnsaari",
  "BRASILIA": "Brasilia",
  "BRITTILÄINEN INTIAN VALTAMEREN ALUE": "Brittiläinen Intian valtameren alue",
  "BRITTILÄISET NEITSYTSAARET": "Brittiläiset Neitsytsaaret",
  "CAYMANSAARET": "Caymansaaret",
  "DOMINIKAANINEN TASAVALTA": "Dominikaaninen tasavalta",
  "EGYPTI": "Egypti",
  "ESPANJA": "Espanja",
  "ETELÄ-AFRIKKA": "Etelä-Afrikka",
  "ETELÄ-GEORGIA JA ETELÄISET SANDWICHSAARET": "Etelä-Georgia ja Eteläiset Sandwichsaaret",
  "ETELÄ-KOREA": "Etelä-Korea",
  "FALKLANDINSAARET": "Falklandinsaaret",
  "FÄRSAARET": "Färsaaret",
  "FIDZI": "Fidzi",
  "FILIPPIINIT": "Filippiinit",
  "GRÖNLANTI": "Grönlanti",
  "HEARD- JA MCDONALDSAARET": "Heard- ja McDonaldsaaret",
  "INTIA": "Intia",
  "IRAK": "Irak",
  "IRAN": "Iran",
  "IRLANTI": "Irlanti",
  "ISLANTI": "Islanti",
  "ISO-BRITANNIA": "Iso-Britannia",
  "ITALIA": "Italia",
  "ITÄ-TIMOR": "Itä-Timor",
  "ITÄVALTA": "Itävalta",
  "JAMAIKA": "Jamaika",
  "JAPANI": "Japani",
  "JORDANIA": "Jordania",
  "JOULUSAARI": "Joulusaari",
  "KAMERUN": "Kamerun",
  "KANADA": "Kanada",
  "KAP VERDE": "Kap Verde",
  "KAZAKSTAN": "Kazakstan",
  "KENIA": "Kenia",
  "KIINA": "Kiina",
  "KIRGISIA": "Kirgisia",
  "KOLUMBIA": "Kolumbia",
  "KOOKOSSAARET": "Kookossaaret",
  "KREIKKA": "Kreikka",
  "KROATIA": "Kroatia",
  "KUUBA": "Kuuba",
  "KYPROS": "Kypros",
  "LIBANON": "Libanon",
  "LIETTUA": "Liettua",
  "LUXEMBURG": "Luxemburg",
  "MAKEDONIA": "Makedonia",
  "MALEDIIVIT": "Malediivit",
  "MALESIA": "Malesia",
  "MANSAARI": "Mansaari",
  "MAROKKO": "Marokko",
  "MEKSIKO": "Meksiko",
  "MIKRONESIA": "Mikronesia",
  "NORJA": "Norja",
  "PALESTIINA": "Palestiina",
  "POHJOIS-MARIAANIT": "Pohjois-Mariaanit",
  "PORTUGALI": "Portugali",
  "PUOLA": "Puola",
  "RANSKA": "Ranska",
  "RANSKAN ETELÄISET ALUEET": "Ranskan eteläiset alueet",
  "RUOTSI": "Ruotsi",
  "SAINT BARTHELÉMY": "Saint Barthelémy",
  "SAINT KITTS JA NEVIS": "Saint Kitts ja Nevis",
  "SAINT VINCENT JA GRENADIINIT": "Saint Vincent ja Grenadiinit",
  "SAINT-PIERRE JA MIQUELON": "Saint-Pierre ja Miquelon",
  "SAKSA": "Saksa",
  "SAMBIA": "Sambia",
  "SÃO TOMÉ JA PRÍNCIPE": "São Tomé ja Príncipe",
  "SAUDI-ARABIA": "Saudi-Arabia",
  "SUOMI": "Suomi",
  "SVALBARD JA JAN MAYEN": "Svalbard ja Jan Mayen",
  "SVEITSI": "Sveitsi",
  "SWAZIMAA": "Swazimaa",
  "TADZIKISTAN": "Tadzikistan",
  "TAIWAN": "Taiwan",
  "TANSANIA": "Tansania",
  "TANSKA": "Tanska",
  "THAIMAA": "Thaimaa",
  "TRINIDAD JA TOBAGO": "Trinidad ja Tobago",
  "TSEKKI": "Tsekki",
  "TURKKI": "Turkki",
  "TURKS- JA CAICOSSAARET": "Turks- ja Caicossaaret",
  "UKRAINA": "Ukraina",
  "UNKARI": "Unkari",
  "UUSI-SEELANTI": "Uusi-Seelanti",
  "VATIKAANI": "Vatikaani",
  "VENÄJÄ": "Venäjä",
  "VIRO": "Viro",
  "WALLIS JA FUTUNA": "Wallis ja Futuna",
  "YHDYSVALLAT": "Yhdysvallat",
  "VALKO-VENÄJÄ": "Valko-Venäjä",
  "YHDYSVALTAIN NEITSYTSAARET": "Yhdysvaltain Neitsytsaaret",
  "YHDYSVALTAIN TYYNENMEREN ERILLISSAARET": "Yhdysvaltain Tyynenmeren erillissaaret",

  "LOGIN": {
      "MOVE_TO_LOGIN": "Siirry kirjautumiseen",
      "CHECK_OUT_ONLINE_SERVICE": "Tutustu Elite Alfred Bergin palveluihin",
      "CHECK_OUT_PERSONNEL_FUNDS": "Tutustu henkilöstörahastoihin",
      "ONLINE_SERVICE_IS_FOR_YOU": "Verkkopalvelu sinulle, jolla on sijoituksia Elite Alfred Bergillä. Rahastosäilytys Elite Alfred Bergissä on maksuton.",
      "THIS_SERVICE_IS_FOR_YOU": "Tämä palvelu on sinulle, jolla on henkilöstö- tai palkkiorahasto Elite Alfred Bergillä. Täällä näet omaan jäsenyyteesi liittyvät tiedot.",
      "EVLI_ONLINE_SERVICE": "EAB Verkkopalvelu on My Evli -verkkopalvelu",
      "EVLI_ONLINE_SERVICE_IS_FOR_YOU": "Palvelussa asiakkaat voivat seurata varallisuuden kehitystä, merkitä rahastoja, ostaa osakkeita, lukea yritysanalyysejä ja paljon muuta.",
      "EVLI_GO_TO_LOGIN": "Kirjaudu My Evliin",
      "EVLI_CHECK_OUT_ONLINE_SERVICE": "Tutustu Evlin palveluihin",
      "EVLI_MY_EVLI_LOGIN_LINK": "https://m2.evli.com/m2/wealth?lang=fi",
      "EVLI_COM_LINK": "https://www.evli.com",
  },

  "SHORT": {
      "DAY": "pv",
      "WEEK": "vk",
      "MONTH": "kk",
      "YEAR": "v",
      "PHONE_NUMBER": "Puh.",
      "MONDAY": "Ma",
      "TUESDAY": "Ti",
      "WEDNESDAY": "Ke",
      "THURSDAY": "To",
      "FRIDAY": "Pe",
      "SATURDAY": "La",
      "SUNDAY": "Su",
      "EURO_PER_CALL": "€/puh",
      "EURO_PER_MINUTE": "€/min"
  },

  "INFO_TEXTS": {
      "TRUE_BENEFICIARIES": "Mikäli yrityksellä tai yhteisöllä on omistajia, joiden omistusosuus ja/tai äänioikeus ylittää 25 % tai jollakin luonnollisella henkilöllä on oikeus nimittää tai erottaa enemmistö jäsenistä yrityksen tai yhteisön hallituksesta tai vastaavasta elimestä, tulee heidän tietonsa merkitä tähän.",
      "MIFID_REQUIRED": "Osa asiakastiedoista puuttuu tai on vanhentunut. Laki velvoittaa meitä kysymään tiedot. Voit toistaiseksi ohittaa tietojen syöttämisen jos haluat, mutta suosittelemme syöttämään ne jo tässä vaiheessa.",
      "CONFIRMATION_CODE_REQUIRED": "Puhelimeesi on lähetetty tekstiviesti, jossa lukee vahvistustunnus. Syötä tunnus alla olevaan kenttään ja valitse vahvista.",
      "404": "Tiedosto voi olla siirretty tai poistettu. Tarkista että kirjoitit osoitteen oikein.",
      "500": "Pyyntöäsi käsitellessä meni jotain pieleen, ja palvelin ei osannut ratkaista ongelmaa. Yritäthän uudelleen tai ota meihin yhteyttä.",
      "500_TITLE": "Pahoittelut, palvelimella tapahtui odottamaton virhe.",
      "CONFIRMATION_CODE_REQUIRED_SMS_NOT_SENT": "Puhelimeesi on jo aiemmin lähetetty tekstiviesti jossa lukee vahvistustunnus. Syötä tunnus alla olevaan kenttään ja valitse vahvista. Uutta viestiä ei lähetetä ennen kuin edellisen viestin lähettämisestä on kulunut viisi minuuttia",
      "ELECTRONIC_SIGNATURE_P1": "Asiakas on tietoinen ja hyväksyy, että sähköisten viestintävälineiden käyttämiseen liittyy erityisiä riskejä muu muassa sen vuoksi, että viesti ei mahdollisesti saavu perille, viesti saattaa joutua ulkopuoliseen tietoon tai ulkopuolinen saattaa muutella viestin sisältöä. Elite Alfred Bergillä on oikeus luottaa sähköisten viestintävälineiden välityksin saamansa viestin aitouteen ja oikeellisuuteen.",
      "ELECTRONIC_SIGNATURE_P2": "Elite Alfred Bergillä on oikeus luovuttaa tietoja kolmannelle taholle kulloinkin voimassa olevan lainsäädännön mukaisesti.",
      "ELECTRONIC_SIGNATURE_P3_PART1": "Asiakas hyväksyy sen, että Elite Alfred Berg antaa sijoituspalvelulain mukaisen sijoituspalvelutarjoajan tiedonantovelvollisuuden piiriin kuuluvat palveluntarjoajaa, tarjottuja sijoituspalveluja, rahoitusvälineitä ja niiden riskejä, asiakasluokittelua, kannustimia, eturistiriitojen hallintaa sekä sijoituspalvelun tarjoamisessa käytettäviä menettelytapoja ja periaatteita koskevat tiedot sekä kuluttajansuojalain mukaiset etämyynnin ennakkotiedot",
      "ELECTRONIC_SIGNATURE_P3_PART2": "tai muuta sähköistä viestintävälinettä käyttäen.",
      "ELECTRONIC_SIGNATURE_ON_THEIR_WEBSITE": "verkkosivuillaan",
      "ERROR_UPDATING_INFO": "Tietojen päivittäminen ei onnistunut. Se voi johtua monesta syystä: verkkopankin tunnuksilla tunnistautumisessa tapahtui virhe, tunnukset eivät ole samat, joilla käyttäjätunnus on luotu, tai järjestelmässä on virheellistä tietoa. Ole yhteydessä asiakaspalveluumme, jotta yhteystietosi saadaan päivitettyä.",
      "ERROR_IN_ONLINE_AUTHENTICATION": "Verkkotunnistautuminen ei onnistunut. Se voi johtua monesta syystä: verkkopankin tunnuksilla tunnistautumisessa tapahtui virhe, tunnukset eivät ole samat, joilla käyttäjätunnus on luotu, tai järjestelmässä on virheellistä tietoa. Ole yhteydessä asiakaspalveluumme, jotta yhteystietosi saadaan päivitettyä.",
      "NEW_CUSTOMER_SUCCESS_CONTRACT_PACKAGE_WILL_BE_SENT_BY_MAIL": "Asiakassopimuspaketti lähetetään lähipäivinä postitse antamaanne osoitteeseen.",
      "NEW_CUSTOMER_SUCCESS_TITLE": "Tervetuloa asiakkaaksi ja kiitos luottamuksesta!",
      "NEW_CUSTOMER_USING_USERID": "Jatkossa voitte kirjautua palveluun käyttämällä käyttäjätunnusta ja äsken valitsemaanne salasanaa. Tietosuojan takaamiseksi suosittelemme asiakasnumeron ja salasanan säilyttämistä erillään.",
      "ORDER_CONFIRMATION_INFO": "Pankkipäivinä ennen klo 10 annetut toimeksiannot käsitellään saman päivän aikana. Toimeksiannot toteutuvat rahastojen sääntöjen mukaisesti.",
      "SIGN_CONTRACTS": "Tutustu huolella sopimusehtoihin ja allekirjoita ne sähköisesti.",
      "SUBSCRIPTION_CONFIRMATION_INFO": "Merkitessäsi erikoissijoitusrahastoa alaikäisen nimiin sitoudut merkinnän yhteydessä toimittamaan rahastoyhtiölle maistraatin antaman suostumuksen merkintään.",
      "USERID_CREATED_TITLE": "Uusi käyttäjätunnus luotu",
      "YOU_CAN_NOW_LOGIN_TO_SYSTEM": "Voit nyt kirjautua palveluun tästä linkistä",
      "ERROR_UPDATING_BANK_ACCOUNT": "Salkun vastetilin päivittäminen ei onnistunut. Se voi johtua siitä että verkkopankin tunnuksilla tunnistautumisessa tapahtui virhe tunnukset eivät ole samat joilla käyttäjätunnus on luotu tai järjestelmässä on virheellistä tietoa. Jotta yhteystiedot saadaan päivitettyä voit olla yhteydessä asiakaspalveluumme jonka yhteystiedot näet alla.",
      "ERRORS_WHEN_SENDING_THE_INFORMATION": "Lomaketta tai muita tietoja lähetettäessä tapahtui virhe. Virhe johtuu siitä, että jotkin syötetyistä tiedoista puuttuvat tai ovat väärässä muodossa. Alla olevassa listassa on kenttien nimet, joissa on virheitä sekä tarkemmat virheilmoitukset.",
      "SIGNUP_PROCESS_IS_NOT_READY": "Asiakkaaksitulo on kesken. Haluatko varmasti poistua sivulta?"
  },

  "RISK_CLASS_DESCRIPTIONS": {
      "SECURITY_ORIENTED": "Pidettäessä sijoitus suositellun vähimmäissuositusajan on riski pääoman menettämisestä vähäinen ja vastaavasti tuottotavoite on matala (pitkän aikavälin inflaation suuruinen)",
      "CAUTIOUS": "Pidettäessä sijoitus suositellun vähimmäissijoitusajan riski pääoman menettämisestä on keskimääräistä osakesijoitusta alhaisempi ja vastaavasti vähimmäissijoitusaikana tuottotavoite ylittää jonkin verran pelkkien korkosijoitusten keskimääräisen tuoton.",
      "MODERATE": "Suositellun vähimmäissijoitusajan puitteissa sijoitusriski ja tuottotavoite vastaavat tasapainoisen osake- ja korkosijoitusten yhdistelmän riskiä ja tuottotavoitetta. Riski on jonkin verran osakesijoituksen riskiä alhaisempi ja tuottotavoite korkosijoitusta korkeampi, mutta osakesijoituksen tuottotavoitetta alhaisempi.",
      "RETURN_ORIENTED": "Pidettäessä sijoitus suositellun vähimmäissijoitusajan sijoituksen riski vastaa osakemarkkinoiden sijoitusriskiä ja tuottotavoite vastaavalla ajalla on osakemarkkinoiden tuotto-odotusta hieman korkeampi.",
      "AGGRESSIVE": "Tuottotavoite suositellulla sijoitusajalla ylittää huomattavasti osakemarkkinoiden keskimääräisen tuoton ja samalla riski pääoman menettämisestä osittain tai kokonaan on merkittävästi korkeampi kuin sijoitettaessa osakemarkkinoille."
  },

  "ORDER_TYPES": {
      "Withdrawal": "Nosto",
      "Buy": "Osto",
      "Subscription": "Osto",
      "Sell": "Myynti",
      "Redemption": "Myynti"
  },

  "ORDERS_NAV": {
      "BROWSE_ORDERS": "Selaa toimeksiantoja",
      "BUY_FUND": "Osta rahastoa",
      "MAKE_ORDER": "Tee sijoitus",
      "SELL_FUND": "Myy rahastoa",
      "WITHRAW_MONEY": "Nosta rahaa salkusta",
      "DEPOSIT_MONEY": "Talleta rahaa rahastotilille",
      "BROWSE": "Selaa",
      "BUY": "Osta",
      "SELL": "Myy",
      "WITHRAW": "Nosta",
      "DEPOSIT": "Talleta"
  },

  "ORDERS_BUY": {
      "CHOOSE_PORTFOLIO": "Valitse salkku",
      "FUND_TO_BUY": "Ostettava rahasto",
      "PURPOSE_OF_BUY": "Oston tarkoitus",
      "NEW_ORDER_PORTFOLIO_AVAILABLE_SUM": "Käytettävissä oleva saldo",
      "NEW_ORDER_PORTFOLIO_BALANCE": "Salkun saldo",
      "BUY": "Osta",
      "DRAFT_NOTIFICATION": "Salkkusi tämänhetkinen saldo ei riitä tämän merkinnän tekemiseen. Toteutamme merkinnän, kun merkintään tarvittavat varat ovat siirtyneet salkkuusi. Varojen siirtymisessä kestää tyypillisesti 1–3 pankkipäivää.",
      "CREATE_NEW_PORTFOLIO_TEXT": "Sinulla ei ole vielä omatoimisalkkua, johon voit tehdä rahasto-ostoa. Halutessasi voit kuitenkin luoda omatoimisalkun ",
      "HERE": "täällä",
      "CREATE_NEW_PORTFOLIO": "Luo omatoimisalkku",
  },

  "ORDERS_SELL": {
      "FUND_TO_SELL": "Myytävä rahasto",
      "SELL_PORTIONS": "Myytävät osuudet",
      "SELL_PORTIONS_AVAILABLE": "Myytävissä olevat osuudet",
      "SELL_ALL_PORTIONS": "Myy kaikki",
      "SUM_ESTIMATE": "Arvio myytävästä summasta",
      "ESTIMATE_INFO": "Arvio tarkentuu myöhemmin",
      "PERCENTAGE": "prosenttia",
      "MANAGEMENT_OF_REDEMPTION_FUND": "Lunastusvarojen käsittelytapa",
      "WITHDRAW_FUNDS": "Haluan varat vastatililleni",
      "KEEP_FUNDS_IN_PORTFOLIO": "Jätän varat salkkuun",
      "TOOLTIP": "Myyntitoimeksiannolla myydään valitun rahaston osuuksia salkustasi hankintajärjestyksessä.Toimeksiantoa ei voi kohdistaa tiettyyn hankintaerään."
  },

  "ORDERS_DEPOSIT": {
      "CHOOSE_PORTFOLIO": "Valitse salkku",
      "DEPOSIT_HEADER": "Talleta rahaa rahastotilille",
      "DEPOSIT_SUBHEADER_PAYTRAIL": "Täältä voit tallettaa rahaa salkullesi. Siirtämäsi rahat ovat heti käytössäsi EAB Verkkopalvelussa.",
      "DEPOSIT_SUBHEADER_TRANSFER": "Täältä saat tiedot rahan tallettamiseen pankkitililtäsi omalle Elite Alfred Bergin rahastotilillesi.",
      "DEPOSIT_HELP": "Voit tallettaa rahaa Elite Alfred Bergin rahastotilille suorittamalla maksun omasta pankista alla olevilla tiedoilla. Tarvitset maksun suorittamiseksi maksuviitteen, saajan nimen sekä tilinumeron.",
      "BIC": "BIC",
      "RECEIVER_NAME": "Saajan nimi",
      "RECEIVER_ACCOUNT": "Saajan tilinumero",
      "BANK_NAME": "Pankki",
      "PAYMENT_REFERENCE": "Maksuviite",
      "ELITE_ACCOUNT_NAME": "EAB Varainhoito Oy / Asiakasvarat",
      "GIVE_AMOUNT": "Syötä siirrettävä summa",
      "CHOOSE_PAYMENT_METHOD": "Valitse maksutapa",
      "MOVE_TO_PAYMENT": "Siirry tekemään talletus",
      "AMOUNT_TO_BE_TRANSFERRED": "Siirrettävä summa",
      "AMOUNT_MUST_BE_BETWEEN": "Summan on oltava väliltä",
      "PAYMENT_METHOD": "Maksutapa",
      "PAYMENT_NOK": "Tapahtui virhe ja talletus keskeytyi",
      "DEPOSIT_INFORMATION": "Tiedot talletuksesta",
      "RETURN_TO_ORDERS": "Palaa toimeksiantoihin",
      "PAYMENT_OK": "Rahan tallettaminen salkulle onnistui. Rahat ovat heti käytössäsi ostotoimeksiantoja varten.",
      "SAVING_DEPOSIT": "Käsitellään talletusta",
      "PAYMENT_CANCELLED": "Talletus keskeytyi",
      "CONTACT_CUSTOMER_SERVICE": "Otathan tarvittaessa yhteyttä asiakaspalveluumme",
      "SUM_ISNUMERIC": "Summa on oltava numeerinen.",
      "PAYTRAIL_ONLINE_PAYMENT": "Paytrail verkkomaksu",
      "DEPOSIT_SUCCESSFUL": "Talletus onnistui"
  },

  "ORDERS_CONFIRMATION": {
      "AND_KEEP_FUNDS_IN_PORTFOLIO": "ja varat jäävät salkkuusi.",
      "AND_WITHDRAW_FUNDS": "ja varat nostetaan vastatilillesi.",
      "CONFIRM_BUY": "Vahvista ostotoimeksianto",
      "CONFIRM_SELL": "Vahvista myyntitoimeksianto",
      "CONFIRM_WITHDRAW": "Vahvista nostotoimeksianto",
      "BUY_CONFIRMATION": "Ostotoimeksiannon vahvistus",
      "SELL_CONFIRMATION": "Myyntitoimeksiannon vahvistus",
      "WITHDRAW_CONFIRMATION": "Nostotoimeksiannon vahvistus",
      "CONFIRM_KIID_1": "Olen tutustunut rahastoa koskevaan ",
      "CONFIRM_KIID_MONTHLY_1": "Olen tutustunut ",
      "CONFIRM_KIID_MONTHLY_2": "sopimusehtoihin",
      "CONFIRM_KIID_MONTHLY_3": ", rahastoa koskevaan ",
      "CONFIRM_KIID_2": "avaintietoesitteeseen",
      "CONFIRM_KIID_3": "Elite Alfred Bergin sijoituspalvelun yleisiin ehtoihin",
      "CONFIRM_KIID_4": " sekä ",
      "CONFIRM_KIID_5": "rahaston sääntöihin",
      "CONFIRM_KIID_6": " ja hyväksyn ne.",
      "CONFIRM_KIID_DOWNLOAD_1": "Avaintietoesite (pdf)",
      "CONFIRM_KIID_DOWNLOAD_2": "Rahaston säännöt (pdf)",

      "ENTER_PASSWORD": "Syötä salasanasi",
      "ENTER_CONFIRMATION_CODE": "Syötä vahvistustunnus",
      "SELL": "myynti",
      "WITHDRAW": "nosto",
      "TO_PORTFOLIO": "salkkuun",
      "CONFIRM_WITH_ONLINEID": "Toimeksianto vahvistetaan verkkotunnistautumisen avulla."
  },

  "ORDERS_WITHDRAW": {
      "BALANCE_AVAILABLE_FOR_WITHDRAW": "Nostettavissa oleva saldo",
      "ACCOUNT_FOR_WITHDRAW": "Tili, jolle rahat nostetaan",
      "CHOOSE_BANK_ACCOUNT": "Valitse tili jolle rahat nostetaan.",
  },

  "ORDERS_BUY_PIPE": {
      "YOURE_BUYING": "Olet tekemässä ostotoimeksiantoa rahastoon",
      "CHOOSE_CUSTOMER": "Valitse asiakkuus, jonka salkkuun haluat rahaston oston tehdä",
      "NO_CUSTOMERS": "Yhtään soveltuvaa asiakkuutta ei löytynyt.",
      "CHOOSE": "Valitse asiakkuus",
      "WHAT_AMOUNT": "Summa",
      "TARGET_PORTFOLIO": "Osuudet tullaan ostamaan salkkuun",
      "SEE_DEPOSIT_INFO": "Katso tiedot talletuksesta",
      "NOT_ENOUGH_MONEY": "Summa on suurempi kuin käytössä oleva saldo. Talleta lisää rahaa rahastotilille.",
      "CANCEL_BUY": "Peruuta osto",
      "WRITE_NAME_HERE": "Kirjoita salkun nimi tähän",
      "LOADING_DOCUMENTS": "Ladataan dokumentteja",
      "LOADING_INFORMATION": "Ladataan tietoja",
      "BUY_SUCCESSFUL": "Rahaston ostaminen onnistui",
      "YOUVE_BOUGHT": "Olet ostanut rahastoa",
      "SUBSCRIPTION_PORTFOLIO": "Osuudet ostettiin salkkuun",
      "GO_TO_WEB_SERVICE": "Siirry verkkopalveluun",
      "PORTFOLIO_NUMBER": "Salkun numero",
      "SINGLEPURCHASE": "Kertaosto",
      "MONTHLYSAVING": "Kuukausisäästösopimus",
      "CHANGETOSINGLE": "Vaihda kertaostoon",
      "CHANGETOMONTHLY": "Vaihda kuukausisäästösopimukseen"
  },

  "ORDERS_MONTHLY": {
      "START_MONTHLY_SAVING": "Aloita kuukausisäästö", // "Aloita kuukausisäästäminen", 
      "START_MONTHLY_SAVING_HERE": "Täällä voit aloittaa kuukausisäästämisen.",
      "START_MONTHLY_SAVING_HERE_DESCRIPTION": "Kuukausisäästäminen on helppo ja yksinkertainen tapa aloittaa säännöllinen säästäminen. Kuukausisäästämisellä hajautat ajallista riskiä, kun säästät itse valitsemasi säästösumman automaattisesti joka kuukausi.",
      "GIVE_NAME_TO_CONTRACT": "Anna sopimukselle nimi",
      "CONTRACT_NAME": "Nimi",
      "CONTRACT_NAME_MUST_BE_GIVEN": "Nimi on liian pitkä",
      "CONTRACT_NAME_EXAMPLE": "Esim. Matin säästösopimus",
      "SAVE_FOR_ANOTHER_PERSON": "Haluan aloittaa säästämisen toiselle henkilölle",
      "SAVE_FOR_ANOTHER_PERSON_DESCRIPTION": "Voit helposti aloittaa säästämisen myös toiselle henkilölle. Siinä tapauksessa ota yhteyttä asiakaspalveluun puhelimitse tai lähetä yhteydenottopyyntö ”Ota yhteyttä” -valinnan kautta. Asiakaspalvelijamme neuvovat henkilökohtaisesti, kuinka asiassa edetään.",
      "PAYMENT_FREQUENCY": "Maksun toistuvuus",
      "PAYMENT_DATE": "Maksupäivä",
      "CONFIRNG": "Vahvista",
      "MONTHLY": "kuukausittain",
      "EVERY_3_MONTHS": "3 kuukauden välein",
      "EVERY_6_MONTHS": "6 kuukauden välein",
      "FIRST_DAY": "kuukauden ensimmäinen päivä",
      "15TH_DAY": "kuukauden 15. päivä",
      "LAST_DAY": "kuukauden viimeinen päivä",
      "CREATING_MONTHLY_SUBSCRIPTION": "Olet luomassa säästösopimusta ja merkintäostoa rahastoon",
      "FREQUENCY_IS": "Tämän toimeksiannon toistuvuus on",
      "DATE_IS": "ja osto suoritetaan",
      "CONTRACT_CREATED_HEADER": "Säästösopimus on tehty onnistuneesti!",
      "CONTRACT_CREATED_TEXT": "Olet nyt tehnyt säästösopimuksesi onnistuneesti. Jotta merkinnät voidaan toteuttaa, mene seuraavaksi omaan verkkopankkiisi ja tee toistuvaismaksu alla olevilla tiedoilla. Maksun saavuttua teemme merkinnän säästösopimuksesi kohteena olevaan rahastoon.",
      "WANT_TO_MAKE_A_SUBSCRIPTION": "Haluatko tehdä vielä kertamerkinnän rahastoon?",
      "MAKE_SUBSCRIPTION": "Tee kertamerkintä",
      "RETURN_TO_ORDERS": "Palaa toimeksiantoihin",
      "CONTACT_CUSTOMER_SERVICE": "Ota yhteyttä",
      "ELITE_ACCOUNT_NAME": "EAB Varainhoito Oy / Asiakasvarat",
      "ACCOUNT_NUMBER": "FI61 3131 1001 2315 90",
      "BANK_NAME": "Handelsbanken",
      "BIC": "HANDFIHH",
      "UNKNOWN": "XXXXX",
      "STATUS": {
          "DRAFT": "Odottaa käsittelyä"
      }
  },

  "ORDERS_MISC": {
      "HERE_YOU_CAN_BUY_FUND": "Täällä voit ostaa rahastoa ja merkitä sitä omaan salkkuusi.",
      "HERE_YOU_CAN_SELL_FUND": "Täällä voit myydä rahastoa, jolloin myytävistä osuuksista saatava rahasumma siirtyy rahastotilillesi.",
      "HERE_YOU_CAN_WITHDRAW_MONEY": "Täältä voit nostaa rahaa salkustasi omalle pankkitilillesi.",
      "CANCEL_ORDER": "Peruuta toimeksianto",
  },

  "LONG": {
      "DAY": "Päivä",
      "WEEK": "Viikko",
      "MONTH": "Kuukausi"
  },

  "FENNIA": {
      "FENNIA_ASSET_MANAGEMENT_LTD_INNER_CIRCLE": "Fennia Varainhoito Oy:n lähipiiri",
      "REASON_FOR_BEING_INNER_CIRCLE": "Lähipiiriin kuulumisen peruste",
      "SUBSCRIPTION_CONFIRMATION_INFO": "Jos olet tekemässä merkintää toisen tahon lukuun (esim. alaikäiset lapset tai yritys) pyydämme kääntymään Fennia Varainhoito Oy:n asiakaspalvelun puoleen joko puhelimitse numeroon 010 503 7990 tai sähköpostitse osoitteeseen info.varainhoito@fennia.fi.",
      "DEFINITION_OF_INNER_CIRCLE": "Asiakas on Fennia Varainhoito Oy:n lähipiiriin kuuluva yhteisö tai säätiö, jossa Fennia Varainhoito Oy:llä, Vakuutusyhtiö Fennialla, Vakuutusosakeyhtiö Henki-Fennialla tai jonkin niistä hallintoneuvoston jäsenellä, hallituksen jäsenellä tai varajäsenellä, toimitusjohtajalla tai toimitusjohtajan sijaisella, tilintarkastajalla, varatilintarkastajalla tai päävastuullisella tilintarkastajalla, toimivaan johtoon kuuluvalla henkilöllä tai edellä mainitun henkilön alaikäisellä lapsella, aviopuolisolla tai avioliitonomaisessa suhteessa olevalla henkilöllä (avopuoliso tai rekisteröity parisuhde) on yksin tai yhdessä kirjanpitolain mukainen määräysvalta."
  },

  "TRANSACTION_TYPENAMES": {
      "ADD": "Lisäys",
      "ASSETMANAGEMENTFEE": "Hallinnointipalkkio",
      "CFDENTER": "CFD avaus",
      "CFDSETTLE": "CFD sulku",
      "COST": "Palvelumaksu",
      "CREDITINTERESTDEPOSITION": "Sijoituslainan koron tilitys",
      "CREDITINTERESTWITHDRAWAL": "Sijoituslainan koron veloitus",
      "DELETE": "Poisto",
      "DELETED": "Poisto",
      "DEPOSITINTERESTDEPOSITION": "Talletuskoron tilitys",
      "DEPOSITINTERESTWITHDRAWAL": "Talletuskoron veloitus",
      "DEPOSITION": "Talletus",
      "DIVIDEND": "Osinko",
      "FEEREBATE": "Palkkioalennus",
      "FUTURESCONTRACTENTER": "Johdannaisposition avaus",
      "FUTURESCONTRACTSETTLE": "Johdannaisposition sulku",
      "FXTRADE": "Valuuttakauppa",
      "INTEREST": "Korko",
      "MASTERBUY": "MasterBuy",
      "MASTERSELL": "MasterSell",
      "MERGE": "Sulautuminen",
      "MGTFEEREB": "Palkkionpalautus",
      // HUOM! PE_Interestiä ei näytetä yksinään tapahtumaraportilla, vaan yhdistelmänä reasonCode:n kanssa: jos type on PE_Interest ja reasonCode esim. PRIVATE_EQUITY_FUND_FIXED_INTEREST, näytetään tapahtumaraportilla tapahtumatyypissä "Kiinteä korko"
      // Tarvitaan siis ratkaisu, että tapahtumaraportilla näytetään PRIVATE_EQUITY_FUND_FIXED_INTEREST eikä PE_Interest
      "PE_INTEREST_RETURN": "Tuottokorko",
      "PE_INTEREST_ADDITIONAL_RETURN": "Tuottokoron lisäerä",
      "PE_INTEREST_FIXED": "Kiinteä korko",
      "CASH_DEPOSIT_LATE_ENTRY_COMPENSATION": "Tasauserän hyvitys",
      "CASH_DEPOSIT_RETROCESSION": "Palkkionpalautus",
      "CASH_WITHDRAW_FOUNDING_COST": "Perustamiskulu",
      "CASH_WITHDRAW_LATE_ENTRY_FEE": "Tasauserä",
      "BUY_EQUALIZATION_DEBT": "Lisäys",
      "PE_DIVIDEND": "Voitonjako",
      "PE_SUBSCRIPTION": "Osto (Pääomarahasto)",
      "RETURN_OF_CAPITAL": "Pääomanpalautus",
      "PENALTYINTEREST": "Hyvityskorko",
      "REDEMPTION": "Myynti",
      "REDEMPTIONDEPOSITION": "Myynnin talletus",
      "SELLRECEIVABLE": "Myyntisaatava",
      "SOURCETAXDEPOSITION": "Lähdeveron tilitys",
      "SOURCETAXWITHDRAWAL": "Lähdeveron veloitus",
      "SUBSCRIPTION": "Osto",
      "SUBSCRIPTIONWITHDRAWAL": "Oston veloitus",
      "TRANSFER": "Siirto",
      "WITHDRAWAL": "Nosto",

      "BUY": "Osto",
      "BUY_PRIVATE_EQUITY_FUND": "Jälkimarkkinaosto",
      "SELL": "Myynti",

      "CA_DEPOSIT": "Rahastotapahtuman jättö",
      "CA_WITHDRAW": "Rahastotapahtuman otto",
      "KICKBACK_SUBSCRIPTION": "Palkkionpalautus",
      "DIVIDEND_SUBSCRIPTION": "Osinko (kpl)",
      "RIGHTS_ISSUE": "Merkintäoikeusanti",
      "COUPON_INTEREST": "Kupongin maksu",
      "DEPOSIT": "Jättö",
      "WITHDRAW": "Otto",
      "OTHER": "Muu",
      "INSTALLMENT": "Velkakirjan erääntyminen",

      // Used by the statement report
      "BALANCE": "Saldo",
      "ASSET_MANAGEMENT_FEE": "Varainhoitopalkkio",
      "ASSET_MANAGEMENT_FEE2": "Varainhoitopalkkio",

      "AMORTIZATION": "Kuoletus",
      "DEPOSIT_SWAP": "Siirto (Jättö)",
      "DONATION_REDEMPTION": "Lahjoitus (Otto)",
      "DONATION_SUBSCRIPTION": "Lahjoitus (Jättö)",
      "EXPIRATION_SELL": "Erääntyminen",
      "SWITCH_REDEMPTION": "Osuussarjan vaihto",
      "SWITCH_SUBSCRIPTION": "Osuussarjan vaihto",
      "TRANSFER_DEPOSIT": "Siirto salkkuun",
      "TRANSFER_WITHDRAW": "Siirto salkusta",
      "WITHDRAW_SWAP": "Siirto (Otto)",
  },

  "MONTHLYREPORTS": {
      "REPORTS": "Katsaukset",
      "MONTHLY_REPORTS": "Kuukausikatsaukset",
      "FUNDS_SEARCH": "Rahastohaku",
      "REPORTDATE": "Raportointipäivä",
      "HEDGE": "AJ EAB Value Hedge",
      "OSAKE": "Evli Osake",
      "KORKO": "Evli Korko",
      "OPTIMAALIVARAINHOITO": "Evli Optimaalivarainhoito",
      "FOKUS_EUROOPPA": "Elite Alfred Berg Eurooppa Fokus",
      "FOKUS_SUOMI": "Elite Alfred Berg Suomi Fokus",
      "QUARTERLY_REPORTS": "Kvartaalikatsaukset",
      "QUARTERLY_REPORT": "Kvartaalikatsaus",
      "VUOKRATUOTTO": "Elite Alfred Berg Vuokratuotto",
      "ALYENERGIA": "Elite Älyenergia",
      "DOWNLOAD_PDF": "Lataa kuukausikatsaus (pdf)",
      "DOWNLOAD_QUARTERLY_PDF": "Lataa viimeisin kvartaalikatsaus (pdf)"
  },

  "FUNDSEARCH": {
    "FUND_DOCUMENTS_INFO": "Tutustu rahaston dokumentteihin",
    "FUND_DOCUMENTS_INFO_URL": "https://www.evli.com/tuotteet-ja-palvelut/rahastot/rahastot/eab-dokumentit",
    "SFDR_TOOLTIP_TITLE": "Vastuullisuus ympäristöön, yhteiskuntavastuuseen ja hallintotapaan liittyvissä asioissa.",
    "GOOD": "Harmaa",
    "BETTER": "Vaaleanvihreä",
    "BEST": "Tummanvihreä",
    "SFDR": "ESG",
    "PROFIT_3_MONTHS": "3 kk",
    "PROFIT_6_MONTHS": "6 kk",
    "PROFIT_YTD": "YTD",
    "PROFIT_1_YEARS": "1 v",
    "PROFIT_3_YEARS": "3 v",
    "SHOWN_FUNDS": "Näytettynä rahastot",
    "FUNDS_FOUND_TOTAL": "Yhteensä löytyi",
    "FUNDS": "rahastoa.",
    "SEARCH_BY_KEYWORD": "Etsi hakusanalla",
    "3_MONTHS": "3 kk",
    "12_MONTHS": "12 kk",
  },

  // Jäsenpalvelun käännökset, CRM:stä tulevat arvot
  "Rahasto-osuuden arvo": "Rahasto-osuuden arvo",
  "Maksettu rahasto-osuus": "Maksettu rahasto-osuus",
  "Voittopalkkio": "Voittopalkkio",
  "Yli/Alijäämä": "Yli/Alijäämä",
  "Tuotto": "Tuotto",
  "Tulospalkkio": "Tulospalkkio",
  "Edellisenä maksupäivänä maksettu rahasto-osuus": "Edellisenä maksupäivänä maksettu rahasto-osuus",
  "Lisäosa": "Lisäosa",
  "Rahasto-osuus yhteensä": "Rahasto-osuus yhteensä",
  "Sidotusta nostettavaan siirto": "Sidotusta nostettavaan siirto",
  "Arvonmuutos": "Arvonmuutos",
  "Oikaisu": "Oikaisu",

  "Sähköisesti": "Verkkopalveluun",
  "Kirjeenä": "Kirjeenä",

  "Irtisanoutuminen": "Irtisanoutuminen",
  "Esteellisyys / Siirto rahastoon kuulumattomaan yhtiöön": "Esteellisyys / Siirto rahastoon kuulumattomaan yhtiöön",
  "Määräaikainen": "Määräaikainen",
  "Eläkkeelle siirtyminen": "Eläkkeelle siirtyminen",
  "Kuolema": "Kuolema",
  "Irtisanottu tuotannollisista tai taloudellisista syistä / Yritysjärjestely": "Irtisanottu tuotannollisista tai taloudellisista syistä / Yritysjärjestely",
  "Lepäävä jäsenyys": "Lepäävä jäsenyys",
  "Siirto toiseen rahastoon kuuluvaan yhtiöön": "Siirto toiseen rahastoon kuuluvaan yhtiöön",

  "Rahastoin palkkion kokonaan": "Rahastoin palkkion kokonaan",
  "Nostan käteisenä palkan yhteydessä": "Nostan käteisenä",
  "Rahastoin palkkiosta 75%": "Rahastoin palkkiosta 75%",
  "Rahastoin palkkiosta puolet (50%)": "Rahastoin puolet ja nostan puolet käteisenä",
  "Rahastoin palkkiosta 25%": "Rahastoin palkkiosta 25%",
  "Rahastoin palkkiosta 2/3": "Rahastoin palkkiosta 2/3",
  "Rahastoin palkkiosta 1/3": "Rahastoin palkkiosta 1/3",

  "Vahvistan, että olen Yhdysvaltojen kansalainen": "Vahvistan, että olen Yhdysvaltojen kansalainen",
  "Vahvistan, että en ole Yhdysvaltojen kansalainen": "Vahvistan, että en ole Yhdysvaltojen kansalainen",

  "Valinta on lukittu": "Valinta on lukittu",
  "Valintaa ei ole lukittu": "Valintaa ei ole lukittu",

  /*
   * Jäsenpalvelun käännökset
   */
  "PERSONNELFUND": {
      "PERSONNELFUND_NAME": "Henkilöstö-/palkkiorahasto",
      "FINANCIAL_PERIOD": "Tilikausi",
      "MY_FUND": "Oma rahastoni",

      // Ilmoitukset tallentamattomista tiedoista
      "NOT_SAVED": "Tietoja ei ole tallennettu",
      "ARE_YOU_SURE": "Haluatko varmasti poistua sivulta ennen kuin tiedot on tallennettu?",
      "EXIT": "Kyllä",
      "CANCEL": "Peruuta",

      // Login
      "HRJ_LOGIN_FAIL": "Jäsentietojasi ei löydy. Joko et ole henkilö-/palkkiorahaston jäsen tai työsuhdetietojasi ei vielä ole toimitettu rahaston tietoihin. Ota tarvittaessa yhteys asiakaspalveluun:",

      // Etusivu
      "HOME": "Etusivu",
      "WELCOME": "Tervetuloa!",
      "WELCOME_TO_THE_PERSONNEL_FUND": "Tervetuloa henkilöstö-/palkkiorahastojen verkkopalveluun!",
      "IF_YOU_HAVE_ANY_QUESTIONS": "Mikäli sinulla on kysyttävää palveluistamme, tai olet havainnut virheen työsuhdetiedoissasi tai palkkiosi määräytymisperusteissa, ilmoita siitä meille.",
      "YOU_CAN_LEARN_MORE_ABOUT_THE_RULES": "Rahaston sääntöihin voit tutustua lataamalla säännöt alta.",
      "MEMBER_SERVICE_PROVIDES": "Verkkopalvelussa näet rahasto-osuutesi arvon sekä pystyt ilmoittamaan turvallisesti ja helposti kaikista jäsenyyteesi ja rahasto-osuuteesi liittyvistä asioista.",
      "WE_RECEIVE_YOUR_ADDRESS1": "Saamme työnantajasi kautta osoitetietosi. Jos kuitenkin haluat jäsenyyttäsi koskevan postin eri osoitteeseen, ilmoita uusi osoite.",
      "WE_RECEIVE_YOUR_ADDRESS2": "Muista myös päivittää sähköpostiosoitteesi, niin saat mm. kuittaukset verkkopalvelussa tekemistäsi ilmoituksista sähköpostiisi.",
      "WITHDRAWAL": "Nostoilmoitus",
      "HOLDINGS_IN_THE_FUND": "Yhteenveto rahasto-osuutesi arvosta",
      "RESTRICTED_FUND_UNIT": "Sidottu osuus",
      "WITHDRAWABLE_FUND_UNIT": "Nostettava osuus",
      "TOTAL_FUND_UNIT": "Arvo yhteensä",
      "YOU_MAY_NOT_WITHDRAW1": "Nostoilmoitus on tehtävissä vain rahastolle määriteltynä vuosittaisena nostoilmoitusaikana. Viimeisin nostoilmoitusaika on päättynyt.",
      "YOU_MAY_NOT_WITHDRAW2": "Muista pitää matkapuhelinnumerosi ajan tasalla, niin saat jatkossa puhelimeesi tekstiviestillä tiedon nostoilmoitusajan alkamisesta.",
      "MAKE_A_FUNDING_NOTIFICATION": "Tee rahastointi-ilmoitus",
      "UPDATE_YOUR_CONTACT_INFORMATION": "Päivitä yhteystietosi",
      "EDIT_YOUR_CONTACT_INFORMATION": "Muokkaa yhteystietojani",
      "CONTACT_CUSTOMER_SERVICE": "Ota meihin yhteyttä",
      "TO_CUSTOMER_SERVICE": "Asiakaspalveluun",
      "MAKE_A_FUND_UNIT_WITHDRAWAL_NOTIFICATION": "Tee nostoilmoitus",
      "DOWNLOAD_BYLAWS": "Lataa säännöt",
      "A_NOTIFICATION_OF_FUNDING": "Ilmoitus palkkioiden rahastoinnista tai käteisnostosta on tehtävä vuosittain.",
      "THE_REPORTING_PERIOD_IS_IN_PROGRESS": "Rahastointi-ilmoituksen ilmoitusaika on käynnissä, ja viimeinen ilmoituspäivä on ",
      "THE_LATEST_FUND_REPORTING_PERIOD_HAS_ENDED": "Viimeisin rahastointi-ilmoitusaika on päättynyt.",
      "YOU_CAN_NOW_WITHDRAW_THE_AVAILABLE_SHARE": "Voit nostaa rahasto-osuudestasi nostettavissa olevan osuuden tai osan siitä. Nostoilmoitusaika on käynnissä, ja ilmoitusaika päättyy ",
      "INSPECTED_FUND": "Tarkasteltava rahasto",

      // Rahasto-osuuteni
      "MY_FUND_UNIT": "Rahasto-osuuteni tiedot",
      "MY_FUND_UNIT2": "Rahasto-osuuteni",
      "MEMBERSHIP_DETAILS": "Jäsenyystiedot",
      "FUND_AND_MEMBERSHIP_DETAILS": "Rahasto- ja jäsenyystiedot",
      "FUND_ESTABLISHED": "Rahasto perustettu",
      "FUND_FINANCIAL_PERIOD": "Rahaston tilikausi",
      "MEMBERSHIP_PERIOD": "Jäsenyysaika",
      "EMPLOYMENT_END_DATE": "Työsuhteen päättymispäivä",
      "EMPLOYMENT_END_REASON": "Työsuhteen päättymissyy",
      "MEMBERSHIP_DETAILS_DELIVERY": "Jäsentietojen toimitustapa",
      "WWW_SERVICE": "Verkkopalveluun",
      "HOME_BY_POST": "Postitse",
      "IBAN": "IBAN",
      "BIC": "BIC",
      "WORKING_HOURS": "Työaika",
      "SALARY": "Ansio",
      "BONUSES_WHICH_ARE_FUNDED_DURING": "Kesken tilikauden rahastoidut palkkiot",
      "TAX_PERCENTAGE": "Veroprosentti",
      "GIVE_TAXRATE_HALF_PERCENT_ACCURACY": "Anna veroprosentti puolen prosentin tarkkudella",
      "FUNDING_DATE": "Palkkion maksu rahastoon",
      "FUND_VALUE_DATE": "Arvopäivä",
      "BONUS": "Summa",
      "YOUR_PERSONAL_FUND_UNIT": "Laskelma omasta rahasto-osuudesta",
      "DATE": "Päivämäärä",
      "EXPLANATION": "Selite",
      "RESTRICTED_AMOUNT": "Sidottu osuus",
      "WITHDRAWABLE_AMOUNT": "Nostettavissa",
      "VALUE_OF_FUND_UNIT": "Rahasto-osuuden arvo",
      "CHANGE_IN_VALUE_TEXT": "Rahasto-osuuden arvonmuutos kuvaa rahaston sijoituskohteiden arvonmuutosta, jota ei ole realisoitu rahaksi. Arvonmuutos kertoo, miten rahaston varallisuuden arvo on muuttunut tilikauden aikana.",
      "PROFIT_TEXT": "Rahasto-osuuden tuotto kuvaa rahaston sijoituskohteista saatua tuottoa, joka on realisoitu tilikauden aikana rahaksi. Tuotto sisältää voitot, tappiot ja kulut. ",
      "FUND_UNIT_PAID": "Edellisenä maksupäivänä maksettu rahasto-osuus",
      "PROFIT_REMUNERATION": "Voittopalkkio",
      "PERFORMANCE_BASED_BONUS": "Tulospalkkio",
      "SUPPLEMENT": "Lisäosa",
      "PORTION_OF_THE_PROFITS": "Osuus päättyneen tilikauden tuloksesta (Yli-/alijäämä + arvonmuutos)",
      "PORTION_TRANSFERRED": "Sidotusta nostettavaan siirretty osuus",
      "RESTRICTED_AND_WITHDRAWABLE_IN_TOTAL": "Sidottu ja nostettava yhteensä",
      "TOTAL_FUND_UNIT_AFTER_PAYMENT": "Rahasto-osuus yhteensä",
      "FUND_CONTRIBUTIONS_MADE": "Tilikaudella maksetut rahasto-osuudet",
      "CALCS_NONE": "Laskelmia ei saatavilla",
      "MIDYEAR_NONE": "Palkkioita ei saatavilla",
      "SHARES_NONE": "Osuuksia ei saatavilla",
      "SHARES_NOT_AVAILABLE_NOW": "Osuuksia ei tällä hetkellä saatavilla",

      "TRANSACTION_INFORMATION": "Tapahtuman tiedot",
      "WITHDRAWN_FUND_SHARE1": "Nostettu rahasto-osuus",
      "WITHDRAWN_FUND_SHARE2": "nostettu rahasto-osuus",
      "BACK_TO_THE_CALCULATION": "Takaisin laskelmaan",

      "NO_ACTIVE_FINANCIAL_PERIOD": "Ei voimassa olevaa rahaston tilikautta",

      // Rahasto-osuuden nosto
      "FUND_UNIT_WITHDRAW": "Rahasto-osuuden nosto",
      "READ_MORE_ABOUT_TAXATION": "Lue lisää verotuksesta",
      "ABOUT_TAXATION": "Verotuksesta",
      "TO_PERSONAL_DETAILS": "Omiin tietoihin",

      "WITHDRAWABLE_FUND_UNIT1": "Nostettava rahasto-osuus",

      "MORE_INFORMATION": "Lisätietoja",
      "READ_MORE_ABOUT_FUND_UNIT_WITHDRAWAL": "Lue lisää rahasto-osuuden nostosta",
      "READ_MORE_ABOUT_EMPLOYMENT_END_REASON": "Lue lisää työsuhteen päättymisestä",

      "EDIT_NOTIFICATION": "Muokkaa ilmoitusta",
      "MAKE_FUND_UNIT_WITHDRAWAL_NOTIFICATON": "Tee nostoilmoitus",
      "TEMPORARILY_UNABLE_TO_MAKE_NOTICES_OF_WITHDRAWAL": "Nostoilmoituksen teko ei ole mahdollista tällä hetkellä.",
      "WITHDRAWABLE_AMOUNT_1": "Nostettavissa oleva osuus",
      "I_WILL_WITHDRAW": "Nostan rahasto-osuudestani",
      "OR": "tai",
      "TOTAL_VALUE": "Summa",
      "PAYABLE_FUND_UNIT_AMOUNT": "Maksettava rahasto-osuutesi",
      "PAID_FUND_UNIT": "Maksettu rahasto-osuus",
      "FROM_FUND": "rahastosta",
      "TAXFREE": "Veroton",
      "NOT_TAXFREE": "Veronalainen rahasto-osuus",
      "WITHHOLDING": "Ennakonpidätys",
      "TAX_INFORMATION1": "Kun nostat varoja rahastosta, nostettavasta summasta 20% on sinulle kokonaan verovapaata tuloa ja loput 80% nostosta verotetaan veroprosentin mukaan. Tarkasta, että tili- ja verotietosi ovat oikein. Kopiota verokortista ei tarvitse lähettää, ellei kyseessä ole 0% tai lähdeverokortti: tällöin verokortti on toimitettava henkilöstö-/palkkiorahastojen asiakaspalveluun skannattuna tai postitse.",
      "TAX_INFORMATION2": "Mikäli veroprosenttia ei ole tiedossa, tehdään ennakonpidätys verotettavasta osuudesta 60%:n mukaan.",
      "TAX_INFORMATION3": "Toimitamme puolestasi verottajalle tiedot maksetusta rahasto-osuudesta.",

      "FUND_BALANCE_SHEET_DAY": "Rahaston tilinpäätöspäivä ja maksupäivä",
      "THE_VALUE_OF_THE_FUND_UNIT": "Rahasto-osuuden arvo määräytyy vuosittain rahaston tilinpäätöspäivänä eli arvonmäärityspäivänä. Maksupäivä määräytyy tilinpäätöspäivän mukaan. Henkilöstörahastolaki määrittää, että rahasto-osuuksien maksupäivä tulee olla viimeistään 4 kuukauden kuluttua rahaston tilinpäätöspäivästä.",
      "IF_THE_EMPLOYMENT_CONTINUES": "Työsuhteen jatkuessa",
      "THE_FUND_WILL_TRANSFER": "Rahasto siirtää vuosittain nostettavissa olevaan osuuteen 1-15 % rahaston sääntöjen mukaisesti. Halutessaan jäsen voi nostaa rahastosta vuosittain nostettavissa olevan osan kokonaisuudessaan tai osan siitä. Jäsen voi myös jättää nostettavissa olevan osuuden nostamatta ja jättää osuuden rahastoon sijoitettavaksi. Nostamatta jätetyt osuudet jäsen voi nostaa myöhempinä vuosina niille kertyneiden sijoitusten tuottojen kanssa. Päätösmaksuun menevästä osuudesta tehdään vuosittain.",
      "IF_THE_EMPLOYMENT_ENDS": "Työsuhteen päättyessä",
      "AT_THE_END_OF_THE_EMPLOYMENT_RELATIONSHIP1": "Rahasto-osuus maksetaan jäsenelle työsuhteen päättyessä henkilöstörahastolain määräysten mukaisesti kokonaisuudessaan. Sijoitustoiminnan tuottoja kertyy rahasto-osuuden maksupäivää edeltävään arvonmäärityspäivään saakka. Rahasto-osuuden maksupäivä määräytyy työsuhteen päättymispäivän ja –syyn mukaan.",
      "AT_THE_END_OF_THE_EMPLOYMENT_RELATIONSHIP2": "Rahasto-osuuden maksu tilanteissa, joissa työsuhde päättyy:",
      "OWN_REQUEST": "Ero omasta pyynnöstä",
      "OTHER_INDIVIDUAL_GROUNDS_FOR_DISMISSAL": "Työsuhteen purku tai muu yksilöllinen irtisanomisperuste",
      "END_OF_FIXED_TERM": "Määräaikainen työsuhde on päättynyt",
      "TRANSFER_TO_COMPANY_WITH_NO_FUND_MEMBERSHIP": "Rahastoon kuulumattomaan yhtiöön siirto",
      "DEATH": "Kuolema",
      "TRANSFER_TO_DUTIES_NOT_ENTITLED": "Siirto työtehtävään, joka ei oikeuta rahaston jäsenyyteen",
      "AT_THE_END_OF_THE_EMPLOYMENT_RELATIONSHIP3": "Rahasto-osuus maksetaan viimeistään rahaston seuraavasta arvonmäärityspäivästä 4 kk kuluttua.",
      "AT_THE_END_OF_THE_EMPLOYMENT_RELATIONSHIP4": "Tilanteissa, joissa työsuhde päättyy:",
      "DISMISSED_FOR_PRODUCTION_OR_FINANCIAL_REASONS": "Irtisanottu tuotannollisista/taloudellisista syistä",
      "RETIREMENT": "Eläkkeelle siirtyminen",
      "COMPANY_REORGANISATION": "Yritysjärjestely",
      "AT_THE_END_OF_THE_EMPLOYMENT_RELATIONSHIP5": "Rahasto-osuuden maksun jäsen voi halutessaan jakaa neljälle maksupäivälle (maksupäivä kerran vuodessa). Erien suuruuden jäsen voi määritellä itse.",
      "AT_THE_END_OF_THE_EMPLOYMENT_RELATIONSHIP6": "Tukipaketin ottaneet saavat myös jakaa osuuden noston neljälle vuodella, mikäli rahaston säännöt sallivat sen. Tarkista Säännöt ja tiedotteet -välilehdeltä löytyvistä rahaston säännöistä, onko tukipaketin ottaneilla oikeus jaksottaa.",
      "DORMANT_MEMBERSHIP": "Lepäävä jäsenyys",
      "CHECK_IF_THE_FUND_IS_USING_DORMANT_MEMBERSHIP": "Tarkista Säännöt ja tiedotteet -välilehdeltä löytyvistä rahaston säännöistä, onko rahastolla lepäävä jäsenyys käytössä sekä lepäävän jäsenyyden ehdot.",
      "YOUR_FUND_MEMBERSHIP_CONTINUES": "Jäsenyytesi rahastossa ei pääty, mutta sinulle ei kerry rahastoon uutta palkkio-osuutta.",
      "DORMANT_MEMBER_MAY_MAKE_WITHDRAWALS": "Lepäävän jäsenyyden aikana rahasto-osuudesta voi nostaa osuutta vuosittain rahaston maksupäivänä. Jäsenyyden jatkumisen edellytyksenä on, että jäsen nostaa enintään määrän, joka olisi nostettavissa jäsenyyden jatkuessa.",
      "DURING_DORMANT_MEMBERSHIP": "Lepäävän jäsenyyden aikana rahasto-osuus on sijoitustoiminnassa mukana.",
      "YOU_MAY_ALSO_WITHDRAW_YOUR_FUND_UNIT_AS_A_WHOLE": "Voit nostaa rahasto-osuutesi myös kokonaan, jolloin jäsenyys rahastossa päättyy.",

      "YOU_MAY_WITHDRAW_WILL_BE_PAID_AUTOMATICALLY": "Voit nostaa nostettavissa olevan rahasto-osuutesi kokonaisuudessaan tai haluamasi osan siitä. Voit muuttaa ilmoitustasi nostoilmoitusajan päättymiseen asti. Jos et tee nostoilmoitusta, koko nostettavissa oleva osuus maksetaan sinulle automaattisesti maksupäivänä.",
      "YOU_MAY_WITHDRAW_WILL_NOT_BE_PAID_AUTOMATICALLY": "Voit nostaa nostettavissa olevan rahasto-osuutesi kokonaisuudessaan tai haluamasi osan siitä. Voit muuttaa ilmoitustasi nostoilmoitusajan päättymiseen asti.",
      "YOUR_MEMBERSHIP_HAS_TERMINATED_1": "Jäsenyytesi on päättymässä. Sinulla on halutessasi myös oikeus jaksottaa rahasto-osuuden nostot seuraavalle neljälle vuodelle.",
      "YOUR_MEMBERSHIP_HAS_TERMINATED_2": "Käyttäessäsi jaksotusoikeutta on nostoilmoitus jaksottamisesta tehtävä vuosittain aina uudelleen rahaston ilmoitusaikana. Vuosittain nostamasi summan voit valita vapaasti osuutesi arvosta.",
      "YOUR_MEMBERSHIP_HAS_TERMINATED_3": "Noston jaksottaminen useammalle vuodelle tarkoittaa henkilöstörahaston jäsenyyden jatkumista. Nostamatta jättämäsi osuuden arvo määräytyy vuosittain kulloisenkin arvonmäärityspäivän mukaisesti.",
      "IF_YOU_DONT_REPORT_WILL_BE_PAID_AUTOMATICALLY": "Jos et ilmoita käyttäväsi jaksotusoikeutta, rahasto-osuutesi maksetaan sinulle kokonaisuudessaan maksupäivänä.",
      "IF_YOU_DONT_REPORT_WILL_BE_PAID_LAST_YEAR": "Jos et ilmoita käyttäväsi jaksotusoikeutta, rahasto-osuutesi maksetaan sinulle kokonaisuudessaan viimeisen jaksotusvuoden maksupäivänä.",
      "WITHDRAWAL_NOTICE_TIME_ENDS": "Nostoilmoitusaika päättyy ",
      "FUND_SHARE_WILL_BE_PAID": " ja rahasto-osuus maksetaan tilillesi ",
      "WITHDRAWAL_CONFIRM_MESSAGE_AND_EDIT_YOUR_DETAILS": "Saat nostoilmoituksen vahvistuksesta viestin sähköpostiisi. Tarkista sähköpostiosoitteesi ja muuta halutessasi tilinumeroasi ja veroprosenttiasi kohdasta",
      "YOUR_MEMBERSHIP_HAS_TERMINATED_WILL_BE_PAID": "Jäsenyytesi on päättymässä ja koko rahasto-osuutesi on menossa maksuun. Näet tilillesi maksettavan summan alapuolelta. ",
      "YOU_CAN_EDIT_YOUR_IBAN_AND_TAXRATE": "Veroprosenttia ja tilinumeroa pystyt muuttamaan kohdassa ",
      "AUXILIARY_MEMBERSHIP_1": "Palkkahallinnosta saamiemme tietojen mukaan olet siirtynyt sellaisen konserniyhtiön palvelukseen tai palkitsemisjärjestelmän piiriin, joka ei oikeuta henkilöstörahastojäsenyyteen. Näin ollen kertynyt rahasto-osuutesi suoritetaan kokonaisuudessaan tilillesi ",
      "AUXILIARY_MEMBERSHIP_2": "ja jäsenyytesi henkilöstörahastossa päättyy.",
      "AUXILIARY_MEMBERSHIP_3": "Voit halutessasi myös jäädä rahastoon ns. lepääväksi jäseneksi enintään ",
      "AUXILIARY_MEMBERSHIP_4": " vuodeksi. Lepäävän jäsenyyden edellytyksenä on, että nostat rahasto-osuudesta max. sen osan, mikä sinulla olisi nostettavissa jäsenyyden jatkuessa.",
      "AUXILIARY_MEMBERSHIP_5": "Lepääväksi jäseneksi jääminen tarkoittaa rahastojäsenyyden jatkumista. Nostamatta jättämäsi osuuden arvo määräytyy vuosittain kulloisenkin arvonmäärityspäivän mukaisesti.",
      "AUXILIARY_MEMBERSHIP_6": "Ilmoitus lepäävän jäsenyyden jatkamisesta on tehtävä vuosittain aina uudelleen rahaston ilmoitusaikana. Lepäävän jäsenyyden aikana sinulle ei kerry yrityksen rahastolle maksamaa henkilöstörahastoerää.",
      "AUXILIARY_MEMBERSHIP_7": "Jos palaat lepäävän jäsenyytesi aikana takaisin rahaston piiriin kuuluvaan yhtiöön tai jäsenyyteen oikeuttavan palkkiojärjestelmän piiriin, jatkat jäsenyyttäsi rahastossa katkoitta, ilman sääntöjen määräämää karenssiaikaa.",
      "FUND_UNIT_WITHDRAW_NOTIFICATION_PERIOD_IS_NOT": "Nostoilmoitusaika ei ole käynnissä!",
      "YOU_CAN_MAKE_WITHDRAWAL_NOTICE": "Nostoilmoitus on tehtävissä vain rahastolle määriteltynä vuosittaisena nostoilmoitusaikana. Viimeisin nostoilmoitusaika on päättynyt ",
      "REPORT_YOUR_PHONE_NUMBER_1": "Muista päivittää matkapuhelinnumerosi kohdassa ",
      "REPORT_YOUR_PHONE_NUMBER_2": " niin saat jatkossa puhelimeesi tekstiviestillä tiedon nostoilmoitusajan alkamisesta!",
      "YOUR_MEMBERSHIP_HAS_TERMINATED_AND_FUND_SHARE_IS_PAID": "Rahasto-osuutesi on maksettu kokonaisuudessaan tilillesi ja jäsenyytesi rahastossa on päättynyt!",
      "YOU_HAVE_NO_SHARES_YET": "Sinulle ei ole vielä kertynyt rahastoon nostettavissa olevaa rahasto-osuutta.",
      "SHARES_WILL_BE_TRANSFERED_1": "Palkkiota siirretään rahasto-osuuksiin seuraavan kerran rahaston arvonmäärityspäivän ",
      "SHARES_WILL_BE_TRANSFERED_2": " tehtävässä laskennassa.",
      "YOU_HAVE_REPORTED_YOUR_WITHDRAWAL": "Olet tehnyt alla olevan nostoilmoituksen",
      "YOUR_FUND_UNIT_WILL_BE_PAID_1": "Rahasto-osuutesi maksetaan ",
      "YOUR_FUND_UNIT_WILL_BE_PAID_2": " seuraavasti:",
      "YOUR_FUND_UNIT_HAVE_BEEN_PAID": "Sinulle on maksettu rahasto-osuutta ",
      "YOU_WILL_BE_PAID": "Maksetaan tilille ",
      "YOU_HAVE_BEEN_PAID": "Maksettu tilille ",

      "INFORMATION_ON_THE_CALCULATION": "Verotustiedot",
      "ADDITIONAL_TAX_RATE": "Lisäveroprosentti",
      "ADDITIONAL_TAX_RATE_LIMIT": "Lisäveroprosenttiraja",
      "TAX_AT_SOURCE": "Lähdeverollinen",

      "REMEMBER_TO_KEEP_YOUR_EMAIL_UP_TO_DATE": "Muista pitää sähköpostiosoitteesi ajantasalla, ja muuta halutessasi tilinumeroasi nostettavaa osuutta varten omista tiedoistasi.",

      // Rahastointi/Käteisnosto
      "YOUR_MEMBERSHIP_HAS_FINISHED_YOU_CANNOT_GIVE_FUNDING_NOTIFICATION": "Jäsenyytesi on päättynyt, joten et voi tehdä rahastointi-ilmoitusta. Ota tarvittaessa yhteys asiakaspalveluun.",
      "THIS_FEATURE_IS_AVAILABLE_ONLY_WHERE_FUNDING_IS_VOLUNARY": "Toiminto käytössä vain rahastoilla, joissa rahastointi on vapaaehtoista.",
      "YOU_PREVIOUS_NOTICE": "Edellisen kauden rahastointivalintasi",
      "PREVIOUS_NOTICE1": "Edellisellä kaudella ",
      "PREVIOUS_NOTICE2": " tehty ilmoitus",
      "FUNDING_OPTION_LOCKING": "Rahastointivalinnan lukinta",
      "NO_PREVIOUS_NOTICE": "Ei edellisen kauden tietoja",
      "CURRENT_FUNDING_NOTICE_CHOICE": "Kuluvan ilmoituskauden rahastointivalintasi",
      "YOU_HAVE_LOCKED_YOUR_CHOICES": "Olet lukinnut alla näkyvät rahastointivalinnat. Jos haluat muuttaa tekemiäsi valintoja, poista lukitus, tee uudet valinnat ja vahvista valintasi.",
      "REMOVE_LOCKING_AND_CHANGE_CHOICES": "Poista lukitus ja muuta valintoja",
      "IF_YOU_WANT_TO_LOCK": "Halutessasi voit myös lukita valitsemasi maksutavat, jolloin ne jäävät toistaiseksi voimassa oleviksi.",
      "I_WANT_TO_LOCK": "Haluan lukita tekemäni valinnan.",
      "CONFIRM_MESSAGE": "Saat vahvistuksesta viestin sähköpostiisi. Tarkista sähköpostiosoitteesi omista tiedoistasi.",
      "NOT_NOTIFICATION_PERIOD": "Ilmoitusaika ei ole käynnissä.",
      "REWARD": "Palkkio-osuus",
      "FUND100": "Rahastoin palkkion kokonaan",
      "FUND75": "Rahastoin palkkiosta 75%",
      "FUND50": "Rahastoin puolet ja nostan puolet käteisenä",
      "FUND25": "Rahastoin palkkiosta 25%",
      "FUND67": "Rahastoin palkkiosta 2/3",
      "FUND33": "Rahastoin palkkiosta 1/3",
      "FUND300EUR": "Rahastoin palkkiostani 300 euroa",
      // DONE lisätty ??
      "FUND100EUR": "Rahastoin palkkiostani 100 euroa",
      
      "FUND150EUR": "Rahastoin palkkiostani 150 euroa",
      "FUND50EUR": "Rahastoin palkkiostani 50 euroa",
      "WITHDRAWCASH": "Nostan käteisenä",
      "RISK_STRATEGY": "Riskistrategia",
      "SELECTION_OF_INVESTMENT_STRATEGY": "Valitsen riskistrategiaksi",
      "LOADING_RISK_STRATEGYS": "Ladataan riskistrategioita",
      "CAREFUL": "Varovainen",
      "NEUTRAL": "Neutraali",
      "AGGRESSIVE": "Tuottohakuinen",
      "OWN_SHARE": "Oma osake",
      "STRATEGY_YOU_HAVE_CHOSEN_1": "Olet valinnut rahasto-osuutesi sijoitussalkun riskitasoksi sijoitusstrategian ",
      "STRATEGY_YOU_HAVE_CHOSEN_2": ". Päätös on pysyvä eikä valintaa voi vaihtaa. Jatkossa rahastoimasi palkkiot sijoitetaan kyseiseen salkkuun.",
      "SAVE_SUCCESS_MESSAGE": "Tiedot tallennettiin onnistuneesti!",
      "SAVED_SUCCESSFULLY_NOT_SEND_EMAIL_MESSAGE": "Tiedot tallennettiin onnistuneesti, mutta sähköpostin lähetys epäonnistui. Tarkista sähköpostiosoitteesi.",
      "NOT_SAVED_MESSAGE": "Tietojen tallennus epäonnistui. Ole hyvä ja yritä uudelleen, tai ota yhteyttä jasenpalvelu@eai.fi",
      "NOTIFICATION_PERIOD_HAS_ENDED": "Rahastointi-ilmoitusaika on päättynyt",
      "YOU_HAVE_REPORTED_EARLIER": "Aiemmilla ilmoituskausilla tekemäsi ilmoitukset",
      "FUNDING_AND_WITHDRAWAL_NOTIFICATION": "Rahastointi-ilmoitus",
      "YOU_HAVE_REPORTED_EARLIER_FUNDING_AND_WITHDRAWAL": "Olet ilmoittanut edellisillä tilikausilla rahastoinnistasi seuraavaa:",
      "YOU_HAVE_NOT_REPORTED_EARLIER": "Et ole tehnyt rahastointi-ilmoitusta edellisellä ilmoituskaudella, joten sinulle tallentuu rahastovalinnaksesi rahaston oletusvalinta.",
      "FUNDING_AND_WITHDRAWAL_CHOICE": "Rahastointivalinta",
      "LOCKING": "Lukitus",
      "YOU_HAVE_LOCKED": "Olet lukinnut tekemäsi valinnan, joten palkkioittesi maksutapana toimii viimeisin ilmoittamasi rahastointi-/käteisnostovalinta.",
      "YOU_HAVE_NOT_LOCKED": "Et ole lukinnut tekemääsi valintaa, joten palkkioittesi maksutapa muuttuu rahaston oletusrahastointivalinnaksi, ellet tee uutta rahastointi-/käteisnostoilmoitusta ilmoitusajan auettua.",
      "YOU_HAVE_NOTIFIED_AS_RISK_STRATEGY": "Olet valinnut riskistrategiaksesi strategian ",
      "YOU_HAVE_NOTIFIED_AS_RISK_STRATEGIES": "Olet valinnut riskistrategiaksesi/-strategioiksesi strategian/strategiat",
      "RISK_STRATEGY_CHOICE_1": "Riskistrategian valinta koskee kaikkea aikaisemmin rahastoimaasi osuutta sekä tulevia palkkioitasi.",
      "RISK_STRATEGY_CHOICE_2": "Riskistrategian valinta koskee kaikkea aikaisemmin rahastoimaasi osuutta sekä tulevia palkkioitasi, ellet muuta valintaasi ilmoitusajan auettua.",
      "IF_YOU_DID_NOT_CHOSEN_RISK_STRATEGY": "Jos et tehnyt itse riskistrategiavalintaa, valinnaksi on tallentunut automaattisesti neutraali riskistrategia.",
      "YOU_CANNOT_CHOOSE_RISK_STRATEGY_ANYMORE": "Valittuasi riskistrategian kerran sitä ei voi enää vaihtaa.",
      "YOU_CAN_CHANGE_RISK_STRATEGY": "Riskistrategia on vaihdettavissa jälleen ilmoitusajan auettua.",


      // Säännöt ja tiedotteet
      "BYLAWS": "Rahaston säännöt",
      "CHOOSE_FUND_RULE_YOU_WANT_TO_DOWNLOAD": "Valitse ladattavat säännöt",
      "DOWNLOAD_FUND_RULE": "Lataa säännöt",
      "BYLAWS_NONE": "Sääntöjä ei saatavilla",
      "NOTIFICATIONS": "Tiedotteet",
      "NOTIFICATIONS_NONE": "Tiedotteita ei saatavilla",

      // Omat tiedot
      "PERSONAL_DETAILS": "Omat tiedot",
      "MY_CONTACT_INFORMATION": "Yhteystietoni",
      "NAME": "Nimi",
      "STREET_ADDRESS": "Katuosoite",
      "POSTAL_CODE": "Postinumero",
      "POST_OFFICE": "Postitoimipaikka",
      "MEMBERSHIP_LETTER_DELIVERY_METHOD": "Jäsentietojen toimitustapa",
      "MOBILE_NUMBER": "Matkapuhelinnumero",
      "EMAIL": "Sähköpostiosoite",
      "WE_SEND_EMAIL_CONFIRMATION": "Lähetämme sähköpostitse vahvistuksen verkkopalvelussa tehdyistä nosto- ja rahastointi-ilmoituksistasi.",
      "DIRECT_MARKETING_PERMISSION": "Suoramarkkinointilupa",
      "DO_NOT_BULK_MESSAGE": "EAB Group Oyj saa lähettää minulle suoramarkkinointia sähköpostilla ja lähestyä minua puhelimitse.",
      "TAX_RATE": "Veroprosentti",
      "TAX_RATE_EXTERNAL": "Verottajan ilmoittama veroprosentti",
      "TAX_RATE_INFO": "Maksettavasta osuudesta 20% on verovapaata. 80%:sta suoritetaan ennakonpidätys veroprosenttisi mukaan. Mikäli veroprosentti puuttuu, suoritetaan veronalaisesta osuudesta ennakonpidätys 60 %:lla.",
      "ACCOUNT_NUMBER": "Tilinumero",
      "COMMUNICATION_WITH_ELITE": "Yhteydenpito Elite Alfred Bergin kanssa",
      "I_WANT_MY_MEMBERSHIP_INFORMATION": "Haluan, että jäsentietoni toimitetaan",
      "ADD_TAX_COUNTRY": "Lisää verotusmaa",
      "I_CONFIRM_US_NATIONALITY": "Vahvistan, että olen Yhdysvaltojen kansalainen.",
      "I_CONFIRM_NOT_US_NATIONALITY": "Vahvistan, että en ole Yhdysvaltojen kansalainen.",

      "TAXATION_COUNTRY": "Verotuksellinen asuinvaltio",
      "US_CITIZENSHIP": "Verovelvollisuus Yhdysvalloissa",
      "US": "Yhdysvallat",
      "TIN": "Verotunniste",
      "TIN_NUMBER": "Verotunniste (TIN)",
      "TIN_NUMBER_US": "Verotunniste, Yhdysvallat (TIN)",
      "AFTER_CONFIRMATION_MESSAGE": "Vahvistamisen jälkeen yllä olevia tietoja käytetään oletuksellisina tietoina. Muistathan siis pitää ilmoittamasi tiedot ajan tasalla!",
      "OPTIONALLY_REPORT_ADDRESS_AND_ACCOUNT_INFORMATION": "Ilmoita alla halutessasi toiset yhteys- ja tilitietosi (ei pakollinen)",
      "PERSONNEL_FUNDS_ARE_WITHIN_THE_REALM_OF": "Henkilöstö/palkkiorahastot ovat kansainvälisten verovelvoitteiden piirissä, jonka vuoksi rahaston on selvitettävä jäsentensä verotuksellinen asuinvaltio.",
      "US_CITIZENSHIP_NOT_CONFIRMED": "Verovelvollisuuteen liittyviä tietoja ei ole vahvistettu.",
      "CONFIRM_US_CITIZENSHIP": "Vahvista verotuksellista asuinvaltiota ja Yhdysvaltojen kansalaisuutta koskevat tiedot ennen siirtymistäsi pois Omat tiedot –sivulta.",
      "CONFIRM_US_CITIZENSHIP_BEFORE_FUNDING_NOTIFICATION": "Vahvista verotuksellista asuinvaltiota ja Yhdysvaltojen kansalaisuutta koskevat tiedot ennen rahastointi-ilmoituksen tekoa.",
      "YOUR_PREVIOUSLY_MADE_NOTICE_CHANGED": "Huomioithan, että myös aiemmin tekemäsi nostoilmoituksen tietoja muutettiin.",
      // DONE lisätty 17.11.2021
      "YOU_HAVE_BEEN_REDIRECTED_MISSING_INFO": "Sinut on ohjattu Omat tiedot -sivulle, koska sinulta puuttuu pakollisia tietoja.",

      // Asiakaspalvelu
      "OFFICE_INFO": "Evli Oyj",
      "EVLI_ADDRESS": "Aleksanterinkatu 19, 4. krs",
      "EVLI_POSTALCODE_CITY": "PL 1081, 00101 Helsinki",
      "INVESTOR_SERVICE": "Sijoittajapalvelu",
      "INVESTOR_SERVICE_OPENING_HOURS": "klo 9.30-16.30",
      "INVESTOR_SERVICE_PHONE": "(09) 4766 9701",
      "CONTACT": "Ota yhteyttä",
      "CUSTOMER_SERVICE": "Asiakaspalvelu",
      "GIVE_FEEDBACK": "Jätä palautetta",
      "CUSTOMER_SERVICE_CONTACT_NAME": "Elite Palkitsemispalvelut Oy",
      "CUSTOMER_SERVICE_ADDRESS": "Kluuvikatu 3, 3. krs",
      "CUSTOMER_SERVICE_POSTALCODE_CITY": "00100 Helsinki",
      "FEEDBACK_OR_RECTIFUCATION": "Palaute tai oikaisuvaatimus",
      "WRITE_YOUR_COMMENT": "Kirjoita kysymyksesi tai kommenttisi alla olevaan tekstikenttään. Voit myös ilmoittaa, mikäli havaitset virheen työsuhdetiedoissasi tai palkkiosi määräytymisperusteissa.",
      "MESSAGE": "Viesti",
      "CONTACT_INFO_FOOTER": "Henkilöstö-/palkkiorahaston asiakaspalvelu",
      "SEND_SUCCESS_MESSAGE": "Tiedot lähetettiin onnistuneesti!",
      "SEND_ERROR_MESSAGE": "Tietoja lähetettäessä tapahtui virhe.",
      "SEND": "Lähetä",
      "SENDING_INFORMATION": "Lähetetään tietoja",
      "ATTACHMENT": "Liite",
      "CONFIRM": "Vahvista",
      "MY_MESSAGE_IS_ABOUT_FUND": "Viestini koskee rahastoa:",
      "FOOTER_CUSTOMER_SERVICE": "Asiakaspalvelu henkilöstörahaston jäsenille",

      // tarjoussivu
      "SALE_HEADER": "Henkilöstö- ja palkkiorahaston jäsenedut",
      "SALE_INFO_1": "Hyvä henkilöstö- ja palkitsemisrahaston jäsen!",
      "SALE_INFO_2": "Tarjoamme nyt henkilöstö- ja palkitsemisrahastojen jäsenille ainutlaatuisen tilaisuuden merkitä konsernimme hallinnoimien rahastojen osuussarjoja, jotka ovat tavallisesti suunnattu vain ammattisijoittajille. Jäsenetuna voitte nyt merkitä näitä osuussarjoja ilman merkintäpalkkiota. Myös hallinnointipalkkiot ovat edulliset. Etu on tarjolla vain henkilöstö- ja palkitsemisrahastojen jäsenille ja koskee vain alla olevia rahastoja.",
      "SALE_INFO_3": "Henkilöstö- ja palkitsemisrahastoja tarjoava Elite Palkitsemispalvelut Oy on osa Elite Alfred Bergiä. Elite Alfred Berg on monipuolinen sijoittamisen asiantuntija, joka auttaa suomalaisia yksityishenkilöitä, ammattisijoittajia ja yrityksiä hoitamaan varallisuuttaan kokonaisvaltaisesti, suunnitelmallisesti ja vastuullisesti.",
      "SET_VISIBILITY": "En halua nähdä tätä sivua enää. (Valinta tallentuu uloskirjautuessa)"
  },

  // These should be a part of the WEB_REPORTS category, but that would break other translations in the tableGroupingService and portfolioReport.html
  // so they are here instead
  "Fund": "Rahastot",
  "FundAM": "Rahastot",
  "FundOLD": "Rahastot",
  "Share": "Osakkeet",
  "Receivable": "Merkintäsaamiset",

  "PE_Fund_Equalization_Debt": "Voitonjakosaamiset",
  "Private_Equity_Fund": "Pääomarahastot",

  "WEB_REPORTS": {
      // Salkkuraportti
      "PORTFOLIO_REPORT": "Salkkuraportti",
      "INSTRUMENT": "Arvopaperi",
      "MARKET_PRICE": "Markkinahinta",
      "DATE_OF_RATE": "Kurssipvm",
      "ACQUISITION_VALUE": "Hankinta-arvo",
      "UNREALIZED_PROFIT_OR_LOSS": "Realisoimaton\n voitto / tappio",
      "UNREALIZED_PROFIT_OR_LOSS_SHORT": "Realisoimaton V/T (€)",
      "PORTION_OF_ASSETS": "Osuus varoista",
      "ALL_ASSETS": "Omaisuus",
      "TOTAL_SUM": "yhteensä",
      "GROUPING": "Ryhmittely",
      "ASSET_TYPE": "Omaisuusluokka",
      "CLASSIFICATION": "Omaisuusalaluokka",
      "INSTRUMENT_TYPE": "Arvopaperityyppi",
      "CURRENCY": "Noteerausvaluutta",
      "REPORT_DATE": "Raportointipäivämäärä",
      "NO_TRANSACTIONS": "Ei tapahtumia valitulla salkulla.",
      "DOWNLOAD_REPORT": "Lataa tiedostona",
      "COMMITMENT": "Sitoumus",
      "COMPANY_WAGER": "Yhtiöpanos",
      "TOTAL_REVENUE": "Kokonaistuottoprosentti",
      "PROFIT_DISTRIBUTIONS": "Voitonjaot",
      "CAPITAL_RETURNS": "Palautetut pääomat",
      "SUBSCRIPTION_FEES": "Merkintäpalkkio",
      "INFORMATION_THROUGH_INVESTMENT": "Tiedot koko sijoituksen ajalta",
      "UNCALLED": "Kutsumatta",
      "CALLED": "Kutsuttu",
      "LATE_ENTRY_COMPENSATION": "Tasauserän hyvitys",
      "FEE_RETURNS": "Palkkionpalautukset",
      "LATE_ENTRY_FEE": "Tasauserä",
      "TOTAL_EQUALIZATION": "Pääomakutsujen tasaus",
      "RECALLED_CAPITAL": "Takaisinkutsutut pääomat",
      "FOUNDING_COSTS": "Perustamiskulut",
      "TWR_PROFIT": "TWR Tuotto",
      "TWR_DISCLAIMER1": "Tuotto % eli TWR- eli aikapainotettu tuotto (time-weighted return)",
      "TWR_DISCLAIMER2": "TWR-tuotto kuvaa sijoituskohteen tuottoa ennen veroja ja ennakonpidätystä niin, että pääomamuutosten vaikutus on poistettu.",
      "TWR_DISCLAIMER3": "Tuotto vastaa laskennallista arvonnousua sijoitukselle, jota ei ole myyty tai ostettu lisää kesken sijoitusajan, ja jonka osinko tai muu tuotonjako on sijoitettu uudelleen samaan sijoituskohteeseen.",

      // Tapahtumaraportti
      "TRANSACTION_REPORT": "Tapahtumaraportti",
      "PURCHASE_DATE": "Kauppapäivä",
      "SETTLEMENT_DATE": "Maksupäivä",
      "PIECE": "Kpl",
      "PRICE_OF_SHARE": "Hinta / kpl",
      "DELIVERY_FEE": "Toimitusmaksu",
      "TAX": "Vero",

      // Toteutuneet tuotot
      "REALIZED_PROFITS": "Toteutuneet tuotot",
      "NO_PROFITS_FOUND_WITH_CURRENT_PARAMETERS": "Toteutuneita tuottoja ei löytynyt annetuilla hakuehdoilla",
      "PROFITS_AND_LOSSES": "Voitot ja tappiot",
      "PROFITS_AND_LOSSES_TOTAL": "Voitot ja tappiot yhteensä",
      "SELL_VALUE": "Luovutushinta",
      "PORTFOLIO_TOTAL": "Salkku yhteensä",
      "OTHER_PROFITS_TOTAL": "Muut toteutuneet tuotot yhteensä",
      "NET_PROFIT": "Nettotuotto",
      "ACQUISITION_PRICE": "Hankintahinta",
      "PROFIT_OR_LOSS": "Voitto tai tappio",
      "OTHER_REALIZED_PROFITS_HEADER": "Osingot, sijoitusrahastojen tuotonmaksut ja korot",
      "OTHER_REALIZED_PROFITS_SUMMARY": "Osingot, sijoitusrahastojen tuotonmaksut ja korot yhteensä",
      "RELEASE_DATE": "Luovutuspäivä",
      "PRIVATE_EQUITY_REALIZED_PROFITS": "Pääomarahastojen toteutuneet tuotot",
      "PRIVATE_EQUITY_TAX_INFO": "Pääomarahastojen tiedot verotusta varten",
      "CAPITAL_INVESTMENT": "Pääomasijoitus",
      "SHARE_OF_INCOME": "Osuus yhtymän tuotoista",
      "EVL_SHARE_OF_INCOME": "EVL:n mukainen tulo-osuus",
      "TAX_YEAR": "Verovuosi",


      // Tiliote
      "TRANSACTION_NUMBER_ABBR": "Tap.nro",
      "BALANCE": "Saldo",
      "DEPOSITS_TOTAL": "Panot yhteensä",
      "WITHDRAWS_TOTAL": "Otot yhteensä",
      "TRANSACTION_INFO": "Tapahtuman tiedot",
      "VALUE_INFO": "Summan lisätiedot",
      "COMPARED_BALANCE": "Ostoihin varattu saldo",
      "INCOME_ESTIMATE": "Arvio myynneistä saatavasta käteisestä",

      // Asiakasyhteenveto
      "CUSTOMER_SUMMARY": "Asiakasyhteenveto",
      "ACCOUNT": "Tili",
      "ASSETS_TOTAL": "Varallisuus yhteensä",
      "REALIZED_PROFITS_HEADER": "Realisoituneet voitot",
      "REALIZED_LOSSES": "Realisoituneet tappiot",
      "REALIZED_PROFITS_AND_LOSSES": "Realisoitunut voitto (+) / tappio (-)",
      "OTHER_REALIZED_PROFITS": "Muut toteutuneet tuotot",
      "GROSS": "Brutto",
      "NET": "Netto",
      "COUPONS": "Kupongit",
      "AFTER_TAX": "ennakonpidätyksen jälkeen",
      "DISTRIBUTION_BY_ASSET_TYPE": "Jakauma omaisuusluokittain",
      "DISTRIBUTION_BY_CLASSIFICATION": "Jakauma omaisuusalaluokittain",
      "LARGEST_INVESTMENTS": "Suurimmat omistukset",

      // Salkkuyhteenveto
      "PORTFOLIO_SUMMARY": "Salkkuyhteenveto",

      // Varainhoidon yhteenveto
      "ASSETMANAGEMENT_SUMMARY": "Varainhoidon yhteenveto",
      "INSTRUMENT_NAME": "Sijoituskohde",
      "RISK_DISTRIBUTION_BY_ASSET_TYPE": "Riskijakauma omaisuusluokittain",
      "RISK_DISTRIBUTION_BY_CLASSIFICATION": "Riskijakauma omaisuusalaluokittain",
      "RISK_DISTRIBUTION_BY_INSTRUMENT": "Riskijakauma sijoituskohteittain",
      "PORTION": "Osuus",
      "BELOW": "alle 0.01 %",

      // Riskiluvut
      "VOLATILITY": "Volatiliteetti",
      "SHARPEN_RATIO": "Sharpen luku",
      "BETA": "Beta",
      "R2": "R2",
      "TRACKING_ERROR": "Tracking error",
      "INFORMATION_RATION": "Informaatiosuhde",
      "READ_MORE_ABOUT_RISK_NUMBERS": "Lue lisää riskiluvuista",
      "ADDITIONAL_INFORMATION_ABOUT_RISK_NUMBERS": "Lisätietoa riskiluvuista",
      "VOLATILITY_INFO": "Volatiliteetti mittaa sijoituksen tuoton keskimääräistä vaihtelua eli keskihajontaa. Mitä suurempi volatiliteetti on, sitä enemmän sijoituksen arvo on vaihdellut.",
      "SHARPEN_RATIO_INFO": "Sharpen luku mittaa sijoituksen riskikorjattua tuottoa. Se lasketaan suhteuttamalla riskittömän tuoton ylittävä osuus sijoituksen volatiliteettiin eli riskiin. Positiivinen luku kertoo kannattavasta riskinotosta ja negatiivinen luku riskitöntä korkoa huonommasta tuotosta.",
      "BETA_INFO": "Beta mittaa sijoituksen arvon herkkyyttä muutoksille vertailuindeksin arvossa. Esimerkiksi jos sijoituksen beta on 0.6 ja vertailuindeksin arvo muuttuu 1%:lla, muuttuu sijoituskohteen arvo keskimäärin 0.6% samaan suuntaan.",
      "R2_INFO": "Selitysarvo R2 kuvaa sijoituksen arvon muutoksen prosenttiosuutta, joka on selitettävissä vertailuindeksin muutoksilla. Matala R2 kertoo sijoituksen arvon kehittyvän verraten itsenäisesti suhteessa vertailuindeksiin.",
      "TRACKING_ERROR_INFO": "Tracking Error mittaa sijoituksen aktiivista riskiä eli kuinka tarkasti sijoituksen tuotto seuraa vertailuindeksinsä tuottoa. Korkea tracking error tarkoittaa sijoituksen tuoton vaihtelevan voimakkaasti suhteessa vertailuindeksin tuottoon. Vastaavasti matala tracking error kertoo sijoituksen tuoton seuraavan vertailuindeksin tuottoa.",
      "INFORMATION_RATION_INFO": "Informaatiosuhde mittaa sijoituksen ja vertailuindeksin tuottoeron suhdetta sijoituksen tracking erroriin. Tunnusluku kertoo kuinka paljon vuotuista lisätuottoa on saatu suhteessa otettuun aktiiviseen riskiin.",
      "SHOWN_PERIOD": "Näytetään viimeinen vuosi valitun ajanjakson päättymispäivästä.",
      "INDEX": "Indeksi",
      "RISK_STATISTICS_DISCLAIMER": "Riskiluvut on laskettu raportilla raportoitavan ajanjakson viimeisen päivän tietojen perusteella, tai mikäli kyseiseltä päivältä ei ole saatavissa tietoja, viimeisimmän kyseistä päivää edeltävän pankkipäivän tietojen perusteella.",
      "LOADING_RISK_VALUES": "Ladataan riskilukuja",

      // Yleisiä
      "CUSTOMER": "Asiakas",
      "REPORT_CURRENCY": "Raportointivaluutta",
      "TIME_SPAN": "Ajanjakso",
      "REPORT_CURRENCY_EUR": "EUR",
      "VALUES_IN_INSTRUMENT_CURRENCY_IN_PARENTHESES": "arvopaperin valuutassa suluissa",
      "PORTFOLIO_SUMMARY": "Salkkuyhteenveto",
      "SUMMARY": "Yhteenveto",
      "CAPITA_GAIN_AND_LOSS": "Luovutusvoitot ja -tappiot (9a)",
      "ACCOUNT_STATEMENT": "Tiliote",
      "SELECTED_PORTFOLIO": "Tarkasteltava salkku",
      "TIME_PERIOD": "Näytettävä ajanjakso",
      "3_MONTHS": "Kolme kuukautta",
      "6_MONTHS": "Kuusi kuukautta",
      "A_YEAR": "Vuosi",
      "CURRENT_YEAR": "Vuoden alusta",
      "SINCE_BEGINNING": "Toiminnan alusta",
      "SELECT_DATES": "Valitse päivämäärät",
      "ADJUSTED_DATES": "Mukautettu",
      "PORTFOLIO_EVENTS_BEFORE_2017_FIRST": "Valitulle salkulle tapahtumat ovat väliaikaisesti saatavilla verkkopalvelussa alkaen ",
      "PORTFOLIO_EVENTS_BEFORE_2017_SECOND": ". Tätä aikaisemmat tapahtumat tulevat saataville mahdollisimman pian."
  },

  // todo: remove this when history data is available
  "HISTORY_DATA_NOT_AVAILABLE": "Historiadataa ennen päivää 1.1.2016 ei vielä saatavilla.",


  // New asset subclasses in onefactor (Real Assets)
  "Land and Forest": "Maa- ja metsäomaisuus",
  "Finland": "Suomi",
  "Low Risk 25/75": "Matala riski 25/75",
  "Neutral Risk 50/50": "Neutraali riski 50/50",
  "High Risk 75/25": "Korkea riski 75/25",


  // Statement transaction types
  "Service Fee": "Palkkio",
  "Asset Management Fee": "Varainhoitopalkkio",
  "Asset Management Fee2": "Varainhoitopalkkio",
  "Return of Capital": "Pääomanpalautus",
  "Installment": "Velkakirjan erääntyminen",
  "Interest": "Korko",
  "Fund Subscription Fee": "Merkintäpalkkio",
  "Fund Subscription": "Oston veloitus",
  "Fund Redemption": "Myynnin talletus",
  "Transfer in": "Siirto",
  "Transfer In": "Siirto",
  "Transfer out": "Siirto",
  "Transfer Out": "Siirto",
  "Transfer Deposit": "Siirto",
  "Transfer Withdraw": "Siirto",
  "Security Buy": "Oston veloitus",
  "Security Sell": "Myynnin talletus",
  "Custody Fee": "Hallussapitopalkkio",
  "Coupon Interest": "Kupongin maksu",
  "Customer Withdraw": "",
  "Customer Deposit": "",
  "Withdraw": "",
  "Deposit": "",
  "Correction": "Oikaisu",
  "Interest Debit": "Korkoveloitus",
  "Internal Money Withdraw": "Sisäinen rahan nosto",
  "Bank Costs": "Pankkikulut",
  "Fund Transaction Fee": "Rahaston tapahtumamaksu",
  "Payment / Management Fee": "Maksu-/hallinnointipalkkio",
  "Custody Transaction Fee": "Säilytyksen tapahtumamaksu",
  "Payment / Subscription Net": "Maksu/-merkintä netto",
  "Dividend Payout": "Osinkotuotto",
  "Fund Management Fee": "Rahaston hoitomaksu",
  "Tax Reclaim": "Verohyvitys",
  "Expiration Sell": "Erääntymismyynti",
  "Payment / Redemption Net": "Maksu/lunastus netto",
  "Internal Money Deposition": "Sisäinen rahan talletus",
  "Fee Rebate": "Palkkiohyvitys",


  "NOT_APPROPRIATE_INVESTMENT_FOR_USER": "Antamiesi sijoituskokemusta ja -tietämystä koskevien tietojen perusteella katsomme, että tämä rahoitusväline ei välttämättä ole sinulle asianmukainen. Jos tästä huolimatta haluat jatkaa toimeksiannon tekemistä, sinun tulee ottaa huomioon, että rahoitusväline saattaa sisältää sinulle tuntemattomia riskejä, joiden seurauksia et välttämättä pysty arvioimaan.",

  "FEE_INFO_HEADER": "Sijoituspalvelulain 10. luvun mukaiset ennakkotiedot kuluista, kannustimista ja liitännäisten veloituksista",
  "FEE_INFO_INTRO": "Seuraavat tiedot perustuvat kohtuullisiin oletuksiin, ja lopulliset kulut voivat poiketa tosiasiallisesti aiheutuvista kuluista ja veloituksista. Tiedot perustuvat Elite Alfred Bergin luotettavina pitämiin lähteisiin. Tiedot voivat perustua osittain tai kokonaan kolmannen osapuolen toimittamiin tietoihin. Elite Alfred Berg ei vastaa kolmannelta osapuolelta saadun tiedon oikeellisuudesta. Alla esitetyt tiedot koskevat asiakirjan antamisaikana vallinnutta tilannetta, ja tiedot voivat tai ovat voineet muuttua tämän jälkeen. Todelliset kulut raportoidaan asiakkaalle vuosittain jälkikäteen. Tämä ei ole markkinointimateriaalia, vaan sääntelyn edellyttämiä tietoja ja sinun tulee perehtyä materiaaliin huolellisesti, jotta voit tehdä sijoituspäätöksen.",
  "FEE_INFO_REGARDING_INSTRUMENTS": "Ennakkotiedot koskevat seuraavia palveluita ja rahoitusvälineitä:",
  "FEE_INFO_OFFERED_INSTRUMENT": "Tarjottu sijoituspalvelu / rahoitusväline",
  "FEE_INFO_INVESTED_AMOUNT": "Sijoitettava määrä (EUR):",
  "FEE_INFO_INVESTED_AMOUNT_NO_EUR": "Sijoitettava määrä",
  "FEE_INFO_SUMMARY_OF_FEES": "Yhteenveto kuluista",
  "FEE_INFO_INVESTMENT_SERVICE_FEES": "Sijoitus- ja oheispalvelun tarjoamiseen liittyvät kulut",
  "FEE_INFO_FUND_FEES": "Rahoitusvälineisiin liittyvät kulut",
  "FEE_INFO_TOTAL_FEE": "Yhteismäärä",
  "FEE_INFO_INCENTIVES": "Kannustimet",
  "FEE_INFO_THIRD_PARTY_INCENTIVES": "Kolmannelta osapuolelta saadut maksut (kannustimet)",
  "FEE_INFO_INCENTIVES_DISCLAIMER": "Kannustimien vastaanotto täyttää sääntelyn edellyttämän laadunparannuskriteerin, eikä niitä näin ollen ole velvollisuus siirtää asiakkaalle.",
  "FEE_INFO_DETAILS": "Erittely",
  "FEE_INFO_EAB_FEES": "EAB Group -konsernin veloittamat kulut",
  "FEE_INFO_THIRD_PARTY_FEES": "Kolmannen osapuolen veloittamat kulut",
  "FEE_INFO_FEE_COLLECTION": "Kulujen veloitus",
  "FEE_INFO_ONE_TIME_FEES": "1. Kertaluonteiset kulut ja veloitukset (avausmaksut, lopetusmaksut, vaihtokulut, siirtokulut)",
  "FEE_INFO_CONTINUOUS_FEES": "2. Jatkuvaluonteiset kulut (säilytysmaksut, omaisuudenhoitopalkkio, neuvontamaksu)",
  "FEE_INFO_TRANSACTION_FEES": "3. Suoritettuihin liiketoimiin liittyvät muut kulut",
  "FEE_INFO_SUBSCRIPTION_FEE": "Merkintäpalkkio",
  "FEE_INFO_DURING_SUBSCRIPTION": "Merkinnän yhteydessä",
  "FEE_INFO_REDEMPTION_FEE": "Lunastuspalkkio",
  "FEE_INFO_DURING_REDEMPTION": "Lunastuksen yhteydessä",
  "FEE_INFO_SUBSCRIPTION_REDEMPTION_CURRENCY_EXCHANGE_FEES": "Merkinnän ja lunastuksen valuuttakaupan kulut",
  "FEE_INFO_INCLUDED_IN_PRICES": "Sisältyvät toteutuneisiin kauppahintoihin tai veloitetaan asiakkaan varoista kaupan yhteydessä",
  "FEE_INFO_OTHER_TRANSACTION_FEES": "Suoritettuihin liiketoimiin liittyvät muut kulut",
  "FEE_INFO_OTHER_SERVICE_FEES": "4. Oheispalveluihin liittyvät kulut",
  "FEE_INFO_MISCELANEOUS_FEES": "5. Muut satunnaiset kulut (mahdolliset tuottosidonnaiset palkkiot)",
  "FEE_INFO_INSTRUMENT_RELATED_FEES": "Rahoitusvälineisiin liittyvät kulut",
  "FEE_INFO_INCLUDED_IN_INSTRUMENT_PRICE": "sisältyy rahoitusvälineen hintaan, ei erillistä veloitusta",
  "FEE_INFO_OTHER_INSTRUMENT_RELATED_FEES": "4. Oheispalveluihin liittyvät kulut (säilytysmaksut)",
  "FEE_INFO_CUMULATIVE_FEES_HEADER": "Kuvaus kulujen kumulatiivisesta vaikutuksesta tuottoon",
  "FEE_INFO_CUMULATIVE_FEES_INTRO1": "Alla on esitetty esimerkkejä kulujen vaikutuksesta tuottoon. Kustakin rahastosta vähennettävät palkkiot vaikuttavat koko sijoituksen tuottoon. Vaikka tuottoa ei voi taata, voimme esittää esimerkkejä siitä, miten kulut vaikuttavat sijoitustoiminnan lopputulokseen. Historiallisen tuoton perusteella ei voi ennustaa tulevia tuottoja. Sijoitusten arvo voi sekä nousta että laskea ja sijoittaja voi saada takaisin alkuperäistä sijoitusta alhaisemman summan.",
  "FEE_INFO_CUMULATIVE_FEES_INTRO2": "0 %: nettotuotto-odotus on vain esimerkinomainen, ei sitova.",
  "FEE_INFO_ANNUAL_NET_RETURN": "Vuotuinen nettotuotto:",
  "FEE_INFO_TOTAL_CUMULATIVE_FEES": "Jatkuvaluonteiset kulut yhteensä:",
  "FEE_INFO_CUMULATIVE_EFFECT_OF_FEES": "Kulujen kumulatiivinen vaikutus tuottoon",
  "FEE_INFO_YEAR1": "Vuosi 1",
  "FEE_INFO_YEAR2": "Vuosi 2",
  "FEE_INFO_YEAR3": "Vuosi 3",
  "FEE_INFO_YEAR4": "Vuosi 4",
  "FEE_INFO_YEAR5": "Vuosi 5",
  "FEE_INFO_VALUE_OF_INVESTMENT_AT_YEAR_END": "Sijoituksen arvo vuoden lopussa ilman kuluja",
  "FEE_INFO_VALUE_OF_INVESTMENT_WITH_FEES_AT_YEAR_END": "Sijoituksen nostoarvo vuoden lopussa kulut huomioiden",
  "FEE_INFO_CUMULATIVE_FEE_EFFECT_ON_RETURNS": "Kulujen kumulatiivinen vaikutus tuottoon",
  "FEE_INFO_CUMULATIVE_FEE_EFFECT_ON_RETURN_ANNUALLY": "Kulujen kumulatiivinen vaikutus tuottoon vuotuisena laskettuna",
  "FEE_INFO_BACK": "Takaisin",
  "MONDAYTOFRIDAY": "ma–pe",
  "LOADING_CONFIRMATION": "Ladataan toimeksiannon vahvistussivua",

  // Customer Service section
  "CUSTOMERSERVICE": {
      "TITLE": "Asiakaspalvelu",
      // "SECTION": {
      //     "CUSTOMERSERVICE": "Asiakaspalvelu",
      //     "SUMMARY": "Asiakaspalvelu",
      //     "MESSAGES": "Viestit",
      //     "DOCUMENTS": "Asiakirjat",
      //     "ANNOUNCEMENTS": "Tiedotteet",
      //     "FEEDBACK": "Palaute",
      //     "INSTRUCTIONS": "Ohjeet",
      //     "CONTACTS": "Yhteystiedot"
      // },
      "SUMMARY": {
          "TITLE": "Asiakaspalvelu",
          "MESSAGES": {
              "TITLE": "Viestit",
              "NOTIFICATION1": "Sinulle on",
              "NOTIFICATION2": "uusi viesti verkkopalvelussamme.",
              "NOTIFICATION3": "uutta viestiä verkkopalvelussamme.",
              "NOTIFICATION4": "Sinulle ei ole uusia viestejä.",
              "INSTRUCTION": "Lähesty meitä verkkoviestillä säästämiseen, sijoittamiseen ja varainhoitoon liittyvissä asioissa. Autamme sinua mahdollisimman pian!",
              // DONE lisätty: 23.11.2021
              "SEE_ALL": "Lue kaikki viestit"
          },
          "ANNOUNCEMENTS": {
              "TITLE": "Uusimmat tiedotteet",
              // DONE lisätty: 24.11.2021
              "SEE_ALL": "Lue kaikki tiedotteet"
          },
          "FEEDBACK": {
              "TITLE": "Jätä palautetta",
              "GIVEFEEDBACK": "Onnistuimeko tarjoamaan hyvää palvelua? Jäikö jotakin kehitettävää? Anna meille palautetta toiminnastamme",
              "REPORTPROBLEM": "Kohtasitko ongelman käyttäessasi Elite Alfred Bergin verkkopalvelua? Kerrothan siitä meille, niin ratkaisemme sen."
          },
          "CONTACTS": {
              "TITLE": "Yhteystiedot"
          },
          "INSTRUCTIONS": {
              "TITLE": "Ohjeet"
          }
      },
      "MESSAGES": {
          "TITLE": "Viestit",
          "MESSAGES": {
              "TITLE": "Viestit",
              "INSTRUCTION": "Täältä näet sinulle saapuneet viestit ja viestiketjut. Kun asiallasi on kiire, otathan yhteyttä puhelimitse asiakaspalveluumme 0201 558 610",
              "LOADING": "Ladataan viestejä",
              // DONE lisätty 20.12.2021
              "UPDATING": "Päivitetään viestejä"
          },
          "NEWMESSAGE": {
              "TITLE": "Kirjoita uusi viesti",
              "INSTRUCTION": "Kirjoita viesti asiakaspalveluumme tai omalle pankkiirillesi. Olemme sinuun yhteydessä mahdollisimman pian.",
              "SUBJECT": "Viestin aihe",
              "RECEIVER": "Vastaanottaja",
              "SELECTRECEIVER": "Valitse vastaanottaja",
              "MESSAGETITLE": "Otsikko",
              "MESSAGE": "Viesti",
              "OR": "TAI",
              "MORE": "Lisää",
              "DRAGDROP": "Raahaa liitetiedosto tähän",
              "LOADING_ATTACHMENT_FILE": "Ladataan liitetiedostoa",
              "MESSAGEATTACHMENTINSTRUCTION": "Lataa liitetiedosto omista tiedostoistasi.",
              "ATTACHMENTMAXMIMUMSIZE": "Liiteen suurin sallittu koko on 20Mb.",
              "SENDMESSAGEBUTTON": "Lähetä viesti",
              "RECEIVEDMESSAGES": "Viestit",
              "SENDINGNEWMESSAGE": "Lähetetään viestiä",
              "MESSAGESENDSUCCESFUL": "Viestin lähetys onnistui. Palataan viestit listaan.",
              // DONE lisätty 26.11.2021
              "MESSAGESENDSUCCESFUL_SHORT": "Viestin lähetys onnistui!",
              // DONE lisätty 30.11.2021
              "RETURN_TO_MESSAGES": "Takaisin viesteihin",

              "SENDINGFAILED": "Viestin lähettäminen epäonnistui, yritä uudelleen.",
              "MESSAGEPLACEHOLDER": "Kirjoita viestisi tähän",
              "BANKER": "Pankkiiri",
              "TOO_LONG_SUBJECT": "Antamasi otsikko on liian pitkä",
              "TOO_LONG_MESSAGE": "Antamasi viesti on liian pitkä",
              // DONE lisätty 1.12.2021
              "MAX_LENGTH": "Maksimipituus",
              "CHARACTERLIMIT": "merkkiä",
              "RESPOND_TO_MESSAGE": "Vastaa viestiin",

              "DISCLAIMER": "Huomaathan, että tämän toiminnon kautta lähetetyllä viestillä ei voi tehdä toimeksiantoa. Voit kuitenkin pyytää viestitse, että olemme sinuun yhteydessä toimeksiannon tekemiseksi.",
              "DRAGDROPNOTSUPPORTED": "Tämä selain ei tue tiedostojen raahausominaisuutta",
              "ADDINGATTACHMENTFAiLED": "Liitteen lisääminen epäonnistui. Liite saattaa olla tiedostotyyppiä, jota ei ole sallittu tai sen koko on liian suuri.",
          },
          "MODAL": {
              "MESSAGE_NOT_FINISHED": "Viestin kirjoittaminen on kesken",
              "LEAVE_MESSAGE_PAGE_CONFIRMATION": "Viestin kirjoittaminen on kesken. Haluatko varmasti poistua sivulta?",
              "STAY_ON_PAGE": "Jää sivulle",
              "LEAVE_PAGE": "Poistu"
          }
      },
      "DOCUMENTS": {
          "TITLE": "Asiakirjat",
          "SEARCHINSTRUCTION": "Hae asiakirjoista...",
          "FILTER": "Rajaa asiakirjoja",
          "DOWNLOAD": "Lataa tiedosto",
          "LOADING": "Ladataan asiakirjoja",
          "NOCONTENT": "Asiakirjoja ei löytynyt."
      },
      "ANNOUNCEMENTS": {
          "TITLE": "Tiedotteet",
          "SEARCHINSTRUCTION": "Hae tiedotteista...",
          "FILTER": "Rajaa tiedotteita",
          "LOADING": "Ladataan tiedotteita",
          // DONE lisätty 20.12.2021
          "UPDATING": "Päivitetään tiedotteita",

          "NOCONTENT": "Tiedotteita ei löytynyt",
          "NONEWCONTENT": "Ei uusia tiedotteita",
          // DONE lisätty: 23.11.2021
          "OPEN_ANNOUNCEMENT": "Avaa tiedote",
      },
      "FEEDBACK": {
          "TITLE": "Palaute",
          "GIVEFEEDBACK": {
              "TITLE": "Jätä palautetta",
              "INSTRUCTION_MAIN": "Onnistuimmeko tarjoamaa hyvää palvelua? Jäikö jotakin kehitettävää? Anna meille palautetta toiminnastamme ja vastaamme sinulle halutessasi mahdollisimman pian.",
              "INSTRUCTION_SECONDARY": "Mikäli sinulla on ollut ongelmia Elite Alfred Bergin verkkopalveluun liittyen, kerro siitä täällä",
              "SUBJECT": "Palautteen aihe",
              "TYPE": "Palautteen tyyppi",
              "FEEDBACK": "Palaute",
              "CONTACTTYPE": "Haluan että minuun otetaan yhteyttä"
          },
          "REPORTPROBLEM": {
              "TITLE": "Ilmoita ongelmasta",
              "INSTRUCTION": "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
          },
      },
      "INSTRUCTIONS": {
          "TITLE": "Ohjeet",
      },
      "CONTACTS": {
          "TITLE": "Yhteystiedot",
          "BANKERINSTRUCTION": "Jäikö kysyttävää salkkuusi liittyen? Oma pankkiirisi palvelee sinua.",
          "CUSTOMERSERVICEINSTRUCTION": "Ole yhteydessä asiakaspalveluumme, kun jokin mietityttää.",
          "MEMBERSERVICEINSTRUCTION": "Kun asiasi koskee henkilöstö- ja palkkiorahastoa.",
          "LOADING": "Ladataan yhteystietoja",
          "YOUR_BANKER": "Oma pankkiirisi"
      },
      "DASHBOARD": {
          "TITLE": "Asiakaspalvelu",
      },
      "MONTHLYSAVINGS": {
          "TITLE": "Säästösopimukset",
          "SAVING_CONTRACTS_DESCRIPTION": "Täällä voit tarkastella tekemiäsi säästösopimuksia. Otathan yhteyttä asiakaspalveluumme, mikäli haluat tehdä muutoksia sopimukseen tai päättää sen.",
          "FOR_WHOM": "Kenelle säästetään?",
          "CONTRACT_IN_FORCE": "Sopimus voimassa",
          "CONTRACT_TERMS": "Sopimusehdot",
          "SAVING_CONTRACT_TERMS": "Säästösopimusehdot",
          "IN_FORCE": "Voimassa",
          "TERMINATED": "Lopetettu",
          "SEND_MESSAGE": "Lähetä viesti",
          "LOADING_CONTRACTS": "Ladataan säästösopimuksia",
          "NO_CONTRACTS_FOUND": "Sinulla ei ole yhtään säästösopimusta",
          "TO_SELF": "Itselle"
      },
      "GO_TO_PERSONAL_SETTINGS": "Siirry omiin tietoihin ja asetuksiin"
  },
  "BUYFUNDPIPE": {
      "MONEYTRANSFERSUCCESSFUL": "Rahan tallettaminen salkulle onnistui. Rahat ovat heti käytössäsi ostotoimeksiantoja varten.",
      "MONEYTRANSFERFAILED": "Rahan tallettaminen salkulle epäonnistui.",
      "MONEYTRANSFERINFOLINK": "Katso tiedot talletuksesta",
      "NORIGHTSTOCREATEAPORTFOLIO": "Sinulla ei ole oikeuksia luoda uutta salkkua kyseiselle asiakkuudelle",
      "SELF_SERVICE_PORTFOLIO_NEEDED": "Ostaaksesi rahastoa sinulla tulee olla omatoimisalkku.",
      "CREATE_SELF_SERVICE_PORTFOLIO_HERE": "Voit luoda sen tässä. Halutessasi voit antaa omatoimisalkulle nimen.",
      "PLEASE_CONTACT": "Otathan tarvittaessa yhteyttä ",
      "CUSTOMER_SERVICE": "asiakaspalveluun",
      "LOGIN": {
          "BUYINGFUND": "Olet tekemässä ostotoimeksiantoa rahastoon",
          "NEEDTOBEACUSTOMER": "Ostaaksesi rahastoa sinun tulee olla Elite Alfred Bergin asiakas ja kirjautua sisään verkkopalvelun käyttäjätunnuksilla",
          "HAVECREDENTIALS": "Olen jo asiakas", //"Minulla on tunnukset",
          "NEWCUSTOMER": "Olen uusi asiakas",
          "BACK": "Takaisin",
          "LOGINWITHTUPAS": "Tule asiakkaaksi tunnistautumalla.",
          "CHOOSEBANK": "Valitse pankkisi",
          "LOGINTOBUYFUND": "Kirjaudu sisään jatkaaksesi rahaston ostoa.",
          "LOGIN": "Kirjaudu",
          "LOADINGINFORMATION": "Ladataan tietoja",
          "SIGNINNOW": "Voit nyt kirjautua sisään jatkaaksesi rahaston ostoa.",
          "IDENTIFYTOCONTINUE": "Tunnistaudu jatkaaksesi toimeksiantoa.",
          "IDENTIFYCONSENT": "Valitsemalla tunnistuksen toteuttajan allaolevasta valikosta (pankki tai mobiilivarmenne) hyväksyn sen, että Signicat tunnistusvälityspalvelu välittää tunnistusta varten henkilötietoni (hetu, nimi) pankilta tai mobiilivarmenteen tarjoajalta palvelun tarjoamista varten.",
          "IDENTIFICATIONPRINCIPLES": "Tunnistusperiaatteet",
      }
  },
  "NEWPORTFOLIO": {
      "NEWPORTFOLIO": "Luo uusi salkku",
      "NEWPORTFOLIOSHORT": "Uusi salkku",
      "CHOOSEACCOUNT": "Valitse asiakkuus",
      "CHOOSECUSTOMER": "Valitse asiakkuus, jolle salkku luodaan",
      "CREATENEWPORTFOLIOHERE": "Täällä voit luoda uuden omatoimisalkun.",
      "NEWPORTFOLIONAME": "Anna salkulle nimi",
      "VOLUNTARY": "Vapaaehtoinen",
      "CREATEPORFOLIO": "Luo salkku",
      "CREATEPORFOLIONOTFINISHED": "Salkun luonti kesken",
      "EXITFROMPORTFOLIOCREATION": "Oletko varma, että haluat poistua salkun luonnista ennen kuin uusi salkku on luotu?",
      "PORTFOLIOCREATED": "Uusi salkku ",
      "SUCCESSFULLYFORCUSTOMER": " luotiin onnistuneesti asiakkaalle ",
      "PORTFOLIOCREATIONFAILED": " Salkun luonti epäonnistui",
      "NOSUITABLEACCOUNTS": "Valitettavasti yhtään soveltuvaa asiakkuutta ei löytynyt.",
      "PLEASE_CONTACT": "Otathan tarvittaessa yhteyttä ",
      "PLEASE_CONTACT_2": "",
      "CUSTOMER_SERVICE": "asiakaspalveluun",
      "CREATINGPORTFOLIO": "Luodaan salkkua",
      "GOINGBACKTOBUYPAGE": "Siirrytään hetken kuluttua automaattisesti takaisin osta rahastoa-sivulle.",
      "GOINGBACKTOBROWSEORDERS": "Siirrytään hetken kuluttua automaattisesti takaisin toimeksiantojen etusivulle.",
      "CREATENEWPORTFOLIOLINK": "+ Luo uusi salkku",
  },
  "BANKER": {
    "WELCOME_TO_BANKER_VIEW": "Tervetuloa pankkiirinäkymään",
    "WELCOME_TO_BANKER_VIEW_INFO": "Jatka joko omaan verkkopalveluun tai asiakkaan salkun tarkasteluun.",
    "OWN_WEB_SERVICE": "Oma verkkopalvelu",
    "CUSTOMER_PORTFOLIOS": "Asiakkaiden salkut",
    "SELECT_CUSTOMER": "Valitse asiakas",
    "SEARCH_FOR_PERSON": "Etsi henkilöä",
    "SEARCH_WITH_KEYWORD": "Etsi hakusanalla",
    "CUSTOMER_NAME": "Asiakkaan nimi",
    "SOCIAL_SECURITY_NUMBER": "Henkilötunnus",
    "ACCOUNT_NUMBER": "Asiakasnumero",
    "BROWSE_CUSTOMERS": "Selaa asiakkaita",
    "NO_SEARCH_RESULTS": "Ei hakutuloksia",
    "BANKER_VIEW": "Pankkiirinäkymä",
    "CHANGE_USER_INFO": "Voit vaihtaa käyttäjää kirjautumalla uudelleen sisään palveluun."
  },
  "FUND_OPTIONS" : {
    "TITLE": "Rahastot",
    "DESCRIPTION1": "Elite Alfred Bergin sijoitusrahastoihin voit säästää säännöllisesti tai tehdä kertasijoituksen helposti ja vaivattomasti. Valikoimastamme löydät osake-, korko- ja kiinteistörahastot sekä vaihtoehtoiset sijoitusratkaisut. Tarjoamme asiakkaillemme omien rahastoratkaisujemme lisäksi valittujen yhteistyökumppaneidemme vaihtoehtoja maailmanlaajuisesti.",
    "DESCRIPTION2": "Rahastosijoittajana sinun ei tarvitse seurata markkinoiden ja yhtiöiden tapahtumia niin aktiivisesti kuin osakesijoittajan. Rahastojen asiantuntijat seuraavat markkinoita puolestasi ja tekevät tarvittavat muutokset. Rahastosijoittaminen on myös hyvä keino hajauttaa sijoituksia helposti esimerkiksi ulkomaille tai kohteisiin, joihin yksityissijoittajan on mahdotonta muuten sijoittaa suoraan.",
  },
  "ARIA": {
      // Navigation bar
      "LOGO_IMAGE": "Linkki etusivulle. Kuva Elite Alfred Berg logosta",
      "CHANGE_LANGUAGE_FIN": "Vaihda kieleksi Suomi",
      "CHANGE_LANGUAGE_SWE": "Vaihda kieleksi Ruotsi",
      "CHANGE_LANGUAGE_ENG": "Vaihda kieleksi Englanti",
      "GO_TO_WEBPAGE": "Siirry verkkosivuille",
      "LANGUAGE_SELECTION": "Kielivalinta",
      "LOGOUT": "Kirjaudu ulos",
      "ONLINE_SERVICE": "Verkkopalvelu",
      "PERSONNEL_FUNDS": "Henkilöstö- ja palkkiopalvelut",
      "MAIN_NAVIGATION": "Päänavigaatio alue",
      // Lisätty 16.11.2021
      "SWITCH_TO_SERVICE": "Vaihda palveluun",
      // Landing and sign in pages
      "ONLINE_SERVICE_PICTURE": "Verkkopalveluiden otsikkokuva. Siirry verkkopalveluiden kirjautumissivulle",
      "PERSONNEL_FUNDS_PICTURE": "Henkilöstö- ja palkkiopalveluiden otsikkokuva. Siirry henkilöstö- ja palkkiopalveluiden kirjautumissivulle",
      "ARROW_RIGHT": "Nuoli oikealle",
      "GO_TO_SIGN_IN_OS": "Siirry Verkkopalvelun kirjautumissivulle",
      "GO_TO_SIGN_IN_HRJ": "Siirry Henkilöstö- ja palkkiopalveluiden kirjautumissivulle",
      "GO_TO_SIGN_IN_SEB": "Siirry SEB-palvelun kirjautumissivulle",
      "SIGN_IN_TAB": "Avaa kirjaudu sisään tunnuksillasi näkymä",
      "BECOME_MEMBER_TAB": "Avaa tule asiakkaaksi näkymä",
      "FORGOT_PASSWORD_POPUP": "Unohditko tunnukset pop-up ikkuna",
      "CLOSE_POPUP": "Sulje pop-up ikkuna",
      "USERNAME_FIELD": "Anna käyttäjätunnus",
      "PASSWORD_FIELD": "Anna salasana",
      "IDENTIFY_ONLINE": "Tunnistaudu verkkotunnistautumisella",
      "LOG_IN": "Kirjaudu sisään",
      "LOG_IN_PERSONNEL_FUNDS": "Kirjaudu sisään henkilöstö- ja palkkiopalveluihin",
      "CHOOSE_LOGIN_ACCOUNT": "Valitse käyttäjä tunnus, jolla kirjautuminen tehdään",
      // Footer
      "FOOTER": "Alatunniste osio",
      "FOOTER_INFO": "Yritysinfo",
      "FOOTER_CUSTOMER_SERVICE": "Asiakaspalvelun info",
      "FOOTER_HRJ_CUSTOMER_SERVICE": "Henkilöstö- ja palkkiopalveluiden asiakapalvelun info",
      "FOOTER_CALL_PRICES": "Puheluhinnat info",
      "MA-PE": "Tavoitettavissa maanantaista perjantaihin",
      // front page
      "DATE": "Valittu ajanjakso",
      "SELECT_DATE_WITH_CALENDER": "Valitse ajanjakso kalenterin avulla",
      "SELECTED_PORTFOLIOS": "Valitut salkut",
      "CLEAR_SEARCH_STRING": "Tyhjennä hakuteksti",
      "LARGEST_INV_TABLE": "Suurimmat omistukset -taulukko",
      "ASSETS_SUMMARY_TABLE": "Taulukko varallisuuden yhteenvedosta",
      "CONTACT_PERSON": "Yhteyshenkilö",
      "PHONE_NUMBER": "Puhelinnumero",
      "EMAIL": "Sähköposti",
      "VALUE_HISTORY_GRAPH": "Kuvaaja valittujen salkkujen arvonkehityksestä. Kuvaajassa näkyy salkkujen päivittäiset arvot valitulla ajanjaksolla ja niistä muodostuu jatkuva arvonkehityskäyrä.",
      "INDEXED_PROFITS_GRAPH": "Kuvaaja valitun salkun varallisuuden indeksoidusta tuottokehityksestä. Kuvaajassa näkyy salkkun päivittäiset indeksoidut tuottoarvot valitulla ajanjaksolla ja niistä muodostuu jatkuva tuottokehityskäyrä.",
      "DOWNLOAD_FUND": "Lataa rahaston",
      "MONTHLY_REPORT": "kuukausikatsaus pdf-tiedostona",
      "GO_TO_FUNDS": "Siirry rahaston",
      "TO_FUND_PAGE": "rahastosivulle",
      "SELECT_DATE_LIST": "Ajanjakson valinta lista",
      // Own portfolios
      "OWN_PORTFOLIOS_NAV": "Omat salkkuni sivujen navigaatiolista",
      "SHOW_CUSTOMER_INFO": "Näytä asiakkaan tiedot",
      "CUSTOMER_INFO_SHOWING": "Tiedot näkyvissä",
      "CUSTOMER_INFO_HIDDEN": "Tiedot piiloitettu",
      "CUSTOMER_INFO_ARE": "Asiakkaan tiedot alue",
      "CHANGE_PORTFOLIO_NAME": "Muokkaa salkun nimeä",
      "RETURN_SUMMARY_TABLE": "Tuottoyhteenveto-taulukko",
      "ASSET_CHANGES_TABLE": "Varallisuuden muutos -taulukko",
      "RISK_DISTRIBUTION_INST_TABLE": "Riskijakauma sijoituskohteittain -taulukko",
      "RISK_STATISTICS_TABLE": "Riskiluvut-taulukko",
      "RISK_STATISTICS_POPUP": "Lisätietoa riskiluvuista pop-up ikkuna",
      "DOWNLOAD_PORTFOLIO_SUMMARY_REPORT": "Lataa salkun koontiraportti excel-tiedostona",
      "HOLDINGS_RISK_ASSET_GRAPH": "Kuvaaja valitun salkun omistuksien riskijakaumasta omaisuusluokittain. Kuvaajassa näkyy salkun omistuksien tyypit omaisuusluokittain ja niiden riskiluvut.",
      "HOLDINGS_RISK_CLASS_GRAPH": "Kuvaaja valitun salkun omistuksien riskijakaumasta omaisuusalaluokittain. Kuvaajassa näkyy salkun omistuksien tyypit omaisuusalaluokittain ja niiden riskiluvut.",
      "REPORT_GROUPING": "Raportin ryhmittely menu",
      "REPORT_SHOW_PURCHASES": "Näytä ostoerittäin valinta",
      "REPORT_DATE": "Näytettävä raportointipäivämäärä",
      "SELECTED_DATE": "Valitse päivä. Valittu päivä: ",
      "SELECT_START_DATE": "Valitse aloituspäivä",
      "SELECT_END_DATE": "Valitse lopetuspäivä",
      "SHOW_CALENDAR": "Avaa kalenteri-ikkuna",
      "PORTFOLIO_REPORT_DOWNLOAD_PDF": "Lataa salkkuraportti pdf-tiedostona",
      "PORTFOLIO_REPORT_DOWNLOAD_EXCEL": "Lataa salkkuraportti Excel-tiedostona",
      "PORTFOLIO_REPORT_TABLE": "Salkkuraportti-taulukko",
      "INFORMATION_THROUGH_INVESTMEN_TABLE": "Tiedot koko sijoituksen ajalta taulukko",
      "SHOW_PORTFOLIO_REPORT_INFO_TABLE": "Avaa arvopaperin lisätiedot-taulukko",
      "HIDE_PORTFOLIO_REPORT_INFO_TABLE": "Piilota arvopaperin lisätiedot-taulukko",
      "PORTFOLIO_REPORT_INFO_TABLE": "Arvopaperin lisätiedot-taulukko",
      "TRANSACTIONS_REPORT_TABLE": "Tapahtumaraportti-taulukko",
      "PROFITS_REPORT_PROFITS_TABLE": "Voitot ja tappiot -taulukko",
      "PROFITS_REPORT_DIVIDENDS_TABLE": "Osingot, sijoitusrahastojen tuotonmaksut ja korot -taulukko",
      "PROFITS_REPORT_EQUITY_TABLE": "Pääomarahastojen toteutuneet tuotot -taulukko",
      "PROFITS_REPORT_EQITY_TAX_TABLE": "Pääomarahastojen tiedot verotusta varten -taulukko",
      "STATEMENT_REPORT_TABLE": "Tilioteraportti-taulukko",
      "STATEMENT_REPORT_OPEN_TABLE": "Tilioteraportti avoimet rahatapahtumat-taulukko",
      "STATEMENT_REPORT_SUMMARY": "Tilioteraporttin yhteenveto",
      "TRANSACTIONS_REPORT_SUMMARY_TABLE": "Tapahtumaraportin yhteenveto -taulukko",
      "TRANSACTIONS_REPORT_DOWNLOAD_EXCEL": "Lataa tapahtumaportti Excel-tiedostona",
      "PROFITS_REPORT_PROFITS_DOWNLOAD_EXCEL": "Lataa toteutuneet tuotot -raportin voitot ja tappiot osio Excel-tiedostona",
      "PROFITS_REPORT_DIVIDENDS_DOWNLOAD_EXCEL": "Lataa toteutuneet tuotot -raportin osingot, sijoitusrahastojen tuotonmaksut osio Excel-tiedostona",
      "PROFITS_REPORT_EQUITY_DOWNLOAD_EXCEL": "Lataa toteutuneet tuotot -raportin Pääomarahastojen toteutuneet tuotot osio Excel-tiedostona",
      "PROFITS_REPORT_EQITY_TAX_DOWNLOAD_EXCEL": "Lataa toteutuneet tuotot -raportin Pääomarahastojen tiedot verotusta varten osio Excel-tiedostona",
      "STATEMENT_REPORT_DOWNLOAD_EXCEL": "Lataa tilioteraportti Excel-tiedostona",
      // Orders
      "ORDERS_NAVIGATION": "Toimeksiantojen navigointialue",
      "SELECT_ORDER_PORTFOLIO": "Valitse salkku, johon toimeksianto tehdään",
      "CHOOSE_FUND_TO_BUY": "Valitse ostettava rahasto",
      "CHOOSE_FUND_TO_SELL": "Valitse ostettava rahasto",
      "PORTFOLIO_USABLE_SUM": "Valitun salkun käytettävissä oleva saldo",
      "PORTFOLIO_SUM": "Valitun salkun saldo",
      "TOTAL_BUY_SUM": "Anna ostettava summa",
      "TOTAL_SELL_AMOUNT": "Anna myytävä määrä",
      "TOTAL_SAVE_SUM": "Säästettävä summa",
      "PURPOSE_OF_PURCHASE": "Valitse oston tarkoitus",
      "OTHER_PURPOSE": "Muu tarkoitus",
      "WRITE_OTHER_PURPOSE": "Kuvaa muu tarkoitus",
      "CANCEL_ORDER": "Peruuta toimeksianto",
      "CONFIRM_ORDER": "Siirry vahvistamaan toimeksianto",
      "CONFIRM_ORDER_BUTTON": "Vahvista toimeksianto",
      "CONFIRM_ORDER_BUTTON_DISABLED": "Vahvista toimeksianto estetty. Tarkista syötetyt tiedot.",
      "GO_TO_CREATE_PORTFOLIO": "Siirry uuden salkun luontisivulle",
      "SELECT_FREQUENCY": "Valitse säästön toistuvuus",
      "SELECT_PAYMENT_DATE": "Valitse maksupäivä",
      "RETURN_CANCEL": "Palaa takaisin",
      "CANCEL_ORDER_MODAL": "Peruuta toimeksiannon tekeminen",
      "CANCEL_WITHDRAW_MODAL": "Peruuta toimeksiannon tekeminen",
      "CANCEL_DEPOSIT_MODAL": "Peruuta toimeksiannon tekeminen",
      "CANCEL_PORTFOLIO_MODAL": "Peruuta toimeksiannon tekeminen",
      "CANCEL_ORDER_POPUP": "Toimeksiannon syöttäminen kesken pop-up ikkuna",
      "CANCEL_WITHDRAW_POPUP": "Noston tekeminen kesken pop-up ikkuna",
      "CANCEL_DEPOSIT_POPUP": "Talletuksen tekeminen kesken pop-up ikkuna",
      "CANCEL_PORTFOLIO_POPUP": "Portfolion luominen kesken pop-up ikkuna",
      "CUSTOMER_ORDERS_TABLE": "toimeksiannot-taulukko",
      "CUSTOMER_ORDERS": "toimeksiannot",
      "REMOVE_ORDER_POPUP": "Poista toimeksianto popup-ikkuna",
      "REMOVE_ORDER_FAILED_POPUP": "Toimeksiannot poisto epäonnistui popup-ikkuna",
      "MONTHLY_ORDER_CREATED_POPUP": "Kuukausisäästösopimus luotu onnistuneesti popup-ikkuna",
      "MONTHLY_ORDER_INFO": "Yhteenveto kuukausisäästön tiedoista",
      "RETRUN_TO_ORDER_PAGE": "Palaa toimeksiantojen etusivulle",
      "GO_TO_CREATE_ORDER_PAGE": "Siirry tekemään ostotoimeksianto",
      "DELETE_ORDER_BUTTON": "Poista toimeksianto",
      "ORIGIN_OF_DEPOSIT_MONEY_TABLE": "Sijoitettavien varojen alkuperän valintataulukko",
      "ORIGIN_OF_DEPOSIT_MONEY_OTHER": "Muu alkuperä",
      "WRITE_OTHER_ORIGIN": "Kuvaa muu alkuperä",
      "BANKTRANSFER_INFO_REGION": "Tilisiirron tiedot alue",
      "BANKTRANSFER_SUCCEEEDED": "Talletus onnistui",
      "BANKTRANSFER_FAILED": "Talletus epäonnistui",
      "BANKTRANSFER_SUMMARY_REGION": "Yhteenveto talletuksesta",
      "ENTER_WITHDRAW_AMOuNT": "Syötä nostettava summa",
      "SELECT_WITHDRAW_BANK_ACCOUNT": "Valitse tili, johon rahat nostetaan",
      "GOTO_CONFIRM_WITHDRAWAL": "Siirry vahvistamaan nosto",
      "CONFIRM_WITHDRAWAL": "Vahvistamaan nosto",
      "CONFIRM_PORTFOLIO_CREATION": "Luo salkku",
      "GOTO_PAYTRAIL_DEPOSIT": "Siirry Paytrailin sivulle vahvistamaan talletus",
      "CHOOSE_ACCOUNT": "Valitse asiakkuus, jolle salkku luodaan",
      "CHOOSE_PORTFOLIO_NAME": "Anna salkulle nimi (vapaaehtoinen)",
      // Orders info
      "EX_SUMMARY": "Yhteenveto kuluista taulukko",
      "EX_INCENTIVES": "Kannustimet taulukko",
      "EX_ADVANCE_INFO": "Ennakkotiedot taulukko",
      "EX_SERVICES_COSTS": "Sijoitus- ja oheispalvelun tarjoamiseen liittyvät kulut taulukko",
      "EX_INSTRUMENTS_COSTS": "Rahoitusvälineisiin liittyvät kulut taulukko",
      "EX_CUMULATIVE_AFFECT": "Kulujen kumulatiivinen vaikutus tuottoon taulukko",
      // Paginator
      "PAGINATOR": "Sivunvaihtaja",
      "PAGINATOR_PAGES": "Sivuja yhteensä",
      "SHOWN_ITEMS": "Sivulla näytettävien rivien lukumäärä",
      "PREVIOUS_PAGE": "Edellinen sivu",
      "FIRST_PAGE": "Ensimmäinen sivu",
      "NEXT_PAGE": "Seuraava sivu",
      "LAST_PAGE": "Viimeinen sivu",
      "PAGE": "sivu",
      // funds
      "FUND_PRICE_REGION": "Rahaston hinta osio",
      "FUND_PRICE": "Rahaston hinta",
      "FUND_PRICE_CHANGE_MONTH": "Rahaston arvonmuutos edellisen kuukauden aikana",
      "FUNDS_NAVIGATION": "Rahastot-osion navigointialue",
      "BASIC_INFO_TABLE": "Rahaston perustiedot -taulukko",
      "FUND_ORDER_TABLE": "Rahaston Minimisijoitus ja palkkiot -taulukko",
      "FUND_RETuRN_HISTORY_TABLE": "Rahaston tuottohistoria -taulukko",
      "IN_LANGUAGE": "PDF-muodossa kielelle",
      "DOWNLOAD_MONTHLY_REPORT": "kuukausikatsaus PDF-muodossa",
      "DOWNLOAD_QUARTERLY_REPORT": "kvartaalikatsaus PDF-muodossa",
      "PDF_FORM": "PDF-muodossa",
      "CHOOSE_TIMESPAN": "Valitse arvonkehitys kuvaajalle aikaväli",
      "VALUE_HISTORY_GRAPH_FUND": "Kuvaaja rahaston arvonkehityksestä. Kuvaajassa näkyy rahaston päivittäiset arvot valitulla ajanjaksolla ja niistä muodostuu jatkuva arvonkehityskäyrä.",
      "FUND_SEARCH_REGION": "Rahastojen haun rajaus alue",
      "FUND_ASSET_CLASS": "Rahaston pääluokan valinta",
      "FUND_SUB_CLASS": "Rahaston aliluokan valinta",
      "FUND_COMPANIES": "Rahastoperheen valinta",
      "CLEAR_FUND_SEARCH_SELECTIONS": "Tyhjää rahastohaun hakukriteerit",
      "GOTO_BUY_FUND": "Siirry ostamaan rahastoa",
      "FUND_SEARCH_TABLE": "Rahastohaun tulokset taulukko",
      "MORNINGSTAR": "Morningstar Rating",
      "STARS": "Tähteä",
      "FUND_IMAGE": "Rahaston kuva",
      "FUND_FUND_UNIT_DIAGRAM": "Kaavio rahasto-osuuksistani",
      // own settings
      "CHANGE_INFORMATION_POPUP": "Tietojen muokkaaminen popupikkuna",
      "CHANGE_INFORMATION_FORM": "Tietojen muokkaaminen lomake",
      "CHANGE_PORTFOLIO_NAME_FORM": "Salkun nimen muokkaaminen lomake",
      "CHANGE_INFORMATION_FORM": "Tietojen muokkaaminen lomake",
      "CHANGE_PASSWORD_POPUP": "Vaihda salasana popupikkuna",
      "TAX_COUNTRY_REGION": "Verotusmaan tiedot alue",
      "ERROR_UPDATING_INFO_POPUP": "Virhe tietojen päivittämisessä",
      // lisätty 22.11.2021
      "REMOVE_TAXCOUNTRY": "Poista verotusmaa",
      "SELECT_TAXCOUNTRY": "Valitse verotusmaa",

      // Fund unit
      "FUND_UNIT_HISTORY": "Rahasto-osuuden tapahtumahistoria",
      "FUND_UNIT_INFO": "Rahasto-osuuden tiedot",
      "FUND_UNIT_BONUS_HISTORY": "Rahastoitujen palkkioiden tapahtumahistoria",

      //signup
      "SIGNUP_PAGES_INFO": "Asiakkaaksitulon sivunäkymä",
      "SIGNUP_ASTERIX_INFO": "Tähdellä merkityt kentät ovat pakollisia",
      "SIGNUP_ADD_CITIZENSHIP": "Lisää uusi kansalaisuus",
      "SIGNUP_SEND_INFO": "Lähetä tiedot ja luo asiakkuus",
      "SIGNUP_SAVING_POPUP": "Asiakkuustietojasi tallennetaan popup",
      "SIGNUP_PAGE_TEXT_1": "Asiakkaaksitulosopimuksen sivu ",
      "SIGNUP_PAGE_TEXT_2": "kautta 6",

      // customer service
      "CUSTOMERSERVICE_NAV": "Asiakaspalvelun navigointialue",
      "CONTACT_HOURS": "Yhteydenottoaika",
      // lisätty 24.11.2021
      "YOUR_BANKER": "Pankkiirisi",
      "OPEN_AT": "Aukioloaika",
      "CHOOSE_FUND_FOR_MESSAGE": "Valitse mitä rahastoa viestisi koskee",
      // lisätty 30.11.2021
      "CUSTOMERSERVICE_CONVERSATION_HISTORY": "Keskusteluhistoria",
      "UNOPENED_MESSAGES": "avaamatonta viestiä",
      // lisätty 2.12.2021
      "BANKER_CONTACT_INFO": "Pankkiirisi yhteystiedot",
      "CUSTOMERSERVICE_CONTACT_INFO": "Asiakaspalvelun yhteystiedot",
      "MEMBERSERVICE_CONTACT_INFO": "Jäsenpalvelun yhteystiedot",

      // buy fund pipe
      "DONE_PERCENTAGE": "Oston valmiusprosentti",
      "FEE_INFO_DETAILS_POPUP": "Erittely popup-ikkuna",
      "SELECTED_PORTFOLIO": "Valittu salkku",
      "INSERT_BUE_SUM": "Syötä ostosumma",
      "MESSAGE": "Syötä viesti asiakaspalvelulle",

      // lisätty 27.12.2021
      "WITHDRAWAL_TABLE": "Taulukko rahasto-osuuden nostoilmoituksesta",

      // Banker view
      "CUSTOMER_TABLE": "Taulukko pankkiirin asiakkaista",
      "SUBMIT_USER_SEARCH": "Tee käyttäjähaku",
  },
  "REWARD_CALCULATOR": {
    "YOUR_PERFORMANCE_BONUS": "Syötä tulospalkkiosi",
    "YOUR_TAX_PERCENT": "Syötä veroprosenttisi",
    "INFO_TEXT": "Esimerkkilaskelma perustuu voimassaolevan henkilöstörahastolakiin sekä voimassa olevaan vero- ja muuhun lainsäädäntöön, joka voi muuttua. Laskelmassa on käytetty seuraavia arvoja: työnantajan sivukulut 23 %, työntekijän sivukulut 10 % ja sijoitustoiminnan tavoitteena 4 % vuotuinen tuotto. Tuottotavoite ei ole tae tulevasta tuotosta. Esimerkissä edunsaaja nostaa rahastosäästöjä 3. vuodesta eteenpäin käteistä tulospalkkiota vastaavan rahamäärän. Tämä on mahdollista henkilön tuloveroprosentin ollessa min 25. Alhaisemmalla tuloveroprosentilla vastaavan noston voin tehdä 4. vuodesta eteenpäin."
  }
};
