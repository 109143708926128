/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { styled } from '@mui/system';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

const FooterLink = styled(
  (props) => (
    <Button
      endIcon={<KeyboardArrowRightIcon color="primary" />}
      component={Link}
      color="primary"
      target="_blank"
      rel="noopener"
      {...props}
    />
  ),
)({
  textTransform: 'none',
  fontWeight: 400,
  letterSpacing: 0.2,
});

export default FooterLink;
