import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import i18next from 'i18next';
import axios from '../api/axiosSetup';

export const fetchLatestWithdrawalNotice = createAsyncThunk('fundUnitWithdrawals/fetchLatestWithdrawalNotice', async ({ membershipId }) => {
  const response = await axios.get(`/api/fundunitwithdrawal/latestwithdrawalnotice/${membershipId}`);
  return response.data;
});

export const fetchWithdrawalNoticeDates = createAsyncThunk('fundUnitWithdrawals/fetchWithdrawalNoticeDates', async ({ membershipId }) => {
  const response = await axios.get(`/api/fundunitwithdrawal/withdrawalnoticedates/${membershipId}`);
  return response.data;
});

export const postWithdrawalNotice = createAsyncThunk('fundUnitWithdrawals/postWithdrawalNotice', async ({ membershipId, formData }) => {
  const response = await axios.post(
    `api/fundunitwithdrawal/withdrawalnotice/${membershipId}?language=${i18next.language}`,
    formData,
  );
  return response.status;
});

const initialState = {
  latestWithdrawalNotice: null,
  fetchingLatestWithdrawalNotice: false,
  withdrawalNoticeDates: null,
  fetchingWithdrawalNoticeDates: false,
  postedWithdrawalNoticeStatus: null,
  postingWithdrawalNotice: false,
};

export const fundUnitWithdrawalsSlice = createSlice({
  name: 'fundUnitWithdrawals',
  initialState,
  reducers: {
    resetWithdrawal: () => initialState,
    resetPostStatus: (state) => {
      state.postedWithdrawalNoticeStatus = null;
    },
  },
  extraReducers: {
    [fetchLatestWithdrawalNotice.pending]: (state) => {
      state.fetchingLatestWithdrawalNotice = true;
      state.latestWithdrawalNotice = null;
    },
    [fetchLatestWithdrawalNotice.fulfilled]: (state, { payload }) => {
      state.fetchingLatestWithdrawalNotice = false;
      state.latestWithdrawalNotice = payload;
    },
    [fetchLatestWithdrawalNotice.rejected]: (state) => {
      state.fetchingLatestWithdrawalNotice = false;
    },
    [fetchWithdrawalNoticeDates.pending]: (state) => {
      state.fetchingWithdrawalNoticeDates = true;
    },
    [fetchWithdrawalNoticeDates.fulfilled]: (state, { payload }) => {
      state.fetchingWithdrawalNoticeDates = false;
      state.withdrawalNoticeDates = payload;
    },
    [fetchWithdrawalNoticeDates.rejected]: (state) => {
      state.fetchingWithdrawalNoticeDates = false;
    },
    [postWithdrawalNotice.pending]: (state) => {
      state.postingWithdrawalNotice = true;
    },
    [postWithdrawalNotice.fulfilled]: (state, { payload }) => {
      state.postingWithdrawalNotice = false;
      state.postedWithdrawalNoticeStatus = payload;
    },
    [postWithdrawalNotice.rejected]: (state) => {
      state.postingWithdrawalNotice = false;
    },
  },
});

export default fundUnitWithdrawalsSlice.reducer;

export const { resetWithdrawal, resetPostStatus } = fundUnitWithdrawalsSlice.actions;
