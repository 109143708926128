import React from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

// MUI
import { styled, useTheme } from '@mui/system';
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

// Custom components.
import FooterLink from './FooterLink';

// Images.
import EvliLogoWhite from '../../images/evliLogoWhite.png';

const FooterContainer = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  position: 'relative',
  minHeight: 245,
  paddingTop: 30,
  [theme.breakpoints.down('md')]: {
    paddingTop: 20,
  },
}));

const FooterGrid = styled(Grid)(({ theme }) => ({
  height: '100%',
  width: '100%',
  marginLeft: 'auto',
  marginRight: 'auto',
  color: theme.palette.white.main,
  '& > div': {
    maxWidth: 220,
    '& > h5': {
      marginBottom: 16,
      [theme.breakpoints.down('md')]: {
        marginBottom: 10,
      },
    },
    '& > p': {
      whiteSpace: 'pre-line',
      '& > a': {
        textDecoration: 'none',
        color: theme.palette.secondary.main,
      },
    },
  },
}));

const LinkGrid = styled(Grid)(({ theme }) => ({
  minHeight: 64,
  backgroundColor: theme.palette.white.main,
}));

const MobileFooterContainer = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  position: 'relative',
  minHeight: 245,
  paddingTop: 30,
}));

const MobileFooterImage = styled('div')({
  backgroundPosition: 'bottom right',
  backgroundSize: '100% auto',
  height: '100%',
  width: '100%',
});

function Footer() {
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  // 680px is between small and medium breakpoints.
  const showMobileFooter = useMediaQuery('(max-width:680px)');
  const showWideMobileFooter = useMediaQuery('(max-width:880px)');
  const location = useLocation();
  const hideFooter = location.pathname === '/palkkiolaskuri';

  const inPersonnelFundView = useSelector((state) => state.UIstate.inPersonnelFundView);
  const inLoginView = useSelector((state) => state.UIstate.inLoginView);

  const evliOfficeInfo = (
    <>
      <Typography variant="h5">
        {t('PERSONNELFUND.OFFICE_INFO')}
      </Typography>
      <Typography variant="body2">
        {`${t('PERSONNELFUND.EVLI_ADDRESS')} \n`}
        {t('PERSONNELFUND.EVLI_POSTALCODE_CITY')}
      </Typography>
    </>
  );

  const webServiceCustomerServiceContent = (
    <>
      <Typography variant="h5">
        {t('PERSONNELFUND.INVESTOR_SERVICE')}
      </Typography>
      <Typography variant="body2">
        {`${t('SHORT.PHONE_NUMBER')} ${t('PERSONNELFUND.INVESTOR_SERVICE_PHONE')}\n`}
        {`${t('WORKDAYS')} ${t('PERSONNELFUND.INVESTOR_SERVICE_OPENING_HOURS')}`}
      </Typography>
      <Typography variant="body2" sx={{ marginTop: isSmallScreen ? 1 : 2 }}>
        <a href="mailto:info@evli.com">
          info@evli.com
        </a>
      </Typography>
    </>
  );

  const personnelFundCustomerServiceContent = (
    <>
      <Typography variant="h5">
        {t('PERSONNELFUND.FOOTER_CUSTOMER_SERVICE')}
      </Typography>
      <Typography variant="body2">
        {`${t('SHORT.PHONE_NUMBER')} 0201 558 660\n`}
        {`${t('WORKDAYS')} ${t('CUSTOMER_SERVICE_OPENING_HOURS')}`}
      </Typography>
      <Typography variant="body2" sx={{ marginTop: isSmallScreen ? 1 : 2 }}>
        <a href="mailto:jasenpalvelu@eai.fi">
          jasenpalvelu@eai.fi
        </a>
      </Typography>
    </>
  );

  const callRatesContent = (
    <>
      <Typography variant="h5">
        {t('CALL_RATES')}
      </Typography>
      <Typography variant="subtitle2">
        {`${t('Landline')}:`}
      </Typography>
      <Typography variant="body2">
        {`0,084 ${t('SHORT.EURO_PER_CALL')} + 0,056 ${t('SHORT.EURO_PER_MINUTE')}`}
      </Typography>
      <Typography variant="subtitle2" sx={{ marginTop: 3 }}>
        {`${t('Mobile')}:`}
      </Typography>
      <Typography variant="body2">
        {`0,084 ${t('SHORT.EURO_PER_CALL')} + 0,22 ${t('SHORT.EURO_PER_MINUTE')}`}
      </Typography>
    </>
  );

  /* const companyContent = (
    <>
      <Typography variant="body2">© 2021 EAB Group</Typography>
      <Typography variant="body2">{`${t('BUSINESS_ID')}: 1918955-2`}</Typography>
    </>
  ); */

  const linkGrid = (
    <LinkGrid
      container
      alignItems="center"
      justifyContent="center"
      sx={showMobileFooter ? { paddingTop: 2, paddingBottom: 2 } : {}}
    >
      <Grid item xs={12} md="auto">
        <FooterLink href={inPersonnelFundView ? 'https://www.evli.com/asiakasinformaatio' : 'https://www.eabgroup.fi/sopimusehdot-ja-tarkeaa-tietoa'}>
          {inPersonnelFundView ? t('CONTRACT_CUSTOMER_INFO') : t('CONTRACT_TERMS_IMPORTANT_INFO')}
        </FooterLink>
      </Grid>
      <Grid item xs={12} md="auto">
        <FooterLink href={inPersonnelFundView ? 'https://www.evli.com/fi/footer/henkilotietojen-kaytto' : 'https://www.eabgroup.fi/henkilotietojen-kaytto-elite-alfred-bergilla'}>
          {t('PERSONAL_DATA_PROCESSING')}
        </FooterLink>
      </Grid>
      <Grid item xs={12} md="auto">
        <FooterLink href={inPersonnelFundView ? 'https://www.evli.com/saavutettavuus' : 'https://www.eabgroup.fi/saavutettavuus'}>
          {t('ACCESSIBILITY_STATEMENT')}
        </FooterLink>
      </Grid>
    </LinkGrid>
  );

  if ((showMobileFooter || (inLoginView && showWideMobileFooter)) && !hideFooter) {
    return (
      <footer>
        <MobileFooterContainer>
          <MobileFooterImage>
            <FooterGrid
              container
              direction="column"
              rowSpacing={3}
              sx={{
                marginTop: -3,
                paddingLeft: 2,
                paddingRight: 2,
                paddingBottom: 18,
              }}
            >
              <Grid item>
                <img src={EvliLogoWhite} alt="Evli logo" width={151} />
              </Grid>
              <Grid item>
                {evliOfficeInfo}
              </Grid>
              {(inLoginView || !inPersonnelFundView) && (
                <Grid item>
                  {webServiceCustomerServiceContent}
                </Grid>
              )}
              {(inLoginView || inPersonnelFundView) && (
                <Grid item>
                  {personnelFundCustomerServiceContent}
                </Grid>
              )}
              <Grid item>
                {callRatesContent}
              </Grid>
              {/* <Grid item>
                {companyContent}
              </Grid> */}
            </FooterGrid>
          </MobileFooterImage>
        </MobileFooterContainer>
        {linkGrid}
      </footer>
    );
  }

  if (inLoginView && !hideFooter) {
    return (
      <footer>
        <FooterContainer sx={{ paddingTop: isSmallScreen ? 1 : 3 }}>
          <FooterGrid container columnSpacing={isSmallScreen ? 1 : 3} justifyContent="center">
            <Grid item xs="auto" md>
              <img src={EvliLogoWhite} alt="Evli logo" width={132} />
            </Grid>
            <Grid item>
              {evliOfficeInfo}
            </Grid>
            <Grid item xs={12} sm>
              {webServiceCustomerServiceContent}
            </Grid>
            <Grid item xs={12} sm>
              {personnelFundCustomerServiceContent}
            </Grid>
            <Grid item xs={12} sm>
              {callRatesContent}
            </Grid>
            {/* <Grid item xs="auto" md sx={{ marginTop: 4, paddingRight: 2 }}>
              {companyContent}
            </Grid> */}
          </FooterGrid>
        </FooterContainer>
        {linkGrid}
      </footer>
    );
  }

  return (
    <footer>
      {!hideFooter && (
      <FooterContainer
        sx={{ paddingTop: isSmallScreen ? 1 : 3 }}
      >
        <FooterGrid container columnSpacing={isSmallScreen ? 1 : 3} justifyContent="center">
          <Grid item xs="auto" md={3}>
            <img src={EvliLogoWhite} alt="Evli logo" width={132} />
          </Grid>
          <Grid item>
            {evliOfficeInfo}
          </Grid>
          <Grid item xs={12} sm md={3}>
            {inPersonnelFundView
              ? personnelFundCustomerServiceContent
              : webServiceCustomerServiceContent}
          </Grid>
          <Grid item xs={12} sm md={3}>
            {callRatesContent}
          </Grid>
          {/* <Grid item xs="auto" md={3} sx={{ marginTop: 4, paddingRight: 2 }}>
            {companyContent}
          </Grid> */}
        </FooterGrid>
      </FooterContainer>
      )}
      {!hideFooter && linkGrid}
    </footer>
  );
}

export default Footer;
