import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { format } from 'date-fns';
import axios from '../api/axiosSetup';

export const fetchPortfolioTransactions = createAsyncThunk(
  'transactions/fetchPortfolioTransactions',
  async ({
    portfolioIdList, className, startDate, endDate,
  }, thunkAPI) => {
    const { customerId } = thunkAPI.getState().auth;
    let url = `/api/transactions/portfolioOrderTransactions/${JSON.stringify(
      portfolioIdList,
    )}/${className}`;
    if (startDate && endDate) url = [url, format(new Date(startDate), 'yyyy-MM-dd'), format(new Date(endDate), 'yyyy-MM-dd')].join('/');
    const response = await axios.get(`${url}${customerId ? `?customerId=${customerId}` : ''}`);
    return response.data;
  },
);

export const putCancelTransaction = createAsyncThunk(
  'transactions/putCancelTransaction',
  async ({ source, portfolioId, transactionNumber }, thunkAPI) => {
    const { customerId } = thunkAPI.getState().auth;
    if (customerId) {
      return thunkAPI.rejectWithValue('Forbidden in banker view');
    }
    const response = await axios.put(`/api/transactions/cancelPortfolioOrderTransaction/${source}/${portfolioId}/${transactionNumber}`);
    return response.data;
  },
);

export const fetchPortfolioRiskStatistics = createAsyncThunk(
  'transactions/fetchPortfolioRiskStatistics',
  async ({
    portfolioId, endDate, numberOfYears,
  }) => {
    const formattedEndDate = format(new Date(endDate), 'yyyy-M-d');
    const response = await axios.get(`/api/transactions/portfolioTransactionsRiskStatistics/${portfolioId}/${formattedEndDate}/${numberOfYears}`);
    return response.data;
  },
);

const transactionRequest = async ({
  portfolioIds,
  transactionTypes,
  startDate,
  endDate,
  buyCost,
  getFifoBuyItems,
  customerName,
  previousStartDate,
  language,
}, thunkAPI) => {
  const { customerId } = thunkAPI.getState().auth;
  const params = {
    portfolioIds,
    startDate: format(new Date(startDate), 'yyyy-MM-dd'),
    endDate: format(new Date(endDate), 'yyyy-MM-dd'),
    buyCost: Boolean(buyCost),
    getFifoBuyItems: Boolean(getFifoBuyItems),
    customerName,
    previousStartDate,
    language,
  };
  if (transactionTypes?.length > 0) {
    params.transactionTypes = transactionTypes;
  }
  const response = await axios.get(
    `/api/transactions${customerId ? `?customerId=${customerId}` : ''}`,
    { params },
  );
  if (!response || !response.data) {
    return [];
  }

  // Replace empty GUIDs.
  return response.data.map((item, index) => ({
    ...item,
    id: item.id === '00000000-0000-0000-0000-000000000000'
      ? `${item.id}-${index}` : item.id,
  }));
};

export const fetchTransactions = createAsyncThunk(
  'transactions/fetchTransactions',
  transactionRequest,
);

export const fetchSellTransactions = createAsyncThunk(
  'transactions/fetchSellTransactions',
  transactionRequest,
);

export const fetchOtherProfitsTransactions = createAsyncThunk(
  'transactions/fetchOtherProfitsTransactions',
  transactionRequest,
);

export const fetchPrivateEquityProfitsTransactions = createAsyncThunk(
  'transactions/fetchPrivateEquityProfitsTransactions',
  transactionRequest,
);

// NOTE: disabled since OneFactor endpoint does not return any data.
// Enable if needed in the future.
/* export const fetchPrivateEquityProfitsTax = createAsyncThunk(
  'transactions/fetchPrivateEquityProfitsTax',
  async ({ portfolioIds, startDate, endDate }, thunkAPI) => {
    const { customerId } = thunkAPI.getState().auth;
    const params = {
      portfolioIds,
      startDate: format(new Date(startDate), 'yyyy-MM-dd'),
      endDate: format(new Date(endDate), 'yyyy-MM-dd'),
    };
    const response = await axios.get(
      `api/transactions/getPETaxInfo${customerId ? `?customerId=${customerId}` : ''}`, { params });
    return response.data;
  },
); */

export const fetchStatementTransactionsForPortfolios = createAsyncThunk(
  'transactions/fetchStatementTransactionsForPortfolios',
  async ({
    portfolioIds, startDate, endDate,
  }, thunkAPI) => {
    const { customerId } = thunkAPI.getState().auth;
    const formattedStartDate = format(new Date(startDate), 'yyyy-M-d');
    const formattedEndDate = format(new Date(endDate), 'yyyy-M-d');
    const response = await axios.get(`/api/transactions/statementTransactions${customerId ? `?customerId=${customerId}` : ''}`, {
      params: {
        portfolioIds, startDate: formattedStartDate, endDate: formattedEndDate,
      },
    });
    return response.data;
  },
);

export const fetchStatementBalance = createAsyncThunk(
  'transactions/fetchStatementBalance',
  async ({
    portfolioIds, reportingDate, balanceName,
  }, thunkAPI) => {
    const { customerId } = thunkAPI.getState().auth;
    const formattedReportDate = format(new Date(reportingDate), 'yyyy-M-d');
    const response = await axios.get(`/api/transactions/statementTransactionsBalance${customerId ? `?customerId=${customerId}` : ''}`, {
      params: {
        portfolioIds, endDate: formattedReportDate,
      },
    });
    const returnObject = {};
    returnObject[balanceName] = response.data;
    return returnObject;
  },
);

export const fetchPendingMoneyTransactionsForPortfolios = createAsyncThunk(
  'transactions/fetchPendingMoneyTransactionsForPortfolios',
  async ({
    portfolioIds, endDate,
  }, thunkAPI) => {
    const { customerId } = thunkAPI.getState().auth;
    const formattedEndDate = format(new Date(endDate), 'yyyy-M-d');
    const response = await axios.get(`/api/transactions/pendingMoneyTransactions${customerId ? `?customerId=${customerId}` : ''}`, {
      params: {
        portfolioIds, endDate: formattedEndDate,
      },
    });
    return response.data;
  },
);

const initialState = {
  fetchingPortfolioTransactions: false,
  portfolioTransactions: [],
  postingCancelPortfolioTransaction: false,
  updatingPortfolioIds: [],
  riskStatistics: null,
  fetchingRiskStatistics: false,
  transactions: [],
  fetchingTransactions: false,
  sellTransactions: [],
  fetchingSellTransactions: false,
  otherProfitsTransactions: [],
  fetchingOtherProfitsTransactions: false,
  privateEquityProfitsTransactions: [],
  fetchingPrivateEquityProfitsTransactions: false,
  fetchingStatementTransactionsForPortfolios: false,
  statementTransactionsForPortfolios: [],
  fetchingStatementBalance: false,
  statementBalance: null,
  fetchingPendingMoneyTransactionsForPortfolios: false,
  pendingMoneyTransactionsForPortfolios: null,
  statementReportData: null,
  statementTableData: [],
  statementSummaryData: null,
  statementOpenTransactionEstimates: null,
};

export const transactionsSlice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
    setUpdatingPortfolioIds: (state, { payload }) => {
      state.updatingPortfolioIds = payload;
    },
    resetStatementBalance: (state) => {
      state.statementBalance = null;
    },
    setStatementTableData: (state, { payload }) => {
      state.statementTableData = payload;
    },
    setStatementSummaryData: (state, { payload }) => {
      state.statementSummaryData = payload;
    },
    setStatementOpenTransactionData: (state, { payload }) => {
      state.statementOpenTransactionEstimates = payload;
    },
  },
  extraReducers: {
    [fetchPortfolioTransactions.pending]: (state) => {
      state.fetchingPortfolioTransactions = true;
    },
    [fetchPortfolioTransactions.fulfilled]: (state, { payload }) => {
      state.portfolioTransactions = payload;
      state.fetchingPortfolioTransactions = false;
    },
    [fetchPortfolioTransactions.rejected]: (state) => {
      state.fetchingPortfolioTransactions = false;
    },
    [putCancelTransaction.pending]: (state) => {
      state.postingCancelPortfolioTransaction = true;
    },
    [putCancelTransaction.fulfilled]: (state) => {
      state.postingCancelPortfolioTransaction = false;
    },
    [putCancelTransaction.rejected]: (state) => {
      state.postingCancelPortfolioTransaction = false;
    },
    [fetchPortfolioRiskStatistics.pending]: (state) => {
      state.riskStatistics = null;
      state.fetchingRiskStatistics = true;
    },
    [fetchPortfolioRiskStatistics.fulfilled]: (state, { payload }) => {
      state.fetchingRiskStatistics = false;
      state.riskStatistics = payload;
    },
    [fetchPortfolioRiskStatistics.rejected]: (state) => {
      state.fetchingRiskStatistics = false;
    },
    [fetchTransactions.pending]: (state) => {
      state.fetchingTransactions = true;
      state.transactions = [];
    },
    [fetchTransactions.fulfilled]: (state, { payload }) => {
      state.fetchingTransactions = false;
      state.transactions = payload;
    },
    [fetchTransactions.rejected]: (state) => {
      state.fetchingTransactions = false;
    },
    [fetchSellTransactions.pending]: (state) => {
      state.fetchingSellTransactions = true;
      state.sellTransactions = [];
    },
    [fetchSellTransactions.fulfilled]: (state, { payload }) => {
      state.fetchingSellTransactions = false;
      state.sellTransactions = payload;
    },
    [fetchSellTransactions.rejected]: (state) => {
      state.fetchingSellTransactions = false;
    },
    [fetchOtherProfitsTransactions.pending]: (state) => {
      state.fetchingOtherProfitsTransactions = true;
      state.otherProfitsTransactions = [];
    },
    [fetchOtherProfitsTransactions.fulfilled]: (state, { payload }) => {
      state.fetchingOtherProfitsTransactions = false;
      state.otherProfitsTransactions = payload;
    },
    [fetchOtherProfitsTransactions.rejected]: (state) => {
      state.fetchingOtherProfitsTransactions = false;
    },
    [fetchPrivateEquityProfitsTransactions.pending]: (state) => {
      state.fetchingPrivateEquityProfitsTransactions = true;
      state.privateEquityProfitsTransactions = [];
    },
    [fetchPrivateEquityProfitsTransactions.fulfilled]: (state, { payload }) => {
      state.fetchingPrivateEquityProfitsTransactions = false;
      state.privateEquityProfitsTransactions = payload;
    },
    [fetchPrivateEquityProfitsTransactions.rejected]: (state) => {
      state.fetchingPrivateEquityProfitsTransactions = false;
    },
    [fetchStatementTransactionsForPortfolios.pending]: (state) => {
      state.fetchingStatementTransactionsForPortfolios = true;
      state.statementTransactionsForPortfolios = [];
    },
    [fetchStatementTransactionsForPortfolios.fulfilled]: (state, { payload }) => {
      state.fetchingStatementTransactionsForPortfolios = false;
      state.statementTransactionsForPortfolios = payload;
    },
    [fetchStatementTransactionsForPortfolios.rejected]: (state) => {
      state.fetchingStatementTransactionsForPortfolios = false;
    },
    [fetchStatementBalance.pending]: (state) => {
      state.fetchingStatementBalance = true;
    },
    [fetchStatementBalance.fulfilled]: (state, { payload }) => {
      state.fetchingStatementBalance = false;
      state.statementBalance = { ...state.statementBalance, ...payload };
    },
    [fetchStatementBalance.rejected]: (state) => {
      state.fetchingStatementBalance = false;
    },
    [fetchPendingMoneyTransactionsForPortfolios.pending]: (state) => {
      state.fetchingPendingMoneyTransactionsForPortfolios = true;
      state.pendingMoneyTransactionsForPortfolios = null;
    },
    [fetchPendingMoneyTransactionsForPortfolios.fulfilled]: (state, { payload }) => {
      state.fetchingPendingMoneyTransactionsForPortfolios = false;
      state.pendingMoneyTransactionsForPortfolios = payload;
    },
    [fetchPendingMoneyTransactionsForPortfolios.rejected]: (state) => {
      state.fetchingPendingMoneyTransactionsForPortfolios = false;
    },
  },
});

export const {
  setStatementTableData,
  setUpdatingPortfolioIds,
  setStatementSummaryData,
  setStatementOpenTransactionData,
} = transactionsSlice.actions;

export default transactionsSlice.reducer;
