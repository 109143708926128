import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './locales/en';
import fi from './locales/fi';
import se from './locales/se';
import { setLanguage } from './app/localStorage';

i18n
  .use(initReactI18next)
  .init({
    fallbackLng: 'fi',
    debug: process.env.NODE_ENV === 'development',
    resources: {
      fi: {
        translation: fi,
      },
      en: {
        translation: en,
      },
      se: {
        translation: se,
      },
    },
  });
i18n.on('languageChanged', (lng) => {
  document.documentElement.setAttribute('lang', lng);
  setLanguage(lng);
});

export default i18n;
