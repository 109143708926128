import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Badge from '@mui/material/Badge';
import EmailSharpIcon from '@mui/icons-material/EmailSharp';

function MobileDrawerItem({
  label,
  href,
  open,
  onClick,
  children,
}) {
  const customerConversations = useSelector(
    (state) => state.customerService.customerConversations,
  );
  const unopenedConversations = customerConversations
    ? customerConversations.filter((message) => !message.isOpened)
    : [];

  const ListItemTextWithBadge = () => {
    if (unopenedConversations.length > 0) {
      if (href === '/varallisuus') {
        return (
          <>
            <ListItemText primary={label} />
            <Badge
              badgeContent={unopenedConversations.length}
              color="green"
              sx={{ mr: '16px' }}
              componentsProps={{
                badge: {
                  sx: {
                    minWidth: '13px', height: '13px', width: '13px', mr: '-4px',
                  },
                },
              }}
            >
              <EmailSharpIcon sx={{ fontSize: 20 }} color="primary" />
            </Badge>
          </>
        );
      }
      if (href === '/asiakaspalvelu/etusivu') {
        return (
          <ListItemText>
            <Badge
              badgeContent={unopenedConversations.length}
              color="green"
              sx={{ mr: '8px' }}
              componentsProps={{
                badge: {
                  sx: {
                    minWidth: '16px', height: '16px', width: '16px', mr: '-8px',
                  },
                },
              }}
            >
              {label}
            </Badge>
          </ListItemText>
        );
      }
    }
    return <ListItemText primary={label} />;
  };

  if (!children) {
    if (unopenedConversations.length > 0 && href === '/asiakaspalvelu/viestit') {
      return (
        <ListItem
          key={label}
          component={Link}
          to={href}
          onClick={onClick}
        >
          <ListItemText>
            <Badge
              badgeContent={unopenedConversations.length}
              color="green"
              sx={{ mr: '8px' }}
              componentsProps={{
                badge: {
                  sx: {
                    minWidth: '16px', height: '16px', width: '16px', mr: '-8px',
                  },
                },
              }}
            >
              {label}
            </Badge>
          </ListItemText>
          <KeyboardArrowRightIcon className="oceanColorIcon" />
        </ListItem>
      );
    }
    return (
      <ListItem
        key={label}
        component={Link}
        to={href}
        onClick={onClick}
      >
        <ListItemText primary={label} />
        <KeyboardArrowRightIcon className="oceanColorIcon" />
      </ListItem>
    );
  }

  return (
    <Fragment key={label}>
      <ListItem
        button
        onClick={onClick}
      >
        <ListItemTextWithBadge />
        {open
          ? <ExpandLess className="oceanColorIcon" />
          : <ExpandMore className="oceanColorIcon" />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {children}
      </Collapse>
    </Fragment>
  );
}

MobileDrawerItem.propTypes = {
  label: PropTypes.string.isRequired,
  href: PropTypes.string,
  open: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.node,
};

MobileDrawerItem.defaultProps = {
  href: '',
  open: false,
  onClick: null,
  children: null,
};

export default MobileDrawerItem;
