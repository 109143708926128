/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// MUI
import { styled } from '@mui/system';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Button from '@mui/material/Button';

// Custom components
import SecondaryButton from '../common/SecondaryButton';
import { LANGUAGE_OPTIONS } from '../../lib/constants';

const CustomDiv = styled('div')(({
  marginRight: 20,
}));

const CustomMenu = styled((props) => (
  <Menu
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
))(() => ({
  '& .MuiPaper-root': {
    '& .MuiMenu-list': {
      padding: 0,
    },
    '& .MuiMenuItem-root': {
      borderBottom: '1px solid rgb(77, 87, 88, 0.3)',
      margin: 0,
      height: 40,
    },
  },
}));

const CustomMenuItem = styled(MenuItem)(() => ({
  fontSize: '0.75rem',
  textTransform: 'uppercase',
  color: 'rgba(77, 87, 88, 1)',
  fontWeight: 600,
  display: 'flex',
  justifyContent: 'space-between',
  paddingLeft: 22,
  paddingRight: 22,
  height: 40,
  width: 83,
}));

function LanguageSelect({ variant, color }) {
  const { t, i18n } = useTranslation();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (language) => () => {
    i18n.changeLanguage(language);
    handleClose();
  };

  const selectedLanguage = i18n.language;

  const endIcon = (
    <ExpandMoreIcon
      sx={
        anchorEl
          ? { transform: 'rotate(180deg)', transition: '0.3s' }
          : { transition: '0.3s' }
      }
    />
  );

  const button = variant === 'secondary' ? (
    <SecondaryButton
      onClick={handleOpen}
      aria-label={t('ARIA.LANGUAGE_SELECTION')}
      endIcon={endIcon}
      aria-haspopup="true"
      aria-controls="language-menu"
      size="large"
    >
      {selectedLanguage}
    </SecondaryButton>
  ) : (
    <Button
      onClick={handleOpen}
      aria-label={t('ARIA.LANGUAGE_SELECTION')}
      endIcon={endIcon}
      aria-haspopup="true"
      aria-controls="language-menu"
      size="large"
      variant={variant}
      color={color}
    >
      {selectedLanguage}
    </Button>
  );

  return (
    <>
      <CustomDiv>
        {button}
        <CustomMenu
          id="language-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {LANGUAGE_OPTIONS
            .filter((option) => option.value !== selectedLanguage)
            .map((option) => (
              <CustomMenuItem
                key={option.value}
                onClick={handleClick(option.value)}
                aria-label={t(option.ariaLabel)}
              >
                {option.value}
              </CustomMenuItem>
            ))}
        </CustomMenu>
      </CustomDiv>
    </>
  );
}

LanguageSelect.propTypes = {
  variant: PropTypes.string,
  color: PropTypes.string,
};

LanguageSelect.defaultProps = {
  variant: 'secondary',
  color: null,
};

export default LanguageSelect;
