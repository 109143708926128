/* eslint-disable no-bitwise */
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { format } from 'date-fns';
import i18next from 'i18next';
// import { VP_FREEZE_DATE_OBJECT } from './constants';

/**
 * A custom hook that parses the query string.
 * @returns {Object} - URLSearchParams object.
 */
export function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

/**
 * Generates a GUID (copied from old code).
 * @returns {string} - New GUID.
 */
export function generateGuid() {
  const guid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (character) => {
    const randomInt = Math.random() * 16 | 0;
    const value = character === 'x' ? randomInt : ((randomInt & 0x3) | 0x8);
    return value.toString(16);
  });
  return guid;
}

/**
 * Parses date string to dd.MM.yyyy format. Otherwise returns empty string.
 * @param {string} value - Date to be parsed.
 * @returns {string} - Parsed date.
 */
export function getFullDate(value) {
  return value ? format(new Date(value), 'dd.MM.yyyy') : '';
}

/**
 * Parses date string to dd.MM. format. Otherwise returns empty string.
 * @param {string} value - Date to be parsed.
 * @returns {string} - Parsed date.
 */
export function getShortDate(value) {
  return value ? format(new Date(value), 'dd.MM.') : '';
}

/**
 * Parses number to floating point value (string) with specified digit amount after decimal place.
 * @param {number} value - Number to be parsed.
 * @param {number} [digits=0] - The number of digits to appear after the decimal point.
 * @returns {string} - Parsed float value.
 */
export function parseFloatValue(value, digits = 0) {
  return value !== null || value !== undefined ? value.toFixed(digits).replace('.', ',') : '';
}

/**
 * Parses number to floating point value (string) with specified digit amount after decimal place.
 * @param {array} list - List of anything
 * @param {number} itemsPerPage - Number of items on a page
 * @param {number} pageNumber - Number of current page
 * @returns {array} - array containing appropriate items from the provided list
 */
export const paginateList = (list, itemsPerPage, pageNumber) => (
  list.slice((pageNumber - 1) * itemsPerPage, pageNumber * itemsPerPage)
);

/**
 * Parses number to floating point value (string) with specified digit amount after decimal place.
 * @param {array} list - List of anything
 * @param {number} itemsPerPage - Number of items on a page
 * @returns {number} - number of pages needed to portray the listed items in certain size pages
 */
export const numberOfPaginatedPages = (list, itemsPerPage) => {
  let pagesTotal = Math.floor(list.length / itemsPerPage);
  if (list.length % itemsPerPage !== 0) {
    pagesTotal += 1;
  }
  return pagesTotal;
};
/**
 * Sorts array of objects by 1-2 keys in ascending or descending order.
 * @param {array} array - List of objects
 * @param {string} key - Primary key for sorting
 * @param {string} secondaryKey - Secondary sorting key, optional
 * @param {boolean} ascending - optional, defaults to ascending if left undefined
 * @param {boolean} secondaryAscending - optional, defaults to ascending if left undefined
 * @returns {Object[]} - sorted array
 */
export const sortObjectsByValueOf = (
  array, key, secondaryKey, ascending, secondaryAscending,
) => array.sort((a, b) => {
  // ascending by primary key
  if (ascending || ascending === undefined) {
    if (a[key] === b[key] && secondaryKey !== undefined) {
      // ascending by secondary key
      if (secondaryAscending || secondaryAscending === undefined) {
        if (a[secondaryKey] < b[secondaryKey]) { return -1; }
        if (a[secondaryKey] > b[secondaryKey]) { return 1; }
      } else {
        if (a[secondaryKey] > b[secondaryKey]) { return -1; }
        if (a[secondaryKey] < b[secondaryKey]) { return 1; }
      }
    }
    if (a[key] < b[key]) { return -1; }
    if (a[key] > b[key]) { return 1; }
    return 0;
  }
  if (a[key] === b[key] && secondaryKey !== undefined) {
    // ascending by secondary key
    if (secondaryAscending || secondaryAscending === undefined) {
      if (a[secondaryKey] < b[secondaryKey]) { return -1; }
      if (a[secondaryKey] > b[secondaryKey]) { return 1; }
    } else {
      if (a[secondaryKey] > b[secondaryKey]) { return -1; }
      if (a[secondaryKey] < b[secondaryKey]) { return 1; }
    }
  }
  if (a[key] < b[key]) { return 1; }
  if (a[key] > b[key]) { return -1; }
  return 0;
});

/**
 * Checks if all the elements of the first array are included in the second one
 * @param {array} firstArray - List to check
 * @param {array} secondArray - List to compare against
 * @returns {boolean} - returns true if all the elements of the first array are in the second array
 */
export const allElementsAreInArray = (firstArray, secondArray) => firstArray.every(
  (element) => secondArray.includes(element),
);
/**
 * Creates a unique string that is not in the array provided.
 * @param {string[] || number[]} existingIds - List of ids that are already in use
 * @returns {string} - unique id string
 */
export const createUniqueId = (existingIds) => {
  const lastId = existingIds[existingIds.length - 1] || Math.floor(Math.random() * 100000);
  let randomInt = Math.floor(Math.random() * 100000);
  while (existingIds.includes(lastId.toString() + randomInt.toString())) {
    randomInt += 1;
  }
  return lastId.toString() + randomInt.toString();
};

/**
 * Creates previous quarter date in format of quarter number (01 to 04) and full year.
 * @returns {string} - returns previous quarter number and year
 */
export const getPreviousQuarter = () => {
  const today = new Date();
  const currentQuarter = Math.floor((today.getMonth() / 3));
  const startDatePreviousQuarter = new Date(today.getFullYear(), currentQuarter * 3 - 3, 1);
  const previousQuarterYear = startDatePreviousQuarter.getFullYear();
  const previousQuarterNumber = Math.floor((startDatePreviousQuarter.getMonth() / 3)) + 1;

  return `0${previousQuarterNumber}_${previousQuarterYear}`;
};

/**
 * legacy function from old web service to handle missing instrument name
 * @param {String} instrumentName - name of instrument.
 * @param {String} instrumentType - type of instrument.
 * @returns {String} - object with active and closed portfolio arrays
 */
// If instrumentType is not specified, we'll use simple heuristics of the name to guess the type
export const translateInstrumentName = (instrumentName, instrumentType) => {
  if (instrumentType === 'Receivable :' || (!instrumentType && (instrumentName.indexOf('Receivable :') === 0))) {
    return instrumentName.replace('Receivable :', 'Merkintäsaaminen :');
  }
  return instrumentName;
};

export const translateIfExists = (string, t) => (i18next.exists(string) ? t(string) : string);

/**
 * Compares given date to VP freeze date.
 * If date is higher than VP freeze date, then VP freeze date is returned.
 * @param {object} date - Date to compare to VP freeze date.
 * @returns {object} - date.
 */
export function compareDateToVPFreezeDate(date) {
  // this function returns given date instead of comparing it to freeze date
  return new Date(date);
  // uncomment, if this original functionality is needed later
  // return VP_FREEZE_DATE_OBJECT < date ? new Date(VP_FREEZE_DATE_OBJECT) : new Date(date);
}
