import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

// Redux
import { setInPersonnelFundView, setInLoginView } from '../../redux/UIcontrolSlice';

function AppSectionDefiner() {
  const dispatch = useDispatch();

  // Check if user is in any of the personnel fund paths.
  const frontpageMatch = useRouteMatch('/henkilostorahasto');
  const myFundsMatch = useRouteMatch('/rahasto-osuus');
  const withdrawalNotificationMatch = useRouteMatch('/nostoilmoitus');
  const fundingNotificationMatch = useRouteMatch('/rahastointi-ilmoitus');
  const fundUnitInfoMatch = useRouteMatch('/rahasto-osuus/maksettuosuus/:membershipId?/:fiscalYearId?');
  const myInfoMatch = useRouteMatch('/omattiedot');
  const customerServiceMatch = useRouteMatch('/asiakaspalvelu');
  const logoutPageMatch = useRouteMatch('/kirjauduuloshrj/:guid?/:hrjsale?');

  // Check if user is in login path.
  const loginMatch = useRouteMatch('/kirjaudu');
  const webServiceLoginMatch = useRouteMatch('/verkkopalvelu');
  const personnelFundLoginMatch = useRouteMatch('/jasenpalvelu');
  const personnelFundLoginReturnMatch = useRouteMatch('/tupasLoginReturn');
  const webServiceLoginReturnMatch = useRouteMatch('/tupasLoginEliteReturn');
  const buyFundLoginReturnMatch = useRouteMatch('/ostaRahastoa/kirjautuminen');

  // Combine route matches.
  // Note: customer service route needs exact match, web service also has /asiakaspalvelu routes.
  const inPersonnelFundRoute = Boolean(frontpageMatch || myFundsMatch || withdrawalNotificationMatch
    || fundingNotificationMatch || fundUnitInfoMatch || myInfoMatch
    || customerServiceMatch?.isExact || logoutPageMatch);
  const inLoginRoute = Boolean(loginMatch || webServiceLoginMatch || personnelFundLoginMatch
    || personnelFundLoginReturnMatch || webServiceLoginReturnMatch || buyFundLoginReturnMatch);

  useEffect(() => {
    dispatch(setInPersonnelFundView(inPersonnelFundRoute));
  }, [dispatch, inPersonnelFundRoute]);

  useEffect(() => {
    dispatch(setInLoginView(inLoginRoute));
  }, [dispatch, inLoginRoute]);

  return null;
}

export default AppSectionDefiner;
