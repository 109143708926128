import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

function RedirectToExternalUrl({ redirectTo }) {
  useEffect(() => {
    window.location.href = redirectTo;
  }, [redirectTo]);

  return (
    <Paper elevation={1} square sx={{ padding: 4 }}>
      <Grid container direction="column">
        <Grid item>
          <Typography
            variant="h4"
            sx={{ fontWeight: 300 }}
          >
            Redirecting ...
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}

RedirectToExternalUrl.propTypes = {
  redirectTo: PropTypes.string.isRequired,
};

export default RedirectToExternalUrl;
