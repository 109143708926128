import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { format } from 'date-fns';
import { summaryReportTimeSpanOptions } from '../lib/constants';
import axios from '../api/axiosSetup';
import { sortObjectsByValueOf } from '../lib/commonFunctions';

export const fetchFundsOnSale = createAsyncThunk('funds/fetchFundsOnSale', async () => {
  const response = await axios.get('api/funds/fundsThatAreOnSale');
  return response.data;
});

export const fetchMonthlySavings = createAsyncThunk('funds/monthlySavings', async (_, thunkAPI) => {
  const { customerId } = thunkAPI.getState().auth;
  const response = await axios.get(`api/funds/monthlySavings${customerId ? `?customerId=${customerId}` : ''}`);
  return response.data;
});

export const fetchAllFunds = createAsyncThunk('funds/fetchAllFunds', async (
  {
    searchTerm = '',
    assetTypes = [],
    classifications = [],
    fundFamily = '',
    order = 'asc',
    orderBy = 'name',
    instrumentIds = [],
    showSummary = true,
  }, { signal },
) => {
  let queryParameters = 'searchTerm=';

  if (searchTerm.length > 2) {
    queryParameters += searchTerm;
  }
  if (assetTypes.length > 1) {
    queryParameters += `&${assetTypes.slice(1).map((at) => `assetTypes=${at}`).join('&')}`;
  }
  if (classifications.length > 1) {
    queryParameters += `&${classifications.slice(1).map((at) => `classifications=${at}`).join('&')}`;
  }
  if (fundFamily !== '') {
    queryParameters += `&fundFamily=${fundFamily}`;
  }

  // abort previous request
  const controller = new AbortController();
  signal.addEventListener('abort', () => {
    controller.abort();
  });

  const response = await axios.get(`api/fundSearchAll/1/5000/${orderBy}/${order}?${encodeURI(queryParameters)}`,
    {
      signal: controller.signal,
      params: {
        instrumentIds,
        showSummary,
      },
    });
  return response.data;
});

export const fetchDefaultFunds = createAsyncThunk('funds/fetchDefaultFunds', async () => {
  const response = await axios.get('api/funds/getDefaultFunds');
  return response.data;
});

export const fetchAssetTypes = createAsyncThunk('funds/fetchAssetTypes', async () => {
  const response = await axios.get('api/fundAttributes/assetTypes');
  return response.data;
});

export const fetchClassifications = createAsyncThunk('funds/fetchClassifications', async ({ assetTypes }) => {
  const parseAssetTypes = assetTypes.length > 0 ? encodeURI(assetTypes.map((at) => `assetTypes=${at}`).join('&')) : 'assetTypes=';
  const response = await axios.get(`api/fundAttributes/classifications?${parseAssetTypes}`);
  return response.data;
});

export const fetchFundFamilies = createAsyncThunk('funds/fetchFundFamilies', async () => {
  const response = await axios.get('api/fundAttributes/families');
  return response.data;
});

export const fetchFundBasicPrice = createAsyncThunk('funds/fetchFundBasicPrice', async (instrumentId) => {
  const response = await axios.get(`api/funds/fundbasicprice/${instrumentId}`);
  return response.data;
});

export const fetchCheckMonthlyReportAvailable = createAsyncThunk('funds/fetchCheckMonthlyReportAvailable', async (instrumentId) => {
  const response = await axios.get(`api/funds/monthlyreportavailable/${instrumentId}`);
  return response.data;
});

export const fetchCheckQuarterlyReportAvailable = createAsyncThunk('funds/fetchCheckQuarterlyReportAvailable', async (instrumentId) => {
  const response = await axios.get(`api/funds/quarterlyreportavailable/${instrumentId}`);
  return response.data;
});

export const fetchMutualFundDescription = createAsyncThunk('funds/fetchMutualFundDescription', async (instrumentId) => {
  const response = await axios.get(`api/funds/mutualfunddescription/${instrumentId}`);
  return response.data;
});

export const fetchFundSerieManagerReview = createAsyncThunk('funds/fetchFundSerieManagerReview', async (instrumentId) => {
  const response = await axios.get(`api/funds/fundSerieManagerReview/${instrumentId}`);
  return response.data;
});

export const fetchFundProfithistory = createAsyncThunk('funds/fetchFundProfithistory', async (instrumentId) => {
  const response = await axios.get(`api/funds/fundprofithistory/${instrumentId}`);
  return response.data;
});

export const fetchFundIndexedReturns = createAsyncThunk('funds/fetchFundIndexedReturns', async ({ instrumentId, startDate, endDate }) => {
  let url = `api/funds/fundindexedreturns/${instrumentId}`;

  url += `/${startDate !== null ? format(new Date(startDate), 'yyyy-M-d') : null}`;
  url += `/${endDate !== null ? format(new Date(endDate), 'yyyy-M-d') : null}`;

  const response = await axios.get(url);
  return response.data;
});

export const fetchSearchFunds = createAsyncThunk('funds/fetchSearchFunds', async ({ portfolioId, searchTerm, onlySavings }, thunkAPI) => {
  if (searchTerm.length > 0) {
    const { customerId } = thunkAPI.getState().auth;
    const response = await axios.get(`api/fundSearch/${portfolioId}/${encodeURIComponent(searchTerm)}/${onlySavings}${customerId ? `?customerId=${customerId}` : ''}`);
    const orderedResponse = sortObjectsByValueOf(response.data, 'name');
    return orderedResponse;
  }
  return [];
});

export const fetchOnlySavingsFunds = createAsyncThunk('funds/fetchOnlySavingsFunds', async () => {
  const response = await axios.get('api/fundSearch/getOnlySavingsFunds');
  return response.data;
});

export const fetchFindOnlySavingsFunds = createAsyncThunk('funds/fetchOnlySavingsFunds', async (portfolioId, thunkAPI) => {
  const { customerId } = thunkAPI.getState().auth;
  const response = await axios.get(`api/fundSearch/onlySavingsFunds/${portfolioId}${customerId ? `?customerId=${customerId}` : ''}`);
  return response.data;
});
export const fetchSavingsFunds = createAsyncThunk('funds/fetchSavingsFunds', async () => {
  const response = await axios.get('api/fundSearch/getOnlySavingsFunds');
  return response.data;
});

export const fetchIsAppropriateInvestmentForUser = createAsyncThunk('funds/fetchIsAppropriateInvestmentForUser', async (instrumentId, thunkAPI) => {
  const { customerId } = thunkAPI.getState().auth;
  const response = await axios.get(`api/funds/isAppropriateInvestmentForUser/${instrumentId}${customerId ? `?customerId=${customerId}` : ''}`);
  return response.data;
});

export const fetchFundDetails = createAsyncThunk('funds/fetchFundDetails', async (instrumentId) => {
  const response = await axios.get(`api/funds/funddetails/${instrumentId}`);
  return response.data;
});

export const fetchFundDocuments = createAsyncThunk('funds/fetchFundDocuments', async (instrumentId) => {
  const response = await axios.get(`api/funds/documents/${instrumentId}`);
  return response.data;
});

export const fetchFundFeeInfo = createAsyncThunk('funds/fetchFundFeeInfo', async ({ instrumentId, portfolioId }) => {
  const response = await axios.get(`api/funds/${instrumentId}/feeInfo`,
    {
      params: {
        portfolioId,
      },
    });
  return response.data;
});

export const fetchFundBasicData = createAsyncThunk('funds/fetchFundBasicData', async ({ instrumentId }) => {
  const response = await axios.get(`api/funds/${instrumentId}/basicData`);
  return response.data;
});

const datePickerInitialState = {
  timeSpan: summaryReportTimeSpanOptions(() => { })[0].value,
  startDate: new Date(new Date().setMonth(new Date().getMonth() - 3)).toString(),
  endDate: new Date().toString(),
};

export const fundSharesSlice = createSlice({
  name: 'funds',
  initialState: {
    allFunds: [],
    fetchingAllFunds: false,
    defaultFunds: [],
    fetchingDefaultFunds: false,
    assetTypes: [],
    fetchingAssetTypes: false,
    classifications: [],
    fetchingClassifications: false,
    fundFamilies: [],
    fetchingFundFamilies: false,
    fundsOnSale: [],
    fetchingFundsOnSale: false,
    monthlySavings: [],
    fetchingMonthlySavings: false,
    searchResultFunds: [],
    fetchingSearchResultFunds: false,
    onlySavingsFunds: [],
    fetchingOnlySavingsFunds: false,
    foundOnlySavingsFunds: [],
    fetchingFindOnlySavingsFunds: false,
    isAppropriateInvestmentForUser: null,
    fetchingIsAppropriateInvestmentForUser: false,
    fundDetails: null,
    fetchingFundDetails: false,
    fundDocuments: [],
    fetchingFundDocuments: false,
    fundFeeInfo: null,
    fetchingFundFeeInfo: false,
    searchFilter: {
      searchTerm: '',
      assetTypes: [],
      classifications: [],
      fundFamily: '',
      order: 'asc',
      orderBy: 'name',
    },
    fundBasicPrice: null,
    fetchingFundBasicPrice: false,
    fundMonthlyReport: [],
    fetchingCheckMonthlyReportAvailable: false,
    fundMonthlyReportAvailable: null,
    fetchingCheckQuarterlyReportAvailable: false,
    fundQuarterlyReportAvailable: null,
    mutualFundDescription: '',
    fetchingMutualFundDescription: false,
    fundSerieManagerReview: '',
    fetchingFundSerieManagerReview: false,
    fundProfithistory: null,
    fetchingFundProfithistory: false,
    fundIndexedReturns: [],
    fetchingFundIndexedReturns: false,
    datePicker: datePickerInitialState,
    fetchingFundBasicData: false,
    fundBasicData: null,
    fetchingSavingsFunds: false,
    foundSavingsFunds: [],
  },
  reducers: {
    setSearchFilter: (state, { payload }) => {
      state.searchFilter.searchTerm = payload.searchTerm;
      state.searchFilter.assetTypes = payload.assetTypes;
      state.searchFilter.classifications = payload.classifications;
      state.searchFilter.fundFamily = payload.fundFamily;
      state.searchFilter.order = payload.order;
      state.searchFilter.orderBy = payload.orderBy;
    },
    clearSearchFilter: (state) => {
      state.searchFilter.searchTerm = '';
      state.searchFilter.assetTypes = [];
      state.searchFilter.classifications = [];
      state.searchFilter.fundFamily = '';
      state.classifications = [];
    },
    resetDatePicker: (state) => {
      state.datePicker = datePickerInitialState;
    },
    setTimeSpan: (state, { payload }) => {
      state.datePicker.timeSpan = payload.timeSpan;
      let startDate = new Date();
      let endDate = new Date();
      switch (payload.timeSpan) {
        case '3_MONTHS':
          startDate = new Date(new Date().setMonth(new Date().getMonth() - 3));
          endDate = new Date();
          break;
        case '6_MONTHS':
          startDate = new Date(new Date().setMonth(new Date().getMonth() - 6));
          endDate = new Date();
          break;
        case '12_MONTHS':
          startDate = new Date(new Date().setFullYear(new Date().getFullYear() - 1));
          endDate = new Date();
          break;
        case 'CURRENT_YEAR':
          startDate = new Date(new Date(new Date().setMonth(0)).setDate(1));
          endDate = new Date();
          break;
        case 'SINCE_BEGINNING':
          // current fund's start date is set null
          startDate = null;
          endDate = new Date();
          break;
        default:
          // these will be set as dates chosen by user where reducer is called
          // if there is no dates in the payload, use dates in the state
          startDate = payload.startDate ? new Date(payload.startDate) : state.datePicker.startDate;
          endDate = new Date(payload.endDate || state.datePicker.endDate);
          break;
      }
      state.datePicker.endDate = endDate.toString();
      state.datePicker.startDate = startDate ? startDate.toString() : null;
    },
  },
  extraReducers: {
    [fetchAllFunds.pending]: (state) => {
      state.fetchingAllFunds = true;
      state.allFunds = [];
    },
    [fetchAllFunds.fulfilled]: (state, { payload }) => {
      state.fetchingAllFunds = false;
      state.allFunds = payload;
    },
    // When you search something the previous request (if it is still ongoing)
    // is cancelled and new request is done with new search criteria.
    // The previous request is rejected and progress indicator stops spinning.
    // There is about 0.6 seconds delay before the indicator starts spinning again.
    // Setting the fetchingAllFunds status this way prevents the progress indicator delay.
    [fetchAllFunds.rejected]: (state) => {
      state.fetchingAllFunds = !!state.fetchingAllFunds;
    },
    [fetchDefaultFunds.pending]: (state) => {
      state.fetchingDefaultFunds = true;
      state.defaultFunds = [];
    },
    [fetchDefaultFunds.fulfilled]: (state, { payload }) => {
      state.fetchingDefaultFunds = false;
      state.defaultFunds = payload;
    },
    [fetchDefaultFunds.rejected]: (state) => {
      state.fetchingDefaultFunds = false;
    },
    [fetchAssetTypes.pending]: (state) => {
      state.fetchingAssetTypes = true;
      state.assetTypes = [];
    },
    [fetchAssetTypes.fulfilled]: (state, { payload }) => {
      state.fetchingAssetTypes = false;
      state.assetTypes = payload;
    },
    [fetchAssetTypes.rejected]: (state) => {
      state.fetchingAssetTypes = false;
    },
    [fetchClassifications.pending]: (state) => {
      state.fetchingClassifications = true;
      state.classifications = [];
    },
    [fetchClassifications.fulfilled]: (state, { payload }) => {
      state.fetchingClassifications = false;
      state.classifications = payload;
    },
    [fetchClassifications.rejected]: (state) => {
      state.fetchingClassifications = false;
    },
    [fetchFundFamilies.pending]: (state) => {
      state.fetchingFundFamilies = true;
      state.fundFamilies = [];
    },
    [fetchFundFamilies.fulfilled]: (state, { payload }) => {
      state.fetchingFundFamilies = false;
      state.fundFamilies = payload;
    },
    [fetchFundFamilies.rejected]: (state) => {
      state.fetchingFundFamilies = false;
    },
    [fetchFundsOnSale.pending]: (state) => {
      state.fetchingFundsOnSale = true;
      state.fundsOnSale = [];
    },
    [fetchFundsOnSale.fulfilled]: (state, { payload }) => {
      state.fetchingFundsOnSale = false;
      state.fundsOnSale = payload;
    },
    [fetchFundsOnSale.rejected]: (state) => {
      state.fetchingFundsOnSale = false;
    },
    [fetchMonthlySavings.pending]: (state) => {
      state.fetchingMonthlySavings = true;
      state.monthlySavings = [];
    },
    [fetchMonthlySavings.fulfilled]: (state, { payload }) => {
      state.fetchingMonthlySavings = false;
      state.monthlySavings = payload;
    },
    [fetchMonthlySavings.rejected]: (state) => {
      state.fetchingMonthlySavings = false;
    },
    [fetchSearchFunds.pending]: (state) => {
      state.fetchingSearchResultFunds = true;
    },
    [fetchSearchFunds.fulfilled]: (state, { payload }) => {
      state.fetchingSearchResultFunds = false;
      state.searchResultFunds = payload;
    },
    [fetchSearchFunds.rejected]: (state) => {
      state.fetchingSearchResultFunds = false;
    },
    [fetchOnlySavingsFunds.pending]: (state) => {
      state.fetchingOnlySavingsFunds = true;
    },
    [fetchOnlySavingsFunds.fulfilled]: (state, { payload }) => {
      state.fetchingOnlySavingsFunds = false;
      state.onlySavingsFunds = payload;
    },
    [fetchOnlySavingsFunds.rejected]: (state) => {
      state.fetchingOnlySavingsFunds = false;
    },
    [fetchFindOnlySavingsFunds.pending]: (state) => {
      state.fetchingFindOnlySavingsFunds = true;
    },
    [fetchFindOnlySavingsFunds.fulfilled]: (state, { payload }) => {
      state.fetchingFindOnlySavingsFunds = false;
      state.foundOnlySavingsFunds = payload;
    },
    [fetchFindOnlySavingsFunds.rejected]: (state) => {
      state.fetchingFindOnlySavingsFunds = false;
    },
    [fetchSavingsFunds.pending]: (state) => {
      state.fetchingSavingsFunds = true;
    },
    [fetchSavingsFunds.fulfilled]: (state, { payload }) => {
      state.fetchingSavingsFunds = false;
      state.foundSavingsFunds = payload;
    },
    [fetchSavingsFunds.rejected]: (state) => {
      state.fetchingSavingsFunds = false;
    },
    [fetchIsAppropriateInvestmentForUser.pending]: (state) => {
      state.fetchingIsAppropriateInvestmentForUser = true;
      state.isAppropriateInvestmentForUser = null;
    },
    [fetchIsAppropriateInvestmentForUser.fulfilled]: (state, { payload }) => {
      state.fetchingIsAppropriateInvestmentForUser = false;
      state.isAppropriateInvestmentForUser = payload;
    },
    [fetchIsAppropriateInvestmentForUser.rejected]: (state) => {
      state.fetchingIsAppropriateInvestmentForUser = false;
    },
    [fetchFundDetails.pending]: (state) => {
      state.fetchingFundDetails = true;
      state.fundDetails = null;
    },
    [fetchFundDetails.fulfilled]: (state, { payload }) => {
      state.fetchingFundDetails = false;
      state.fundDetails = payload;
    },
    [fetchFundDetails.rejected]: (state) => {
      state.fetchingFundDetails = false;
    },
    [fetchFundDocuments.pending]: (state) => {
      state.fetchingFundDocuments = true;
      state.fundDocuments = [];
    },
    [fetchFundDocuments.fulfilled]: (state, { payload }) => {
      state.fetchingFundDocuments = false;
      state.fundDocuments = payload;
    },
    [fetchFundDocuments.rejected]: (state) => {
      state.fetchingFundDocuments = false;
    },
    [fetchFundFeeInfo.pending]: (state) => {
      state.fetchingFundFeeInfo = true;
      state.fundFeeInfo = null;
    },
    [fetchFundFeeInfo.fulfilled]: (state, { payload }) => {
      state.fetchingFundFeeInfo = false;
      state.fundFeeInfo = payload;
    },
    [fetchFundFeeInfo.rejected]: (state) => {
      state.fetchingFundFeeInfo = false;
    },
    [fetchFundBasicPrice.pending]: (state) => {
      state.fetchingFundBasicPrice = true;
    },
    [fetchFundBasicPrice.fulfilled]: (state, { payload }) => {
      state.fetchingFundBasicPrice = false;
      state.fundBasicPrice = payload;
    },
    [fetchFundBasicPrice.rejected]: (state) => {
      state.fetchingFundBasicPrice = false;
    },
    [fetchCheckMonthlyReportAvailable.pending]: (state) => {
      state.fetchingCheckMonthlyReportAvailable = true;
    },
    [fetchCheckMonthlyReportAvailable.fulfilled]: (state, { payload }) => {
      state.fetchingCheckMonthlyReportAvailable = false;
      state.fundMonthlyReportAvailable = payload;
    },
    [fetchCheckMonthlyReportAvailable.rejected]: (state) => {
      state.fetchingCheckMonthlyReportAvailable = false;
    },
    [fetchCheckQuarterlyReportAvailable.pending]: (state) => {
      state.fetchingCheckQuarterlyReportAvailable = true;
    },
    [fetchCheckQuarterlyReportAvailable.fulfilled]: (state, { payload }) => {
      state.fetchingCheckQuarterlyReportAvailable = false;
      state.fundQuarterlyReportAvailable = payload;
    },
    [fetchCheckQuarterlyReportAvailable.rejected]: (state) => {
      state.fetchingCheckQuarterlyReportAvailable = false;
    },
    [fetchMutualFundDescription.pending]: (state) => {
      state.fetchingMutualFundDescription = true;
    },
    [fetchMutualFundDescription.fulfilled]: (state, { payload }) => {
      state.fetchingMutualFundDescription = false;
      state.mutualFundDescription = payload;
    },
    [fetchMutualFundDescription.rejected]: (state) => {
      state.fetchingMutualFundDescription = false;
    },
    [fetchFundSerieManagerReview.pending]: (state) => {
      state.fetchingFundSerieManagerReview = true;
    },
    [fetchFundSerieManagerReview.fulfilled]: (state, { payload }) => {
      state.fetchingFundSerieManagerReview = false;
      state.fundSerieManagerReview = payload;
    },
    [fetchFundSerieManagerReview.rejected]: (state) => {
      state.fetchingFundSerieManagerReview = false;
    },
    [fetchFundProfithistory.pending]: (state) => {
      state.fetchingFundProfithistory = true;
    },
    [fetchFundProfithistory.fulfilled]: (state, { payload }) => {
      state.fetchingFundProfithistory = false;
      state.fundProfithistory = payload;
    },
    [fetchFundProfithistory.rejected]: (state) => {
      state.fetchingFundProfithistory = false;
    },
    [fetchFundIndexedReturns.pending]: (state) => {
      state.fetchingFundIndexedReturns = true;
    },
    [fetchFundIndexedReturns.fulfilled]: (state, { payload }) => {
      state.fetchingFundIndexedReturns = false;
      state.fundIndexedReturns = payload;
    },
    [fetchFundIndexedReturns.rejected]: (state) => {
      state.fetchingFundIndexedReturns = false;
    },
    [fetchFundBasicData.pending]: (state) => {
      state.fetchingFundBasicData = true;
      state.fundBasicData = null;
    },
    [fetchFundBasicData.fulfilled]: (state, { payload }) => {
      state.fetchingFundBasicData = false;
      state.fundBasicData = payload;
    },
    [fetchFundBasicData.rejected]: (state) => {
      state.fetchingFundBasicData = false;
    },
  },
});

export const {
  clearSearchFilter,
  setSearchFilter,
  resetDatePicker,
  setTimeSpan,
} = fundSharesSlice.actions;

export default fundSharesSlice.reducer;
