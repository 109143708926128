import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../api/axiosSetup';

export const postFeedback = createAsyncThunk('personnelFundContact/postFeedback', async ({ formData }) => {
  const response = await axios.post(
    'api/email/contact/',
    formData,
  );
  return response.status;
});

export const personnelFundContactSlice = createSlice({
  name: 'personnelFundContact',
  initialState: {
    postingFeedback: false,
  },
  extraReducers: {
    [postFeedback.pending]: (state) => {
      state.postingFeedback = true;
    },
    [postFeedback.fulfilled]: (state, { payload }) => {
      state.feedback = payload;
      state.postingFeedback = false;
    },
    [postFeedback.rejected]: (state) => {
      state.postingFeedback = false;
    },
  },
});

export default personnelFundContactSlice.reducer;
